import { AfterViewInit, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatBottomSheet, MatPaginator, MatSort, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { InventoryItemsDataSource } from './inventory-items-datasource';
import { faBars, faCheck, faSearch, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { InventoryItemDTO } from '../../../models/item';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { SelectionPopupComponent } from '../../../../../../customer-integrations/hollingbery/modules/financial/contracts/widgets/selection-popup/selection-popup.component';
import { InventoryItemEditComponent, InventoryItemPopupData } from '../inventory-item-edit/inventory-item-edit.component';
import { Subscription } from 'rxjs';
import { FilterSettings } from '../../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { KwFilterDropdownService } from '../../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'inventory-items',
  templateUrl: './inventory-items.component.html',
  styleUrls: ['./inventory-items.component.scss']
})
export class InventoryItemsComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: InventoryItemsDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'description', 'variety', 'actions'];

  // Icons //
  public faBars = faBars;
  public faSearch = faSearch;
  public faTruck = faTruck;
  public faCheck = faCheck;

  public items: InventoryItemDTO[] = [];
  public isLoading: boolean = true;
  private initialLoad: boolean = true;
  public filterResults: FilterResults;
  public dropdownSettings: FilterSettings = {
    
    roomSettings: {
      display: false,
      singleSelection: false,
    },
    warehouseSettings: {
      display: false,
      singleSelection: false,
    },
    locationSettings: {
      display: false,
      singleSelection: false,
    },
    lotNumberSettings: {
      display: false,
      singleSelection: false,
    },
    inventoryItemSettings: {
      display: true,
      singleSelection: false,
    },
    varietySettings: {
      display: true,
      singleSelection: false,
    },
  };
  
  constructor(
    private inventoryService: InventoryService, 
    private errorService: ErrorService,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    public filterService: KwFilterDropdownService

    ){
      
    }
  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngOnInit(){
    this.dataSource = new InventoryItemsDataSource(this.paginator, this.sort);
    this.updateTable();
  }

  updateTable(){
    this.isLoading = true;
    this.inventoryService.getInventoryItems().subscribe(x => {
      x.sort((a,b) => a.name.localeCompare(b.name));
      this.items = x;
      this.dataSource.data = this.items;
      
      this.paginator._changePageSize(this.paginator.pageSize);
      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  openDialog(row: InventoryItemDTO){

    let data = {
      item: row,
      isPopup: true
    }
    // open the bottom sheet

    this.router.navigateByUrl("/harvest-engine/inventory/inventory-item-detail?item=" + row.id);

    // this.bottomSheet.open(InventoryItemEditComponent, { data });

    // // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    //  let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {

      
    //   if (job) {
    //     bottomSheetSubscription.unsubscribe();
    //     this.updateTable();
    //   }
    // });

  }

  createItem(){
    let data = {
      item: {},
      isPopup: true
    }
    this.bottomSheet.open(InventoryItemEditComponent, { data });

    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      if (job) {
        bottomSheetSubscription.unsubscribe();
        this.updateTable();
      }
    });
  }

  setFilterResults(results: FilterResults) {
    if(this.initialLoad){
      this.initialLoad = false;
      this.filterResults = this.filterService.getFilterState();
    } else {
      this.filterResults = results;
    }
    this.dataSource.data = _.sortBy([
      ...this.items.filter(x =>
        (this.filterResults.selectedInventoryItems.length == 0 || this.filterResults.selectedInventoryItems.map(y => y.id).includes(x.id)) &&
        (this.filterResults.selectedVarieties.length == 0 || this.filterResults.selectedVarieties.map(y => y.id).includes(x.varietyId))
      )
    ], x => x.name);
    this.isLoading = false;
    // this.dataSource.data.sort((a,b) => a.name.localeCompare(b.name));
    this.paginator._changePageSize(this.paginator.pageSize);
  }


}
