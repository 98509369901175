import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../../../reports/report.service';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import { PrecisionSessionService } from '../../services/precision-session.service';
import * as lodash from 'lodash';

@Component({
  selector: 'purchase-order-report',
  templateUrl: './purchase-order-report.component.html',
  styleUrls: ['./purchase-order-report.component.scss']
})
export class PurchaseOrderReportComponent implements OnInit {

  public purchaseOrder: PrecisionPurchaseOrder;

  constructor(
    private precisionSession: PrecisionSessionService,
    private reportService: ReportService
  ) {


  }

  ngOnInit() {

    this.purchaseOrder = { ...this.precisionSession.reportPurchaseOrder };
    
    setTimeout(() => {
      
      this.reportService.showDate = false;
      this.reportService.documentTitle = "Purchase Order";
      this.reportService.onDataReady();
    }, 500);

  }

  getTotal() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.quantityOrdered));
  }

  getTotalReceived() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.receivedQuantity));
  }

  getTotalPrice() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.rate * x.quantityOrdered));
  }

}
