import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../@core/utils/error.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { ContractDTO } from '../models/contract';
import { ContractLineDTO } from '../models/lineItem';

@Injectable({
  providedIn: 'root'
})
export class ContractLinesService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    // this.basePath = environment.HOLLINGBERY_API_URL;
    this.basePath = environment.PLATFORM_API_URL;


  }


  public getContractLines(contractId: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.get<any>(`${this.basePath}/api/lineitem`,

      {
        headers,
      }
    );
  }
  public getContractLinesById(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    // console.log(`${this.basePath}/api/contractlines/${id}`)
    return this.httpClient.get<any>(`${this.basePath}/api/contractlines/${id}`,

      {
        headers,
      }
    );
  }

  public getContractLinesByContractId(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    // console.log(`${this.basePath}/api/contractlines/byContractId/${id}`)
    return this.httpClient.get<any>(`${this.basePath}/api/contractlines/byContractId/${id}`,

      {
        headers,
      }
    );
  }

  public postContractLines(dto: ContractLineDTO): Observable<ContractLineDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.post<ContractLineDTO>(`${this.basePath}/api/contractlines`, dto,

      {
        headers,
      }
    );
  }
  public putContractLines(dto: ContractLineDTO): Observable<ContractLineDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    // console.log(`${this.basePath}/api/contractlines/${dto.id}`)
    return this.httpClient.put<ContractLineDTO>(`${this.basePath}/api/contractlines/${dto.id}`, dto,

      {
        headers,
      }
    );
  }
}
