import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../@core/utils/error.service';
import { InventoryLotDTO, InventoryLotSummaryDTO } from '../../inventory/models/inventory-lot';
import { Observable, of } from 'rxjs';

import { server } from 'typescript/lib/tsserverlibrary';

@Injectable({
  providedIn: 'root'
})
export class LotSummaryService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  private refreshInterval_ms = 1000 * 60 * 5;
  private isRefreshing = false;

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this._refreshLotSummaries();
    setInterval(() => {
      this._refreshLotSummaries();
    }, this.refreshInterval_ms);

  }

  private _cachedLotSummaries: InventoryLotSummaryDTO[] = [];
  private _lastUpdate: Date = new Date(0);

  public updateLotReadyToPick(id: number, readyToPick: boolean){
    this._cachedLotSummaries.find(lot => lot.lotId === id).readyToPick = readyToPick;
  }

  private _refreshLotSummaries() {

    if (!this.isRefreshing)
      this.isRefreshing = true;
      this.getLotLastUpdate().subscribe(serverLastUpdate => {

        let serverLastUpdateMillis = new Date(serverLastUpdate).getTime();
        let lastUpdateMillis = new Date(this._lastUpdate).getTime();
        if (serverLastUpdateMillis > lastUpdateMillis) {
          this._getApiLotSummary(false, 0).subscribe(lotSummaries => {
            this._cachedLotSummaries = lotSummaries;
            this._lastUpdate = new Date();
            this.isRefreshing = false;
          });
        }
      });


  }

  public getLotLastUpdate(): Observable<Date> {
    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<Date>(`${this.basePath}/api/inventory/lots/lastupdate`,
      {
        headers: headers,
      }
    );
  }

  private _getApiLotSummary(includeInactive: boolean = false, workOrderId: number = 0): Observable<InventoryLotSummaryDTO[]> {

    // else return the http request
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('IncludeInactive', <any>includeInactive);
    }

    queryParameters = queryParameters.set('workOrderId', workOrderId.toString());

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<InventoryLotSummaryDTO[]>(`${this.basePath}/api/inventory/lots/summary`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getLotSummary(includeInactive: boolean = false, workOrderId: number = 0, useCache: boolean = true): Observable<InventoryLotSummaryDTO[]> {

    // if the _cachedLotSummaries is not empty or instructured not to use cache, return it

    if (this._cachedLotSummaries.length > 0 && useCache) {
      return of(this._cachedLotSummaries);
    }

    return this._getApiLotSummary(includeInactive, workOrderId);
  }
}


