import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DiagramComponent, SnapSettingsModel, DataSourceModel, NodeModel, Diagram, ConnectorModel, PageSettingsModel } from '@syncfusion/ej2-angular-diagrams';
import { InventoryLotSummaryDTO } from '../../models/inventory-lot';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { KW_COLORS } from '../../../../../@theme/kragworks-colors';
import { LotSummaryService } from '../../../pick-plan/services/lot-summary.service';

@Component({
  selector: 'lot-hierarchy-chart',
  templateUrl: './lot-hierarchy-chart.component.html',
  styleUrls: ['./lot-hierarchy-chart.component.scss']
})
export class LotHierarchyChartComponent implements OnInit, OnChanges {

  @Input() lotId: number;
  @ViewChild("diagram")
  public diagram: DiagramComponent;
  public pageSettings: PageSettingsModel = {background: { color: 'transparent' }};
  public snapSettings: SnapSettingsModel;

  public isLoading = true;

  nodes: NodeModel[] = [];
  connectors: any[] = [];

  public dataSourceSettings: DataSourceModel;

  public lotList: InventoryLotSummaryDTO[] = [];

  constructor(
    private inventoryService: InventoryService,
    private lotSummaryService: LotSummaryService
  ) { }

  ngOnChanges() {
    if (this.lotId)
      this.setDiagram();
  }

  setDiagram() {
    this.lotSummaryService.getLotSummary().subscribe(lots => {

      this.lotList = [];
      let currentLot = lots.find(x => x.lotId == this.lotId);
      this.lotList.push(currentLot);

      // get parent lots
      if (currentLot.parentLotId && currentLot.parentLotId !== 0) {
        let hasParentLot = true;
        let currentParentLotId = currentLot.parentLotId;

        let parentLot = lots.find(x => x.lotId == currentParentLotId);
        let maxParents = 100;
        let currentIndex = 0;
        while (hasParentLot && currentIndex < maxParents) {

          currentIndex++;

          if (!parentLot)
            hasParentLot = false;

          this.lotList.push(parentLot);

          if (!parentLot.parentLotId || parentLot.parentLotId == 0)
            hasParentLot = false;
          else {
            // set the next parent lot
            parentLot = lots.find(x => x.lotId == parentLot.parentLotId);
            if(parentLot){
              currentParentLotId = parentLot.lotId;
            } else{
              hasParentLot = false;
            }

          }

        }
      }

      // get child lots
      let childLots = lots.filter(x => x.parentLotId === currentLot.lotId);
      
      for (let child of childLots) {
        this.lotList.push(child);


      }

      this.lotList.forEach((lot, index) => {
        // Create node
        const node: NodeModel = {
          id: lot.lotId.toString(),
          offsetY: index === 0 ? 50 : (100 * index) + 50, // Adjust offsetY based on node count
          annotations: [{ content: lot.lotNumber}],
        };
  
        // Create connector (if parentId exists)
        if (lot.parentLotId && this.lotList.find(x => x.lotId === lot.parentLotId)) {
          const connector: ConnectorModel = {
            id: `connector_${this.connectors.length + 1}`,
            sourceID: lot.parentLotId.toString(),
            targetID: lot.lotId.toString(),
          };
  
          this.connectors.push(connector);
        }
  
        // Add node to the nodes array
        this.nodes.push(node);
      });
      this.nodes = [...this.nodes]
      this.connectors = [...this.connectors]

      this.isLoading = false;


    })
  }

  //Sets the default properties for all the Nodes
  public getNodeDefaults(obj: NodeModel): NodeModel {
    obj.width = 250;
    obj.height = 50;
    obj.style.fill = KW_COLORS.blue;
    obj.annotations = [{ content: obj.annotations[0].content, style: { color: '#FFFFFF', fontSize: 16 } }];
    obj.shape = { type: 'Basic', shape: 'Rectangle', cornerRadius: 6 };
    obj.offsetX = 200;
    return obj;
  }

  //Sets the default properties for all the connectors
  public getConnectorDefaults(connector: ConnectorModel, diagram: Diagram): ConnectorModel {
    connector.style = {
      strokeColor: '#6BA5D7',
      strokeWidth: 2
    };
    connector.targetDecorator.style.fill = '#6BA5D7';
    connector.targetDecorator.style.strokeColor = '#6BA5D7';
    connector.targetDecorator.shape = 'None';
    connector.type = 'Orthogonal';

    return connector;
  }


  ngOnInit(): void {
    this.snapSettings = {
      constraints: 0
    }
  }

}


// this.nodes = [
//   {
//     id: 'parent',
//     offsetY: 50,
//     annotations: [{ content: 'Parent' }]
//   },
//   {
//     id: 'child1',
//     offsetY: 150,
//     annotations: [{ content: 'Child 1' }]
//   },
//   {
//     id: 'child2',
//     offsetY: 250,
//     annotations: [{ content: 'Child 2' }]
//   }
// ];

// this.connectors = [
//   {
//     id: 'connector1',
//     sourceID: 'parent',
//     targetID: 'child1'
//   },
//   {
//     id: 'connector2',
//     sourceID: 'child1',
//     targetID: 'child2'
//   }
// ];


