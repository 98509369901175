import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { faDoorClosed, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { faArrowsAltV, faEllipsisV, faSave } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowSquareDown } from '@fortawesome/pro-solid-svg-icons';
import { NbToastrService } from '@nebular/theme';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { SessionService } from '../../../../../@core/utils/session.service';
import { FlagColor } from '../../models/flag-color';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { FileUploadService } from '../../../../../../app/harvest-engine/core/services/FileUpload.service';
import { ContractService } from '../../services/contract.service';
import { Router } from '@angular/router';
import { LocationStrategy, PlatformLocation } from '@angular/common';
// import { FileReader } from 'file-api';
// import { Order } from '../../../../../customer-integrations/hollingbery/modules/financial/contracts/models/order';
// import { OrdersService } from '../../services/orders.service';

@Component({
  selector: 'popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnInit {

  row: any;

  public isLoading: boolean = false;
  // public order: Order = {};
  public files: File[] = [];
  // icons
  faSave = faSave;
  faClose = faWindowClose;
  

  constructor(
    private session: SessionService,
    private matDialog: MatDialog,
    // private orderService: OrdersService,
    private errorService: ErrorService,
    public uploadService: FileUploadService,
    public contractService: ContractService,
    private toastr: NbToastrService,
    public dialogRef: MatDialogRef<PopupModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    // private location: Location
    private locationStrategy: LocationStrategy,
    private platformLocation: PlatformLocation
  ) { }

  ngOnInit() {
    // this.order = {...this.session.order};
    this.row = this.data.row;
    // console.log(this.row)
  }
  onFileUpload(event) {
    // console.log(event.addedFiles);
    this.files = [];
    this.files.push(...event.addedFiles);
    
  }
  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  save() {
    this.isLoading = true;
    const reader = new FileReader();
    reader.readAsDataURL(this.files[0]);
    reader.onload = () => {
      const base64 = reader.result as string;
      // console.log(base64);
      // console.log([this.files[0]])
      // console.log(this.row.id)
      // console.log(this.row)
      // let payload = [base64]
      
      this.uploadService.postContractUploads([this.files[0]], this.row.id).subscribe(x => {
        // console.log(x)
        let contractDTO = this.row
          contractDTO.contractStatus = "Signed", 
          contractDTO.isLocked = true
        this.contractService.putContract(contractDTO, this.row.id).subscribe(z => {
          // console.log(z)
          this.isLoading = false;
          this.matDialog.closeAll();
        })
      }, err => { this.errorService.handleError(err); console.log(err)})
    };
    // console.log(this.row)
    // console.log(this.row.id)
   
  }

  cancel() {
    // window.location.href = window.location.href;
    this.matDialog.closeAll();
  }

}
