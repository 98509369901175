import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatBottomSheet } from '@angular/material';
import { JobDetailsDataSource } from './job-details-datasource';
import { PrecisionJobService } from '../../services/precision-job.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
import { NbToastrService } from '@nebular/theme';
import * as _ from 'underscore';
import { PrecisionCategory } from '../../models/precision-category';
import { PrecisionItem } from '../../models/precision-items';
import { faFileInvoiceDollar, faSearchDollar, faTimes, faSearch, faSave, faEdit, faCogs } from '@fortawesome/free-solid-svg-icons';
import { PrecisionJobDetail } from '../../models/precision-job-detail';
import { PrecisionStatus } from '../../models/precision-status';
import { DropboxPopupComponent, DropboxPopupData } from '../dropbox-popup/dropbox-popup.component';
import { PrecisionJob } from '../../models/precision-job';
import { UserService } from '../../../../@core/data/users.service';
import { AuthService } from '../../../../@core/auth/auth.service';
import { JobDetailBottomSheetData, JobDetailEditBottomsheetComponent } from './job-detail-edit-bottomsheet/job-detail-edit-bottomsheet.component';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../../../@core/utils/error.service';
import { trim } from 'lodash';
import { PrecisionPurchaseOrderService } from '../../services/precision-purchase-order.service';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import { PrecisionPurchaseOrderItem } from '../../models/precision-purchase-order-item';
import { PrecisionSessionService } from '../../services/precision-session.service';
import { faArrowSquareRight, faClipboard, faClipboardList } from '@fortawesome/pro-solid-svg-icons';
import * as XLSX from 'xlsx';
import { detachEmbeddedView } from '@angular/core/src/view';
import { ExcelService } from '../../../../@core/utils/excel.service';


type AOA = any[][];

@Component({
  selector: 'job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: JobDetailsDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['category'
    , 'item'
    , 'description'
    , 'fabTimeHours'
    , 'partNumber'
    , 'manufacturer'
    , 'quantity'
    , 'price'
    , 'purchaseOrder'
    , 'dueDate'
    , 'vendor'
    , 'notes'
    , 'createdBy'
    , 'status'
    , 'edit'
  ];


  faSearchDollar = faSearchDollar;
  faTimes = faTimes;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faSave = faSave;
  faSearch = faSearch;
  faEdit = faEdit;
  faCogs = faCogs;
  faArrowSquareRight = faArrowSquareRight;
  faClipboardList = faClipboardList;
  faClipboard = faClipboard;

  public recordId: number;
  public categories: string[] = [];
  public selectedCategories: string[] = [];
  public allCategoriesSelected: boolean = false;

  public isMobile: boolean = false;
  public quickbooksItems: string[] = [];

  public items: string[] = [];

  public selectedItems: string[] = [];
  public allItemsSelected: boolean = false;

  public showSelect: boolean = false;
  public isRFQ: boolean = false;
  public isPO: boolean = false;

  public allColumns: any;
  public allJobDetails: PrecisionJobDetail[] = [];
  public statuses: PrecisionStatus[] = [];
  public selectedStatus: string;

  private isNewJobDetail: boolean = false;
  private selectedJobIndex: number;

  // used to check when the bottom sheet is closed.
  private bottomSheetSubscription: Subscription;

  public editLineItem: PrecisionJobDetail;

  public isLoading: boolean = true;
  public job: PrecisionJob;

  // public purchaseOrder: PrecisionPurchaseOrder = {};

  // BOM Import
  data: AOA = [[], []];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';


  constructor(
    private precisionJobService: PrecisionJobService,
    private precisionPurchaseOrderService: PrecisionPurchaseOrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private session: PrecisionSessionService,
    private toastr: NbToastrService,
    private bottomSheet: MatBottomSheet,
    private authService: AuthService,
    private errorService: ErrorService,
    private excelService: ExcelService
  ) { }


  ngOnInit() {
    if (window.screen.width <= 576) {
      this.isMobile = true;
    }

    this.allColumns = this.displayedColumns;
    this.dataSource = new JobDetailsDataSource(this.paginator, this.sort);

    this.activatedRoute.queryParams.subscribe(params => {
      if (!params["recordId"]) {
        this.toastr.warning("We could not find that job", "Job not found");
      }
      this.recordId = params["recordId"];
    })

    this.getJobDetails(this.recordId);
  }

  addEditItem(jobDetail: PrecisionJobDetail) {

    // if the job detail is null, then it's a new job
    this.isNewJobDetail = !jobDetail ? true : false;

    // get the index of the job in the job detail list
    if (this.job.jobDetails)
      this.selectedJobIndex = this.job.jobDetails.indexOf(jobDetail);
    else
      this.selectedJobIndex = 0;

    let data: JobDetailBottomSheetData = {
      // pass the properties of the detail.  This way it's not overwriting the one on the screen if they press cancel
      jobDetail: { ...jobDetail },
      categories: this.categories.filter(x => x !== '(ALL)'),
      items: this.items.filter(x => x !== '(ALL)'),
      quickbooksItems: this.quickbooksItems.filter(x => x !== '(ALL)'),
      manufacturers: this.getManufacturers(),
      statuses: this.statuses.map(s => s.name),
      vendors: this.getVendors()


    }

    // open the bottom sheet
    this.bottomSheet.open(JobDetailEditBottomsheetComponent, { data, disableClose: false },);

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(jobDetail => {
      if (jobDetail !== null) {

        if (jobDetail.status === "") {
          jobDetail.status = 'Incomplete';
        }

        if (this.isNewJobDetail) {
          this.job.jobDetails = [jobDetail, ...this.job.jobDetails];
        } else {
          // update the detail we saved from the table
          this.job.jobDetails[this.selectedJobIndex] = jobDetail;
        }
        this.precisionJobService.putJob(this.job).subscribe(() => { }, err => this.errorService.handleError(err));
        this.allJobDetails = this.job.jobDetails;
        this.search();
        this.updateCategoriesAndItems(jobDetail);
      }
      this.bottomSheetSubscription.unsubscribe();
    });


  }

  getManufacturers(): string[] {
    let manufacturers = [];
    if (this.job.jobDetails && this.job.jobDetails.find(jd => jd && jd.manufacturer != ''))
      manufacturers = this.job.jobDetails.filter(d => d && d.manufacturer).map(d => d.manufacturer);

    manufacturers = _.uniq(manufacturers);
    manufacturers = _.sortBy(manufacturers);
    return manufacturers;
  }

  getVendors(): string[] {
    let vendors = [];
    if (this.job.jobDetails)
      vendors = this.job.jobDetails.filter(d => d && d.vendor).map(d => trim(d.vendor));

    vendors = _.uniq(vendors);
    vendors = _.sortBy(vendors);
    return vendors;
  }


  /// Used when an item has been added to make sure the categories/items are updated
  updateCategoriesAndItems(jobDetail: PrecisionJobDetail) {
    if (!this.categories.includes(jobDetail.category)) {
      this.categories.push(jobDetail.category);
    }

    if (!this.items.includes(jobDetail.item)) {
      this.items.push(jobDetail.item);
    }

  }

  getJobDetails(recordId: number) {
    this.precisionJobService.getJob(recordId).subscribe(job => {
      if (job.jobDetails) {
        job.jobDetails = _.sortBy(job.jobDetails, 'category');
        this.dataSource.data = job.jobDetails.filter(d => d && d.isActive);
        this.job = job;
        this.allJobDetails = job.jobDetails.filter(d => d && d.isActive);
        this.paginator._changePageSize(this.paginator.pageSize);
      } else if (!job.jobDetails) {
        this.dataSource.data = [];
        this.job = job;
        this.allJobDetails = [];
        this.paginator._changePageSize(this.paginator.pageSize);
      }

      this.getItems();
      this.getJobCategories();
      this.getStatuses();

    }, err => {
      this.isLoading = false;
      this.errorService.handleError(err);
    });
  }

  selectAllCategories() {
    if (this.selectedCategories.find(v => v == '(ALL)')) {
      if (!this.allCategoriesSelected) {
        this.allCategoriesSelected = true;
        this.selectedCategories = [...this.categories];
      } else {
        this.allCategoriesSelected = false;
        this.selectedCategories = this.selectedCategories.filter(x => x != '(ALL)');
      }
    } else {
      if (this.allCategoriesSelected) {
        this.allCategoriesSelected = false;
        this.selectedCategories = [];
      }
    }
  }

  getJobCategories() {
    this.precisionJobService.getCategories().subscribe(cats => {
      // get the categories in the detail
      if (this.job.jobDetails) {
        let detailCategories = this.job.jobDetails.filter(c => c && c.category).map(c => c.category);
        // combine the api categories and teh detail categories
        this.categories = [...detailCategories, ...cats.map(i => i.name)];
        // filter out duplicates
        this.categories = _.uniq(this.categories);
        // sort 
        this.categories = _.sortBy(this.categories);
        // append all to the front of the list
        this.categories = ["(ALL)", ...this.categories];
      }
    }, err => this.errorService.handleError(err));
  }

  getItems() {
    // set detail items list
    if (this.job.jobDetails) {
      let detailItems = this.job.jobDetails.filter(d => d && d.item).map(d => d.item);
      this.items = [...detailItems];
      this.items = _.uniq(this.items);
      this.items = _.sortBy(this.items);
      this.items = ["(ALL)", ...this.items];
    }

    this.precisionJobService.getItems().subscribe(items => {
      
        this.quickbooksItems = [...items.map(i => i.name)];
        this.quickbooksItems = _.uniq(this.quickbooksItems);
        this.quickbooksItems = _.sortBy(this.quickbooksItems);

        this.quickbooksItems = ["(ALL)", ...this.quickbooksItems];

    }, err => this.errorService.handleError(err));
  }

  downloadTemplate(){

    let template = [{
      'ITEM NO' : '',
      'PART NUMBER' : '',
      'DESCRIPTION': '',
      'QTY' : ''

    }];
    this.excelService.exportAsExcelFile(template, "BOM Template");
  }
  getStatuses() {
    this.precisionJobService.getStatuses().subscribe(statuses => {
      this.statuses = statuses;
      this.isLoading = false;
    }, err => this.errorService.handleError(err));
  }

  selectAllItems() {
    if (this.selectedItems.find(v => v == '(ALL)')) {
      if (!this.allItemsSelected) {
        this.allItemsSelected = true;
        this.selectedItems = [...this.items];
      } else {
        this.allItemsSelected = false;
        this.selectedItems = this.selectedItems.filter(x => x != '(ALL)');
      }
    } else {
      if (this.allItemsSelected) {
        this.allItemsSelected = false;
        this.selectedItems = [];
      }
    }
  }

  search() {

    let searchResults = [...this.allJobDetails.filter(detail => detail && (this.selectedCategories.length === 0 || this.selectedCategories.includes(detail.category) || this.selectedCategories.includes("(ALL)"))
      && (this.selectedItems.length === 0 || this.selectedItems.includes(detail.item) || this.selectedItems.includes("(ALL)"))
      && (detail.isActive || false)

    )];

    this.dataSource.data = searchResults;

    this.paginator._changePageSize(this.paginator.pageSize);
  }

  openDropbox() {
    let data: DropboxPopupData = {
      job: this.job
    }
    this.bottomSheet.open(DropboxPopupComponent, { data });
  }

  createRFQ() {
    this.isPO = false;
    this.isRFQ = true;
    this.toggleShowSelect();
  }

  createPO() {
    this.isRFQ = false;
    this.isPO = true;
    this.toggleShowSelect();
  }

  private toggleShowSelect() {
    this.showSelect = !this.showSelect;
    if (this.showSelect) {
      this.displayedColumns.push('select');
      this.displayedColumns = this.displayedColumns.filter(c => c != 'edit');

      // deselect everything
      for (let detail of this.dataSource.data) {
        detail.selected = false;
      }
    }
    else if (!this.showSelect) {
      const index: number = this.displayedColumns.indexOf('select');
      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
      this.displayedColumns.push('edit');
    }
  }

  cancel() {
    this.isRFQ = false;
    this.isPO = false;
    this.toggleShowSelect();
  }

  saveRFQ() {

    this.session.job = this.job;
    let purchaseOrder: PrecisionPurchaseOrder = {};

    purchaseOrder.job = this.job;
    purchaseOrder.jobId = this.job.recordId;

    let filteredDetails = this.job.jobDetails.filter(x => x && x.selected === true);
    purchaseOrder.lineItems = filteredDetails;

    let rfq: PrecisionPurchaseOrder = {
      isRFQ: true,
      job: this.job,
      jobId: this.job.recordId,
      isActive: false,
      lineItems: this.job.jobDetails.filter(x => x && x.selected === true).map(x => <PrecisionPurchaseOrderItem>{
        receivedQuantity: 0,
        item: x.item,
        quickbooksItem: x.quickbooksItem,
        rate: x.price,
        description: x.description,
        partNumber: x.partNumber,
        notes: x.notes,
        quantityOrdered: x.quantity
      })
    }

    this.session.precisionPurchaseOrder = rfq;
    this.router.navigateByUrl(`/precision/rfq`);

  }

  savePO() {
    //this.isLoading = true;
    this.session.job = this.job;

    let purchaseOrder: PrecisionPurchaseOrder = {};
    purchaseOrder.job = this.job;
    purchaseOrder.jobId = this.job.recordId;

    let filteredDetails = this.job.jobDetails.filter(x => x && x.selected === true);
    purchaseOrder.lineItems = filteredDetails.map(x => <PrecisionPurchaseOrderItem>{
      receivedQuantity: 0,
      item: x.item,
      rate: x.price,
      quickbooksItem: x.quickbooksItem,
      description: x.description,
      partNumber: x.partNumber,
      notes: x.notes,
      quantityOrdered: x.quantity
    });

    this.isPO = false;
    this.toggleShowSelect();
    this.session.precisionPurchaseOrder = purchaseOrder;
    this.router.navigateByUrl(`/precision/quick-po`);
    //this.isLoading = false;


  }

  setStatus(jobDetail: PrecisionJobDetail) {
    this.precisionJobService.putJob(this.job).subscribe(() => { }, err => this.errorService.handleError(err));
  }

  selectAll() {
    for (let x of this.allJobDetails) {
      x.selected = true;
    }

  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));


      let bom = this.data.slice(1, this.data.length).map(x => {
        
        let result = <PrecisionJobDetail>{
          id: this.uuidv4(),
          category: x[1],
          item: x[1],
          partNumber: x[1],
          description: x[2],
          quantity: x[3],
          status: 'Incomplete',
          isActive: true,
          quickbooksItem: 'Non-Inventory part'

        }

        let splitPart = x[1].toString().split('_');
        if (splitPart.length > 1) {
          result.category = splitPart[0];
          result.partNumber = splitPart[1];
        }

        return result;

      });

      if (this.job.jobDetails) {
        this.job.jobDetails = [...this.job.jobDetails, ...bom];
      } else {
        this.job.jobDetails = [...bom];
      }

      this.precisionJobService.putJob(this.job).subscribe(() => { }, err => this.errorService.handleError(err));
      this.allJobDetails = this.job.jobDetails;
      this.search();
      for (let det of bom) {
        this.updateCategoriesAndItems(det);
      }
    };

    //this.isImport = true;
    reader.readAsBinaryString(target.files[0]);
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
