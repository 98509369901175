import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AggregateRowModel, ColumnModel, FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'lot-product-summary',
  templateUrl: './lot-product-summary.component.html',
  styleUrls: ['./lot-product-summary.component.scss']
})
export class LotProductSummaryComponent implements OnInit, OnChanges {

  @Input() allHandlingUnits: HandlingUnitMasterDTO[] = [];
  @Input() lotId: number;
  public aggregates: AggregateRowModel[] = [];
  public handlingUnitDetails: HandlingUnitDetailDTO[] = [];

  public dateFormat = { type: 'date', format: 'MM/dd/yyyy' };

  public selectionOptions = { type: 'Multiple' };
  public columns: ColumnModel[] = [];

  public data: any[] = [];

  public pageSettings: PageSettingsModel = {
    pageSize: 10,
    pageSizes: [10, 25, 50],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    this.aggregates = [];
    let aggregate: AggregateRowModel = { columns: [] };


    this.handlingUnitDetails = this.allHandlingUnits.flatMap(x => x.handlingUnitDetails).filter(x => x.lotId == this.lotId);

    let productGroups = _.groupBy(this.handlingUnitDetails, x => x.itemName);

    this.data = _.map(productGroups, (value, key) => {
      return {
        product: key,
        qty: lodash.sum(value.map(y => y.quantity)),
        hasComponents: value[0].packageHasComponents,
        packageComponentName: value[0].packageComponentName,
        packageComponentQuantityPerPackage: value[0].packageComponentQuantityPerPackage,
        packageComponentUnitOfMeasure: value[0].packageComponentUnitOfMeasure,
        packageComponentWeight: value[0].packageComponentWeight,
        packageComponentWeightUnitOfMeasure: value[0].packageComponentWeightUnitOfMeasure,
        packageQuantityPerPallet: value[0].packageQuantityPerPallet,
        packageUnitOfMeasure: value[0].packageUnitOfMeasure,
        packageName: value[0].packageName,
        packageWeight: value[0].packageWeight,
        totalWeight: lodash.sum(value.map(y => y.quantity)) * value[0].packageWeight
      };
    });


    for (let product of this.data) {
      if (product.hasComponents && !this.columns.find(x => x.field == product.packageComponentName)) {


        // get package qty
        product[product.packageUnitOfMeasure] = Math.floor(product.qty);


        // get components
        product[product.packageComponentName] = (product.qty % 2 * product.packageComponentQuantityPerPackage);

        // weight
        product['weight'] = product.totalWeight;


      }
    }

    // set columns



    this.columns.push({
      field: 'product',
      headerText: 'Product',
      autoFit: false
    });



    let packageUnitOfMeasures = _.uniq(this.data.map(x => x.packageUnitOfMeasure).filter(x => x != ""));

    for (let packageUnitOfMeasure of packageUnitOfMeasures) {
      this.columns.push({
        field: 'qty',
        headerText: packageUnitOfMeasure,
        autoFit: true
      });

      aggregate.columns.push({
        field: 'qty',
        type: 'sum',
        format: 'N1'
      });
    }


    let uniqComponentPackageNames = _.uniq(this.data.map(x => x.packageComponentName).filter(x => x != ""));

    for (let uniqPackageName of uniqComponentPackageNames) {
      this.columns.push({
        field: uniqPackageName,
        headerText: uniqPackageName,
        autoFit: true
      });

      aggregate.columns.push({
        field: uniqPackageName,
        type: 'sum',
        format: 'N1'
      });
    }

    this.columns.push({
      field: 'totalWeight',
      headerText: 'Weight',
      autoFit: true,

      format: 'N1'
    });

    aggregate.columns.push({
      field: 'totalWeight',
      type: 'sum',
      format: 'N1'
    });

    this.aggregates.push(aggregate);


  }

  loadData() {

  }

  ngOnInit() {
  }

}
