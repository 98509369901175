import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { ErrorService } from '../../../@core/utils/error.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrecisionDropboxService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public isDownloading: boolean;

  constructor(
    private authService: AuthService,
    protected httpClient: HttpClient, 
    private errorService: ErrorService
  ) {
    this.basePath = environment.PRECISION_API_URL;
   }

  public getDropboxKey(): Observable<string> {

    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<string>(`${this.basePath}/api/dropbox`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  
}
