import {
	Component,
	OnInit,
	Input,

} from '@angular/core';

// import $ from 'jquery';
import { NbSpinnerService } from '@nebular/theme';
import { KragUtilitiesService } from '../../@core/utils/krag-utilities.service';

@Component({
	selector: 'm-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

	public hostname = window.location.hostname;
	public host = window.location.host;
	public logoImgURL;
	public bgImgURL;
	@Input() action = 'login';
	today: number = Date.now();

	constructor(
		private spinner : NbSpinnerService,
		private utilSvc: KragUtilitiesService,
	) {}

	ngOnInit(): void {
		this.spinner.load();
		// $(document).ready(function() {
		// 	$('#welcomediv').delay(2000).fadeIn(400);
		// 	$('#welcomediv2').delay(2000).fadeIn(400);
		// 	$('#logindiv').delay(2000).fadeIn(450);
		// });

		this.logoImgURL = this.getLogoImg();
		this.bgImgURL = this.getBackgroundImageURL();
	}

	public getLogoImg() : string{
		return 'Login/' + this.utilSvc.getSubdomain() + "_logo";
	}

	public getBackgroundImageURL() : string{
		return 'https://res.cloudinary.com/kragworks/image/upload/Login/' + this.utilSvc.getSubdomain() + "_bg.jpg";
	}
}
