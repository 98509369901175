import { I } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { faBarcode, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { KwFilterDropdownService } from '../../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { HandlingUnitBulkMove } from '../../../models/handling-unit-bulk-actions';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { InventorySessionService } from '../../../services/inventory-session.service';
import { InventoryHumEditComponent } from '../inventory-hum-edit/inventory-hum-edit.component';
import { NbToastrService } from '@nebular/theme';

export interface InventoryScanPopupData {
  isTransferScan?: boolean;
  isHumEdit?: boolean;
  hums?: HandlingUnitMasterDTO[];
}

@Component({
  selector: 'inventory-scan-popup',
  templateUrl: './inventory-scan-popup.component.html',
  styleUrls: ['./inventory-scan-popup.component.scss']
})
export class InventoryScanPopupComponent implements OnInit {

  // Icons //
  public faTimes = faTimes;
  public faPrint = faPrint;
  public faBarcode = faBarcode;

  public tagNumber: string = '';
  public isLoading = false;
  public tagNotFound = false;
  public handlingUnitBulkMoveList: HandlingUnitBulkMove[] = [];
  tagNumbers: string[] = [];
  public bottomSheetSubscription: Subscription;
  @ViewChild('tagNumberInput') inputField: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<InventoryScanPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filterService: KwFilterDropdownService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dialog: MatDialog,
    private inventoryService: InventoryService,
    private session: InventorySessionService,
    public toastr: NbToastrService,
  ) { }

  ngOnInit() {
    if (this.data.isMultiple) {
      this.filterService.loadAllData();
    }
    // set the focus a little after the page loads to prevent toggle from getting focus
    setTimeout(() => {
      this.inputField.nativeElement.focus();
    }, 500);

  }



  scanTag() {
    this.isLoading = true;

    let tagNumber = this.tagNumber;

    let tag: HandlingUnitMasterDTO;

    let location = this.filterService.allLocations.find(x => x.barcode == tagNumber);
    
    let room = this.filterService.allRooms.find(x => x.barcode == tagNumber);


    let warehouse = this.filterService.allWarehouses.find(x => x.barcode == tagNumber);
    if (this.data.hums && !this.data.isMultiple) {

      tag = this.data.hums.find(x => x.barcode == tagNumber);

      if (!tag && !location && !room && !warehouse) {
        if (!this.data.isHumEdit) {
          const confRef = this.dialog.open(ConfirmationDialogComponent, {
            minWidth: '10%',
            data: {
              confirmLabel: 'Yes',
              title: 'Create Inventory?',
              confirmMessage: 'Do you want to create inventory with this barcode?',
              cancelLabel: 'No'
            }
          }).afterClosed().subscribe(result => {

            if (result === true) {
              let data = {
                barcode: tagNumber,
                handlingUnitDetails: [],
              }

              if (this.data.isTransferScan) {
                this.close({ "hum": data, "isHum": true });

              } else {
                this.bottomSheet.open(InventoryHumEditComponent, { panelClass: 'bottomsheet-60-width', data });

                this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(event => {
                  if (event)
                    this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary-popup?humId=" + event.id);
                });

                this.close(null);
              }

            }
          });
        }

        // FOR AUSTIN
        //If tag isn't found, hit the hum service with "include inactive" for that barcode"
        // this.inventoryService.getInventoryHUM(this.tagNumber) <-- add includeinactive
        this.tagNotFound = true;
        return
      }

      this.tagNotFound = false;


      if (tag)
        this.close({ "hum": tag, "isHum": true });

      if (location) {
        this.filterService.selectedLocation.push(this.filterService.listOfLocations.find(x => x.id == location.id))
        this.filterService.filter();
        this.close({ "location": location, "isLocation": true });
      }


      if (room) {
        this.filterService.selectedRooms.push(this.filterService.listOfRooms.find(x => x.id == room.id))
        this.filterService.filter();
        this.close({ "room": room, "isRoom": true });
      }


      if (warehouse) {
        this.filterService.selectedWarehouses.push(this.filterService.listOfWarehouses.find(x => x.id == warehouse.id))
        this.filterService.filter();
        this.close({ "warehouse": warehouse, "isWarehouse": true });
      }

    } else if (!this.data.hums && !this.data.isMultiple) {
      let includeInactive = true
      this.inventoryService.getInventoryHUM(tagNumber, includeInactive).subscribe(x => {

        if (x && x.id != 0)
          tag = x;

        if (!tag && !location && !room && !warehouse) {
          if (!this.data.isHumEdit) {
            const confRef = this.dialog.open(ConfirmationDialogComponent, {
              minWidth: '10%',
              data: {
                confirmLabel: 'Yes',
                title: 'Create Inventory?',
                confirmMessage: 'Do you want to create inventory with this barcode?',
                cancelLabel: 'No'
              }
            }).afterClosed().subscribe(result => {

              if (result === true) {
                let data: HandlingUnitMasterDTO = {
                  barcode: tagNumber,
                  handlingUnitDetails: [],
                }

                if (this.data.isTransferScan) {
                  this.close({ "hum": data, "isHum": true });

                } else {

                  this.bottomSheet.open(InventoryHumEditComponent, { panelClass: 'bottomsheet-60-width', data });

                  this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(event => {
                    if (event)
                      this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary-popup?humId=" + event.id);
                  });

                  this.close(null);
                }


              }
            });
          }
          this.tagNotFound = true;
          return
        }

        this.tagNotFound = false;


        if (tag) {

        // CHECK TO SEE IF HUM IS ACTIVE //
        if (!tag.isActive) {
          this.tagNotFound = true;
          this.isLoading = false;
          this.toastr.warning('This Barcode is inactive', 'Error', { duration: 0 });
          return;
        }
        this.close({ "hum": tag, "isHum": true });
        }

        if (location) {
          if (!this.data.isHumEdit) {
            this.filterService.selectedLocation.push(this.filterService.listOfLocations.find(x => x.id == location.id))
            this.filterService.filter();
          }
          this.close({ "location": location, "isLocation": true });
        }


        if (room) {
          if (!this.data.isHumEdit) {
            this.filterService.selectedRooms.push(this.filterService.listOfRooms.find(x => x.id == room.id))
            this.filterService.filter();
          }
          this.close({ "room": room, "isRoom": true });
        }


        if (warehouse) {
          if (!this.data.isHumEdit) {
            this.filterService.selectedWarehouses.push(this.filterService.listOfWarehouses.find(x => x.id == warehouse.id))
            this.filterService.filter();
          }
          this.close({ "warehouse": warehouse, "isWarehouse": true });
        }
      });
    } else if (!this.data.hums && this.data.isMultiple) {
      this.inventoryService.getInventoryHUM(tagNumber).subscribe(x => {

        // if nothing was found
        if (x.id == 0 && !location && !room && !warehouse) {
          this.tagNotFound = true;
          this.isLoading = false;
          return;
        }

        // if it's a location scan
        if (x.id == 0 && (location || room || warehouse)) {
          
          let warehouseId = warehouse ? warehouse.id : 0;
          let roomId = room ? room.id : 0;
          let locationId = location ? location.id : 0;
          
          this.inventoryService.getInventoryHUMs(false, 0, null, warehouseId, roomId, locationId).subscribe(results => {
            
            for (let result of results) {
              this.addToBulkList(result);
            }

            if (results.length == 0) {
              this.tagNotFound = true;
            }
          });
        } else {
          // if it's just a regular old tag

          this.addToBulkList(x);
        }

        this.tagNotFound = false;


        this.isLoading = false;
      }, err => { console.log(err) });
    }

    this.isLoading = false;
    this.tagNumber = "";
    this.inputField.nativeElement.focus();
  }

  private addToBulkList(x: HandlingUnitMasterDTO) {
    this.handlingUnitBulkMoveList.push({
      humId: x.id,
      warehouseId: null,
      roomId: null,
      roomLocationId: null,
      roomLocationName: x.roomLocationName,
      roomName: x.roomName,
      warehouseName: x.warehouseName,
      tagNumber: x.barcode,
      details: (x.handlingUnitDetails.length > 0 ? x.handlingUnitDetails[0].itemName : ""),
      lot: (x.handlingUnitDetails.length > 0 ? x.handlingUnitDetails[0].lotNumber : ""),
      isMultiple: true,
    });

    if (!this.handlingUnitBulkMoveList[0].handlingUnitMasterIds) {
      this.handlingUnitBulkMoveList[0].handlingUnitMasterIds = [];
      this.handlingUnitBulkMoveList[0].handlingUnitMasterIds.push(x.id);
    } else {
      this.handlingUnitBulkMoveList[0].handlingUnitMasterIds.push(x.id);
    }
  }

  bulkMoveNext() {
    this.session.handlingUnitBulkMoveList = this.handlingUnitBulkMoveList;
    this.dialog.closeAll();
    this.router.navigateByUrl("/harvest-engine/inventory/inventory-bulk-move");
  }


  close(tag) {
    this.dialogRef.close(tag);
  }
}

