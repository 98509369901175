import { Component, OnInit } from '@angular/core';
import { Crop } from '../../../models/crop';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { CropService } from '../../../services/crop.service';
import { MatDialogRef } from '@angular/material';
import { SessionService } from '../../../../../@core/utils/session.service';
import { NgForm } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'crop-details',
  templateUrl: './crop-details.component.html',
  styleUrls: ['./crop-details.component.scss']
})
export class CropDetailsComponent implements OnInit {
//#region Properties
public editActive = false;
public cropLoading = true;
public editCrop: Crop = {};
public submitting: any;
//#endregion

//#region Constructor with Dependencies  
constructor(
  private errorService: ErrorService,
  public dialogRef: MatDialogRef<any>,
  private session: SessionService,
  public cropService: CropService,
  private toastrService: NbToastrService
) {}
//#endregion

ngOnInit() {
  this.editCrop = this.session.selectedCrop;
  if (
    this.editCrop === null ||
    this.editCrop === undefined ) {
    this.editCrop = {};
    this.editCrop.isActive = true;
    this.cropLoading = false;
  }
}

cancelClicked(form: NgForm, cancelled: boolean = false) {
  this.editActive = false;
  this.editCrop = {};
  form.resetForm();
  this.dialogRef.close(cancelled);
}

onSubmit() {
  this.submitting = true;
  setTimeout(()=>{
    if (this.editCrop.id) {

      this.cropService
        .putCrop(this.editCrop)
        .subscribe(
          () => {
            this.toastrService.success(
              "Crop Details successfully updated!",
              this.editCrop.name + " updated!"
            );
            this.dialogRef.close(true);
          },
          (err) => {
            this.errorService.handleError(err);
            this.dialogRef.close(false);
          }
        );
    } else {
      this.cropService.postCrop(this.editCrop).subscribe(
        () => {
          this.toastrService.success(
            "Crop Details successfully saved!",
            this.editCrop.name + " saved!"
          );
          this.dialogRef.close(true);
        },
        (err) => {
          this.errorService.handleError(err);
          this.dialogRef.close(false);
        }
      );
    }
  },1000);
}
}
