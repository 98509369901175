import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MatDialogRef, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { faArrowAltCircleLeft, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { TransactionService } from '../../../../../core/services/TransactionService';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { InventoryAddItemPopupComponent } from '../../inventory-items/inventory-add-item-popup/inventory-add-item-popup.component';
import * as _ from 'underscore';

@Component({
  selector: 'inventory-adjust-qty-popup',
  templateUrl: './inventory-adjust-qty-popup.component.html',
  styleUrls: ['./inventory-adjust-qty-popup.component.scss']
})
export class InventoryAdjustQtyPopupComponent implements OnInit {

  @Input() isHumEdit: boolean = false;
  @Input() hum: HandlingUnitMasterDTO;
  @Output() valueChange = new EventEmitter<any>();
  public transactionReasons = [];
  public reasonCodeId: number;

  public faTimes = faTimes;
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public isLoading: boolean = true;
  faTrash = faTrash;

  constructor(public dialogRef: MatBottomSheetRef<InventoryAdjustQtyPopupComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private changeDetRef: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    public transactionService: TransactionService,
    private router: Router,
    private inventoryService: InventoryService) { }

  ngOnInit() {
    if (this.hum)
      this.data.Hum = this.hum;

    this.transactionService.getTransactionReasonCodes().subscribe(data => {
      data = _.sortBy(data, 'reason');
      this.transactionReasons = data;
      this.isLoading = false;
      this.changeDetRef.detectChanges();
    }, err => {this.errorService.handleError(err); this.isLoading = false; this.changeDetRef.detectChanges();});

    
  }

  submit() {
    this.isLoading = true;
    this.data.Hum.transactionReasonCodeId = this.reasonCodeId;
    this.inventoryService.putInventoryHUM(this.data.Hum).subscribe(x => {
      this.data.Hum.transactionReasonCodeId = undefined;
      this.dialogRef.dismiss(this.data.Hum);
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  close() {
    this.dialogRef.dismiss();
  }

  onNoClick(): void {
    this.dialogRef.dismiss();
  }

  deleteItem(row) {
    let v = [];
    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Delete item?',
        confirmMessage: 'Are you sure you want to delete this item?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {
      if (result === true && !this.isHumEdit) {
        this.isLoading = true;
        this.data.Hum.handlingUnitDetails.forEach((element, index) => {
          if (element.id == row.id) {
            element.isActive = false;
            var t = [];
            this.changeDetRef.detectChanges();
          }
        });
        this.inventoryService.putInventoryHUM(this.data.Hum).subscribe(x => {
          this.data.Hum.handlingUnitDetails.forEach((element, index) => {
            if (element.id == row.id) {
              this.data.Hum.handlingUnitDetails.splice(index, 1);
              this.changeDetRef.detectChanges();
            }
            this.isLoading = false;
          });
        }, err => {
          this.errorService.handleError(err);
          this.isLoading = false;
        });
      }
      else if (result === true && this.isHumEdit) {
        this.hum.handlingUnitDetails.forEach((element, index) => {
          if (element == row) {
            this.hum.handlingUnitDetails.splice(index, 1);
          }
          this.isLoading = false;
        });

        this.changeDetRef.detectChanges();
        this.valueChange.emit(true);
      }
    });
  }

  addItem() {
    let data = {
      Hum: JSON.parse(JSON.stringify(this.data.Hum))
    }
    // open the bottom sheet
    this.bottomSheet.open(InventoryAddItemPopupComponent, { data });

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      if (job) {
        this.data.Hum = job;
      }
      bottomSheetSubscription.unsubscribe();

    });


    // const dialogRef = this.dialog.open(InventoryAddItemPopupComponent, {
    //   width: '30%',
    //   data: {
    //     Hum: this.data.Hum
    //   }
    // });

    // dialogRef.afterClosed().subscribe((confirmed: boolean) => {

    // });
  }

  getWeight(hud: HandlingUnitDetailDTO) {
    if(hud)
      return hud.quantity * hud.packageComponentQuantityPerPackage * hud.packageComponentWeight;
    else
      return 0;
  }
}
