import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrinterSelectorComponent } from './printer-selector.component';
import { NbSpinnerModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { MatFormFieldModule, MatOptionModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    PrinterSelectorComponent
  ],
  exports: [
    PrinterSelectorComponent
  ],
  entryComponents: [

  ],
  imports: [
    CommonModule,
    NbSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    FormsModule,
    MultiSelectAllModule
  ]
})
export class PrinterSelectorModule { }
