import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { faCalendarAlt, faCalendarCheck, faCalendarPlus, faChartLine, faPlus, faTags, faThLarge, faTrashAlt, faTruck, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { InventoryAddItemPopupComponent } from '../../inventory-items/inventory-add-item-popup/inventory-add-item-popup.component';
import { InventoryAdjustQtyPopupComponent } from '../inventory-adjust-qty-popup/inventory-adjust-qty-popup.component';
import { InventoryMoveLocationPopupComponent } from '../inventory-move-location-popup/inventory-move-location-popup.component';
import * as moment from 'moment';
import { faArrowAltCircleLeft, faEdit, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { InventoryStatusDTO } from '../../../models/inventory-status';
import { faExchange, faSave } from '@fortawesome/pro-duotone-svg-icons';
import { RemoveInventoryConfirmationComponent } from './remove-inventory-confirmation/remove-inventory-confirmation.component';
import { InventoryTransferService } from '../../inventory-transfer/inventory-transfer.service';
import { SessionService } from '../../../../../../@core/utils/session.service';
import { PrinterDTO } from '../../../../estimates/models/printer-dto';
import { BinTagService } from '../../../../estimates/services/bin-tag.service';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { HandlingUnitDetailEditComponent } from '../../handling-unit-detail-edit/handling-unit-detail-edit.component';

@Component({
  selector: 'inventory-summary-popup',
  templateUrl: './inventory-summary-popup.component.html',
  styleUrls: ['./inventory-summary-popup.component.scss']
})
export class InventorySummaryPopupComponent implements OnInit {

  // Icons //
  public faChartLine = faChartLine;
  public faThLarge = faThLarge;
  public faTags = faTags;
  public faTruckMoving = faTruckMoving;
  public faCalendarCheck = faCalendarCheck;
  public faCalendarAlt = faCalendarAlt;
  public faTruck = faTruck;
  faPrint = faPrint;
  public faExchange = faExchange;
  public faSave = faSave;
  faEdit = faEdit;

  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faCalendarPlus = faCalendarPlus;
  public faTrashAlt = faTrashAlt;
  public isLoading: boolean = false;
  public humId: number;
  private previousUrl: string;
  public hum: HandlingUnitMasterDTO;
  public humIsActive: boolean = true;
  public printers: PrinterDTO[] = [];
  public selectedPrinter: PrinterDTO = {};
  faPlus = faPlus;

  public newStatusId = 0;
  public newOrderNumber = "";
  

  public allStatuses: InventoryStatusDTO[] = [];
  public isPrint: boolean = false;
  public BottomSheetSubsciption: Subscription;

  constructor(
    //private pickPlanSessionService: PickPlanSessionService,
    //private pickPlanService: PickPlanService,
    private bottomSheet: MatBottomSheet,
    private errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetRef: ChangeDetectorRef,
    private toastr: NbToastrService,
    private inventoryService: InventoryService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InventorySummaryPopupComponent>,
    public authService: AuthService,
    public sessionService: SessionService,
    private binTagService: BinTagService,
    private inventoryTransferService: InventoryTransferService
    //@Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.humId){
        this.humId = params.humId;
        this.humIsActive = JSON.parse(params.isActive)
        }  else
        this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary");
    });
  }


  ngOnInit(): void {
    this.getPrinters();
    this.isLoading = true;
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        pairwise() // check it's second route load
      )
      .subscribe((e: any[]) => {
        this.previousUrl = e[0].url;
      });

    this.inventoryService.getInventoryStatuses().subscribe(x => {
      this.allStatuses = x;
    }, err => { this.errorService.handleError(err); });

    if (this.humId) {
      let includeInactive = this.humIsActive == true ? false : true;

      this.inventoryService.getInventoryHUM(this.humId.toString(), includeInactive).subscribe(x => {
        this.hum = x;
        this.newStatusId = x.inventoryStatusId;
        this.newOrderNumber = x.orderNumber;

        this.isLoading = false;
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
  }


  lotNumberClick(event) {
    if(this.authService.getCurrentUser().tenant.tenantId == 'b0ed668d-7ef2-4a23-a333-94ad278f45d7') {
      this.router.navigateByUrl('/harvest-engine/laboratory/lot-dashboard/' + event.lotId)
    } else {
      this.router.navigateByUrl('/harvest-engine/inventory/lot-dashboard/' + event.lotId)
    }
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  async removeInventory() {
    var val = await this.statusWarningPopup();

    if (val) {
      const confRef = this.dialog.open(RemoveInventoryConfirmationComponent, {
        minWidth: '10%',
        data: {
        }
      }).afterClosed().subscribe(result => {

        if (result.result == true) {

          this.isLoading = true;

          if (result.transactionReasonCodeId) {
            this.hum.transactionReasonCodeId = result.transactionReasonCodeId;
          }

          this.isLoading = true;
          this.hum.isActive = false;

          this.inventoryService.putInventoryHUM(this.hum).subscribe(x => {
            this.back();
          });
        }
      });
    }
  }

  setStatus() {
    var newStatus = this.allStatuses.find(x => x.id == this.newStatusId);
    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Update Status',
        confirmMessage: 'Are you sure you want to update the status for ' + this.hum.barcode + '?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {
      if (result == true) {
        this.hum.inventoryStatusId = this.newStatusId;
        this.hum.inventoryStatusIsLocked = newStatus.isLocked;
        this.hum.inventoryStatusIsWarning = newStatus.isWarning;
        this.hum.inventoryStatusName = newStatus.name;
        this.isLoading = true;
        this.inventoryService.putInventoryHUM(this.hum).subscribe(x => {
          this.isLoading = false;
          this.toastr.success("Successfully updated inventory", "Success");
        });
      } else {
        this.newStatusId = this.hum.inventoryStatusId;
      }
    });
  }

  updateOrder() {
    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Update Order #',
        confirmMessage: 'Are you sure you want to update the order for ' + this.hum.barcode + '?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {
      if (result == true) {
        this.hum.orderNumber = this.newOrderNumber;
        this.isLoading = true;
        this.inventoryService.putInventoryHUM(this.hum).subscribe(x => {
          this.isLoading = false;
          this.toastr.success("Successfully updated order", "Success");
        });
      } else {
        this.newOrderNumber = this.hum.orderNumber;
      }
    });
  }

  async transferInventory() {
    var val = await this.statusWarningPopup();

    if (val) {
      this.inventoryTransferService.sourceHum = this.hum;

      this.router.navigateByUrl('/harvest-engine/inventory/inventory-transfer');
    }
  }

  async adjustQty() {
    var val = await this.statusWarningPopup();

    if (val) {
      let data = {
        Hum: JSON.parse(JSON.stringify(this.hum))
      }
      // open the bottom sheet
      this.bottomSheet.open(InventoryAdjustQtyPopupComponent, { data });

      // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
      let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
        if (job) {
          this.hum = job;
        }
        bottomSheetSubscription.unsubscribe();

      });
    }

  }

  async statusWarningPopup() {
    var continueVar = true;

    if (this.hum.inventoryStatusIsLocked) {
      const confRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '10%',
        data: {
          confirmLabel: 'Ok',
          title: 'Inventory Status',
          confirmMessage: 'Inventory has a status of ' + this.hum.inventoryStatusName + ' and cannot be edited.',
          cancelLabel: 'No',
          isWarning: true
        }
      });

      confRef.afterClosed().subscribe(result => {
        continueVar = false;
      });

      await confRef.afterClosed().toPromise();
    }
    else if (this.hum.inventoryStatusIsWarning) {
      const confRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '10%',
        data: {
          confirmLabel: 'Yes',
          title: 'Inventory Status',
          confirmMessage: 'Inventory has a status of ' + this.hum.inventoryStatusName + ', would you like to continue?',
          cancelLabel: 'No'
        }
      });

      confRef.afterClosed().subscribe(result => {
        continueVar = result;
      });

      await confRef.afterClosed().toPromise();
    }

    return continueVar;
  }

  getRowQTY(row: HandlingUnitMasterDTO) {

    if (row && row.handlingUnitDetails)
      return row.handlingUnitDetails.reduce((acc, cur) => acc + cur.quantity, 0);
  }

  async moveInventory() {
    var val = await this.statusWarningPopup();

    if (val) {
      let data = {
        hum: JSON.parse(JSON.stringify(this.hum))
      }
      data.hum.isMultiple = false;
      // open the bottom sheet
      this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });

      // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
      let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
        if (job) {
          this.hum = job;
        }
        bottomSheetSubscription.unsubscribe();
        this.changeDetRef.detectChanges();

      });
    }

  }

  async addItem() {
    var val = await this.statusWarningPopup();

    if (val) {
      let data = {
        Hum: JSON.parse(JSON.stringify(this.hum)),
        summaryPopupId: this.dialogRef.id
      }
      // open the bottom sheet
      this.bottomSheet.open(InventoryAddItemPopupComponent, { data });

      // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
      let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
        if (job) {
          this.hum = job;
        }
        bottomSheetSubscription.unsubscribe();
        this.changeDetRef.detectChanges();

      });
    }

    // const dialogRef = this.dialog.open(InventoryAddItemPopupComponent, {
    //   width: '30%',
    //   data: {
    //     Hum: this.hum
    //   }
    // });

    // dialogRef.afterClosed().subscribe((confirmed: boolean) => {

    // });
  }

  duplicateInventory() {
    this.isLoading = true;
    let newHum = JSON.parse(JSON.stringify(this.hum));
    newHum.id = 0;
    newHum.handlingUnitDetails.forEach(element => {
      element.id = 0;
    });
    this.inventoryService.postInventoryHUM(newHum).subscribe(x => {
      this.isLoading = false;
      this.dialogRef.close();
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  getLocation() {
    let locationNames = [];
    if (this.hum) {

      if (this.hum.warehouseName) {
        locationNames.push(this.hum.warehouseName);
      }
      if (this.hum.roomName) {
        locationNames.push(this.hum.roomName)
      }

      if (this.hum.roomLocationName) {
        locationNames.push(this.hum.roomLocationName);
      }
    }

    return locationNames.join(", ");
  }


  convertDate(date: Date) {
    return moment(date).format('LL');
  }

  updateHUM(hum) {
    this.hum = hum;
    this.changeDetRef.detectChanges();
  }

  back() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.router.navigateByUrl('/harvest-engine/inventory/inventory-summary');
    }
  }

  reprintInventory() {
    if (this.isPrint == false) {
      this.isPrint = true;
    } else {
      this.isPrint = false;
    }
  }

  getPrinters() {
    if (this.sessionService.printers) {
      this.printers = this.sessionService.printers;
    } else if (!this.sessionService.printers) {
      this.binTagService.getPrinters().subscribe(p => {
        this.printers = p.filter(x => x.isActive);
      }, err => this.errorService.handleError(err));
    }
  }

  selectPrinter(printerId: number) {
    this.selectedPrinter = this.printers.filter(x => x.id === printerId)[0];

  }

  print() {
    this.inventoryService.reprintInventoryHUM(this.hum.id.toString(), this.selectedPrinter.id.toString()).subscribe(x => {
      this.toastr.success('Print job sent successfully', 'SUCCCESS');
    }, err => {
      this.isLoading = false;
      this.errorService.handleError(err);
    });
  }

  edit(detail: HandlingUnitDetailDTO) {
    let newHud = JSON.parse(JSON.stringify(detail));
    this.inventoryService.selectedHUD = newHud;

      this.bottomSheet.open(HandlingUnitDetailEditComponent);

      let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(x => {
        this.inventoryService.selectedHUD = undefined;
        bottomSheetSubscription.unsubscribe();

        this.inventoryService.getInventoryHUM(this.humId.toString()).subscribe(x => {
          
          this.hum = x;
          
          this.newStatusId = x.inventoryStatusId;
          this.newOrderNumber = x.orderNumber;
  
          this.isLoading = false;
        }, err => {
          this.errorService.handleError(err);
          this.isLoading = false;
        });
      });
    
  }

}
