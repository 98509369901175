import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet, MatPaginator, MatSort } from '@angular/material';
import { faCheck, faEdit, faPencilAlt, faPlusCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from '../../../../@core/utils/error.service';
import { Room } from '../../models/storage/room';
import { RoomDetail } from '../../models/storage/room-detail';
import { RoomLocation } from '../../models/storage/room-location';
import { RoomService } from '../../services/storage/room-service';
import { WarehouseService } from '../../services/storage/warehouse-service';
import { FilterSettings } from '../../widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../widgets/kw-filter-dropdown/models/filter-results';
import { RoomLocationEditComponent } from './room-location-edit/room-location-edit.component';
import { RoomLocationsDataSource } from './room-locations-datasource';
import * as _ from 'underscore';
import { Warehouse } from '../../models/storage/warehouse';
import { KwFilterDropdownService } from '../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'room-locations',
  templateUrl: './room-locations.component.html',
  styleUrls: ['./room-locations.component.css']
})
export class RoomLocationsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: RoomLocationsDataSource;
  public roomLoading = true;
  private initialLoad = true;
  public roomLocations: RoomLocation[] = [];

  public allRoomLocations: RoomLocation[] = [];
  public filterSettings: Object = {};
  public warehouses: Warehouse[] = [];
  public filter: Object = {};
  public editRoomLocation: RoomLocation = {};
  private bottomSheetSubscription: Subscription;
  public faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  public faCheck = faCheck;
  public faEdit = faEdit;
  public pageSettings: PageSettingsModel = {
    pageSize: 50
  }


  public filterResults: FilterResults;
  public dropdownSettings: FilterSettings = {

    roomSettings: {
      display: true,
      singleSelection: false,
    },
    warehouseSettings: {
      display: true,
      singleSelection: false,
    },
    locationSettings: {
      display: true,
      singleSelection: false,
    }
  };

  public faPlusCircle = faPlusCircle;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name'];

  constructor(
    private errorService: ErrorService,
    public service: RoomService,
    private changeDetRef: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    public warehouseService: WarehouseService,
    public filterService: KwFilterDropdownService
    ) {}

  ngAfterViewInit() {
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" }
    this.updateRooms();
  }

  updateRooms() {
    this.roomLoading = true;
    Observable.forkJoin([
      this.service.getRoomLocations(),
      this.warehouseService.getWarehouses()
    ]).subscribe(data => {
      this.roomLocations = data[0];
      this.allRoomLocations = data[0];
      this.warehouses = data[1];
      this.roomLoading = false;
    }, err => { this.errorService.handleError(err); this.roomLoading = false; });
  }

  getRoomName(field, data: RoomLocation, column) {
    let value = ""
    if (data.room)
      value = data.room.name;

    return value;
  }

  getWarehouseName(field, data: RoomLocation, column) {
    let value = ""
    if (data.room) {
      if (data.room.warehouse)
        value = data.room.warehouse.name;

    }

    return value;
  }

  editClicked(id: number) {
    this.editRoomLocation = { ...this.roomLocations.filter(u => u.id === id)[0] };
    this.bottomSheet.open(RoomLocationEditComponent, {
      panelClass: 'bottomsheet-60-width', data: {
        roomLocation: { ...this.editRoomLocation }
      }
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.updateRooms();
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  // getStatus(row: Room){
  //   let status = this.statuses.find(x => x.id == row.statusId)
  //   if(status)
  //     return status.value
  //   else return "";
  // }

  addRoomClicked() {
    this.editRoomLocation = {};
    this.bottomSheet.open(RoomLocationEditComponent, {
      panelClass: 'bottomsheet-60-width', data: {}
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.updateRooms();
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  deleteRoomClicked(form: NgForm) {
    this.roomLoading = true;
    if (this.editRoomLocation.id !== null && this.editRoomLocation.id !== undefined) {
      this.service.deleteRoom(this.editRoomLocation.id).subscribe(x => {
        this.roomLocations = this.dataSource.data.filter(x => x.id != this.editRoomLocation.id);
        this.dataSource.data = this.dataSource.data.filter(x => x.id != this.editRoomLocation.id);
        this.paginator._changePageSize(this.paginator.pageSize)
        this.editRoomLocation = {};
        this.roomLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
    else {
      alert("No Room Selected!!");
      this.roomLoading = false;
      form.resetForm();
    }
  }

  saveRoomClicked(form: NgForm) {
    this.roomLoading = true;
    if (this.editRoomLocation.isActive === null || this.editRoomLocation.isActive === undefined) { this.editRoomLocation.isActive = false; };
    if (this.editRoomLocation.id !== null && this.editRoomLocation.id !== undefined) {
      this.editRoomLocation.updatedDate = new Date;
      let index = this.dataSource.data.indexOf(this.dataSource.data.filter(w => w.id === this.editRoomLocation.id)[0]);
      this.service.putRoom(this.editRoomLocation).subscribe(x => {
        //this.dataSource.data[index] = this.editRoomLocation;
        this.dataSource.data = [...this.dataSource.data];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.editRoomLocation = {};
        this.roomLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
    else {
      this.editRoomLocation.id = 0;
      this.editRoomLocation.createdDate = new Date();
      this.editRoomLocation.updatedDate = this.editRoomLocation.createdDate;
      //this.editRoomLocation.locations = [];
      this.service.postRoom(this.editRoomLocation).subscribe(x => {
        // this.editRoom.warehouse = this.editWarehouse;
        //this.roomList.push(JSON.parse(JSON.stringify(x)));
        //this.dataSource.data = [...this.roomList];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.editRoomLocation = {};
        this.roomLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
  }

  cancelClicked(form: NgForm) {
    this.editRoomLocation = {};
    //this.getCustomAttributes();
    form.resetForm();
  }


  getValue(row: Room, attrId: number) {
    return row.details.find(x => x.roomCustomAttributeId == attrId).value
  }

  clearDetailValue(typeId: number) {
    //this.editRoomLocation.details.find(x => x.roomCustomAttribute.id === typeId).value = "";
  }

  getDetailName(row: Room, attrId: number) {
    return row.details.find(x => x.id == attrId).roomCustomAttribute.name
  }

  setFilterResults(results: FilterResults) {
    if (this.initialLoad) {
      this.initialLoad = false;
      this.filterResults = this.filterService.getFilterState();
    } else {
      this.filterResults = results;
    }
    this.roomLocations = _.sortBy([
      ...this.allRoomLocations.filter(x =>
        (this.filterResults.selectedWarehouses.length == 0 || this.filterResults.selectedWarehouses.map(y => y.id).includes(x.room.warehouseId)) &&
        (this.filterResults.selectedRooms.length == 0 || this.filterResults.selectedRooms.map(y => y.id).includes(x.roomId)) &&
        (this.filterResults.selectedLocations.length == 0 || this.filterResults.selectedLocations.map(y => y.id).includes(x.id))
      )
    ], x => x.name);
    this.roomLoading = false;

    this.changeDetRef.detectChanges();
  }
}
