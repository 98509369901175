import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PrecisionJob } from '../models/precision-job';
import { HttpResponse, HttpEvent, HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { PrecisionCustomer } from '../models/precision-customer';
import { PrecisionPurchaseOrder } from '../models/precision-purchase-order';

@Injectable({
  providedIn: 'root'
})
export class PrecisionPurchaseOrderService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    private authService: AuthService,
    protected httpClient: HttpClient, 
  ) {
    this.basePath = environment.PRECISION_API_URL;
   }


  public getPurchaseOrders(type: string = null): Observable<PrecisionPurchaseOrder[]> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    if(type){
      queryParameters = queryParameters.set('type', type);
    }
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionPurchaseOrder[]>(`${this.basePath}/api/purchaseorders/`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getPurchaseOrder(recordId: number): Observable<PrecisionPurchaseOrder> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters.append("recordId", recordId.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionPurchaseOrder>(`${this.basePath}/api/purchaseOrders/${recordId}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public postPurchaseOrder(pushToQb: boolean, purchaseOrder: PrecisionPurchaseOrder, sendEmail: boolean): Observable<PrecisionPurchaseOrder> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('pushToQb', pushToQb.toString());
    queryParameters = queryParameters.append('sendEmail', sendEmail.toString());
    
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<PrecisionPurchaseOrder>(`${this.basePath}/api/purchaseOrders`, purchaseOrder, 
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public putPurchaseOrder(pushToQb: boolean, purchaseOrder: PrecisionPurchaseOrder, sendEmail: boolean): Observable<PrecisionPurchaseOrder> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('pushToQb', pushToQb.toString());
    queryParameters = queryParameters.append('sendEmail', sendEmail.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<PrecisionPurchaseOrder>(`${this.basePath}/api/purchaseOrders/${purchaseOrder.recordId}`, purchaseOrder,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }
 
  public deleteRfq(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/purchaseOrders/${id}`,
      {
        headers,
      }
    );
  }


}
