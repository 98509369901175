import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Brewery } from '../models/brewery';

@Injectable({
  providedIn: 'root'
})
export class BreweryService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    this.basePath = environment.HOLLINGBERY_API_URL;

  }

  public getBreweryList(): Observable<Brewery[]> {
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Brewery[]>(`${this.basePath}/api/brewers`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getBrewery(breweryId: number): Observable<Brewery> {
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<Brewery>(`${this.basePath}/api/brewers/${breweryId}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public postBrewery(brewery: Brewery): Observable<Brewery> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post<Brewery>(`${this.basePath}/api/brewers`, brewery,

      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  public putBrewery(brewery: Brewery): Observable<Brewery> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.put<Brewery>(`${this.basePath}/api/brewers?id=${brewery.recordId}`, brewery,

      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  public deleteBrewery(brewery: Brewery): Observable<Brewery> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');

    return this.httpClient.delete<Brewery>(`${this.basePath}/api/brewers/${brewery.recordId}`,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }
}
