import { InjectionToken } from "@angular/core";

// craeted token to use in providers
export const SHARED_SERVICE: InjectionToken<MockSharedService> =
  new InjectionToken<MockSharedService>("SHARED_SERVICE");

// Interface of Shared service to remove circular dependency
export interface MockSharedService {
  openDialog(componentName: string, type: any, ...data: any[]): any;
}
