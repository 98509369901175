import { AfterViewInit, Component, Input, OnInit, Testability, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faBars, faCheck, faScanner, faSearch, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { FilterSettings } from '../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { InventoryReportService } from '../../../pick-plan/services/inventory-reports.service';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { InventoryItemReportDTO } from '../../models/item-report-dto';
import { InventoryItemReportDataSource } from './inventory-item-report-datasource';
import * as _ from 'underscore';
import { Observable } from 'rxjs/Rx';
import { InventoryItemAttributeDTO } from '../../models/item-attribute';
import { Room } from '../../../../core/models/storage/room';
import { KwFilterDropdownService } from '../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'inventory-item-report',
  templateUrl: './inventory-item-report.component.html',
  styleUrls: ['./inventory-item-report.component.scss']
})
export class InventoryItemReportComponent implements OnInit {
  @Input() public isMapPopup: boolean = false;
  @Input() public roomInput: Room;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: InventoryItemReportDataSource;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'warehouse', 'room', 'location', 'lotNumber', 'quantity'];

  // Icons //
  public faBars = faBars;
  public faSearch = faSearch;
  public faScanner = faScanner;
  public faTruck = faTruck;
  public faCheck = faCheck;
  
  public isLoading: boolean = false;
  private initialLoad: boolean = true;
  public items: InventoryItemReportDTO[] = [];
  public itemAttrabutes: InventoryItemAttributeDTO[];
  public allItems: InventoryItemReportDTO[] = [];
  public filterResults: FilterResults;

  public dropdownSettings: FilterSettings = {
    
    roomSettings: {
      display: true,
      singleSelection: false,
    },
    warehouseSettings: {
      display: true,
      singleSelection: false,
    },
    locationSettings: {
      display: true,
      singleSelection: false,
    },
    lotNumberSettings: {
      display: true,
      singleSelection: false,
    },
    inventoryItemSettings: {
      display: true,
      singleSelection: false,
    },
  };

  constructor(
    private inventoryReportService: InventoryReportService, 
    private inventoryService: InventoryService,
    private errorService: ErrorService,
    private bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    public filterService: KwFilterDropdownService
    ){}

  ngOnInit() {
    this.isLoading = true;
    this.dataSource = new InventoryItemReportDataSource(this.paginator, this.sort);
    if(this.roomInput){
      Observable.forkJoin([
        this.inventoryReportService.getInventoryItemsReport(false, this.roomInput.id),
        this.inventoryService.getInventoryItemAttributes()
        //this.roomService.getRooms()
      ])
        .subscribe(x => {
        this.allItems = x[0];
        this.items = x[0];
        this.dataSource.data = this.items;
        this.paginator._changePageSize(this.paginator.pageSize);

        this.itemAttrabutes = x[1];

        var lastCol = this.displayedColumns.pop();
        this.itemAttrabutes.forEach(x => this.displayedColumns.push(x.name));
        this.displayedColumns.push(lastCol)
        
        this.isLoading = false;

      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
    else {
      this.isLoading = true;
      Observable.forkJoin([
      this.inventoryReportService.getInventoryItemsReport(),
      this.inventoryService.getInventoryItemAttributes()
      //this.roomService.getRooms()
    ])
      .subscribe(x => {
      this.allItems = x[0];
      this.items = x[0];
      this.dataSource.data = this.items;
      this.paginator._changePageSize(this.paginator.pageSize);

      this.itemAttrabutes = x[1];

      var lastCol = this.displayedColumns.pop();
      this.itemAttrabutes.forEach(x => this.displayedColumns.push(x.name));
      this.displayedColumns.push(lastCol)

      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
    }
  }

  
  setFilterResults(results: FilterResults) {
    if(this.initialLoad){
      this.initialLoad = false;
      this.filterResults = this.filterService.getFilterState();
    } else {
      this.filterResults = results;
    }
    this.dataSource.data = _.sortBy([
      ...this.allItems.filter(x =>
        (this.filterResults.selectedWarehouses.length == 0 || this.filterResults.selectedWarehouses.map(y => y.id).includes(x.warehouseId)) &&
        (this.filterResults.selectedRooms.length == 0 || this.filterResults.selectedRooms.map(y => y.id).includes(x.roomId)) &&
        (this.filterResults.selectedLocations.length == 0 || this.filterResults.selectedLocations.map(y => y.id).includes(x.roomLocationId)) &&
        (this.filterResults.selectedInventoryItems.length == 0 || this.filterResults.selectedInventoryItems.map(y => y.id).includes(x.id)) &&
        (this.filterResults.selectedLotNumbers.length == 0 || this.filterResults.selectedLotNumbers.map(y => y.id).includes(x.lotId))
      )
    ], x => x.name);
    // this.isLoading = false;
    this.dataSource.data.sort((a,b) => a.name.localeCompare(b.name));
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  getCustFieldVal(row: InventoryItemReportDTO, attrabuteId){
    var t = row.attributes.find(x => x.inventoryItemAttributeId == attrabuteId);
    if(t && t.value)
      return t.value;
    else
      return "";
  }
}
