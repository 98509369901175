import { I, X } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ColumnModel, FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';

import * as _ from 'underscore';
import * as lodash from 'lodash';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { InventoryLotDTO } from '../../models/inventory-lot';
import { HandlingUnitMasterDTO } from '../../models/handling-unit-master';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'lot-dashboard',
  templateUrl: './lot-dashboard.component.html',
  styleUrls: ['./lot-dashboard.component.scss']
})
export class LotDashboardComponent implements OnInit {

  @ViewChild('locationGrid') locationGrid: GridComponent;
  @ViewChild('technicianGrid') technicianGrid: GridComponent;

  @ViewChild('barcodeGrid') barcodeGrid: GridComponent;
  public lotId: number;
  public isLoading: boolean = true;
  public handlingUnitsLoading: boolean = true;

  public dateFormat = { type: 'date', format: 'MM/dd/yyyy' };
  public lot: InventoryLotDTO;
  public selectionOptions = { type: 'Multiple' };

  // public massBalance: any[] = [];

  public allHandlingUnits: HandlingUnitMasterDTO[] = [];
  public activeHandlingUnits: HandlingUnitMasterDTO[] = [];
  public inActiveHandlingUnits: HandlingUnitMasterDTO[] = [];
  public shippedHandlingUnits: HandlingUnitMasterDTO[] = [];


  public locationSummaries: any[] = [];
  public technicianStats: any[] = [];

  public pageSettings: PageSettingsModel = {
    pageSize: 10,
    pageSizes: [10, 25, 50],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inventoryService: InventoryService,
    public authService: AuthService,
    private errorService: ErrorService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.lotId = +this.route.snapshot.paramMap.get('id') || 0;
    
    Observable.forkJoin([
      this.inventoryService.getLots(),
      this.inventoryService.getInventoryHUMs(true, this.lotId),
      // this.inventoryService.getLotMassBalance(this.lotId)
    ]).subscribe(x => {
      // lots
      this.lot = x[0].find(x => x.id == this.lotId);
      this.isLoading = false;

      // hums
      this.allHandlingUnits = x[1].filter(hum => hum.handlingUnitDetails.filter(hud => hud.lotId == this.lotId).length > 0 );

      this.activeHandlingUnits = this.allHandlingUnits.filter(x => x.isActive);
      this.inActiveHandlingUnits = this.allHandlingUnits.filter(x => !x.isActive);

      this.shippedHandlingUnits = this.inActiveHandlingUnits.filter(x => x.transactions.filter(y => y.transactionType == 'Handling Unit Removed' && y.reasonCode == 'Shipped').length > 0);
      
      let locationGroups = _.groupBy(this.activeHandlingUnits, x => this.getHumLocations(null, x, null));

      this.locationSummaries = _.map(locationGroups, (value, key) => {
        return { location: key, qty: lodash.sum(value.flatMap(y => y.handlingUnitDetails.filter(z => z.lotId == this.lotId)).map(y => y.quantity))};
      });

      this.handlingUnitsLoading = false;


      // mass balance
      // this.massBalance = x[2];

    }, err => {this.errorService.handleError(err); this.isLoading = false; });
  }



  public getHumLocations(field, data: HandlingUnitMasterDTO, column) {

    var list = [];
    if(data.warehouseName)
      list.push(data.warehouseName);
    
    if(data.roomName)
      list.push(data.roomName);

    if(data.roomLocationName)
      list.push(data.roomLocationName);

    return list.join(", ");
  }


  public getVarieties() {
    let varieties = _.uniq(this.allHandlingUnits.flatMap(x => x.handlingUnitDetails.filter(y => y.lotId == this.lotId)).filter(x => x.varietyName).map(y => y.varietyName));
    
    return varieties.join(', ');

  }

}
