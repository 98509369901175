import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse, HttpEvent } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { Observable } from 'rxjs';
import { Building } from '../models/support/building';
import { Color } from 'ng2-charts';
import { ContractType } from '../models/support/contract-type';
import { PackingOption } from '../models/support/packing-option';
import { ReceivingType } from '../models/support/receiving-type';
import { Owner } from '../models/support/owner';
import { BoxType } from '../models/support/box-type';
import { environment } from '../../../../environments/environment';
import { Label } from '../models/support/label';
import { Terms } from '../models/support/terms';
import { HollingberyQuickbooksCustomer } from '../models/support/hollingbery-qb-customer';

@Injectable({
  providedIn: 'root'
})
export class HollingberySupportServiceService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthService
  ) {
    this.basePath = environment.HOLLINGBERY_API_URL;

  }

  //Terms
  public getTerms(observe?: 'body', reportProgress?: boolean): Observable<Array<Terms>>;
  public getTerms(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Terms>>>;
  public getTerms(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Terms>>>;
  public getTerms(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${this.basePath}/api/terms`,
      {
        headers: headers,
      }
    );
  }


  // Building 
  public getBuildings(observe?: 'body', reportProgress?: boolean): Observable<Array<Building>>;
  public getBuildings(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Building>>>;
  public getBuildings(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Building>>>;
  public getBuildings(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${this.basePath}/api/support/building`,
      {
        headers: headers,
      }
    );
  }

  public deleteBuilding(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Building>>;
  public deleteBuilding(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Building>>>;
  public deleteBuilding(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Building>>>;
  public deleteBuilding(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiBlocksByIdPut.');
    }
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/support/building/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public putBuilding(id: number, body?: Building, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public putBuilding(id: number, body?: Building, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public putBuilding(id: number, body?: Building, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public putBuilding(id: number, body?: Building, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiBlocksByIdPut.');
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.put(`${this.basePath}/api/support/building/${encodeURIComponent(String(id))}`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public postBuilding(body?: Building, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public postBuilding(body?: Building, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public postBuilding(body?: Building, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public postBuilding(body?: Building, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post(`${this.basePath}/api/support/building/`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  // COlors 

  public getColors(observe?: 'body', reportProgress?: boolean): Observable<Array<Color>>;
  public getColors(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Color>>>;
  public getColors(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Color>>>;
  public getColors(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${this.basePath}/api/support/color`,
      {
        headers: headers,
      }
    );
  }

  public deleteColor(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Color>>;
  public deleteColor(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Color>>>;
  public deleteColor(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Color>>>;
  public deleteColor(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiBlocksByIdPut.');
    }
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/support/Color/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public putColor(id: number, body?: Color, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public putColor(id: number, body?: Color, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public putColor(id: number, body?: Color, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public putColor(id: number, body?: Color, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiBlocksByIdPut.');
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.put(`${this.basePath}/api/support/color/${encodeURIComponent(String(id))}`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public postColor(body?: Color, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public postColor(body?: Color, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public postColor(body?: Color, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public postColor(body?: Color, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post(`${this.basePath}/api/support/color/`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  // Contract Type 

  public getContractType(observe?: 'body', reportProgress?: boolean): Observable<Array<ContractType>>;
  public getContractType(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ContractType>>>;
  public getContractType(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ContractType>>>;
  public getContractType(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${this.basePath}/api/support/contracttype`,
      {
        headers: headers,
      }
    );
  }

  public deleteContractType(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ContractType>>;
  public deleteContractType(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ContractType>>>;
  public deleteContractType(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ContractType>>>;
  public deleteContractType(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/support/contracttype/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public putContractType(id: number, body?: ContractType, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public putContractType(id: number, body?: ContractType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public putContractType(id: number, body?: ContractType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public putContractType(id: number, body?: ContractType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.put(`${this.basePath}/api/support/contracttype/${encodeURIComponent(String(id))}`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public postContractType(body?: ContractType, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public postContractType(body?: ContractType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public postContractType(body?: ContractType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public postContractType(body?: ContractType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post(`${this.basePath}/api/support/contractType/`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }




  // PackingOption

  public getPackingOption(observe?: 'body', reportProgress?: boolean): Observable<Array<PackingOption>>;
  public getPackingOption(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PackingOption>>>;
  public getPackingOption(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PackingOption>>>;
  public getPackingOption(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${this.basePath}/api/support/PackingOptions`,
      {
        headers: headers,
      }
    );
  }

  public deletePackingOption(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<PackingOption>>;
  public deletePackingOption(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PackingOption>>>;
  public deletePackingOption(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PackingOption>>>;
  public deletePackingOption(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/support/PackingOptions/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public putPackingOption(id: number, body?: PackingOption, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public putPackingOption(id: number, body?: PackingOption, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public putPackingOption(id: number, body?: PackingOption, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public putPackingOption(id: number, body?: PackingOption, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.put(`${this.basePath}/api/support/PackingOptions/${encodeURIComponent(String(id))}`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public postPackingOption(body?: PackingOption, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public postPackingOption(body?: PackingOption, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public postPackingOption(body?: PackingOption, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public postPackingOption(body?: PackingOption, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post(`${this.basePath}/api/support/PackingOptions/`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  public getCropYear(observe?: 'body', reportProgress?: boolean): Observable<string>;
  public getCropYear(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
  public getCropYear(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
  public getCropYear(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      
      let headers = this.defaultHeaders;

      headers = headers.set('Authorization', String(this.authService.getUserToken()));

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
          'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [
      ];

      return this.httpClient.get(`${this.basePath}/api/system/cropyear`,
          {
              headers: headers
          }
      );
  }


  // ReceivingType

  public getReceivingType(observe?: 'body', reportProgress?: boolean): Observable<Array<ReceivingType>>;
  public getReceivingType(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReceivingType>>>;
  public getReceivingType(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReceivingType>>>;
  public getReceivingType(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${this.basePath}/api/support/ReceivingType`,
      {
        headers: headers,
      }
    );
  }

  public deleteReceivingType(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ReceivingType>>;
  public deleteReceivingType(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReceivingType>>>;
  public deleteReceivingType(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReceivingType>>>;
  public deleteReceivingType(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/support/ReceivingType/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public putReceivingType(id: number, body?: ReceivingType, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public putReceivingType(id: number, body?: ReceivingType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public putReceivingType(id: number, body?: ReceivingType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public putReceivingType(id: number, body?: ReceivingType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling.');
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.put(`${this.basePath}/api/ReceivingType/${encodeURIComponent(String(id))}`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public postReceivingType(body?: ReceivingType, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public postReceivingType(body?: ReceivingType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public postReceivingType(body?: ReceivingType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public postReceivingType(body?: ReceivingType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post(`${this.basePath}/api/support/ReceivingType/`, body,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



    // Owner

    public getOwner(observe?: 'body', reportProgress?: boolean): Observable<Array<Owner>>;
    public getOwner(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Owner>>>;
    public getOwner(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Owner>>>;
    public getOwner(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.get(`${this.basePath}/api/support/Owner`,
        {
          headers: headers,
        }
      );
    }
  
    public deleteOwner(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Owner>>;
    public deleteOwner(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Owner>>>;
    public deleteOwner(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Owner>>>;
    public deleteOwner(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling.');
      }
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.delete(`${this.basePath}/api/support/Owner/${encodeURIComponent(String(id))}`,
        {
          headers: headers,
        }
      );
    }
  
    public putOwner(id: number, body?: Owner, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public putOwner(id: number, body?: Owner, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public putOwner(id: number, body?: Owner, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public putOwner(id: number, body?: Owner, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling.');
      }
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
  
      headers = headers.set('Accept', 'application/json');
  
      headers = headers.set('Content-Type', 'application/json');
  
  
      return this.httpClient.put(`${this.basePath}/api/support/Owner/${encodeURIComponent(String(id))}`, body,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
  
    public postOwner(body?: Owner, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postOwner(body?: Owner, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postOwner(body?: Owner, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postOwner(body?: Owner, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
  
      headers = headers.set('Accept', 'application/json');
  
      headers = headers.set('Content-Type', 'application/json');
  
  
      return this.httpClient.post(`${this.basePath}/api/support/Owner/`, body,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }


    // BoxType

    public getBoxType(observe?: 'body', reportProgress?: boolean): Observable<Array<BoxType>>;
    public getBoxType(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BoxType>>>;
    public getBoxType(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BoxType>>>;
    public getBoxType(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.get(`${this.basePath}/api/support/BoxType`,
        {
          headers: headers,
        }
      );
    }
  
    public deleteBoxType(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BoxType>>;
    public deleteBoxType(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BoxType>>>;
    public deleteBoxType(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BoxType>>>;
    public deleteBoxType(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling.');
      }
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.delete(`${this.basePath}/api/support/BoxType/${encodeURIComponent(String(id))}`,
        {
          headers: headers,
        }
      );
    }
  
    public putBoxType(id: number, body?: BoxType, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public putBoxType(id: number, body?: BoxType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public putBoxType(id: number, body?: BoxType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public putBoxType(id: number, body?: BoxType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling.');
      }
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
  
      headers = headers.set('Accept', 'application/json');
  
      headers = headers.set('Content-Type', 'application/json');
  
  
      return this.httpClient.put(`${this.basePath}/api/support/BoxType/${encodeURIComponent(String(id))}`, body,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
  
    public postBoxType(body?: BoxType, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postBoxType(body?: BoxType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postBoxType(body?: BoxType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postBoxType(body?: BoxType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
  
      headers = headers.set('Accept', 'application/json');
  
      headers = headers.set('Content-Type', 'application/json');
  
  
      return this.httpClient.post(`${this.basePath}/api/support/BoxType/`, body,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    // Label

    public getLabel(observe?: 'body', reportProgress?: boolean): Observable<Array<Label>>;
    public getLabel(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Label>>>;
    public getLabel(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Label>>>;
    public getLabel(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.get(`${this.basePath}/api/support/Label`,
        {
          headers: headers,
        }
      );
    }
  
    public deleteLabel(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Label>>;
    public deleteLabel(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Label>>>;
    public deleteLabel(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Label>>>;
    public deleteLabel(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling.');
      }
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.delete(`${this.basePath}/api/support/Label/${encodeURIComponent(String(id))}`,
        {
          headers: headers,
        }
      );
    }
  
    public putLabel(id: number, body?: Label, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public putLabel(id: number, body?: Label, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public putLabel(id: number, body?: Label, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public putLabel(id: number, body?: Label, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
      if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling.');
      }
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
  
      headers = headers.set('Accept', 'application/json');
  
      headers = headers.set('Content-Type', 'application/json');
  
  
      return this.httpClient.put(`${this.basePath}/api/support/Label/${encodeURIComponent(String(id))}`, body,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }
  
    public postLabel(body?: Label, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postLabel(body?: Label, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postLabel(body?: Label, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postLabel(body?: Label, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
      ];
  
      headers = headers.set('Accept', 'application/json');
  
      headers = headers.set('Content-Type', 'application/json');
  
  
      return this.httpClient.post(`${this.basePath}/api/support/Label/`, body,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

    // QB Customer
    public getQbCustomers(): Observable<HollingberyQuickbooksCustomer[]> {
  
      // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
  
      let headers = this.defaultHeaders;
  
      headers = headers.set('Authorization', String(this.authService.getUserToken()));
  
  
      // to determine the Accept header
      let httpHeaderAccepts: string[] = [
        'application/json'
      ];
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }
  
      // to determine the Content-Type header
      const consumes: string[] = [
      ];
  
      return this.httpClient.get<HollingberyQuickbooksCustomer[]>(`${this.basePath}/api/customer`,
        {
          headers: headers,
        }
      );
    }
}
