import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog, MatPaginator, MatSort } from "@angular/material";
import {
  faCheck,
  faEye,
  faPen,
  faPlus,
  faPlusCircle,
  faTimes,
  faTrash,
  faBan
} from "@fortawesome/free-solid-svg-icons";
import { ErrorService } from "../../../../@core/utils/error.service";
import { TruckType } from "../../../modules/pick-plan/models/truck-type";
import { truckTypeService } from "../../../modules/pick-plan/services/truck-type.service";
import { Observable } from "rxjs";
import {
  ColumnModel,
  FilterSettingsModel,
  GridComponent,
  PageSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import {
  ConfirmationData,
  ConfirmationDialogComponent,
} from "../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component";
import { KmlService } from "../../../../@core/utils/kml.service";
import { NbToastrService } from "@nebular/theme";
import { SessionService } from "../../../../@core/utils/session.service";
import { TruckTypeDetailsComponent } from "./truck-type-details/truck-type-details.component";


@Component({
  selector: 'truck-type-list',
  templateUrl: './truck-type-list.component.html',
  styleUrls: ['./truck-type-list.component.scss']
})
export class TruckTypeListComponent implements OnInit {
//#region Properties
public editActive = false;
public truckTypeId: number;
public truckTypeLoading = true;
public submitting: any;
public editTruckType: TruckType = {};
public faTimes = faTimes;
public faCheck = faCheck;
public allTruckTypes: TruckType[] = [];
public allColumns: ColumnModel[] = [];
public gridName: string = "TruckTypeConfigGrid";
public pageSettings: PageSettingsModel = {
  pageSize: 50,
  pageSizes: [50, 100, 200],
};
public filterSettings: FilterSettingsModel = {
  mode: "Immediate",
  type: "Excel",
};
public toolbarOptions: string[] = [
  "ExcelExport",
];
public enablePersistence: boolean = true;
//#endregion

//#region Icon Properties
public faPlus = faPlus;
public faPen = faPen;
public faEye = faEye;
public faTrash = faTrash;
public faPlusCircle = faPlusCircle;
public faBan = faBan;
//#endregion

//#region ViewChild Properties
@ViewChild("grid") grid: GridComponent;

@ViewChild("actionTemplate")
public actionTemplate: any;

@ViewChild("isActiveTemplate")
public isActiveTemplate: any;
//#endregion
 
  //#region Constructor with dependecies
  constructor(
    private errorService: ErrorService,
    public truckTypeService: truckTypeService,
    private kmlService: KmlService,
    private toastrService: NbToastrService,
    private sessionService: SessionService,
    public dialogService: MatDialog
  ) {}
  //#endregion

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    this.truckTypeLoading = true;

    Observable.forkJoin([
      this.truckTypeService.getTruckTypes(true),
    ]).subscribe(
      (results) => {
        this.allTruckTypes = results[0];

        this.truckTypeLoading = false;
        this.setColumns();
      },
      (err) => {
        this.errorService.handleError(err);
        this.truckTypeLoading = false;
      }
    );
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Truck Type Name",
        },
        {
          field: "description",
          headerText: "Description",
        },
        {
          headerText: "Action",
          template: this.actionTemplate,
          width: 200,
        },
      ]
    );
  }

  clearFilters() {
    window.localStorage.setItem("gridBlocksConfigFilters", "");
    this.grid.clearFiltering();
  }

  toolbarClick(args) {
    this.truckTypeLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "Excel Export") {
      this.grid
        .excelExport({
          fileName: `TruckTypeExport.xlsx`,
        })
        .then(() => {
          this.truckTypeLoading = false;
        });
    }
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this truck type?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.truckTypeLoading = true;
    this.truckTypeService.deleteTruckTypeDTO(id).subscribe(
      () => {
        this.toastrService.info("Truck type successfully deleted!");
        this.truckTypeLoading = false;
        this.loadData();
      },
      (err) => {
        this.errorService.handleError(err);
        this.truckTypeLoading = false;
      }
    );
  }

  
  openDialog(IsEdit: boolean, data?: TruckType) {
    const truckTypeDetailsDialog = this.dialogService.open(TruckTypeDetailsComponent, {
      width: "500px",
    });
    if (IsEdit) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("grid" + this.gridName, persistData);
      this.sessionService.selectedTruckType = data;
    } else {
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedTruckType = {} as TruckType;
    }

    truckTypeDetailsDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedTruckType = {} as TruckType;
    });
  }
}
