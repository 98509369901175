import { Injectable } from '@angular/core';
import { HandlingUnitMasterDTO } from '../../models/handling-unit-master';

@Injectable({
  providedIn: 'root'
})
export class InventoryTransferService {

  public sourceHum: HandlingUnitMasterDTO;
  public destinationHum: HandlingUnitMasterDTO;
  
  constructor() { }
}
