import { Component, OnInit, ViewChild, Input } from '@angular/core';

import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgForm, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { BlockSelectComponent } from '../../general/block-select/block-select.component';
import { UserSelectComponent } from '../../general/user-select/user-select.component';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { NbToastrService } from '@nebular/theme';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Router } from '@angular/router';
import { FieldTaskDTO } from '../../../../../harvest-engine/modules/field-events/models/field-task';
import { FarmDTO } from '../../../../../harvest-engine/core/models/farm';
import { UserDTO } from '../../../../../harvest-engine/core/models/user';

import { FarmsService } from '../../../../../harvest-engine/core/services/farms.service';
import { FieldEventType } from '../../../../../harvest-engine/modules/field-events/models/field-event-type';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';
import { FieldEventTypesService } from '../../../../../harvest-engine/modules/field-events/services/field-event-types.service';

@Component({
  selector: 'm-field-task-input',
  templateUrl: './field-task-input.component.html',
  styleUrls: ['./field-task-input.component.scss']
})
export class FieldTaskInputComponent implements OnInit {

  constructor(
    private fieldEventTypesService: FieldEventTypesService,
    private fieldTasksService: FieldTasksService,
    private authService: AuthService,
    private farmService: FarmsService,
    private dialog: MatDialog,
    private toastr: NbToastrService,
    private errorService: ErrorService,
    private router: Router

  ) { }

  public faTimesCircle = faTimesCircle;
  public faPlusCircle = faPlusCircle;

  public farmsLoaded: boolean = false;
  public fieldTaskLoaded: boolean = false;
  public fieldEventTypesLoaded: boolean = false;
  public saveAttempted: boolean = false;

  @ViewChild('fieldTaskForm') private form: NgForm;

  fieldEventTypesSelect: FieldEventType[];
  @Input() fieldTask: FieldTaskDTO = {};
  farmList: FarmDTO[];
  // form = new FormGroup({
  //   dueDate: new FormControl('dueDate', [Validators.required]),
  //   fieldEventType: new FormControl('fieldEventType', [Validators.required]),
  //   comment: new FormControl(),
  //   startDate: new FormControl()
  // });
  isSaving: boolean = false;
  selectedUsersList: UserDTO[] = [];

  ngOnInit() {

    this.getFieldEventTypes();
    this.getFarms();
    this.fieldTask.requestedBlocks = [];
    this.getFieldTask();

  }
  
  onSubmit(formDirective: FormGroupDirective) {
    this.saveAttempted = true;

    if (!this.form.valid) {
      this.toastr.show('Please review inputs', 'Failed to save!', { status: NbToastStatus.DANGER });
    }

    if (this.form.valid) {

      this.isSaving = true;
  
      if(this.fieldTask.assignedUsers !== undefined){
        this.fieldTask.assignedUsers.length = 0;
      }
     

      this.fieldTask.assignedUsers = [];
      for (const u of this.selectedUsersList) {
        this.fieldTask.assignedUsers.push(u);
      };

      // let missing = this.fieldTask.assignedUsers.filter(item => this.selectedUsersList.indexOf(item) < 0);
      //   console.log(missing);

      this.fieldTask.isActive = true;

      if (this.fieldTask.fieldTaskId == 0 || this.fieldTask.fieldTaskId == null) {
        this.fieldTasksService.apiFieldTasksPost(this.fieldTask).subscribe(result => {

            if (result.fieldTaskId !== 0) {
              this.toastr.show('Success!', 'Field task added.', { status: NbToastStatus.SUCCESS });

              this.fieldTask.requestedBlocks = [];
              this.fieldTask.assignedUsers = [];
              this.isSaving = false;
              this.selectedUsersList = [];
              this.form.reset();
              formDirective.resetForm();
              this.saveAttempted = false;
       
            }

          }, err => {
            this.errorService.handleError(err);
          });
      } else {
        this.fieldTasksService.apiFieldTasksByIdPut(
          this.fieldTask.fieldTaskId, this.fieldTask).subscribe(result => {

            if (result.fieldTaskId !== 0) {
              this.toastr.show('Success!', 'Field task added.', { status: NbToastStatus.SUCCESS });

              this.isSaving = false;
              this.saveAttempted = false;
              this.router.navigateByUrl('/field-tasks/ft-report');
            }

          }, err => {
            this.errorService.handleError(err);
          });
      }
    }

  }

  getFieldEventTypes(): void {
    this.fieldEventTypesService.apiFieldEventTypesGet().subscribe(fieldEventTypes => {
      this.fieldEventTypesSelect = fieldEventTypes;
      this.fieldEventTypesLoaded = true;
    }, err => this.errorService.handleError(err));
  }

  getFarms(): void {
    this.farmService.apiFarmsGet().subscribe(farms => {
      this.farmList = farms;
      this.farmsLoaded = true;

    });
  }

  getFieldTask(): void {
    if(this.fieldTask.fieldTaskTypeId !== undefined){ 
    this.fieldTasksService.apiFieldTasksByIdGet(this.fieldTask.fieldTaskId).subscribe(task => {
      this.fieldTask = task;
      this.fieldTaskLoaded = true;
    });
      this.selectedUsersList = this.fieldTask.assignedUsers;
  }
}

  openBlockSelectDialog(): void {
    const dialogRef = this.dialog.open(BlockSelectComponent, {
      width: '50%',
      height: '85%',
      data: { farmList: this.farmList, selectedBlocks: this.fieldTask.requestedBlocks },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.fieldTask.requestedBlocks === undefined) {
        this.fieldTask.requestedBlocks = [];
      }
      if (result !== undefined) {
        this.fieldTask.requestedBlocks = result.selectedBlocks;
      }
    });
  }


  openUserSelectDialog(): void {
    const dialogRef = this.dialog.open(UserSelectComponent, {
      width: '40%',
      data: { selectedUserList: this.selectedUsersList }

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.selectedUsersList = result.selectedUserList;
      }
    });
  }

}
