import { Component, OnInit, Inject } from '@angular/core';
import { faChartLine, faThLarge, faTags, faTruckMoving, faCalendarCheck, faEdit, faReceipt, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { SessionService } from '../../../../../@core/utils/session.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatBottomSheet } from '@angular/material';
import { QuickPoComponent } from '../../quick-po/quick-po.component';
import { PrecisionPurchaseOrderService } from '../../../services/precision-purchase-order.service';
import { PrecisionJob } from '../../../models/precision-job';
import { NewJobPopupData, JobPopupComponent } from '../../job-popup/job-popup.component';
import { Subscription } from 'rxjs';
import { PrecisionSessionService } from '../../../services/precision-session.service';


@Component({
  selector: 'job-actions-dialog',
  templateUrl: './job-actions-dialog.component.html',
  styleUrls: ['./job-actions-dialog.component.scss']
})
export class JobActionsDialogComponent implements OnInit {

  // Icons //
  faChartLine = faChartLine;
  faThLarge = faThLarge;
  faTags = faTags;
  faTruckMoving = faTruckMoving;
  faCalendarCheck = faCalendarCheck;
  faEdit = faEdit;
  faReceipt = faReceipt;
  faBinoculars = faBinoculars

  public jobBottomSheetSubsciption: Subscription;

  constructor(
    public bottomSheet: MatBottomSheet,
    private session: PrecisionSessionService,
    private router: Router,
    private poService: PrecisionPurchaseOrderService,
    public dialogRef: MatDialogRef<JobActionsDialogComponent>,
    
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  edit() {
    this.dialogRef.close();
    let data: NewJobPopupData = {
      job: this.session.job
    }
    
    this.bottomSheet.open(JobPopupComponent, { data });

    this.jobBottomSheetSubsciption = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {

    });
  }


  toJobDetails() {
    this.dialogRef.close();
    this.session.recordId = this.session.job.recordId;
    this.session.job = this.session.job;
    this.router.navigateByUrl(`/precision/job-details?recordId=${this.session.job.recordId}`);
  }
}
