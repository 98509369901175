import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PrecisionJob } from '../models/precision-job';
import { HttpResponse, HttpEvent, HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { PrecisionCustomer } from '../models/precision-customer';

@Injectable({
  providedIn: 'root'
})
export class PrecisionCustomerService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    private authService: AuthService,
    protected httpClient: HttpClient, 
  ) {
    this.basePath = environment.PRECISION_API_URL;
   }


  public getCustomers(): Observable<PrecisionCustomer[]> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionCustomer[]>(`${this.basePath}/api/customers/`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getCustomer(recordId: number): Observable<PrecisionCustomer> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionCustomer>(`${this.basePath}/api/customers/${recordId}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }



}
