import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import { FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { InventoryBatchDTO } from '../../models/inventory-batch-dto';
import { InventoryBatchService } from '../../services/inventory-batch.service';
import { InventoryBatchActionsComponent } from '../../widgets/inventory-batch-actions/inventory-batch-actions.component';

@Component({
  selector: 'inventory-batch-list',
  templateUrl: './inventory-batch-list.component.html',
  styleUrls: ['./inventory-batch-list.component.scss']
})
export class InventoryBatchListComponent implements OnInit {

  public batches: InventoryBatchDTO[] = [];

  public faBars = faBars;
  public isLoading = true;

  public pageSettings: PageSettingsModel = {
    pageSize: 50,
    pageSizes: [50, 100, 200],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }



  constructor(
    private errorService: ErrorService,
    private batchService: InventoryBatchService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isLoading = true;
    this.batchService.getAll().subscribe(x => {
      this.batches = x;
      this.isLoading = false;
    }, err => { this.errorService.handleError(err) });
  }

  rowClicked(data) {
    this.dialog.open(InventoryBatchActionsComponent, {
      data: {
        inventoryBatch: data,
        closeDialogOnActions: true
      }
    }).afterClosed().subscribe(x => {
      this.getData();
    })

  }

}
