import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faBars, faCheck, faEdit, faPencilAlt, faPlusCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { ReasonCodesDataSource, ReasonCodesItem } from './reason-codes-datasource';
import { DataManager, ODataAdaptor, Query } from '@syncfusion/ej2-data';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { ReasonCode } from '../../models/reason-code';
import { TransactionService } from '../../../../core/services/TransactionService';
import { ReasonCodeEditComponent } from './reason-code-edit/reason-code-edit.component';

@Component({
  selector: 'reason-codes',
  templateUrl: './reason-codes.component.html',
  styleUrls: ['./reason-codes.component.css']
})
export class ReasonCodesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: ReasonCodesDataSource;

  public isLoading = true;
  public faEdit = faEdit;
  public faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  public faCheck = faCheck;
  faBars = faBars;
  public faPlusCircle = faPlusCircle;
  @ViewChild('grid') grid: GridComponent;
  public reasonCodes: ReasonCode[] = [];
  public allReasonCodes: ReasonCode[] = [];
  public data: DataManager;
  public filterSettings: Object;
  public filter: Object;
  public dateFormat = { type: 'date', format: 'MM/dd/yyyy hh:mm' };
  public pageSettings: PageSettingsModel = {
    pageSize: 50
  }

  public columns: any[] = [];
  private bottomSheetSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private errorService: ErrorService,
    public transactionService: TransactionService,
    private pelletLotChangeService: InventoryService,
    private bottomSheet: MatBottomSheet //,
    //public filterService: KwFilterDropdownService,
  ) {

  }

  ngOnInit() {
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" }
    this.updateTable();
  }

  updateTable() {
    this.isLoading = true;
    Observable.forkJoin([
      this.transactionService.getTransactionReasonCodes()
    ]).subscribe(x => {
      this.reasonCodes = x[0];
      this.allReasonCodes = x[0];
      this.isLoading = false;

    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    }
    );
  }


  public rowClicked(args): void {
    this.bottomSheet.open(ReasonCodeEditComponent, {
      data: {
        reasonCode: args
      }
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.updateTable();
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  public addCodeClicked(){
    this.bottomSheet.open(ReasonCodeEditComponent, {
      data: {
        reasonCode: {}
      }
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.updateTable();
      this.bottomSheetSubscription.unsubscribe();
    });
  }


}
