import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as _ from 'underscore';
import { PrinterDTO } from '../../../modules/estimates/models/printer-dto';
import { BinTagService } from '../../../modules/estimates/services/bin-tag.service';


@Component({
  selector: 'printer-selector',
  templateUrl: './printer-selector.component.html',
  styleUrls: ['./printer-selector.component.scss']
})
export class PrinterSelectorComponent implements OnInit {

  @Input() printer: PrinterDTO;
  @Output() printerChange: EventEmitter<PrinterDTO> = new EventEmitter<PrinterDTO>();

  @Output() selectionChanged: EventEmitter<PrinterDTO> = new EventEmitter<PrinterDTO>();

  @Input() isRequired: boolean = false;
  public searchValue: string = "";
  public isLoading = true;

  public objList: PrinterDTO[] = [];
  public allObj: PrinterDTO[] = [];

  constructor(
    private binTagService: BinTagService,
    private changeDetRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.binTagService.getPrinters().subscribe(r => {
      this.allObj = r.filter(x => x.isActive);
      this.filter();
      this.isLoading = false;
      this.changeDetRef.markForCheck();
    })
  }

  onSelectChange(){
    
    this.selectionChanged.emit(this.printer);
  }

  filter() {

    let filterList = this.allObj.filter(x => this.searchValue === ''
                                              || x.name.toLowerCase().includes(this.searchValue.toLowerCase())
                                              );
    if (filterList.length > 20)
      this.objList = [];
    else
      this.objList = _.sortBy(filterList, 'name');

    // add selected object so it shows in the list
    if (this.printer && this.printer.id !== 0) {
      if (this.objList.filter(x => x.id == this.printer.id).length === 0) {
        let selectedObj = this.allObj.filter(x => x.id == this.printer.id)[0];
        if (selectedObj) {
          this.objList.push(selectedObj);
        }
      }
    }

    this.printer = this.objList[0];
    this.selectionChanged.emit(this.printer);
  }

}
