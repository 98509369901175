import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { BinTagBatchDTO } from '../models/bin-tag-batch-dto';
import { Observable } from 'rxjs';
import { PrinterDTO } from '../models/printer-dto';
import { BinTagType } from '../models/bin-tag-type';
import { BinTagDTO } from '../../pick-plan/models/bin-tag-dto';


@Injectable({
  providedIn: 'root'
})
export class BinTagService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getBinTagTypes(): Observable<BinTagType[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<BinTagBatchDTO[]>(`${this.basePath}/api/binTagtypes`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getBinTagBatches(estimateId: number): Observable<BinTagBatchDTO[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('estimateId', estimateId.toString());
    

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<BinTagBatchDTO[]>(`${this.basePath}/api/BinTagBatches`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public reprintBinTags(printerId: number, binTagIds: number[]) {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('printerId', printerId.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<BinTagBatchDTO>(`${this.basePath}/api/BinTagBatches/reprintTags`, binTagIds,

      {
        headers,
        params: queryParameters
      }
    );
  }

  public getBinTagBatch(binTagBatchId: number): Observable<BinTagBatchDTO[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<BinTagBatchDTO[]>(`${this.basePath}/api/BinTagBatches/${binTagBatchId}`,
      {
        headers,
      }
    );
  }

  public postBinTagBatchDTO(binTag: BinTagBatchDTO): Observable<BinTagBatchDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<BinTagBatchDTO>(`${this.basePath}/api/BinTagBatches`, binTag,

      {
        headers,
      }
    );
  }

  public reprintBinTagBatch(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<BinTagBatchDTO>(`${this.basePath}/api/BinTagBatches/reprint/${id}`, '',

      {
        headers,
      }
    );
  }

  public putBinTagBatchDTO(binTag: BinTagBatchDTO): Observable<BinTagBatchDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<BinTagBatchDTO>(`${this.basePath}/api/BinTagBatches`, binTag,

      {
        headers,
      }
    );
  }

  public getPrinters(includeInactive: boolean = false): Observable<PrinterDTO[]> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrinterDTO[]>(`${this.basePath}/api/Printers/`,
      {
        headers: headers,
        
      }
    );
  }

  public deleteBinTagDTO(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/BinTagBatches/${id}`,
      {
        headers,
      }
    );
  }

  public getBinTags(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<BinTagDTO[]>(`${this.basePath}/api/binTags`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getBinTag(binTagId: number): Observable<BinTagDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<BinTagDTO>(`${this.basePath}/api/binTags/${binTagId}`,
      {
        headers,
      }
    );
  }

  public barcodeLookup(barcode: string): Observable<BinTagDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<BinTagDTO>(`${this.basePath}/api/binTags/barcodelookup/${barcode}`,
      {
        headers,
      }
    );
  }

  public postBinTag(binTag: BinTagDTO): Observable<BinTagDTO> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<BinTagDTO>(`${this.basePath}/api/binTags`, binTag,

      {
        headers,
      }
    );
  }

  public putBinTagDTO(binTag: BinTagDTO): Observable<BinTagDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<BinTagDTO>(`${this.basePath}/api/binTags/${binTag.binId}`, binTag,

      {
        headers,
      }
    );
  }

  public deleteBinTag(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/binTags/${id}`,
      {
        headers,
      }
    );
  }


}
