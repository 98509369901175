import { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss']
})
export class RefreshComponent implements OnInit, OnChanges, OnDestroy {

  @Input() timespan: number = 60000;
  @Output() elapsed: EventEmitter<any> = new EventEmitter();
  public updateSubscription;

  constructor() { }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
     this.updateSubscription = interval(this.timespan).subscribe(
      (val) => {
        //this.callbackFunction();
        this.elapsed.emit();
      }
    );
  }

  ngOnInit() {
  }

}
