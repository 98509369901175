import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';

import { Observable, of } from 'rxjs';
import { InventoryLotDTO } from '../../inventory/models/inventory-lot';

export interface lotInventory {
    lotId?: number,
    handlingUnitMasterId?: number[],
    handlingUnitDetailId?: number[],
    quantity?: number,
    toLocationId?: number, 
    toWarehouseId?: number,
    toRoomId?: number,
    reasonCodeId?: number,  
    orderNumber?: string,

    // front-end only
    originalQuantity?: number
    packoutCode?: string
}

@Injectable({
  providedIn: 'root'
})
export class lotInventoryService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public lotInventoryAdd(lotInventoryObj: lotInventory): Observable<lotInventory> {

    // return of(lotInventoryObj);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<lotInventory>(`${this.basePath}/api/inventory/ChangeByLot/add`, lotInventoryObj,

      {
        headers,
      }
    );
  }

  public lotInventoryRemove(lotInventoryObj: lotInventory): Observable<lotInventory> {

    // return of(lotInventoryObj);


    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<lotInventory>(`${this.basePath}/api/inventory/ChangeByLot/remove`, lotInventoryObj,

      {
        headers,
      }
    );
  }

  public lotInventoryTransfer(lotInventoryObj: lotInventory): Observable<lotInventory> {

    // return of(lotInventoryObj);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<lotInventory>(`${this.basePath}/api/inventory/ChangeByLot/move`, lotInventoryObj,

      {
        headers,
      }
    );
  }

  public lotInventoryAddToOrder(lotInventoryObj: lotInventory): Observable<lotInventory> {

    // return of(lotInventoryObj);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<lotInventory>(`${this.basePath}/api/inventory/ChangeByLot/moveToOrder`, lotInventoryObj,

      {
        headers,
      }
    );
  }

  public getLotById(lotId: string): Observable<InventoryLotDTO> {

    // return of(lotInventoryObj);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<InventoryLotDTO>(`${this.basePath}/api/inventory/lots/${lotId}`,

      {
        headers,
      }
    );
  }

}
