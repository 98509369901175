import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatBottomSheet, MatBottomSheetRef, MatDialog } from '@angular/material';
import { JobListDataSource } from './job-list-datasource';
import { PrecisionJobService } from '../../services/precision-job.service';
import { PrecisionJob } from '../../models/precision-job';
import { faWrench, faCalendarPlus, faTimes, faBars, faSearch, faPrint, faWindowClose, faSave, faEdit, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
import { JobPopupComponent, NewJobPopupData } from '../job-popup/job-popup.component';
import { ErrorService } from '../../../../@core/utils/error.service';
import { Subscription } from 'rxjs';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import { PrecisionSessionService } from '../../services/precision-session.service';
import * as _ from 'underscore';
import { JobActionsDialogComponent } from './job-actions-dialog/job-actions-dialog.component';
import { PurchaseOrderDialogComponent } from './purchase-order-dialog/purchase-order-dialog.component';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})
export class JobListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: JobListDataSource;

  displayedColumns = ['jobNumber', 'status', 'customerName', 'description', 'stakeholder', 'jobType', 'customerPONumber', 'comments', 'pos', 'inQb', 'actions'];

  faTimes = faTimes;
  faCheck = faCheck;
  faEdit = faEdit;
  faBinoculars = faBinoculars;
  faWrench = faWrench;
  faCalendarPlus = faCalendarPlus;
  faBars = faBars;

  public isLoading: boolean = false;
  public allJobs: PrecisionJob[] = [];
  public searchValue: string = '';
  public jobBottomSheetSubsciption: Subscription;
  public isMobile: boolean = false;
  public jobStatusList: string[] = ['(ALL)', 'Open', 'Ready to Bill', 'Closed'];
  public searchStatus: string = "Open";
  constructor(
    private precisionJobService: PrecisionJobService,
    private router: Router,
    private session: PrecisionSessionService,
    private bottomSheet: MatBottomSheet,
    private errorService: ErrorService,
    private matDialog: MatDialog,
  ) { }

  // ngAfterViewInit() {
  //   this.dataSource = new JobListDataSource(this.paginator, this.sort);
  // }

  ngOnInit() {

    
    this.dataSource = new JobListDataSource(this.paginator, this.sort);
    this.loadData();

  }

  loadData(){

    this.isLoading = true;
    this.precisionJobService.getJobs().subscribe(jobs => {
      jobs = _.sortBy(jobs, 'createdDate').reverse();
      this.allJobs = jobs;
      this.search();
      this.isLoading = false;
    }, err => { this.errorService.handleError(err) });
  }

  statusChanged(status:string) {
    this.searchStatus = status;
    this.search();
  }


  search() {
    let filteredJobs: PrecisionJob[] = JSON.parse(JSON.stringify(this.allJobs));
    
    filteredJobs = filteredJobs.filter(fj =>
      (fj.customerName && fj.customerName.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
      || fj.jobNumber && fj.jobNumber.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
      || fj.stakeholder && fj.stakeholder.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
      || fj.clientPONumber && fj.clientPONumber.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
      || fj.jobType && fj.jobType.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
      || fj.description && fj.description.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1)
      
      && ((!fj.status ? 'Open' : fj.status) == this.searchStatus || this.searchStatus == '(ALL)'));

    this.dataSource.data = filteredJobs;

    this.paginator._changePageSize(this.paginator.pageSize);

  }



  createNewJob() {
    this.router.navigateByUrl(`/precision/new-job`);
  }

  // toJobDetails(row: PrecisionJob) {
  //   this.session.recordId = row.recordId;
  //   this.session.job = row;
  //   this.router.navigateByUrl(`/precision/job-details?recordId=${row.recordId}`);
  // }

  toCustomerPO(row: PrecisionJob) {
    this.session.recordId = row.recordId;
    // Add Selected PO to Session
    this.router.navigateByUrl(`/precision/purchase-order?recordId=${row.recordId}`);
  }

  toQuickPO(row: PrecisionJob, po: PrecisionPurchaseOrder) {
    this.session.recordId = row.recordId;
    this.session.job = row;
    this.session.precisionPurchaseOrder = po;
    this.router.navigateByUrl(`/precision/quick-po?recordId=${po.recordId}`);
  }

  // toEditJob(job: PrecisionJob) {
  //   let data: NewJobPopupData = {
  //     job: { ...job }
  //   }
  //   this.bottomSheet.open(JobPopupComponent, { data });

  //   this.jobBottomSheetSubsciption = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
  //     if(job) {
  //       let updateItem = this.dataSource.data.find(j => j.recordId == job.recordId);
  //       if(job.isActive) {
  //         let updateItemIndex = this.dataSource.data.indexOf(updateItem);
  //         this.dataSource.data[updateItemIndex] = { ...job };

  //         this.paginator._changePageSize(this.paginator.pageSize);
  //         this.jobBottomSheetSubsciption.unsubscribe();
  //       } else {
  //         this.dataSource.data = this.allJobs.filter(x => x.recordId !== job.recordId);
  //         this.paginator._changePageSize(this.paginator.pageSize);
  //         this.jobBottomSheetSubsciption.unsubscribe();
  //       }

  //     }

  //   });
  // }

  openNewJobPopup() {
    let data: NewJobPopupData = {
      job: {}
    }
    this.bottomSheet.open(JobPopupComponent, { data });

    this.jobBottomSheetSubsciption = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      if (job) {
        this.loadData();
        this.jobBottomSheetSubsciption.unsubscribe();
      }

    });
  }

  openDialog(row: PrecisionJob) {
    this.session.job = row;
    this.matDialog.open(JobActionsDialogComponent);
  }

  openPoDialog(row: PrecisionJob) {
    this.session.job = row;
    this.matDialog.open(PurchaseOrderDialogComponent);
  }

}
