import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { UsersService } from '../../../../../@core/services/users.service';
import { UserDTO } from '../../../../../harvest-engine/core/models/user';

@Component({
  selector: 'm-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  constructor(
    private userService : UsersService,
    private authService : AuthService,
    private ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<UserSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserSelectData
  ) { }

  isLoaded : boolean = false;
  userList : Array<any> = [];
  selectedUserList : UserDTO[] = [];

  ngOnInit() {

    this.selectedUserList = this.data.selectedUserList;

    this.userService.apiUsersGet().subscribe(users => {
      this.userList = users;

      for(var b of this.userList){
        if(this.selectedUserList.some(x => x.platformUserId == b.platformUserId)){
          b.selected = true;
        }
        else{
          b.selected = false;
        }
      }

      this.isLoaded = true;
      this.ref.detectChanges();
    });

  }


  onUserSelect(event, user : UserDTO){
    if(!event.checked){
      this.addSelectedUser(user);
    }
    else{
      this.removeSelectedUser(user);
    }
  }

  removeSelectedUser(user: UserDTO): any {
    var userExists = false;
    for(var i = this.selectedUserList.length -1; i >= 0 ; i--){
        if(this.selectedUserList[i].platformUserId == user.platformUserId){
          userExists = true;
        }

    }

    if(!userExists){
      this.selectedUserList.push(user);

    }

  }

  addSelectedUser(user: UserDTO): any {
    for(var i = this.selectedUserList.length -1; i >= 0 ; i--){
      if(this.selectedUserList[i].platformUserId == user.platformUserId){
        this.selectedUserList.splice(i, 1);
      }
  }
  }

  saveAndClose(){
    this.dialogRef.close({ selectedUserList: this.selectedUserList });
  }

  close() {
    this.dialogRef.close();
  }
}

export interface UserSelectData{
  selectedUserList: UserDTO[];
}

