import { T } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { interval, Subject, Subscription, timer } from 'rxjs';

@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  @Output() tick: EventEmitter<number> = new EventEmitter<number>();

  @Output() start: EventEmitter<any> = new EventEmitter<any>();

  @Output() stop: EventEmitter<any> = new EventEmitter<any>();

  public startDate = new Date();

  constructor(
    private changeDetRef: ChangeDetectorRef){
      
    }


  public stopedAtDateTime = new Date();

  public pausedAt = 0;

  public currentSecond = 0;
  public hasBeenPaused = false;
  // public dDay = new Date('Jan 01 2021 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public isLoading = true;
  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  public isRunning: boolean = true;

  private timeElapsed = 0;
  private timer = null;

  private step: number;

  update = new Subject<number>();

  private getTimeDifference() {

    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor(timeDifference % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnInit() {
    this.timeElapsed = 0;
    this.step = 50;

    this.startT();
    
    // this.subscription = interval(1000)
    //   .subscribe(x => {
    //     if (this.isRunning) {
    //       this.currentSecond++;
    //       this.getTimeDifference();
    //       this.isLoading = false;
          
    //     }
    //   });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  stopTimer() {
    this.stopedAtDateTime = new Date();
    this.isRunning = false;
    this.stop.emit(null);
  }

  startT() {
    this.timer = timer(1000 , 1000);
    this.start.emit(null);
    this.subscription = this.timer.subscribe(timeElapsed => {
      this.isLoading = false;
      if(this.isRunning == false)
        this.timeDifference = timeElapsed + this.timeDifference;
      else if(this.hasBeenPaused)
        this.timeDifference = timeElapsed + this.pausedAt;
      else
        this.timeDifference = timeElapsed;
      this.isRunning = true;
      this.getTimeDifference();
      this.tick.emit(timeElapsed);
    });
    this.changeDetRef.detectChanges();
  }

  pause() {
    this.isRunning = false;
    this.changeDetRef.detectChanges();
    this.stop.emit(null);
    if (this.timer) {
      this.pausedAt = this.timeDifference;
      this.subscription.unsubscribe();
      this.hasBeenPaused = true;
      this.timer = null;
    } else {
      this.startT();
    }
  }

  stopT() {
    if (this.timer) {
      this.subscription.unsubscribe();
      this.timer = null;
    }
  }

}
