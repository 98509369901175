import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Room } from '../../models/storage/room';
import * as _ from 'underscore';
import { RoomService } from '../../services/storage/room-service';
import { ErrorService } from '../../../../@core/utils/error.service';


@Component({
  selector: 'room-selector',
  templateUrl: './room-selector.component.html',
  styleUrls: ['./room-selector.component.scss']
})
export class RoomSelectorComponent implements OnInit, OnChanges {

  @Input() room: Room;
  @Input() cropYearId: number;
  @Input() isBackupRoom: boolean;

  @Output() roomChange: EventEmitter<Room> = new EventEmitter<Room>();

  @Output() selectionChanged: EventEmitter<Room> = new EventEmitter<Room>();

  @Input() selectedWarehouseId: number = 0;
  @Input() selectedRoomId: number;

  @Input() isRequired: boolean = false;
  public searchValue: string = "";
  public isLoading = true;

  public objList: Room[] = [];
  public allObj: Room[] = [];
  public filterList: Room[] =[];

  constructor(
    private roomService: RoomService,
    private changeDetRef: ChangeDetectorRef,
    private errorService: ErrorService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter();
  }

  ngOnInit() {    

    this.roomService.getRooms(false, this.cropYearId).subscribe(r => {
      this.allObj = r;
      this.filter();
      if(this.selectedRoomId)
        this.room = this.allObj.find(x => x.id == this.selectedRoomId)
      this.isLoading = false;
      this.changeDetRef.markForCheck();
    }, err => this.errorService.handleError(err));
  }

  onSelectChange(){
    
    this.selectionChanged.emit(this.room);
  }

  clearSelection(){
    this.room = {};
  }

  filter() {

    this.filterList = [];
    this.filterList = this.allObj.filter(x => (this.searchValue === ''
                                              || (x.name.toLowerCase().includes(this.searchValue.toLowerCase())
                                              || x.warehouse.name.toLowerCase().includes(this.searchValue.toLowerCase())))
                                              && (!this.selectedWarehouseId || x.warehouseId === this.selectedWarehouseId)
                                              );

    if (this.filterList.length > 50)
      this.objList = [];
    else
      this.objList = _.sortBy(this.filterList, x => x.name.toLowerCase());

    // add selected room so it shows in the list
    if (this.room && this.room.id !== 0) {
      if (this.objList.filter(x => x.id == this.room.id).length === 0) {
        let selectedObj = this.allObj.filter(x => x.id == this.room.id)[0];
        if (selectedObj) {
          this.objList.push(selectedObj);
        }
      }
    }
  }

}