import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'm-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  confirmationData: ConfirmationData = {
    cancelLabel: '',
    confirmLabel: '',
    confirmMessage: '',
    title: '',
    displayComment: false,
    isWarning: false
  };

  public isLoading: boolean = false;

  public comments: string;

  ngOnInit(): void {

  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData,
    private dialog: MatDialogRef<ConfirmationDialogComponent>,
  ) {

    if (data) {
      this.confirmationData.confirmLabel = data.confirmLabel || 'Yes';
      this.confirmationData.cancelLabel = data.cancelLabel || 'Cancel';
      this.confirmationData.confirmMessage = data.confirmMessage || 'Are you sure you want to do this?';
      this.confirmationData.title = data.title || 'Confirm';
    } else {
      this.confirmationData.confirmLabel = 'Yes';
      this.confirmationData.cancelLabel = 'Cancel';
      this.confirmationData.confirmMessage = 'Are you sure you want to do this?';
      this.confirmationData.title = 'Confirm';
    }
  }

  close(result: any) {
    if (result) {
      this.isLoading = result;
      setTimeout(() => {
        if (this.data.displayComment) {
          var obj = {
            result: result,
            comment: this.comments
          }
          this.dialog.close(obj);
        }
        else {
          this.dialog.close(result);
        }
        this.isLoading = false;
      }, 1000)
    } else {
      if (this.data.displayComment) {
        var obj = {
          result: result,
          comment: this.comments
        }
        this.dialog.close(obj);
      }
      else {
        this.dialog.close(result);
      }
    }
  }

}

export interface ConfirmationData {
  confirmMessage: string;
  confirmLabel: string;
  cancelLabel: string;
  title: string;
  displayComment: boolean;
  isWarning: boolean;
}
