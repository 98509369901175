import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
// import { Order } from '../../models/order';
import { ContractDTO } from '../models/contract';


export class ContractTableDataSource extends DataSource<ContractDTO> {
  data: ContractDTO[] = [];

  constructor(private paginator: MatPaginator, private sort: MatSort) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<ContractDTO[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    // Set the paginator's length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: ContractDTO[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: ContractDTO[]) {
    if (!this.sort.active || this.sort.direction === '') {
      // console.log('sort not active')
      // return data;
      return data.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';
        return compare(a.effectiveDate, b.effectiveDate, isAsc);
      })
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'customerName': return compare(a.customerName, b.customerName, isAsc);
        case 'contractType': return compare(a.contractTemplateName, b.contractTemplateName, isAsc);
        case 'contractYear': return compare(a.contractYear, b.contractYear, isAsc);
        case 'expirationDate': return compare(a.expirationDate, b.expirationDate, isAsc);
        case 'effectiveDate': return compare(a.effectiveDate, b.effectiveDate, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        case 'salesRepName': return compare(a.salesRepName, b.salesRepName, isAsc);
        // case 'salesRep': return compare(a.salesRep, b.salesRep, isAsc);
        default: return 0;
      }

    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
