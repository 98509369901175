import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { AuthService } from "../../../../@core/auth/auth.service";
import { Observable } from "rxjs";
import { PurchaseOrder } from "../models/purchaseOrder";
import { HandlingUnitMasterDTO } from "../../inventory/models/handling-unit-master";

@Injectable({
  providedIn: "root",
})
export class PurchaseOrderService {
  protected basePath = "/";
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  //Create purchase order with erp flag
  public createPO(
    po: PurchaseOrder,
    saveERPFlag?: boolean
  ): Observable<PurchaseOrder> {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();
    if (saveERPFlag){
      queryParameters = queryParameters.set(
        "saveERPFlag",
        saveERPFlag.toString()
      );
    }

    return this.httpClient.post<any>(`${this.basePath}/api/purchaseorder`, po, {
      params: queryParameters,
      headers,
    });
  }

  //Update purchase order with erp flag false default for now
  public updatePO(
    po: PurchaseOrder,
    saveERPFlag?: boolean
  ): Observable<PurchaseOrder> {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();
    if (saveERPFlag){
      queryParameters = queryParameters.set(
        "saveERPFlag",
        saveERPFlag.toString()
      );
    }

    return this.httpClient.put<any>(
      `${this.basePath}/api/purchaseorder/${po.id}`,
      po,
      {
        params: queryParameters,
        headers,
      }
    );
  }

  //To get all active POs
  getPOList() {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get(`${this.basePath}/api/purchaseorder/getAll`, {
      headers: headers,
    });
  }

  //Delete purchase order based on Id
  public deletePurchaseOrder(poId: number, saveERPFlag?: boolean) {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    let queryParameters = new HttpParams();
    if (saveERPFlag){
      queryParameters = queryParameters.set(
        "saveERPFlag",
        saveERPFlag.toString()
      );
    }

    return this.httpClient.delete(
      `${this.basePath}/api/purchaseorder/${poId}`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

    //To get all active POs by latest offer no
    getPOfferNo() {
      let headers = this.defaultHeaders;
      headers = headers.set(
        "Authorization",
        String(this.authService.getUserToken())
      );
  
      return this.httpClient.get(`${this.basePath}/api/purchaseorder/GetPOByLatestOfferNo`, {
        headers: headers,
      });
    }

    //create purchase receipt
    public createPR(po: HandlingUnitMasterDTO[],saveERPFlag?: boolean): Observable<HandlingUnitMasterDTO[]> {
      let headers = this.defaultHeaders;
      headers = headers.set(
        "Authorization",
        String(this.authService.getUserToken())
      );
  
      const httpHeaderAcceptSelected: string | undefined = "application/json";
      if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set("Accept", httpHeaderAcceptSelected);
      }
  
      let queryParameters = new HttpParams();
      if (saveERPFlag){
        queryParameters = queryParameters.set(
          "saveERPFlag",
          saveERPFlag.toString()
        );
      }
  
      return this.httpClient.post<any>(`${this.basePath}/api/purchaseorder/createpurchasereceipt`, po, {
        params: queryParameters,
        headers,
      });
    }
}
