export const KW_COLORS = {
    red: '#89051f',
    white: '#edffec',
    blue: '#253d5b',
    grey: '#5c5b60',
    green: '#3f4b3b',
    lightGray: '#d3d3d3',
    binsReceived: '#6f4e7c',
    capacity: '#ff9f56',
    planned: '#0884a5',
}
