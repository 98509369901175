import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { faTags, faTicket, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { TransactionService } from '../../../../core/services/TransactionService';
import { PrinterDTO } from '../../../estimates/models/printer-dto';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { InventoryBatchDTO } from '../../models/inventory-batch-dto';
import { InventoryBatchService } from '../../services/inventory-batch.service';

export interface InventoryBatchActionsDialogData {
  inventoryBatch: InventoryBatchDTO,
  closeDialogOnActions: boolean
}

@Component({
  selector: 'inventory-batch-actions',
  templateUrl: './inventory-batch-actions.component.html',
  styleUrls: ['./inventory-batch-actions.component.scss']
})
export class InventoryBatchActionsComponent implements OnInit {

  public faTags = faTags;
  public faTrash = faTrash;
  public faTicket = faTicket;

  public printer: PrinterDTO;
  public isPrinting = false;
  public isLoading = false;
  public reasonCodeId: number;
  public isVoid = false;
  public transactionReasons = [];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public authService: AuthService,
    public dialogRef: MatDialogRef<InventoryBatchActionsComponent>,
    private batchService: InventoryBatchService,
    private errorService: ErrorService,
    private transactionService: TransactionService,
    @Inject(MAT_DIALOG_DATA) public data: InventoryBatchActionsDialogData) {}

  ngOnInit() {
    this.isLoading = true;
    this.transactionService.getTransactionReasonCodes().subscribe(x => {
      this.transactionReasons = x;
      this.isLoading = false;
    }, err => { this.errorService.handleError(err); this.isLoading = false; })
  }

  reprintTags() {
    this.isLoading = true;
    this.batchService.reprintBatch(this.data.inventoryBatch.id, this.printer.id).subscribe(x => {
      this.isLoading = false;

      if(this.data.closeDialogOnActions)
        this.dialogRef.close("reprint");

    }, err => { this.errorService.handleError(err); this.isLoading = false; });

  }

  setPrinter(printer: PrinterDTO){
    this.printer = printer;
  }



  voidBatch() {

    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Void Batch?',
        confirmMessage: !this.data.inventoryBatch.batchDetails || !this.data.inventoryBatch.batchDetails[0] ? `This action will remove all barcodes from this batch from inventory.  Are you sure you want to continue?`
         : `This action will remove ${this.data.inventoryBatch.batchDetails[0].availableQuantity}
         ${this.data.inventoryBatch.batchDetails[0].availableQuantity == 1 ? 'barcode' : 'barcodes'} for lot ${this.data.inventoryBatch.batchDetails[0].lotNumber} from inventory. Are you sure you want to void this batch?`,
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {

      if (result === true) {
        this.isLoading = true;
        this.batchService.voidBatch(this.data.inventoryBatch.id, this.reasonCodeId).subscribe(x => {
          this.isLoading = false;
          this.dialogRef.close("void");
        }, err => { this.errorService.handleError(err); this.isLoading = false; })
      
      }
    });
  }

}
