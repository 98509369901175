import { OnInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { PoLineItemsDataSource } from './po-line-items-datasource';
import { PrecisionJobService } from '../../../services/precision-job.service';
import { SessionService } from '../../../../../@core/utils/session.service';
import { PrecisionSessionService } from '../../../services/precision-session.service';

@Component({
  selector: 'po-line-items',
  templateUrl: './po-line-items.component.html',
  styleUrls: ['./po-line-items.component.css']
})
export class PoLineItemsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: PoLineItemsDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name'];

  constructor(
    private precisionJobService: PrecisionJobService,
    private session: PrecisionSessionService,

  ) { }

  ngOnInit() {
    this.dataSource = new PoLineItemsDataSource(this.paginator, this.sort);

    if(this.session.precisionJobDetails) {
      this.dataSource.data = this.session.precisionJobDetails;
    } else {
      // getPo();
    }

  }

  getPo() {
    // need to subscribe to po
  }
}
