import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CropYearService } from '../../services/crop-year.service';
import * as _ from 'underscore';
import { CropYear } from '../../models/cropYear';

@Component({
  selector: 'crop-year-dropdown',
  templateUrl: './crop-year-dropdown.component.html',
  styleUrls: ['./crop-year-dropdown.component.scss']
})
export class CropYearDropdownComponent implements OnInit {

  @Output() cropYear = new EventEmitter<CropYear>();
  @Input() cropYearInput: CropYear;

  public selectedCropYear: CropYear; 
  public cropYearList: CropYear[] = [];

  constructor(
    private cropYearService: CropYearService,
  ) { }


  ngOnInit() {
    this.cropYearService.getCropYears().subscribe(x => {
      x = _.sortBy(x, 'year').reverse();
      this.cropYearList = x

      for(let y of this.cropYearList) {
        if(y.isCurrentYear) {
          this.selectedCropYear = y;
          this.cropYear.emit(this.selectedCropYear);
        }
      }
    })
  }

  emitCropYear() {
    this.cropYear.emit(this.selectedCropYear);
  }

}
