import { Component, OnInit } from '@angular/core';
import { Carrier } from '../../../../modules/pick-plan/models/carrier';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { carrierService } from '../../../../modules/pick-plan/services/carrier.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionService } from '../../../../../@core/utils/session.service';
import { NgForm } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { TruckType } from '../../../../modules/pick-plan/models/truck-type';
import { truckTypeService } from '../../../../modules/pick-plan/services/truck-type.service';

@Component({
  selector: 'carrier-edit-list',
  templateUrl: './carrier-edit-list.component.html',
  styleUrls: ['./carrier-edit-list.component.scss']
})
export class CarrierEditListComponent implements OnInit {

  //#region Properties
  public editActive = false;
  public carrierLoading = true;
  public editCarrier: Carrier = {};
  public editTruck: TruckType = {};
  public truckTypes: TruckType[] = [];
  public submitting = false;
  //#endregion

  //#region Constructor with Dependencies  
  constructor(
    private errorService: ErrorService,
    public carrierService: carrierService,
    public dialogRef: MatDialogRef<any>,
    private session: SessionService,
    public truckTypeService: truckTypeService,
    private toastrService: NbToastrService
  ) {}
  //#endregion

  ngOnInit() {
    this.editCarrier = this.session.selectedCarrier || { isActive: true };
    this.editTruck = this.session.selectedTruck || { isActive: true };
    this.carrierLoading = false;
    this.loadTruckTypes();
  }

  private loadTruckTypes() {
    this.truckTypeService.getTruckTypes().subscribe(
      (data) => {
        this.truckTypes = data;
      },
      (err) => {
        this.errorService.handleError(err);
      }
    );
  }

  cancelClicked(form: NgForm, cancelled: boolean = false) {
    this.editActive = false;
    this.editCarrier = {};
    form.resetForm();
    this.dialogRef.close(cancelled);
  }

  saveCarrierClicked(form: NgForm) {
    this.submitting = true;

    if (this.editCarrier.isActive === null || this.editCarrier.isActive === undefined) {
      this.editCarrier.isActive = false;
    }

    const saveOperation = this.editCarrier.id
      ? this.carrierService.putCarrierDTO(this.editCarrier)
      : this.carrierService.postCarrierDTO(this.editCarrier);

    saveOperation.subscribe(
      (response) => {
        if (!this.editCarrier.id) {
          this.editCarrier.id = response.id;
          this.toastrService.success(
            "Carrier successfully saved!",
            this.editCarrier.name + " created!"
          );
        } else {
          const index = this.truckTypes.findIndex(t => t.id === this.editCarrier.id);
          this.truckTypes[index] = this.editCarrier;
          this.toastrService.success(
            "Carrier details successfully updated!",
            this.editCarrier.name + " updated!"
          );
        }
        this.dialogRef.close(true);
      },
      (err) => {
        this.errorService.handleError(err);
        this.dialogRef.close(false);
      }
    ).add(() => this.submitting = false);
  }

  truckChanged(id: number) {
    this.editTruck = this.truckTypes.find((x) => x.id === id);
    this.editCarrier.truckTypeId = this.editTruck.id;
    this.editCarrier.truckType = this.editTruck;
  }
}
