import { Injectable } from '@angular/core';
import { CustomerTheme, DEFAULT_CUSTOMER_THEME } from './customer-theme';
import { count } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public customerTheme: CustomerTheme;

  constructor() {
    this.customerTheme = DEFAULT_CUSTOMER_THEME;
  }


  public getColor(index: number, opacity: number = 0): string {

    let returnColor: string;

    if (index >= this.customerTheme.colors.length) {

      let divisionResult = index / this.customerTheme.colors.length;
      let remainder = divisionResult % 1;

      let newIndex = Math.round(remainder * this.customerTheme.colors.length);

      returnColor = this.customerTheme.colors[newIndex];
    } else {
      returnColor = this.customerTheme.colors[index];
    }

    if (opacity != 0) {
      return returnColor + opacity.toString(16);
    } else {
      return returnColor;
    }

  }
}
