import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Warehouse } from '../../models/storage/warehouse';


@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }


  public getWarehouses(includeInactive: boolean = false): Observable<Warehouse[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters.append('includeInactive', includeInactive.toString());
    
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Warehouse[]>(`${this.basePath}/api/warehouses`,
      {
        headers,
      }
    );
  }

  public putWarehouse(warehouse: Warehouse): Observable<Warehouse[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<Warehouse[]>(`${this.basePath}/api/warehouses/${warehouse.id}`, warehouse,

      {
        headers,
      }
    );
  }

  public getWarehouse(id: number): Observable<Warehouse> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Warehouse>(`${this.basePath}/api/warehouses/${id}`,
      {
        headers,
      }
    );
  }

  public deleteWarehouse(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/warehouses/${id}`,
      {
        headers,
      }
    );
  }

  public postWarehouse(warehouse: Warehouse): Observable<Warehouse> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<Warehouse>(`${this.basePath}/api/warehouses`, warehouse,

      {
        headers,
      }
    );
  }
}
