import { Component, Input, OnInit } from '@angular/core';
import { BlockDTO } from '../../../../../../harvest-engine/core/models/block';


@Component({
  selector: 'kw-popover',
  templateUrl: './field-task-block-popover.component.html',
  styleUrls: ['./field-task-block-popover.component.scss']
})

export class FieldTaskBlockPopoverComponent implements OnInit {

  @Input() blocks: BlockDTO[];



  constructor(

  ) { }

  ngOnInit() {

  }
}