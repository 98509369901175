import { Component, OnInit, Inject } from '@angular/core';
import { PrecisionJobDetail } from '../../../models/precision-job-detail';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material';
import { PrecisionCategory } from '../../../models/precision-category';
import { PrecisionItem } from '../../../models/precision-items';
import { PrecisionVendor } from '../../../models/precision-vendor';
import { PrecisionStatus } from '../../../models/precision-status';

export interface JobDetailBottomSheetData {
  jobDetail: PrecisionJobDetail
  categories: string[];
  items: string[];
  quickbooksItems: string[];
  vendors: string[];
  statuses: string[];
  manufacturers: string[];
}


@Component({
  selector: 'job-detail-edit-bottomsheet',
  templateUrl: './job-detail-edit-bottomsheet.component.html',
  styleUrls: ['./job-detail-edit-bottomsheet.component.scss']
})

export class JobDetailEditBottomsheetComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: JobDetailBottomSheetData,
    private _bottomSheetRef: MatBottomSheetRef<JobDetailEditBottomsheetComponent>,
  ) { }

  ngOnInit() {
    
  }

  close(jobDetail: PrecisionJobDetail){
    this._bottomSheetRef.dismiss(jobDetail);
  }

}
