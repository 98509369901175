import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorService } from '../../../../../../../@core/utils/error.service';
import { TransactionService } from '../../../../../../core/services/TransactionService';
import { InventoryService } from '../../../../../pick-plan/services/inventory.service';

@Component({
  selector: 'remove-inventory-confirmation',
  templateUrl: './remove-inventory-confirmation.component.html',
  styleUrls: ['./remove-inventory-confirmation.component.scss']
})
export class RemoveInventoryConfirmationComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<RemoveInventoryConfirmationComponent>,
    private inventoryService: InventoryService,
    private transactionService: TransactionService,
    private errorService: ErrorService
  ) { }

  public transactionReasons = [];
  public isLoading = true;
  public transactionReasonCodeId = 0;

  ngOnInit() {
    this.transactionService.getTransactionReasonCodes().subscribe(x => {
      this.transactionReasons = x;
      this.isLoading = false;

    }, err => this.errorService.handleError(err))

  }


  close(result) {

    this.dialog.close({
      result: result,
      transactionReasonCodeId: this.transactionReasonCodeId
    })
  }


}
