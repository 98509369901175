import { OnInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ErrorService } from '../../../../@core/utils/error.service';
import { estimateTypeLock } from '../../../modules/estimates/models/estimate-type-lock';
import { EstimatesService } from '../../../modules/estimates/services/estimates.service';
import { EstimatesLockDataSource } from './estimates-lock-datasource';
import * as _ from 'underscore';

@Component({
  selector: 'estimates-lock',
  templateUrl: './estimates-lock.component.html',
  styleUrls: ['./estimates-lock.component.css']
})
export class EstimatesLockComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: EstimatesLockDataSource;
  public isLoading = true;
  public faTimes = faTimes;
  public faCheck = faCheck;

  constructor(
    private estimateService: EstimatesService,
    private errorService: ErrorService,
    ){

  }

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'cropYear', 'isActive'];

  ngOnInit() {
    this.dataSource = new EstimatesLockDataSource(this.paginator, this.sort);
    this.estimateService.getEstimateTypeLocks().subscribe(estimates => {
      this.dataSource.data = _.sortBy(estimates, x => x.cropYear.name).reverse();
      
      this.paginator._changePageSize(this.paginator.pageSize);
      this.isLoading = false;
    }, err => { this.isLoading = false; this.errorService.handleError(err); });
  }

  toggleActive(row: estimateTypeLock){
    this.isLoading = true;
    if(row.isActive){
      row.isActive = false;
    }
    else{
      row.isActive = true;
    }
    this.estimateService.putEstimateTypeLock(row).subscribe(estimates => {
      this.isLoading = false;
    }, err => { this.isLoading = false; this.errorService.handleError(err); });
  }
}
