import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
	MatButtonModule,
	MatFormFieldModule,
	MatInputModule,
	MatCheckboxModule
} from '@angular/material';
import { ThemeModule } from '../../@theme/theme.module';
import { PartialsModule } from '../../partials/partials.module';
import { KragUtilitiesService } from '../../@core/utils/krag-utilities.service';
import { CloudinaryModule } from '@cloudinary/angular-5.x';





@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatButtonModule,
		CloudinaryModule,
		MatInputModule,
		MatFormFieldModule,
		MatCheckboxModule,
		PartialsModule,
		ThemeModule.forRoot(),

		RouterModule.forChild([
			{
				path: '',
				component: AuthComponent
			}
		])
	],
	providers: [
		KragUtilitiesService
	],
	declarations: [
		AuthComponent,
		LoginComponent,
	]
})
export class AuthModule {}
