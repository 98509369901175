import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs/Rx';
import { Location } from '@angular/common';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { CropVariety } from '../../../../../core/models/crop-variety';
import { VarietyService } from '../../../../../core/services/variety.service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { InventoryLotAttributeValue, InventoryLotDTO } from '../../../models/inventory-lot';
import { BlockService } from '../../../../../core/services/block.service';
import { LoadService } from '../../../../pick-plan/services/load.service';
import { CropYearService } from '../../../../../core/services/crop-year.service';
import { BlockDTO } from '../../../../../core/models/block';
import { CropYear } from '../../../../../core/models/cropYear';
import { LoadViewModel } from '../../../../pick-plan/models/load-view-model';
import { InventoryLotAttributeValueDTO } from '../../../models/item-attribute-value copy';
import { InventoryLotAttributeDTO } from '../../../models/lot-attribute';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { InventoryAddItemPopupComponent } from '../../inventory-items/inventory-add-item-popup/inventory-add-item-popup.component';
import { InventoryHumEditComponent } from '../../inventory-summary/inventory-hum-edit/inventory-hum-edit.component';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { InventoryItemDTO } from '../../../models/item';
import * as _ from 'underscore';
 
export interface InventoryLotPopupData {
  lot: InventoryLotDTO;
  isPopup: boolean;
  isReturnBottomsheet?: boolean;
  isHumEdit: boolean;
  isBulkCreate: boolean;
  summaryPopupId?: string;
  hum?: HandlingUnitMasterDTO;
  hud?: HandlingUnitDetailDTO;
}

@Component({
  selector: 'inventory-lot-edit',
  templateUrl: './inventory-lot-edit.component.html',
  styleUrls: ['./inventory-lot-edit.component.scss']
})
export class InventoryLotEditComponent implements OnInit {
  @ViewChild('lotInputForm') lotInputForm: NgForm;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: InventoryLotPopupData,
    private activatedRoute: ActivatedRoute,
    private inventoryService: InventoryService,
    private router: Router,
    private blocksService: BlockService,
    private loadService: LoadService,
    private bottomSheet: MatBottomSheetRef<InventoryLotEditComponent>,
    private cropYearService: CropYearService,
    private bottomSheetReturn: MatBottomSheet,
    private changeDetRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private location: Location,
    public authService: AuthService,
    private varietyService: VarietyService,
    private errorService: ErrorService,) {
    if (data.lot)
      this.lot = data.lot;
    else
      this.lot = {};
  }

  // Icons  //
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faTimes = faTimes;
  public faPlus = faPlusCircle;

  public lot: InventoryLotDTO = {};
  public years: CropYear[];
  public blocks: BlockDTO[];
  public lotAttrabutes: InventoryLotAttributeDTO[];
  public loads: LoadViewModel[];
  public varieties: CropVariety[];
  public isLoading: boolean = true;
  public lots: InventoryLotDTO[] = [];
  public accessionItems: InventoryItemDTO[] = [];

  ngOnInit() {

    this.inventoryService.getInventoryItems().subscribe(items => {
      this.accessionItems = items.filter(x => x.parentItemName == 'Accession');
    })

    Observable.forkJoin([
      this.blocksService.apiBlocksGet(null, false),
      this.cropYearService.getCropYears(false),
      this.authService.hasRole("harvest:read") ? this.loadService.getLoads(false, false, null, '') : Observable.of([]),
      this.inventoryService.getInventoryLotAttributes(),
      this.inventoryService.getLots(),
      this.varietyService.getVarieties(),
      
      //this.roomService.getRooms()
    ])
      .subscribe(x => {
        this.blocks = x[0];
        this.years = x[1];
        this.loads = x[2];
        this.lotAttrabutes = x[3];
        this.lots = x[4];


        this.varieties = _.sortBy(x[5], y => y.name);
        //this.isLoading = false;
        if (!this.lot.id)
          this.activatedRoute.queryParams.subscribe(params => {
            if (params["lotId"]) {
              this.inventoryService.getInventoryLot(params["lotId"]).subscribe(x => {
                this.lot = x;
                this.isLoading = false;
                this.changeDetRef.detectChanges();
              }, err => {
                this.errorService.handleError(err);
                this.isLoading = false;
                this.changeDetRef.detectChanges();
              });
            }
            this.customFields();
            this.isLoading = false;
            this.changeDetRef.detectChanges();
          })
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      });
  }

  submit() {
    this.isLoading = true;
    if (this.lot.id) {
      this.inventoryService.putInventoryLot(this.lot).subscribe(x => {
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
    else {
      this.inventoryService.postInventoryLot(this.lot).subscribe(x => {
        this.lot.id = x.id;
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
  }

  cancel() {
    if (this.data.isPopup) {
      if (this.lot.id && !this.data.isReturnBottomsheet) {
        this.bottomSheet.dismiss(this.lot);
      }
      else if (this.lot.id && this.data.isReturnBottomsheet) {
        this.data.hud.lotId = this.lot.id;
        let data = {
          Hum: this.data.hum,
          hud: this.data.hud,
          lotId: this.lot.id,
          summaryPopupId: this.data.summaryPopupId,
          isBulkCreate: this.data.isBulkCreate
        }
        // open the bottom sheet
        if (!this.data.isHumEdit)
          this.bottomSheetReturn.open(InventoryAddItemPopupComponent, { data });
        else
          this.bottomSheetReturn.open(InventoryHumEditComponent, { panelClass: 'bottomsheet-60-width', data });

        // subscribe to the bottom sheet so we can get the detail once it has been saved. Then unsubscribe from the bottom sheet.
        let bottomSheetSubscription = this.bottomSheetReturn._openedBottomSheetRef.afterDismissed().subscribe(job => {

          bottomSheetSubscription.unsubscribe();

        });
      }
      else {
        this.bottomSheet.dismiss();
      }
    }
    else
      this.location.back();
  }

  deleteLot() {
    this.isLoading = true;
    this.inventoryService.deleteInventoryLot(this.lot.id).subscribe(x => {
      this.cancel();
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  customFields() {
    if (!this.lot.attributes)
      this.lot.attributes = [];

    this.lotAttrabutes.forEach(f => {

      var tempField = this.lot.attributes.find(x => x.id == f.id);
      if (tempField) {
        tempField.customAttributeDescription = f.description;
        tempField.customAttributeName = f.name;
        tempField.id = this.lot.id;
      }
      else {
        let fieldValue: InventoryLotAttributeValue = {
          customAttributeDatatype: f.datatype,
          customAttributeDescription: f.description,
          customAttributeName: f.name,
          id: 0,
          customAttributeId: f.id,
          isActive: true
        };
        this.lot.attributes.push(fieldValue);
      }
    });
  }

  getOptionsLength(attrabute) {
    if (this.lotAttrabutes) {
      var t = this.lotAttrabutes.find(x => x.id == attrabute.customAttributeId);
      if (t && t.options)
        return t.options.length;
      else
        return 0;
    }
    else return 0;
  }

  ClearValue(field) {
    var currentField = this.lot.attributes.find(x => x.id == field.id);
    currentField.value = null;
  }

  getFieldName(field) {
    var currentField = this.lotAttrabutes.find(x => x.id == field.customAttributeId);
    return currentField.name
  }

  getFieldOptions(field) {
    var currentField = this.lotAttrabutes.find(x => x.id == field.customAttributeId);
    currentField.options = currentField.options.sort((a, b) => a.display.localeCompare(b.display));
    return currentField.options
  }
}
