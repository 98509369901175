import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PrecisionJobDetail } from '../../../models/precision-job-detail';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { PrecisionJobService } from '../../../services/precision-job.service';
import * as _ from 'underscore';
import { ErrorService } from '../../../../../@core/utils/error.service';


@Component({
  selector: 'job-detail-edit',
  templateUrl: './job-detail-edit.component.html',
  styleUrls: ['./job-detail-edit.component.scss']
})
export class JobDetailEditComponent implements OnInit, OnChanges {

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private jobService: PrecisionJobService,
    private errorService: ErrorService
  ) { }



  @Input() jobDetail: PrecisionJobDetail;
  @Input() categories: string[];
  @Input() items: string[];
  @Input() vendors: string[];
  @Input() statuses: string[];
  @Input() manufacturers: string[];
  // @Input() quickbooksItems: string[];
  @Input() quickbooksItems: string[];
  public quickbooksItemsLoading: boolean = false;

  @Output() close: EventEmitter<PrecisionJobDetail> = new EventEmitter<PrecisionJobDetail>();

  public isNew: boolean;

  ngOnInit() {  
    if (!this.quickbooksItems || (this.quickbooksItems.length == 1 && this.quickbooksItems[0] == "(ALL)")) {     
      this.getItems();
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.jobDetail.item) {


      this.resetJobDetail();
      this.isNew = true;
    }
  }

  getItems() {

    this.jobService.getItems().subscribe(items => {

      this.quickbooksItems = [...items.map(i => i.name)];
      this.quickbooksItems = _.uniq(this.quickbooksItems);
      this.quickbooksItems = _.sortBy(this.quickbooksItems);

      this.quickbooksItems = ["(ALL)", ...this.quickbooksItems];
      // console.log('here');

      this.quickbooksItemsLoading = false;
    }, err => this.errorService.handleError(err));
  }

  remove() {
    this.jobDetail.isActive = false;
    this.close.emit(this.jobDetail);
  }

  cancel() {
    this.close.emit(null);
  }

  onSubmit() {
    if(!this.jobDetail.id || this.jobDetail.id == "") {
      this.jobDetail.id = this.uuidv4();
    }
    
    this.jobDetail.isActive = true;
    this.close.emit(this.jobDetail);
  }

  private resetJobDetail() {
    this.jobDetail = {
      isEditing: true,
      category: '',
      createdBy: this.authService.getCurrentUser().name,
      createdByUserId: this.authService.getCurrentUser().platformUserId,
      description: '',
      dueDate: new Date(),
      fabTimeHours: 0,
      isActive: true,
      item: '',
      manufacturer: '',
      notes: '',
      partNumber: '',
      purchaseOrder: '',
      purchaseOrderId: 0,
      quantity: 0,
      receiptDates: [],
      receivedQty: 0,
      selected: false,
      status: '',
      vendor: '',
      vendorId: 0
    };
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
