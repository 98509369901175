import { Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { faBarcode, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { KwFilterDropdownService } from '../../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { HandlingUnitBulkMove } from '../../../../inventory/models/handling-unit-bulk-actions';
import { HandlingUnitMasterDTO } from '../../../../inventory/models/handling-unit-master';
import { InventorySessionService } from '../../../../inventory/services/inventory-session.service';
import { lotInventoryService } from '../../../services/lab-quantity-service';

export interface ScanPopupData {
  isTransferScan?: boolean;
  isHumEdit?: boolean;
  hums?: HandlingUnitMasterDTO[];
}

@Component({
  selector: 'scan-popup',
  templateUrl: './scan-popup.component.html',
  styleUrls: ['./scan-popup.component.scss']
})
export class ScanPopupComponent implements OnInit {

  // Icons //
  public faTimes = faTimes;
  public faPrint = faPrint;
  public faBarcode = faBarcode;

  public tagNumber: string = '';
  public isLoading = false;
  public tagNotFound = false;
  public handlingUnitBulkMoveList: HandlingUnitBulkMove[] = [];
  tagNumbers: string[] = [];
  public bottomSheetSubscription: Subscription;
  @ViewChild('tagNumberInput') inputField: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ScanPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public filterService: KwFilterDropdownService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dialog: MatDialog,
    private inventoryService: InventoryService,
    private session: InventorySessionService,
    private lotInventoryService: lotInventoryService,
  ) { }

  ngOnInit() {
    if (this.data.isLocationScan) {
      this.filterService.loadAllData();
    }
    // set the focus a little after the page loads to prevent toggle from getting focus
    setTimeout(() => {
      this.inputField.nativeElement.focus();
    }, 500);

  }



  scanLocation() {

    this.isLoading = true;
    let tagNumber = this.tagNumber;

    if(this.data.isLocationScan){
      let location = this.filterService.allLocations.find(x => x.barcode == tagNumber);
      if (location) {
        if (!this.data.isHumEdit) {
          this.filterService.selectedLocation.push(this.filterService.listOfLocations.find(x => x.id == location.id))
          this.filterService.filter();
        }
        this.close({ "location": location, "isLocation": true });
      }

      let room = this.filterService.allRooms.find(x => x.barcode == tagNumber);

      if (room) {
        if (!this.data.isHumEdit) {
          this.filterService.selectedRooms.push(this.filterService.listOfRooms.find(x => x.id == room.id))
          this.filterService.filter();
        }
        this.close({ "room": room, "isRoom": true });
      }

      let warehouse = this.filterService.allWarehouses.find(x => x.barcode == tagNumber);

      if (warehouse) {
        if (!this.data.isHumEdit) {
          this.filterService.selectedWarehouses.push(this.filterService.listOfWarehouses.find(x => x.id == warehouse.id))
          this.filterService.filter();
        }
        this.close({ "warehouse": warehouse, "isWarehouse": true });
      }

      if(!location && !room && !warehouse){
        this.tagNotFound = true;
      }
    } else if (this.data.isLotScan) {

      this.lotInventoryService.getLotById(tagNumber).subscribe(x => {
        if(x.id == null || x.id == 0 || x.id == undefined){
          this.inventoryService.getInventoryHUMByBarcode(tagNumber).subscribe(x => {
            if(x.handlingUnitDetails.length > 0){
              this.router.navigateByUrl(`harvest-engine/laboratory/lot-dashboard/${x.handlingUnitDetails[0].lotId}`);
              this.close({ "lot": x, "isLot": true });
            } else {
              this.tagNotFound = true;
            }
          }, (error) => {
          })

        } else {
          this.router.navigateByUrl(`harvest-engine/laboratory/lot-dashboard/${x.id}`);
          this.close({ "lot": x, "isLot": true });
        }

      }, (error) => {
        if(error.status == 404){
          this.inventoryService.getInventoryHUMByBarcode(tagNumber).subscribe(x => {
            if(x.handlingUnitDetails.length > 0){
              this.router.navigateByUrl(`harvest-engine/laboratory/lot-dashboard/${x.handlingUnitDetails[0].lotId}`);
              this.close({ "lot": x, "isLot": true });
            } else {
              this.tagNotFound = true;
            }
          }, (error) => {
          })

        }
      });
    }


    this.isLoading = false;
    this.tagNumber = "";
    this.inputField.nativeElement.focus();
  }

  close(tag) {
    this.dialogRef.close(tag);
  }
}

