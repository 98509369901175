import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';

import { Observable, of } from 'rxjs';
import { InventoryPackageTypeDTO } from '../models/inventory-package-type';


@Injectable({
  providedIn: 'root'
})
export class InventoryPackageTypeService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getAll(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<InventoryPackageTypeDTO[]>(`${this.basePath}/api/inventory/inventorypackagetypes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getById(id: number): Observable<InventoryPackageTypeDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<InventoryPackageTypeDTO>(`${this.basePath}/api/inventory/inventorypackagetypes/${id}`,
      {
        headers,
      }
    );
  }

  public post(dto: InventoryPackageTypeDTO): Observable<InventoryPackageTypeDTO> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<InventoryPackageTypeDTO>(`${this.basePath}/api/inventory/inventorypackagetypes`, dto,

      {
        headers,
      }
    );
  }

  public put(dto: InventoryPackageTypeDTO): Observable<InventoryPackageTypeDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<InventoryPackageTypeDTO>(`${this.basePath}/api/inventory/inventorypackagetypes/${dto.id}`, dto,

      {
        headers,
      }
    );
  }

  public delete(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/inventory/inventorypackagetypes/${id}`,
      {
        headers,
      }
    );
  }

}
