import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';

import { Observable, of } from 'rxjs';
import { RandomNumberGenerationSource } from 'd3';
import { random } from 'lodash';
import { InventoryItemDTO } from '../../inventory/models/item';
import { HandlingUnitMasterDTO } from '../../inventory/models/handling-unit-master';
import { InventoryLotAttributeValue, InventoryLotDTO } from '../../inventory/models/inventory-lot';
import { InventoryItemAttributeDTO } from '../../inventory/models/item-attribute';
import { InventoryLotAttributeDTO } from '../../inventory/models/lot-attribute';
import { InventoryStatusDTO } from '../../inventory/models/inventory-status';
import { InventoryItemReportDTO } from '../../inventory/models/item-report-dto';




@Injectable({
  providedIn: 'root'
})
export class InventoryReportService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getInventoryItemsReport(includeInactive: boolean = false, roomId: number = 0) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('IncludeInactive', <any>includeInactive);
    }

    if (roomId !== undefined && roomId !== null) {
      queryParameters = queryParameters.set('roomId', <any>roomId);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<InventoryItemReportDTO[]>(`${this.basePath}/api/inventory/InventoryReports/ItemReport`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }
 
}
