import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { ContractTemplateType } from '../../../../shared/enum';

@Injectable({
  providedIn: 'root'
})
export class PDFService {
    constructor(){
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }
    // public initialize() {
    //     console.log('PDF Service Initialized');
    //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // }

  
  public downloadPDF(data: any) {

    pdfMake.createPdf(data).print();

   let dd = {
    "content": [
        {
            "text": "AGREEMENT TO MODIFY\n\n",
            "style": "header",
            "alignment": "center"
        },
        {
            "table": {
                "widths": [
                    "auto",
                    "*",
                    "auto",
                    "*"
                ],
                "body": [
                    [
                        "Buyer: ",
                        "{{companyAddress}}\n{{cityName}},{{stateName}} {{zipName}}",
                        "Seller: ",
                        "Hollingbery & Son, Inc.\n302 N. 1st Ave.\nYakima, WA 98902"
                    ]
                ]
            }
        },
        {
            "text": [
                "\nBuyer and Seller hereby agree to modify the Hop Sales Offer No.{{offerNumber}}:\n\n"
            ]
        },
        {
            "alignment": "center",
            "table": {
                "widths": [
                    "auto",
                    "*",
                    "*"
                ],
                "body": [
                    [
                        {
                            "text": "Product",
                            "bold": true,
                            "decoration": "underline"
                        },
                        {
                            "text": "Previous",
                            "bold": true,
                            "decoration": "underline"
                        },
                        {
                            "text": "Amended",
                            "bold": true,
                            "decoration": "underline"
                        }
                    ],
                    [
                        "{{lineItemsAmended}}",
                        "",
                        ""
                    ]
                ]
            }
        },
        "\nAll other terms and conditions shall remain the same. Seller’s offer to sell the Hops to Buyer under the terms of this Agreement to Modify shall expire at 5 p.m. on {{expirationDate}}, unless Buyer signs this Agreement to Modify and provides it to Seller by such time and date. Seller shall provide a signed copy of this Agreement to Modify by E-mail at the E-mail address provided below for Buyer.\n\n{{otherRemarks}}\n\n",
        {
            "fontSize": 10,
            "table": {
                "widths": [
                    "*",
                    "*"
                ],
                "body": [
                    [
                        {
                            "text": "Buyer:",
                            "bold": "true"
                        },
                        {
                            "text": "Seller:",
                            "bold": "true"
                        }
                    ],
                    [
                        {
                            "text": "{{companyName}}\nE-mail: {{email}}"
                        },
                        {
                            "text": "Hollingbery & Son, Inc.\nE-mail: sales@hollingberyandson.com"
                        }
                    ],
                    [
                        {
                            "text": [
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Signature",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Date",
                                    "bold": "true",
                                    "alignment": "center"
                                }
                            ]
                        },
                        {
                            "text": [
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Signature",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "\n\n_________________________________________\n",
                                    "bold": "true",
                                    "alignment": "center"
                                },
                                {
                                    "text": "Date",
                                    "bold": "true",
                                    "alignment": "center"
                                }
                            ]
                        }
                    ]
                ]
            }
        }
    ],
    "styles": {
        "marginSm": {
            "margin": [
                5,
                0
            ]
        },
        "indent": {
            "margin": [
                30,
                0
            ]
        },
        "header": {
            "fontSize": 14,
            "bold": true
        },
        "subheader": {
            "fontSize": 14,
            "bold": true
        },
        "quote": {
            "italics": true
        },
        "small": {
            "fontSize": 8
        },
        "table": {
            "margin": [
                0,
                5,
                0,
                0
            ]
        }
    }
}

    // console.log(data)
    
    // console.log(JSON.stringify(dd))
    // dd.footer = function(currentPage, pageCount) {return {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',margin: [0, 10]};},
    // pdfmake.createPdf(dd).print();

    // data.footer = function(currentPage, pageCount) {return {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',margin: [0, 10]};},



  }

public generateVerbiage(data, offerNumber){

    let lineItems =""
    let amendedLineItems = ""
    let totalPrice = 0
    let tenPercent = 0
    let currency = ""
    if(data.LineItems.length > 0 && Object.keys(data.LineItems[0]).length > 1){
        data.LineItems.map(item => {
            var temp = [
                // item.varietyObj.name, //+ " - " +item.inventoryItem.name,
                item.inventoryItem.name,
                item.inventoryItem.description,
                // item.cropYear,
                item.quantity,
                item.unitOfMeasure,
                (item.price.toLocaleString('en-US', { style: 'currency', currency: item.currency })),
                item.currency,
                // item.packageObj.name,
            ]
            // console.log(temp)
            lineItems += JSON.stringify(temp) + ","
            if(item.priceAmend){
                totalPrice += item.priceAmend * item.quantityAmend
            } else {
                totalPrice += item.price * item.quantity
            }
            currency = item.currency
        })
        lineItems = lineItems.slice(0, -1)
        tenPercent = totalPrice * .1
    }
    if(data.LineItemsAmended && Object.keys(data.LineItemsAmended[0]).length > 1){
        data.LineItemsAmended.map((item, idx) => {
            var temp = [
                data.LineItems[idx].inventoryItem.name + " - " + data.LineItems[idx].inventoryItem.description,
                data.LineItems[idx].quantity + " - " + data.LineItems[idx].price.toLocaleString('en-US', { style: 'currency', currency: item.currency }),
                item.quantityAmend + " - " + item.priceAmend.toLocaleString('en-US', { style: 'currency', currency: item.currency })
            ]
            // console.log(temp)
            amendedLineItems += JSON.stringify(temp) + ","
        })
        amendedLineItems = amendedLineItems.slice(0, -1)
    }


    let payload = data.Verbiage
    let offerId = offerNumber || this.getJulianDate() + data.LastId;

    let newPayload = payload.replace(/{{totalPrice}}|{{tenPercent}}|{{currentDate}}|{{companyName}}|{{companyAddress}}|{{cityName}}|{{stateName}}|{{zipName}}|{{contractYear}}|{{expirationDate}}|{{phone}}|{{email}}|{{effectiveDate}}|\["{{lineItems}}","","","","",""\]|{{faxNumber}}|{{otherRemarks}}|{{offerNumber}}|\["{{lineItemsAmended}}","",""\]/gi, function(match) {
        if (match === '{{currentDate}}') return data.CurrentDate || "";
        if (match === '{{companyName}}') return data.Address.company || "";
        if (match === '{{companyAddress}}') return data.Address.address || "";
        if (match === '{{cityName}}') return data.Address.city || "";
        if (match === '{{stateName}}') return data.Address.state || "";
        if (match === '{{zipName}}') return data.Address.zip || "";
        if (match === '{{expirationDate}}') return data.ExpirationDate || "";
        if (match === '{{effectiveDate}}') return data.EffectiveDate || "";
        if (match === '{{contractYear}}') return data.ContractYear || "";
        if (match === '\["{{lineItems}}","","","","",""\]') return lineItems || "";
        if (match === '{{phone}}') return data.Address.phoneNumber || "";
        if (match === '{{email}}') return data.Address.email || "";
        if (match === '{{faxNumber}}') return  data.Address.faxNumber || "";
        if (match === '{{offerNumber}}') return  offerId || "";
        if (match === '{{otherRemarks}}') return  data.OtherRemarks || "";
        if (match === '\["{{lineItemsAmended}}","",""\]') return amendedLineItems || "";
        if (match === '{{totalPrice}}') return  totalPrice.toLocaleString('en-US', { style: 'currency', currency: currency }) || "";
        if (match === '{{tenPercent}}') return  tenPercent.toLocaleString('en-US', { style: 'currency', currency: currency }) || "";


        });
        let finalPayload = JSON.parse(newPayload)
        finalPayload.footer = function(currentPage, pageCount) {return {
            columns: [
                {text: 'Initial_______', alignment: 'left', fontSize: 10, margin: [40, 10, 0, 0]}, 
                {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',fontSize: 10,margin: [0, 10, 40, 0]},
                {text: data.Address.company, alignment: 'right',fontSize: 10, margin: [0, 10, 40, 0]}
              ]
        }}
        return finalPayload;
    }
    public openPDF(data, companyName,footerNotRequired=false){
        // pdfmake.vfs = pdfFonts.pdfMake.vfs;
        
        if(footerNotRequired){
            data.footer = function(currentPage, pageCount) {return {
                columns: [
                    {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',fontSize: 10},
                ]
            }}
        }else{
            data.footer = function(currentPage, pageCount) {return {
                columns: [
                    {text: 'Initial_______', alignment: 'left', fontSize: 10, margin: [40, 10, 0, 0]}, 
                    {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',fontSize: 10,margin: [0, 10, 40, 0]},
                    {text: companyName, alignment: 'right',fontSize: 10, margin: [0, 10, 40, 0]}
                ]
            }}
        }
        pdfMake.createPdf(data).open();
    }

    getJulianDate(){
        const today = new Date();
        const start = new Date(today.getFullYear(), 0, 0);
        const diff = +today - +start + (start.getTimezoneOffset() - today.getTimezoneOffset()) * 60 * 1000;
        const oneDay = 1000 * 60 * 60 * 24;
        var julian = Math.floor(diff / oneDay); // + 1
        var result  = julian < 100 ? "0" + (julian < 10 ? "0" + julian : julian) : julian;

        const year = today.getFullYear().toString().substr(-2);

        // var largestId = this.dataSource.data.reduce((max, obj) => Math.max(max, obj.id), 0);
        // largestId++
        return result.toString() + year;
  }
    
  public generatePOVerbiage(data,templateName,offerNumber){

    let lineItems =""
    let amendedLineItems = ""
    let totalPrice = 0
    let tenPercent = 0
    let currency = ""
    let priceLines = ""
    if(data.LineItems.length > 0 && Object.keys(data.LineItems[0]).length > 1){
        data.LineItems.map(item => {

            if(templateName == ContractTemplateType.SpotPurchaseOrder || templateName == ContractTemplateType.ReceivedPurchaseOrder){
            var temp = [
                item.hasOwnProperty('cropVarietyObj') && item.cropVarietyObj ?  item.cropVarietyObj.name : '',
                item.hasOwnProperty('inventoryItem') && item.inventoryItem ? item.inventoryItem.name : '',
                item.hasOwnProperty('cropYearObj') && item.cropYearObj ? item.cropYearObj.name : '',
                item.qty,
                item.unitOfMeasureName,
                item.price  ? (item.price.toLocaleString('en-US', { style: 'currency', currency: item.currency })) : 0,
                item.currency,               
                item.hasOwnProperty('packageTypeObj') && item.packageTypeObj ? item.packageTypeObj.name : '',
            ]
            lineItems += JSON.stringify(temp) + ","
        }

        if(templateName == ContractTemplateType.PlusPurchaseOrder){
            var poTemp = [
                item.hasOwnProperty('cropYearObj') && item.cropYearObj ? item.cropYearObj.name : '',
                `${item.qty} ${item.unitOfMeasureName}` || '',
                item.price  ? (item.price.toLocaleString('en-US', { style: 'currency', currency: item.currency })) : 0,
                item.otherRemarks
            ];

            var priceLinesTemp = `The price shall be $${item.price} per ${item.unitOfMeasureName} crop year ${item.cropYearObj.name}`

            priceLines += priceLinesTemp + ", ";
            lineItems += JSON.stringify(poTemp) + ","
        }
        if(templateName == ContractTemplateType.FlatPurchaseOrder){
            var poTemp = [
                item.hasOwnProperty('cropYearObj') && item.cropYearObj ? item.cropYearObj.name : '',
                `${item.qty} ${item.unitOfMeasureName}` || '',
                item.price  ? (item.price.toLocaleString('en-US', { style: 'currency', currency: item.currency })) : 0
            ];

            var priceLinesTemp = `The price shall be $${item.price} per ${item.unitOfMeasureName} crop year ${item.cropYearObj.name}`

            priceLines += priceLinesTemp + ", ";
            lineItems += JSON.stringify(poTemp) + ","
        }
            // console.log(temp)
            if(item.priceAmend){
                totalPrice += item.priceAmend * item.quantityAmend
            } else {
                totalPrice += item.price * item.qty
            }
            currency = item.currency
        })
        lineItems = lineItems.slice(0, -1)
        tenPercent = totalPrice * .1
    }
    
    if(data.LineItemsAmended && Object.keys(data.LineItemsAmended[0]).length > 1){
        data.LineItemsAmended.map((item, idx) => {
            var temp = [
                data.LineItems[idx].inventoryItem.name,
                data.LineItems[idx].qty + " - " + data.LineItems[idx].price.toLocaleString('en-US', { style: 'currency', currency: item.currency }),
                item.quantityAmend + " - " + item.priceAmend.toLocaleString('en-US', { style: 'currency', currency: item.currency })
            ]
            amendedLineItems += JSON.stringify(temp) + ","
        })
        amendedLineItems = amendedLineItems.slice(0, -1)
    }
    


    let payload = data.Verbiage
    let offerId = offerNumber || this.getJulianDate() + data.LastId;

    let newPayload = payload.replace(/{{priceLines}}|{{orignalOfferNo}}|{{varietyName}}|{{totalPrice}}|{{offerNo}}|{{tenPercent}}|{{date}}|{{companyName}}|{{companyAddress}}|{{cityName}}|{{stateName}}|{{zipName}}|{{contractYear}}|{{expirationDate}}|{{phone}}|{{email}}|{{effectiveDate}}|\["{{varietyName}}","{{productName}}","{{cropYearId}}","{{qty}}","{{unitOfMeasureId}}","{{pricePerUnit}}","{{currency}}","{{packaging}}"\]|\["{{cropYearId}}","{{unitOfMeasureId}}","{{pricePerUnit}}","{{otherRemarksForItems}}"\]|{{faxNumber}}|{{offerNumber}}|{{supplierName}}|{{customerName}}|{{termsOfDelivery}}|{{paymentTerms}}|{{validityDate}}|{{deliveryDate}}|{{otherRemarks}}|{{image}}|{{kragworksUser}}|\["{{amendedLineItems}}","",""\]/gi, function(match) {
        if (match === '{{date}}') return data.date || "";
        if (match === '{{companyName}}') return data.Address.company || "";
        if (match === '{{companyAddress}}') return data.Address.address || "";
        if (match === '{{cityName}}') return data.Address.city || "";
        if (match === '{{stateName}}') return !!data.Address.state ? data.Address.state + ',' : "";
        if (match === '{{zipName}}') return data.Address.zip || "";
        if (match === '{{expirationDate}}') return data.ExpirationDate || "";
        if (match === '{{effectiveDate}}') return data.EffectiveDate || "";
        if (match === '{{contractYear}}') return data.ContractYear || "";
        if (match === '\["{{cropYearId}}","{{unitOfMeasureId}}","{{pricePerUnit}}","{{otherRemarksForItems}}"\]') return lineItems || "";
        if (match === '\["{{varietyName}}","{{productName}}","{{cropYearId}}","{{qty}}","{{unitOfMeasureId}}","{{pricePerUnit}}","{{currency}}","{{packaging}}"\]') return lineItems || "";
        if (match === '{{phone}}') return data.Address.phoneNumber || "";
        if (match === '{{email}}') return data.Address.email || "";
        if (match === '{{faxNumber}}') return  data.Address.faxNumber || "";
        if (match === '{{supplierName}}') return  data.supplierName || "";
        if (match === '{{customerName}}') return  data.customerName || "";
        if (match === '{{termsOfDelivery}}') return  data.termsOfDelivery || "";
        if (match === '{{paymentTerms}}') return  data.hasOwnProperty('paymentTermsObj') && data.paymentTermsObj ? data.paymentTermsObj.verbiage : "";
        if (match === '{{validityDate}}') return  data.validityDate || "";
        if (match === '{{deliveryDate}}') return  data.deliveryDate || "";
        if (match === '{{otherRemarks}}') return  data.otherRemarks || "";
        if (match === '{{kragworksUser}}') return  data.kragworksUser || "";
        if (match === '{{varietyName}}') return  data.varietyName || "";
        if (match ===  '{{image}}') return  data.imagePath;
        if (match === '\["{{amendedLineItems}}","",""\]') return amendedLineItems || "";
        if (match === '{{totalPrice}}') return  totalPrice.toLocaleString('en-US', { style: 'currency', currency: currency }) || "";
        if (match === '{{tenPercent}}') return  tenPercent.toLocaleString('en-US', { style: 'currency', currency: currency }) || "";
        if (match === '{{offerNo}}') return offerId || "";
        if (match === '{{orignalOfferNo}}') return data.orignalOfferNo || "";
        if (match === '{{priceLines}}') return priceLines || "";

        });
        //removes control characters
        const cleanedJsonObj = newPayload.replace(/[\x00-\x1F\x7F]/g, ''); 
        let finalPayload = JSON.parse(cleanedJsonObj);
        finalPayload.footer = function(currentPage, pageCount) {return {
            columns: [
                {text: `Page ${currentPage.toString()} of ${pageCount.toString()}`,alignment: 'center',fontSize: 10,margin: [0, 10, 40, 0]},
              ]
        }}
        return finalPayload;
    }

    //Convert image url to base64 format
    getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute('crossOrigin', 'anonymous');
    
          img.onload = () => {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
    
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
    
            var dataURL = canvas.toDataURL('https://res.cloudinary.com/kragworks/image/upload/v1568224370/355809ec-3c76-4ef7-9341-b013755806f2/ReportLogo.png');
    
            resolve(dataURL);
          };
    
          img.onerror = (error) => {
            reject(error);
          };
    
          img.src = url;
        });
      }

}

