import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MatDialog, MatDialogRef, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { faArrowAltCircleLeft, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faScanner } from '@fortawesome/pro-solid-svg-icons';
import { Observable, pipe } from 'rxjs/Rx';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { Room } from '../../../../../core/models/storage/room';
import { Warehouse } from '../../../../../core/models/storage/warehouse';
import { RoomLocation } from '../../../../../core/models/storage/room-location';
import { TenantWordsPipe } from '../../../../../core/pipes/tenant-words.pipe';
import { RoomService } from '../../../../../core/services/storage/room-service';
import { WarehouseService } from '../../../../../core/services/storage/warehouse-service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { InventoryMoveLocationPopupComponent } from '../inventory-move-location-popup/inventory-move-location-popup.component';
import { InventoryScanPopupComponent } from '../inventory-scan-popup/inventory-scan-popup.component';
import { map, startWith } from 'rxjs/operators';
import { InventoryAddItemPopupComponent } from '../../inventory-items/inventory-add-item-popup/inventory-add-item-popup.component';
import { ThemeSwitcherListComponent } from '../../../../../../@theme/components';
import { I } from '@angular/cdk/keycodes';
import { PrinterDTO } from '../../../../estimates/models/printer-dto';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { MockSharedService, SHARED_SERVICE } from '../../../../../../shared/mockSharedService';



@Component({
  selector: 'inventory-hum-edit',
  templateUrl: './inventory-hum-edit.component.html',
  styleUrls: ['./inventory-hum-edit.component.scss']
})
export class InventoryHumEditComponent implements OnInit {
  @ViewChild('addItemPopup') public addItemPopup: InventoryAddItemPopupComponent;

  public isLoading: boolean = true;
  public faTimes = faTimes;
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faScanner = faScanner;
  faTrash = faTrash;
  public newHum: HandlingUnitMasterDTO = { handlingUnitDetails: [] };

  public searchWarehouse = "";
  public allWarehouses: Warehouse[] = [];
  public filteredWarehouseOptions: Warehouse[] = [];
  public defaultWarehouseSearchPrompt = `Please search for a ${this.pipe.transform("warehouse")}.`;
  public warehouseSearchPrompt: string = `Please search for a ${this.pipe.transform("warehouse")}.`;

  public searchRoom = "";
  public allRooms: Room[] = [];
  public filteredRoomOptions: Room[] = [];
  public defaultRoomSearchPrompt = `Please search for a ${this.pipe.transform("room")}.`;
  public roomSearchPrompt: string = `Please search for a ${this.pipe.transform("room")}.`;

  public searchLocation = "";
  public allLocations: RoomLocation[] = [];
  public filteredLocationOptions: RoomLocation[] = [];
  public defaultLocationSearchPrompt = `Please search for a ${this.pipe.transform("location")}.`;
  public locationSearchPrompt: string = `Please search for a ${this.pipe.transform("location")}.`;

  public isBulkCreate: boolean = false;
  public tagQty: number = 1;

  public allStatuses = [];
  private printer: PrinterDTO = {
    id: 0
  };



  constructor(
    //@Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    //public dialogRef: MatDialogRef<InventoryHumEditComponent>,
    private _bottomSheetRef: MatBottomSheetRef<InventoryHumEditComponent>,
    private errorService: ErrorService,
    public pipe: TenantWordsPipe,
    public roomService: RoomService,
    private changeDetRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public warehouseService: WarehouseService,
    private inventoryService: InventoryService,
    private authService: AuthService,
    @Inject(SHARED_SERVICE) private sharedService: MockSharedService, //Inject interface of shared service
     ) { }

  ngOnInit() {
    this.isLoading = true;

    if (this.data.isBulkCreate)
      this.isBulkCreate = this.data.isBulkCreate;

    if (this.data.Hum)
      this.newHum = JSON.parse(JSON.stringify(this.data.Hum));
    if (this.data.barcode)
      this.newHum.barcode = this.data.barcode;
    if (!this.newHum.handlingUnitDetails)
      this.newHum.handlingUnitDetails = [];
    this.newHum.handlingUnitDetails.forEach(x => { x.isChecked = true; });
    Observable.forkJoin([
      this.warehouseService.getWarehouses(),
      this.roomService.getRooms(),
      this.roomService.getRoomLocations(),
      this.inventoryService.getInventoryStatuses()
      //, this.roomService.getRooms() 
    ])
      .subscribe(x => {
        this.allWarehouses = x[0];
        this.allRooms = x[1];
        this.allLocations = x[2];
        this.allStatuses = x[3];

        this.filterWarehouses();
        this.filterRooms()
        this.filterLocations();



        this.isLoading = false;
        this.changeDetRef.detectChanges();
      }, err => {
        this.errorService.handleError(err);

        this.isLoading = false;
      });
  }

  setPrinter(printer: PrinterDTO) {
    this.printer = printer;
  }

  close(master) {
    this._bottomSheetRef.dismiss(master);
  }

  submit() {


    if (this.addItemPopup.hudReady) {
      this.addItemPopup.addItemToHum();
    }

    if (this.newHum.handlingUnitDetails.length == 0) {
      const confRef = this.dialog.open(ConfirmationDialogComponent, {
        minWidth: '10%',
        data: {
          confirmLabel: 'Ok',
          title: 'Missing Requirements',
          confirmMessage: this.pipe.transform('Items') + ' must be added to inventory.',
          isWarning: true
        }
      });
    }
    else {
      this.isLoading = true;

      if (this.isBulkCreate) {


        let newHums: HandlingUnitMasterDTO[] = [];

        //Check if YCR, then only create 1 HUM
        var printQuantity = 1
        if (this.authService.hasTenant(["Yakima Chief Ranches", 'e7e73238-662f-4da2-b3a5-89f4abb87969']) ) {

          printQuantity = this.tagQty;
          this.newHum.barcode = '';
          this.newHum.handlingUnitDetails.forEach(x => {
            x.quantity = this.tagQty * x.quantity;
          });
          newHums.push(this.newHum);
        } else {
          for (let i = 0; i < this.tagQty; i++) {
            this.newHum.barcode = '';
            newHums.push(this.newHum);
          }
        }


        this.inventoryService.postBulkInventoryHUM(newHums, this.printer.id, printQuantity).subscribe(results => {

          this.close(results);
        }, err => {
          this.errorService.handleError(err);
          this.isLoading = false;
        });

      } else {
        if (this.newHum.id) {
          this.inventoryService.putInventoryHUM(this.newHum).subscribe(x => {
            this.close(x);
          }, err => {
            this.errorService.handleError(err);
            this.isLoading = false;
          });
        }
        else {
          this.inventoryService.postInventoryHUM(this.newHum).subscribe(x => {
            this.close(x);
          }, err => {
            this.errorService.handleError(err);
            this.isLoading = false;
          });
        }
      }


    }
  }

  roomSelectionChanged(event?: string) {

    if (this.newHum.roomId) {
      let room = this.allRooms.find(x => x.id === this.newHum.roomId);


      if (!room) {
        this.filteredLocationOptions = [...this.allLocations];

        this.searchRoom = '';

        return;
      }


      this.newHum.roomName = room.name;
      this.newHum.warehouseId = room.warehouseId;

      this.newHum.roomLocationId = null;
      this.searchLocation = "";

    }

    else {
      this.newHum.roomId = null;
      this.newHum.roomLocationId = null;
      this.searchLocation = '';
    }

    this.filterLocations();

    this.changeDetRef.detectChanges();
  }

  filterLocation(event) {
    let tagNumber = event;
    let location = this.allLocations.find(x => x.barcode == tagNumber);
    let test = this.newHum.roomLocationId;

    let room = this.allRooms.find(x => x.barcode == tagNumber);

    let warehouse = this.allWarehouses.find(x => x.barcode == tagNumber);

    if (warehouse) {
      this.newHum.warehouseId = warehouse.id;
      return;
    }

    if (room) {
      this.newHum.roomId = room.id;
      this.newHum.warehouseId = room.warehouseId;
      return;
    }

    if (location) {
      this.newHum.roomLocationId = location.id;
      this.newHum.roomId = location.roomId;
      this.newHum.warehouseId = location.room.warehouseId;
      return;
    }
    this.changeDetRef.detectChanges();
  }

  

  filterLocations() {
    if (this.allLocations) {
      let filterValue = this.searchLocation.toLowerCase();
      let results = this.allLocations.filter(option => option.name.toLowerCase().includes(filterValue) && (option.roomId == this.newHum.roomId || !this.newHum.roomId));

      if (results.length > 20) {
        this.filteredLocationOptions = [];
        this.locationSearchPrompt = `Too many ${this.pipe.transform("locations")} found... please narrow your search.`
      } else {
        this.filteredLocationOptions = [...results];
        this.locationSearchPrompt = this.defaultLocationSearchPrompt;
      }
    }
  }
  filterWarehouses() {
    if (this.allWarehouses) {
      let filterValue = this.searchWarehouse.toLowerCase();
      let results = this.allWarehouses.filter(option => option.name.toLowerCase().includes(filterValue));

      if (results.length > 20 && !this.newHum.warehouseId) {
        this.filteredWarehouseOptions = [];
        this.warehouseSearchPrompt = `Too many ${this.pipe.transform("Warehouses")} found... please narrow your search.`
      } else {
        this.filteredWarehouseOptions = [...results];
        this.warehouseSearchPrompt = this.defaultWarehouseSearchPrompt;
      }
    }
    console.log(this.filteredWarehouseOptions)
  }
  warehouseSelectionChanged() {

    if (this.newHum.warehouseId) {
      let warehouse = this.allWarehouses.find(x => x.id === this.newHum.warehouseId);

      this.newHum.warehouseName = warehouse.name;

      this.filteredRoomOptions = this.allRooms.filter(x => x.warehouseId == warehouse.id);

      //this.locations = this.allLocations.filter(x => x.room.warehouseId == this.newHum.warehouseId)

      this.newHum.roomId = null;
      this.newHum.roomLocationId = null;
      this.searchLocation = "";
      this.searchRoom = "";
    }

    else {
      this.newHum.roomId = null;
      this.newHum.roomLocationId = null;
      // this.newHum.warehouseId = null;
    }

    this.changeDetRef.detectChanges();
  }

  filterRooms() {
    if (this.allRooms) {
      let filterValue = this.searchRoom.toLowerCase();
      let results = this.allRooms.filter(option => option.name.toLowerCase().includes(filterValue) && (option.warehouseId == this.newHum.warehouseId || !this.newHum.warehouseId));

      if (results.length > 20 && !this.newHum.warehouseId) {

        this.filteredRoomOptions = [];
        this.roomSearchPrompt = `Too many ${this.pipe.transform("rooms")} found... please narrow your search.`
      } else {

        this.filteredRoomOptions = [...results];
        this.roomSearchPrompt = this.defaultRoomSearchPrompt;
      }
    }
  }

  locationSelectionChanged(any?: string) {

    if (this.newHum.roomLocationId) {
      let location = this.allLocations.find(x => x.id === this.newHum.roomLocationId);

      if (!location) {
        this.searchLocation = '';
        return;
      }


      this.newHum.roomLocationName = location.name;



      let selectedWarehouse = this.allWarehouses.find(x => x.id === location.room.warehouseId);

      if (selectedWarehouse) {
        this.newHum.warehouseId = selectedWarehouse.id;
        this.newHum.warehouseName = selectedWarehouse.name;
        this.searchWarehouse = selectedWarehouse.name;
      }


      let room = this.allRooms.find(x => x.id == location.roomId);

      if (room) {
        this.newHum.roomId = room.id;
        this.newHum.roomName = room.name;
        this.searchRoom = room.name;
      }


      this.filterWarehouses();
      this.filterRooms();
      this.filterLocations();

      this.changeDetRef.detectChanges();

    }
    else {

      this.newHum.roomLocationId = null;
    }

    this.changeDetRef.detectChanges();
  }

  scan() {

    let dialogRef = this.sharedService.openDialog(
      "InventoryScanPopupComponent",
      this.dialog,
      {
        minWidth: "30%",
        data: {
          isHumEdit: true,
        },
      }
    );

    dialogRef.afterClosed().subscribe(x => {
      this.searchLocation = "";
      this.searchWarehouse = "";
      this.searchRoom = "";

      if (x.isLocation) {
        this.newHum.roomLocationId = x.location.id;
        this.searchLocation = x.location.name;

        this.newHum.roomLocationId = x.location.id;
        this.searchRoom = x.location.room.name;

        this.searchWarehouse = x.location.room.warehouse.name
        this.newHum.warehouseId = x.location.room.warehouse.id;

        this.locationSelectionChanged();
      }

      if (x.isRoom) {
        this.newHum.roomLocationId = x.room.id;
        this.searchRoom = x.room.name;

        this.searchWarehouse = x.room.warehouse.name
        this.newHum.warehouseId = x.room.warehouse.id;

        this.searchLocation = '';
        this.newHum.roomLocationId = null;

        this.roomSelectionChanged();
      }

      if (x.isWarehouse) {
        this.searchWarehouse = x.warehouse.name
        this.newHum.warehouseId = x.warehouse.id;

        this.searchRoom = '';
        this.newHum.roomId = null;
        this.searchLocation = '';
        this.newHum.roomLocationId = null;


        this.warehouseSelectionChanged();
      }

      this.isLoading = false;
      this.changeDetRef.detectChanges();
    });
  }
}
