import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { ErrorService } from '../utils/error.service';


@Injectable({
    providedIn: 'root'
  })
export class RoleGuard implements CanActivate {
    constructor(
        public auth: AuthService,
        public router: Router,
        private errorService: ErrorService
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;
       
        var result = this.auth.hasRole(expectedRole);

        
        if (!result) {
            this.errorService.handleError({ status: 403 });
        }

        return result;
    }
}
