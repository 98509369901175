import { ChartOptions, ChartType, ChartDataSets, ChartData, Chart } from 'chart.js';

import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
// import * as moment from "moment-timezone";
import * as moment from "../../../../../../../node_modules/moment";
import * as linq from "linq";
import { ThemeService } from '../../../../../@theme/theme.service';

import { FieldTaskDTO } from '../../../../../harvest-engine/modules/field-events/models/field-task';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';


@Component({
  selector: 'app-line-chart',
  templateUrl: './field-task-line-chart.component.html',
  styleUrls: ['./field-task-line-chart.component.scss']
})
export class FieldTaskLineChartComponent implements OnInit, OnChanges {


  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private fieldTaskService: FieldTasksService,

    private themeService: ThemeService
  ) {

  }


  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() autoRefresh: boolean = true;

  tasksNotStarted: FieldTaskDTO[] = [];
  overdueFieldTasks: FieldTaskDTO[] = [];
  tasksInProgress: FieldTaskDTO[] = [];
  userCount: number;
  tasksDueToday: FieldTaskDTO[] = [];
  @Output() fieldTaskUserCount = new EventEmitter<number>();
  @Output() fieldTasksInProgress = new EventEmitter<FieldTaskDTO[]>();
  @Output() fieldTasksOverdue = new EventEmitter<FieldTaskDTO[]>();
  @Output() fieldTasksDueToday = new EventEmitter<FieldTaskDTO[]>();
  @Output() fieldTasksNotStarted = new EventEmitter<FieldTaskDTO[]>();

  dateToday = new Date().toLocaleString();
  currentWeek: string[];
  public chartLegend = true;
  tasks: FieldTaskDTO[];
  filteredTasks: any;
  public fieldTasks: FieldTaskDTO = {};
  public isLoading: boolean = true;
  public isTasksNull: boolean = false;


  ngOnInit(): void {
    document.getElementById('tasksCompletedLineGraph');
    this.startDate = moment().subtract(7, 'days').toDate();
    this.endDate = new Date();
    this.updateGraph(this.startDate, this.endDate);

  }


  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.updateGraph(this.startDate, this.endDate, true);
  }

  private updateGraph(startDate: Date, endDate: Date, animate: boolean = true) {
    if (startDate !== undefined && endDate !== undefined) {

      this.fieldTaskService.apiFieldTasksGet(null, true, null, null, null, '', this.startDate.toString()).subscribe(tasks => {
        this.tasks = tasks;

        this.getCurrentWeekAndTasks();

        let employeeList: string[] = [];
        let dataSets: any = [];

        // Get all assigned employee
        // employeeList = linq.from(this.tasks)

        //   .selectMany(x => x.assignedUsers)
        //   .groupBy(x => x.name)
        //   .select(x => x.key()).toArray();

          for (let x of this.tasks) {
            employeeList.push(x.completedByUserName);
            employeeList = employeeList.filter((completedByUserName, index, array) =>
              index === array.findIndex((findEmployee) =>
              findEmployee === completedByUserName))
          }
      

          // Get Field Task User Count for Banner
          this.userCount = employeeList.length;
          this.fieldTaskUserCount.emit(this.userCount);

          // Get In Progress Field Tasks for Banner
          this.tasksInProgress = this.tasks.filter(x => 
                                                        (
                                                          moment().isBetween(new Date(x.startDate).toDateString(), new Date(x.dueDate).toDateString(), 'day')
                                                          || moment(new Date().toDateString()).isSame(new Date(x.startDate).toDateString())
                                                          || moment(new Date().toDateString()).isSame(new Date(x.dueDate).toDateString())
                                                        )
                                                        && x.isUserCompleted == false);

          this.fieldTasksInProgress.emit(this.tasksInProgress)

          //Get overdue field tasks for Banner
          this.overdueFieldTasks = this.tasks.filter(x => moment(new Date().toDateString()).isAfter(new Date(x.dueDate).toDateString())
                                                    && x.isUserCompleted == false);
          
          this.fieldTasksOverdue.emit(this.overdueFieldTasks);

          // Get field tasks due today
          this.tasksDueToday = this.tasks.filter(x => moment(new Date().toDateString()).isSame(new Date(x.dueDate).toDateString()));
          this.fieldTasksDueToday.emit(this.tasksDueToday);

          // Get tasks not started
          this.tasksNotStarted = this.tasks.filter(x => moment(new Date().toDateString()).isBefore(new Date(x.startDate).toDateString()));
          this.fieldTasksNotStarted.emit(this.tasksNotStarted);

        // Iterate through employees and create a new dataset object
        for (let i = 0; i < employeeList.length; i++) {
          // Create the dataset for the employee
          let empDataset = {
            label: employeeList[i],
            yAxisID: 'tsk',
            data: [],
            borderColor: this.themeService.getColor(i),
            pointBackgroundColor: this.themeService.getColor(i),
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: this.themeService.getColor(i),
            pointHoverBorderColor: this.themeService.getColor(i),
            borderWidth: 1,
            backgroundColor: this.themeService.getColor(i, 100),
            pointColor: "rgba(220,220,220,1)",
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            pointRadius: 3,
            //
          }
          // Go through each date (Label) and set to the count of tasks completed that day for that employee
          for (let date of this.currentWeek) {
            let currDateCount = linq.from(this.tasks)
              .where(x => moment(x.completedDate).format("MM-DD-YYYY") == date
                && x.completedByUserName == employeeList[i])
              .count();

            empDataset.data.push(currDateCount);

          }
          // push the dataset into the chart datasets
          dataSets.push(empDataset)

        }

        let data: any = {
          type: 'line',
          data: {
            labels: this.currentWeek,
            datasets: dataSets,
          },
          options: {
            animation: {
              duration: (animate) ? 1000 : 0
            },
            events: ['click'], //disables mouseOver
            maintainAspectRatio: false,
            title: {
              display: true,
              text: '',
              fontColor: 'black',
            },
            legend: {
              position: 'top',
              labels: {
                fontColor: 'black'
              }
            },
            scales: {
              yAxes: [{
                id: 'tsk',
                barPercentage: 0.5,
                gridLines: {
                  display: true,
                },
                ticks: {
                  min: 0.1,
                  max: 10,
                  stepSize: 1,
                },
              }],
              xAxes: [{
                gridLines: {
                  zeroLineColor: "black",
                  zeroLineWidth: 0,
                },
                tooltips: {
                  display: false,
                },
                ticks: {
                  min: 0,
                  max: 6500,
                  stepSize: 1300,
                },
                scaleLabel: {
                  display: false,
                  labelString: "Density in kg/m3"
                },
              }]
            },
          }
        };

        let tasksCompletedLineGraph = new Chart("tasksCompletedLineGraph", data);
        this.isLoading = false;

      }, err => { this.errorService.handleError(err); });
    }
  }

  getCurrentWeekAndTasks() {
    // get current week starting @Sunday //
    var currentDate = moment();
    var weekStart = currentDate.clone().startOf('isoWeek');
    var currentWeek = [];
    for (var i = -1; i <= 5; i++) {
      currentWeek.push(moment(weekStart).add(i, 'days').format("MM-DD-YYYY"));
    }
    this.currentWeek = currentWeek;
    for (let d of this.currentWeek) {
      let filteredTasks = this.tasks.filter(x => moment(x.completedDate).format("MM-DD-YYYY") == d);
      this.filteredTasks = filteredTasks;
      
      if(filteredTasks.length < 1) {
        this.isTasksNull = true;
      }

    }

  }



}
