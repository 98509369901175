import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatBottomSheet, MatDialogRef } from '@angular/material';
import { PurchaseOrderTableDataSource } from './purchase-order-table-datasource';
import { PrecisionPurchaseOrderService } from '../../services/precision-purchase-order.service';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import * as Moment from "../../../../../../node_modules/moment";
import { ActivatedRoute, Router } from '@angular/router';
import { faBars, faEdit } from '@fortawesome/free-solid-svg-icons';
import { PoActionsDialogComponent } from './po-actions-dialog/po-actions-dialog.component';
import { SessionService } from '../../../../@core/utils/session.service';
import { QuickPoComponent } from '../quick-po/quick-po.component';
import { PrecisionSessionService } from '../../services/precision-session.service';
import * as _ from 'underscore';
import { PrecisionJobDetail } from '../../models/precision-job-detail';
import { JobDetailBottomSheetData, JobDetailEditBottomsheetComponent } from '../job-details/job-detail-edit-bottomsheet/job-detail-edit-bottomsheet.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'purchase-order-table',
  templateUrl: './purchase-order-table.component.html',
  styleUrls: ['./purchase-order-table.component.scss']
})
export class PurchaseOrderTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: PurchaseOrderTableDataSource;
  faBars = faBars;
  faEdit = faEdit;
  allPurchaseOrders: PrecisionPurchaseOrder[] = [];
  searchValue: any;

  private bottomSheetSubscription: Subscription;

  constructor(private purchaseOrderService: PrecisionPurchaseOrderService,
    private router: Router,
    public dialog: MatDialog,
    private session: PrecisionSessionService,
    public bottomSheet: MatBottomSheet,
  ) {

    if (this.router.url.includes('rfq'))
      this.isRfq = true;
  }


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['purchaseOrderNumber', 'jobNumber', 'vendorName', 'dateOrdered', 'contactName', 'dueDate', 'items', 'shipAddress', 'createdBy', 'alerts', 'actions'];

  isLoading: boolean = true;
  purchaseOrders: PrecisionPurchaseOrder[] = [];
  isRfq: boolean = false;

  ngOnInit() {
    this.dataSource = new PurchaseOrderTableDataSource(this.paginator, this.sort);
    this.loadPOs();
  }

  dateValid(date: Date) {
    //return true;
    if (Moment(date).isAfter(new Date('1900-01-01')))
      return true;
    else
      return false;
  }

  openPoActionDialog(row: PrecisionPurchaseOrder) {
    this.session.precisionPurchaseOrder = row;
    this.dialog.open(PoActionsDialogComponent);

    this.dialog.afterAllClosed.subscribe(result => {
      this.loadPOs();
    });

  }

  openDialog(row: PrecisionPurchaseOrder) {
    this.session.precisionPurchaseOrder = row;
    this.bottomSheet.open(QuickPoComponent);

  }

  getAlerts(row: PrecisionPurchaseOrder) {
    let alerts: string[] = [];
    // if (this.dateValid(row.dueDate) && Moment(row.dueDate).isBefore(new Date()))
    //   alerts.push('Past Due');

    if (!row.job)
      alerts.push('Missing Job');

    if ((row.lineItems || []).length == 0)
      alerts.push('No line items');

    if (!row.isPushedToQb)
      alerts.push('Not in Quickbooks');

    return alerts.join('<br> ');
  }

  getVendors(row: PrecisionPurchaseOrder) {
    if (row.isRFQ)
      return row.rfqVendors.map(v => v.vendorName).join('<br>');
    else
      return row.vendorName;
  }

  getShippingAddress(row: PrecisionPurchaseOrder) {
    return `${row.shipToAddress || ''}${row.shipToAddress ? '<br>' : ''}${row.shipToCity || ''} ${row.shipToCity ? ', ' : ''} ${row.shipToState || ''} ${row.shipToZipCode || ''}`;

  }

  loadPOs() {
    this.purchaseOrderService.getPurchaseOrders(!this.isRfq ? 'po' : 'rfq').subscribe(pos => {
      pos = _.sortBy(pos, 'purchaseOrderNumber').reverse();
      this.dataSource.data = pos;
      this.allPurchaseOrders = pos;
      this.paginator._changePageSize(this.paginator.pageSize);

      if(this.session.jobNumber) {
        this.searchValue = this.session.jobNumber;
        this.onSearchChange();
      }

      this.isLoading = false;
    });
  }

  toJobDetails(jobId: number) {
    if (jobId) {
      this.session.recordId = jobId;
      this.session.job = undefined;
      this.router.navigateByUrl(`/precision/job-details?recordId=${jobId}`);
    }
  }



  onSearchChange() {
    let filteredPurchaseOrders: PrecisionPurchaseOrder[] = JSON.parse(JSON.stringify(this.allPurchaseOrders));
    if (this.searchValue && this.searchValue !== '') {

      filteredPurchaseOrders = filteredPurchaseOrders.filter(fj =>
        (fj.purchaseOrderNumber && fj.purchaseOrderNumber.toLowerCase().includes(this.searchValue.toLowerCase()))
        || (fj.job && fj.job.jobNumber && fj.job.jobNumber.toLowerCase().includes(this.searchValue.toLowerCase()))
        || (fj.contactName && fj.contactName.toLowerCase().includes(this.searchValue.toLowerCase()))
        || (fj.createdBy && fj.createdBy.toLowerCase().includes(this.searchValue.toLowerCase()))
        || (fj.vendorName && fj.vendorName.toLowerCase().includes(this.searchValue.toLowerCase()))
      );
      this.dataSource.data = filteredPurchaseOrders;
      this.paginator._changePageSize(this.paginator.pageSize);
    } else {
      this.dataSource.data = this.allPurchaseOrders
      this.paginator._changePageSize(this.paginator.pageSize);
    }
  }


  // addEditItem(jobDetail: PrecisionJobDetail) {

  //   // let data: JobDetailBottomSheetData = {
  //     // pass the properties of the detail.  This way it's not overwriting the one on the screen if they press cancel
  //     // jobDetail: { ...jobDetail },
  //     // categories: this.categories.filter(x => x !== '(ALL)'),
  //     // items: this.items.filter(x => x !== '(ALL)'),
  //     // manufacturers: this.getManufacturers(),
  //     // statuses: this.statuses.map(s => s.name),
  //     // vendors: this.getVendors()


  //   // }

  //   // open the bottom sheet
  //   this.bottomSheet.open(JobDetailEditBottomsheetComponent, { disableClose: false },);

  //   // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
  //   this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(jobDetail => {

  //     this.bottomSheetSubscription.unsubscribe();
  //   });


  // }
}

