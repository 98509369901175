import { Component, Input, OnInit } from '@angular/core';
import { ErrorService } from '../../../@core/utils/error.service';
import { PoolService } from '../../core/services/pool.service';
import { StorageService } from '../../core/services/storage.service';

@Component({
  selector: 'tool-tip-popup',
  templateUrl: './tool-tip-popup.component.html',
  styleUrls: ['./tool-tip-popup.component.scss']
})
export class ToolTipPopupComponent implements OnInit {

  @Input() displayText: string;
  @Input() displayType: string;
  @Input() toolTipItemId: number;
  public toolTipText: string;

  constructor(
    private storageService: StorageService,
    private poolService: PoolService,
    private errorService: ErrorService) { }

  ngOnInit() {
    if (this.displayType == "Storage" && this.toolTipItemId != 0 && this.toolTipItemId != null) {
      this.storageService.getStorageType(this.toolTipItemId).subscribe(data => {
        this.toolTipText = data.description
      }, err => { this.errorService.handleError(err); });
    }

    else if(this.displayType == "Pool" && this.toolTipItemId != 0 && this.toolTipItemId != null){
      this.poolService.getPoolType(this.toolTipItemId).subscribe(data => {
        this.toolTipText = data.description
      }, err => { this.errorService.handleError(err); });
    }

  }

}
