import { Injectable } from '@angular/core';
import { ARAgingSummaryReport_ReportItem } from '../modules/financial/accounts-receivable/models/ar-aging-summary';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { PositionReport_RowItem } from '../modules/financial/position-report/models/position-report';
import { PostitionReportDetail } from '../modules/financial/position-report/models/position-report-detail';
import { environment } from '../../../../environments/environment';
import { StorePayments } from '../modules/financial/accounts-receivable/models/store-payments';

@Injectable({
    providedIn: 'root'
})
export class HollingberyApiService {



    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();

    constructor(
        protected httpClient: HttpClient
    ) {
        this.basePath = environment.HOLLINGBERY_API_URL;

    }

    public getArAgingSummary(authorization: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ARAgingSummaryReport_ReportItem>>;
    public getArAgingSummary(authorization: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ARAgingSummaryReport_ReportItem>>>;
    public getArAgingSummary(authorization: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ARAgingSummaryReport_ReportItem>>>;
    public getArAgingSummary(authorization: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/aragingsummary`,
            {
                headers: headers,
            }
        );
    }

    public getAgingReportOverTime(authorization: string, startDate: Date, endDate: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<ARAgingSummaryReport_ReportItem>>;
    public getAgingReportOverTime(authorization: string, startDate: Date, endDate: Date,  observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ARAgingSummaryReport_ReportItem>>>;
    public getAgingReportOverTime(authorization: string, startDate: Date, endDate: Date,  observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ARAgingSummaryReport_ReportItem>>>;
    public getAgingReportOverTime(authorization: string, startDate: Date, endDate: Date,  observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('StartDate', startDate.toLocaleString());
        queryParameters = queryParameters.set('EndDate', endDate.toLocaleString());
        if (startDate !== undefined) {
            queryParameters = queryParameters.set('StartDate', startDate.toLocaleString());
        }

        if (endDate !== undefined) {
            queryParameters = queryParameters.set('EndDate', endDate.toLocaleString());
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/aragingsummary/summaryovertime`,
            {
                headers: headers,
                params: queryParameters,
            }
        );
    }

    public getPositionReport(authorization: string, cropYear: string, multiplier: Number, contractType: string, ownership: string, packageType?: string, snapshotDate?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<PositionReport_RowItem>>;
    public getPositionReport(authorization: string, cropYear: string, multiplier: Number, contractType: string, ownership: string, packageType?: string, snapshotDate?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PositionReport_RowItem>>>;
    public getPositionReport(authorization: string, cropYear: string, multiplier: Number, contractType: string, ownership: string, packageType?: string, snapshotDate?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PositionReport_RowItem>>>;
    public getPositionReport(authorization: string, cropYear: string, multiplier: Number, contractType: string, ownership: string, packageType?: string, snapshotDate?: Date, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('CropYear', cropYear.toString());
        queryParameters = queryParameters.set('Multiplier', multiplier.toString());
        if (ownership !== undefined) {
            queryParameters = queryParameters.set('Ownership', ownership.toString());
        }

        if (contractType != undefined) {
            queryParameters = queryParameters.set('ContractType', contractType.toString());
        }

        if (snapshotDate !== undefined && snapshotDate !== null) {
            queryParameters = queryParameters.set('SnapshotDate', snapshotDate.toString());
        }

        if (packageType !== undefined && packageType !== null) {
            queryParameters = queryParameters.set('PackageType', packageType.toString());
        }


        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/positionreport`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }

    public getContractTypes(authorization: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getContractTypes(authorization: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getContractTypes(authorization: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getContractTypes(authorization: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/positionreport/contracttypes`,
            {
                headers: headers,
            }
        );
    }

    public getOwnership(authorization: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getOwnership(authorization: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getOwnership(authorization: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getOwnership(authorization: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/positionreport/ownership`,
            {
                headers: headers,
            }
        );
    }

    getPositionReportDetails(authorization: string, cropYear: string, variety: string, packageSize: string, lotId: string, breweryName: string, contractType: string, ownership: string, snapshotDate?: Date): Observable<PostitionReportDetail[]>
    getPositionReportDetails(authorization: string, cropYear: string, variety: string, packageSize: string, lotId: string, breweryName: string, contractType: string, ownership: string, snapshotDate?: Date): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        let queryParameters = new HttpParams();

        if (cropYear !== null) {
            queryParameters = queryParameters.set('CropYear', cropYear.toString());
        }

        if (ownership !== null) {
            queryParameters = queryParameters.set('Ownership', ownership.toString());
        }

        if (contractType != null) {
            queryParameters = queryParameters.set('ContractType', contractType.toString());
        }

        if (snapshotDate != null) {
            queryParameters = queryParameters.set('SnapshotDate', snapshotDate.toString());
        }

        if (variety != null) {
            queryParameters = queryParameters.set('Variety', variety.toString());
        }

        if (packageSize != null) {
            queryParameters = queryParameters.set('PackageSize', packageSize.toString());
        }

        if (lotId != null) {
            queryParameters = queryParameters.set('LotId', lotId.toString());
        }

        if (breweryName != null) {
            queryParameters = queryParameters.set('BreweryName', breweryName.toString());
        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/positionreport/detail`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }


    getStorePayments(authorization: string): Observable<StorePayments[]>{
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StorePayments[]>(`${this.basePath}/api/StorePayments`,
            {
                headers: headers
            }
        );
    }

    putStorePayment(authorization: string, payment: StorePayments): Observable<StorePayments> {

        let headers = this.defaultHeaders;
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling apiBlocksGet.');
        }
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('Authorization', String(authorization));
        }
    
        const httpHeaderAcceptSelected: string | undefined = 'application/json';
        if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
    
        // to determine the Content-Type header
        const consumes: string[] = [
        ];
    
        return this.httpClient.put<StorePayments>(`${this.basePath}/api/StorePayments/${payment.recordId}`, payment,
    
          {
            headers,
          }
        );
      }
}
