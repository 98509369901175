import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { BlockCustomFieldDTO, BlockCustomFieldValueDTO, BlockDTO, BlockGPSPointDTO } from '../models/block';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlockService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this.defaultHeaders = this.defaultHeaders.set('Authorization', String(this.authService.getUserToken()));
  }




  /**
   * 
   * 
   * @param id 
   */
  public apiBlocksByIdGet(id: number, includeProductionHistory: boolean = false): Observable<BlockDTO> {
      if (id === null || id === undefined) {
          throw new Error('Required parameter id was null or undefined when calling apiBlocksByIdGet.');
      }

      if (id == 0) return Observable.of({})
      
      let queryParameters = new HttpParams();

      queryParameters = queryParameters.append('includeProductionHistory', includeProductionHistory.toString());
      
      return this.httpClient.get(`${this.basePath}/api/Blocks/${encodeURIComponent(String(id))}`,
          {
              headers: this.defaultHeaders,
              params: queryParameters
          }
      );
  }

  /**
   * 
   * 
   * @param id 
   * @param body 
   */
  public apiBlocksByIdPut(id: number, body?: BlockDTO): Observable<BlockDTO>
  {
      if (id === null || id === undefined) {
          throw new Error('Required parameter id was null or undefined when calling apiBlocksByIdPut.');
      }


      return this.httpClient.put(`${this.basePath}/api/Blocks/${encodeURIComponent(String(id))}`, body,
          {
              headers: this.defaultHeaders,
          }
      );
  }

  /**
   * 
   * 
   * @param body 
   */
  
  public apiBlocksCreateUpdateGpsPointsPut(body?: Array<BlockGPSPointDTO> ): Observable<BlockGPSPointDTO> {
     
      return this.httpClient.put(`${this.basePath}/api/Blocks/CreateUpdateGpsPoints`, body,
          {
              headers: this.defaultHeaders,
          }
      );
  }


  public apiBlocksDisableGpsPointsPut(body?: Array<BlockGPSPointDTO>): Observable<any> {
    
      return this.httpClient.put(`${this.basePath}/api/Blocks/DisableGpsPoints`, body,
          {
            headers: this.defaultHeaders,
          }
      );
  }

  
 
  public apiBlocksGet(farmId?: number, includeInactive?: boolean): Observable<BlockDTO[]> {
     

      let queryParameters = new HttpParams();
      if (farmId !== undefined && farmId !== null) {
          queryParameters = queryParameters.set('farmId', <any>farmId);
      }
      if (includeInactive !== undefined && includeInactive !== null) {
          queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
      }


      return this.httpClient.get<BlockDTO[]>(`${this.basePath}/api/Blocks`,
          {
              params: queryParameters,
              headers: this.defaultHeaders,
          }
      );
  }

  public apiBlocksWithQCGet(farmId?: number, includeInactive?: boolean, cropYear?: number, qcArea?: number): Observable<BlockDTO[]> {
     

    let queryParameters = new HttpParams();
    if (farmId !== undefined && farmId !== null) {
        queryParameters = queryParameters.set('farmId', <any>farmId);
    }
    if (includeInactive !== undefined && includeInactive !== null) {
        queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }
    if (cropYear) {
      queryParameters = queryParameters.append('cropYear', cropYear.toString());
    }

    if (qcArea) {
        queryParameters = queryParameters.append('qcArea', qcArea.toString());
      }

    return this.httpClient.get<BlockDTO[]>(`${this.basePath}/api/Blocks/qc`,
        {
            params: queryParameters,
            headers: this.defaultHeaders,
        }
    );
}


  public getCustomFields(): Observable<BlockCustomFieldDTO[]> {
     

    let queryParameters = new HttpParams();

    return this.httpClient.get<BlockCustomFieldDTO[]>(`${this.basePath}/api/Blocks/customfields`,
        {
            params: queryParameters,
            headers: this.defaultHeaders,
        }
    );
}

public getCustomFieldValues(): Observable<BlockCustomFieldValueDTO[]> {
     

    let queryParameters = new HttpParams();

    return this.httpClient.get<BlockCustomFieldValueDTO[]>(`${this.basePath}/api/Blocks/customFieldValues`,
        {
            params: queryParameters,
            headers: this.defaultHeaders,
        }
    );
}



  public apiBlocksPost(body?: BlockDTO): Observable<BlockDTO> {
      return this.httpClient.post(`${this.basePath}/api/Blocks`, body,
          {
            headers: this.defaultHeaders,
          }
      );
  }
}
