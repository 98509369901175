import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { AsyncPipe, CommonModule } from '@angular/common';
import { HarvestEngineRoutingModule } from './harvest-engine.routing.module';
import { RoomSelectorComponent } from './core/widgets/room-selector/room-selector.component';
import { MatButtonModule, MatExpansionModule, MatFormFieldModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatSortModule, MatDividerModule, MatIconModule, MatInputModule, MatNativeDateModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NbCardModule, NbSpinnerModule } from '@nebular/theme';
import { WarehouseSelectorComponent } from './core/widgets/warehouse-selector/warehouse-selector.component';
//import { KwFilterDropdownComponent } from './core/widgets/kw-filter-dropdown/kw-filter-dropdown.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CropYearDropdownComponent } from './core/widgets/crop-year-dropdown/crop-year-dropdown.component';
import { EditService, FreezeService, GridModule, GroupService, PageService, ResizeService, SortService } from '@syncfusion/ej2-angular-grids';
import { WordListComponent } from './core/components/word-list/word-list.component';
import { WordListTableComponent } from './core/components/word-list/word-list-table/word-list-table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RestartServiceComponent } from './core/components/restart-service/restart-service.component';
import { RoomsListComponent } from './core/components/rooms-list/rooms-list.component';
import { WarehouseListComponent } from './core/components/warehouse-list/warehouse-list.component';
import { RoomsListTableComponent } from './core/components/rooms-list/rooms-list-table/rooms-list-table.component';
import { WarehouseListTableComponent } from './core/components/warehouse-list/warehouse-list-table/warehouse-list-table.component';
import { VarietiesListComponent } from './core/components/varieties/varieties-list/varieties-list.component';
import { VarietiesDetailComponent } from './core/components/varieties/varieties-detail/varieties-detail.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TruckTypeListComponent } from './core/components/truck-type-list/truck-type-list.component';
import { CarrierListComponent } from './core/components/carrier-list/carrier-list.component';
import { CarrierListTableComponent } from './core/components/carrier-list/carrier-list-table/carrier-list-table.component';
import { FarmsListComponent } from './core/components/farms/farms-list/farms-list.component';
import { FarmDetailComponent } from './core/components/farms/farm-detail/farm-detail.component';
import { FarmsTableComponent } from './core/components/farms/farms-table/farms-table.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PoolListComponent } from './core/components/pool-list/pool-list.component';
import { CropListComponent } from './core/components/crop-list/crop-list.component';
import { StorageListComponent } from './core/components/storage-list/storage-list.component';
import { NgxMaskModule } from 'ngx-mask';
import { RefreshComponent } from './core/components/refresh/refresh.component';
import { DispatchEditComponent } from './core/components/carrier-list/dispatch-edit/dispatch-edit.component';
import { FieldmanListComponent } from './core/components/fieldman-list/fieldman-list.component';
import { RoomSummaryComponent } from './core/components/room-summary/room-summary.component';
import { InventoryModule } from './modules/inventory/inventory.module';
import { KwFilterDropdownModule } from './core/widgets/kw-filter-dropdown/kw-filter-dropdown.module';
import { TenantWordsModule } from './core/pipes/tenant-words.module';
import { DictionaryComponent } from './core/components/word-list/dictionary/dictionary.component';
import { DictionaryEditComponent } from './core/components/word-list/dictionary-edit/dictionary-edit.component';
import { EstimatesLockComponent } from './core/components/estimates-lock/estimates-lock.component';
import { RoomEditPopupComponent } from './core/components/rooms-list/room-edit-popup/room-edit-popup.component';
import { ToolTipPopupComponent } from './modules/tool-tip-popup/tool-tip-popup.component';
import { TimerComponent } from './core/widgets/timer/timer.component';
import { PackageTypesComponent } from './core/components/package-types/package-types.component';
import { InventoryPackageTypeEditComponent } from './core/components/package-types/inventory-package-type-edit/inventory-package-type-edit.component';
import { RoomLocationsComponent } from './core/components/room-locations/room-locations.component';
import { PrinterSelectorModule } from './core/widgets/printer-select/printer-selector.module';
import { RoomLocationEditComponent } from './core/components/room-locations/room-location-edit/room-location-edit.component';
import { LocationBarcodeListComponent } from './core/components/location-barcode-list/location-barcode-list.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ContractsModule } from './modules/contracts/contracts.module';
import { EmployeesComponent } from './core/components/employees/employees.component';
import { EmployeeEditComponent } from './core/components/employees/employee-edit/employee-edit.component';
import { ScanPopupComponent } from './modules/laboratory/pages/lot-summary/scan-popup/scan-popup.component';
import { RoomDashboardComponent } from './core/components/rooms-list/room-dashboard/room-dashboard.component';
import { CarrierEditListComponent } from './core/components/carrier-list/carrier-edit-list/carrier-edit-list.component';
import { TruckTypeDetailsComponent } from './core/components/truck-type-list/truck-type-details/truck-type-details.component';
import { FieldmanEditListComponent } from './core/components/fieldman-list/fieldman-edit-list/fieldman-edit-list.component';
import { StorageDetailsComponent } from './core/components/storage-list/storage-details/storage-details.component';
import { PoolDetailsComponent } from './core/components/pool-list/pool-details/pool-details.component';
import { CropDetailsComponent } from './core/components/crop-list/crop-details/crop-details.component';
import { MatAutocompleteModule } from '@angular/material';

// COMMENT FOR BUILD
@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    HarvestEngineRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NbCardModule,
    NgxMatSelectSearchModule,
    NbSpinnerModule,
    MatExpansionModule,
    TooltipModule,
    AngularMultiSelectModule,
    FontAwesomeModule,
    MatButtonModule,
    MatTableModule,
    PrinterSelectorModule,
    GridModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    KwFilterDropdownModule,
    NgxMaskModule,
    MatCheckboxModule,
    FontAwesomeModule,
    TenantWordsModule,
    ColorPickerModule,
    MatButtonModule,
    MatIconModule,
    NgxBarcodeModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    InventoryModule,
    ContractsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    ReactiveFormsModule
  ],
  exports: [
    RoomSelectorComponent,
    WarehouseSelectorComponent,
    ToolTipPopupComponent,
    TimerComponent,
    RefreshComponent,
    CropYearDropdownComponent,
    DispatchEditComponent,
    ScanPopupComponent,
    RoomDashboardComponent,
    CarrierEditListComponent
  ],
  declarations: [
    RoomSelectorComponent,
    ToolTipPopupComponent,
    WarehouseSelectorComponent,
    FarmsTableComponent,
    FarmsListComponent,
    FarmDetailComponent,
    CropYearDropdownComponent,
    WordListComponent,
    WordListTableComponent,
    RestartServiceComponent,
    RoomsListComponent,
    WarehouseListComponent,
    RoomsListTableComponent,
    WarehouseListTableComponent,
    VarietiesListComponent,
    VarietiesDetailComponent,
    CarrierListComponent,
    CarrierListTableComponent,
    CarrierListTableComponent,
    TruckTypeListComponent,
    PoolListComponent,
    CropListComponent,
    StorageListComponent,
    RefreshComponent,
    DispatchEditComponent,
    FieldmanListComponent,
    RoomSummaryComponent,
    DictionaryComponent,
    DictionaryEditComponent,
    EstimatesLockComponent,
    RoomEditPopupComponent,
    ToolTipPopupComponent,
    TimerComponent,
    PackageTypesComponent,
    InventoryPackageTypeEditComponent,
    RoomLocationsComponent,
    RoomLocationEditComponent,
    LocationBarcodeListComponent,
    EmployeesComponent,
    EmployeeEditComponent,
    ScanPopupComponent,
    RoomDashboardComponent,
    CarrierEditListComponent,
    TruckTypeDetailsComponent,
    FieldmanEditListComponent,
    StorageDetailsComponent,
    PoolDetailsComponent,
    CropDetailsComponent
  ],
  entryComponents: [
    DispatchEditComponent,
    RoomEditPopupComponent,
    RoomLocationEditComponent,
    RoomSummaryComponent,
    InventoryPackageTypeEditComponent,
    ScanPopupComponent,
    CarrierEditListComponent,
    TruckTypeDetailsComponent,
    FieldmanEditListComponent,
    StorageDetailsComponent,
    PoolDetailsComponent,
    CropDetailsComponent
  ],
  providers: [
    SortService,
    GroupService,
    FreezeService,
    ResizeService,
    PageService,
    EditService,
    
  ]
})
export class HarvestEngineModule { }
