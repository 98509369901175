import { Component, OnInit, Inject } from '@angular/core';
import { JobDetailsComponent } from '../job-details/job-details.component';
import { MatBottomSheetRef, MAT_DIALOG_DATA, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { PrecisionJob } from '../../models/precision-job';
import { PrecisionDropboxService } from '../../services/precision-dropbox.service';

export interface DropboxPopupData {
  job: PrecisionJob
}
@Component({
  selector: 'dropbox-popup',
  templateUrl: './dropbox-popup.component.html',
  styleUrls: ['./dropbox-popup.component.scss']
})
export class DropboxPopupComponent implements OnInit {

  public job: PrecisionJob;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<JobDetailsComponent>,
    public dropboxService: PrecisionDropboxService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DropboxPopupData
  ) {
    this.job = data.job;
   }

  ngOnInit() {
  }

  close(){
    this._bottomSheetRef.dismiss();
  }

}
