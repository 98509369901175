import { Component, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { JobListComponent } from '../job-list/job-list.component';
import { PrecisionJob } from '../../models/precision-job';
import { JobDetailEditBottomsheetComponent } from '../job-details/job-detail-edit-bottomsheet/job-detail-edit-bottomsheet.component';

export interface NewJobPopupData {
  job: PrecisionJob;
}

@Component({
  selector: 'job-popup',
  templateUrl: './job-popup.component.html',
  styleUrls: ['./job-popup.component.scss']
})

export class JobPopupComponent implements OnInit {

  public job: PrecisionJob;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<JobPopupComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: NewJobPopupData
  ) {
    this.job = data.job;
  }

  ngOnInit() {
  }

  close(job: PrecisionJob){
    this._bottomSheetRef.dismiss(job);
  }


}

