import { Injectable } from '@angular/core';
import { EstimateDTO } from '../models/estimate-dto';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { estimateTypeDTO } from '../models/estimate-type-dto';
import { BinTagBatchDTO } from '../models/bin-tag-batch-dto';
import { EstimateDTOGrouping } from '../models/estimate-dto-groupings';
import { estimateTypeLock } from '../models/estimate-type-lock';

@Injectable({
  providedIn: 'root'
})
export class EstimatesService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getEstimateTypes(includeInactive: boolean = false): Observable<estimateTypeDTO[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters.append('includeInactive', includeInactive.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<estimateTypeDTO[]>(`${this.basePath}/api/estimateTypes`,
      {
        headers,
      }
    );
  }

  public getEstimateDTOs(includeInactive: boolean = false, cropYearId?: number): Observable<EstimateDTO[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('includeInactive', includeInactive.toString());

    if(cropYearId)
      queryParameters = queryParameters.append('cropYearId', cropYearId.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    
    return this.httpClient.get<EstimateDTO[]>(`${this.basePath}/api/estimates`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getEstimatesGroupings(includeInactive: boolean = false, cropYearId?: number): Observable<EstimateDTOGrouping[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('includeInactive', includeInactive.toString());

    if(cropYearId)
      queryParameters = queryParameters.append('cropYearId', cropYearId.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    
    return this.httpClient.get<EstimateDTOGrouping[]>(`${this.basePath}/api/estimates/variety?groupByBlock=true`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public putEstimateDTO(estimate: EstimateDTO): Observable<EstimateDTO[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<EstimateDTO[]>(`${this.basePath}/api/estimates/${estimate.id}`, estimate,

      {
        headers,
      }
    );
  }

  public getMostRecentEstimate(blockId: number): Observable<EstimateDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('blockId', blockId.toString());

    return this.httpClient.get<EstimateDTO>(`${this.basePath}/api/Estimates/mostrecent`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getYoYActuals(blockId: number): Observable<EstimateDTO[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('blockId', blockId.toString());

    return this.httpClient.get<EstimateDTO[]>(`${this.basePath}/api/Estimates/yoyActuals`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getEstimateDTO(id: number): Observable<EstimateDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<EstimateDTO>(`${this.basePath}/api/Estimates/${id}`,
      {
        headers,
      }
    );
  }

  public deleteEstimateDTO(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/Estimates/${id}`,
      {
        headers,
      }
    );
  }

  public postEstimateDTO(estimate: EstimateDTO): Observable<EstimateDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<EstimateDTO>(`${this.basePath}/api/estimates`, estimate,

      {
        headers,
      }
    );
  }




  //Estimate Type Locks -----------------------------------------------------------------


  public getEstimateTypeLocks(includeInactive: boolean = true): Observable<estimateTypeLock[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters.append('includeInactive', includeInactive.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<estimateTypeLock[]>(`${this.basePath}/api/estimateTypes/TypeLocks`,
      {
        headers,
      }
    );
  }

  public getLocksByYear(cropYearId: number): Observable<estimateTypeLock[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<estimateTypeLock[]>(`${this.basePath}/api/estimateTypes/TypeLocks/${cropYearId}`,
      {
        headers,
      }
    );
  }

  public putEstimateTypeLock(estimateTypeLock: estimateTypeLock): Observable<estimateTypeLock[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<estimateTypeLock[]>(`${this.basePath}/api/estimateTypes/TypeLocks/${estimateTypeLock.id}`, estimateTypeLock,

      {
        headers,
      }
    );
  }

}
