import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { CropVariety, CropVarietyCategory, VarietyCustomField } from '../models/crop-variety';

@Injectable({
  providedIn: 'root'
})
export class VarietyService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }


  public getCategories(): Observable<CropVarietyCategory[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropVariety[]>(`${this.basePath}/api/cropvarieties/categories`,
      {
        headers,
      }
    );
  }

  public getVarieties(includeInactive: boolean = false): Observable<CropVariety[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters.append('includeInactive', includeInactive.toString());
    
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropVariety[]>(`${this.basePath}/api/cropvarieties`,
      {
        headers,
      }
    );
  }

  public putCropVariety(variety: CropVariety): Observable<CropVariety[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<CropVariety[]>(`${this.basePath}/api/CropVarieties/${variety.id}`, variety,

      {
        headers,
      }
    );
  }

  public getCropVariety(id: number): Observable<CropVariety> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropVariety>(`${this.basePath}/api/cropvarieties/${id}`,
      {
        headers,
      }
    );
  }

  public deleteCropVariety(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/cropvarieties/${id}`,
      {
        headers,
      }
    );
  }

  public postCropVariety(CropVariety: CropVariety): Observable<CropVariety[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<CropVariety[]>(`${this.basePath}/api/CropVarieties`, CropVariety,

      {
        headers,
      }
    );
  }

  public postCustomField(CustomField: VarietyCustomField): Observable<VarietyCustomField[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<VarietyCustomField[]>(`${this.basePath}/api/CropVarieties/customfields`, CustomField,

      {
        headers,
      }
    );
  }
  public putCustomField(CustomFields: VarietyCustomField[]): Observable<VarietyCustomField[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<VarietyCustomField[]>(`${this.basePath}/api/CropVarieties/customfields`, CustomFields,

      {
        headers,
      }
    );
  }

  public deleteCropVarietyCustomField(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/cropvarieties/customfields/${id}`,
      {
        headers,
      }
    );
  }
}
