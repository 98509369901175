import {
	Component, OnInit,
} from '@angular/core';

import { AuthService } from '../../../@core/auth/auth.service';

@Component({
	selector: 'm-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],

	
})
export class LoginComponent implements OnInit {
	ngOnInit(): void {
		
	}

	constructor(
		private auth: AuthService
	) {}

	submit() {
		this.auth.login();
	}


}

