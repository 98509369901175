import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { PlatformConfig } from '../models/platform-config';

@Injectable({
  providedIn: 'root'
})
export class PlatformConfigService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }


  public getDispatchPhone(): Observable<string> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<string>(`${this.basePath}/api/platformconfig/dispatchphone`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getIsHops(): Observable<boolean> {
    
    // return Observable.of(true);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/isHops`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getBulkBinUpload(): Observable<boolean> {
    
    return Observable.of(true);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/isHops`,
      {
        headers,
        params: queryParameters
      }
    );
  }


  public getShowRoomAtReceiving(): Observable<boolean> {
    

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/showRoomAtReceiving`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getShowReceivingPrinter(): Observable<boolean> {
    

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/showReceivingPrinter`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getShowStoragePlanLookupAtReceiving(): Observable<boolean> {
    

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/showStoragePlanLookupAtReceiving`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getRoomTreatmentInterval(): Observable<number> {
    

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<number>(`${this.basePath}/api/platformconfig/roomTreatmentInterval`,
      {
        headers,
        params: queryParameters
      }
    );
  }


  public getIsSpanish(): Observable<boolean> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/isSpanish`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getPublicDispatchPhone(tenantShortCode: string): Observable<string> {

    let headers = this.defaultHeaders;

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('tenantShortCode', tenantShortCode)

    return this.httpClient.get<string>(`${this.basePath}/api/platformconfig/public/dispatchphone`,
      {
        params: queryParameters
      }
    );
  }

  public putDispatchPhone(phone: string): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.put(`${this.basePath}/api/platformconfig/dispatchphone`, {phone},
      {
        headers,
        params: queryParameters,
      }
    );
  }

  public getTimelineReportGrouping(): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get(`${this.basePath}/api/platformconfig/timelineReportGrouping`,
      {
        headers,
        params: queryParameters,
      }
    );
  }

  public getAllConfigValues(): Observable<PlatformConfig[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<PlatformConfig[]>(`${this.basePath}/api/platformconfig/`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getPrintReceiptOnSave(): Observable<boolean> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/printReceiptOnSave`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getMaxBinsAllowed(): Observable<number> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<number>(`${this.basePath}/api/platformconfig/maxBinsAllowed`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  
  public getUseHectares(): Observable<boolean> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<boolean>(`${this.basePath}/api/platformconfig/useHectares`,
      {
        headers,
        params: queryParameters
      }
    );
  }
}
