import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { WorkOrderType } from '../models/work-order';


@Injectable({
  providedIn: 'root'
})
export class WorkOrderTypeService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;

  }
  
  public getWorkOrderTypes(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('IncludeInactive', <any>includeInactive);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<WorkOrderType[]>(`${this.basePath}/api/workOrder/workordertypes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }


  public createWorkOrderType(workOrderType: WorkOrderType) {

    let queryParameters = new HttpParams();


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.post<WorkOrderType>(`${this.basePath}/api/workOrder/workordertypes`, workOrderType,
      {
        params: queryParameters,
        headers: headers,
      
      }
    );
  }

  public updateWorkOrderType(workOrderType: WorkOrderType) {

    let queryParameters = new HttpParams();


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<WorkOrderType>(`${this.basePath}/api/workOrder/workordertypes/${workOrderType.id}`, workOrderType,
      {
        params: queryParameters,
        headers: headers,
      
      }
    );
  }

  public deleteWorkOrderType(workOrderType: WorkOrderType) {

    let queryParameters = new HttpParams();


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.delete<WorkOrderType>(`${this.basePath}/api/workOrder/workordertypes/${workOrderType.id}`,
      {
        params: queryParameters,
        headers: headers,
      
      }
    );
  }

}
