import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { PrecisionJobDetail } from '../../models/precision-job-detail';



export class JobDetailsDataSource extends DataSource<PrecisionJobDetail> {
  data: PrecisionJobDetail[] = [];

  constructor(private paginator: MatPaginator, private sort: MatSort) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<PrecisionJobDetail[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    // Set the paginator's length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: PrecisionJobDetail[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: PrecisionJobDetail[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'category': return compare(a.category, b.category, isAsc);
        case 'item': return compare(a.item, b.item, isAsc);
        case 'description': return compare(a.description, b.description, isAsc);
        case 'fabTimeHours': return compare(a.fabTimeHours, b.fabTimeHours, isAsc);
        case 'partNumber': return compare(a.partNumber, b.partNumber, isAsc);
        case 'manufacturer': return compare(a.manufacturer, b.manufacturer, isAsc);
        case 'quantity': return compare(a.quantity, b.quantity, isAsc);
        case 'purchaseOrder': return compare(a.purchaseOrder, b.purchaseOrder, isAsc);
        case 'dueDate': return compare(a.dueDate, b.dueDate, isAsc);
        case 'vendor': return compare(a.vendor, b.vendor, isAsc);
        case 'notes': return compare(a.notes, b.notes, isAsc);
        case 'createdBy': return compare(a.createdBy, b.createdBy, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
