import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { NbToastrService } from '@nebular/theme';
import { VarietyService } from '../../../services/variety.service';
import { CropVariety, CropVarietyCategory, VarietyCustomField } from '../../../models/crop-variety';
import { CropService } from '../../../services/crop.service';
import { Crop } from '../../../models/crop';
import { faTimes } from '@fortawesome/fontawesome-pro';
import { KwFilterDropdownService } from '../../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { PoolListDataSource } from '../../pool-list/pool-list-datasource';
import { SessionService } from '../../../../../@core/utils/session.service';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationData, ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'varieties-detail',
  templateUrl: './varieties-detail.component.html',
  styleUrls: ['./varieties-detail.component.scss']
})
export class VarietiesDetailComponent implements OnInit {

  //#region Properties
  // dataSource: PoolListDataSource;
  private varietyId: number;
  public crops: Crop[] = [];
  public variety: CropVariety = {};
  public loading: boolean = true;
  public cropLoading: boolean = true;
  public submitting: any;
  public isEditBtnClick: boolean;
  public categories: CropVarietyCategory[] = [];
  public newCustomField: VarietyCustomField = {
    name: "",
    value: "",
    type: ""
  };
  //#endregion

   //#endregion icon
  public faTrash = faTrash;
  public faPlus = faPlus;
   //#endregion

  //#region ViewChild Properties
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  //#endregion

  constructor(
    private route: ActivatedRoute,
    private varietyService: VarietyService,
    private errorService: ErrorService,
    private authService: AuthService,
    private toastr: NbToastrService,
    private cropService: CropService,
    private filterService: KwFilterDropdownService,
    public dialogRef: MatDialogRef<any>,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogService: MatDialog
  ) { 
    this.isEditBtnClick = data.isEditBtnClick;
  }

  ngOnInit() {
    // this.dataSource = new PoolListDataSource(this.paginator, this.sort);
    // get variety id from route
    this.varietyId = +this.route.snapshot.paramMap.get('id') || 0;

    this.variety = this.sessionService.selectedcropVarieties;

    if (this.variety === null || this.variety === undefined) {
      this.variety = {};
      this.variety.isActive = true;
      this.cropLoading = false;
    }

    this.varietyService.getCategories().subscribe(x => {
      this.categories = x;
    });

    this.cropService.getCrops().subscribe(crops => {
      this.crops = crops;
      this.cropLoading = false;
    });

    if ((this.varietyId || 0) !== 0) {
      this.varietyService.getCropVariety(this.varietyId).subscribe(vrt => {
        this.variety = vrt;
        this.loading = false;
        this.cropLoading = false;
      }, err => { this.errorService.handleError(err) });
    } 
    else {
      this.variety.isActive = true;
      this.cropLoading = false;
    }
  }

  addCustomField(){
    this.loading = true;
    this.newCustomField.cropVarietyId = this.variety.id;

    this.varietyService.postCustomField(this.newCustomField).subscribe(x => {
      this.variety.customFields.push({...this.newCustomField});
      this.toastr.success(this.newCustomField.name + ' successfully added!');
      this.newCustomField = {
        name: "",
        value: "",
        type: ""
      }
    })
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this custome field?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "500px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteCustomField(id);
      }
    });
  }

  deleteCustomField(id: number){
    this.loading = true;
    this.varietyService.deleteCropVarietyCustomField(id).subscribe(x => {
      this.toastr.info("Custome Field successfully deleted!");
      this.variety.customFields = this.variety.customFields.filter(field => field.id !== id);
      this.cropLoading = false;
      this.ngOnInit();
    })
  }

  onCropChange(cropId: number) {
    let selectedCrop = this.crops.find(x => x.id == cropId);

    if (selectedCrop) {
      this.variety.cropName == selectedCrop.name;
    }
  }

  onSubmit() {
    let self = this;
    this.submitting = true;
    setTimeout(() => {
    if (!self.variety.id || self.variety.id === 0) {
      // create variety
      self.varietyService.postCropVariety(self.variety).subscribe(vrt => {
        self.toastr.success(self.variety.name + ' successfully saved', 'Saved!');
        self.dialogRef.close(true);
      }, err => { 
        self.errorService.handleError(err); 
        self.dialogRef.close(false);
      });
    } else {
      // update variety
      if(self.variety.customFields.length > 0){
        self.varietyService.putCustomField(self.variety.customFields).subscribe(x => {
        })
      }
      self.varietyService.putCropVariety(self.variety).subscribe(result => {
        self.toastr.success(self.variety.name + ' successfully saved', 'Saved!');
        self.dialogRef.close(true);
      }, err => {
        self.errorService.handleError(err);
        self.dialogRef.close(false);
      });
    }
  }, 1000);
  }

  cancelClicked(cancelled: boolean = false) {
    this.variety.isActive = false;
    this.variety = {};
    this.dialogRef.close(cancelled);
  }

}
