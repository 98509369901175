import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ItemAssembliesDataSource } from './item-assemblies-datasource';
import { faBars, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { InventoryItemAssemblyDTO } from '../../models/item-assembly';
import { DataManager, ODataAdaptor, Query } from '@syncfusion/ej2-data';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { KwFilterDropdownService } from '../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { FilterSettings } from '../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { ItemAssemblyEditComponent } from '../item-assembly-edit/item-assembly-edit.component';
import { InventoryService } from '../../../pick-plan/services/inventory.service';

@Component({
  selector: 'item-assemblies',
  templateUrl: './item-assemblies.component.html',
  styleUrls: ['./item-assemblies.component.css']
})
export class ItemAssembliesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: ItemAssembliesDataSource;

  public isLoading = true;
  private initialLoad = true;
  public faEdit = faEdit;
  @ViewChild('grid') grid: GridComponent;
  public assemblies: InventoryItemAssemblyDTO[] = [];
  public allAssemblies: InventoryItemAssemblyDTO[] = [];
  public data: DataManager;
  public filterSettings: Object;
  public filter: Object;
  public pageSettings: PageSettingsModel = {
    pageSize: 50
  }

  public dropdownSettings: FilterSettings = {
    blockSettings: {
      display: true,
      singleSelection: false
    },
    farmSettings: {
      display: true,
      singleSelection: false
    },
    varietySettings: {
      display: true,
      singleSelection: false
    },
    storageSettings: {
      display: true,
      singleSelection: false
    },
    cropSettings: {
      display: false,
      singleSelection: true
    },
    cropYearSettings: {
      display: true,
      singleSelection: true
    },
    fieldmanSettings: {
      display: true,
      singleSelection: false
    },
    roomSettings: {
      display: false,
      singleSelection: false
    },
    warehouseSettings: {
      display: false,
      singleSelection: false
    },
    qcAreaSettings: {
      display: true,
      singleSelection: true
    }
  };

  constructor(
    public dialog: MatDialog,
    private errorService: ErrorService,
    private inventoryService: InventoryService, 
    private bottomSheet: MatBottomSheet,
    public filterService: KwFilterDropdownService,
    ){
      
    }

  ngOnInit() {
    this.dataSource = new ItemAssembliesDataSource(this.paginator, this.sort);
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" }
    this.updateTable();
  }

  updateTable(){
    this.isLoading = true;
    this.inventoryService.getInventoryItemAssemblies().subscribe(x => {
      this.assemblies = x;
      this.allAssemblies = x;
      this.dataSource.data = this.assemblies;
      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  setFilterResults(results: FilterResults) {
    if(this.initialLoad){
      this.initialLoad = false;
      results = this.filterService.getFilterState();
    }
    // let filteredResults = [...this.allAssemblies.filter(x =>
    //   (results.selectedFarms.map(y => y.id).includes(x.) || results.selectedFarms.length === 0) &&
    //   (results.selectedBlocks.map(y => y.id).includes(x.blockId) || results.selectedBlocks.length === 0) &&
    //   (results.selectedVarieties.map(y => y.id).includes(x.varietyId) || results.selectedVarieties.length === 0) &&
    //   (results.selectedQcAreas.map(y => y.id).includes(x.qcAreaId) || results.selectedQcAreas.length === 0) &&
    //   (results.selectedFieldmen.map(y => y.itemName).includes(x.fieldman) || results.selectedFieldmen.length === 0) &&
    //   (results.selectedCropYears.map(y => y.id).includes(x.cropYearId) || results.selectedCropYears.length === 0)
    // )];

    // this.assemblies = [...filteredResults];
  }

  public rowClicked(args): void {
    let data = {
      itemAssembly: args.data
    };

    this.bottomSheet.open(ItemAssemblyEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      this.grid.clearSelection();
      this.updateTable();
      bottomSheetSubscription.unsubscribe();
    });
  }

  createItemAssembly(){
    let data = {
      itemAssembly: {}
    };

    this.bottomSheet.open(ItemAssemblyEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      this.grid.clearSelection();
      this.updateTable();
      bottomSheetSubscription.unsubscribe();
    });
  }

}
