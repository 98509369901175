import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, ChartData } from 'chart.js';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { BaseChartDirective } from 'ng2-charts';
import { UserDTO } from '../../../../../harvest-engine/core/models/user';
import { FieldTaskDTO } from '../../../../../harvest-engine/modules/field-events/models/field-task';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';


@Component({
  selector: 'kw-field-task-completed-by-month',
  templateUrl: './field-task-completed-by-month.component.html',
  styleUrls: ['./field-task-completed-by-month.component.scss']
})

export class FieldTaskCompletedByMonthComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private fieldTaskService: FieldTasksService,

  ) {

  }

  @ViewChild("baseChart") chart: BaseChartDirective;

  public chartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  public chartLabels: string[] = ['March'];
  public chartType: ChartType = 'bar';
  public chartLegend = true;

  private cropYear = new Date().getFullYear();

  public isLoading: boolean = true;
  private months: any[] = [];
  private users: UserDTO[] = [];
  public chartData: ChartDataSets[] = [
    { data: [], label: '' },

  ];

  myColors = [
    {
      backgroundColor: '#0E3D59',
      borderColor: '#0E3D59',
      pointBackgroundColor: '#0E3D59',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#88A61B',
      borderColor: '#88A61B',
      pointBackgroundColor: '#88A61B',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#F29F05',
      borderColor: '#F29F05',
      pointBackgroundColor: '#F29F05',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#F25C05',
      pointBackgroundColor: '#F25C05',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#D92525',
      borderColor: '#D92525',
      pointBackgroundColor: '#D92525',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: 'rgba(150, 169, 205)',
      borderColor: 'rgb(150, 169, 205)',
      pointBackgroundColor: 'rgb(150, 169, 205)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
  ];

  ngOnInit(): void {
    this.fieldTaskService.apiFieldTasksGet().subscribe(tasks => {
      this.handleData(tasks);
      this.isLoading = false;

    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    })
  }

  handleData(tasks: FieldTaskDTO[]): any {
    let filterDate = new Date("01/01/" + this.cropYear.toString());
    let newLabels: string[] = [];
    // Get current crop year tasks
    let filteredTasks = tasks.filter(t => t.isUserCompleted === true && new Date(t.completedDate) >= filterDate);

    /** Populate month labels  **/
    for (const t of filteredTasks) {
      let completedDate = new Date(t.completedDate);
      let month = { name: completedDate.toLocaleString('en-us', { month: 'long' }), number: completedDate.getMonth() };

      this.addMonth(month);
    }

    // this.chartLabels = [];
    for (var m of this.months) {
      newLabels.push(m.name);
    }

    /* get users */
    for (var ft of filteredTasks) {
      if (ft.assignedUsers !== undefined && ft.assignedUsers !== null) {
        for (var u of ft.assignedUsers) {
          this.addUser(u);

        }
      }
    }

    let newChartData: ChartDataSets[] = [];
    for (var u of this.users) {
      let data: number[] = [];
      for (var m of this.months) {

        let userTasks = filteredTasks.filter(t => new Date(t.completedDate).getMonth() == m.number && t.completedByUserId === u.platformUserId.toString());

        if (userTasks !== null && userTasks !== undefined) {
          data.push(userTasks.length);
        }

      }

      newChartData.push({ data: data, label: u.name });

    }

    // this.chartData = newChartData;
    this.reloadChart(newChartData, newLabels);

  }


  private addUser(u: UserDTO) {
    if (u !== undefined && u !== null) {
      let userExists = false;
      for (var au of this.users) {
        if (au.platformUserId === u.platformUserId) {
          userExists = true;
        }

      }
      if (!userExists) {
        this.users.push(u);
      }
    } 
  }

  private addMonth(month: { name: string; number: number; }) {
    let monthExists = false;

    for (var m of this.months) {
      if (m.number === month.number) {
        monthExists = true;
      }
    }

    if (!monthExists) {
      this.months.push(month);
    }

    this.months = this.months.sort();
  }


  reloadChart(newData: ChartDataSets[], newLabels: string[]) {
    if (this.chart !== undefined) {
      this.chart.chart.destroy();
      this.chart.chart = 0;

      this.chart.datasets = newData;
      this.chart.labels = newLabels;
      this.chart.ngOnInit();
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {

  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {

  }

}