import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { faEdit, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PageSettingsModel, FilterSettingsModel, ColumnModel } from '@syncfusion/ej2-angular-grids';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from '../../../../@core/utils/error.service';
import { FirmanSessionService } from '../../../../customer-integrations/firman/services/firman-session.service';
import { Employee } from '../../models/employee';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { EmployeeService } from '../../services/employee/employee.service';
import { FpCrewService } from '../../../../customer-integrations/firman/services/crew/fp-crew.service';
import { Fp_Crew } from '../../../../customer-integrations/firman/models/fp-crew';
import { parse } from 'querystring';
import * as XLSX from 'xlsx';

@Component({
  selector: 'employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  public isLoading = false;

  public pageSettings: PageSettingsModel = {
    pageSize: 200,
    pageSizes: [50, 100, 200],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }

  public dataSource: any[] = [];
  public faEdit = faEdit;
  public faCheck = faCheck;
  public faTimes = faTimes;

  @ViewChild('actionTemplate')
  public actionTemplate: any;
  @ViewChild('activeTemplate')
  public activeTemplate: any;
  @ViewChild('hourlyTemplate')
  public hourlyTemplate: any;


  public columns: ColumnModel[] = [];
  baseColumns: ColumnModel[] = [];
  endColumns: ColumnModel[] = [];
  public crews: Fp_Crew[] = [];


  // used to check when the bottom sheet is closed.
  private bottomSheetSubscription: Subscription;

  constructor(
    public employeesService: EmployeeService,
    public crewService: FpCrewService,
    public changeDetRef: ChangeDetectorRef,
    private errorService: ErrorService,
    private sessionService: FirmanSessionService,
    public matBottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    this.endColumns = [];
    this.baseColumns = [];

    this.endColumns = [
      { headerText: 'Actions', width: 100 },
    ];

    this.baseColumns = [
      { field: 'firstName', headerText: 'First Name', autoFit: false },
      { field: 'lastName', headerText: 'Last Name', autoFit: false },
      { field: 'middleInitial', headerText: 'Middle Initial', autoFit: false },
      { field: 'streetAddress', headerText: 'Address', autoFit: false },
      // { field: 'crewId', headerText: 'Crew', autoFit: false, valueAccessor: this.getCrewName },
      { field: 'crewName', headerText: 'Crew', autoFit: false},

      { field: 'integrationId', headerText: 'Employee #', autoFit: false }
    ];

    Observable.forkJoin([
      this.employeesService.getEmployees(),
      this.crewService.getCrews()
    ]).subscribe(x => {
      this.dataSource = x[0];
      this.crews = x[1];

      this.dataSource.forEach(x => {
        x.crew = this.crews.find(c => c.id == x.crewId);
        x.crewName = x.crew ? x.crew.crewName : '';
      });
      this.columns = [...this.baseColumns, ...this.endColumns];

      this.columns.find(x => x.headerText == 'Actions').template = this.actionTemplate;
      this.changeDetRef.detectChanges()
      // this.columns.find(x => x.headerText == 'Active?').template = this.activeTemplate;
      // this.columns.find(x => x.headerText == 'Hourly?').template = this.hourlyTemplate;
      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  openDialog(data: Employee) {

    this.matBottomSheet.open(EmployeeEditComponent, {
      data: {
        employee: data,
        crews: [...this.crews]
      }
    });

    this.bottomSheetSubscription = this.matBottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.bottomSheetSubscription.unsubscribe();
      this.loadData();
    });


  }

  // getCrewName(field, data: Employee, column) {
  //   var crew = data.crew;

  //   if (crew)
  //     return crew.crewName;

  //   return "";
  // }

  createEmployee() {
    let data = {};

    this.matBottomSheet.open(EmployeeEditComponent, { data: data });

    this.bottomSheetSubscription = this.matBottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.bottomSheetSubscription.unsubscribe();
      this.loadData();
    });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const arrayBuffer = e.target.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      const bstr = arr.join("");

      const workbook = XLSX.read(bstr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert sheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      // convert the jsonData to the Employee[] type using the csvToDtoMapping
      const employees: Employee[] = jsonData.map((data) => {
        const employee: Partial<Employee> = {};

        Object.keys(data).forEach((key) => {
          const dtoKey = this.csvToDtoMapping[key];
          if (dtoKey) {
            // Handle different data types, for now, just handle 'timestamp' and number-based columns
            if (!isNaN(Number(data[key]))) {
              employee[dtoKey] = Number(data[key]);
            } else {
              employee[dtoKey] = data[key];
            }
          }
        });

        return employee as Employee;
      });
      
      this.processEmployees(employees);
      // Add the jsonData to your data structure here
    };
    fileReader.readAsArrayBuffer(file);
  }

  processEmployees(employees_to_process: Employee[]) {
    this.isLoading = true;
    let tasksToRun = [];
    let crewsUploaded = []

    for (let employee_to_process of employees_to_process) {

      let existing_employee = this.dataSource.find(x => x.integrationId == employee_to_process.integrationId);
      let crew = this.crews.find(x => x.crewShortCode.toLocaleLowerCase() == employee_to_process['importCrewName'].toLocaleLowerCase());

      if(!crew) {

        alert(`Crew ${employee_to_process['importCrewName']} not found`);
        this.isLoading = false;
        return;
      }

      // add crew to the crewsUploaded if it doesn't exist
      if(!crewsUploaded.find(x => x.id == crew.id)) {
        crewsUploaded.push(crew);
      }

      if(existing_employee) {
        existing_employee.firstName = employee_to_process.firstName;
        existing_employee.lastName = employee_to_process.lastName;
        existing_employee.middleInitial = employee_to_process.middleInitial;
        existing_employee.streetAddress = employee_to_process.streetAddress;
        existing_employee.crewId = crew.id;
        existing_employee.crew = null;
        existing_employee.integrationId = employee_to_process.integrationId;
        tasksToRun.push(this.employeesService.putEmployee(existing_employee));
      } else {
        employee_to_process.crew = null;
        employee_to_process.crewId = crew.id;
        tasksToRun.push(this.employeesService.postEmployee(employee_to_process));
      }
    }

    // get all of the crew employees and clear the crew id if they don't exist in the employees_to_process
    let all_employees = this.dataSource.filter(x => crewsUploaded.map(y => y.id).includes(x.crewId));
    
    for(let employee of all_employees) {
      if(!employees_to_process.find(x => x.integrationId == employee.integrationId)) {
        employee.crewId = null;
        tasksToRun.push(this.employeesService.putEmployee(employee));
      }
    }

    Observable.forkJoin(tasksToRun).subscribe(x => {
      this.loadData();
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });

  }

  csvToDtoMapping: { [key: string]: string } = {
    'Account #': 'integrationId',
    'Crew ID': 'importCrewName',
    'First Name': 'firstName',
    'Last Name': 'lastName',
    'First & Middle Names': 'firstName',
  };

}
