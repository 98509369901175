import { Injectable } from '@angular/core';
import { BlockDTO } from '../../harvest-engine/core/models/block';
import { CropVariety } from '../../harvest-engine/core/models/crop-variety';
import { FarmDTO } from '../../harvest-engine/core/models/farm';



@Injectable({
  providedIn: 'root'
})
export class KragUtilitiesService {

  constructor() { }


  // kw collections

  public sortFarmsAndBlocks(farmList: FarmDTO[]): FarmDTO[] {
    farmList.sort(function (a, b) {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
    // then sort the each farms blocks
    for (const f of farmList) {
      f.blocks.sort(function (a, b) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }

    return farmList;
  }

  public removeInactiveBlocksFromFarms(farmList: FarmDTO[]) {
    for (const f of farmList) {
      let blockList: BlockDTO[] = [];

      for (const b of f.blocks) {
        if (b.isActive === true) {
          blockList.push(b);
        }
      }

      f.blocks = blockList;
    }

    return farmList;
  }

  public orderVarietiesByName(cropList: CropVariety[]): CropVariety[] {
    return cropList.sort(function (a, b) {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });
  }

  blockIsMapped(blk: BlockDTO): boolean {
    var gpsPoints = blk.gpsPoints.filter(g => g.isActive === true);

    if (gpsPoints === null || gpsPoints === undefined || gpsPoints.length <= 0) {
      return false;
    }
    return true;
  }
  /*** URL Functions ****/

  // get subdomain
  public getSubdomain(): string {
    let subdomain: string;

    const domain = window.location.hostname;

    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www' || domain.split('.')[0] === 'hopheadfarms' ) {
      subdomain = 'portal';
    } else {

      subdomain = domain.split('.')[0];
    }
    return subdomain.toLocaleLowerCase();

  }

  public getHostURL(): string {
    let subdomain: string;

    const domain = window.location.hostname;

    if (domain.toLocaleLowerCase() === 'localhost')
      return 'http://localhost:4200';

    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      subdomain = 'portal';
    } else {

      subdomain = domain.split('.')[0];
    }


    return 'https://' + subdomain.toLocaleLowerCase() + '.' + domain.toLocaleLowerCase();

  }

  public filterArrayByArray(array: any[], filterArray: any[]): boolean {
    var filteredArray = array.filter(el => {


      for (var i = 0; i < filterArray.length; i++) {
        if (el == filterArray[i]) {
          return true;
        }
      }
      return false;
    });
    
    return filteredArray.length > 0;
  }
}
