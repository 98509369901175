import { Injectable } from '@angular/core';

import { PickPlanDTO } from '../models/pick-plan-dto';
import { PickDTO } from '../models/pick-dto';
import { FarmDTO } from '../../../core/models/farm';
import { BlockDTO } from '../../../core/models/block';
import { LotSelectorData } from '../widgets/lot-selector/lot-selector.component';
import { StorageByRoomItem } from '../pages/storage-by-room/storage-by-room-table/storageByRoomItem';


@Injectable({
  providedIn: 'root'
})
export class PickPlanSessionService {

  constructor(

  ) { 
  }

  // Pick Plan Session Variables
  public loadFilterWarehouse: string;
  public loadFilterPicktypeId: number;
  public loadFilterBlockId: number;
  public loadFilterDate: Date;
  public pick: PickDTO;
  public isPopup: boolean;
  public farmList: string[];
  public farmDTO: FarmDTO[];
  public blockList: string[];
  public blockDTO: BlockDTO[];
  public storageList: string[];
  public varietyList: string[];
  public pickPlan: PickPlanDTO;
  public allPickPlans: PickPlanDTO[];
  public selectedPick: PickDTO;
  public fieldmanList: string[];
  public lotSelectorData: LotSelectorData;
  public tagNumber: number;
  public farmName: string;
  public blockName: string;
  public varietyName: string;
  public startDate: Date;
  public endDate: Date;
  public storageByRoomItem: StorageByRoomItem;
  public selectedCropYearId: number;

}
