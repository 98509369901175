import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';

import { Observable } from 'rxjs';
import { Carrier } from '../models/carrier';



@Injectable({
  providedIn: 'root'
})
export class carrierService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getCarriers(includeInactive: boolean = false): Observable<Carrier[]> {
    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();
    if (includeInactive != null) {
        queryParameters = queryParameters.set('includeInactive', String(includeInactive));
    }

    return this.httpClient.get<Carrier[]>(`${this.basePath}/api/carriers`, {
        headers,
    });
}

  public getCarrier(carrierId: number): Observable<Carrier> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Carrier>(`${this.basePath}/api/carriers/${carrierId}`,
      {
        headers,
      }
    );
  }

  public postCarrierDTO(carrierDTO: Carrier): Observable<Carrier> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<Carrier>(`${this.basePath}/api/carriers`, carrierDTO,

      {
        headers,
      }
    );
  }

  public putCarrierDTO(carrierDTO: Carrier): Observable<Carrier> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<Carrier>(`${this.basePath}/api/carriers/${carrierDTO.id}`, carrierDTO,

      {
        headers,
      }
    );
  }

  public deleteCarrierDTO(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/carriers/${id}`,
      {
        headers,
      }
    );
  }

}
