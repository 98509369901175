import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatPaginator, MatSort, MatSortable } from '@angular/material';
import { FieldTaskTableDataSource } from './field-task-table-datasource';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { KragUtilitiesService } from '../../../../../@core/utils/krag-utilities.service';
import { SessionService } from '../../../../../@core/utils/session.service';
import { ReportService } from '../../../../../reports/report.service';
import { faEye, faEdit, faFileAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FieldTaskDTO } from '../../../../../harvest-engine/modules/field-events/models/field-task';
import * as _ from 'underscore';
import { FarmsService } from '../../../../../harvest-engine/core/services/farms.service';
import { VarietyService } from '../../../../../harvest-engine/core/services/variety.service';
import { CropVariety } from '../../../../../harvest-engine/core/models/crop-variety';
import { BlockDTO } from '../../../../../harvest-engine/core/models/block';
import { FarmDTO } from '../../../../../harvest-engine/core/models/farm';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kw-field-task-table',
  templateUrl: './field-task-table.component.html',
  styleUrls: ['./field-task-table.component.css']
})
export class FieldTaskTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: FieldTaskTableDataSource;

  @Output() Loaded = new EventEmitter();
  @Output() fieldTaskViewSelected = new EventEmitter<FieldTaskDTO>();
  @Output() fieldTaskEditSelected = new EventEmitter<FieldTaskDTO>();
  @Output() fieldTaskSelected = new EventEmitter<FieldTaskDTO>();
  // @Output() loading = new EventEmitter<boolean>();

  @Input() isReport: boolean = false;

  // Field Task Bar Data
  @Input() fieldTaskBarData: FieldTaskDTO[];

  // Used for the report.  If this is empty, it will load all
  @Input() selectedFieldTasks: FieldTaskDTO[];

  loading: boolean = true;
  public faEye = faEye;
  public faEdit = faEdit;
  public faFileAlt = faFileAlt;
  public faCheck = faCheck;
  public faTimes = faTimes;
  faSearch = faSearch;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['startDate', 'dueDate', 'assignedUsers', 'requestedBlocks', 
                      'requestedFieldEventType', 'fieldEvents', 'isUserCompleted', 'completedBy', 'completedDate', 'requestorComments'];

  private fieldTasks: FieldTaskDTO[];

  constructor(
    private authService: AuthService,
    private fieldTasksService: FieldTasksService,
    // private fieldEventService: FieldEvent,
    private errorService: ErrorService,
    private farmService: FarmsService,
    private vrtService: VarietyService,
    private kragUtilities: KragUtilitiesService,
    private reportService: ReportService,
    private sessionService: SessionService,
  ) { }

  // Search Variables
  searchString: string;
  includeCompleted: boolean;
  varieties: CropVariety[] = [];
  selectedFieldTask: FieldTaskDTO[] = [];
  selectedVarietyId: number;
  selectedFarmId: number;
  selectedBlock: BlockDTO = {};
  farms: FarmDTO[] = [];

  // form = new FormGroup({
  //   grower: new FormControl('grower'),
  //   variety: new FormControl('variety'),
  // });



  ngOnInit() {
    this.dataSource = new FieldTaskTableDataSource(this.paginator, this.sort);
    this.loadSearchParams();
    this.loadFieldTasks();
    this.loadDropdowns();
    if(!this.isReport && this.fieldTaskBarData == undefined){
      // , 'edit', 'view'
      this.displayedColumns.push('view');
      this.displayedColumns.push('edit');

    }
  }

  loadSearchParams() {
    this.searchString = this.sessionService.getSearchParam("searchString");
    this.selectedFarmId = this.sessionService.getSearchParam("selectedFarm");
    this.selectedVarietyId = this.sessionService.getSearchParam("selectedVarietyId");
    this.includeCompleted = this.sessionService.getSearchParam("includeInactive") || false;

  }

  loadFieldTasks() {
    this.loading = true;

    if (this.fieldTaskBarData !== undefined) {
      
      this.fieldTasks = this.fieldTaskBarData;
      this.search();
      this.sort.sort(<MatSortable>{
        id: 'isUserCompleted',
        start: 'asc'
      })

      if(this.isReport){
        this.dataSource.setPaginator(this.fieldTasks.length);
      }
      this.loading = false;
    } else if (this.selectedFieldTasks === [] || this.selectedFieldTasks === undefined || this.selectedFieldTasks === null && this.fieldTaskBarData == undefined){
      this.fieldTasksService.apiFieldTasksGet(null, true).subscribe(tsks => {
        this.fieldTasks = tsks;
        this.search();
        this.sort.sort(<MatSortable>{
          id: 'isUserCompleted',
          start: 'asc'
        })
  
        if(this.isReport){
          this.dataSource.setPaginator(this.fieldTasks.length);
        }
        this.loading = false;
        
        this.Loaded.emit();
  
      }, err => {
        this.errorService.handleError(err);
      });
    } else {
      this.fieldTasks = this.selectedFieldTasks; 
      this.dataSource.data = this.selectedFieldTasks;
      this.dataSource.setPaginator(this.selectedFieldTasks.length);
      setTimeout(() => (this.Loaded.emit()), 1000);
      this.loading = false;
      
    }
   

  }

  updateFieldTaskTable(tsks: FieldTaskDTO[]) {
    this.loading = true;
    this.dataSource.data = tsks;
    // hacky way to update the UI by forcing it to change pagination to the same thing.
    this.paginator._changePageSize(this.paginator.pageSize);
    this.loading = false;
  }

  search() {

    // this.fieldTasks = _.sortBy(this.fieldTasks, 'startDate');

    let filteredFieldTasks: FieldTaskDTO[] = JSON.parse(JSON.stringify(this.fieldTasks));

    if (this.selectedVarietyId === null || this.selectedVarietyId == 0 || this.selectedVarietyId == null) {
      filteredFieldTasks = filteredFieldTasks;
      this.sessionService.addSearchParam("selectedVarietyId", this.selectedVarietyId);
    } else {
      filteredFieldTasks = filteredFieldTasks.filter(ft => this.filterVariety(ft, this.selectedVarietyId) === true);
      this.sessionService.addSearchParam("selectedVarietyId", this.selectedVarietyId);
    }

    if (this.selectedFarmId === null || this.selectedFarmId == 0) {
      filteredFieldTasks = filteredFieldTasks;
      this.sessionService.addSearchParam("selectedFarm", this.selectedFarmId);
    } else {
      filteredFieldTasks = filteredFieldTasks.filter(ft => this.filterFarms(ft, this.selectedFarmId) === true);
      this.sessionService.addSearchParam("selectedFarm", this.selectedFarmId);
    }

    if (this.searchString !== undefined && this.searchString !== null && this.searchString !== '') {
      filteredFieldTasks = filteredFieldTasks.filter(ft => this.filterBlocks(ft) === true);
      this.sessionService.addSearchParam("searchString", this.searchString);
      
    }

    // include completed
    if(this.includeCompleted) {
      filteredFieldTasks = filteredFieldTasks.filter(b => (b.isUserCompleted = true));
      this.sessionService.addSearchParam("includeCompleted", this.includeCompleted);
    } else {
      filteredFieldTasks = filteredFieldTasks;
      this.sessionService.addSearchParam("includeCompleted", this.includeCompleted);
    }

    this.updateFieldTaskTable(filteredFieldTasks);

  }

  editFieldTaskSelected(task: FieldTaskDTO) {
    this.fieldTaskEditSelected.emit(task);
  }

  viewFieldTaskSelected(task: FieldTaskDTO) {
    this.fieldTaskViewSelected.emit(task);
  }

  filterFarms(ft: FieldTaskDTO, farmId: number): boolean {
    let farmCount = ft.requestedBlocks.filter(b => b.farmId === farmId).length;
    if(farmCount > 0){
      return true;
    }
    return false;
  }

  filterVariety(ft: FieldTaskDTO, varietyId: number): boolean {
    let varietyCount = ft.requestedBlocks.filter(b => b.varietyId === varietyId).length;
    if(varietyCount > 0){
      return true;
    }
    return false;
  }

  filterBlocks(b: FieldTaskDTO): boolean {
    let blockCount = b.requestedBlocks.filter(b => (b.name.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1) ).length;
    if(blockCount > 0){
      return true;
    }
    return false;
  }

  loadDropdowns() {
    this.vrtService.getVarieties().subscribe(vrts => {
      this.varieties = this.kragUtilities.orderVarietiesByName(vrts);
    }, err => {
      this.errorService.handleError(err);
    });

    this.farmService.apiFarmsGet().subscribe(f => {
      this.farms = this.kragUtilities.sortFarmsAndBlocks(f);
    }, err => {
      this.errorService.handleError(err);
    });
  }

  generateReport() {
    // Use the table data.
    //this.sessionService.selectedFieldTasks = [];

    for(let data of this.dataSource.data) {
      this.sessionService.selectedFieldTasks.push(JSON.parse(JSON.stringify(data)));
    }
    this.sessionService.selectedFieldTasks = this.dataSource.data;

    this.reportService.printReport("field-task-overview", []);
  }


}
