import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpEvent, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { FieldTaskDTO } from '../models/field-task';


@Injectable({
  providedIn: 'root'
})
export class FieldTasksService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this.defaultHeaders = this.defaultHeaders.set('Authorization', String(this.authService.getUserToken()));
  }

  public apiFieldTasksByIdDelete(id: number): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFieldTasksByIdDelete.');
    }

    let headers = this.defaultHeaders;

    return this.httpClient.delete(`${this.basePath}/api/FieldTasks/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
      }
    );
  }

  public apiFieldTasksByIdGet(id: number): Observable<FieldTaskDTO>{
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFieldTasksByIdGet.');
    }

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/FieldTasks/${encodeURIComponent(String(id))}`,
      {

        headers: headers,


      }
    );
  }


  public apiFieldTasksByIdPut(id: number, body?: FieldTaskDTO): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFieldTasksByIdPut.');
    }

    let headers = this.defaultHeaders;


    return this.httpClient.put(`${this.basePath}/api/FieldTasks/${encodeURIComponent(String(id))}`, body,
      {
        headers: headers,
      }
    );
  }


  public apiFieldTasksGet(fieldTaskTypeId?: number, isActive?: boolean, blockId?: number, farmId?: number, isUserCompleted?: boolean, dueDate?: string, startDate?: string, createdByUserId?: string): Observable<Array<FieldTaskDTO>> {


    let queryParameters = new HttpParams();
    if (fieldTaskTypeId !== undefined && fieldTaskTypeId !== null) {
      queryParameters = queryParameters.set('fieldTaskTypeId', <any>fieldTaskTypeId);
    }
    if (isActive !== undefined && isActive !== null) {
      queryParameters = queryParameters.set('isActive', <any>isActive);
    }
    if (blockId !== undefined && blockId !== null) {
      queryParameters = queryParameters.set('blockId', <any>blockId);
    }
    if (farmId !== undefined && farmId !== null) {
      queryParameters = queryParameters.set('farmId', <any>farmId);
    }
    if (isUserCompleted !== undefined && isUserCompleted !== null) {
      queryParameters = queryParameters.set('isUserCompleted', <any>isUserCompleted);
    }
    if (dueDate !== undefined && dueDate !== null) {
      queryParameters = queryParameters.set('dueDate', <any>dueDate);
    }
    if (startDate !== undefined && startDate !== null) {
      queryParameters = queryParameters.set('startDate', <any>startDate);
    }
    if (createdByUserId !== undefined && createdByUserId !== null) {
      queryParameters = queryParameters.set('createdByUserId', <any>createdByUserId);
    }

    let headers = this.defaultHeaders;


    return this.httpClient.get<FieldTaskDTO[]>(`${this.basePath}/api/FieldTasks`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public apiFieldTasksPost(body?: FieldTaskDTO): Observable<FieldTaskDTO> {

    let headers = this.defaultHeaders;

    return this.httpClient.post(`${this.basePath}/api/FieldTasks`, body,
      {
        headers: headers,
      }
    );
  }

}
