import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { ReasonCode } from '../../modules/inventory/models/reason-code';
import { Transaction } from '../models/transaction';
import { TransactionReasonCode } from '../models/transactionReasonCode';
// import moment = require('moment-timezone');
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public wordList: TransactionReasonCode[] = [];
  public isLoading: boolean = true;
  private AuthToken: string;

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public setToken(token: string) {
    this.AuthToken = token;
  }

  

  public getTransactionReasonCodes(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('IncludeInactive', <any>includeInactive);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<TransactionReasonCode[]>(`${this.basePath}/api/TransactionReasonCodes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getTransactions(startDate: Date = null, endDate: Date = null) {

    let queryParameters = new HttpParams();

    endDate = moment(endDate).endOf('day').toDate();

    if(startDate && endDate) {
      queryParameters = queryParameters.set('startDate', startDate.toISOString());
      queryParameters = queryParameters.set('endDate', endDate.toISOString());
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<Transaction[]>(`${this.basePath}/api/Transactions`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getTransactionsByReason(lotId: number, reason: string) {

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('lotId', lotId.toString());
    queryParameters = queryParameters.set('reason', reason);

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<Transaction[]>(`${this.basePath}/api/Transactions/ByReason`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public postTransactions(transactions: Transaction[]): Observable<any> {
      
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<Transaction[]>(`${this.basePath}/api/Transactions/CreateMultiple`, transactions,

      {
        headers
      }
    );
  }


  public deleteCode(id: number) {

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.delete(`${this.basePath}/api/TransactionReasonCodes/${id}`,
      {
        headers,
      }
    );
  }


  public createCode(reasonCode: ReasonCode) {

    let queryParameters = new HttpParams();


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.post<TransactionReasonCode[]>(`${this.basePath}/api/TransactionReasonCodes`, reasonCode,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }


  public updateCode(reasonCode: ReasonCode) {

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<TransactionReasonCode[]>(`${this.basePath}/api/TransactionReasonCodes/${reasonCode.id}`, reasonCode,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

}
