import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';

import { DEMO_CREWS, Fp_Crew } from '../../models/fp-crew';

@Injectable({
  providedIn: 'root'
})
export class FpCrewService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL; 
    this.defaultHeaders = this.defaultHeaders.set('Authorization', this.authService.getUserToken());

  }

  public getCrews(): Observable<Fp_Crew[]> {

    if (environment.isMockEnabled) {
      return Observable.of(DEMO_CREWS);
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();



    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Fp_Crew[]>(`${this.basePath}/api/FirmanPollen/FpCrew`,
      {
        headers,
      }
    );
  }

  public putCrew(crew: Fp_Crew): Observable<Fp_Crew> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();



    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.put<Fp_Crew>(`${this.basePath}/api/FirmanPollen/FpCrew/${crew.id}`, crew,
      {
        headers,
      }
    );
  }


  public postCrew(crew: Fp_Crew): Observable<Fp_Crew> {

    if (environment.isMockEnabled) {
      return Observable.of(crew);
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.post<Fp_Crew>(`${this.basePath}/api/FirmanPollen/FpCrew`,
      crew,
      {
        headers,
      }
    );
  }

  public deleteCrew(id: number): Observable<Fp_Crew> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('setInactive', "false");

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<Fp_Crew>(`${this.basePath}/api/FirmanPollen/FpCrew/${id}`,
      {
        headers: headers,
        params: queryParameters,
      }
    );
  }
}


