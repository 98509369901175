import { Component, OnInit } from '@angular/core';
import { faPrint } from '@fortawesome/pro-duotone-svg-icons';
import { ReportService } from '../../../../reports/report.service';
import { WarehouseService } from '../../services/storage/warehouse-service';
import { FilterSettings } from '../../widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../widgets/kw-filter-dropdown/models/filter-results';
import { LocationBarcodeReportService } from './location-barcode-report.service';
import { KwFilterDropdownService } from '../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'location-barcode-list',
  templateUrl: './location-barcode-list.component.html',
  styleUrls: ['./location-barcode-list.component.scss']
})
export class LocationBarcodeListComponent implements OnInit {

  public isLoading = false;
  private initialLoad = true;


  public dropdownSettings: FilterSettings = {

    warehouseSettings: {
      display: true,
      singleSelection: false
    },
    roomSettings: {
      display: true,
      singleSelection: false
    },
    locationSettings: {
      display: true,
      singleSelection: false
    },
  };

  public faPrint = faPrint;

  constructor(
    public warehouseService: WarehouseService,
    public barcodeService: LocationBarcodeReportService,
    public reportService: ReportService,
    public filterService: KwFilterDropdownService
  ) { }

  ngOnInit() {
    
    if (!this.barcodeService.isReport) {
      this.isLoading = true;
      this.warehouseService.getWarehouses().subscribe(x => {
        this.barcodeService.allWarehouses = x;
        this.barcodeService.selectedWarehouses = x;
        this.isLoading = false;
      })
    } else {
      this.reportService.onDataReady();
      setTimeout(x => {

        this.barcodeService.isReport = false;
      }, 1000);

    }

  }

  printReport() {
    this.barcodeService.isReport = true;

    this.reportService.printReport('location-barcode-report', ['']);
  }

  setFilterResults(results: FilterResults) {
    if (this.initialLoad) {
      this.initialLoad = false;
      results = this.filterService.getFilterState();
    }

    this.barcodeService.selectedWarehouses = JSON.parse(JSON.stringify(this.barcodeService.allWarehouses.filter(x =>
      (results.selectedWarehouses.length == 0 || results.selectedWarehouses.map(y => y.id).includes(x.id))
    )));

    for (let warehouse of this.barcodeService.selectedWarehouses) {
      warehouse.rooms = [...warehouse.rooms.filter(x =>
        (results.selectedRooms.length == 0 || results.selectedRooms.map(y => y.id).includes(x.id))
      )]

      for(let room of warehouse.rooms) {
        room.locations = [...room.locations.filter(x => 
          (results.selectedLocations.length == 0 || results.selectedLocations.map(y => y.id).includes(x.id))
        )]
      }
    }

  }

}
