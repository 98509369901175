import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { faBars, faCheck, faSearch, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faPalletAlt, faScanner } from '@fortawesome/pro-solid-svg-icons';
import { AggregateColumnModel, AggregateRowModel, Column, ColumnModel, FilterSettingsModel, GridComponent, GroupSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { KragUtilitiesService } from '../../../../../@core/utils/krag-utilities.service';
import { KwFilterDropdownService } from '../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { FilterSettings } from '../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { HandlingUnitDetailDTO } from '../../models/handling-unit-detail';
import { HandlingUnitMasterDTO } from '../../models/handling-unit-master';
import { InventoryItemsDataSource } from '../inventory-items/inventory-items/inventory-items-datasource';
import { InventoryHumEditComponent } from './inventory-hum-edit/inventory-hum-edit.component';
import { InventoryScanPopupComponent } from './inventory-scan-popup/inventory-scan-popup.component';
import * as _ from 'underscore';
import { InventorySummaryPopupComponent } from './inventory-summary-popup/inventory-summary-popup.component';
import { faBox } from '@fortawesome/pro-duotone-svg-icons';
import * as moment from 'moment';
import { NbToastrService } from '@nebular/theme';
import { InventorySessionService } from '../../services/inventory-session.service';
import { HandlingUnitBulkMove } from '../../models/handling-unit-bulk-actions';


export interface InventorySummaryTableBaseItem {
  id?: number;
  // Hum Values
  updatedDate?: Date;
  createdDate?: Date;
  createdBy?: string;
  createdByUserId?: number;
  barcode?: string;
  roomLocationId?: number | null;
  roomLocationName?: string;
  roomId?: number | null;
  roomName?: string;
  warehouseId?: number | null;
  warehouseName?: string;
  inventoryStatusId?: number | null;
  inventoryStatusName?: string;

  // hud values
  quantity?: string;
  itemId?: number | null;
  itemName?: string;
  itemDescription?: string;
  blockId?: number | null;
  blockName?: string;
  cropId?: number;
  cropName?: string;
  varietyName?: string;
  varietyId?: number | null;
  varietyColor?: string;
  varietyShortName?: string;
  farmName?: string;
  farmId?: number | null;
  fieldman?: string;
  famousIntegrationId?: string;
  lotId?: number;
  lotNumber?: string;
  loadId?: number | null;
  loadNumber?: string;
  cropYearId?: number | null;
  cropYear?: string;
  lotExperationDate?: string;
  alphaLotAttribute?:string;

  parentItemId?: number;
  parentItem?: string;

  packageComponentName?: string;
  packageComponentQuantityPerPackage?: number;
  packageComponentUnitOfMeasure?: string;
  packageComponentWeight?: number;
  packageComponentWeightUnitOfMeasure?: string;
  packageDescription?: string;
  packageHasComponents?: boolean;
  packageName?: string;
  packageQuantityPerPallet?: number;
  packageUnitOfMeasure?: string;

  lastTransaction?: string;

  orderNumber?: string;
  orderId?: number;
  weight?: number;
}

@Component({
  selector: 'inventory-summary',
  templateUrl: './inventory-summary.component.html',
  styleUrls: ['./inventory-summary.component.css']
})
export class InventorySummaryComponent implements OnInit {


  // Icons //
  public faBars = faBars;
  public faSearch = faSearch;
  public faScanner = faScanner;
  public faTruck = faTruck;
  public faCheck = faCheck;
  public faBox = faBox;

  public pageSize = 50;
  public currentPage = 1;
  faPalletAlt = faPalletAlt;

  public groupOptions: GroupSettingsModel = {
    columns: ['barcode'],
    showDropArea: true

  };


  @ViewChild('actionTemplate')
  public actionTemplate: any;

  @ViewChild('grid') grid: GridComponent;
  public toolbarOptions: string[] = ['PdfExport', 'ExcelExport'];

  public pageSettings: PageSettingsModel = {
    pageSize: 50,
    pageSizes: [50, 100, 200, 250]
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"

  }


  public aggregates: AggregateRowModel[] = [];

  public aggregate: AggregateRowModel = {
    columns: [{
      type: 'Sum',
      field: 'inventoryStatusName',
      format: 'C2',
      footerTemplate: 'Totals:'
    }]
  };

  public items: HandlingUnitMasterDTO[] = [];
  public filteredItems: HandlingUnitMasterDTO[] = [];

  public tableData: InventorySummaryTableBaseItem[] = [];
  public displayedColumns: ColumnModel[] = [];
  public baseColumns: ColumnModel[] = [];

  public isLoading: boolean = true;
  private initialLoad: boolean = true;
  public bottomSheetSubscription: Subscription;

  public dropdownSettings: FilterSettings = {
    inventoryItemSettings: {
      display: true,
      singleSelection: false
    },
    locationSettings: {
      display: true,
      singleSelection: false
    },
    varietySettings: {
      display: false,
      singleSelection: false,
    },
    roomSettings: {
      display: true,
      singleSelection: false,
    },
    warehouseSettings: {
      display: true,
      singleSelection: false
    },
    lotNumberSettings: {
      display: true,
      singleSelection: false
    }

  };

  constructor(
    private inventoryService: InventoryService,
    private errorService: ErrorService,
    private bottomSheet: MatBottomSheet,
    private changeDetRef: ChangeDetectorRef,
    private session: InventorySessionService,
    private toastr: NbToastrService,
    public filterService: KwFilterDropdownService,
    private router: Router,
    public dialog: MatDialog,
    private kragUtils: KragUtilitiesService,
    public authService: AuthService,
  ) { }

  ngOnInit() {

    this.baseColumns = [
      { headerText: 'Barcode', field: 'barcode', autoFit: true },
      { headerText: 'Status', field: 'inventoryStatusName', autoFit: true },
      //{ headerText: 'Location', field: 'location', autoFit: true },
      { headerText: 'Product', field: 'itemName', autoFit: true },
      { headerText: 'Lot', field: 'lotNumber', autoFit: true },
      { headerText: 'Alpha', field: 'alphaLotAttribute', autoFit: true },
      { headerText: 'Order #', field: 'orderNumber', autoFit: true },
      { headerText: 'Weight', field: 'weight', autoFit: true, format: "N0" },
    ]

    this.loadData();

    this.pageSettings = {
      pageSize: this.pageSize,
      currentPage: this.currentPage
    }


  }

  loadData() {
    this.inventoryService.getInventorySummary().subscribe(x => {
      this.items = x;
      this.filteredItems = this.items;

      this.filterService.filter();

      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }
  toolbarClick(args: any) {
    if (args.item.id === 'grid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
      this.grid.pdfExport();
    }

    if (args.item.id === 'grid_excelexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
      this.grid.excelExport();
    }
  }

  openDialog(row: HandlingUnitMasterDTO) {
    this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary-popup?humId=" + row.id + "&isActive=" + row.isActive);

  }


  createHUM() {

    let data = {
      Hums: this.items
    }
    this.bottomSheet.open(InventoryHumEditComponent, {
      panelClass: 'bottomsheet-100-maxHeight',
      data,
      disableClose: true
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(event => {
      if (event)
        this.openDialog(event);
    });

  }

  moveBulkHUM() {
    if (this.filterService.filterResults.selectedInventoryItems.length == 0 && this.filterService.filterResults.selectedWarehouses.length == 0 &&
      this.filterService.filterResults.selectedRooms.length == 0 && this.filterService.filterResults.selectedLocations.length == 0 &&
      this.filterService.filterResults.selectedLotNumbers.length == 0) {
      this.toastr.warning("Please select at least one filter to move inventory.", "Warning!");
    }
    else if (this.tableData.length == 0) {
      this.toastr.warning("Filtered inventory must be available to move.", "Warning!");
    }
    else {
      let bulkMoveList: HandlingUnitBulkMove[] = [];

      let groupData = this.tableData.reduce((r, a) => {
        r[a.barcode] = [...r[a.barcode] || [], a];
        return r;
      }, {});


      const barcodes = Object.keys(groupData);
      for (const barcode of barcodes) {
        const x = groupData[barcode];

        let warehouse = this.filterService.allWarehouses.find(y => y.id == x[0].warehouseId);
        let room = this.filterService.allRooms.find(y => y.id == x[0].roomId);
        let location = this.filterService.allLocations.find(y => y.id == x[0].roomLocationId);

        const uniqueItemsSet = new Set();
        x.forEach(item => {
          const value = item["itemName"];
          uniqueItemsSet.add(value);
        });

        const uniqueLotSet = new Set();
        x.forEach(item => {
          const value = item["lotNumber"];
          uniqueLotSet.add(value);
        });

        bulkMoveList.push({
          humId: x[0].id,
          warehouseId: x[0].warehouseId ? x[0].warehouseId : 0,
          roomId: x[0].roomId ? x[0].roomId : 0,
          roomLocationId: x[0].roomLocationId ? x[0].roomLocationId : 0,
          roomLocationName: location ? location.name : "",
          roomName: room ? room.name : "",
          warehouseName: warehouse ? warehouse.name : "",
          tagNumber: x[0].barcode,
          details:  Array.from(uniqueItemsSet).join(', '), //(x.handlingUnitDetails.length > 0 ? x.handlingUnitDetails[0].itemName : ""),
          lot: Array.from(uniqueLotSet).join(', '), //(x.handlingUnitDetails.length > 0 ? x.handlingUnitDetails[0].lotNumber : ""),
          isMultiple: true,
        });

        if (!bulkMoveList[0].handlingUnitMasterIds) {
          bulkMoveList[0].handlingUnitMasterIds = [];
          bulkMoveList[0].handlingUnitMasterIds.push(x[0].id);
        } else {
          bulkMoveList[0].handlingUnitMasterIds.push(x[0].id);
        }
      }
      this.session.handlingUnitBulkMoveList = bulkMoveList;
      this.router.navigateByUrl("/harvest-engine/inventory/inventory-bulk-move");
    }
  }

  createBulkHUM() {

    let data = {
      Hums: this.items,
      isBulkCreate: true
    }
    this.bottomSheet.open(InventoryHumEditComponent, {
      panelClass: 'bottomsheet-100-maxHeight',
      data,
      disableClose: true
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(event => {
      if (event)
        this.loadData();
    });

  }

  getFooterTotal() {
    return this.filteredItems.reduce((accumulator, obj) => {
      return accumulator + this.getRowQTY(obj);
    }, 0);
  }

  // getFooterAggTotal() {
  //   var val = 0;
  //   this.tableData.forEach(x => {
  //     x.cu.forEach(c => {
  //       c.storageTypes.forEach(z => val += z.total)
  //     })
  //   })
  //   return formatNumber(val, 'en-US', '1.0-0');
  // }

  scan() {
    const dialogRef = this.dialog.open(InventoryScanPopupComponent, {
      minWidth: '30%',
      data: {
        Hums: this.items
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isHum)
        this.openDialog(x.hum);


      this.changeDetRef.detectChanges();
    });
  }


  getLocation(row: HandlingUnitMasterDTO) {
    var array = [row.warehouseName, row.roomName, row.roomLocationName].filter(x => x);

    return array.join(", ");

  }

  getRowQTY(row: HandlingUnitMasterDTO) {
    return row.handlingUnitDetails.reduce((acc, cur) => acc + cur.quantity, 0);
  }

  setFilterResults(results: FilterResults) {
    if (this.initialLoad) {
      this.initialLoad = false;
      results = this.filterService.getFilterState();
    }
    let filteredResults = this.items.filter(x =>
      x.isActive &&
      (results.selectedWarehouses.map(y => y.id).includes(x.warehouseId) || results.selectedWarehouses.length == 0) &&
      (results.selectedInventoryItems.length == 0 || this.kragUtils.filterArrayByArray(x.handlingUnitDetails.map(y => y.itemId), results.selectedInventoryItems.map(y => y.id))) &&
      (results.selectedRooms.map(y => y.id).includes(x.roomId) || results.selectedRooms.length == 0) &&
      (results.selectedLocations.map(y => y.id).includes(x.roomLocationId) || results.selectedLocations.length == 0) &&
      (results.selectedVarieties.length == 0 || this.kragUtils.filterArrayByArray(x.handlingUnitDetails.map(y => y.varietyId), results.selectedVarieties.map(y => y.id))) &&
      (results.selectedLotNumbers.length == 0 || this.kragUtils.filterArrayByArray(x.handlingUnitDetails.map(y => y.lotId), results.selectedLotNumbers.map(y => y.id)))
      //(results.selectedVarieties.map(y => y.id).includes(x.handlingUnitDetails.map(y =>).) || results.selectedVarieties.length == 0)
    );

    this.filteredItems = [...filteredResults];

    this.setTableData();

  }

  setTableData() {
    this.displayedColumns = [];
    this.aggregates = []
    this.tableData = [];

    let uniqItemAttr = _.uniq(this.filteredItems.flatMap(x => x.handlingUnitDetails).flatMap(x => x.itemAttributeValues).map(x => <any>{ name: x.name + "Cust", id: x.inventoryItemAttributeId }), true, x => x.name);

    let uniqComponents = _.uniq(this.filteredItems.flatMap(x => x.handlingUnitDetails).map(x => x.packageComponentName));
    let uniqUnitOfMeasures = _.uniq(this.filteredItems.flatMap(x => x.handlingUnitDetails).map(x => x.packageUnitOfMeasure));

    for (let hum of this.filteredItems) {
      for (let hud of hum.handlingUnitDetails) {
        let tableRecord: InventorySummaryTableBaseItem = {
          barcode: hum.barcode,
          blockId: hud.blockId,
          id: hum.id,
          blockName: hud.blockName,
          createdBy: hum.createdBy,
          createdByUserId: hum.createdByUserId,
          createdDate: hum.createdDate,
          cropId: hud.cropId,
          cropName: hud.cropName,
          cropYear: hud.cropYear,
          cropYearId: hud.cropYearId,
          famousIntegrationId: hud.famousIntegrationId,
          farmId: hud.farmId,
          farmName: hud.farmName,
          fieldman: hud.fieldman,
          inventoryStatusId: hum.inventoryStatusId,
          inventoryStatusName: hum.inventoryStatusName,
          itemDescription: hud.itemDescription,
          itemId: hud.itemId,
          itemName: hud.itemName,
          loadId: hud.loadId,
          loadNumber: hud.loadNumber,
          lotExperationDate: hud.lotExperationDate,
          lotId: hud.lotId,
          lotNumber: hud.lotNumber,
          orderId: hum.orderId,
          orderNumber: hum.orderNumber,
          packageComponentName: hud.packageComponentName,
          packageComponentQuantityPerPackage: hud.packageComponentQuantityPerPackage,
          packageComponentUnitOfMeasure: hud.packageComponentUnitOfMeasure,
          packageComponentWeight: hud.packageComponentWeight,
          packageComponentWeightUnitOfMeasure: hud.packageComponentWeightUnitOfMeasure,
          packageDescription: hud.packageDescription,
          packageHasComponents: hud.packageHasComponents,
          packageName: hud.packageName,
          packageQuantityPerPallet: hud.packageQuantityPerPallet,
          packageUnitOfMeasure: hud.packageUnitOfMeasure,
          parentItem: hud.parentItem,
          parentItemId: hud.parentItemId,
          quantity: hud.quantity.toLocaleString('en-us'),
          roomId: hum.roomId,
          roomLocationId: hum.roomLocationId,
          roomLocationName: hum.roomLocationName,
          roomName: hum.roomName,
          updatedDate: hum.updatedDate,
          varietyColor: hud.varietyColor,
          varietyId: hud.varietyId,
          varietyName: hud.varietyName,
          varietyShortName: hud.varietyShortName,
          warehouseId: hum.warehouseId,
          warehouseName: hum.warehouseName,
          lastTransaction: "",
          weight: hud.weight,
          alphaLotAttribute: !!hud.lotAttributeValues && !!hud.lotAttributeValues.find(x=>x.customAttributeName == "AA%" && x.isActive) ? hud.lotAttributeValues.find(x=>x.customAttributeName == "AA%" && x.isActive).value : ""
        };

        if (hum.transactions && hum.transactions.length > 0)
          tableRecord.lastTransaction = moment(_.sortBy(hum.transactions, x => x.createdDate).reverse()[0].createdDate).format('LL');

        for (let attr of uniqItemAttr) {
          let result = hud.itemAttributeValues.find(x => x.inventoryItemAttributeId == attr.id);
          tableRecord[attr.name] = result ? result.value : "";
        }

        // uom / quantity set
        let setQuantity = true;

        if (hud.packageUnitOfMeasure) {
          if (hud.packageHasComponents) {
            tableRecord[hud.packageUnitOfMeasure] = Math.floor(hud.quantity);
            tableRecord[hud.packageComponentName] = (hud.quantity % 2 * hud.packageComponentQuantityPerPackage)
          }

          setQuantity = false;
        }

        // if (!setQuantity)
        //   tableRecord.quantity = "";

        this.tableData.push(tableRecord);
      }
    }

    this.aggregate = {
      columns: [{
        type: 'Sum',
        field: 'inventoryStatusName',
        format: 'C2',
        footerTemplate: 'Totals:'
      }]
    };

    this.displayedColumns.push(...this.baseColumns);
    // this.displayedColumns.push(...uniqItemAttr.map(x => <ColumnModel>{ field: x.name, headerText: x.name.slice(0, -4), autoFit: true}));
    this.displayedColumns.push({ headerText: 'Quantity', field: 'quantity', autoFit: true });
    // this.displayedColumns.push(...uniqUnitOfMeasures.map(x => <ColumnModel>{ field: x, headerText: x, autoFit: true, format: 'N2'}));
    // this.displayedColumns.push(...uniqComponents.map(x => <ColumnModel>{ field: x, headerText: x, autoFit: true, format: 'N2'}));

    this.aggregate.columns.push(...uniqUnitOfMeasures.map(x => <AggregateColumnModel>{
      field: x,
      type: 'Sum',
      format: 'N2',
      footerTemplate: '${Sum}'
    }));

    this.aggregate.columns.push(...uniqComponents.map(x => <AggregateColumnModel>{
      field: x,
      type: 'Sum',
      format: 'N2',
      footerTemplate: '${Sum}'
    }));

    this.displayedColumns.push({
      headerText: "Created Date",
      field: 'createdDate',
      type: 'date',
      format: 'MM/dd/yyyy',
      autoFit: true
    });

    // this.displayedColumns.push({
    //   headerText: "Last Transaction",
    //   field: 'lastTransaction',
    //   autoFit: true
    // });

    this.displayedColumns.push({
      headerText: "Actions",
      template: this.actionTemplate,
      width: 100
    });


    this.aggregates.push(this.aggregate);
  }
}
