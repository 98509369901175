import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../@core/utils/error.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { ContractDTO } from '../models/contract';
import { ContractLine } from '../models/lineItem';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    // this.basePath = environment.HOLLINGBERY_API_URL;
    this.basePath = environment.PLATFORM_API_URL;


  }


  public getContractTemplates(): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.get<any>(`${this.basePath}/api/contracttemplate`,

      {
        headers,
      }
    );
  }
  public getContracts(): Observable<ContractDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.get<ContractDTO>(`${this.basePath}/api/contract`,

      {
        headers,
      }
    );
  }


  public getCustomerContracts(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.get<any>(`${this.basePath}/api/contract/Current/${id}`,

      {
        headers,
      }
    );
  }

  public deleteContract(id: number): Observable<any>{

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.delete(`${this.basePath}/api/contract/${id}`,

      {
        headers,
      }
    );
  }



  public getContractById(id: number): Observable<ContractDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<ContractDTO>(`${this.basePath}/api/contract/${id}`,

      {
        headers,
      }
    );
  }
  public getLastContractId(): Observable<number> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.get<number>(`${this.basePath}/api/contract/lastId`,

      {
        headers,
      }
    );
  }

  public postContract(dto: ContractDTO): Observable<ContractDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    return this.httpClient.post<ContractDTO>(`${this.basePath}/api/contract`, dto,

      {
        headers,
      }
    );
  }
  public putContract(dto: ContractDTO, id: number): Observable<ContractDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    // console.log(dto)
    // console.log(id)
    // console.log(`${this.basePath}/api/contract/${id}`)

    return this.httpClient.put<ContractDTO>(`${this.basePath}/api/contract/${id}`, dto,

      {
        headers,
      }
    );
  }



  // public postContractLine(dto: ContractLine): Observable<ContractLine> {

  //   let headers = this.defaultHeaders;

  //   headers = headers.set('Authorization', String(this.authService.getUserToken()));

  //   const httpHeaderAcceptSelected: string | undefined = 'application/json';
  //   if (httpHeaderAcceptSelected !== undefined) {
  //     headers = headers.set('Accept', httpHeaderAcceptSelected);
  //   }

  //   // to determine the Content-Type header
  //   const consumes: string[] = [
  //   ];
  //   return this.httpClient.post<ContractLine>(`${this.basePath}/api/contractLines`, dto,

  //     {
  //       headers,
  //     }
  //   );
  // }
}
