import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Order } from '../models/order';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { environment } from '../../../../../../../environments/environment';
import { AvailableLot } from '../models/available-lot';
import { PickList } from '../models/pick-list';
import { DailyBuildList } from '../models/build-list';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { PromotionDTO } from '../models/promotion';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  protected basePath = '/';

  protected hubPath = '/';
  public defaultHeaders = new HttpHeaders();
  public allOrders: Order[] = [];
  public selectedPickDate: Date; 

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {
    this.basePath = environment.HOLLINGBERY_API_URL;
    this.hubPath = environment.PLATFORM_API_URL;
  }

  public getOrderStatuses(): string[] {
    return [
      'Pending',
      'Pre-pay',
      'Staged',
      'Preparing for Shipment',
      'Ready to Ship',
      'Verified for Shipment',
      'Shipped',
      'Final Review',
      'Completed'
    ]
  }

  public getAvailableLots(variety: string, cropYear: number): Observable<AvailableLot[]> {
    
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    queryParameters = queryParameters.set('cropYear', (cropYear || '').toString());
    queryParameters = queryParameters.set('variety', (variety || '').toString());

    
    return this.httpClient.get<AvailableLot[]>(`${this.basePath}/api/orders/availableLot`,
    // return this.httpClient.get<AvailableLot[]>(`${this.hubPath}/api/inventory/lots/available`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getOrders(startDate?: Date, endDate?: Date): Observable<Order[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    
    if(startDate && endDate) {
      queryParameters = queryParameters.set('startDate', startDate.toISOString());
      queryParameters = queryParameters.set('endDate', endDate.toISOString());
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Order[]>(`${this.basePath}/api/orders`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public putOrder(order: Order): Observable<Order[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<Order[]>(`${this.basePath}/api/orders/${order.recordId}`, order,

      {
        headers,
      }
    );
  }

  public getOrder(orderId: number): Observable<Order> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Order>(`${this.basePath}/api/orders/${orderId}`,
      {
        headers,
      }
    );
  }

  public getPickList(pickDate: Date = null): Observable<PickList[]> {
    
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();

    if (pickDate) {
      queryParameters = queryParameters.set('pickDate', (pickDate.toISOString() || new Date().toISOString()).toString());
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PickList[]>(`${this.basePath}/api/orders/picklist`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public updateAllOrders() {
    this.getOrders().subscribe(x => {
      this.allOrders = x;
    }, err => {
      this.errorService.handleError(err);
    });
  }

  public getPromotions(): Observable<PromotionDTO[]> {
    let headers = this.defaultHeaders.set('Authorization', String(this.authService.getUserToken()));
    const httpHeaderAcceptSelected: string | undefined = 'application/json';

    if (httpHeaderAcceptSelected !== undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<PromotionDTO[]>(`${this.hubPath}/api/Promotion/Promotions`,
    {
      headers,
    }
  );
    ;
  }

}
