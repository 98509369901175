import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { InventoryLotDTO, InventoryLotWithHUM } from '../../../models/inventory-lot';
import { ScanPopupComponent } from '../../../../laboratory/pages/lot-summary/scan-popup/scan-popup.component';
import { Observable } from 'rxjs';
import { ReasonCode } from '../../../models/reason-code';
import { faArrowAltCircleLeft, faPlusCircle, faScanner, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { NgForm } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatDialog } from '@angular/material';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { BlockDTO } from '../../../../../core/models/block';
import { CropVariety } from '../../../../../core/models/crop-variety';
import { CropYear } from '../../../../../core/models/cropYear';
import { RoomService } from '../../../../../core/services/storage/room-service';
import { WarehouseService } from '../../../../../core/services/storage/warehouse-service';
import { LabQuantityAddComponent } from '../../../../laboratory/pages/lot-summary/lab-quantity-add/lab-quantity-add.component';
import { lotInventory } from '../../../../laboratory/services/lab-quantity-service';
import { lotInventoryService } from '../../../../laboratory/services/lab-quantity-service';
import { LoadViewModel } from '../../../../pick-plan/models/load-view-model';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { InventoryItemDTO } from '../../../models/item';
import { InventoryLotAttributeDTO } from '../../../models/lot-attribute';

export interface InventoryLabPopupData {
  lot: InventoryLotWithHUM;
  isPopup: boolean;
  lotId?: number;
  type?: string;
  lotName?: string;
}

@Component({
  selector: 'lot-details-by-room-move-inventory',
  templateUrl: './lot-details-by-room-move-inventory.component.html',
  styleUrls: ['./lot-details-by-room-move-inventory.component.scss']
})

export class LotDetailsByRoomMoveInventoryComponent implements OnInit {
  @ViewChild('lotInputForm') lotInputForm: NgForm;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: InventoryLabPopupData,
    private inventoryService: InventoryService,
    private lotInventoryService: lotInventoryService,
    private bottomSheet: MatBottomSheetRef<LotDetailsByRoomMoveInventoryComponent>,
    private changeDetRef: ChangeDetectorRef,
    private dialog: MatDialog,
    public authService: AuthService,
    private warehouseService: WarehouseService,
    private roomService: RoomService,
    private toastrService: NbToastrService,
) {}

  // Icons  //
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faTimes = faTimes;
  public faPlus = faPlusCircle;
  public faScanner = faScanner;

  public warehouses = [];
  public rooms = [];
  public locations = [];
  public allWarehouses = [];
  public allRooms = [];
  public allLocations = [];

  public lot: InventoryLotDTO = {};
  public years: CropYear[];
  public blocks: BlockDTO[];
  public lotAttrabutes: InventoryLotAttributeDTO[];
  public loads: LoadViewModel[];
  public varieties: CropVariety[];
  public isLoading: boolean = true;
  public lots: InventoryLotDTO[] = [];
  public accessionItems: InventoryItemDTO[] = [];
  public lotInventoryObj: lotInventory = {
    lotId: this.data.lot.id,
    handlingUnitMasterId: this.data.lot.handlingUnitMasterId,
    handlingUnitDetailId: this.data.lot.handlingUnitDetailId,
    quantity: null,
    toLocationId: null,
    toWarehouseId: null,
    toRoomId: null,
    reasonCodeId: null,
    orderNumber: null,
  }; 


  public reasonCodes: ReasonCode[] = [];

  ngOnInit() {

    Observable.forkJoin([
      this.inventoryService.getReasonCodes(),
      this.warehouseService.getWarehouses(),
      this.roomService.getRooms(),
      this.roomService.getRoomLocations(),

    ])
    .subscribe(items => {
      this.reasonCodes = items[0];
      this.allWarehouses = items[1];
      this.allRooms = items[2];
      this.allLocations = items[3];


      this.warehouses = this.allWarehouses;
      this.rooms = this.allRooms;
      this.locations = this.allLocations; 

      this.isLoading = false;

      this.lotInventoryObj = {
        lotId: this.data.lotId,
        handlingUnitMasterId: this.data.lot.handlingUnitMasterId,
        handlingUnitDetailId: this.data.lot.handlingUnitDetailId,
        quantity: null,
        toLocationId: null,
        toWarehouseId: null,
        toRoomId: null,
        reasonCodeId: null,
      };

      this.changeDetRef.detectChanges();
    })

 
  }

  submit() {
    this.isLoading = true;
    if(this.data.type == "add"){
      this.lotInventoryService.lotInventoryAdd(this.lotInventoryObj).subscribe(x => {
        this.bottomSheet.dismiss('reload');
      }, err => {
        this.toastrService.warning(err.error, 'ERROR!');
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      })
    } else if (this.data.type == "remove"){
      this.lotInventoryService.lotInventoryRemove(this.lotInventoryObj).subscribe(x => {
        this.bottomSheet.dismiss('reload');
      }, err => {
        this.toastrService.warning(err.error, 'ERROR!');
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      })
    } else if (this.data.type == "transfer"){
      this.lotInventoryService.lotInventoryTransfer(this.lotInventoryObj).subscribe(x => {
        this.bottomSheet.dismiss('reload');
      }, err => {
        this.toastrService.warning(err.error, 'ERROR!');
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      })
    }
    else if (this.data.type == "addToOrder"){
      this.lotInventoryService.lotInventoryAddToOrder(this.lotInventoryObj).subscribe(x => {
        this.bottomSheet.dismiss('reload');
      }, err => {
        this.toastrService.warning(err.error.Message, 'ERROR!');
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      })
    }

  }

  cancel() {
      this.bottomSheet.dismiss();
  }

  roomSelectionChanged(){
    let room = this.allRooms.find(x => x.id == this.lotInventoryObj.toRoomId);
    this.lotInventoryObj.toWarehouseId = room.warehouseId;
    this.locations = [...this.allLocations.filter(x => {
      return this.rooms.some( f => {
        return room.id == x.roomId;
      });
    })];
    this.lotInventoryObj.toLocationId = null;
    this.changeDetRef.detectChanges();
  }

  warehouseSelectionChanged(){
    let warehouse = this.allWarehouses.find(x => x.id == this.lotInventoryObj.toWarehouseId);
    this.rooms = [...this.allRooms.filter(x => x.warehouseId == warehouse.id)];
    this.locations = [...this.allLocations.filter(x => {
      return this.rooms.some( f => {
        return f.id == x.roomId;
      });
    })];
    this.lotInventoryObj.toRoomId = null;
    this.lotInventoryObj.toLocationId = null;
    this.changeDetRef.detectChanges();
  }

  locationSelectionChanged(){
    let location = this.allLocations.find(x => x.id == this.lotInventoryObj.toLocationId);
    let room = this.allRooms.find(x => x.id == location.roomId);
    this.lotInventoryObj.toWarehouseId = room.warehouseId;
    this.lotInventoryObj.toRoomId = room.id;
    this.locations = [...this.allLocations.filter(x => {
      return this.rooms.some( f => {
        return room.id == x.roomId;
      });
    })];
    this.changeDetRef.detectChanges();

  }

  scan() {
    const dialogRef = this.dialog.open(ScanPopupComponent, {
      minWidth: '30%',
      data: {
        isLocationScan: true
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isLocation) {
        this.lotInventoryObj.toLocationId = x.location.id;
        this.locationSelectionChanged();
      }

      if (x.isRoom) {
        this.lotInventoryObj.toRoomId = x.room.id;
        this.roomSelectionChanged();
      }

      if (x.isWarehouse) {
        this.lotInventoryObj.toWarehouseId = x.warehouse.id;
        this.warehouseSelectionChanged();
      }

      this.isLoading = false;
      this.changeDetRef.markForCheck();
    });
  }
}