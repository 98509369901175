import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Order } from '../models/order';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { environment } from '../../../../../../../environments/environment';
import { AvailableLot } from '../models/available-lot';
import { PickList } from '../models/pick-list';
import { DailyBuildList } from '../models/build-list';
import { Items } from '../models/items';


@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public selectedPickDate: Date; 

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.HOLLINGBERY_API_URL;
  }

  public getItems(): Observable<Items[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Items[]>(`${this.basePath}/api/items`,
      {
        headers,
      }
    );
  }

}
