import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { TruckType } from '../models/truck-type';



@Injectable({
  providedIn: 'root'
})
export class truckTypeService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }
  
  public getTruckTypes(includeInactive?: boolean): Observable<TruckType[]> {
    let queryParameters = new HttpParams();

    if (includeInactive != null) {
        queryParameters = queryParameters.set('includeInactive', String(includeInactive));
    }

    return this.httpClient.get<TruckType[]>(`${this.basePath}/api/truckType`, {
        headers: this.defaultHeaders.set('Authorization', String(this.authService.getUserToken())),
    });
}

  public getTruckType(truckTypeId: number): Observable<TruckType> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<TruckType>(`${this.basePath}/api/truckType/${truckTypeId}`,
      {
        headers,
      }
    );
  }

  public postTruckTypeDTO(truckTypeDTO: TruckType): Observable<TruckType> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<TruckType>(`${this.basePath}/api/truckType`, truckTypeDTO,

      {
        headers,
      }
    );
  }

  public putTruckTypeDTO(truckTypeDTO: TruckType): Observable<TruckType> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<TruckType>(`${this.basePath}/api/truckType/${truckTypeDTO.id}`, truckTypeDTO,

      {
        headers,
      }
    );
  }

  public deleteTruckTypeDTO(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/truckType/${id}`,
      {
        headers,
      }
    );
  }

}
