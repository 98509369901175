import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../../../../@core/auth/auth.service';

import { ErrorService } from '../../../../../@core/utils/error.service';
import { FieldTaskDTO } from '../../../../../harvest-engine/modules/field-events/models/field-task';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';


@Component({ 
  selector: 'app-bar-chart',
  templateUrl: './field-task-bar-chart.component.html',
  styleUrls: ['./field-task-bar-chart.component.scss']
})
export class FieldTaskBarChartComponent implements OnInit {

  chartOptions = {
    responsive: true
  };

  private fieldTasks: FieldTaskDTO[] = [];
  public isLoading: boolean = true;

  chartData = [
  ];

  chartLabels = ['March'];

  myColors = [
    {
      backgroundColor: '#0E3D59',
      borderColor: '#0E3D59',
      pointBackgroundColor: '#0E3D59',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#88A61B',
      borderColor: '#88A61B',
      pointBackgroundColor: '#88A61B',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#F29F05',
      borderColor: '#F29F05',
      pointBackgroundColor: '#F29F05',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#F25C05',
      pointBackgroundColor: '#F25C05',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: '#D92525',
      borderColor: '#D92525',
      pointBackgroundColor: '#D92525',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
    {
      backgroundColor: 'rgba(150, 169, 205)',
      borderColor: 'rgb(150, 169, 205)',
      pointBackgroundColor: 'rgb(150, 169, 205)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
    },
  ];

  ngOnInit(): void {
    this.fieldTaskService.apiFieldTasksGet().subscribe(tasks => {
      this.fieldTasks = tasks;

      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    })
  }

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private fieldTaskService: FieldTasksService,
  ) {

  }
    
}