import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material';
import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs/Rx';
import { Room } from '../../models/storage/room';
import { FilterResults} from '../../../core/widgets/kw-filter-dropdown/models/filter-results'
import * as _ from 'underscore'
import { ErrorService } from '../../../../@core/utils/error.service';
import { StorageByRoomItem } from '../../../modules/pick-plan/pages/storage-by-room/storage-by-room-table/storageByRoomItem'
import { HarvestStorageDataService } from '../../../modules/pick-plan/services/harvest-storage-data.service';
import { CropYearService } from '../../services/crop-year.service';
import { KW_COLORS } from '../../../../@theme/kragworks-colors';
import { Color } from 'ng2-charts';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { PickPlanSessionService } from '../../../modules/pick-plan/services/pick-plan-session.service';
import { StorageByActionsDialogComponent } from '../../../modules/pick-plan/pages/storage-by-pool/storage-by-actions-dialog/storage-by-actions-dialog.component';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { ReceivingSummaryItem } from '../../../modules/pick-plan/models/receiving-summary-item';
import { RoomCustomAttrabute } from '../../models/storage/room-custom-attrabute';


@Component({
  selector: 'room-summary',
  templateUrl: './room-summary.component.html',
  styleUrls: ['./room-summary.component.scss']
})
export class RoomSummaryComponent implements OnInit {
  
  public faArrowAltCircleLeft = faArrowAltCircleLeft;

  public room: Room = {};
  public filterResults: FilterResults;
  public roomData: StorageByRoomItem;
  public roomLoading = true;
  public cropYearId: number;
  faBars = faBars;

    //Bar Chart Variables ---------------------------
    receivedBarData: number[] = [];
    plannedBarData: number[] = [];
    showAll: boolean = true;
    receivedBarQTYData: number[] = [];
    public allBarChartData: ChartDataSets[] = [];
    public barChartData: ChartDataSets[] = [];
    public allBarChartLabels: string[] = [];
    public barChartLabels: string[] = [];
    filledRoomTolerance: number = .75;
    public barColor: string[] = [KW_COLORS.blue];
    public barChartType: ChartType = 'horizontalBar';
    public barChartLegend: boolean = false;
    @Input() receivingData: ReceivingSummaryItem[] = [];
    public tableData: StorageByRoomItem[] = []
    public attrabutes: RoomCustomAttrabute[] = [];

    public barChartOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false
          },
        }],
        yAxes: [{
          stacked: true,
          gridLines: {
            display: false
          }
        }]
      },
  
      tooltips: {
        // disable due to bugs
        enabled: false,
        mode: 'label',
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
  
            // Loop through all datasets to get the actual total of the index
            var total = 0;
            for (var i = 0; i < data.datasets.length; i++)
              total = total + +data.datasets[i].data[tooltipItem.index];
  
            // If it is not the last dataset, you display it as you usually do
            if (tooltipItem.datasetIndex != data.datasets.length - 1) {
              return label + " : " + value;
            } else { // .. else, you display the dataset and the total, using an array
              return [label + " : " + value, "Room Capacity: " + total];
            }
          },
          title: function (tooltipItem, data) {
            return "Room Data";
          }
        }
  
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
  
      }
    };

  public barChartColors: Color[] = [
    { backgroundColor: KW_COLORS.planned },
    { backgroundColor: KW_COLORS.capacity },
    { backgroundColor: KW_COLORS.lightGray }
  ]

  constructor(
    public dialogRef: MatDialogRef<RoomSummaryComponent>,
    public ReportsService: HarvestStorageDataService,
    private errorService: ErrorService,
    public cropYearService: CropYearService,
    private pickPlanSessionService: PickPlanSessionService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
    ) { 
    if(this.data.room){
      this.room = {... this.data.room}
    }
    if(this.data.cropYear){
      this.cropYearId = this.data.cropYear
    }
  }

  ngOnInit() {

    Observable.forkJoin([this.ReportsService.getStorageByRoomItemById(this.room.id, this.cropYearId)])
      .subscribe(data => {
        this.roomData = data[0];
        this.roomLoading = false;
      }, err => {
        this.roomLoading = false;
        this.errorService.handleError(err);
      });
  }
  openDialog(storageByRoomItem: StorageByRoomItem) {
    this.pickPlanSessionService.storageByRoomItem = storageByRoomItem;
    this.pickPlanSessionService.selectedCropYearId = this.cropYearId;
    const dialogRef = this.dialog.open(StorageByActionsDialogComponent, {
      width: '280px',
      data: {
        room: storageByRoomItem,
        cropYearId: this.cropYearId
      }
    }).afterClosed().subscribe(result => {
      if(result){
        this.dialogRef.close();
      }
      // this.getPageData();
    });
  }
  
  close() {
    this.dialogRef.close();
  }

}
