import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet, MatPaginator, MatSort } from '@angular/material';
import { faCheck, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Room } from '../../../models/storage/room';
import { RoomCustomAttrabute } from '../../../models/storage/room-custom-attrabute';
import { RoomDetail } from '../../../models/storage/room-detail';
import { RoomStatus } from '../../../models/storage/roomStatus';
import { Warehouse } from '../../../models/storage/warehouse';
import { RoomService } from '../../../services/storage/room-service';
import { WarehouseService } from '../../../services/storage/warehouse-service';
import { FilterSettings } from '../../../widgets/kw-filter-dropdown/models/filter-options';
import { RoomEditPopupComponent } from '../room-edit-popup/room-edit-popup.component';
import { RoomsListTableDataSource } from './rooms-list-table-datasource';
import { KwFilterDropdownService } from '../../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'rooms-list-table',
  templateUrl: './rooms-list-table.component.html',
  styleUrls: ['./rooms-list-table.component.css']
})
export class RoomsListTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: RoomsListTableDataSource;
  public faPlusCircle = faPlusCircle;

  public editRoom: Room = {};
  public roomId: number;
  public roomLoading = true;
  private initialLoad = true;
  public submitting: any;
  public filteredDetails: RoomCustomAttrabute[] = [];
  public newRoomDetail: RoomDetail[] = [];
  public roomList: Room[] = [];
  private bottomSheetSubscription: Subscription;
  public statuses: RoomStatus[] = [];
  // public editWarehouse: Warehouse;

  public faTimes = faTimes;
  public faCheck = faCheck;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'roomDescription', 'roomCapacity', 'warehouse','barcode',  'integrationId', 'status'];
  defaultColumns = ['id', 'name', 'roomDescription', 'roomCapacity', 'warehouse', 'barcode', 'integrationId', 'status'];

  public filterSettings: FilterSettings = {
    roomSettings: {
      display: true,
      singleSelection: false
    },

  };

  constructor(
    private errorService: ErrorService,
    public service: RoomService,
    private changeDetRef: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    public warehouseService: WarehouseService,
    public filterService: KwFilterDropdownService
  ) { }

  ngOnInit() {
    this.roomLoading = true;
    this.dataSource = new RoomsListTableDataSource(this.paginator, this.sort);
    
    this.updateRooms();
  }

  updateRooms(){
    this.roomLoading = true;
    Observable.forkJoin([
      this.service.getRooms(true),
      this.service.getRoomStatuses()
    ]).subscribe(data => {
      this.statuses = data[1];
      this.service.getCustomAttributes().subscribe(attrs => {
        this.filteredDetails = attrs;
        this.newRoomDetail = attrs.map(attr => {
          return <RoomDetail>{
            id: 0,
            value: "",
            isActive: true,
            roomId: this.editRoom.id,
            roomCustomAttributeId: attr.id,
            roomCustomAttribute: attr
          }
        });

        this.editRoom.details = [...this.newRoomDetail];

        this.displayedColumns = [...this.defaultColumns]

        for (let x of this.filteredDetails) {
          this.displayedColumns.push(x.name.toString());
        }

        this.displayedColumns.push('isActive');
        this.displayedColumns.push('edit');

        this.roomList = data[0];
        this.dataSource.data = [...this.roomList];
        this.changeDetRef.markForCheck();
        this.roomLoading = false;
        this.paginator._changePageSize(this.paginator.pageSize);
      });
    }, err => { this.errorService.handleError(err); this.roomLoading = false; });
  }

  editClicked(id: number) {
    this.editRoom = { ...this.dataSource.data.filter(u => u.id === id)[0] };
    this.bottomSheet.open(RoomEditPopupComponent, {
      panelClass: 'bottomsheet-60-width', data: {
        room: {...this.editRoom}
      }
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.updateRooms();
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  getStatus(row: Room){
    let status = this.statuses.find(x => x.id == row.statusId)
    if(status)
      return status.value
    else return "";
  }

  addRoomClicked() {
    this.editRoom = {};
    this.editRoom.details = this.newRoomDetail;
    this.editRoom.isActive = true;
    this.bottomSheet.open(RoomEditPopupComponent, {
      panelClass: 'bottomsheet-60-width', data: {
        room: {...this.editRoom}
      }
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.updateRooms();
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  deleteRoomClicked(form: NgForm) {
    this.roomLoading = true;
    if (this.editRoom.id !== null && this.editRoom.id !== undefined) {
      this.service.deleteRoom(this.editRoom.id).subscribe(x => {
        this.roomList = this.dataSource.data.filter(x => x.id != this.editRoom.id);
        this.dataSource.data = this.dataSource.data.filter(x => x.id != this.editRoom.id);
        this.paginator._changePageSize(this.paginator.pageSize)
        this.editRoom = {};
        this.roomLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
    else {
      alert("No Room Selected!!");
      this.roomLoading = false;
      form.resetForm();
    }
  }

  saveRoomClicked(form: NgForm) {
    this.roomLoading = true;
    if (this.editRoom.isActive === null || this.editRoom.isActive === undefined) { this.editRoom.isActive = false; };
    if (this.editRoom.id !== null && this.editRoom.id !== undefined) {
      this.editRoom.updatedDate = new Date;
      let index = this.dataSource.data.indexOf(this.dataSource.data.filter(w => w.id === this.editRoom.id)[0]);
      this.service.putRoom(this.editRoom).subscribe(x => {
        this.dataSource.data[index] = this.editRoom;
        this.dataSource.data = [...this.dataSource.data];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.editRoom = {};
        this.roomLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
    else {
      this.editRoom.id = 0;
      this.editRoom.createdDate = new Date();
      this.editRoom.updatedDate = this.editRoom.createdDate;
      this.editRoom.locations = [];
      this.service.postRoom(this.editRoom).subscribe(x => {
        // this.editRoom.warehouse = this.editWarehouse;
        this.roomList.push(JSON.parse(JSON.stringify(x)));
        this.dataSource.data = [...this.roomList];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.editRoom = {};
        this.roomLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
  }

  cancelClicked(form: NgForm) {
    this.editRoom = {};
    this.getCustomAttributes();
    form.resetForm();
  }


  getValue(row: Room, attrId: number) {
    if(row.details.find(x => x.roomCustomAttributeId == attrId).roomCustomAttribute.datatype == "date" &&
    row.details.find(x => x.roomCustomAttributeId == attrId).value != "")
      return formatDate(row.details.find(x => x.roomCustomAttributeId == attrId).value,'MM/dd/yyyy',"en-US");
    else
      return row.details.find(x => x.roomCustomAttributeId == attrId).value
  }

  clearDetailValue(typeId: number) {
    this.editRoom.details.find(x => x.roomCustomAttribute.id === typeId).value = "";
  }

  getDetailName(row: Room, attrId: number) {
    return row.details.find(x => x.id == attrId).roomCustomAttribute.name
  }

  getCustomAttributes() {
    this.service.getCustomAttributes().subscribe(attrs => {
      this.filteredDetails = attrs;
      this.newRoomDetail = attrs.map(attr => {
        return <RoomDetail>{
          id: 0,
          value: "",
          isActive: true,
          roomId: this.editRoom.id,
          roomCustomAttributeId: attr.id,
          roomCustomAttribute: attr
        }
      });

      this.editRoom.details = [...this.newRoomDetail];
    });
  }

  search(event) {
    if(this.initialLoad){
      this.initialLoad = false;
      event = this.filterService.getFilterState();
    }
    let results = this.roomList.filter(x => 
      (event.selectedRooms.map(y => y.id).includes(x.id) || event.selectedRooms.length == 0)  
   );
    
    this.dataSource.data = results;
    this.paginator._changePageSize(this.paginator.pageSize);
  }
}
