import { Injectable } from "@angular/core";
import { InventoryHumEditComponent } from "../harvest-engine/modules/inventory/pages/inventory-summary/inventory-hum-edit/inventory-hum-edit.component";
import { InventoryAddItemPopupComponent } from "../harvest-engine/modules/inventory/pages/inventory-items/inventory-add-item-popup/inventory-add-item-popup.component";
import { InventoryScanPopupComponent } from "../harvest-engine/modules/inventory/pages/inventory-summary/inventory-scan-popup/inventory-scan-popup.component";
import { MockSharedService } from "./mockSharedService";
import { InventoryLotEditComponent } from "../harvest-engine/modules/inventory/pages/inventory-lots/inventory-lot-edit/inventory-lot-edit.component";

@Injectable()
export class SharedService implements MockSharedService {
  mapper = {};

  constructor() {
    //map component name based on string
    this.mapper = {
      'InventoryHumEditComponent': InventoryHumEditComponent,
      'InventoryAddItemPopupComponent': InventoryAddItemPopupComponent,
      'InventoryScanPopupComponent': InventoryScanPopupComponent,
      'InventoryLotEditComponent': InventoryLotEditComponent
    };
  }

  //open mat dialog or bottom sheet based on type
  openDialog(componentName: string, type: any, ...data: any[]) {
    let modalRef = type.open(this.mapper[componentName], data[0]);
    return modalRef;
  }
}
