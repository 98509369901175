import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { Fieldman } from '../models/fieldman';

@Injectable({
  providedIn: 'root'
})
export class FieldmanService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public wordList: Fieldman[] = [];
  public isLoading: boolean = true;
  private AuthToken: string;

  constructor(
    public authService: AuthService,
    protected httpClient: HttpClient
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public putFieldman(fieldmen: Fieldman): Observable<Fieldman> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', this.authService.getUserToken());

    return this.httpClient.put<Fieldman>(`${this.basePath}/api/Fieldman/${fieldmen.id}`, fieldmen,

      {
        headers,
      }
    );
  }

  public getFieldman(id?: number): Observable<Fieldman> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', this.authService.getUserToken());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    if(id != null){ return this.httpClient.get<Fieldman>(`${this.basePath}/api/Fieldman/${id}`, { headers,});}
    else{ return this.httpClient.get<Fieldman>(`${this.basePath}/api/Fieldman`, { headers,});}
  }

  public getFieldmen(): Observable<Fieldman[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', this.authService.getUserToken());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Fieldman[]>(`${this.basePath}/api/Fieldman`, { headers,});
  }

  public deleteFieldman(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', this.authService.getUserToken());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/Fieldman/${id}`,
      {
        headers,
      }
    );
  }

  public postFieldman(Fieldman: Fieldman): Observable<Fieldman> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', this.authService.getUserToken());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<Fieldman>(`${this.basePath}/api/Fieldman`, Fieldman,

      {
        headers,
      }
    );
  }
}
