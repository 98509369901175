import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RestartService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public isLoading: boolean = true;

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public postRestart(printerId: number) {

    let headers = new HttpHeaders();


    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('printerId', printerId.toString());
    
    return this.httpClient.post<string>(`${this.basePath}/api/Restart`,{},
      {
        headers,
        params: queryParameters
      }
    );
  }
}
