import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KwSpinnerDirective } from './kw-spinner.directive';
import { KwSpinnerComponent } from '../components/kw-spinner/kw-spinner.component';

@NgModule({
  exports: [KwSpinnerComponent, KwSpinnerDirective],
  declarations: [KwSpinnerComponent, KwSpinnerDirective],
  entryComponents: [KwSpinnerComponent],
})
export class KwSpinnerModule { }
