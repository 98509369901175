import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../@core/utils/error.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { CustomerAttributeValue } from '../models/customerAttributeValue';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    // this.basePath = environment.HOLLINGBERY_API_URL;
    this.basePath = environment.PLATFORM_API_URL;


  }

  public getCustomerList(): Observable<Customer[]> {
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Customer[]>(`${this.basePath}/api/customer`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getCustomer(customerId: number): Observable<Customer> {
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<Customer>(`${this.basePath}/api/customer/${customerId}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public postCustomer(customer: Customer): Observable<Customer> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');

    //Pass saveERPFlag hardcoded true from UI.
    return this.httpClient.post<Customer>(`${this.basePath}/api/customer?saveERPFlag=true`, customer,

      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  public putCustomer(customer: Customer): Observable<Customer> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');

    //Pass saveERPFlag hardcoded true from UI.
    return this.httpClient.put<Customer>(`${this.basePath}/api/customer/${customer.id}?saveERPFlag=true`, customer,

      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  public deleteCustomer(customerId: number): Observable<Customer> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');

    return this.httpClient.delete<Customer>(`${this.basePath}/api/customer/${customerId}`,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

  //Get all custom attributes for customers
  getCustomerAttributes(): Observable<CustomerAttributeValue[]> {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );
    return this.httpClient.get<CustomerAttributeValue[]>(
      `${this.basePath}/api/customerattribute/getall`,
      {
        headers: headers,
      }
    );
  }
}
