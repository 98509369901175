import { Component, OnInit } from '@angular/core';
import { TenantWords } from '../../models/tenantWords';
import { Router } from '@angular/router';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'word-list',
  templateUrl: './word-list.component.html',
  styleUrls: ['./word-list.component.scss']
})
export class WordListComponent implements OnInit {

  tenantWordList: TenantWords;
  public loading: boolean = true;
  public faPlusCircle = faPlusCircle;

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
    this.tenantWordList = JSON.parse(localStorage.getItem('tenantWordDictionary'));
  }

  onLoaded(){
    this.loading = false;
  }

}
