import { ChangeDetectorRef, Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { InventorySessionService } from '../../../services/inventory-session.service';
import * as moment from 'moment-timezone';
import { faTrashAlt, faTruck, faPrint } from '@fortawesome/pro-duotone-svg-icons';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { InventorySummaryPopupComponent } from '../inventory-summary-popup/inventory-summary-popup.component';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { NbToastrService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { InventoryTransferService } from '../../inventory-transfer/inventory-transfer.service';
import { InventoryMoveLocationPopupComponent } from '../inventory-move-location-popup/inventory-move-location-popup.component';
import { RemoveInventoryConfirmationComponent } from '../inventory-summary-popup/remove-inventory-confirmation/remove-inventory-confirmation.component';
import * as _ from 'underscore';
import { HandlingUnitBulkDelete, HandlingUnitBulkMove } from '../../../models/handling-unit-bulk-actions';
import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { NavigationService } from '../../../../../core/services/navigation.service';
import { FilterSettingsModel, GridComponent, PageSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { KragUtilitiesService } from '../../../../../../@core/utils/krag-utilities.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { PrinterDTO } from '../../../../estimates/models/printer-dto';

@Component({
  selector: 'inventory-bulk-move',
  templateUrl: './inventory-bulk-move.component.html',
  styleUrls: ['./inventory-bulk-move.component.scss']
})
export class InventoryBulkMoveComponent implements OnInit {

  @ViewChild('grid') grid: GridComponent;

  // Icons
  faTrashAlt = faTrashAlt;
  faTruck = faTruck;
  faPrint = faPrint
  faArrowAltCircleLeft = faArrowAltCircleLeft;

  public isLoading: boolean = false;
  bulkRemoveInventory: HandlingUnitBulkDelete = {};
  public selectionOptions: SelectionSettingsModel;
  public handlingUnitBulkMove: HandlingUnitBulkMove = {};
  selectedRecords: HandlingUnitBulkMove[] = [];
  humToMove: HandlingUnitBulkMove = {};
  public printer: PrinterDTO;

  public moveCount: number = 0;

  public pageSettings: PageSettingsModel = {
    pageSize: 200,
    pageSizes: [50, 100, 200],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }

  constructor(
    public session: InventorySessionService,
    private bottomSheet: MatBottomSheet,
    private errorService: ErrorService,
    // private router: Router,
    private route: ActivatedRoute,
    private changeDetRef: ChangeDetectorRef,
    private toastr: NbToastrService,
    private inventoryService: InventoryService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InventorySummaryPopupComponent>,
    public authService: AuthService,
    private navigationService: NavigationService,
    private router: Router,
    private kragUtils: KragUtilitiesService,
  ) { }

  ngOnInit() {
    this.humToMove.handlingUnitMasterIds = [];
    this.selectionOptions = { type: 'Multiple', persistSelection: true };
    this.isLoading = true;
    if (!this.session.handlingUnitBulkMoveList || this.session.handlingUnitBulkMoveList.length <= 0) {
      // this.navigationService.back();
      this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary");
    }

    this.moveCount = this.session.handlingUnitBulkMoveList.length;

    this.handlingUnitBulkMove = this.session.handlingUnitBulkMoveList[0];

    this.isLoading = false;

  }

  convertDate(date: Date) {
    return moment(date).format('LL');
  }

  moveInventory() {

    // CHECK TO SEE IF THERE ARE SELECTED RECORDS, IF SO, PASS SELECTED RECORDS INTO INVENOTORY MOVE POPUP
    this.rowSelected(null);

    if (this.humToMove.handlingUnitMasterIds.length > 0) {

      if (this.humToMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog.open(ConfirmationDialogComponent, {
          minWidth: '10%',
          data: {
            confirmLabel: 'Yes',
            title: 'Inventory Move count exceeds 25',
            confirmMessage: 'Are you sure you want to move this inventory?',
            cancelLabel: 'No'
          }
        }).afterClosed().subscribe(result => {

          if (result === true) {
            let data = {
              hum: JSON.parse(JSON.stringify(this.humToMove))
            }
            // open the bottom sheet
            this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });
          }
        }
        );
      }
      else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.humToMove))
        }
        // open the bottom sheet
        this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });
      }

    } else {
      if (this.handlingUnitBulkMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog.open(ConfirmationDialogComponent, {
          minWidth: '10%',
          data: {
            confirmLabel: 'Yes',
            title: 'Inventory Move count exceeds 25',
            confirmMessage: 'Are you sure you want to move this inventory?',
            cancelLabel: 'No'
          }
        }).afterClosed().subscribe(result => {

          if (result === true) {
            let data = {
              hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove))
            }
            // open the bottom sheet
            this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });
          }
        }
        );
      }
      else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove))
        }
        // open the bottom sheet
        this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });
      }
    }

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      if (job) {

        let savedHumIds = this.session.handlingUnitBulkMoveList[0].handlingUnitMasterIds;
        let any = this.session.handlingUnitBulkMoveList.filter(x => !this.humToMove.handlingUnitMasterIds.includes(x.humId));
        this.session.handlingUnitBulkMoveList = any;
        this.handlingUnitBulkMove = any[0];
        this.handlingUnitBulkMove.handlingUnitMasterIds = savedHumIds;

        bottomSheetSubscription.unsubscribe();
        this.changeDetRef.detectChanges();
        // this.router.navigateByUrl("/harvest-engine/laboratory/technician-dashboard");
      }
    });
  }

  setPrinter(printer: PrinterDTO) {
    this.printer = printer;
  }

  printInventory() {
    // CHECK TO SEE IF THERE ARE SELECTED RECORDS, IF SO, PASS SELECTED RECORDS INTO INVENOTORY MOVE POPUP
    this.rowSelected(null);

    if (this.humToMove.handlingUnitMasterIds.length > 0) {

      if (this.humToMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog.open(ConfirmationDialogComponent, {
          minWidth: '10%',
          data: {
            confirmLabel: 'Yes',
            title: 'Inventory Print count exceeds 25',
            confirmMessage: 'Are you sure you want to print this inventory?',
            cancelLabel: 'No'
          }
        }).afterClosed().subscribe(result => {

          if (result === true) {
            let data = {
              hum: JSON.parse(JSON.stringify(this.humToMove))
            }
            // send print request
            this.isLoading = true;
            this.inventoryService.bulkReprintInventoryHUM(this.humToMove.handlingUnitMasterIds, this.printer.id).subscribe(x => {
              this.toastr.success('Inventory printed', 'SUCCESS');
              this.isLoading = false;
            }, err => {
              this.isLoading = false;
              this.errorService.handleError(err);
            });
          }
        }
        );
      }
      else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.humToMove))
        }
        // send print request
        this.isLoading = true;
        this.inventoryService.bulkReprintInventoryHUM(this.humToMove.handlingUnitMasterIds, this.printer.id).subscribe(x => {
          this.toastr.success('Inventory printed', 'SUCCESS');
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          this.errorService.handleError(err);
        });
      }

    } else {
      if (this.handlingUnitBulkMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog.open(ConfirmationDialogComponent, {
          minWidth: '10%',
          data: {
            confirmLabel: 'Yes',
            title: 'Inventory Print count exceeds 25',
            confirmMessage: 'Are you sure you want to print this inventory?',
            cancelLabel: 'No'
          }
        }).afterClosed().subscribe(result => {

          if (result === true) {
            let data = {
              hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove))
            }
            // send print request
            this.isLoading = true;
            this.inventoryService.bulkReprintInventoryHUM(this.handlingUnitBulkMove.handlingUnitMasterIds, this.printer.id).subscribe(x => {
              this.toastr.success('Inventory printed', 'SUCCESS');
              this.isLoading = false;
            }, err => {
              this.isLoading = false;
              this.errorService.handleError(err);
            });
          }
        }
        );
      }
      else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove))
        }
        // send print request
        this.isLoading = true;
        this.inventoryService.bulkReprintInventoryHUM(this.handlingUnitBulkMove.handlingUnitMasterIds, this.printer.id).subscribe(x => {
          this.toastr.success('Inventory printed', 'SUCCESS');
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          this.errorService.handleError(err);
        });
      }
    }
  }

  myFunction() {
    //console.log("myFunction");
  }

  removeInventory() {
    const confRef = this.dialog.open(RemoveInventoryConfirmationComponent, {
      minWidth: '10%',
      data: {
      }
    }).afterClosed().subscribe(result => {

      if (result.result == true) {

        this.isLoading = true;
        this.rowSelected(null);

        if (this.humToMove.handlingUnitMasterIds.length > 0) {

          //console.log("HumToMove");
          //console.log(this.humToMove);
          this.bulkRemoveInventory.handlingUnitMasterIds = this.humToMove.handlingUnitMasterIds;
        } else {
          //console.log("handlingUnitBulkMove");
          //console.log(this.handlingUnitBulkMove);
          this.bulkRemoveInventory.handlingUnitMasterIds = this.handlingUnitBulkMove.handlingUnitMasterIds;
        }

        this.bulkRemoveInventory.reasonCodeId = 0;

        if (result.transactionReasonCodeId) {
          this.bulkRemoveInventory.reasonCodeId = result.transactionReasonCodeId;
        }

        //console.log("BulkRemoveInventory");
        //console.log(this.bulkRemoveInventory)

        this.inventoryService.bulkDeleteHandlingUnitMasters(this.bulkRemoveInventory).subscribe(x => {

          let savedHumIds = this.session.handlingUnitBulkMoveList[0].handlingUnitMasterIds;
          let any = this.session.handlingUnitBulkMoveList.filter(x => !this.humToMove.handlingUnitMasterIds.includes(x.humId));
          this.session.handlingUnitBulkMoveList = any;
          this.handlingUnitBulkMove = any[0];
          this.handlingUnitBulkMove.handlingUnitMasterIds = savedHumIds;

          this.toastr.success('Items removed from inventory', 'SUCCESS');
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          this.errorService.handleError(err);
        });
      }
    });
  }

  back() {
    // this.router.navigateByUrl("/harvest-engine/laboratory/technician-dashboard");
    this.navigationService.back();
  }

  rowSelected(data) {
    this.selectedRecords = [];
    this.humToMove.handlingUnitMasterIds = [];

    //console.log(this.grid.getSelectedRecords());

    let dataToMove = this.grid.getSelectedRecords();

    if (dataToMove.length == 0)
      this.moveCount = this.session.handlingUnitBulkMoveList.length;
    else
      this.moveCount = dataToMove.length;

    for (let x of dataToMove) {
      this.selectedRecords.push(x)
    }

    for (let y of this.selectedRecords) {
      this.humToMove.handlingUnitMasterIds.push(y.humId);
    }

    this.humToMove.isMultiple = true;
  }

}
