import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KwFilterDropdownComponent } from './kw-filter-dropdown.component';
import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDividerModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatSelectModule, MatSortModule, MatTableModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { NbCardModule, NbSpinnerModule } from '@nebular/theme';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';
import { TenantWordsModule } from '../../pipes/tenant-words.module';

@NgModule({
  declarations: [
    KwFilterDropdownComponent
  ],
  exports: [
    KwFilterDropdownComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    TenantWordsModule,
    FormsModule,
    NbCardModule,
    NgxMatSelectSearchModule,
    NbSpinnerModule,
    MatExpansionModule,
    AngularMultiSelectModule,
    FontAwesomeModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    NgxMaskModule,
    MatCheckboxModule,

    MatCheckboxModule,
    MatTableModule,
    ColorPickerModule,
    MatSortModule,
    MatIconModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ]
})
export class KwFilterDropdownModule { }
