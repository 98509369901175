export enum ActionTypeForInvetory
{
    AdjustQuantity = "AdjustQuantity",
    CreateInventory = "CreateInventory",
    UpdateInventory = "UpdateInventory"
}

// Enum for all types of Purchase order available in db.
export enum ContractTemplateType {
    SpotPurchaseOrder = "Spot Purchase Order",
    ReceivedPurchaseOrder = "Received Purchase Order",
    PlusPurchaseOrder = "Plus Purchase Order",
    FlatPurchaseOrder = "Flat Purchase Order",
    Amendment = "Amendment"
}