import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../@core/utils/error.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { CropVariety } from '../../../core/models/crop-variety';
import {InventoryPackageTypeDTO} from '../../inventory/models/inventory-package-type'

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    // this.basePath = environment.HOLLINGBERY_API_URL;
    this.basePath = environment.PLATFORM_API_URL;


  }

  public getPackageTypes(): Observable<InventoryPackageTypeDTO[]> {
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<InventoryPackageTypeDTO[]>(`${this.basePath}/api/inventory/inventoryPackageTypes`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }
  public getCropVarieties(): Observable<CropVariety[]> {
    // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropVariety[]>(`${this.basePath}/api/CropVarieties`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

//   public getCustomer(customerId: number): Observable<Customer> {
//     // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

//     let headers = this.defaultHeaders;

//     headers = headers.set('Authorization', String(this.authService.getUserToken()));

//     let queryParameters = new HttpParams();

//     return this.httpClient.get<Customer>(`${this.basePath}/api/customer/${customerId}`,
//       {
//         headers: headers,
//         params: queryParameters
//       }
//     );
//   }

//   public postCustomer(customer: Customer): Observable<Customer> {
//     let headers = this.defaultHeaders;

//     headers = headers.set('Authorization', String(this.authService.getUserToken()));

//     // to determine the Accept header
//     let httpHeaderAccepts: string[] = [
//     ];

//     headers = headers.set('Accept', 'application/json');

//     headers = headers.set('Content-Type', 'application/json');


//     return this.httpClient.post<Customer>(`${this.basePath}/api/customer`, customer,

//       {
//         // withCredentials: this.configuration.withCredentials,
//         headers: headers
//       }
//     );
//   }

//   public putCustomer(customer: Customer): Observable<Customer> {
//     let headers = this.defaultHeaders;

//     headers = headers.set('Authorization', String(this.authService.getUserToken()));

//     // to determine the Accept header
//     let httpHeaderAccepts: string[] = [
//     ];

//     headers = headers.set('Accept', 'application/json');

//     headers = headers.set('Content-Type', 'application/json');


//     return this.httpClient.put<Customer>(`${this.basePath}/api/customer?id=${customer.Id}`, customer,

//       {
//         // withCredentials: this.configuration.withCredentials,
//         headers: headers
//       }
//     );
//   }

//   public deleteCustomer(customer: Customer): Observable<Customer> {
//     let headers = this.defaultHeaders;

//     headers = headers.set('Authorization', String(this.authService.getUserToken()));

//     // to determine the Accept header
//     let httpHeaderAccepts: string[] = [
//     ];

//     headers = headers.set('Accept', 'application/json');

//     headers = headers.set('Content-Type', 'application/json');

//     return this.httpClient.delete<Customer>(`${this.basePath}/api/customer/${customer.Id}`,
//       {
//         // withCredentials: this.configuration.withCredentials,
//         headers: headers
//       }
//     );
//   }
}
