import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faBars, faCheck, faEdit, faPencilAlt, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { InventoryPackageTypeDTO } from '../../../modules/inventory/models/inventory-package-type';
import { PackageTypesDataSource } from './package-types-datasource';
import { DataManager, ODataAdaptor, Query } from '@syncfusion/ej2-data';
import { FilterSettings } from '../../widgets/kw-filter-dropdown/models/filter-options';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../../@core/utils/error.service';
import { InventoryService } from '../../../modules/pick-plan/services/inventory.service';
import { FilterResults } from '../../widgets/kw-filter-dropdown/models/filter-results';
import { InventoryPackageTypeEditComponent } from './inventory-package-type-edit/inventory-package-type-edit.component';
import { KwFilterDropdownService } from '../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'package-types',
  templateUrl: './package-types.component.html',
  styleUrls: ['./package-types.component.css']
})
export class PackageTypesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: PackageTypesDataSource;

  public isLoading = true;
  private initialLoad = true;
  public faEdit = faEdit;
  public faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  public faCheck = faCheck;
  faBars = faBars;
  @ViewChild('grid') grid: GridComponent;
  public packageTypes: InventoryPackageTypeDTO[] = [];
  public allPackageTypes: InventoryPackageTypeDTO[] = [];
  public data: DataManager;
  public filterSettings: Object;
  public filter: Object;
  public pageSettings: PageSettingsModel = {
    pageSize: 50
  }

  public columns: any[] = [];

  public dropdownSettings: FilterSettings = {
    blockSettings: {
      display: true,
      singleSelection: false
    },
    farmSettings: {
      display: true,
      singleSelection: false
    },
    varietySettings: {
      display: true,
      singleSelection: false
    },
    storageSettings: {
      display: true,
      singleSelection: false
    },
    cropSettings: {
      display: false,
      singleSelection: true
    },
    cropYearSettings: {
      display: true,
      singleSelection: true
    },
    fieldmanSettings: {
      display: true,
      singleSelection: false
    },
    roomSettings: {
      display: false,
      singleSelection: false
    },
    warehouseSettings: {
      display: false,
      singleSelection: false
    },
    qcAreaSettings: {
      display: true,
      singleSelection: true
    }
  };

  constructor(
    public dialog: MatDialog,
    private errorService: ErrorService,
    private packageTypeService: InventoryService,
    private bottomSheet: MatBottomSheet,
    public filterService: KwFilterDropdownService,
  ) {

  }

  ngOnInit() {
    this.dataSource = new PackageTypesDataSource(this.paginator, this.sort);
    this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" }
    this.updateTable();
  }

  updateTable() {
    this.isLoading = true;
    Observable.forkJoin([
      this.packageTypeService.getInventoryPackageTypes()
    ]).subscribe(x => {
      this.packageTypes = x[0];
      this.allPackageTypes = x[0];
      this.isLoading = false;

    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    }
    );
  }

  setFilterResults(results: FilterResults) {
    // if (this.initialLoad) {
    //   this.initialLoad = false;
    //   this.filterResults = this.filterService.getFilterState();
    // }

    // let filteredResults = [...this.allAssemblies.filter(x =>
    //   (results.selectedFarms.map(y => y.id).includes(x.) || results.selectedFarms.length === 0) &&
    //   (results.selectedBlocks.map(y => y.id).includes(x.blockId) || results.selectedBlocks.length === 0) &&
    //   (results.selectedVarieties.map(y => y.id).includes(x.varietyId) || results.selectedVarieties.length === 0) &&
    //   (results.selectedQcAreas.map(y => y.id).includes(x.qcAreaId) || results.selectedQcAreas.length === 0) &&
    //   (results.selectedFieldmen.map(y => y.itemName).includes(x.fieldman) || results.selectedFieldmen.length === 0) &&
    //   (results.selectedCropYears.map(y => y.id).includes(x.cropYearId) || results.selectedCropYears.length === 0)
    // )];

    // this.assemblies = [...filteredResults];
  }

  public rowClicked(args): void {
    let data = {
      packageType: args
    };

    this.bottomSheet.open(InventoryPackageTypeEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      this.grid.clearSelection();
      this.updateTable();
      bottomSheetSubscription.unsubscribe();
    });
  }

  createPackageType() {
    let data = {
      packageType: {}
    };

    this.bottomSheet.open(InventoryPackageTypeEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      this.grid.clearSelection();
      this.updateTable();
      bottomSheetSubscription.unsubscribe();
    });
  }


}
