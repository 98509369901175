// src/app/auth/auth.service.ts

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
import { AUTH_CONFIG } from '../auth/auth.config';

import { timer, of } from 'rxjs';
import * as Promise from 'bluebird';
import { UsersService } from '../services/users.service';
import { UserDTO } from '../../harvest-engine/core/models/user';
import { TenantWordService } from '../../harvest-engine/core/services/TenantWord.service';
import { SignalrIntegrationsHubService } from '../../harvest-engine/core/signalr/signalr-integrations-hub.service';
import { environment } from '../../../environments/environment';
declare var localStorage: Storage;

(window as any).global = window;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  
  isAdmin: boolean;
  isGettingUser: boolean = false;
  // define the refreshSubscription property
  refreshSubscription: any;
  clientId: any;
  


  auth0: any;

  constructor(public router: Router,
    private injector: Injector,
  ) { 
    // get subdomain from the url
    const subdomain = window.location.hostname.split('.')[0];

    var clientId = AUTH_CONFIG.CLIENT_ID;
    this.clientId = clientId;
    var clientDomain = AUTH_CONFIG.CLIENT_DOMAIN;

    // // Hop Head serve Web Portal Locally
    // var clientId = 'aqxPzh14tBl5Q3thUGHt93OH9onyv04v';
    // var clientDomain = 'hopheadfarms.us.auth0.com';

    // if the subdomain exists, set the clientId and domain
    if (subdomain && subdomain.toLowerCase() == 'hopheadfarms' || environment.isHopHeadFarms) {
      clientId = 'aqxPzh14tBl5Q3thUGHt93OH9onyv04v';
      this.clientId = clientId;
      clientDomain = 'hopheadfarms.us.auth0.com';
    }
    if (subdomain && subdomain.toLowerCase() == 'vgf' || environment.isVGF) {
      clientId = 'LsmsMTFhLgiefCBuW3dKpXqEtlmPMvfA';
      this.clientId = clientId;
      clientDomain = 'vgf.us.auth0.com';
    }


    this.auth0 = new auth0.WebAuth({
      clientID: clientId,
      domain: clientDomain,
      responseType: 'token id_token code',
      audience: 'https://hub.krag.works',
      redirectUri: this.getCallbackURL(),
      scope: AUTH_CONFIG.SCOPE,
      algorithms: ['RS256'],
      
    });
  }

  public login(): void {
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {

        this.setSession(authResult);

        this.retrieveProfile(authResult).then(result => {
          this.setProfile(result);

          this.SetKragworksUser(() => {
            this.router.navigate(['/']);
          });
        });

        this.SetDictionary();

        return;

      } else if (err) {
        this.router.navigate(['/login']);
        console.log(err);
      } else {
        this.router.navigate(['/login']);
      }

    });
  }

  private setSession(authResult): void {

    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);

    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    this.scheduleRenewal();
  }

  public setToken(token: string){
    localStorage.setItem('access_token', token);
  }

  retrieveProfile(authResult) {

    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
        if (profile) {
          resolve(profile);
        } else if (err) {
          reject(err);
        }
      });
    });
  }

  private setProfile(profile): void {
    localStorage.setItem('authProfile', JSON.stringify(profile));
    this.isAdmin = this._checkAdmin(profile);

  }

  public userProfile(): any {
    const profile = JSON.parse(localStorage.getItem('userProfile'));

    // console.log(profile);
    if (profile === null || profile === undefined) {
      console.log('Cannot find user profile... performing reset');
      return;
    }

    if (profile.picture === null || profile.picture === undefined || profile.picture === '') {
      profile.picture = 'assets/images/favicon.png';
    }

    return profile;
  }

  public authProfile(): any {
    const profile = JSON.parse(localStorage.getItem('authProfile'));

    if (profile == null || profile === undefined) {
      this.SetKragworksUser(() => { });
    }
    return profile;
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('authProfile');
    localStorage.removeItem('userProfile');
    this.unscheduleRenewal();

    this.auth0.logout({
      returnTo: this.getCallbackURL(),
      clientID: this.clientId,
    });
    // Go back to the home route
    this.router.navigate(['login']);

  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time

    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    var result = new Date().getTime() < expiresAt;

    return result;
  }


  public getTenantId(): string {
    return this.authProfile()['https://kragworks:auth0:com/tenant_id'];
  }

  public getUserToken(): string {
    if (this.isAuthenticated) {
      return 'Bearer '.concat(localStorage.getItem('access_token'));
    } else { return ''; }

  }

  public renewToken() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.setSession(result);
      }
    });
  }

  private _checkAdmin(profile) {
    // Check if the user has admin role
    const roles = profile[AUTH_CONFIG.NAMESPACE] || [];
    return roles.indexOf('admin') > -1;
  }


  public scheduleRenewal() {
    if (!this.isAuthenticated()) { return; }
    this.unscheduleRenewal();

    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));

    const expiresIn$ = of(expiresAt).pipe(
      mergeMap(
        // tslint:disable-next-line:no-shadowed-variable
        expiresAt => {
          const now = Date.now();
          // Use timer to track delay until expiration
          // to run the refresh at the proper time
          return timer(Math.max(1, expiresAt - now));
        }
      )
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = expiresIn$.subscribe(
      () => {
        this.renewToken();
        this.scheduleRenewal();
      }
    );
  }

  public unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  // User Methods
  public SetKragworksUser(callback: (data) => void) {
    if (!this.isGettingUser) {
      this.isGettingUser = true;
      this.injector.get(UsersService).apiUsersGetCurrentUserGet().subscribe(user => {

        //TODO: SET DICTIONARY
        this.isGettingUser = false;
        localStorage.setItem('userProfile', JSON.stringify(user));
        callback(user);
      }, err => console.log(err));
    }
  }

  public SetDictionary() {
    localStorage.removeItem('tenantWordDictionary');
    this.injector.get(TenantWordService).setToken(this.getUserToken());
    this.injector.get(TenantWordService).getTenantWords().subscribe(words => {
      localStorage.setItem('tenantWordDictionary', JSON.stringify(words));
    });
  }

  public getCurrentUser(): UserDTO {

    return JSON.parse(localStorage.getItem('userProfile'));
  }

  // get callback
  private getCallbackURL(): string {
    let callback: string;

    const domain = window.location.hostname;

    if (domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' || domain.split('.')[0] === 'lvh' || domain.split('.')[0] === 'www') {
      callback = AUTH_CONFIG.REDIRECT;
    } else {


      callback = "https://" + domain + "/callback";
    }
    console.log('subdomain', callback);

    return callback;
  }


  public hasRole(expectedRole: string): boolean {
    let result: boolean = false;

    // iterate through all expected roles and see if user has them.
    if (this.userProfile().roles.some(role => role.name == expectedRole || role.name == 'kw:admin')) {
      result = true;
    }

    return result;
  }

  public hasTenant(expectedTenants: string[]): boolean {
    let result: boolean = false;

    // iterate through all expected roles and see if user has them.
    if (expectedTenants.some(x => x == this.userProfile().tenant.name) || this.hasRole('kw:admin')) {
      result = true;
    }
    
    return result;
  }
}
