import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../@core/utils/error.service';
import { PrinterDTO } from '../../../modules/estimates/models/printer-dto';
import { RestartService } from '../../services/restart.service';

@Component({
  selector: 'restart-service',
  templateUrl: './restart-service.component.html',
  styleUrls: ['./restart-service.component.scss']
})
export class RestartServiceComponent implements OnInit {

  public loading: boolean = true;
  public faPlusCircle = faPlusCircle;
  public serviceLoading = false;
  public printer: PrinterDTO;
  public submitting: any;
  

  constructor(
    public router: Router,
    private restartService: RestartService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
  }

  onLoaded(){
    this.loading = false;
  }

  setPrinter(printer: PrinterDTO){
    this.printer = printer;
  }

  restartClicked(){
    this.restartService.postRestart(this.printer.id).subscribe(x => {
      //do the things
    }, err => {this.errorService.handleError(err)});
  }

}
