import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ErrorService } from '../../../../@core/utils/error.service';
import { BreweryService } from '../../../../customer-integrations/hollingbery/modules/brewery-configuration/service/brewery.service';
import { CartApiService } from '../../../../customer-integrations/hollingbery/modules/financial/orders/services/cart.service';
import { EstimatesService } from '../../../modules/estimates/services/estimates.service';
import { InventoryService } from '../../../modules/pick-plan/services/inventory.service';
import { QualityControlStructureService } from '../../../modules/quality-control/services/quality-control-structure.service';
import { TenantWordsPipe } from '../../pipes/tenant-words.pipe';
import { BlockService } from '../../services/block.service';
import { CropYearService } from '../../services/crop-year.service';
import { CropService } from '../../services/crop.service';
import { RoomService } from '../../services/storage/room-service';
import { WarehouseService } from '../../services/storage/warehouse-service';
import { carrierService } from '../../../modules/pick-plan/services/carrier.service';
import { truckTypeService } from '../../../modules/pick-plan/services/truck-type.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterItem } from './models/kw-filter-item';
import { Room } from '../../models/storage/room';
import { Warehouse } from '../../models/storage/warehouse';
import { InventoryItemDTO } from '../../../modules/inventory/models/item';
import { RoomLocation } from '../../models/storage/room-location';
import { InventoryLotDTO } from '../../../modules/inventory/models/inventory-lot';
import { estimateTypeDTO } from '../../../modules/estimates/models/estimate-type-dto';
import { Cart } from '../../../../customer-integrations/hollingbery/modules/financial/orders/models/cart';
import { Brewery } from '../../../../customer-integrations/hollingbery/modules/brewery-configuration/models/brewery';
import { Crop } from '../../models/crop';
import { CropYear } from '../../models/cropYear';
import { QcArea } from '../../../modules/quality-control/models/qc-master';
import { BlockCustomFieldDTO, BlockCustomFieldValueDTO, BlockDTO } from '../../models/block';
import { Carrier } from '../../../modules/pick-plan/models/carrier';
import { TruckType } from '../../../modules/pick-plan/models/truck-type';
import * as _ from 'underscore';
import { FilterResults } from './models/filter-results';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AuthService } from '../../../../@core/auth/auth.service';
import { FarmFeatureService } from '../../../modules/farm-features/services/farm-feature.service';
import { FarmFeatureEventType } from '../../../modules/farm-features/models/farm-feature-event';
import { FarmFeature, FarmFeatureType } from '../../../modules/farm-features/models/farm-feature-dto';
import { Y } from '@angular/cdk/keycodes';
import { PoolTypeDTO } from '../../../modules/pick-plan/models/pool-type-dto';
import { PoolService } from '../../services/pool.service';
import { VarietyService } from '../../services/variety.service';
import { VarietyDTO } from '../../../../customer-integrations/hollingbery/modules/production/models/variety-dto';
import { CropVariety } from '../../models/crop-variety';
import { PlatformConfig } from '../../models/platform-config';
import { PlatformConfigService } from '../../services/platform-config.service';
import { StorageTypeDTO } from '../../../modules/pick-plan/models/storage-types-dto';
import { StorageService } from '../../services/storage.service';
import { WorkOrderService } from '../../../modules/work-orders/services/work-orders.service';
import { WorkOrderTypeService } from '../../../modules/work-orders/services/work-order-type.service';
import { WorkOrderType } from '../../../modules/work-orders/models/work-order';

export interface FilterRetainerRoute {
  sourceUrl: string;
  destinationUrl: string;
  bothDirections: boolean;
}

export interface BlockCustomFieldDropdownValue {
  name: string;
  id: number;
  data: FilterItem[];
}

@Injectable({
  providedIn: 'root'
})
export class KwFilterDropdownService {


  constructor(
    private blocksService: BlockService,
    private poolService: PoolService,
    private cropYearService: CropYearService,
    private pipe: TenantWordsPipe,
    private estimateService: EstimatesService,
    private CropService: CropService,
    private errorService: ErrorService,
    private QcStructureService: QualityControlStructureService,
    private warehouseService: WarehouseService,
    private inventoryService: InventoryService,
    private carrierService: carrierService,
    private truckTypeService: truckTypeService,
    private roomService: RoomService,
    private cartService: CartApiService,
    private breweryService: BreweryService,
    private router: Router,
    private authService: AuthService,
    private farmFeatureService: FarmFeatureService,
    private varietyService: VarietyService,
    private platformConfig: PlatformConfigService,
    private storageService: StorageService,
    private workOrderTypeService: WorkOrderTypeService,
  ) {



    let routerSubscription = this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        
        //--------- Do not believe this is needed anymore, but leaving it in for now ------------

        // let sourceUrl = events[0].urlAfterRedirects;
        // let destinationUrl = events[1].urlAfterRedirects;
        // let bypassFilterReset = true;

        // // check to see if it's a bypass route
        // for (let bypass of this.filterRetainerRoutes) {

        //   if (sourceUrl.includes(bypass.sourceUrl) && destinationUrl.includes(bypass.destinationUrl)) {
        //     bypassFilterReset = true;
        //   }

        //   // if it is flagged as both directions, check the inverse
        //   if (bypass.bothDirections) {
        //     if (destinationUrl.includes(bypass.sourceUrl) && sourceUrl.includes(bypass.destinationUrl)) {
        //       bypassFilterReset = true;
        //     }
        //   }

        // }

        // add if statement to retain filters permanently HE-492


        if (!this.bypassFilterReset) {
          this.resetDropdowns();
        } 

      });

  }

  onCheckboxChange(checked: boolean): void {
    this.bypassFilterReset = checked;
  }
  getRetainFilters(): boolean {
    return this.bypassFilterReset;
  }
  
  getFilterState(): FilterResults {
    return this.filterResults;
  }

  //Only reload varieties (For when a new variety is created)
  reloadVarieties() {
    this.isLoading = true;
    this.varietyService.getVarieties(true).subscribe(data => {
      this.allVarieties = data;
      this.allVarieties = _.sortBy(this.allVarieties, 'name');
      var varietyMap = [...this.allVarieties].map(x => {
        return {
          id: x.id,
          itemName: x.name
        };
      });
      var uniqVarieties = _.uniq(varietyMap, x => x.id);
      this.listOfVarieties = _.sortBy(uniqVarieties, x => x.itemName);
       this.isLoading = false;
    })
  }

  loadAllData() {
    this.isLoading = true;

    this.platformConfig.getIsHops().subscribe(isHops => {
      this.isHops = isHops;

      Observable.forkJoin([
        // this.blocksService.apiBlocksGet(),
        this.blocksService.apiBlocksGetAll(),
        this.cropYearService.getCropYears(),
        this.CropService.getCrops(),
        this.QcStructureService.getQcAreas(),
        this.estimateService.getEstimateTypes(),
        this.warehouseService.getWarehouses(),
        this.truckTypeService.getTruckTypes(),
        this.carrierService.getCarriers(),

        this.inventoryService.getInventoryItems(),
        this.inventoryService.getLotsForFilters(),
        this.roomService.getRoomLocations(),
        (this.authService.getCurrentUser().tenant.tenantId == '355809ec-3c76-4ef7-9341-b013755806f2' ? this.cartService.getCartReport() : Observable.of([])),
        (this.authService.getCurrentUser().tenant.tenantId == '355809ec-3c76-4ef7-9341-b013755806f2' ? this.breweryService.getBreweryList() : Observable.of([])),
        (this.authService.hasRole('farm-feature-events:read') ? this.farmFeatureService.getFarmFeatureEventTypes() : Observable.of([])),
        (this.authService.hasRole('farm-features:read') ? this.farmFeatureService.getFarmFeatures() : Observable.of([])),
        (this.authService.hasRole('farm-features:read') ? this.farmFeatureService.getFarmFeatureTypes() : Observable.of([])),
        this.blocksService.getCustomFields(),
        this.blocksService.getCustomFieldValues(),
        this.poolService.getPoolTypes(),
        this.varietyService.getVarieties(),
        this.storageService.getStorageTypes(),
        this.roomService.getRooms(),
        this.workOrderTypeService.getWorkOrderTypes(),
        // this.farmFeatureService.getFarmFeatureEventTypes(),
      ]
      ).subscribe((data: any) => {
        this.allBlocks = data[0];
        this.allYears = data[1];
        this.allCrops = data[2];
        this.allQcAreas = data[3];
        this.allEstimateTypes = data[4];

        this.allWarehouses = data[5];

        this.allTruckTypes = data[6];
        this.allCarriers = data[7];

        this.allInventoryItems = data[8];
        this.allLots = data[9];
        this.allLocations = data[10];

        this.allUserNames = data[11];
        this.allBreweries = data[12];

        this.allFeatureEventTypes = data[13];
        this.allFeatures = data[14];
        this.allFeatureTypes = data[15];

        this.allRooms = data[21];

        this.allCrops = _.sortBy(this.allCrops, 'name');
        this.allBlockCustomFieldValues = data[17];

        this.allPoolTypes = data[18];
        this.allPoolTypes = _.sortBy(this.allPoolTypes, 'name');

        this.allVarieties = data[19];
        this.allVarieties = _.sortBy(this.allVarieties, 'name');

        this.allStorageTypes = data[20];
        this.allStorageTypes = _.sortBy(this.allStorageTypes, 'name');

        this.customBlockFields = data[16].map(x => {
          let values = _.uniq([...this.allBlockCustomFieldValues.filter(field => field.blockCustomFieldId == x.id && field.value != '').map(x => x.value)]);


          let newValues = values.map(item => <FilterItem>{
            id: item,
            itemName: item
          });


          return {
            id: x.id,
            name: x.name,
            data: newValues
          };
        });

        this.allGroupings.push(
          {
            itemName: 'Block',
            id: 'blockName',
            group: ''
          },
          {
            itemName: 'Farm',
            id: 'farmName',
            group: ''
          },
          {
            itemName: 'Variety',
            id: 'varietyName',
            group: ''
          },
        )

        let customGroupings = data[16].map(x => {
          return {
            id: x.name,
            itemName: x.name,
            group: ''
          };
        });

        for (let x of customGroupings) {
          this.allGroupings.push(x);
        }

        this.allWorkOrderTypes = data[22];
        // for(let x of this.allWorkOrderTypes) {
        //   if(x.name == 'Greenhouse') {
        //     x.name = 'Plant-Out'
        //   }
        // }

        this.setDropdownLists();
      }, err => { 
        this.errorService.handleError(err); 
        this.isLoading = false; });
    }, err => { 
      this.errorService.handleError(err); 
      this.isLoading = false; });
  }

  public resetDropdowns(forceReset: boolean = false) {

    this.selectedGrouping = [];
    this.selectedFarms = [];
    this.selectedBlocks = this.defaultSelectedList;
    this.selectedBlockCustomFieldValue = [...this.customBlockFields.map(x => {
      return {
        id: x.id,
        name: x.name,
        data: []
      };
    })];

    this.selectedRooms = this.defaultSelectedList;
    this.selectedWarehouses = this.defaultSelectedList;
    this.selectedInventoryItem = this.defaultSelectedList;
    this.selectedLocation = this.defaultSelectedList;
    this.selectedLotNumber = [];
    this.selectedEstimateTypes = [{ itemName: 'Final', id: 0 }];
    this.selectedVarieties = [];
    this.selectedFieldmen = [];
    this.selectedUserName = [];
    this.selectedBrewery = [];
    this.selectedCarrier = [];
    this.selectedTruckType = [];
    this.selectedLoad = '';
    this.selectedCrop = this.defaultSelectedList;
    this.selectedTypes = [];
    this.selectedShipmentStatuses = this.defaultShipmentStatuses;
    this.selectedCropYear = [];
    this.selectedOrganicConventional = [];
    this.selectedCompleted = [];
    this.selectedQcAreas = [];
    this.selectedFeatureTypes = [];
    this.selectedFeatures = [];
    this.selectedFeatureEventType = [];
    this.selectedDates = [new Date(), new Date()];


    //this.loadAllData();
    this.setDropdownLists(forceReset);
  }

  public setDropdownLists(forceReset: boolean = false) {

    // if we flipped the bypass flag, reset it and skip over the resets
    
    if (this.bypassFilterReset && !this.initialLoad && !forceReset) {
      // this.bypassFilterReset = false;
      return;
    }
    if(this.initialLoad){
      this.initialLoad = false;
    }

    this.selectedCrop[0].id = this.allCrops.filter(x => x.isDefault === true)[0].id;
    this.selectedCrop[0].itemName = this.allCrops.filter(x => x.isDefault === true)[0].name;
    this.selectedBlocks = [];
    this.selectedBlockCustomFieldValue = [...this.customBlockFields.map(x => {
      return {
        id: x.id,
        name: x.name,
        data: []
      };
    })];
    this.selectedFeatureEventType = [];
    this.selectedWarehouses = [];
    this.selectedRooms = [];
    this.selectedInventoryItem = [];
    this.selectedLocation = [];
    this.selectedLotNumber = [];
    this.selectedFarms = [];
    this.selectedGrouping = [];
    this.selectedStorageTypes = [];
    this.selectedWorkOrderTypes = [];


    //set crop
    var crop = [...this.allCrops].map(x => {
      return {
        id: x.id,
        itemName: x.name,
      };
    });


    var uniqCrops = _.uniq(crop, x => x.id);
    this.listOfCrops = _.sortBy(uniqCrops, x => x.itemName);

    var blockMap = [...this.allBlocks].map(x => {
      return {
        id: x.id,
        itemName: x.name + " (" + x.varietyName + ")",
        group: x.farmName
      };
    });

    var uniqBlocks = _.uniq(blockMap, x => x.id);
    this.listOfBlocks = _.sortBy(uniqBlocks, x => x.group);

    /// set farms
    var farmMap = [...this.allBlocks].map(x => {
      return {
        id: x.farmId,
        itemName: x.farmName
      };
    });
    var uniqFarms = _.uniq(farmMap, x => x.id);
    this.listOfFarms = _.sortBy(uniqFarms, x => x.itemName);

    /// set storage types
    var storageTypeMap = [...this.allStorageTypes].map(x => {
      return {
        id: x.id,
        itemName: x.name
      };
    });
    var uniqStorageTypes = _.uniq(storageTypeMap, x => x.id);
    this.listOfStorageTypes = _.sortBy(uniqStorageTypes, x => x.itemName);

    /// set work order types
    var workOrderTypeMap = [...this.allWorkOrderTypes].map(x => {
      return {
        id: x.id,
        itemName: x.name,
      };
    });
    var uniqWorkOrderTypes = _.uniq(workOrderTypeMap, x => x.id);
    this.listOfWorkOrderTypes = _.sortBy(uniqWorkOrderTypes, x => x.itemName);

    /// set feature event types
    var eventTypeMap = [...this.allFeatureEventTypes].map(x => {
      return {
        id: x.id,
        itemName: x.name
      };
    });
    var uniqEventTypes = _.uniq(eventTypeMap, x => x.id);
    this.listOfFeatureEventTypes = _.sortBy(uniqEventTypes, x => x.itemName);

    this.listOfFeatureTypes = _.sortBy([...this.allFeatureTypes].map(x => {
      return {
        id: x.farmFeatureTypeId,
        itemName: x.name
      };
    }), x => x.itemName);

    this.listOfFeatures = _.sortBy([...this.allFeatures].map(x => {
      return {
        id: x.farmFeatureId,
        itemName: x.name,
        group: x.farmName
      };
    }), x => x.itemName);

    var eventTypeMap = [...this.allFeatureEventTypes].map(x => {
      return {
        id: x.id,
        itemName: x.name
      };
    });
    var uniqEventTypes = _.uniq(eventTypeMap, x => x.id);
    this.listOfFeatureEventTypes = _.sortBy(uniqEventTypes, x => x.itemName);

    /// set varieties
    if (this.isHops) {
      let varietyMap = [...this.allVarieties]
        .filter(x =>
        (
          (this.selectedBlocks.map(y => y.id).includes(x.id) || this.selectedBlocks.length == 0) &&
          (this.selectedCrop.map(y => y.id).includes(x.cropId) || this.selectedCrop.length == 0))
        )
        .map(x => {
          return {
            id: x.id,
            itemName: x.name,
          };
        });

      let uniqVarieties = _.uniq(varietyMap, x => x.id);
      this.listOfVarieties = _.chain(uniqVarieties).sortBy(x => x.itemName).sortBy(x => x.itemName).value();
    } else if (this.allBlocks.length > 0) {
      var varietyMap = [...this.allBlocks].map(x => {
        return {
          id: x.varietyId,
          itemName: x.varietyName
        };
      });
      var uniqVarieties = _.uniq(varietyMap, x => x.id);
      this.listOfVarieties = _.sortBy(uniqVarieties, x => x.itemName);
    } else {
      var varietyMap = [...this.allVarieties].map(x => {
        return {
          id: x.id,
          itemName: x.name
        };
      });
      var uniqVarieties = _.uniq(varietyMap, x => x.id);
      this.listOfVarieties = _.sortBy(uniqVarieties, x => x.itemName);
    }


    /// set pools
    var poolMap = [...this.allPoolTypes].map(x => {
      return {
        id: x.id,
        itemName: x.name
      };
    });
    var uniqPools = _.uniq(poolMap, x => x.id);
    this.listOfPoolTypes = _.sortBy(uniqPools, x => x.itemName);

    /// set carriers
    var carrierMap = [...this.allCarriers].map(x => {
      return {
        id: x.id,
        itemName: x.name
      };
    });
    var uniqCarriers = _.uniq(carrierMap, x => x.id);
    this.listOfCarriers = _.sortBy(uniqCarriers, x => x.itemName);

    /// set truckTypes
    var truckTypeMap = [...this.allTruckTypes].map(x => {
      return {
        id: x.id,
        itemName: x.name
      };
    });
    var uniqTruckTypes = _.uniq(truckTypeMap, x => x.id);
    this.listOfTruckTypes = _.sortBy(uniqTruckTypes, x => x.itemName);

    // set fieldmen
    var fieldmenMap = [...this.allBlocks].map(x => {
      return {
        id: x.fieldman || '',
        itemName: x.fieldman || 'None'
      };
    });
    var uniqFieldmen = _.uniq(fieldmenMap, x => x.id);
    this.listOfFieldmen = _.sortBy(uniqFieldmen, x => x.itemName);

    let resultBlocks = this.allBlocks.filter(x => this.selectedBlocks.filter(y => y.id != 0).map(y => y.id).includes(x.id));


    let sortedYears = _.sortBy(this.allYears, x => x.year).reverse();

    sortedYears = sortedYears.filter(x => x.cropId === this.selectedCrop[0].id);

    // set crop years
    if (resultBlocks.length > 0) {
      this.listOfYears = [...sortedYears.filter(x => resultBlocks.map(y => y.cropId).includes(x.cropId))].map(x => <FilterItem>{
        id: x.id,
        itemName: x.name,
        isActive: x.isCurrentYear,
      });
    } else if (this.allBlocks.length > 0) {
      this.listOfYears = [...sortedYears.filter(x => this.allBlocks.map(y => y.cropId).includes(x.cropId))].map(x => <FilterItem>{
        id: x.id,
        itemName: x.name,
        isActive: x.isCurrentYear,
      });
    } else {
      this.listOfYears = [...sortedYears].map(x => <FilterItem>{
        id: x.id,
        itemName: x.name,
        isActive: x.isCurrentYear,
      });
    }

    // qc areas
    this.listOfQcAreas = _.sortBy(this.allQcAreas, x => x.name).map(x => <FilterItem>{
      id: x.id,
      itemName: x.name
    });

    // warehouse
    this.listOfWarehouses = this.allWarehouses.map(x => <FilterItem>{
      id: x.id,
      itemName: x.name,
    });

    // item
    this.listOfInventoryItems = this.allInventoryItems.map(x => <FilterItem>{
      id: x.id,
      itemName: x.name,
    });

    // usernames
    this.listOfUserNames = _.uniq(
      _.sortBy(
        this.allUserNames.map(x => <FilterItem>{
          id: x.id,
          itemName: x.userName,
        }), 
        x => x.itemName
      ), 
      x => x.itemName
    );

    // breweries
    this.listOfBreweries = _.sortBy(this.allBreweries.map(x => <FilterItem>{
      id: x.recordId,
      itemName: x.displayName,
    }), x => x.itemName);

    // locations
    this.listOfLocations = this.allLocations.map(x => <FilterItem>{
      id: x.id,
      itemName: x.name,
    });

    // lots
    this.listOfLotNumbers = this.allLots.map(x => <FilterItem>{
      id: x.id,
      itemName: x.lotNumber,
    });

    // room
    this.listOfRooms = this.allRooms.map(x => <FilterItem>{
      id: x.id,
      itemName: x.name,
      group: x.warehouse.name
    });

    // storage
    this.listOfStorageTypes = this.allStorageTypes.map(x => <FilterItem>{
      id: x.id,
      itemName: x.name,
      group: ''
    });

    // feature stuff
    this.listOfFeatureEventTypes = this.allFeatureEventTypes.map(x => <FilterItem>{
      id: x.id,
      itemName: x.name,
      group: x.farmFeatureTypeName
    });

    this.listOfFeatureTypes = this.allFeatureTypes.map(x => <FilterItem>{
      id: x.farmFeatureTypeId,
      itemName: x.name,
    });

    this.listOfFeatures = this.allFeatures.map(x => <FilterItem>{
      id: x.farmFeatureId,
      itemName: x.name,
      group: x.farmName
    });

    this.selectedCropYear = this.listOfYears.filter(x => x.isActive == true);

    // this.selectedCropYear = this.listOfYears.filter(x => x.itemName === new Date().getFullYear().toString());

    this.filterBlocks();
    this.filterRooms();
    this.filterRoomLocations();

    this.filterFieldman();
    this.filterEstimateTypes();

    // We do not need to emit the crop as it is being emitted via the filterResultsChange.emit and part of the filterResults array
    //this.cropYear.emit(this.selectedCropYear[0]);

    this.filterResults.selectedShipmentStatuses = this.selectedShipmentStatuses;
    this.filterResults.selectedCropYears = this.selectedCropYear;
    this.filterResults.selectedWarehouses = this.selectedWarehouses;

    this.filterResults.selectedLotNumbers = this.selectedLotNumber;
    this.filterResults.selectedInventoryItems = this.selectedInventoryItem;
    this.filterResults.selectedLocations = this.selectedLocation;

    this.filterResults.selectedRooms = this.selectedRooms;
    this.filterResults.selectedOrganConventional = this.selectedOrganicConventional;
    this.filterResults.selectedBlocks = this.selectedBlocks;

    this.filterResults.selectedFarms = this.selectedFarms;
    this.filterResults.selectedVarieties = this.selectedVarieties;
    this.filterResults.selectedFieldmen = this.selectedFieldmen;

    this.filterResults.selectedUserNames = this.selectedUserName;
    this.filterResults.selectedBreweries = this.selectedBrewery;

    this.filterResults.selectedQcAreas = this.selectedQcAreas;
    this.filterResults.selectedEstimateTypes = this.selectedEstimateTypes;
    this.filterResults.selectedRooms = this.selectedRooms;
    this.filterResults.selectedCarriers = this.selectedCarrier;
    this.filterResults.selectedTruckTypes = this.selectedTruckType;
    this.filterResults.selectedDateRange = this.selectedDates;
    this.filterResults.selectedGrouping = this.selectedGrouping;

    // this.filterResultsChange.emit(this.filterResults); 
    this.hasLoaded = true;
    this._itemChangedSource.next(null);
    this.isLoading = false;

  }

  public filter(event: any = null) {
    this.filterBlocks();
    this.filterRooms();
    this.filterRoomLocations();

    this.filterFieldman();
    this.filterEstimateTypes();
    this.change();

  }

  selectDates() {
    this.filterResults.selectedDateRange = this.selectedDates;
    this.filter();

  }

  change() {
    this.filterResults.selectedBlocks = this.selectedBlocks;

    this.filterResults.selectedWorkOrderTypes = this.selectedWorkOrderTypes;

    this.filterResults.selectedStorageTypes = this.selectedStorageTypes;

    this.filterResults.selectedPools = this.selectedPoolTypes;

    this.filterResults.selectedGrouping = this.selectedGrouping;

    this.filterResults.selectedBlockCustomFieldValue = this.selectedBlockCustomFieldValue;

    this.filterResults.selectedFeatureEventTypes = this.allFeatureEventTypes.filter(x => this.selectedFeatureEventType.map(y => y.id).includes(x.id));
    this.filterResults.selectedFeatureTypes = this.allFeatureTypes.filter(x => this.selectedFeatureTypes.map(y => y.id).includes(x.farmFeatureTypeId));
    this.filterResults.selectedFeatures = this.allFeatures.filter(x => this.selectedFeatures.map(y => y.id).includes(x.farmFeatureId));

    this.filterResults.selectedCropYears = this.selectedCropYear.filter(x => x.id != 0);
    this.filterResults.selectedFarms = this.selectedFarms.filter(x => x.id != 0);
    this.filterResults.selectedFieldmen = this.selectedFieldmen;

    this.filterResults.selectedUserNames = this.selectedUserName;
    this.filterResults.selectedBreweries = this.selectedBrewery;

    this.filterResults.selectedCarriers = this.selectedCarrier;
    this.filterResults.selectedTruckTypes = this.selectedTruckType;
    this.filterResults.selectedLoad = this.selectedLoad;

    this.filterResults.selectedVarieties = this.selectedVarieties;
    this.filterResults.selectedOrganConventional = this.selectedOrganicConventional;
    this.filterResults.selectedQcAreas = this.selectedQcAreas;
    this.filterResults.selectedEstimateTypes = this.allEstimateTypes.filter(x => this.selectedEstimateTypes.map(y => y.id).includes(x.id));
    this.filterResults.selectedWarehouses = this.allWarehouses.filter(x => this.selectedWarehouses.map(y => y.id).includes(x.id));

    this.filterResults.selectedInventoryItems = this.allInventoryItems.filter(x => this.selectedInventoryItem.map(y => y.id).includes(x.id));
    this.filterResults.selectedLotNumbers = this.allLots.filter(x => this.selectedLotNumber.map(y => y.id).includes(x.id));
    this.filterResults.selectedLocations = this.allLocations.filter(x => this.selectedLocation.map(y => y.id).includes(x.id));


    this.filterResults.selectedRooms = this.allRooms.filter(x => this.selectedRooms.map(y => y.id).includes(x.id));
    this.filterResults.selectedDateRange = [...this.selectedDates];
    this.filterResults.selectedShipmentStatuses = this.selectedShipmentStatuses;

    if (this.filterResults.selectedEstimateTypes.length == 0)
      this.filterResults.selectedEstimateTypes = [{ id: 0, name: 'Final' }]

    if (this.selectedCompleted.length > 0)
      this.filterResults.includeCompleted = this.selectedCompleted[0].id;

    this._itemChangedSource.next(null);

  }

  filterEstimateTypes() {
    // estimate types
    this.listOfEstimateTypes = _.sortBy(this.allEstimateTypes.filter(x => x.cropId == this.selectedCrop[0].id), x => x.order).map(x => <FilterItem>{
      id: x.id,
      itemName: x.name
    });
    this.listOfEstimateTypes = [...this.listOfEstimateTypes, { id: 0, itemName: 'Final' }];
  }


  filterBlocks() {
    let customFieldValues = [];
    this.selectedBlockCustomFieldValue.forEach(x => x.data.forEach(d => customFieldValues.push(d)));

    let blockMap = [...this.allBlocks]
      .filter(x =>
      (
        (this.selectedFarms.map(y => y.id).includes(x.farmId) || this.selectedFarms.length == 0) &&
        (this.selectedVarieties.map(y => y.id).includes(x.varietyId) || this.selectedVarieties.length == 0) &&
        (this.selectedFieldmen.map(y => y.id).includes(x.fieldman || '') || this.selectedFieldmen.length == 0) &&
        //(this.selectedBlocks.map(y => y.id).includes(x.id) || this.selectedBlocks.length == 0) &&
        (this.selectedCrop.map(y => y.id).includes(x.cropId) || this.selectedCrop.length == 0) //&&

        //(customFieldValues.map(y => y.itemName).includes(x.customFields.map(z => z.value)) || customFieldValues.length == 0)
      )
      )
      .map(x => {
        return {
          id: x.id,
          itemName: x.name + " (" + x.varietyName + ")",
          group: x.farmName
        };
      });

    let uniqBlocks = _.uniq(blockMap, x => x.id);
    this.listOfBlocks = _.chain(uniqBlocks).sortBy(x => x.itemName).sortBy(x => x.group).value();
  }

  filterRooms() {
    let roomMap = [...this.allRooms]
      .filter(x =>
        (this.selectedWarehouses.map(y => y.id).includes(x.warehouse.id) || this.selectedWarehouses.length == 0)
      )
      .map(x => {
        return {
          id: x.id,
          itemName: x.name,
          group: x.warehouse.name
        };
      });

    let uniqBlocks = _.uniq(roomMap, x => x.id);
    this.listOfRooms = _.chain(uniqBlocks).sortBy(x => x.itemName).sortBy(x => x.group).value();

    if(this.listOfRooms.length == 0)
        this.listOfRooms = [{ itemName: 'No data found', id: 0 }];
  }

  filterRoomLocations() {
    
    let locationMap = [];
    if (this.selectedRooms.length == 0)
      locationMap = [...this.allLocations]
        .filter(x =>
          (this.listOfRooms.map(y => y.id).includes(x.roomId))
        )
        .map(x => {
          return {
            id: x.id,
            itemName: x.name,
            group: x.room.name
          };
        });

    else
      locationMap = [...this.allLocations]
        .filter(x =>
          (this.selectedRooms.map(y => y.id).includes(x.roomId) || this.selectedRooms.length == 0)
        )
        .map(x => {
          return {
            id: x.id,
            itemName: x.name,
            group: x.room.name
          };
        });

    let uniqBlocks = _.uniq(locationMap, x => x.id);
    this.listOfLocations = _.chain(uniqBlocks).sortBy(x => x.itemName).sortBy(x => x.group).value();

    if(this.listOfLocations.length == 0){
        this.listOfLocations = [{ itemName: 'No data found', id: 0 }];
    }
  }

  filterFieldman() {
    let fieldmanMap = [...this.allBlocks]
      .filter(x =>
      (
        (this.selectedFarms.map(y => y.id).includes(x.farmId) || this.selectedFarms.length == 0) &&
        (this.selectedVarieties.map(y => y.id).includes(x.varietyId) || this.selectedVarieties.length == 0) &&
        (this.selectedFieldmen.map(y => y.id).includes(x.fieldman || '') || this.selectedFieldmen.length == 0) &&
        (this.selectedBlocks.map(y => y.id).includes(x.id) || this.selectedBlocks.length == 0))
      )
      .map(x => {
        return {
          id: x.fieldman,
          itemName: x.fieldman || 'None',
        };
      });

    let uniqFieldmans = _.uniq(fieldmanMap, x => x.id);
    this.listOfFieldmen = _.chain(uniqFieldmans).sortBy(x => x.itemName).sortBy(x => x.itemName).value();

  }

  filterCropYearByCropID() {

    this.listOfYears = [...this.allYears.filter(x => x.cropId === this.selectedCrop[0].id).map(y => {
      return {
        id: y.id,
        itemName: y.year.toString(),
        isActive: y.isCurrentYear,
      }
    })]
    this.listOfYears = _.sortBy(this.listOfYears, 'itemName').reverse();

    if(this.listOfYears.filter(x => x.itemName === this.selectedCropYear[0].itemName).length > 0){
      this.selectedCropYear = this.listOfYears.filter(x => x.itemName === this.selectedCropYear[0].itemName)
    } else {
      this.selectedCropYear = [this.listOfYears[0]]
    }
    this.filter();
  }


  /// Properties
  public selectedDates: Date[] = [];
  public isLoading: boolean = true;
  public hasLoaded: boolean = false;
  public filterRetainerRoutes: FilterRetainerRoute[] = [
    {
      sourceUrl: '/harvest-engine/estimates/estimates-list',
      destinationUrl: '/harvest-engine/pick-plan/pick-plan-edit',
      bothDirections: true
    },
    {
      sourceUrl: '/harvest-engine/inventory/inventory-summary',
      destinationUrl: '/harvest-engine/inventory/inventory-summary-popup',
      bothDirections: true
    }
  ]

  public isHops: boolean = false;
  public bypassFilterReset: boolean = true;
  public initialLoad = true;



  public loadSearchValue: string;
  private defaultSelectedList = [{ itemName: 'Select', id: 0 }];

  private _itemChangedSource = new BehaviorSubject(null);
  public itemChanged = this._itemChangedSource.asObservable();


  public filterResults: FilterResults = {
    selectedBlocks: [],
    selectedBlockCustomFieldValue: [],
    selectedCropYears: [],
    selectedFarms: [],
    selectedFieldmen: [],
    selectedVarieties: [],
    selectedOrganConventional: [],
    selectedDateRange: [],
    selectedQcAreas: [],
    includeCompleted: false,
    selectedCarriers: [],
    selectedTruckTypes: [],
    selectedLoad: "",
    selectedInventoryItems: [],
    selectedLocations: [],
    selectedLotNumbers: [],
    selectedBreweries: [],
    selectedUserNames: [],
    selectedFeatureEventTypes: [],
    selectedFeatureTypes: [],
    selectedFeatures: [],
    selectedGrouping: [],
    selectedStorageTypes: [],
    selectedWorkOrderTypes: []
  };

  public allWorkOrderTypes: WorkOrderType[] = [];
  public selectedFarms: FilterItem[] = [];
  public listOfFarms: FilterItem[] = [];
  public listOfWorkOrderTypes: FilterItem[] = [];
  public selectedWorkOrderTypes: FilterItem[] = [];
  public farmDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Farm") + "s",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "No data found",
    badgeShowLimit: 1,
  };


  public selectedBlocks: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  public listOfBlocks: FilterItem[] = [];
  public allBlocks: BlockDTO[] = [];

  public selectedBlockCustomFieldValue: BlockCustomFieldDropdownValue[] = [];
  public listOfBlockCustomFieldValues: FilterItem[] = [];
  private allBlockCustomFieldValues: BlockCustomFieldValueDTO[] = [];
  public customBlockFields: BlockCustomFieldDropdownValue[] = [];
  public allCustomBlockFieldValues: BlockCustomFieldDropdownValue[] = [];

  public selectedRooms: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  public listOfRooms: FilterItem[] = [];
  public allRooms: Room[] = [];


  public selectedWarehouses: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  public listOfWarehouses: FilterItem[] = [];
  public allWarehouses: Warehouse[] = [];

  public selectedInventoryItem: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  public listOfInventoryItems: FilterItem[] = [];
  public allInventoryItems: InventoryItemDTO[] = [];

  public selectedLocation: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  public listOfLocations: FilterItem[] = [];
  public allLocations: RoomLocation[] = [];

  public selectedLotNumber: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  public listOfLotNumbers: FilterItem[] = [];
  public allLots: InventoryLotDTO[] = [];

  public selectedEstimateTypes: FilterItem[] = [{ itemName: 'Final', id: 0 }];
  public listOfEstimateTypes: FilterItem[] = [];
  public allEstimateTypes: estimateTypeDTO[] = [];

  public selectedPoolTypes: FilterItem[] = [];
  public listOfPoolTypes: FilterItem[] = [];
  public allPoolTypes: PoolTypeDTO[] = [];

  public allVarieties: CropVariety[] = [];
  public selectedVarieties: FilterItem[] = [];
  public listOfVarieties: FilterItem[] = [];

  public allFeatures: FarmFeature[] = [];
  public selectedFeatures: FilterItem[] = [];
  public listOfFeatures: FilterItem[] = [];

  public allFeatureTypes: FarmFeatureType[] = [];
  public selectedFeatureTypes: FilterItem[] = [];
  public listOfFeatureTypes: FilterItem[] = [];

  public selectedFieldmen: FilterItem[] = [];
  public listOfFieldmen: FilterItem[] = [];

  public selectedUserName: FilterItem[] = [];
  public listOfUserNames: FilterItem[] = [];
  public allUserNames: Cart[] = [];

  public selectedBrewery: FilterItem[] = [];
  public listOfBreweries: FilterItem[] = [];
  public allBreweries: Brewery[] = [];

  public allFeatureEventTypes: FarmFeatureEventType[] = [];
  public selectedFeatureEventType: FilterItem[] = [];

  public selectedCarrier: FilterItem[] = [];
  public listOfCarriers: FilterItem[] = [];
  private allCarriers: Carrier[] = [];

  public selectedTruckType: FilterItem[] = [];
  public listOfTruckTypes: FilterItem[] = [];
  private allTruckTypes: TruckType[] = [];

  public selectedLoad: string = "";

  public allGroupings: FilterItem[] = [];

  public selectedGrouping: FilterItem[] = [];

  // public selectedCrop: FilterItem[] = [];
  public selectedCrop: FilterItem[] = [{ itemName: 'Select', id: 0 }];
  public listOfCrops: FilterItem[] = [];
  public allCrops: Crop[] = [];

  // STORAGE TYPES //
  public selectedStorageTypes: FilterItem[] = [];
  public listOfStorageTypes: FilterItem[] = [];
  private allStorageTypes: StorageTypeDTO[] = [];

  public selectedTypes: FilterItem[] = [];
  public listOfTypes: FilterItem[] = [];

  defaultShipmentStatuses: FilterItem[] = [{
    id: 1,
    itemName: 'Shipment Scheduled'
  },
  {
    id: 2,
    itemName: 'Accepted'
  },
  {
    id: 3,
    itemName: 'Expired'
  },
  {
    id: 4,
    itemName: 'Picked Up'
  },
  {
    id: 6,
    itemName: 'Rescheduled'
  },
  {
    id: 7,
    itemName: 'Updated'
  },
  ];

  public selectedShipmentStatuses: FilterItem[] = this.defaultShipmentStatuses;

  public listOfShipmentStatuses: FilterItem[] = [
    {
      id: 1,
      itemName: 'Shipment Scheduled'
    },
    {
      id: 2,
      itemName: 'Accepted'
    },
    {
      id: 3,
      itemName: 'Expired'
    },
    {
      id: 4,
      itemName: 'Picked Up'
    },
    {
      id: 5,
      itemName: 'Received'
    },
    {
      id: 6,
      itemName: 'Rescheduled'
    },
    {
      id: 7,
      itemName: 'Updated'
    },
  ];

  public listOfYears: FilterItem[] = [];
  public allYears: CropYear[] = [];

  defaultCropYear: number;
  public selectedCropYear: FilterItem[] = [];

  public selectedOrganicConventional: FilterItem[] = [];

  public listOfOrganic: FilterItem[] = [
    { itemName: 'Organic', id: true },
    { itemName: 'Conventional', id: false }];

  public selectedCompleted: FilterItem[] = [];
  public listOfCompleted: FilterItem[] = [
    { itemName: 'Yes', id: true },
    { itemName: 'No', id: false }
  ];




  public selectedQcAreas: FilterItem[] = [];
  public listOfQcAreas: FilterItem[] = [];
  public allQcAreas: QcArea[] = [];
  public listOfFeatureEventTypes: FilterItem[] = [];
}
