import { Component, OnInit } from '@angular/core';
import { StorageTypeDTO } from '../../../../modules/pick-plan/models/storage-types-dto';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { StorageService } from '../../../services/storage.service';
import { MatDialogRef } from '@angular/material';
import { SessionService } from '../../../../../@core/utils/session.service';
import { NgForm } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'storage-details',
  templateUrl: './storage-details.component.html',
  styleUrls: ['./storage-details.component.scss']
})
export class StorageDetailsComponent implements OnInit {

 //#region Properties
 public editActive = false;
 public storageLoading = true;
 public editStorageType: StorageTypeDTO = {};
 public submitting: any;
 //#endregion

  constructor(
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<any>,
    private session: SessionService,
    public storageService: StorageService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
    this.editStorageType = this.session.selectedStorageType;
    if(
      this.editStorageType === null ||
      this.editStorageType === undefined){
        this.editStorageType = {};
        this.editStorageType.isActive = true;
        this.storageLoading = false;
      } 
  }

  cancelClicked(form: NgForm, cancelled: boolean = false) {
    this.editActive = false;
    this.editStorageType = {};
    form.resetForm();
    this.dialogRef.close(cancelled);
  }

  onSubmit() {
    this.submitting = true;
    setTimeout(()=>{
      if (this.editStorageType.id) {
        this.storageService
          .putStorageTypeDTO(this.editStorageType)
          .subscribe(
            () => {
              this.toastrService.success(
                "Storage Type Details successfully updated!",
                this.editStorageType.name + " updated!"
              );
              this.dialogRef.close(true);
            },
            (err) => {
              this.errorService.handleError(err);
              this.dialogRef.close(false);
            }
          );
      } else {
        this.storageService.postStorageTypeDTO(this.editStorageType).subscribe(
          () => {
            this.toastrService.success(
              "Storage Type Details successfully saved!",
              this.editStorageType.name + " saved!"
            );
            this.dialogRef.close(true);
          },
          (err) => {
            this.errorService.handleError(err);
            this.dialogRef.close(false);
          }
        );
      }
    },1000);
  }

}
