import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { SessionService } from '../../../../../@core/utils/session.service';
import { PoolTypeDTO } from '../../../../modules/pick-plan/models/pool-type-dto';
import { PoolService } from '../../../services/pool.service';
import { PoolListDataSource } from '../pool-list-datasource';
import * as _ from 'underscore';

@Component({
  selector: 'pool-details',
  templateUrl: './pool-details.component.html',
  styleUrls: ['./pool-details.component.scss']
})
export class PoolDetailsComponent implements OnInit {

  //#region Properties
  dataSource: PoolListDataSource;
  public editPool: PoolTypeDTO = {};
  public editActive = false;
  public poolLoading: boolean = false;
  public submitting: any;
  //#endregion

  //#region ViewChild Properties
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  //#endregion

  //#region Constructor with dependencies
  constructor(
    private poolService: PoolService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<any>,
    private sessionService: SessionService
  ) { }
  //#endregion

  ngOnInit() {
    this.dataSource = new PoolListDataSource(this.paginator, this.sort);
    this.poolLoading = true;

    this.editPool = this.sessionService.selectedPoolType;
    if (this.editPool === null || this.editPool === undefined) {
      this.editPool = {};
      this.editActive = true;
      this.poolLoading = false;
    }

    this.poolService.getPoolTypes().subscribe(data => {
      data = _.sortBy(data, 'name');
      this.dataSource.data = data;

      if (this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
      this.poolLoading = false;
    }, err => { this.errorService.handleError(err); this.poolLoading = false; });
  }

  savePoolClicked() {
    this.submitting = true;
    setTimeout(() => {
      if (this.editPool.isActive === null || this.editPool.isActive === undefined) { this.editPool.isActive = false; }
      if (this.editPool.id !== null && this.editPool.id !== undefined) {
        this.editPool.updatedDate = new Date;
        let index = this.dataSource.data.indexOf(this.dataSource.data.filter(w => w.id === this.editPool.id)[0]);
        this.poolService.putPoolTypeDTO(this.editPool).subscribe(x => {
          this.dataSource.data[index] = this.editPool;
          this.dataSource.data = [...this.dataSource.data];
          if (this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
          this.editPool = {};
          this.dialogRef.close(true);
        }, err => {
          this.errorService.handleError(err);
          this.dialogRef.close(false);
        });
      }
      else {
        this.editPool.id = 0;
        this.editPool.createdDate = new Date();
        this.editPool.updatedDate = this.editPool.createdDate;
        this.poolService.postPoolTypeDTO(this.editPool).subscribe(x => {
          this.editPool.id = x.id;
          this.dataSource.data.push(this.editPool);
          this.dataSource.data = [...this.dataSource.data];
          if (this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
          this.editPool = {};
          this.dialogRef.close(true);
        }, err => {
          this.errorService.handleError(err);
          this.dialogRef.close(false);
        });
      }
      this.editActive = false;
    }, 1000);
  }

  cancelClicked(cancelled: boolean = false) {
    this.editActive = false;
    this.editPool = {};
    this.dialogRef.close(cancelled);
  }

}
