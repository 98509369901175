import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dictionary-edit',
  templateUrl: './dictionary-edit.component.html',
  styleUrls: ['./dictionary-edit.component.scss']
})
export class DictionaryEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
