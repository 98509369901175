import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import {  QcArea, QcType } from '../models/qc-master';
import * as _ from 'underscore';




@Injectable({
  providedIn: 'root'
})
export class QualityControlStructureService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this.defaultHeaders = this.defaultHeaders.set('Authorization', String(this.authService.getUserToken()));
  }


  public getQcAreas(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    let headers =  this.defaultHeaders;
    
    return this.httpClient.get<QcArea[]>(`${this.basePath}/api/qcareas`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getQcArea(id: number): Observable<QcArea> {

    let headers = this.defaultHeaders;

    return this.httpClient.get<QcArea>(`${this.basePath}/api/qcareas/${id}`,
      {
        headers,
      }
    );
  }

  public postQcArea(value: QcArea): Observable<QcArea> {

    let headers = this.defaultHeaders;

    return this.httpClient.post<QcArea>(`${this.basePath}/api/qcArea`, value,

      {
        headers,
      }
    );
  }

  public putQcArea(value: QcArea): Observable<QcArea> {

    let headers = this.defaultHeaders;

    return this.httpClient.put<QcArea>(`${this.basePath}/api/qcAreas/${value.id}`, value,

      {
        headers,
      }
    );
  }

  public deleteQcArea(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    return this.httpClient.delete(`${this.basePath}/api/qcareas/${id}`,
      {
        headers,
      }
    );
  }


  public getQcTypes(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    let headers =  this.defaultHeaders;
    
    return this.httpClient.get<QcType[]>(`${this.basePath}/api/QcTypes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getQcType(id: number): Observable<QcType> {

    let headers = this.defaultHeaders;

    return this.httpClient.get<QcType>(`${this.basePath}/api/QcTypes/${id}`,
      {
        headers,
      }
    );
  }

  public postQcType(value: QcType): Observable<QcType> {

    let headers = this.defaultHeaders;

    return this.httpClient.post<QcType>(`${this.basePath}/api/QcTypes`, value,

      {
        headers,
      }
    );
  }

  public putQcType(value: QcType): Observable<QcType> {

    let headers = this.defaultHeaders;

    return this.httpClient.put<QcType>(`${this.basePath}/api/QcTypes/${value.id}`, value,

      {
        headers,
      }
    );
  }

  public deleteQcType(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    return this.httpClient.delete(`${this.basePath}/api/QcTypes/${id}`,
      {
        headers,
      }
    );
  }

}
