import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { faArrowAltCircleLeft, faBinoculars, faEdit, faExchangeAlt, faMinus, faPlus, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { lotInventory } from '../../../../laboratory/services/lab-quantity-service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { ReasonCode } from '../../../models/reason-code';

@Component({
  selector: 'lot-details-by-room-dialog',
  templateUrl: './lot-details-by-room-dialog.component.html',
  styleUrls: ['./lot-details-by-room-dialog.component.scss']
})
export class LotDetailsByRoomDialogComponent implements OnInit {
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faBinoculars = faBinoculars;
  public faEdit = faEdit;
  public faPlus = faPlus;
  public faMinus = faMinus;
  public isLoading = false;
  public faExchangeAlt = faExchangeAlt;

  public quantityEdit;

  public lotInventoryItem: lotInventory;
  reasonCodes: ReasonCode[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: lotInventory,
    private dialog: MatDialog,
    private inventoryService: InventoryService,
    public dialogRef: MatDialogRef<LotDetailsByRoomDialogComponent>,
  ) {
    this.lotInventoryItem = data;
   }


  ngOnInit() {
    this.inventoryService.getReasonCodes().subscribe(x => {
      this.reasonCodes = x;
    });

  }

  add() {
    this.lotInventoryItem.quantity += this.quantityEdit;
  }

  remove() {
    this.lotInventoryItem.quantity += -this.quantityEdit;
  }

  openTransfer(){
    this.dialogRef.close("transfer");
  }

  submit() {
    this.dialogRef.close(this.lotInventoryItem);
  }
  
  cancel() {
    this.dialogRef.close();
  }

  openAdd(){
    this.dialogRef.close("add");
  }
  openRemove(){
    this.dialogRef.close("remove");
  }

  openAddToOrder(){
    this.dialogRef.close("addToOrder");
  }

}
