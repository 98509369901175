import { Component, OnInit } from '@angular/core';
import { TenantDTO } from '../../harvest-engine/core/models/tenant';
import { UsersService } from '../../@core/services/users.service';
import { MatDialog } from '@angular/material';
import { NbToastrService } from '@nebular/theme';
import { ErrorService } from '../../@core/utils/error.service';
import { Router } from '@angular/router';
import { AuthService } from '../../@core/auth/auth.service';

@Component({
  selector: 'change-tenant',
  templateUrl: './change-tenant.component.html',
  styleUrls: ['./change-tenant.component.scss']
})
export class ChangeTenantComponent implements OnInit {

  tenantList: TenantDTO[];
  selectedTenant: string;
  user: any;
  submitting: boolean;
  tenantGet: boolean;
  
  constructor(
    private userService: UsersService,
    private dialog: MatDialog,
    private toastr: NbToastrService,
    private errorService: ErrorService,
    public router: Router,
    private authService: AuthService
  ) { 
    this.getUserTenant();
  }

  ngOnInit() {
    this.user = this.authService.userProfile();
  }

  getUserTenant() {
    this.tenantGet = true;
    this.userService.getUserTenant().subscribe(
      (tenant) => {
        this.tenantGet = false;
        this.tenantList = tenant;
      },
      (error) => {
        this.tenantGet = false;
        this.tenantList = [];
      }
    );
  }

  changeTenant(){
    if(!this.selectedTenant) return;
    this.submitting = true;
    this.userService.updateTenant(this.selectedTenant).subscribe(
      (tenant) => {
        //Adding delay of 15 sec to to get latest tenant from backend
        setTimeout(() => {
          this.authService.SetKragworksUser(() => { 
          location.reload();
          });
        }, 15000);
        
      },
      (error) => {
        this.errorService.handleError(error);
        this.submitting = false;
      }
    );
  }
  
  onCancelClick(): void {
    this.dialog.closeAll();
  }
}
