import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HandlingUnitBulkMove } from '../models/handling-unit-bulk-actions';


@Injectable({
  providedIn: 'root'
})
export class InventorySessionService {

  constructor(
  ) { 

  }

  public handlingUnitBulkMoveList: HandlingUnitBulkMove[];



}
