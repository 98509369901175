import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Transaction } from '../../../../core/models/transaction';
import { DataManager, ODataAdaptor, Query } from '@syncfusion/ej2-data';
import { TransactionTableDataSource } from './transaction-table-datasource';
import { FilterSettings } from '../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { Observable } from 'rxjs';
import { FilterResults } from '../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { TransactionService } from '../../../../core/services/TransactionService';
import * as moment from 'moment';
import { KwFilterDropdownService } from '../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.css']
})
export class TransactionTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: TransactionTableDataSource;

  public isLoading = true;
  private initialLoad = true;
  public faEdit = faEdit;
  @ViewChild('grid') grid: GridComponent;
  public transactions: Transaction[] = [];
  public allTransactions: Transaction[] = [];
  public data: DataManager;
  public filter: Object;
  public pageSettings: PageSettingsModel = {
    pageSize: 50
  }
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"

  }

  toolbarClick(args: any) {

    if (args.item.properties.text === 'Excel Export') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
      this.grid.excelExport();
    }

    if (args.item.properties.text === 'PDF Export') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
      this.grid.pdfExport();
    }
  }


  public columns: any[] = [];

  public toolbarOptions: string[] = ['ExcelExport'];

  public dropdownSettings: FilterSettings = {
    dateRangeSettings: {
      display: true,
      singleSelection: true,
      startDate: moment().startOf('day').subtract(7,'d').toDate(),
      endDate: moment().endOf('day').toDate()
    }
  };

  constructor(
    public dialog: MatDialog,
    private errorService: ErrorService,
    private transactionService: TransactionService,
    private bottomSheet: MatBottomSheet,
    public filterService: KwFilterDropdownService,
  ) {

  }

  ngOnInit() {
    this.dataSource = new TransactionTableDataSource(this.paginator, this.sort);
    //this.filterSettings = { type: "Menu" };
    this.filter = { type: "CheckBox" }
    // this.updateTable();
  }

  updateTable() {
    this.isLoading = true;
    Observable.forkJoin([
      this.transactionService.getTransactions(this.dropdownSettings.dateRangeSettings.startDate, this.dropdownSettings.dateRangeSettings.endDate),
    ]).subscribe(x => {
      this.transactions = x[0];
      this.allTransactions = x[0];

      for(let x  of this.transactions){
        x['lots'] = x.tranDetailLot.map(y => y.lot).join(', ');
        x['products'] = x.tranDetailLot.map(y => y.item).join(', ');
      }
      this.isLoading = false;
      
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    }
    );
  }

  setFilterResults(results: FilterResults) {
    if (this.initialLoad) {
      this.initialLoad = false;
      results = this.filterService.getFilterState();
    }
    this.dropdownSettings.dateRangeSettings.startDate = results.selectedDateRange[0];
    this.dropdownSettings.dateRangeSettings.endDate = results.selectedDateRange[1];
    this.updateTable();

    // let filteredResults = [...this.allAssemblies.filter(x =>
    //   (results.selectedFarms.map(y => y.id).includes(x.) || results.selectedFarms.length === 0) &&
    //   (results.selectedBlocks.map(y => y.id).includes(x.blockId) || results.selectedBlocks.length === 0) &&
    //   (results.selectedVarieties.map(y => y.id).includes(x.varietyId) || results.selectedVarieties.length === 0) &&
    //   (results.selectedQcAreas.map(y => y.id).includes(x.qcAreaId) || results.selectedQcAreas.length === 0) &&
    //   (results.selectedFieldmen.map(y => y.itemName).includes(x.fieldman) || results.selectedFieldmen.length === 0) &&
    //   (results.selectedCropYears.map(y => y.id).includes(x.cropYearId) || results.selectedCropYears.length === 0)
    // )];

    // this.assemblies = [...filteredResults];
  }

  public rowClicked(args): void {
    // let data = {
    //   transaction: args.data
    // };

    // this.bottomSheet.open(InventoryTransactionEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

    // let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
    //   this.grid.clearSelection();
    //   this.updateTable();
    //   bottomSheetSubscription.unsubscribe();
    // });
  }

  public getItem(data: Transaction) {
    let d = data.tranDetailLot.find(x => x.id == data.handlingUnitDetailId);
    if(d)
      return d.item;
    else
      return "";
  }

  public getLot(data: Transaction) {
    let d = data.tranDetailLot.find(x => x.id == data.handlingUnitDetailId);
    if(d)
      return d.lot;
    else
      return "";
  }

  public getDateValue(field, data: Transaction, column) {
    let date = moment(data.createdDate);
    if (date) {
        return date.format('LL');
    }
    else
      return '';
  }
  actionBegin(args) {
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = 500000; // change the filterchoicecount 
    }
  }


}
