import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { faArrowAltCircleLeft, faBinoculars, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faTruck } from '@fortawesome/pro-solid-svg-icons';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { FileUploadService } from '../../../../../core/services/FileUpload.service';
import { AuthService } from '../../../../../../@core/auth/auth.service';

@Component({
  selector: 'lots-options-diolog',
  templateUrl: './lots-options-diolog.component.html',
  styleUrls: ['./lots-options-diolog.component.scss']
})
export class LotsOptionsDiologComponent implements OnInit {
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faBinoculars = faBinoculars;
  public faEdit = faEdit;
  public faTruck = faTruck;

  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private uploadService: FileUploadService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<LotsOptionsDiologComponent>,
    public authService: AuthService
  ) { }


  ngOnInit() {
  }

  edit(){
    this.dialogRef.close("edit");
  }

  dashboard(){
    this.dialogRef.close("dash");
  }

  moveInv(){
    this.dialogRef.close("move");
  }
  adjustInv(){
    this.dialogRef.close("adjust");
  }

}
