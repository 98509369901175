import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { InventoryItemsComponent } from "./pages/inventory-items/inventory-items/inventory-items.component";
import { InventorySummaryComponent } from "./pages/inventory-summary/inventory-summary.component";
import { InventoryItemEditComponent } from "./pages/inventory-items/inventory-item-edit/inventory-item-edit.component";
import { InventoryLotEditComponent } from "./pages/inventory-lots/inventory-lot-edit/inventory-lot-edit.component";
import { InventoryItemReportComponent } from "./pages/inventory-item-report/inventory-item-report.component";
import { CycleCountComponent } from "./pages/cycle-count/cycle-count.component";
import { InventoryItemDetailComponent } from "./pages/inventory-items/inventory-item-detail/inventory-item-detail.component";
import { InventorySummaryPopupComponent } from "./pages/inventory-summary/inventory-summary-popup/inventory-summary-popup.component";
import { ItemAssembliesComponent } from "./pages/item-assemblies/item-assemblies.component";
import { ItemAssemblyEditComponent } from "./pages/item-assembly-edit/item-assembly-edit.component";
import { TransactionTableComponent } from "./pages/transaction-table/transaction-table.component";
import { InventoryTransferComponent } from "./pages/inventory-transfer/inventory-transfer.component";
import { InventoryBulkMoveComponent } from "./pages/inventory-summary/inventory-bulk-move/inventory-bulk-move.component";
import { LotsListComponent } from "./pages/inventory-lots/lots-list/lots-list.component";
import { LotDashboardComponent } from "./pages/lot-dashboard/lot-dashboard.component";
import { InventoryBatchListComponent } from "./pages/inventory-batch-list/inventory-batch-list.component";
import { ReasonCodesComponent } from "./pages/reason-codes/reason-codes.component";
import { PackoutReportComponent } from "./pages/packout-report/packout-report.component";




const routes: Routes = [
    {
        path: 'inventory-items',
        component: InventoryItemsComponent
    },
    {
        path: 'inventory-summary',
        component: InventorySummaryComponent
    },
    {
        path: 'inventory-item-report',
        component: InventoryItemReportComponent
    },
    {
        path: 'inventory-item-detail',
        component: InventoryItemDetailComponent
    },
    {
        path: 'inventory-summary-popup',
        component: InventorySummaryPopupComponent
    },
    {
        path: 'inventory-item-edit',
        component: InventoryItemEditComponent
    },

    {
        path: 'inventory-batch-list',
        component: InventoryBatchListComponent
    },


    {
        path: 'transaction-table',
        component: TransactionTableComponent
    },
    {
        path: 'item-assemblies',
        component: ItemAssembliesComponent
    },
    {
        path: 'item-assembly-edit',
        component: ItemAssemblyEditComponent
    },
    {
        path: 'inventory-transfer',
        component: InventoryTransferComponent
    },
    {
        path: 'lots-list',
        component: LotsListComponent
    },
    {
        path: 'inventory-lot-edit',
        component: InventoryLotEditComponent
    },
    {
        path: 'cycle-count',
        component: CycleCountComponent
    },
    {
        path: 'inventory-bulk-move',
        component: InventoryBulkMoveComponent
    },
    {
        path: 'lot-dashboard/:id',
        component: LotDashboardComponent
      },
      {
        path: 'reason-codes',
        component: ReasonCodesComponent
    },
    {
        path: 'packout-report',
        component: PackoutReportComponent
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InventoryRoutingModule {
}