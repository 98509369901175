import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FeatureCollection, Feature } from 'geojson';
import * as _ from 'underscore';
import * as mapboxgl from 'mapbox-gl';
import * as moment from 'moment-timezone';
import { FilterResults } from '../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { FarmFeature, FarmFeatureType } from '../../farm-features/models/farm-feature-dto';

@Injectable({
  providedIn: 'root'
})
export class FarmFeatureMarkerService {
  private farmFeatureMarkers = [];
  private farmFeatureOnScreen = [];

  public farmFeatureClick: boolean;
  private allFarmFeatures: FarmFeature[] = [];


  @Output() pointClicked: EventEmitter<FarmFeature> = new EventEmitter<FarmFeature>();

  private map: any;
  private farmFeatureClusterMarkers: mapboxgl.Marker[] = [];
  private farmFeatureClusterMarkersOnScreen: mapboxgl.Marker[] = [];
  constructor(
    private popup: MatDialog,
  ) { }

  public getAllMarkersOnScreen() {
    return [...this.farmFeatureOnScreen, ...this.farmFeatureClusterMarkersOnScreen];
  }

  public async initializeMarkers(map, farmFeatures: FarmFeature[], filterResults: FilterResults) {
    this.map = map;
    this.allFarmFeatures = farmFeatures;

    this.map.on('mouseenter', 'farmFeaturePointLayer', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('click', 'farmFeaturePointLayer', (e) => {

      this.farmFeatureClick = true;


      let farmFeature = this.allFarmFeatures.find(x => x.farmFeatureId == e.features[0].properties['farmFeatureId']);
      this.pointClicked.emit(farmFeature);
    });

    for (let clusterMarker of this.farmFeatureClusterMarkersOnScreen) {
      if (clusterMarker)
        clusterMarker.remove();
    }

    for (let marker of this.farmFeatureOnScreen) {
      if (marker)
        marker.remove();
    }


    this.farmFeatureClusterMarkers = [];
    this.farmFeatureClusterMarkersOnScreen = [];
    this.farmFeatureMarkers = [];
    this.farmFeatureOnScreen = [];


    if (this.map.getLayer('farmFeaturePointLayer'))
      this.map.removeLayer('farmFeaturePointLayer');

    if (this.map.getSource("farmFeatureSource"))
      this.map.removeSource("farmFeatureSource");

    let eventFeatureCollection = <FeatureCollection>{
      type: 'FeatureCollection',
      features: []
    };


    let filteredFarmFeatures = farmFeatures.filter(x => x.longitude &&
      x.latitude &&
      +x.latitude != 0 &&
      +x.longitude != 0
    );


    if (filteredFarmFeatures.length > 0) {

      filteredFarmFeatures.forEach(farmFeature => {
        let feature = <Feature>{
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [+farmFeature.longitude, +farmFeature.latitude]
          },
          properties: {
            ...farmFeature,
            name: farmFeature.temperature_F == null ? farmFeature.name : farmFeature.temperature_F.toFixed(0) + '°F',
            farmFeatureTypeId: farmFeature.farmFeatureTypeId,
            icon: farmFeature.farmFeatureTypeFontAwesomeIconName,
            'marker-color': farmFeature.farmFeatureTypeIconColor,
            temperature: farmFeature.temperature_F ? farmFeature.temperature_F : -9999
          }
        };

        eventFeatureCollection.features.push(feature);
      });
    }

    let uniqueTypes = _.uniq(filteredFarmFeatures.map(x => x.farmFeatureTypeId));


    let source: mapboxgl.AnySourceData = {
      type: 'geojson',
      data: eventFeatureCollection,
      cluster: true,
      clusterRadius: 20,
      clusterProperties: uniqueTypes.reduce((current, type) => {
        current[type] = ['+', ['case', ['==', ['get', 'farmFeatureTypeId'], type], 1, 0]];
        return current;
      }, {})
    };

    this.map.addSource('farmFeatureSource', source);

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    this.map.on('render', () => {
      if (!this.map.isSourceLoaded('farmFeatureSource')) return;
      this.updateFarmFeatureMarkers();
      this.setFarmFeatureMarkers();
    });
  }


  private async setFarmFeatureMarkers() {

    if (this.map.getSource("farmFeatureSource")) {


      // circle and symbol layers for rendering individual events (unclustered points)
      if (!this.map.getLayer('farmFeaturePointLayer'))
        this.map.addLayer({
          'id': 'farmFeaturePointLayer',
          //'type': 'circle',
          'source': 'farmFeatureSource',
          'type': 'symbol',
          layout: {
            'text-field': ['get', 'name'],
            'text-offset': [0, 1],
            'text-anchor': 'top',
            'text-size': 15,
          },
          paint: {
            "text-color": "#000",
            "text-halo-color": "#fff",
            "text-halo-width": 2
          }
        });




      const newMarkers = [];
      const features = this.map.querySourceFeatures('farmFeatureSource');

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {

        const coords = feature.geometry['coordinates'];
        const props = feature.properties;

        if (!props.hasOwnProperty('cluster') || !props.cluster) {


          const id = props.farmFeatureId;

          let marker = this.farmFeatureMarkers[id];
          if (!marker) {

            let iconSource = '';

            let html = `<img style="cursor: pointer;" src="https://cdn.mapmarker.io/api/v1/font-awesome/v5/icon?icon=${props['icon']}&color=FFF&size=20&hoffset=0&voffset=-1">`;
            const el = document.createElement('div');
            el.innerHTML = html;
            el.innerHTML = html;

            marker = this.farmFeatureMarkers[id] = new mapboxgl.Marker(el, {}).setLngLat(coords);


            // marker = farmFeatureMarkers[id] = new mapboxgl.Marker({
            //   color: feature.properties['marker-color']
            // }).setLngLat(coords);
          }

          newMarkers[id] = marker;

          if (!this.farmFeatureOnScreen[id]) marker.addTo(this.map);
        }

      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in this.farmFeatureOnScreen) {
        if (!newMarkers[id]) this.farmFeatureOnScreen[id].remove();
      }


      this.farmFeatureOnScreen = newMarkers;


    }
  }

  private updateFarmFeatureMarkers() {
    const newMarkers = [];
    const features = this.map.querySourceFeatures('farmFeatureSource');

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {

      const coords = feature.geometry['coordinates'];
      const props = feature.properties;

      if (props.cluster) {

        const id = props.cluster_id;

        let marker = this.farmFeatureClusterMarkers[id];
        if (!marker) {
          const el = this.createFarmFeaturesDonutChart(props);

          marker = this.farmFeatureClusterMarkers[id] = new mapboxgl.Marker(el, {}).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!this.farmFeatureClusterMarkersOnScreen[id]) marker.addTo(this.map);
      }

    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.farmFeatureClusterMarkersOnScreen) {
      if (!newMarkers[id]) this.farmFeatureClusterMarkersOnScreen[id].remove();
    }


    this.farmFeatureClusterMarkersOnScreen = newMarkers;
  }

  private createFarmFeaturesDonutChart(props): HTMLElement {
    const offsets = [];
    let filteredEventTypes: FarmFeatureType[] = [];

    const counts = [];


    let farmFeatureTypes: FarmFeatureType[] = _.uniq(this.allFarmFeatures.map(x => <any>{
      farmFeatureTypeId: x.farmFeatureTypeId,
      iconColor: x.farmFeatureTypeIconColor

    }), true, x => x.farmFeatureTypeId);

    for (let key in props) {
      let includedFarmFeatureType = farmFeatureTypes.find(x => x.farmFeatureTypeId.toString() == key)
      if (includedFarmFeatureType) {
        filteredEventTypes.push(includedFarmFeatureType)
        counts.push(props[key]);
      }
    }

    let total = 0;

    for (const count of counts) {
      offsets.push(total);
      total += count;
    }
    const fontSize =
      total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
    const r =
      total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div>
  <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

    for (let i = 0; i < counts.length; i++) {
      html += this.donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        filteredEventTypes[i].iconColor
      );
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
  <text dominant-baseline="central" transform="translate(${r}, ${r})">
  ${total.toLocaleString()}
  </text>
  </svg>
  </div>`;

    const el = document.createElement('div');

    el.innerHTML = html;

    return el;
  }

  private donutSegment(start, end, r, r0, color) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
      } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
      } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
      }" fill="${color}" />`;
  }
}
