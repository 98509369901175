import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { CropYear } from '../models/cropYear';

@Injectable({
  providedIn: 'root'
})
export class CropYearService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }


  public getCropYears(includeInactive: boolean = false): Observable<CropYear[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters.append('includeInactive', includeInactive.toString());
    
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropYear[]>(`${this.basePath}/api/cropyears`,
      {
        headers,
      }
    );
  }

  public putCropYear(cropYear: CropYear): Observable<CropYear[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<CropYear[]>(`${this.basePath}/api/cropYears/${cropYear.id}`, cropYear,

      {
        headers,
      }
    );
  }

  public getCropYear(id: number): Observable<CropYear> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropYear>(`${this.basePath}/api/CropYears/${id}`,
      {
        headers,
      }
    );
  }

  public getCurrentCropYear(cropId: number = 0): Observable<CropYear> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CropYear>(`${this.basePath}/api/CropYears/current/${cropId}`,
      {
        headers,
      }
    );
  }

  public deleteCropYear(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/CropYears/${id}`,
      {
        headers,
      }
    );
  }

  public postCropYear(cropYear: CropYear): Observable<CropYear[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<CropYear[]>(`${this.basePath}/api/cropYears`, cropYear,

      {
        headers,
      }
    );
  }
}
