import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MatDialog, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { TenantWordsPipe } from '../../../../../core/pipes/tenant-words.pipe';
import { TransactionService } from '../../../../../core/services/TransactionService';
import { ReasonCode } from '../../../models/reason-code';

@Component({
  selector: 'reason-code-edit',
  templateUrl: './reason-code-edit.component.html',
  styleUrls: ['./reason-code-edit.component.scss']
})
export class ReasonCodeEditComponent implements OnInit {

  public reasonCode: ReasonCode;
  isSubmitting = false;
  public faTrash = faTrash;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: ReasonCodeEditComponent,
    private _bottomSheetRef: MatBottomSheetRef<ReasonCodeEditComponent>,
    public transactionService: TransactionService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private tenantWords: TenantWordsPipe
  ) {
    if (this.data.reasonCode)
      this.reasonCode = this.data.reasonCode;
  }

  ngOnInit() {
  }

  onSubmit(){
    this.isSubmitting = true;

    if (this.reasonCode.id && this.reasonCode.id != 0) {
      this.transactionService.updateCode(this.reasonCode).subscribe(x => {
        this.isSubmitting = false;
        this._bottomSheetRef.dismiss();
      }, err => { this.isSubmitting = false; this.errorService.handleError(err); });
    } else {
      this.transactionService.createCode(this.reasonCode).subscribe(x => {
        this.isSubmitting = false;
        this._bottomSheetRef.dismiss();
      }, err => { this.isSubmitting = false; this.errorService.handleError(err); });
    }
  }

  onDelete() {

    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: `Delete ${this.tenantWords.transform('Work Order')} Type?`,
        confirmMessage: 'Are you sure you want to delete ' + this.reasonCode.reason + '?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {

      if (result === true) {
        this.isSubmitting = true;
        this.transactionService.deleteCode(this.reasonCode.id).subscribe(x => {
          this.isSubmitting = false;
          this._bottomSheetRef.dismiss();
        }, err => { this.isSubmitting = false; this.errorService.handleError(err); });
      }
    });

  }

}
