import { EventEmitter, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public isPrinting = false;

  public isPrinting_v2 = false;
  
  public previousUrl: string[];
  public documentTitle: string = "Report";
  public documentSubTitle: string = "";
  public documentType: string = "";
  public printedDate: Date = new Date();
  public showDate: boolean = true;
  
  public printed: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private location: Location,
    private r: ActivatedRoute,
  ) {
  }

  printReport_v2(landscape: boolean = false, testMode: boolean = false) {
    this.isPrinting_v2 = true;

    this.isPrinting = true;
    // Set the background to white, because Justin.
    document.body.style.background = "white";

    let routerDiv = document.getElementById("router");
    let reportContainerDiv = document.getElementById("reportRouterContainer");
    let mainContentContainerDiv = document.getElementById("mainRouterContainer");

    reportContainerDiv.innerHTML = routerDiv.innerHTML;

    setTimeout(() => {

      var head = document.head || document.getElementsByTagName('head')[0];
      var style = document.createElement('style');
  
      if (landscape) {
        style.type = 'text/css';
        style.media = 'print';
  
        style.appendChild(document.createTextNode(landscape ?
          '@page { size: A4 landscape; margin: 0in;}' :
          '@page { size: A4;  margin: 0in; }'));
  
        head.appendChild(style);
      }
  
      window.print();
      this.isPrinting = false;
  
      if (landscape) {
        head.removeChild(style);
      }
      
      document.body.style.background = "radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)";
      setTimeout(() => {
        this.printed.emit();
      }, 300);

      this.isPrinting_v2 = false;
    }, 500);
    
    


  }

  printReport(reportName: string, reportData: string[]) {
    this.isPrinting = true;

    //this.router.navigate(['reports', documentName, documentData.join()], { relativeTo: this.r.parent })
    document.body.style.background = "white";
    this.isPrinting = true;

    // Set the background to white, because Justin.
    document.body.style.background = "white";

    this.router.navigate(['/',
      {
        outlets: {
          'reports': ['reports', reportName, reportData.join()]
        }
      }]);
  }

  printAssetReport(reportName: string, FarmFeatureId: string, blockId: string) {
    this.isPrinting = true;

    //this.router.navigate(['reports', documentName, documentData.join()], { relativeTo: this.r.parent })

    this.isPrinting = true;

    // Set the background to white, because Justin.
    document.body.style.background = "white";

    this.router.navigate(['/',
      {
        outlets: {
          'reports': ['reports', reportName, FarmFeatureId, blockId]
        }
      }]);
  }

  onDataReady(landscape: boolean = false) {
    setTimeout(() => {
      var head = document.head || document.getElementsByTagName('head')[0];
      var style = document.createElement('style');

      if (landscape) {
        style.type = 'text/css';
        style.media = 'print';

        style.appendChild(document.createTextNode(landscape ?
          '@page { size: A4 landscape; margin: 0in;}' :
          '@page { size: A4;  margin: 0in; }'));

        head.appendChild(style);
      }

      window.print();
      this.isPrinting = false;

      if (landscape) {
        head.removeChild(style);
      }
      
      document.body.style.background = "radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)";
      this.router.navigate([{ outlets: { reports: null } }]);
      this.printed.emit();

    },1000); // Added delay to ensure data is rendered
  }
}