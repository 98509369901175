import { Injectable } from '@angular/core';
import { Fp_Worksite } from '../models/fp-worksite';
import { Fp_Crew } from '../models/fp-crew';
import * as moment from 'moment';
import { FpDryingRoomLog } from '../models/fp-drying-room-log';

@Injectable({
  providedIn: 'root'
})
export class FirmanSessionService {

  // used in the production schedule edit component
  public selectedWorksite: Fp_Worksite;
  public selectedDate: any = moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toISOString().split('T')[0];

  // used in the production schedule edit component
  public selectedCrew: Fp_Crew;


  // worksite list for the selected day
  // used in the production schedule edit component
  public dailyWorksiteList: Fp_Worksite[] = [];

  // used in drying room log cards and dashboard
  public selectedDryingRoomLog: FpDryingRoomLog

  constructor() { }
}
