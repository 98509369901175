import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { SessionService } from '../../../../../@core/utils/session.service';
import { PrecisionJob } from '../../../models/precision-job';
import { PrecisionPurchaseOrder } from '../../../models/precision-purchase-order';
import { PrecisionSessionService } from '../../../services/precision-session.service';

@Component({
  selector: 'purchase-order-dialog',
  templateUrl: './purchase-order-dialog.component.html',
  styleUrls: ['./purchase-order-dialog.component.scss']
})
export class PurchaseOrderDialogComponent implements OnInit {

  public purchaseOrders: PrecisionPurchaseOrder[] = [];
  public total: number = 0;

  constructor(
    public session: PrecisionSessionService,
    private dialog: MatDialog,
    private router: Router,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.purchaseOrders = this.session.job.purchaseOrders;

    this.total = this.session.job.purchaseOrders.flatMap(x => x.lineItems).reduce((total, po) => {
      return total + (po.rate * po.quantityOrdered);
    }, 0 );
    
  }

  toQuickPO(po: PrecisionPurchaseOrder) {
    this.session.recordId = this.session.job.recordId;
    this.session.precisionPurchaseOrder = po;
    this.dialog.closeAll();
    this.router.navigateByUrl(`/precision/quick-po?recordId=${po.recordId}`);
  }
  
}
