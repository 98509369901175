import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'm-vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.scss']
})
export class VerticalTimelineComponent implements OnInit {
  @Input() timelineGroups: TimelineGroup[];

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
    for (const group of this.timelineGroups) {
      for (const item of group.timelineItems) {

        // if posts is undefined, set it to an empty array
        if (item.timelinePosts === undefined || item.timelinePosts === null) {
          item.timelinePosts = [];
        }

        // make sure route has leading slash
        if (item.onclickRoute !== undefined && item.onclickRoute.substr(0, 1) !== '/') {
          item.onclickRoute = '/' + item.onclickRoute;
        }
      }
    }
  }

  routeClicked(item: TimelineItem) {
    if (item.onclickRoute !== undefined && item.onclickRoute !== '' ) {
      this.router.navigate([item.onclickRoute]);
    }
  }
}


// Timeline Item Structure

export interface TimelineGroup {
  timelineYear?: string;
  timelineItems: TimelineItem[];
}

export interface TimelineItem {
  timelineDates?: TimeLineDate[];
  timelinePosts?: TimeLinePost[];
  onclickRoute?: string;
  timelineItemLinkObject?: any;
}

export interface TimeLineDate {
  timelineDays?: string[];
  timelineMonths?: string[];
}

export interface TimeLinePost {
  postTitle?: string;
  contentInnerHTML: string;
}
