import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Room } from '../../../models/storage/room';
import { RoomService } from '../../../services/storage/room-service';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { ColumnModel, FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { InventoryService } from '../../../../modules/pick-plan/services/inventory.service';
import { HandlingUnitMasterDTO } from '../../../../modules/inventory/models/handling-unit-master';
import { ActivatedRoute } from '@angular/router';
import { PickPlanService } from '../../../../modules/pick-plan/services/pick-plan.service';
import { LoadService } from '../../../../modules/pick-plan/services/load.service';
import { LoadViewModel } from '../../../../modules/pick-plan/models/load-view-model';
import { PickPlanDTO } from '../../../../modules/pick-plan/models/pick-plan-dto';
import { KragworksEJSConstants } from '../../../../../@core/utils/ejs-constants';
import { FilterSettings } from '../../../widgets/kw-filter-dropdown/models/filter-options';
import { initial } from 'lodash';
import { KwFilterDropdownService } from '../../../widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { FilterResults } from '../../../widgets/kw-filter-dropdown/models/filter-results';
import * as _ from 'underscore';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import { MatBottomSheet } from '@angular/material';
import { LotDetailsByRoomComponent } from '../../../../modules/inventory/widgets/lot-details-by-room/lot-details-by-room.component';

@Component({
  selector: 'room-dashboard',
  templateUrl: './room-dashboard.component.html',
  styleUrls: ['./room-dashboard.component.scss']
})
export class RoomDashboardComponent implements OnInit, OnChanges {

  @Input() roomId: number;
  @Input() cropYearId: number;
  @Input() showFilter: boolean = true;

  public filterSettings: FilterSettings = {
    roomSettings: {
      display: false,
      singleSelection: true
    },
    cropYearSettings: {
      display: true,
      singleSelection: true
    }

  };

  public faBars = faBars;

  public room: Room;
  public isLoading: boolean = true;
  public allHums: HandlingUnitMasterDTO[] = [];
  public allLoads: LoadViewModel[] = [];
  public allPickPlans: PickPlanDTO[] = [];

  public inventorySummaries: RoomDashboardInventorySummary[] = [];

  @ViewChild('logGrid') logGrid: GridComponent;
  public toolbarOptions: string[] = ['PdfExport', 'ExcelExport'];

  @ViewChild('logDateTemplate') logDateTemplate: any;

  @ViewChild('viewLots') viewLots: any;

  public logColumns: ColumnModel[] = [
  ];

  public inventoryColumns: ColumnModel[] = [];

  public ejsConstants = KragworksEJSConstants;

  public initialLoad: boolean = true;

  constructor(
    private roomService: RoomService,
    private errorService: ErrorService,
    private inventoryService: InventoryService,
    private route: ActivatedRoute,
    private pickPlanService: PickPlanService,
    private loadService: LoadService,
    private filterService: KwFilterDropdownService,
    public matBottomSheet: MatBottomSheet
  ) { }
  @ViewChild('detailTemplate') detailTemplate: any;

  ngOnInit() {
    // get the cropYearId GET param

    // get roomId from route
    this.route.params.subscribe(params => {
      if (params['id']) this.roomId = params['id'];
    });


    this.logColumns = [

      {
        field: 'timeCreated',
        headerText: 'Time Created',
        template: this.logDateTemplate,
        autoFit: true
      },
      {
        field: 'type',
        headerText: 'Type',
        autoFit: true
      },
      {
        field: 'message',
        headerText: 'Message',
        width: 250

      }
    ]

    this.inventoryColumns = [
      {
        field: 'farmName',
        headerText: 'Farm',
        autoFit: true
      },
      {
        field: 'blockName',
        headerText: 'Block',
        autoFit: true
      },
      {
        field: 'varietyName',
        headerText: 'Variety',
        autoFit: true
      },
      {
        field: 'pickPlanQty',
        headerText: 'Planned Qty',
        autoFit: true
      },
      {
        field: 'receivedQty',
        headerText: 'Received Qty',
        autoFit: true
      },
      {
        field: 'qtyOnHand',
        headerText: 'Qty On Hand',
        autoFit: true
      },
      // {
      //   template: this.viewLots,
      //   headerText: 'View Lots',
      // }
    ]

    if (this.roomId) {
      this.loadData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();

    // // if the roomid has changed, reload the data
    // if (changes.roomId && changes.roomId.currentValue) {
    //   this.loadData();
    // }
  }

  viewLotsClicked(rowData: RoomDashboardInventorySummary) {
    console.log('view');
    this.matBottomSheet.open(LotDetailsByRoomComponent, {
      data: {
        room: this.room,
        cropYearId: this.cropYearId,
        farmId: rowData.farmId,
        blockId: rowData.blockId,
        varietyId: rowData.varietyId
      }
    });
  }


  logToolbarClick(args: any) {
    if (args.item.id === 'grid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
      this.logGrid.pdfExport();
    }

    if (args.item.id === 'grid_excelexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name 
      this.logGrid.excelExport();
    }
  }

  search(event: FilterResults) {
    if (this.initialLoad) {
      this.initialLoad = false;
      event = this.filterService.getFilterState();
    }
    this.cropYearId = event.selectedCropYears[0].id;
    //this.roomId = event.selectedRooms[0].id;
    this.loadData();

  }

  public refreshParentTable(isRefresh: boolean = false) {
    if(isRefresh)
      this.loadData();
  }

  loadData() {
    this.isLoading = true;
    console.log('loading data');
    // log cropyearid and roomid
    console.log(this.cropYearId);
    console.log(this.roomId);

    if (this.cropYearId == null) {
      return;
    }
    Observable.forkJoin([
      this.roomService.getRoom(this.roomId),
      this.inventoryService.getInventoryHUMs(false, null, null, null, this.roomId, null, false, false, true),
      this.pickPlanService.getPickPlans(false, null, null, this.cropYearId),
      this.loadService.getLoads(false, false, null, null, this.cropYearId)
    ]).subscribe(data => {
      this.room = data[0];

      this.allHums = data[1];
      this.allPickPlans = data[2];
      this.allLoads = data[3];

      // get all pick plans
      this.allPickPlans = this.allPickPlans.filter(p => p.locationId === this.roomId);
      this.inventorySummaries = [];
      let stagingInventorySummaries = [];

      let summarizedPickPlans = this.allPickPlans.map(p => {
        let dashboardInventoryObj: RoomDashboardInventorySummary = {
          blockId: p.blockId,
          blockName: p.blockName,
          farmId: p.farmId,
          farmName: p.farmName,
          lotSummaries: [],
          pickPlanQty: p.scheduledQty,
          qtyOnHand: 0,
          receivedQty: 0,
          varietyId: p.varietyId,
          varietyName: p.varietyName
        }

        return dashboardInventoryObj;
      });

      stagingInventorySummaries.push(...summarizedPickPlans);

      // get all loads
      let filteredLoads = this.allLoads.filter(l => l.binAssignments.filter(x => x.roomId == this.roomId).length > 0);

      for (let load of filteredLoads) {
        // get bin assignments that are in the room
        let binAssignments = load.binAssignments.filter(b => b.roomId == this.roomId);

        let binAssignmentSummaries = binAssignments.map(ba => {
          let dashboardInventoryObj: RoomDashboardInventorySummary = {
            blockId: ba.blockId,
            blockName: ba.blockName,
            farmId: ba.farmId,
            farmName: ba.farmName,
            lotSummaries: [],
            pickPlanQty: 0,
            qtyOnHand: 0,
            receivedQty: ba.quantity,
            varietyId: ba.varietyId,
            varietyName: ba.varietyName,

          };
          return dashboardInventoryObj;
        });

        stagingInventorySummaries.push(...binAssignmentSummaries);
      }

      // get all HUMs
      let filteredHUMs = this.allHums.filter(h => h.roomId == this.roomId);
      for (let hum of filteredHUMs) {
        for (let hud of hum.handlingUnitDetails) {

          stagingInventorySummaries.push({
            blockId: hud.blockId,
            blockName: hud.blockName,
            farmId: hud.farmId,
            farmName: hud.farmName,
            lotSummaries: [],
            pickPlanQty: 0,
            qtyOnHand: hud.quantity,
            receivedQty: 0,
            varietyId: hud.varietyId,
            varietyName: hud.varietyName
          });
        }
      }

      // group all staging inventory summaries by farm, block, variety
      let groupedInventorySummaries = _.groupBy(stagingInventorySummaries, i => i.farmId + i.blockId + i.varietyId);

      for (let key in groupedInventorySummaries) {
        let inventorySummary = groupedInventorySummaries[key];
        let summarizedInventory = {
          farmId: inventorySummary[0].farmId,
          farmName: inventorySummary[0].farmName,
          blockId: inventorySummary[0].blockId,
          blockName: inventorySummary[0].blockName,
          varietyId: inventorySummary[0].varietyId,
          varietyName: inventorySummary[0].varietyName,
          pickPlanQty: inventorySummary.reduce((acc, curr) => acc + curr.pickPlanQty, 0),
          receivedQty: inventorySummary.reduce((acc, curr) => acc + curr.receivedQty, 0),
          qtyOnHand: inventorySummary.reduce((acc, curr) => acc + curr.qtyOnHand, 0),
          lotSummaries: []
        }

        for (let inv of inventorySummary) {
          summarizedInventory.lotSummaries.push({
            lotId: inv.lotId,
            lotName: inv.lotName,
            qtyOnHand: inv.qtyOnHand,
            receiptDate: inv.receiptDate
          });
        }

        this.inventorySummaries.push(summarizedInventory);
      }




      this.isLoading = false;
    }, err => { this.errorService.handleError(err); this.isLoading = false; }
    )
  };

}


export interface RoomDashboardInventorySummary {
  farmId: number;
  farmName: string;
  blockId: number;
  blockName: string;
  varietyId: number;
  varietyName: string;
  pickPlanQty: number;
  receivedQty: number;
  qtyOnHand: number;
  lotSummaries: RoomDashboardLotSummary[];
}

export interface RoomDashboardLotSummary {
  lotId: number;
  lotName: string;
  qtyOnHand: number;
  receiptDate: Date;
}