import { FilterSettingsModel, PageSettingsModel } from "@syncfusion/ej2-angular-grids";

export interface KragworksEJSConstants {
    filterSettings: FilterSettingsModel;
    pageSettings: PageSettingsModel;
    exportToolbarOptions: string[];
    textWrapSettings: Object
}

export const KragworksEJSConstants: KragworksEJSConstants = {
    filterSettings: {
        mode: "Immediate",
        type: "Excel"
    },
    pageSettings: {
        pageSize: 100,
        pageSizes: [50, 100, 200, 250, 1000, 5000]
    },
    exportToolbarOptions: ['ExcelExport', 'PdfExport'],
    textWrapSettings: { wrapMode: 'Content' }
};