import { Fp_Worksite } from "./fp-worksite";

export interface Fp_Crew {
    id?: number;
    crewName?: string;
    crewShortCode?: string;
    isActive?: boolean;

    // FRONTEND ONLY
    worksites?: Fp_Worksite[];
}


export const DEMO_CREWS = [
    {
        id: 1,
        crewName: 'Orange Crew'
    },
    {
        id: 2,
        crewName: 'White Crew'
    },
    {
        id: 3,
        crewName: 'Gray Crew'
    },
    {
        id: 4,
        crewName: 'Red Crew'
    },
    {
        id: 5,
        crewName: 'Yellow Crew'
    }

]