import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { faArrowAltCircleLeft, faCalendarAlt, faCalendarCheck, faCalendarPlus, faChartLine, faTags, faThLarge, faTrashAlt, faTruck, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { filter, pairwise } from 'rxjs/operators';
import { InventoryItemDTO } from '../../../models/item';
import * as moment from 'moment';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { InventoryItemEditComponent } from '../inventory-item-edit/inventory-item-edit.component';

@Component({
  selector: 'inventory-item-detail',
  templateUrl: './inventory-item-detail.component.html',
  styleUrls: ['./inventory-item-detail.component.scss']
})
export class InventoryItemDetailComponent implements OnInit {

  public faChartLine = faChartLine;
  public faThLarge = faThLarge;
  public faTags = faTags;
  public faTruckMoving = faTruckMoving;
  public faCalendarCheck = faCalendarCheck;
  public faCalendarAlt = faCalendarAlt;
  public faTruck = faTruck;
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faCalendarPlus = faCalendarPlus;
  public faTrashAlt = faTrashAlt;
  public isLoading: boolean = false;
  public itemId: number;
  public item: InventoryItemDTO;
  private previousUrl: string;

  constructor(
    private inventoryService: InventoryService,
    private errorService: ErrorService,
    private bottomSheet: MatBottomSheet,
    private changeDetRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      if (params.item)
        this.itemId = params.item;
      else
        this.router.navigateByUrl("/harvest-engine/inventory/inventory-items");
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        pairwise() // check it's second route load
      )
      .subscribe((e: any[]) => {
        this.previousUrl = e[0].url;
      });

    if (this.itemId)
      this.inventoryService.getInventoryItem(this.itemId).subscribe(x => {
        this.item = x;
        this.changeDetRef.markForCheck();
        this.isLoading = false;
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
  }

  convertDate(date: Date) {
    return moment(date).format('LL');
  }

  back() {
    if (this.previousUrl) {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.router.navigateByUrl('/harvest-engine/inventory/inventory-items');
    }
  }

  editItem() {
    let data = {
      item: this.item,
      isPopup: true
    }

    this.bottomSheet.open(InventoryItemEditComponent, { data });
    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      this.isLoading = true;
      this.inventoryService.getInventoryItem(this.itemId).subscribe(x => {
        this.item = x;

        this.changeDetRef.markForCheck();
        this.isLoading = false;
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
      if (job) {
        bottomSheetSubscription.unsubscribe();
      }
    });
  }

}
