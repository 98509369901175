import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator, MatSort } from '@angular/material';
import { faCheck, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Room } from '../../../models/storage/room';
import { Warehouse } from '../../../models/storage/warehouse';
import { RoomService } from '../../../services/storage/room-service';
import { WarehouseService } from '../../../services/storage/warehouse-service';
import { WarehouseListTableDataSource } from './warehouse-list-table-datasource';
import { PlatformConfigService } from '../../../services/platform-config.service';


@Component({
  selector: 'warehouse-list-table',
  templateUrl: './warehouse-list-table.component.html',
  styleUrls: ['./warehouse-list-table.component.css']
})
export class WarehouseListTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: WarehouseListTableDataSource;
  public faPlusCircle = faPlusCircle;

  public editActive = false;
  public warehouseId: number;
  public warehouseLoading = true;
  public submitting: any;
  public editWarehouse: Warehouse = {};
  public faTimes = faTimes;
  public faCheck = faCheck;


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'name', 'description', 'integrationId', 'famousAccessGroup', 'barcode', 'isActive', 'edit'];
  isREPToken: boolean = false;

  constructor(
    private errorService: ErrorService,
    public warehouseService: WarehouseService,
    private platformConfigService: PlatformConfigService,
    private changeDetRef: ChangeDetectorRef
  ) { 
    this.getAllConfigValues();
  }

  ngOnInit() {
    this.warehouseLoading = true;
    this.dataSource = new WarehouseListTableDataSource(this.paginator, this.sort);
    this.warehouseService.getWarehouses().subscribe(data => {
      this.dataSource.data = data;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.warehouseLoading = false;
    }, err => {this.errorService.handleError(err); this.warehouseLoading = false;});
    
  }

  editClicked(id: number) {
    this.editWarehouse = { ...this.dataSource.data.filter(u => u.id === id)[0] };
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 16);
  this.editActive = true;
  }

  addWarehouseClicked() {
    this.editWarehouse = {};
    this.editWarehouse.isActive = true;
    this.editActive = true;
  }

  deleteWarehouseClicked(form: NgForm) {
    this.warehouseLoading = true;
    let isERPSave = true;
    if (this.editWarehouse.id) {
      this.warehouseService.deleteWarehouse(this.editWarehouse.id,isERPSave).subscribe(x => {
        this.dataSource.data = this.dataSource.data.filter(x => x.id != this.editWarehouse.id);
        //this.dataSource.data.splice(this.dataSource.data.indexOf(this.editWarehouse), 1);

        this.paginator._changePageSize(this.paginator.pageSize)
        this.editWarehouse = {};
        this.warehouseLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.warehouseLoading = false; form.resetForm(); });
    }
    else {
      alert("No Warehouse Selected!!");
      this.warehouseLoading = false; 
      form.resetForm();
    }
    this.editActive = false;
  }

  saveWarehouseClicked(form: NgForm) {
    //do not call api if form not valid
    if(form.invalid) return;
    //Set erp flag true default;
    let isERPSave = true;

    this.warehouseLoading = true;
    if (this.editWarehouse.isActive === null || this.editWarehouse.isActive === undefined) { this.editWarehouse.isActive = false; };
    if (this.editWarehouse.id !== null && this.editWarehouse.id !== undefined) {
      this.editWarehouse.updatedDate = new Date;
      let index = this.dataSource.data.indexOf(this.dataSource.data.filter(w => w.id === this.editWarehouse.id)[0]);
      this.warehouseService.putWarehouse(this.editWarehouse,isERPSave).subscribe(x => {
        this.dataSource.data[index] = this.editWarehouse;
        this.dataSource.data = [ ...this.dataSource.data];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.editWarehouse = {};
        this.warehouseLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.warehouseLoading = false; form.resetForm(); });
    }
    else {
      this.editWarehouse.id = 0;
      this.editWarehouse.createdDate = new Date();
      this.editWarehouse.updatedDate = this.editWarehouse.createdDate;
      this.warehouseService.postWarehouse(this.editWarehouse,isERPSave).subscribe(x => {
        this.editWarehouse.id = x.id;
        this.dataSource.data.push(this.editWarehouse);
        this.dataSource.data = [ ...this.dataSource.data];
        this.paginator._changePageSize(this.paginator.pageSize);
        this.editWarehouse = {};
        this.warehouseLoading = false;
        form.resetForm();
      }, err => { this.errorService.handleError(err); this.warehouseLoading = false; form.resetForm(); });
    }
    this.editActive = false;
  }

  cancelClicked(form: NgForm) {
    this.editActive = false;
    this.editWarehouse = {};
    form.resetForm();
  }

    //get ERPNext token from config
    getAllConfigValues() {
     this.platformConfigService.getAllConfigValues().subscribe(
       (data) => {
         if(!!data && data.length > 0){
           //set true if erp token present
           this.isREPToken = !!data.find(x=>x.name.includes("ERPNext:AccessToken")).value;
         }
       },
       (err) => {
         this.errorService.handleError(err);
       }
     );
     this.changeDetRef.markForCheck();
   }

  /* warehouseChanged(warehouse: Warehouse){
    this.editWarehouse = warehouse;
    this.editWarehouse.warehouseId = this.editWarehouse.id;
  } */
}
