import { Component, OnDestroy, Inject } from '@angular/core';
import { MatBottomSheet, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { faCalendarAlt, faCalendarCheck, faCalendarPlus, faChartLine, faTags, faThLarge, faTrashAlt, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { faFlask } from '@fortawesome/pro-duotone-svg-icons';
import { faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { RoomEditPopupComponent } from '../../../../../core/components/rooms-list/room-edit-popup/room-edit-popup.component';
import { QcSessionService } from '../../../../quality-control/services/qc-session.service';
import { PickPlanSessionService } from '../../../services/pick-plan-session.service';
import { PickPlanService } from '../../../services/pick-plan.service';
import { LotSelectorComponent, LotSelectorData } from '../../../widgets/lot-selector/lot-selector.component';
import { PpActionsDialogComponent } from '../../harvest-manager/pp-actions-dialog/pp-actions-dialog.component';
import { PickPlanEditBottomSheetData, PickPlanEditComponent } from '../../pick-plan-edit/pick-plan-edit.component';

@Component({
  selector: 'storage-by-actions-dialog',
  templateUrl: './storage-by-actions-dialog.component.html',
  styleUrls: ['./storage-by-actions-dialog.component.scss']
})
export class StorageByActionsDialogComponent {

  // Icons //
  public faChartLine = faChartLine;
  public faThLarge = faThLarge;
  public faTags = faTags;
  public faTruckMoving = faTruckMoving;
  public faCalendarCheck = faCalendarCheck;
  public faFlask = faFlask;
  public faCalendarAlt = faCalendarAlt;
  public faCalendarPlus = faCalendarPlus;
  public faTrashAlt = faTrashAlt;
  faWarehouseAlt = faWarehouseAlt;

  public pickPlanBottomSheetSubsciption: Subscription;
  private bottomSheetSubscription: Subscription;

  constructor(
    private router: Router,
    private pickPlanSessionService: PickPlanSessionService,
    private pickPlanService: PickPlanService,
    public dialogRef: MatDialogRef<PpActionsDialogComponent>,
    public matDialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    public authService: AuthService,
    private qcSessionService: QcSessionService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}


  onNoClick(): void {
    this.dialogRef.close();
  }

  goToQC() {
    this.qcSessionService.selectedRoomId = this.data.room.roomId;
    this.qcSessionService.selectedCropYearId = this.data.cropYearId;

    this.router.navigateByUrl('harvest-engine/quality-control/room-summary');
    
    this.dialogRef.close("QC");

  }

  openStoragePlanPopup() {
    
    if(this.data.pool){
      this.router.navigateByUrl("/harvest-engine/pick-plan/pick-plans?pool=" + this.data.pool);
    }
    if(this.data.room){
      this.router.navigateByUrl("/harvest-engine/pick-plan/pick-plans?room=" + this.data.room);
    }
    if(this.data.type){
      this.router.navigateByUrl("/harvest-engine/pick-plan/pick-plans?type=" + this.data.type);
    }

    this.dialogRef.close("StoragePlan");
  }

  createStoragePlanForRoomPopup(){
    this.dialogRef.close("CreateStorage");
    this.pickPlanSessionService.lotSelectorData = this.data;
    this.router.navigateByUrl(`/harvest-engine/pick-plan/lot-selector`);
  }

  editRoom() {
    this.editRoom = this.data.room;
    this.bottomSheet.open(RoomEditPopupComponent, {
      panelClass: 'bottomsheet-60-width', data: {
        room: {...this.editRoom}
      }
    });

    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(() => {
      this.matDialog.closeAll();
      this.bottomSheetSubscription.unsubscribe();
    });
  }
}
