import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "../@core/auth/auth.guard";
import { WordListComponent } from "./core/components/word-list/word-list.component";
import { RestartServiceComponent } from "./core/components/restart-service/restart-service.component";
import { RoomsListComponent } from "./core/components/rooms-list/rooms-list.component";
import { WarehouseListComponent } from "./core/components/warehouse-list/warehouse-list.component";
import { RoleGuard } from "../@core/auth/auth.roleguard.service";
import { VarietiesListComponent } from "./core/components/varieties/varieties-list/varieties-list.component";
import { VarietiesDetailComponent } from "./core/components/varieties/varieties-detail/varieties-detail.component";
import { CarrierListComponent } from "./core/components/carrier-list/carrier-list.component";
import { TruckTypeListComponent } from "./core/components/truck-type-list/truck-type-list.component";
import { FarmDetailComponent } from "./core/components/farms/farm-detail/farm-detail.component";
import { FarmsListComponent } from "./core/components/farms/farms-list/farms-list.component";
import { PoolListComponent } from "./core/components/pool-list/pool-list.component";
import { StorageListComponent } from "./core/components/storage-list/storage-list.component";
import { FieldmanListComponent } from "./core/components/fieldman-list/fieldman-list.component";
import { EstimatesLockComponent } from "./core/components/estimates-lock/estimates-lock.component";
import { PackageTypesComponent } from "./core/components/package-types/package-types.component";
import { RoomLocationsComponent } from "./core/components/room-locations/room-locations.component";
import { LocationBarcodeListComponent } from "./core/components/location-barcode-list/location-barcode-list.component";
import { ContractInputComponent } from "./modules/contracts/contract-input/contract-input.component";
import { ContractListComponent } from "./modules/contracts/contract-list/contract-list.component";
import { ContractAmmendComponent } from "./modules/contracts/contract-ammend/contract-ammend.component";
import { EmployeesComponent } from "./core/components/employees/employees.component";
import { EmployeeEditComponent } from "./core/components/employees/employee-edit/employee-edit.component";
import { CropListComponent } from "./core/components/crop-list/crop-list.component";
import { RoomDashboardComponent } from "./core/components/rooms-list/room-dashboard/room-dashboard.component";

const routes: Routes = [
    {
        path: 'estimates',
        loadChildren: 'app/harvest-engine/modules/estimates/estimates.module#EstimatesModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'work-orders',
        loadChildren: 'app/harvest-engine/modules/work-orders/work-orders.module#WorkOrdersModule',
        canActivate: [AuthGuard]
    },

    {
        path: 'web-store',
        loadChildren: 'app/harvest-engine/modules/web-store/web-store.module#WebStoreModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'laboratory',
        loadChildren: 'app/harvest-engine/modules/laboratory/laboratory.module#LaboratoryModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'pick-plan',
        loadChildren: 'app/harvest-engine/modules/pick-plan/pick-plan.module#PickPlanModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'inventory',
        loadChildren: 'app/harvest-engine/modules/inventory/inventory.module#InventoryModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'quality-control',
        loadChildren: 'app/harvest-engine/modules/quality-control/quality-control.module#QualityControlModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'integrations',
        loadChildren: 'app/harvest-engine/modules/integrations/integrations.module#IntegrationsModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'scouting',
        loadChildren: 'app/harvest-engine/modules/field-events/field-events.module#FieldEventsModule'
    },
    {
        path: 'spray',
        loadChildren: 'app/harvest-engine/modules/spray/spray.module#SprayModule'
    },
    {
        path: 'gs-long',
        loadChildren: 'app/customer-integrations/gs-long/gs-long.module#GSLongModule',
    },
    {
        path: 'financial',
        loadChildren: 'app/harvest-engine/modules/financial/financial.module#FinancialModule'
    },
    {
        path: 'hop-analysis',
        loadChildren: 'app/harvest-engine/modules/hop-analysis/hop-analysis.module#HopAnalysisModule'
    },
    {
        path: 'farm-assessment',
        loadChildren: 'app/harvest-engine/modules/farm-assessment/farm-assessment.module#FarmAssessmentModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'contracts/contract-input',
        component: ContractInputComponent,
    },
    {
        path: 'contracts/contract-list',
        component: ContractListComponent,
    },
    {
        path: 'contracts/contract-ammend',
        component: ContractAmmendComponent,
    },
    {
        path: 'core/word-list',
        component: WordListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },

    {
        path: 'core/estimates-lock',
        component: EstimatesLockComponent,
    },
    {
        path: 'core/restart-service',
        component: RestartServiceComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['kw-admin']
        }
    },
    {
        path: 'core/rooms-list',
        component: RoomsListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/room-dashboard/:id',
        component: RoomDashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'core/warehouse-list',
        component: WarehouseListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },

    {
        path: 'core/varieties',
        // loadChildren: 'app/harvest-engine/core/components/varieties/varieties.module#VarietiesModule',
        component: VarietiesListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/varieties/detail',
        component: VarietiesDetailComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/varieties/detail/:id',
        component: VarietiesDetailComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {

        path: 'core/farms',
        component: FarmsListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/carrier-list',
        component: CarrierListComponent,
    },
    {

        path: 'core/farms/detail',
        component: FarmDetailComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/truck-type-list',
        component: TruckTypeListComponent,
    },
    {
        path: 'core/farms/detail/:id',
        component: FarmDetailComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },

    {
        path: 'core/package-types',
        component: PackageTypesComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/blocks',
        loadChildren: 'app/harvest-engine/core/components/blocks/blocks.module#BlocksModule',
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['read:farms']
        }
    },
    {
        path: 'core/room-locations',
        component: RoomLocationsComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/location-barcodes',
        component: LocationBarcodeListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/pool-list',
        component: PoolListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/crop-list',
        component: CropListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/storage-list',
        component: StorageListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'core/fieldman-list',
        component: FieldmanListComponent,
        canActivate: [RoleGuard, AuthGuard],
        data: {
            expectedRole: ['admin']
        }
    },
    {
        path: 'farm-features',
        loadChildren: 'app/harvest-engine/modules/farm-features/farm-feature.module#FarmFeatureModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'overview-map',
        loadChildren: 'app/harvest-engine/modules/overview-map/overview-map.module#OverviewMapModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'scales',
        loadChildren: 'app/harvest-engine/modules/scale/scale.module#ScaleModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'chat',
        loadChildren: 'app/harvest-engine-ai/harvest-engine-ai.module#HarvestEngineAiModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'core/employee-list',
        component: EmployeesComponent,
    },
    {
        path: 'core/employee-edit',
        component: EmployeeEditComponent,
    },
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HarvestEngineRoutingModule {
}