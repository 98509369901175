import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { InventoryLocation } from '../../customer-integrations/hollingbery/modules/inventory/receiving/models/inventory-location';
import { HollingberySupportServiceService } from '../../customer-integrations/hollingbery/services/hollingbery-support-service.service';
import { ErrorService } from './error.service';
import { Order, OrderLine } from '../../customer-integrations/hollingbery/modules/financial/orders/models/order';
import { EstimateDTO } from '../../harvest-engine/modules/estimates/models/estimate-dto';
import { PrinterDTO } from '../../harvest-engine/modules/estimates/models/printer-dto';
import { BlockDTO } from '../../harvest-engine/core/models/block';
import { FarmDTO } from '../../harvest-engine/core/models/farm';

import { UserDTO } from '../../harvest-engine/core/models/user';
import { FieldTaskDTO } from '../../harvest-engine/modules/field-events/models/field-task';
import { FieldEventType } from '../../harvest-engine/modules/field-events/models/field-event-type';
import { Cart } from '../../customer-integrations/hollingbery/modules/financial/orders/models/cart';
import * as moment from 'moment-timezone';
import { CropYear } from '../../harvest-engine/core/models/cropYear';
import { Brewery } from '../../customer-integrations/hollingbery/modules/brewery-configuration/models/brewery';
import { FilterItem } from '../../harvest-engine/core/widgets/kw-filter-dropdown/models/kw-filter-item';
import { FilterResults } from '../../harvest-engine/core/widgets/kw-filter-dropdown/models/filter-results';
import { CropYearService } from '../../harvest-engine/core/services/crop-year.service';
import { ContractDTO } from '../../harvest-engine/modules/contracts/models/contract';
import { ContractLine } from '../../harvest-engine/modules/contracts/models/lineItem';

import { Cart as eCart } from '../../harvest-engine/modules/web-store/models/cart';
import { VehicleDTO, VehicleType } from '../../harvest-engine/modules/spray/models/spray-vehicle';
import { Carrier } from '../../harvest-engine/modules/pick-plan/models/carrier';
import { TruckType } from '../../harvest-engine/modules/pick-plan/models/truck-type';
import { Fieldman } from '../../harvest-engine/core/models/fieldman';
import { StorageTypeDTO } from '../../harvest-engine/modules/pick-plan/models/storage-types-dto';
import { PoolTypeDTO } from '../../harvest-engine/modules/pick-plan/models/pool-type-dto';
import { CropVariety } from '../../harvest-engine/core/models/crop-variety';
import { Customer } from '../../harvest-engine/modules/contracts/models/customer';
import { Spray_RecommendationCreateDTO } from '../../harvest-engine/modules/spray/models/spray-rec';
import { Crop } from '../../harvest-engine/core/models/crop';
import { Applicator } from '../../harvest-engine/modules/spray/models/spray-applicator';
import { FarmFeatureType } from '../../harvest-engine/modules/farm-features/models/farm-feature-dto';
import { SprayApplicationRecord, SprayApplicationRecordDetail } from '../../harvest-engine/modules/spray/models/spray-application-record';
import { Spray_NoticeTemplate } from '../../harvest-engine/modules/spray/models/spray-notice-template';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private hbSupportService: HollingberySupportServiceService,
    private cropYearService: CropYearService,
    private errorService: ErrorService
  ) { 
    // Get the current crop year
    // TODO: This should NOT reference hollingbery
    this.cropYearService.getCurrentCropYear().subscribe(data => {
      this._currentCropYear = +data;
    }, err => this.errorService.handleError(err));
  }

  public hideLayout: boolean = false;

  // Hollingbery
  public order: Order;
  public cart: Cart;
  public orders: Order[];
  public orderLine: OrderLine;
  public orderLines: OrderLine[];
  public selectedPkgSize: any;
  public selectedLotId: any;
  public selectedMultiplier: any;
  public selectedBrewery: any;
  public selectedContractTypes: string;
  public selectedOwnership: string;
  public isBrewerySummary: boolean;
  public hollingberyIsPrivate: boolean = false;
  public variety: string;
  public selectedDateRange: Date[];
  public cropYear: number[] = [];
  public breweryList: Brewery[] = [];
  public cropYearFilterItem: FilterItem[] = [];

  // Harvest Engine
  public contract: ContractDTO;
  public ecommerceCart: eCart;
  public contractLine: ContractLine[];
  public trapReportColumns: any;
  public filterResults: any;
  public blockDTO: BlockDTO[] = [];
  public farmDTO: FarmDTO[] = [];
  public farmList: string[] = [];
  public blockList: BlockDTO[] = [];
  public printers: PrinterDTO[];
  public estimate: EstimateDTO;
  public selectedBlock: BlockDTO;
  public selectedVehicle: VehicleDTO;
  public selectedApplicator: Applicator
  public selectedTruckType: TruckType;
  public selectedStorageType: StorageTypeDTO;
  public selectedCrop: Crop;
  public selectedVehicleType: VehicleType;
  public selectedFieldEventType: FieldEventType;
  public selectedUser: UserDTO;
  public selectedCropYear: CropYear;
  public selectedFarm: FarmDTO;
  public selectedTask: FieldTaskDTO;
  public selectedAssetType: FarmFeatureType;
  public reportData: any;
  public selectedVariety: any;
  public selectedVarieties: any;
  public blockId: number; 
  public selectedCropYears: string;
  public positionReportCropYears: number[];
  public farms: FarmDTO[];
  public selectedFieldTasks: FieldTaskDTO[] = [];
  public selectedInventoryDetails: InventoryLocation;
  public room: any[];
  public allRooms: any[];
  public building: any[];
  public snapshotDate: Date;
  public searchParams: { paramName: string, value: any }[] = [];
  private _currentCropYear: number = 0;
  public redirectUrlRec: string = "";
  public selectedCarrier: Carrier;
  public selectedTruck: Carrier;
  public selectedFieldMan: Fieldman;
  public selectedPoolType: PoolTypeDTO;
  public selectedcropVarieties: CropVariety;
  public selectedCustomer: Customer;
  public selectedSprayRecommendation: Spray_RecommendationCreateDTO;
  public selectedAppRecord: SprayApplicationRecord;
  public selectedAppRecDetail: SprayApplicationRecordDetail;
  public selectedAppRecDetailList: SprayApplicationRecordDetail[];
  public selectedSprayRecommendationId: number;
  public selectedSprayVehicle:Applicator;
  public selectedChemicalId:string;
  public currentCropYear(): number {
    this._currentCropYear = moment().year();

    let currentDate = moment(new Date());

      if(currentDate.month() <= 6){
        this._currentCropYear = currentDate.year() - 1;
      } else {
        this._currentCropYear = currentDate.year();
      }

    return this._currentCropYear;
    // if(this._currentCropYear === 0) {

    //   // this.hbSupportService.getCropYear().subscribe(data => {
    //   //   this._currentCropYear = +data;
    //   // }, err => this.errorService.handleError(err));
      

    //   return this._currentCropYear;

    // } else {
      
    //   return this._currentCropYear;
    // }
  }


  public getSearchParam(paramName: string): any {
    const param = this.searchParams.filter(p => p.paramName === paramName)[0];

    if (param === undefined) {
      return null;
    }
    else 
    {
      return param.value;
    }
  }

  public addSearchParam(paramName: string, paramValue: any) {
    // remove any existing of the same name
    this.removeSearchParam(paramName);

    this.searchParams.push({paramName: paramName, value: paramValue });
  }

  public removeSearchParam(paramName: string){
      this.searchParams = this.searchParams.filter(p => p.paramName !== paramName);
  }

  public addUrlParam(paramName: string, paramValue: string) {

    // changes the route without moving from the current view or
    // triggering a navigation event,
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        [paramName]: [paramValue]
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: true
      // do not trigger navigation
    });

  }
}
