import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MatDialogRef, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { faArrowAltCircleLeft, faPlus, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { InventoryItemDTO } from '../../../models/item';
import { InventoryItemAttributeValueDTO } from '../../../models/item-attribute-value';
import { Observable } from 'rxjs/Rx';
import { InventoryLotDTO } from '../../../models/inventory-lot';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { Router } from '@angular/router';
import { InventoryItemEditComponent } from '../inventory-item-edit/inventory-item-edit.component';
import { Subscription } from 'rxjs';
import { InventoryLotEditComponent } from '../../inventory-lots/inventory-lot-edit/inventory-lot-edit.component';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { FormControl, NgForm } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { map, startWith } from 'rxjs/operators';
import { TenantWordsPipe } from '../../../../../core/pipes/tenant-words.pipe';
import * as _ from 'underscore';
import { MockSharedService, SHARED_SERVICE } from '../../../../../../shared/mockSharedService';
import { PurchaseOrder } from '../../../../purchase-order/models/purchaseOrder';

export interface InventoryItemPopupData {
  item?: InventoryItemDTO;
  hum?: HandlingUnitMasterDTO;
  hud?: HandlingUnitDetailDTO;
  isHumEdit?: boolean;
  isPopup?: boolean;
  isReturnBottomsheet?: boolean;
  summaryPopupId?: string;
  isBulkCreate?: boolean;
}

export interface InventoryLotPopupData {
  lot?: InventoryLotDTO;
  hum?: HandlingUnitMasterDTO;
  hud?: HandlingUnitDetailDTO;
  isPopup?: boolean;
  isHumEdit?: boolean;
  isReturnBottomsheet?: boolean;
  summaryPopupId?: string;
  isBulkCreate?: boolean;
}
 
@Component({
  selector: 'inventory-add-item-popup',
  templateUrl: './inventory-add-item-popup.component.html',
  styleUrls: ['./inventory-add-item-popup.component.scss']
})
export class InventoryAddItemPopupComponent implements OnInit {
  @Input() isHumEdit: boolean = false;
  @Input() hum: HandlingUnitMasterDTO;
  @Input() isBulkCreate: boolean = false;

  @Input() itemId: number;
  @Input() lotId: number;
  @Input() summaryPopupId: string;
  @Input() items: PurchaseOrder;
  @ViewChild('itemForm') public itemForm: NgForm;

  constructor(public dialogRef: MatBottomSheetRef<InventoryAddItemPopupComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private changeDetRef: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private pipe: TenantWordsPipe,
    private inventoryService: InventoryService,
    @Inject(SHARED_SERVICE) private sharedService: MockSharedService, //Inject interface of shared service
    ) { }


  faTimes = faTimes;
  public faArrowAltCircleLeft = faArrowAltCircleLeft;

  public isLoading: boolean = true;

  public bottomSheetSubscription: Subscription;



  public qty: number;
  public productDescription: string;
  public packageQty: number;
  public packageComponentQty: number;

  public hud: HandlingUnitDetailDTO = {};
  public hudReady: boolean = false;
  public faPlusCircle = faPlusCircle;

  public selectedItem: InventoryItemDTO;
  public searchItem = "";
  public allItems: InventoryItemDTO[] = [];
  public filteredItemOptions: InventoryItemDTO[] = [];
  public defaultItemSearchPrompt = `Please search for a ${this.pipe.transform("item")}.`;
  public itemSearchPrompt: string = `Please search for a ${this.pipe.transform("item")}.`;

  public selectedLot: InventoryLotDTO;
  public searchLot = "";
  public allLots: InventoryLotDTO[] = [];
  public filteredLotOptions: InventoryLotDTO[] = [];
  public defaultLotSearchPrompt = `Please search for a ${this.pipe.transform("lot")}.`;
  public lotSearchPrompt: string = `Please search for a ${this.pipe.transform("lot")}.`;


  public productControl = new FormControl();
  public filteredProductOptions: Observable<InventoryItemDTO[]>;

  faPlus = faPlus;

  ngOnChanges(changes: SimpleChanges): void {
    this.filterItembyPOItems();
  }
  
  ngOnInit() {
    this.hud = {};
    if (this.hum)
      this.data.Hum = this.hum;
    this.getData();

    if (this.data.hud) {
      this.hud.itemId = this.data.hud.itemId
      this.hud.lotId = this.data.hud.lotId
    }

  }

  getData() {
    this.isLoading = true;
    Observable.forkJoin([
      this.inventoryService.getInventoryItems(),
      this.inventoryService.getLots()
      //this.roomService.getRooms()
    ])
      .subscribe(x => {
        this.allItems = x[0];
        this.allLots = x[1];

        
        if(this.hud) {
          this.searchLot = this.hud.lotNumber;
          this.lotId = this.hud.lotId;
          this.itemId = this.hud.itemId;
          this.searchItem = this.hud.itemName;
        }
        this.filterItembyPOItems();
        this.filterLots();
        this.filterItems();
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      });


  }


  close() {
    this.dialogRef.dismiss();
  }

  onNoClick(): void {
    this.dialogRef.dismiss();
  }

  addItem() {
    let data: InventoryItemPopupData = {};
    if (this.hum)
      data = {
        item: {},
        hum: this.hum,
        hud: this.hud,
        isHumEdit: this.isHumEdit,
        isPopup: true,
        summaryPopupId: this.data.summaryPopupId,
        isReturnBottomsheet: true,
        isBulkCreate: this.isBulkCreate
      }

    else
      data = {
        item: {},
        hum: this.data.Hum,
        hud: this.hud,
        isHumEdit: this.isHumEdit,
        isPopup: true,
        summaryPopupId: this.data.summaryPopupId,
        isReturnBottomsheet: true,
        isBulkCreate: this.isBulkCreate
      }
    // open the bottom sheet
    this.bottomSheet.open(InventoryItemEditComponent, { data });

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(item => {
      this.getData();
  
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  addItemToHum() {
    this.hud.itemName = this.allItems.find(x => x.id == this.hud.itemId).name;
    this.hud.quantity = this.qty;
    this.hud.isActive = true;
    this.hud.handlingUnitMasterId = this.data.Hum.id;
    this.hud.packageComponentWeightUnitOfMeasure = this.allItems.find(x => x.id == this.hud.itemId).packageComponentWeightUnitOfMeasure;
    this.hud.itemDescription = this.productDescription;
    this.hud.packageComponentWeight = this.allItems.find(x => x.id == this.hud.itemId).packageComponentWeight;
    this.hud.packageComponentQuantityPerPackage = this.allItems.find(x => x.id == this.hud.itemId).packageComponentQuantityPerPackage;
    if (!this.hum.handlingUnitDetails)
      this.data.Hum.handlingUnitDetails = [];
    this.hum.handlingUnitDetails.push({ ...this.hud });
    this.hud = {};
    this.qty = 0;
    this.packageComponentQty = 0;
    this.packageQty = 0;
    this.itemForm.reset();
  }

  getBoxQty() {
    if (this.selectedItem && this.selectedItem.packageComponentQuantityPerPackage != 0)
      return Math.ceil((this.qty / this.selectedItem.packageComponentQuantityPerPackage));
    else
      return 0;
  }

  getPalletQty() {
    if (this.selectedItem && this.selectedItem.packageQuantityPerPallet != 0)
      return Math.ceil((this.getBoxQty() / this.selectedItem.packageQuantityPerPallet));
    else
      return 0;
  }

  filterLots() {

    if (this.allLots) {
      if (!this.searchLot)
        this.searchLot = "";

      let filterValue = this.searchLot.toLowerCase();
      let results = this.allLots.filter(option => option.lotNumber && option.lotNumber.toLowerCase().includes(filterValue));

      if (results.length > 40 && !this.lotId) {
        this.filteredLotOptions = [];
        this.lotSearchPrompt = `Too many ${this.pipe.transform("lots")} found... please narrow your search.`
      } else {
        this.filteredLotOptions = _.sortBy([...results], x => x.lotNumber);
        this.lotSearchPrompt = this.defaultLotSearchPrompt;
      }
    }
  }

  filterItems() {

    if (this.allItems) {

      if (!this.searchItem)
        this.searchItem = "";

      let filterValue = this.searchItem.toLowerCase();
      let results = this.allItems.filter(option => option.name && option.name.toLowerCase().includes(filterValue));

      if (results.length > 40 && !this.itemId) {
        this.filteredItemOptions = [];
        this.itemSearchPrompt = `Too many ${this.pipe.transform("items")} found... please narrow your search.`
      } else {
        this.filteredItemOptions = _.sortBy([...results], x => x.name);
        this.itemSearchPrompt = this.defaultLotSearchPrompt;
      }
    }
  }


  addLot() {

    let data: InventoryLotPopupData = {};
    if (this.hum)
      data = {
        lot: {},
        isPopup: true,
        hum: this.hum,
        isHumEdit: this.isHumEdit,
        hud: this.hud,
        isReturnBottomsheet: true,
        summaryPopupId: this.data.summaryPopupId,
        isBulkCreate: this.isBulkCreate
      }
    else
      data = {
        lot: {},
        isPopup: true,
        hum: this.data.Hum,
        isHumEdit: this.isHumEdit,
        hud: this.hud,
        isReturnBottomsheet: true,
        summaryPopupId: this.data.summaryPopupId,
        isBulkCreate: this.isBulkCreate
      }

    // open the bottom sheet
    let ref = this.sharedService.openDialog('InventoryLotEditComponent',this.bottomSheet, { data });


    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    this.bottomSheetSubscription = ref.afterDismissed().subscribe(lot => {

      this.getData();


      this.bottomSheetSubscription.unsubscribe();
    });
  }

  submit() {
    this.isLoading = true;
    this.hud.itemName = this.allItems.find(x => x.id == this.hud.itemId).name;
    this.hud.quantity = this.qty;
    this.hud.isActive = true;
    this.hud.handlingUnitMasterId = this.data.Hum.id
    this.data.Hum.handlingUnitDetails.push(this.hud);
    this.inventoryService.putInventoryHUM(this.data.Hum).subscribe(x => {

      if (this.data && this.data.summaryPopupId)
        this.dialog.getDialogById(this.data.summaryPopupId).componentInstance.updateHUM(x);

      this.dialogRef.dismiss(x);

    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  itemSelectionChanged() {
    this.selectedItem = this.allItems.find(x => x.id == this.itemId);
    this.productDescription = this.selectedItem.description;
    
    if (this.selectedItem) {
      this.hud.itemId = this.selectedItem.id;
      this.hud.itemName = this.selectedItem.name;
    }
    else{
      this.hud.itemId = null;
      this.hud.itemName = null;
    }

    if((this.qty || this.packageComponentQty) && this.hud.lotId && this.hud.itemId)
      this.hudReady = true;
    else
      this.hudReady = false;

    this.changeDetRef.detectChanges();
  }

  lotSelectionChanged() {
    let selectedLot = this.allLots.find(x => x.id == this.lotId);

    if (selectedLot) {
      this.hud.lotId = selectedLot.id;
      this.hud.lotNumber = selectedLot.lotNumber;
    }
    else{
      this.hud.lotId = null;
      this.hud.lotNumber = null;
    }

    if((this.qty || this.packageComponentQty) && this.hud.lotId && this.hud.itemId)
      this.hudReady = true;
    else
      this.hudReady = false;
  }




  setItem(item?: InventoryItemDTO) {
    if (item) {
      this.selectedItem = item;
      this.hud.itemId = item.id
      this.hud.itemName = item.name;
    }

    if((this.qty || this.packageComponentQty) && this.hud.lotId && this.hud.itemId)
      this.hudReady = true;
    else
      this.hudReady = false;
  }

  getWeight() {
    if(this.selectedItem)
      return this.qty * this.selectedItem.packageComponentQuantityPerPackage * this.selectedItem.packageComponentWeight;
    else
      return 0;
  }

  setQty() {
    if(this.selectedItem && this.selectedItem.packageHasComponents) {
      let componentToPackage =  this.packageComponentQty/this.selectedItem.packageComponentQuantityPerPackage;

      if(!this.packageQty)
        this.packageQty = 0;

      if(componentToPackage)
        this.qty = componentToPackage + this.packageQty;
      else
        this.qty = this.packageQty;

    }

    if((this.qty || this.packageComponentQty) && this.hud.lotId && this.hud.itemId)
      this.hudReady = true;
    else
      this.hudReady = false;
  }

  setDescription() {
    if(this.productDescription) {
      this.hud.itemDescription = this.productDescription;
    }


  }

  readyCheck(){
    if((this.qty || this.packageComponentQty) && this.hud.lotId && this.hud.itemId)
      this.hudReady = true;
    else
      this.hudReady = false;
  }

  filterItembyPOItems(){
    if (!!this.items && this.allItems.length > 0) {
      let selectedItems = this.items.items.map(x=>x.itemId) || [];
      this.allItems = this.allItems.filter(x=> selectedItems.includes(x.id));
    }
  }
}
