import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryItemsComponent } from './pages/inventory-items/inventory-items/inventory-items.component';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatIconModule, MatCheckboxModule, MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatButtonModule, MatExpansionModule, MatSlideToggleModule, MatDividerModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatSelectModule, MatDialogRef, MatBottomSheetRef, MatAutocompleteModule } from '@angular/material';
import { InventoryRoutingModule } from './inventory.routing.module';
import { NbCardModule, NbSpinnerModule, NbToastrModule } from '@nebular/theme';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InventoryItemEditComponent } from './pages/inventory-items/inventory-item-edit/inventory-item-edit.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { InventorySummaryComponent } from './pages/inventory-summary/inventory-summary.component';
import { InventorySummaryPopupComponent } from './pages/inventory-summary/inventory-summary-popup/inventory-summary-popup.component';
import { InventoryAddItemPopupComponent } from './pages/inventory-items/inventory-add-item-popup/inventory-add-item-popup.component';
import { FormsModule } from '@angular/forms';
import { InventoryLotEditComponent } from './pages/inventory-lots/inventory-lot-edit/inventory-lot-edit.component';
import { InventoryScanPopupComponent } from './pages/inventory-summary/inventory-scan-popup/inventory-scan-popup.component';
import { InventoryAdjustQtyPopupComponent } from './pages/inventory-summary/inventory-adjust-qty-popup/inventory-adjust-qty-popup.component';
import { InventoryMoveLocationPopupComponent } from './pages/inventory-summary/inventory-move-location-popup/inventory-move-location-popup.component';
import { InventoryHumEditComponent } from './pages/inventory-summary/inventory-hum-edit/inventory-hum-edit.component';
import { InventoryItemReportComponent } from './pages/inventory-item-report/inventory-item-report.component';
import { KwFilterDropdownModule } from '../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.module';
import { TenantWordsModule } from '../../core/pipes/tenant-words.module';
import { CycleCountComponent } from './pages/cycle-count/cycle-count.component';
import { InventoryItemDetailComponent } from './pages/inventory-items/inventory-item-detail/inventory-item-detail.component';
import { ItemAssembliesComponent } from './pages/item-assemblies/item-assemblies.component';
import { ItemAssemblyEditComponent } from './pages/item-assembly-edit/item-assembly-edit.component';
import { ExcelExportService, FilterService, FreezeService, GridModule, GroupService, PageService, PdfExportService, ResizeService, SortService, AggregateService, ToolbarService, EditService, RowDDService, SelectionService } from '@syncfusion/ej2-angular-grids';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RemoveInventoryConfirmationComponent } from './pages/inventory-summary/inventory-summary-popup/remove-inventory-confirmation/remove-inventory-confirmation.component';
import { InventoryTransferComponent } from './pages/inventory-transfer/inventory-transfer.component';
import { TransactionTableComponent } from './pages/transaction-table/transaction-table.component';
import { InventoryBulkMoveComponent } from './pages/inventory-summary/inventory-bulk-move/inventory-bulk-move.component';
import { LotsListComponent } from './pages/inventory-lots/lots-list/lots-list.component';
import { PrinterSelectorModule } from '../../core/widgets/printer-select/printer-selector.module';
import { LotHierarchyChartComponent } from './widgets/lot-hierarchy-chart/lot-hierarchy-chart.component';
import { LotDashboardComponent } from './pages/lot-dashboard/lot-dashboard.component';
import { LotProductSummaryComponent } from './pages/lot-dashboard/lot-product-summary/lot-product-summary.component';
import { LotLocationSummaryComponent } from './pages/lot-dashboard/lot-location-summary/lot-location-summary.component';
import { LotBarcodeSummaryComponent } from './pages/lot-dashboard/lot-barcode-summary/lot-barcode-summary.component';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { LotsOptionsDiologComponent } from './pages/inventory-lots/lots-options-diolog/lots-options-diolog.component';
import { LotOrderSummaryComponent } from './pages/lot-dashboard/lot-order-summary/lot-order-summary.component';
import { HandlingUnitDetailEditComponent } from './pages/handling-unit-detail-edit/handling-unit-detail-edit.component';
import { InventoryBatchListComponent } from './pages/inventory-batch-list/inventory-batch-list.component';
import { InventoryBatchActionsComponent } from './widgets/inventory-batch-actions/inventory-batch-actions.component';
import { ReasonCodesComponent } from './pages/reason-codes/reason-codes.component';
import { ReasonCodeEditComponent } from './pages/reason-codes/reason-code-edit/reason-code-edit.component';
import { DiagramModule } from '@syncfusion/ej2-angular-diagrams';
import { LotDetailsByRoomComponent } from './widgets/lot-details-by-room/lot-details-by-room.component';
import { LotDetailsByRoomDialogComponent } from './widgets/lot-details-by-room/lot-details-by-room-dialog/lot-details-by-room-dialog.component';
import { LotDetailsByRoomActionComponent } from './widgets/lot-details-by-room-action/lot-details-by-room-action.component';
import { LotDetailsByRoomMoveInventoryComponent } from './widgets/lot-details-by-room/lot-details-by-room-move-inventory/lot-details-by-room-move-inventory.component';
import { MassBalanceComponent } from './widgets/mass-balance/mass-balance.component';
import { PackoutReportComponent } from './pages/packout-report/packout-report.component';
import { ReasonCodeChangerPopupComponent } from './widgets/mass-balance/reason-code-changer-popup/reason-code-changer-popup.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

@NgModule({
  declarations: [InventoryItemsComponent, InventoryItemEditComponent, InventorySummaryComponent, InventorySummaryPopupComponent, InventoryAddItemPopupComponent,
    LotsListComponent, InventoryLotEditComponent, InventoryScanPopupComponent, InventoryAdjustQtyPopupComponent, InventoryMoveLocationPopupComponent, InventoryHumEditComponent,
    InventoryItemReportComponent, CycleCountComponent, InventoryItemDetailComponent, ItemAssembliesComponent, ItemAssemblyEditComponent, RemoveInventoryConfirmationComponent,
    InventoryTransferComponent, TransactionTableComponent, InventoryBulkMoveComponent, LotHierarchyChartComponent, LotDashboardComponent, LotProductSummaryComponent, LotLocationSummaryComponent, LotBarcodeSummaryComponent, LotsOptionsDiologComponent, LotOrderSummaryComponent, HandlingUnitDetailEditComponent, InventoryBatchListComponent, InventoryBatchActionsComponent, ReasonCodesComponent, ReasonCodeEditComponent, LotDetailsByRoomComponent, LotDetailsByRoomDialogComponent, LotDetailsByRoomActionComponent, LotDetailsByRoomMoveInventoryComponent, MassBalanceComponent, PackoutReportComponent, ReasonCodeChangerPopupComponent],
  imports: [
    MatAutocompleteModule,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    NbCardModule,
    MatCheckboxModule,
    TooltipModule,
    PrinterSelectorModule,
    GridModule,
    NbSpinnerModule,
    //HarvestEngineModule,
    // HarvestEngineModule,
    MatIconModule,
    MatBottomSheetModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    MatSortModule,
    InventoryRoutingModule,
    MatButtonModule,
    NgxMatSelectSearchModule,
    FormsModule,
    MatExpansionModule,
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    TenantWordsModule,
    NbCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    NbSpinnerModule,
    KwFilterDropdownModule,
    NbToastrModule,
    MatDividerModule,
    LottieAnimationViewModule,
    MatFormFieldModule,
    DiagramModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatBottomSheetModule,
    //HarvestEngineModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DatePickerModule,
    NumericTextBoxAllModule
  ],
  exports: [
    InventoryItemsComponent,
    ItemAssembliesComponent,
    InventoryItemReportComponent,
    LotHierarchyChartComponent,
    InventoryScanPopupComponent,
    LotDetailsByRoomComponent,
    LotDashboardComponent,
    MassBalanceComponent
  ],
  entryComponents: [
    InventoryItemEditComponent,
    ItemAssemblyEditComponent,
    LotDetailsByRoomActionComponent,
    LotDetailsByRoomMoveInventoryComponent,
    InventoryAdjustQtyPopupComponent,
    InventoryMoveLocationPopupComponent,
    LotDetailsByRoomDialogComponent,
    //InventorySummaryPopupComponent,
    InventoryAddItemPopupComponent,
    // LabQuantityAddComponent,
    InventoryScanPopupComponent,
    InventoryHumEditComponent,
    InventoryBatchActionsComponent,
    ReasonCodeEditComponent,
    LotDetailsByRoomComponent,
    LotsOptionsDiologComponent,
    RemoveInventoryConfirmationComponent,
    HandlingUnitDetailEditComponent,
    MassBalanceComponent,
    ReasonCodeChangerPopupComponent
  ],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    PageService,
    SortService,
    GroupService,
    FreezeService,
    ResizeService,
    ExcelExportService,
    ToolbarService,
    PdfExportService,
    FilterService,
    AggregateService,
    EditService,
    SelectionService,
    RowDDService 
  ]
})
export class InventoryModule { }
