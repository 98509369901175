import { Component, OnInit } from '@angular/core';


import * as differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';

@Component({
  selector: 'm-field-task-active',
  templateUrl: './field-task-active.component.html',
  styleUrls: ['./field-task-active.component.scss']
})
export class FieldTaskActiveComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private fieldTask: FieldTasksService,
  ) { }

  fieldTasks: any[] = [];
  ngOnInit() {
    this.fieldTask.apiFieldTasksGet(null, false).subscribe(tasks => {

      for (const ft of tasks) {
        const t = JSON.parse(JSON.stringify(ft));
        t.dateDifference = differenceInCalendarDays(t.dueDate, new Date());
        if (ft.assignedUsers === null || ft.assignedUsers.length === 0) {
          t.assignedUserLabel = 'All Users';
        } else {
          t.assignedUserLabel = (ft.assignedUsers.length > 1) ? ft.assignedUsers.length + ' Users' : ft.assignedUsers[0].name;
        }

        this.fieldTasks.push(t);
      }

      this.fieldTasks.sort(function(a, b) {
          return a.dateDifference - b.dateDifference;
      });
      // this.fieldTasks = tasks;
    });
  }

}
