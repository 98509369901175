import { Component, OnInit } from '@angular/core';
import { TruckType } from '../../../../modules/pick-plan/models/truck-type';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { truckTypeService } from '../../../../modules/pick-plan/services/truck-type.service';
import { MatDialogRef } from '@angular/material';
import { SessionService } from '../../../../../@core/utils/session.service';
import { NgForm } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'truck-type-details',
  templateUrl: './truck-type-details.component.html',
  styleUrls: ['./truck-type-details.component.scss']
})
export class TruckTypeDetailsComponent implements OnInit {

  //#region Properties
  public editActive = false;
  public truckTypeLoading = true;
  public editTruckType: TruckType = {};
  public submitting: any;
  //#endregion
  
  //#region Constructor with Dependencies  
  constructor(
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<any>,
    private session: SessionService,
    public truckTypeService: truckTypeService,
    private toastrService: NbToastrService
  ) {}
  //#endregion

  ngOnInit() {
    this.editTruckType = this.session.selectedTruckType;
    if (
      this.editTruckType === null ||
      this.editTruckType === undefined ) {
      this.editTruckType = {};
      this.editTruckType.isActive = true;
      this.truckTypeLoading = false;
    }
  }

  cancelClicked(form: NgForm, cancelled: boolean = false) {
    this.editActive = false;
    this.editTruckType = {};
    form.resetForm();
    this.dialogRef.close(cancelled);
  }

  onSubmit() {
    this.submitting = true;
    setTimeout(()=>{
      if (this.editTruckType.id) {
        this.truckTypeService
          .putTruckTypeDTO(this.editTruckType)
          .subscribe(
            () => {
              this.toastrService.success(
                "Truck Type Details successfully updated!",
                this.editTruckType.name + " updated!"
              );
              this.dialogRef.close(true);
            },
            (err) => {
              this.errorService.handleError(err);
              this.dialogRef.close(false);
            }
          );
      } else {
        this.truckTypeService.postTruckTypeDTO(this.editTruckType).subscribe(
          () => {
            this.toastrService.success(
              "Truck Type Details successfully saved!",
              this.editTruckType.name + " saved!"
            );
            this.dialogRef.close(true);
          },
          (err) => {
            this.errorService.handleError(err);
            this.dialogRef.close(false);
          }
        );
      }
    },1000);
  }

}
