import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { PoolTypeDTO } from '../../modules/pick-plan/models/pool-type-dto';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PoolService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  
  public getPoolTypes(includeInactive: boolean = false) {

    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<PoolTypeDTO[]>(`${this.basePath}/api/poolTypes`,
      {
        // params: queryParameters,
        headers: headers,
      }
    );
  }

  public getPoolType(poolTypeId: number): Observable<PoolTypeDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PoolTypeDTO>(`${this.basePath}/api/poolTypes/${poolTypeId}`,
      {
        headers,
      }
    );
  }

  public postPoolTypeDTO(poolTypeDTO: PoolTypeDTO): Observable<PoolTypeDTO> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<PoolTypeDTO>(`${this.basePath}/api/poolTypes`, poolTypeDTO,

      {
        headers,
      }
    );
  }

  public putPoolTypeDTO(poolTypeDTO: PoolTypeDTO): Observable<PoolTypeDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<PoolTypeDTO>(`${this.basePath}/api/poolTypes/${poolTypeDTO.id}`, poolTypeDTO,

      {
        headers,
      }
    );
  }

  public deletePoolTypeDTO(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/poolTypes/${id}`,
      {
        headers,
      }
    );
  }

}
