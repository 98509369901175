import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { PrecisionJobDetail } from '../../models/precision-job-detail';
import { NgForm } from '@angular/forms';
import { PrecisionPurchaseOrderReceipt } from '../../models/precision-purchase-order-receipt';
import { SessionService } from '../../../../@core/utils/session.service';
import { PrecisionPurchaseOrderService } from '../../services/precision-purchase-order.service';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import { NbToastrService } from '@nebular/theme';
import { ErrorService } from '../../../../@core/utils/error.service';
import { Subscription } from 'rxjs';
import { MatBottomSheetRef } from '@angular/material';
import { PrecisionSessionService } from '../../services/precision-session.service';
import { PrecisionReceiptItem } from '../../models/precision-receipt-item';

@Component({
  selector: 'receive-line-items',
  templateUrl: './receive-line-items.component.html',
  styleUrls: ['./receive-line-items.component.scss']
})
export class ReceiveLineItemsComponent implements OnInit, OnDestroy {

  @Input() lineItem: PrecisionJobDetail[] = [];
  @ViewChild('receiveLineItemForm') receiveLineItemForm: NgForm;

  public poReceipt: PrecisionPurchaseOrderReceipt;
  public po: PrecisionPurchaseOrder;
  public currDate = new Date();
  public receivedQty: number;
  public purchaseOrder: PrecisionPurchaseOrder;
  isLoading: boolean = false;

  constructor(
    private session: PrecisionSessionService,
    private precisionPoService: PrecisionPurchaseOrderService,
    private toastr: NbToastrService,
    private errorService: ErrorService,
    private _bottomSheetRef: MatBottomSheetRef<ReceiveLineItemsComponent>,
  ) { }

  ngOnDestroy(): void {
    this.session.precisionJobDetail = undefined;
  }

  ngOnInit() {
    this.purchaseOrder = this.session.precisionPurchaseOrder;
    
    if(this.session.precisionJobDetail) {
      this.lineItem.push(this.session.precisionJobDetail);
    }

    if(!this.poReceipt) {
      this.poReceipt = {
        recordId: 0,
        receiptDate: new Date(),
        receivedBy: '',
        receiptItems: [],
        comments: '',
        receivedAt: '',
  
        isActive: true,
        createdBy: '',
        createdByUserId: 0,
        status: '',
      };
    }

    let item: PrecisionReceiptItem[] = this.lineItem.map(item => {
      return <PrecisionReceiptItem>{
        itemNumber: item.item,
        receivedQuantity: item.receivedQty,
        notes: '',
      }
    })

    this.poReceipt.receiptItems.push(item[0]);
    
  }

  save() {
    this.isLoading = true;
    
    for(let item of this.lineItem) {
      this.poReceipt.receiptItems.push({
        itemNumber: item.item,
        receivedQuantity: item.receivedQty,
      })
    }

    this.poReceipt.receiptItems.push({
      //itemNumber: this.lineItem.item,
      receivedQuantity: this.receivedQty
    });

    if (!this.purchaseOrder.receipts) {
      this.purchaseOrder.receipts = [];
    }

    this.purchaseOrder.receipts.push(this.poReceipt);
    this.precisionPoService.putPurchaseOrder(false, this.purchaseOrder, false).subscribe(po => {
      this.toastr.success('Successfully received');
      this._bottomSheetRef.dismiss();
      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });

  }

  clearReceivedBy() {
    this.poReceipt.receivedBy = '';
  }

  clearReceivedAt() {
    this.poReceipt.receivedAt = '';
  }

  clearReceivedQty() {
    this.receivedQty = null;
  }

  clearComments() {
    this.poReceipt.comments = '';
  }

  clear() {
    this.receiveLineItemForm.resetForm()
  }

}
