import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HandlingUnitMasterDTO } from '../../models/handling-unit-master';
import { InventoryTransferService } from './inventory-transfer.service';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';
import { faArrowAltCircleLeft, faSave, faScanner, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { HandlingUnitDetailDTO } from '../../models/handling-unit-detail';
import { MatDialog } from '@angular/material';
import { InventoryScanPopupComponent, InventoryScanPopupData } from '../inventory-summary/inventory-scan-popup/inventory-scan-popup.component';
import { NbToastrService } from '@nebular/theme';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';

@Component({
  selector: 'inventory-transfer',
  templateUrl: './inventory-transfer.component.html',
  styleUrls: ['./inventory-transfer.component.scss']
})
export class InventoryTransferComponent implements OnInit {

  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public isLoading = false;

  public faTimes = faTimes;
  public faSave = faSave;
  public faScanner = faScanner;

  public hum: HandlingUnitMasterDTO;
  public destinationHum: HandlingUnitMasterDTO;

  constructor(
    public inventoryTransferService: InventoryTransferService,
    private router: Router,
    public location: Location,
    public dialog: MatDialog,
    public toastr: NbToastrService,
    private inventoryService: InventoryService,
    private errorService: ErrorService,
  ) {

  }

  ngOnInit() {
    if (!this.inventoryTransferService.sourceHum) {
      this.location.back();
    }
    this.hum = this.inventoryTransferService.sourceHum;

  }

  convertDate(date: Date) {
    return moment(date).format('LL');
  }

  setQty(data: HandlingUnitDetailDTO) {
    if (data && data.packageHasComponents) {
      if(!data.transferComponentQty)
        data.transferComponentQty = 0;

      if(!data.transferPackageQty)
        data.transferPackageQty = 0;

      let componentToPackage = data.transferComponentQty / data.packageComponentQuantityPerPackage;


      data.transferQty = componentToPackage + data.transferPackageQty;


    } 

    let hudIndex = this.hum.handlingUnitDetails.findIndex(x => x.id == data.id);

    this.hum.handlingUnitDetails[hudIndex].transferQty = data.transferQty;
    this.hum.handlingUnitDetails[hudIndex].transferPackageQty = data.transferPackageQty;
    this.hum.handlingUnitDetails[hudIndex].transferComponentQty = data.transferComponentQty;
  }

  getLocation(hum: HandlingUnitMasterDTO) {
    let locationNames = [];
    if (hum) {

      if (hum.warehouseName) {
        locationNames.push(hum.warehouseName);
      }
      if (hum.roomName) {
        locationNames.push(hum.roomName)
      }

      if (hum.roomLocationName) {
        locationNames.push(hum.roomLocationName);
      }
    }

    return locationNames.join(", ");
  }

  scan() {
    const dialogRef = this.dialog.open(InventoryScanPopupComponent, {
      minWidth: '30%',
      data: <InventoryScanPopupData>{
        isTransferScan: true

      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isHum) {
        if (x.hum.id == this.hum.id) {
          this.toastr.warning("Inventory cannot be transfered to the selected item", "Invalid Scan");
        } else {
          this.destinationHum = x.hum;
        }
      }
    });
  }

  save() {

    let t = 0;

    // update the source
    for (let sourceHud of this.hum.handlingUnitDetails) {

      if (sourceHud.transferQty && sourceHud.transferQty != 0 && sourceHud.transferQty != undefined && sourceHud.transferQty != null) {
        sourceHud.quantity = sourceHud.quantity - sourceHud.transferQty;

        //update the destination
        let existingDestHud = this.destinationHum.handlingUnitDetails.find(x => x.lotNumber == sourceHud.lotNumber && x.itemName == sourceHud.itemName);
        
        
        if (existingDestHud) {
          existingDestHud.quantity = existingDestHud.quantity + sourceHud.transferQty;
        } else {
          let newHud: HandlingUnitDetailDTO = {
            isActive: true,
            itemId: sourceHud.itemId,
            itemDescription: sourceHud.itemDescription,
            itemName: sourceHud.itemName,
            loadId: sourceHud.loadId,
            loadNumber: sourceHud.loadNumber,
            lotId: sourceHud.lotId,
            lotNumber: sourceHud.lotNumber,
            quantity: sourceHud.transferQty

          }
          this.destinationHum.handlingUnitDetails.push(newHud);
        }

      }


    }

      // hit the API

      this.isLoading = true;

      let requestList = [];

      if (this.destinationHum.id != 0 && this.destinationHum.id) {
        requestList.push(this.inventoryService.putInventoryHUM(this.destinationHum));
      }
      else {
        requestList.push(this.inventoryService.postInventoryHUM(this.destinationHum));
      }

      requestList.push(this.inventoryService.putInventoryHUM(this.hum));

      Observable.forkJoin(requestList).subscribe(results => {
        this.isLoading = false;
        this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary-popup?humId=" + results[0].id);
      }, err => { this.errorService.handleError(err); this.isLoading = false; })

  }
}
