import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { UserUploadFile, UserUploadFileMetadata } from '../models/userUploadFile';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public fileList: [] = [];
  public isLoading: boolean = true;
  private AuthToken: string;

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public setToken(token: string){
    this.AuthToken = token;
  }

  public getFileUploads(id?: number): Observable<UserUploadFile> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    if(id != null){ return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload/${id}`, { headers,});}
    else{ return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload`, { headers,});}
  }

  public getFileUploadsByFieldEvent(id: number): Observable<UserUploadFile> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('fieldEventId', id.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload`, { headers, params: queryParameters});
  }



  public getFileUploadRecords(lastUpdate: Date): Observable<UserUploadFileMetadata[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    if(lastUpdate) queryParameters = queryParameters.set('lastUpdate', lastUpdate.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<UserUploadFileMetadata[]>(`${this.basePath}/api/FileUpload/GetRecordMetadata`, { headers, params: queryParameters}).pipe(retry(4));
  }



  public deleteFileUploads(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/FileUpload/${id}`,
      {
        headers,
      }
    );
  }

  public postFileUploads(file: File[], fieldEventId?): Observable<FormData> {

    let headers = this.defaultHeaders;
    const formData = new FormData();

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    for (const x of file){
      formData.append('file', x);
    };
    
    if(fieldEventId)  
      formData.append('fieldEventId', fieldEventId);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.post<any>(`${this.basePath}/api/FileUpload`, formData,
    //return this.httpClient.post<any>(`http://localhost:50329/api/FileUpload`, formData,
      {
        headers,
      }
    );
  }

  public postLotFileUploads(file: File[], lotId?): Observable<FormData> {

    let headers = this.defaultHeaders;
    const formData = new FormData();

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    for (const x of file){
      formData.append('file', x);
    };
    
    if(lotId)  
      formData.append('lotId', lotId);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.post<any>(`${this.basePath}/api/FileUpload/LotFiles`, formData,
    //return this.httpClient.post<any>(`http://localhost:50329/api/FileUpload`, formData,
      {
        headers,
      }
    );
    
  }


  public getLotFileUploads(id?: number): Observable<UserUploadFile> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('lotId', id.toString());
    
    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    if(id != null){ return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload/LotFiles`, { headers, params: queryParameters});}
    else{ return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload/LotFiles`, { headers,});}
  }
  

  public postLoadFileUploads(file: File[], loadId?): Observable<FormData> {

    let headers = this.defaultHeaders;
    const formData = new FormData();

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    for (const x of file){
      formData.append('file', x);
    };
    
    if(loadId)  
      formData.append('loadId', loadId);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.post<any>(`${this.basePath}/api/FileUpload/LoadFiles`, formData,
    //return this.httpClient.post<any>(`http://localhost:50329/api/FileUpload`, formData,
      {
        headers,
      }
    );
  }

  public getLoadFileUploads(id?: number): Observable<UserUploadFile> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    if(id != null){ return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload/${id}`, { headers,});}
    else{ return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload`, { headers,});}
  }

  public getFileUploadsByLoad(id: number): Observable<UserUploadFile> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('loadId', id.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload/LoadFiles`, { headers, params: queryParameters});
  }

  public getFileUploadsByFeatureEventId(id: number): Observable<UserUploadFile> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('featureId', id.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<UserUploadFile>(`${this.basePath}/api/FileUpload/FeatureFiles`, { headers, params: queryParameters});
  }

  public postContractUploads(file: File[], contractId?): Observable<FormData> {

    let headers = this.defaultHeaders;
    const formData = new FormData();

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    for (const x of file){
      formData.append('file', x);
    };
    
    if(contractId)  
      formData.append('contractId', contractId);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.post<any>(`${this.basePath}/api/FileUpload/ContractFiles`, formData,
      {
        headers,
      }
    );
  }
  public getContractFileUploads(id?): Observable<[UserUploadFile]> {

    let headers = this.defaultHeaders;


    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('contractId', id.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<[UserUploadFile]>(`${this.basePath}/api/FileUpload/ContractFiles`, 
    { 
      headers, params: queryParameters
    });
  }

  // Save PO files in db
  public postPOUploads(file: File[], poId?): Observable<FormData> {

    let headers = this.defaultHeaders;
    const formData = new FormData();

    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    for (const x of file){
      formData.append('file', x);
    };
    
    if(poId)  
      formData.append('poId', poId);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.post<any>(`${this.basePath}/api/FileUpload/PurchaseOrderFiles`, formData,
      {
        headers,
      }
    );
  }

  //Get PO files from db
  public getPurchaseOrderFiles(id?): Observable<[UserUploadFile]> {

    let headers = this.defaultHeaders;


    headers = headers.set('Authorization', String(this.authService.getUserToken()));
    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('poId', id.toString());

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<[UserUploadFile]>(`${this.basePath}/api/FileUpload/PurchaseOrderFiles`, 
    { 
      headers, params: queryParameters
    });
  }
}
