import { ChangeDetectorRef, Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { InventorySessionService } from '../../../services/inventory-session.service';
import * as moment from 'moment-timezone';
import { faTrashAlt, faTruck, faPrint, faSave } from '@fortawesome/pro-duotone-svg-icons';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { MatBottomSheet, MatDialog, MatDialogRef } from '@angular/material';
import { InventorySummaryPopupComponent } from '../inventory-summary-popup/inventory-summary-popup.component';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { NbToastrService } from '@nebular/theme';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { InventoryTransferService } from '../../inventory-transfer/inventory-transfer.service';
import { InventoryMoveLocationPopupComponent } from '../inventory-move-location-popup/inventory-move-location-popup.component';
import { RemoveInventoryConfirmationComponent } from '../inventory-summary-popup/remove-inventory-confirmation/remove-inventory-confirmation.component';
import * as _ from 'underscore';
import { HandlingUnitBulkDelete, HandlingUnitBulkMove } from '../../../models/handling-unit-bulk-actions';
import { faArrowAltCircleLeft, faScanner } from '@fortawesome/pro-solid-svg-icons';
import { NavigationService } from '../../../../../core/services/navigation.service';
import { EditSettingsModel, FilterSettingsModel, GridComponent, PageSettingsModel, parentsUntil, SaveEventArgs, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { KragUtilitiesService } from '../../../../../../@core/utils/krag-utilities.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { PrinterDTO } from '../../../../estimates/models/printer-dto';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { InventoryScanPopupComponent } from '../inventory-scan-popup/inventory-scan-popup.component';
import { PlatformConfigService } from '../../../../../core/services/platform-config.service';
import { debounceTime, filter, pairwise, takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Component({
  selector: "inventory-bulk-move",
  templateUrl: "./inventory-bulk-move.component.html",
  styleUrls: ["./inventory-bulk-move.component.scss"],
})
export class InventoryBulkMoveComponent implements OnInit {
  @ViewChild("grid") grid: GridComponent;

  // Icons
  faTrashAlt = faTrashAlt;
  faTruck = faTruck;
  faPrint = faPrint;
  faSave = faSave;
  faScanner = faScanner;

  faArrowAltCircleLeft = faArrowAltCircleLeft;

  public isLoading: boolean = false;
  bulkRemoveInventory: HandlingUnitBulkDelete = {};
  public selectionOptions: SelectionSettingsModel;
  public handlingUnitBulkMove: HandlingUnitBulkMove = {};
  selectedRecords: HandlingUnitBulkMove[] = [];
  humToMove: HandlingUnitBulkMove = {};
  public printer: PrinterDTO;

  public moveCount: number = 0;

  public pageSettings: PageSettingsModel = {
    pageSize: 200,
    pageSizes: [50, 100, 200],
  };

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel",
  };
  integrationId: string = "";
  dateIn: Date;
  dateOut: Date;
  allHUMData: HandlingUnitMasterDTO[] = [];
  selectedRecord: any[] = [];
  isHops: boolean = false;
  filteredOptions: string[] = [];
  filteredSearchOptions: string[] = [];
  allIntegrationIds: string[] = [];
  searchIntegrationId: string = "";
  navigationSub$: Subscription;
  // idChangeSubject: Subject<any> = new Subject();
  editSettings?: EditSettingsModel;
  @ViewChild("editForm") public editForm?: FormGroup | any;
  allEqual: boolean;
  boxrules: { min: number; max: number; };
  patternReg = "/^[0-9]\d*$/"; 
  
  

  constructor(
    public session: InventorySessionService,
    private bottomSheet: MatBottomSheet,
    private errorService: ErrorService,
    // private router: Router,
    private route: ActivatedRoute,
    private changeDetRef: ChangeDetectorRef,
    private toastr: NbToastrService,
    private inventoryService: InventoryService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<InventorySummaryPopupComponent>,
    public authService: AuthService,
    private navigationService: NavigationService,
    private router: Router,
    private kragUtils: KragUtilitiesService,
    private platformConfigService: PlatformConfigService
  ) {
    this.getIsHops();
    this.getAllIntegrationId();
    this.loadAllData();
    this.navigationSub$ = this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((e: any[]) => {
        if (e[1].url == e[1].urlAfterRedirects) {
          if (
            !this.session.handlingUnitBulkMoveList ||
            this.session.handlingUnitBulkMoveList.length <= 0
          ) {
            //If navigate to same component
            this.loadAllData();
            // this.getInitialData();
          }
        }
      });
  }

  ngOnInit() {
    this.editSettings = { allowEditing: true, mode: "Normal" };
    this.boxrules =  { min:1,max:10000000000000 };
    this.humToMove.handlingUnitMasterIds = [];
    this.selectionOptions = {
      type: "Multiple",
      checkboxOnly: true,
      checkboxMode: "Default",
    };
    this.isLoading = true;
    if (
      !this.session.handlingUnitBulkMoveList ||
      this.session.handlingUnitBulkMoveList.length <= 0
    ) {
      // this.navigationService.back();
      this.router.navigateByUrl("/harvest-engine/inventory/inventory-summary");
    } else {
      //   this.getInitialData();
      this.moveCount = this.session.handlingUnitBulkMoveList.length;

      this.handlingUnitBulkMove = this.session.handlingUnitBulkMoveList[0];
    }

    this.isLoading = false;
    // this.idChangeSubject.pipe(debounceTime(500)).subscribe((data) => {
    //   this.searchDatabyIntegrationId(data);
    // });
  }

  getInitialData(data?: any) {
    if (!!data) {
      this.integrationId = data.integrationId;
      this.dateIn = new Date(data.dateIn);
      this.dateOut = new Date(data.dateOut);
    } else {
      this.integrationId = "";
      this.dateIn = null;
      this.dateOut = null;
    }
  }

  convertDate(date: Date) {
    return moment(date).format("LL");
  }

  moveInventory() {
    // CHECK TO SEE IF THERE ARE SELECTED RECORDS, IF SO, PASS SELECTED RECORDS INTO INVENOTORY MOVE POPUP
    this.rowSelected(null);

    if (this.humToMove.handlingUnitMasterIds.length > 0) {
      if (this.humToMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog
          .open(ConfirmationDialogComponent, {
            minWidth: "10%",
            data: {
              confirmLabel: "Yes",
              title: "Inventory Move count exceeds 25",
              confirmMessage: "Are you sure you want to move this inventory?",
              cancelLabel: "No",
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result === true) {
              let data = {
                hum: JSON.parse(JSON.stringify(this.humToMove)),
              };
              // open the bottom sheet
              this.bottomSheet.open(InventoryMoveLocationPopupComponent, {
                data,
              });
            }
          });
      } else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.humToMove)),
        };
        // open the bottom sheet
        this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });
      }
    } else {
      if (this.handlingUnitBulkMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog
          .open(ConfirmationDialogComponent, {
            minWidth: "10%",
            data: {
              confirmLabel: "Yes",
              title: "Inventory Move count exceeds 25",
              confirmMessage: "Are you sure you want to move this inventory?",
              cancelLabel: "No",
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result === true) {
              let data = {
                hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove)),
              };
              // open the bottom sheet
              this.bottomSheet.open(InventoryMoveLocationPopupComponent, {
                data,
              });
            }
          });
      } else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove)),
        };
        // open the bottom sheet
        this.bottomSheet.open(InventoryMoveLocationPopupComponent, { data });
      }
    }

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef
      .afterDismissed()
      .subscribe((job) => {
        if (job) {
          let savedHumIds =
            this.session.handlingUnitBulkMoveList[0].handlingUnitMasterIds;
          let any = this.session.handlingUnitBulkMoveList.filter(
            (x) => !this.humToMove.handlingUnitMasterIds.includes(x.humId)
          );
          this.session.handlingUnitBulkMoveList = any;
          this.handlingUnitBulkMove = any[0];
          this.handlingUnitBulkMove.handlingUnitMasterIds = savedHumIds;

          bottomSheetSubscription.unsubscribe();
          this.changeDetRef.detectChanges();
          // this.router.navigateByUrl("/harvest-engine/laboratory/technician-dashboard");
        }
      });
  }

  setPrinter(printer: PrinterDTO) {
    this.printer = printer;
  }

  printInventory() {
    // CHECK TO SEE IF THERE ARE SELECTED RECORDS, IF SO, PASS SELECTED RECORDS INTO INVENOTORY MOVE POPUP
    this.rowSelected(null);

    if (this.humToMove.handlingUnitMasterIds.length > 0) {
      if (this.humToMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog
          .open(ConfirmationDialogComponent, {
            minWidth: "10%",
            data: {
              confirmLabel: "Yes",
              title: "Inventory Print count exceeds 25",
              confirmMessage: "Are you sure you want to print this inventory?",
              cancelLabel: "No",
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result === true) {
              let data = {
                hum: JSON.parse(JSON.stringify(this.humToMove)),
              };
              // send print request
              this.isLoading = true;
              this.inventoryService
                .bulkReprintInventoryHUM(
                  this.humToMove.handlingUnitMasterIds,
                  this.printer.id
                )
                .subscribe(
                  (x) => {
                    this.toastr.success("Inventory printed", "SUCCESS");
                    this.isLoading = false;
                  },
                  (err) => {
                    this.isLoading = false;
                    this.errorService.handleError(err);
                  }
                );
            }
          });
      } else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.humToMove)),
        };
        // send print request
        this.isLoading = true;
        this.inventoryService
          .bulkReprintInventoryHUM(
            this.humToMove.handlingUnitMasterIds,
            this.printer.id
          )
          .subscribe(
            (x) => {
              this.toastr.success("Inventory printed", "SUCCESS");
              this.isLoading = false;
            },
            (err) => {
              this.isLoading = false;
              this.errorService.handleError(err);
            }
          );
      }
    } else {
      if (this.handlingUnitBulkMove.handlingUnitMasterIds.length > 25) {
        const confRef = this.dialog
          .open(ConfirmationDialogComponent, {
            minWidth: "10%",
            data: {
              confirmLabel: "Yes",
              title: "Inventory Print count exceeds 25",
              confirmMessage: "Are you sure you want to print this inventory?",
              cancelLabel: "No",
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result === true) {
              let data = {
                hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove)),
              };
              // send print request
              this.isLoading = true;
              this.inventoryService
                .bulkReprintInventoryHUM(
                  this.handlingUnitBulkMove.handlingUnitMasterIds,
                  this.printer.id
                )
                .subscribe(
                  (x) => {
                    this.toastr.success("Inventory printed", "SUCCESS");
                    this.isLoading = false;
                  },
                  (err) => {
                    this.isLoading = false;
                    this.errorService.handleError(err);
                  }
                );
            }
          });
      } else {
        let data = {
          hum: JSON.parse(JSON.stringify(this.handlingUnitBulkMove)),
        };
        // send print request
        this.isLoading = true;
        this.inventoryService
          .bulkReprintInventoryHUM(
            this.handlingUnitBulkMove.handlingUnitMasterIds,
            this.printer.id
          )
          .subscribe(
            (x) => {
              this.toastr.success("Inventory printed", "SUCCESS");
              this.isLoading = false;
            },
            (err) => {
              this.isLoading = false;
              this.errorService.handleError(err);
            }
          );
      }
    }
  }

  myFunction() {
    //console.log("myFunction");
  }

  removeInventory() {
    const confRef = this.dialog
      .open(RemoveInventoryConfirmationComponent, {
        minWidth: "10%",
        data: {},
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == true) {
          this.isLoading = true;
          this.rowSelected(null);

          if (this.humToMove.handlingUnitMasterIds.length > 0) {
            //console.log("HumToMove");
            //console.log(this.humToMove);
            this.bulkRemoveInventory.handlingUnitMasterIds =
              this.humToMove.handlingUnitMasterIds;
          } else {
            //console.log("handlingUnitBulkMove");
            //console.log(this.handlingUnitBulkMove);
            this.bulkRemoveInventory.handlingUnitMasterIds =
              this.handlingUnitBulkMove.handlingUnitMasterIds;
          }

          this.bulkRemoveInventory.reasonCodeId = 0;

          if (result.transactionReasonCodeId) {
            this.bulkRemoveInventory.reasonCodeId =
              result.transactionReasonCodeId;
          }

          //console.log("BulkRemoveInventory");
          //console.log(this.bulkRemoveInventory)

          this.inventoryService
            .bulkDeleteHandlingUnitMasters(this.bulkRemoveInventory)
            .subscribe(
              (x) => {
                let savedHumIds =
                  this.session.handlingUnitBulkMoveList[0]
                    .handlingUnitMasterIds;
                let any = this.session.handlingUnitBulkMoveList.filter(
                  (x) => !this.humToMove.handlingUnitMasterIds.includes(x.humId)
                );
                this.session.handlingUnitBulkMoveList = any;
                this.handlingUnitBulkMove = any[0];
                this.handlingUnitBulkMove.handlingUnitMasterIds = savedHumIds;

                this.toastr.success("Items removed from inventory", "SUCCESS");
                this.isLoading = false;
              },
              (err) => {
                this.isLoading = false;
                this.errorService.handleError(err);
              }
            );
        }
      });
  }

  back() {
    // this.router.navigateByUrl("/harvest-engine/laboratory/technician-dashboard");
    this.navigationService.back();
  }

  rowSelected(data) {
    this.selectedRecords = [];
    this.humToMove.handlingUnitMasterIds = [];

    //console.log(this.grid.getSelectedRecords());

    let dataToMove = this.grid.getSelectedRecords();

    if (dataToMove.length == 0)
      this.moveCount = this.session.handlingUnitBulkMoveList.length;
    else this.moveCount = dataToMove.length;

    for (let x of dataToMove) {
      this.selectedRecords.push(x);
    }

    for (let y of this.selectedRecords) {
      this.humToMove.handlingUnitMasterIds.push(y.humId);
    }

    this.humToMove.isMultiple = true;
  }

  onboxEndChange(e: any, data: any) {
    if (!!data) {
      let findIdx = this.session.handlingUnitBulkMoveList.findIndex(
        (x) => x.humId == data.humId
      );
      if (Number(findIdx) >= 0) {
        data.boxStart = !isNaN(Number(e) - 25) ? Number(e) - 25 : 0
        this.session.handlingUnitBulkMoveList[findIdx].boxStart = data.boxStart;
        this.session.handlingUnitBulkMoveList[findIdx].boxEnd = e;
      }

      // setTimeout(() => {
      //   this.grid.refresh();
      //   //Hide and show both column based on isHops value.
      //   this.hideColumn();
      // }, 1000);
    }
  }

  onboxStartChange(e: any, data: any) {
    if (!!data) {
      // let index = data.index;
      let findIdx = this.session.handlingUnitBulkMoveList.findIndex(
        (x) => x.humId == data.humId
      );
      if (Number(findIdx) >= 0) {
        this.session.handlingUnitBulkMoveList[findIdx].boxStart = e;
      }
    }
  }

  onDataBound() {
    if (this.grid) {
      if (this.session.handlingUnitBulkMoveList.length > 0) {
        let arr = this.session.handlingUnitBulkMoveList;
        this.allEqual = arr.every(
          (val) => val.integrationId == arr[0].integrationId
        );
        if (this.allEqual) {
          this.getInitialData(arr[0]);
        } else {
          this.getInitialData();
        }
        this.moveCount = this.session.handlingUnitBulkMoveList.length;
      }
    }
  }

  //Hide and show both column based on isHops value.
  hideColumn() {
    if (this.isHops) {
      (this.grid as GridComponent).getColumnByField("boxStart").visible = true;
      (this.grid as GridComponent).getColumnByField("boxEnd").visible = true;
    } else {
      (this.grid as GridComponent).getColumnByField("boxStart").visible = false;
      (this.grid as GridComponent).getColumnByField("boxEnd").visible = false;
    }
  }

  onSave() {

    //Validation
    if (!this.allEqual) {
      if (!this.integrationId) {
        this.toastr.danger("Please add Secondary Lot Code!");
        return;
      } else if (!this.dateIn) {
        this.toastr.danger("Please add Date In!");
        return;
      } else if (!this.dateOut) {
        this.toastr.danger("Please add Date Out!");
        return;
      }
    }

    //Validation to allow numbers only
    // if(!!this.editForm && this.editForm.invalid){
    //   this.toastr.danger("Pease enter number only!");
    //   return;
    // }

    let allHumSelectedData: any[] = [];
    if (
      this.session.handlingUnitBulkMoveList.length > 0 &&
      this.allHUMData.length > 0 && this.session.handlingUnitBulkMoveList.length == this.allHUMData.length
    ) {
      let ids = this.session.handlingUnitBulkMoveList.filter(x=>!!x.boxEnd || !!x.boxStart).map((x) => x.humId) || [];
      if (ids.length > 0) {
        allHumSelectedData = this.allHUMData.filter((x) => ids.includes(x.id));
      }
      allHumSelectedData.forEach((hum) => {
        let selectedObj = this.session.handlingUnitBulkMoveList.find(
          (x) => x.humId == hum.id
        );
        hum.boxStart = selectedObj.boxStart;
        hum.boxEnd = selectedObj.boxEnd;
        hum.integrationId = this.integrationId;
        hum.dateIn = this.dateIn.toLocaleDateString("default", {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
        });
        if(this.dateOut){
          hum.dateOut = this.dateOut.toLocaleDateString("default", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
          });
        }
      });
      this.isLoading = true;

      // let APIS = allHumSelectedData.map((p) =>
      //   this.inventoryService.putInventoryHUM(p)
      // );

      this.inventoryService.updateHotRoomBulk(allHumSelectedData).subscribe(
        (x) => {
          this.toastr.success("Successfully Saved!", "Success");
          this.isLoading = false;
          this.allHUMData = [];
          this.getAllIntegrationId();
        },
        (err) => {
          this.isLoading = false;
          this.errorService.handleError(err);
        }
      );
    } else {
      this.isLoading = true;
      let ids = this.session.handlingUnitBulkMoveList.filter(x=>!!x.boxEnd || !!x.boxStart).map((x) => x.humId) || [];

      //Get Inventory by hum Id
      let APIS = ids.map((p: any) => this.inventoryService.getInventoryHUM(p));

      forkJoin(APIS).subscribe(
        (response) => {
          this.isLoading = false;
          this.allHUMData = response;
          this.onSave();
        },
        (err) => {
          this.isLoading = false;
          this.errorService.handleError(err);
        }
      );
    }
  }

  loadAllData() {
    let ids: number[] = [];
    if (
      !!this.session.handlingUnitBulkMoveList &&
      this.session.handlingUnitBulkMoveList.length > 0
    )
      ids = this.session.handlingUnitBulkMoveList
        .map((x) => x.humId)
        .filter((v) => !!v);
    this.inventoryService.getAllHUMbyId(ids).subscribe(
      (data: any) => {
        this.allHUMData = data || [];
        // this.optionsForSecondaryCodes = data.map(x=>x.integrationId).filter(v=>!!v);
      },
      (err) => {
        this.errorService.handleError(err);
      }
    );
  }

  scan() {
    const dialogRef = this.dialog.open(InventoryScanPopupComponent, {
      minWidth: "30%",
      data: {
        Hums: [],
        isHumEdit: false,
        disableToggle: true,
        isMultiple: true,
      },
    });

    dialogRef.afterClosed().subscribe((x) => {
      if (x.isHum) this.openDialog(x.hum);

      this.changeDetRef.detectChanges();
    });
  }

  openDialog(row: HandlingUnitMasterDTO) {
    this.router.navigateByUrl(
      "/harvest-engine/inventory/inventory-summary-popup?humId=" +
        row.id +
        "&isActive=" +
        row.isActive
    );
  }

  getIsHops() {
    this.isLoading = true;
    this.platformConfigService.getIsHops().subscribe(
      (value) => {
        this.isHops = value;
        this.isLoading = false;
        this.hideColumn();
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  getAllIntegrationId() {
    this.isLoading = true;
    this.inventoryService.getAllIntegrationId().subscribe(
      (value) => {
        this.allIntegrationIds = value || [];
        this.allIntegrationIds = this.allIntegrationIds.filter((x) => !!x);
        //Remove duplicate Integration Id
        this.allIntegrationIds = this.allIntegrationIds.filter(
          (li, idx, self) => self.indexOf(li) === idx
        );
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  onSelectionChange(value) {
    this.filteredOptions = this._filter(value || "");
  }

  //return matching data from list
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allIntegrationIds
      .filter((x) => !!x)
      .filter((option) => option.toLowerCase().includes(filterValue));
  }

  onIDSearch(data: string) {
    this.filteredSearchOptions = this._filter(data || "");
    //this.idChangeSubject.next(data);
  }

  // Get all dInentory based on searched integration id
  searchDatabyIntegrationId() {
    let data = this.searchIntegrationId;
    if (!!data) {
      this.inventoryService
        .getAllByIntegrationId(data)
        // .pipe(takeUntil(this.idChangeSubject))
        .subscribe((results: HandlingUnitMasterDTO[]) => {
          let moveList = [];
          if (!!results && results.length > 0) {
            results.forEach((x) => {
              let obj = {
                humId: x.id,
                warehouseId: null,
                roomId: null,
                roomLocationId: null,
                roomLocationName: x.roomLocationName,
                roomName: x.roomName,
                warehouseName: x.warehouseName,
                tagNumber: x.barcode,
                details:
                  x.handlingUnitDetails.length > 0
                    ? x.handlingUnitDetails[0].itemName
                    : "",
                lot:
                  x.handlingUnitDetails.length > 0
                    ? x.handlingUnitDetails[0].lotNumber
                    : "",
                isMultiple: true,
                boxStart: x.boxStart ? x.boxStart : "",
                boxEnd: x.boxEnd ? x.boxEnd : "",
                integrationId: x.integrationId ? x.integrationId : "",
                dateIn: x.dateIn ? x.dateIn : "",
                dateOut: x.dateOut ? x.dateOut : "",
              };
              moveList.push(obj);
            });
          }

          if (moveList.length > 0) {
            this.session.handlingUnitBulkMoveList = moveList;
            this.loadAllData();
            // this.getInitialData();
            this.getAllIntegrationId();
          } else {
            this.session.handlingUnitBulkMoveList = [];
          }
        });
    }
  }

  actionBegin(args: SaveEventArgs): void {
    if (args.requestType === "save") {
      if (this.editForm.valid) {
        this.session.handlingUnitBulkMoveList.forEach((x) => {
          if (x.humId == args.data["humId"]) {
            x = args.data;
          }
        });
      }
      //  else {
      //   args.cancel = true;
      // }
    }
  }

  public focusIn(event: FocusEvent): void {
    (event.target as HTMLElement).classList.add("e-input-focus");
  }

  public focusOut(event: FocusEvent): void {
    (event.target as HTMLElement).classList.remove("e-input-focus");
  }

  actionComplete(args: any): void {
    // Set initial focus
    if (args.requestType === "beginEdit") {
      (
        (args.form as HTMLFormElement).elements.namedItem(
          "boxEnd"
        ) as HTMLInputElement
      ).focus();
    }
  }

  load() {
    (this.grid as GridComponent).element.addEventListener("mouseup", (e) => {
      if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
        if ((this.grid as GridComponent).isEdit)
          (this.grid as GridComponent).endEdit();
        let index: number = parseInt(
          (e.target as HTMLElement).getAttribute("Index") as string
        );
        if (index >= 0) {
          (this.grid as GridComponent).selectRow(index);
          (this.grid as GridComponent).startEdit();
        }
      }
    });

    (this.grid as GridComponent).element.addEventListener("keydown", (e:any) => {
      //ArrowDown press
      if (
        e.keyCode === 40) {
        this.editACell(e,+1);
      }

      //ArrowUp press
      if(e.keyCode === 38){
        this.editACell(e,-1);
      }
    });
  }

  private editACell(e: any,num:number) {
    let row = parentsUntil(e.target, 'e-row', false);
          let Index = Number(row.getAttribute('aria-rowindex'));
          if ((e.target as HTMLElement).id == 'boxStart' || (e.target as HTMLElement).id == 'boxEnd') {
            if ((this.grid as GridComponent).isEdit)
              (this.grid as GridComponent).endEdit();

          if (Index >= 0) {
            (this.grid as GridComponent).selectRow(Index+num);
            (this.grid as GridComponent).startEdit();
          }
        }
  }

  ngOnDestroy() {
    this.navigationSub$.unsubscribe();
  }
}
