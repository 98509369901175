import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { FarmsTableDataSource } from './farms-table-datasource';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FarmsService } from '../../../services/farms.service';
import { FarmDTO } from '../../../models/farm';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FilterResults } from '../../../widgets/kw-filter-dropdown/models/filter-results';
import { ColumnModel, FilterSettingsModel, GridComponent, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { KmlService } from '../../../../../@core/utils/kml.service';
import { Observable } from 'rxjs';
import { ConfirmationData, ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'kw-farms-table',
  templateUrl: './farms-table.component.html',
  styleUrls: ['./farms-table.component.css']
})
export class FarmsTableComponent implements OnInit {
  @Output() loaded = new EventEmitter();
  @Output() farmSelected = new EventEmitter<FarmDTO>();

  //#region Properties
  public allFarms: FarmDTO[] = [];
  public isLoading: boolean;
  public faCheck = faCheck;
  public faTimes = faTimes;
  public enablePersistence: boolean = true;
  public filterResults: FilterResults;
  dataSource: FarmsTableDataSource;
  public pageSettings: PageSettingsModel = {
    pageSize: 200,
    pageSizes: [50, 100, 200],
  };

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel",
  };
  public toolbarOptions: string[] = [
    "ExcelExport",
    "KML Export",
    "JSON Export",
  ];
  public allColumns: ColumnModel[] = [];
  public gridName: string = "BlocksConfigFilters";
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'blocks', 'address', 'city', 'state', 'zip', 'integrationId', 'active', 'edit'];
  //#endregion

  //#region Icon Properties
  public faPen = faPen;
  public faTrash = faTrash;
  //#endregion

  //#region ViewChild Properties
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild("grid")
  public grid: GridComponent;

  @ViewChild("farmActionTemplate")
  public farmActionTemplate: any;
  //#endregion

  //#region Constructor with dependencies
  constructor(
    private farmService: FarmsService,
    private errorService: ErrorService,
    private kmlService: KmlService,
    private dialogService: MatDialog,
    private toastrService: NbToastrService
  ) { }
  //#endregion

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    // let self = this;
    this.isLoading = true;
    Observable.forkJoin([
      this.farmService.apiFarmsGet(),
    ]).subscribe((x) => {
      this.allFarms = x[0];
      this.grid.dataSource = this.allFarms;

      this.isLoading = false;
      this.loaded.emit(false);
      this.setColumns();
    }),
    (err) => {
      this.errorService.handleError(err);
      this.isLoading = false;
    };
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Name",
        },
        {
          field: "streetAddress",
          headerText: "Address",
        },
        {
          field: "city",
          headerText: "City",
        },
        {
          field: "state",
          headerText: "State",
        },
        {
          field: "zipCode",
          headerText: "Zip",
        },
        {
          field: "integrationId",
          headerText: "Integration Id",
        },
        {
          headerText: "Action",
          template: this.farmActionTemplate,
          width: 200,
        }
      ]
    );
  }

  toolbarClick(args) {
    this.isLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "KML Export") {
      this.kmlService.getAllKML(new Date().getFullYear());
      this.isLoading = false;
    } else if (args.item.properties.text == "JSON Export") {
      this.kmlService.getGeoJSONPolygons();
      this.isLoading = false;
    } else {
      this.grid
        .excelExport({
          fileName: `FarmsExport.xlsx`,
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }

  public editClicked(farm: FarmDTO) {
    this.farmSelected.emit(farm);
  }

  confirmDelete(id: number) {
    let self = this;
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this Farms?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        self.isLoading = true;
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.farmService.apiFarmsByIdDelete(id).subscribe(
      () => {
        this.toastrService.success("Farms successfully deleted!");
        // setTimeout(() => {
        // this.isLoading = false;
        this.loadData();
        // }, 1000);
      },
      (error) => {
        this.errorService.handleError(error);
        console.error(error);
      }
    );
  }
}
