import { EventEmitter, Injectable, Output } from '@angular/core';
import { Feature, FeatureCollection } from 'geojson';
import { FieldEventDTO } from '../../field-events/models/field-event';
import * as moment from 'moment-timezone';
import { FilterResults } from '../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { FieldEventType } from '../../field-events/models/field-event-type';
import * as _ from 'underscore';
import * as mapboxgl from 'mapbox-gl';
import { FieldEventDetailsComponent } from '../../../../pages/field-events/widgets/field-event-details/field-event-details.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class FieldEventMarkerService {

  private fieldEventMarkers = [];
  private fieldEventOnScreen = [];

  public fieldEventClick: boolean;
  private allFieldEvents: any;


  @Output() pointClicked: EventEmitter<FieldEventDTO> = new EventEmitter<FieldEventDTO>();

  private map: any;
  private fieldEventClusterMarkers: mapboxgl.Marker[] = [];
  private fieldEventClusterMarkersOnScreen: mapboxgl.Marker[] = [];
  constructor(
    private popup: MatDialog,
  ) { }

  public getAllMarkersOnScreen() {
    return [...this.fieldEventOnScreen, ...this.fieldEventClusterMarkersOnScreen];
  }

  public async initializeMarkers(map, fieldEvents: FieldEventDTO[], //filterResults: FilterResults

  ) {
    this.map = map;
    this.allFieldEvents = fieldEvents;

    for (let clusterMarker of this.fieldEventClusterMarkersOnScreen) {
      if (clusterMarker)
        clusterMarker.remove();
    }

    for (let marker of this.fieldEventOnScreen) {
      if (marker)
        marker.remove();
    }


    this.fieldEventClusterMarkers = [];
    this.fieldEventClusterMarkersOnScreen = [];
    this.fieldEventMarkers = [];
    this.fieldEventOnScreen = [];


    if (this.map.getLayer('fieldEventPointLayer'))
      this.map.removeLayer('fieldEventPointLayer');

    if (this.map.getSource("fieldEventSource"))
      this.map.removeSource("fieldEventSource");

    let eventFeatureCollection = <FeatureCollection>{
      type: 'FeatureCollection',
      features: []
    };


    let filteredFieldEvents = fieldEvents.filter(x => x.longitude &&
      x.latitude &&
      +x.latitude != 0 &&
      +x.longitude != 0 //&&
      //moment(x.createdDate).year() == +filterResults.selectedCropYears[0].itemName
    );


    if (filteredFieldEvents.length > 0) {

      filteredFieldEvents.forEach(fieldEvent => {
        let feature = <Feature>{
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [+fieldEvent.longitude, +fieldEvent.latitude]
          },
          properties: {
            ...fieldEvent,
            fieldEventTypeId: fieldEvent.fieldEventType.id,
            icon: fieldEvent.fieldEventType.fontAwesomeIconName,
            'marker-color': fieldEvent.fieldEventType.iconColor,
          }
        };

        eventFeatureCollection.features.push(feature);
      });
    }

    let uniqueTypes = _.uniq(filteredFieldEvents.map(x => x.fieldEventType.id));


    let source: mapboxgl.AnySourceData = {
      type: 'geojson',
      data: eventFeatureCollection,
      cluster: true,
      clusterRadius: 20,
      clusterProperties: uniqueTypes.reduce((current, type) => {
        current[type] = ['+', ['case', ['==', ['get', 'fieldEventTypeId'], type], 1, 0]];
        return current;
      }, {})
    };

    this.map.addSource('fieldEventSource', source);

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    this.map.on('render', () => {
      if (!this.map.isSourceLoaded('fieldEventSource')) return;
      this.updateFieldEventMarkers();
      this.setFieldEventMarkers();
    });
  }


  private async setFieldEventMarkers() {

    if (this.map.getSource("fieldEventSource")) {


      // circle and symbol layers for rendering individual events (unclustered points)
      if (!this.map.getLayer('fieldEventPointLayer'))
        this.map.addLayer({
          'id': 'fieldEventPointLayer',
          //'type': 'circle',
          'source': 'fieldEventSource',
          'type': 'symbol'
        });


      const newMarkers = [];
      const features = this.map.querySourceFeatures('fieldEventSource');

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {

        const coords = feature.geometry['coordinates'];
        const props = feature.properties;

        if (!props.hasOwnProperty('cluster') || !props.cluster) {


          const id = props.id;

          let marker = this.fieldEventMarkers[id];
          if (!marker) {

            //let html = `<svg  width="${20}" height="${20} x="0px" y="-20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512"><path fill="${props['marker-color']}" d="M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z"></path></svg>`;
            let html = `<img style="cursor: pointer;" src="https://cdn.mapmarker.io/api/v1/font-awesome/v5/pin?icon=${props['icon']}&background=${props['marker-color'].replace('#', '')}&color=FFF&size=30&hoffset=0&voffset=-1">`;
            const el = document.createElement('div');
            el.innerHTML = html;

            marker = this.fieldEventMarkers[id] = new mapboxgl.Marker(el, {}).setLngLat(coords);

            marker.getElement().addEventListener('click', () => {
              this.fieldEventClick = true;


              let fieldEvent = this.allFieldEvents.find(x => x.id == props['id']);
              this.pointClicked.emit(fieldEvent);




            });
            // marker = fieldEventMarkers[id] = new mapboxgl.Marker({
            //   color: feature.properties['marker-color']
            // }).setLngLat(coords);
          }

          newMarkers[id] = marker;

          if (!this.fieldEventOnScreen[id]) marker.addTo(this.map);
        }

      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in this.fieldEventOnScreen) {
        if (!newMarkers[id]) this.fieldEventOnScreen[id].remove();
      }


      this.fieldEventOnScreen = newMarkers;


    }
  }

  private updateFieldEventMarkers() {
    const newMarkers = [];
    const features = this.map.querySourceFeatures('fieldEventSource');

    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
    // and add it to the map if it's not there already
    for (const feature of features) {

      const coords = feature.geometry['coordinates'];
      const props = feature.properties;

      if (props.cluster) {

        const id = props.cluster_id;

        let marker = this.fieldEventClusterMarkers[id];
        if (!marker) {
          const el = this.createFieldEventsDonutChart(props);

          marker = this.fieldEventClusterMarkers[id] = new mapboxgl.Marker(el, {}).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!this.fieldEventClusterMarkersOnScreen[id]) marker.addTo(this.map);
      }

    }
    // for every marker we've added previously, remove those that are no longer visible
    for (const id in this.fieldEventClusterMarkersOnScreen) {
      if (!newMarkers[id]) this.fieldEventClusterMarkersOnScreen[id].remove();
    }


    this.fieldEventClusterMarkersOnScreen = newMarkers;
  }

  private createFieldEventsDonutChart(props): HTMLElement {
    const offsets = [];
    let filteredEventTypes: FieldEventType[] = [];

    const counts = [];


    let fieldEventTypes: FieldEventType[] = _.uniq(this.allFieldEvents.map(x => x.fieldEventType), true, x => x.id);

    for (let key in props) {
      let includedFieldEventType = fieldEventTypes.find(x => x.id.toString() == key)
      if (includedFieldEventType) {
        filteredEventTypes.push(includedFieldEventType)
        counts.push(props[key]);
      }
    }

    let total = 0;

    for (const count of counts) {
      offsets.push(total);
      total += count;
    }
    const fontSize =
      total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
    const r =
      total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    let html = `<div>
  <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

    for (let i = 0; i < counts.length; i++) {
      html += this.donutSegment(
        offsets[i] / total,
        (offsets[i] + counts[i]) / total,
        r,
        r0,
        filteredEventTypes[i].iconColor
      );
    }
    html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
  <text dominant-baseline="central" transform="translate(${r}, ${r})">
  ${total.toLocaleString()}
  </text>
  </svg>
  </div>`;

    const el = document.createElement('div');

    el.innerHTML = html;

    return el;
  }

  private donutSegment(start, end, r, r0, color) {
    if (end - start === 1) end -= 0.00001;
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
    const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;

    // draw an SVG path
    return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
      } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
      } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
      }" fill="${color}" />`;
  }


}
