import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { UserDTO } from "../../harvest-engine/core/models/user";


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  private token: string;

  constructor(
    protected httpClient: HttpClient,
  ) {
    this.token = 'Bearer '.concat(localStorage.getItem('access_token'));
    this.defaultHeaders = this.defaultHeaders.set('Authorization', String(this.token));
    this.basePath = environment.PLATFORM_API_URL;
    
  }

  public apiUsersCreateUserPost(body?: UserDTO): Observable<UserDTO> {
    let headers = this.defaultHeaders;

    return this.httpClient.post(`${this.basePath}/api/Users/CreateUser`, body,
      {
        headers: headers
      }
    );
  }

  public apiUsersDeleteUserDelete(id?: number): Observable<any> {

    let headers = this.defaultHeaders;

    return this.httpClient.delete(`${this.basePath}/api/Users/DeleteUser/${id}`,
      {
        headers: headers,
      }
    );
  }

  public apiUsersGet(): Observable<UserDTO[]> {

    let headers = this.defaultHeaders;

    return this.httpClient.get<UserDTO[]>(`${this.basePath}/api/Users`,
      {
        headers: headers,
      }
    );
  }

  public apiUserGet(platformUserId: number): Observable<UserDTO> {

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/Users/${encodeURIComponent(String(platformUserId))}`,
      {
        headers: headers,
      }
    );
  }

  public apiUsersGetCurrentUserGet(): Observable<UserDTO> {

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/Users/GetCurrentUser`,
      {
        headers: headers,
      }
    );
  }

  public apiUsersUpdateUserPost(body?: UserDTO, updatePassword?: boolean,): Observable<UserDTO> {

    let queryParameters = new HttpParams();
    if (updatePassword !== undefined && updatePassword !== null) {
      queryParameters = queryParameters.set('updatePassword', <any>updatePassword);
    }

    let headers = this.defaultHeaders;

    return this.httpClient.put(`${this.basePath}/api/Users/UpdateUser`, body,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }


  public apiRolesByIdGet(id: number): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRolesByIdGet.');
    }

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/Roles/${encodeURIComponent(String(id))}`,
      {
        headers: headers
      }
    );
  }

  public apiRolesGet(): Observable<any> {

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/Roles`,
      {
        headers: headers,
      }
    );
  }


  public apiGroupRolesGet(): Observable<any> {

    let headers = this.defaultHeaders;

    return this.httpClient.get(`${this.basePath}/api/GroupRoles`,
      {
        headers: headers,
      }
    );
  }

  //Get list of all user tenant
  public getUserTenant(): Observable<any> {
    let headers = this.defaultHeaders;
    return this.httpClient.get(`${this.basePath}/api/Users/tenants`, {
      headers: headers,
    });
  }

  //Update user tenant based on selected tenant id
  public updateTenant(tenantId: string): Observable<any> {
    let headers = this.defaultHeaders;
    return this.httpClient.put(
      `${this.basePath}/api/Users/ChangeTenant/${tenantId}`,{},
      {
        headers: headers,
      }
    );
  }


}