import { Component, OnInit, ViewChild, ElementRef, ViewChildren, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { ErrorService } from '../../../../@core/utils/error.service'
import { OrdersService } from '../../../../customer-integrations/hollingbery/modules/financial/orders/services/orders.service';
import { Customer } from '../models/customer';
import { Observable } from 'rxjs/Rx';
import * as _ from 'underscore';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { PackingOption } from '../../../../customer-integrations/hollingbery/models/support/packing-option';
import { SupportService } from '../services/support.service';
import { Cart, CartAmended, CartItem, ContractTemplate } from '../models/cart';
import { CartApiService } from '../../../../customer-integrations/hollingbery/modules/financial/orders/services/cart.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
import { PositionReportService } from '../../../../customer-integrations/hollingbery/modules/financial/position-report/services/position-report.service';
import { US_STATES } from '../../../../@core/data/us-state-list';
import { FormControl, NgForm } from '@angular/forms';
import { faArrowAltRight, faArrowAltSquareRight, faArrowSquareRight, faPlusCircle, faPlusSquare, faSortSizeDownAlt, faSortSizeUpAlt } from '@fortawesome/pro-solid-svg-icons';
import { ItemsService } from '../../../../customer-integrations/hollingbery/modules/financial/orders/services/items.service';
import { Items } from '../../../../customer-integrations/hollingbery/modules/financial/orders/models/items';
import { ContractService } from '../services/contract.service';
import { SelectionsContract } from '../../../../customer-integrations/hollingbery/modules/financial/contracts/models/selections-contract';
import { NbToastrService } from '@nebular/theme';
import { NbToastrConfig } from '@nebular/theme/components/toastr/toastr-config';
import { CropVariety } from '../../../core/models/crop-variety';
import { InventoryPackageTypeDTO } from '../../inventory/models/inventory-package-type';
import { ContractDTO } from '../models/contract';
import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PDFService } from '../services/pdf.service';
import { ContractLinesService } from '../services/contractLines.service'
import { InventoryService } from '../../pick-plan/services/inventory.service';
import { InventoryItemDTO } from '../../inventory/models/item';
import { currencyList } from '../models/currencyList';




@Component({
  selector: 'contract-ammend',
  templateUrl: './contract-ammend.component.html',
  styleUrls: ['./contract-ammend.component.scss']
})

export class ContractAmmendComponent implements OnInit, OnChanges {

  public faEdit = faEdit;
  public faTrash = faTrash;
  public faPlus = faPlusCircle;
  public faSortSizeUpAlt = faSortSizeUpAlt;
  public faSortSizeDownAlt = faSortSizeDownAlt;
  faArrowAltSquareRight = faArrowAltSquareRight;
  faArrowAltRight = faArrowAltRight;
  faArrowSquareRight = faArrowSquareRight;
  public ExpirationDate: Date = new Date();
  public EffectiveDate: Date = new Date();

  public cart: Cart = {
    items: [],
    shippingAddress: {},
    
  };


  public listOfItems: Items[] = [];
  public selectedLineIndex: number = -1;
  allContracts: SelectionsContract[] = [];
  contractList: SelectionsContract[] = [];
  public customerList: Customer[] = [];
  public allCustomers: Customer[] = [];
  public searchCustomer: string = '';
  public searchItemNumber: string = '';
  public searchItemDesc: string = '';

  public isItemAsc: boolean = false;
  public isContractYearAsc: boolean = false;
  public isVarietyAsc: boolean = false;
  public isCropYearAsc: boolean = false;
  public isPkgTypeAsc: boolean = false;
  public isPkgSizeAsc: boolean = false;
  public isContractAmountAsc: boolean = false;
  public isContractTypeAsc: boolean = false;

  public varieties: CropVariety[] = [];
  public contractTemplates: ContractTemplate[] = [];
  public inventoryItems: InventoryItemDTO[] = [];
  public itemList: InventoryItemDTO[] = [];
  public currencyList = [];

  public packages: InventoryPackageTypeDTO[];
  public isLoading: boolean = true;

  public states = US_STATES;
  public isSubmitted: boolean = false;
  public setItemFocus: boolean = true;

  // public order: Order = {};
  public allItems: Items[] = [];

  public paramId: string;
  public isEdit: boolean = false;
  

  // @ViewChild('orderForm') public form: NgForm;

  constructor(
    private customerService: CustomerService,
    private errorService: ErrorService,
    private orderService: OrdersService,
    private supportServices: SupportService,
    private pdfService: PDFService,
    private contractLineService: ContractLinesService,
    private cartService: CartApiService,
    private router: Router,
    private session: SessionService,
    private positionReportService: PositionReportService,
    private itemsService: ItemsService,
    private contractService: ContractService,
    private inventoryService: InventoryService,
    private tostrService: NbToastrService,
    private route: ActivatedRoute,
  ) {
    this.ExpirationDate.setDate(this.ExpirationDate.getDate() + 14);
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.setItemFocus) {
      document.getElementById('itemInput').focus();
      this.setItemFocus = false;
    }
  }

  ngOnInit() {
    
    this.loadAll();
    this.route.queryParamMap.subscribe(params => {
      this.paramId = params.get('contractId');
      this.isEdit = params.get('edit') == 'true';
    });
    pdfmake.vfs = pdfFonts.pdfMake.vfs;

   const keys = Object.keys(currencyList)
   this.currencyList = keys

  }
  preLoadfields(){
    let contract = this.session.contract
    contract.contractLine.sort((a, b) => {
      return a.timeCreated > b.timeCreated ? 1 : -1
    })

    if(Object.keys(contract.contractLine).length > 0){
      contract.contractLine.forEach((item) => {
        // let varietyObj = this.varieties.find(v => v.id == item.varietyId)
        let inventoryObj = this.inventoryItems.find(i => i.id == item.inventoryItemId)
        // let packageObj = this.packages.find(p => p.id == item.packageId)
        this.cart.items.push({
          id: item.id,
          // varietyObj: varietyObj,
          // packageObj: packageObj,
          inventoryItem: inventoryObj,
          cropYear: item.cropYear,
          quantity: this.isEdit ? item.quantityPrevious : item.quantity,
          quantityAmend: item.quantity,
          unitOfMeasure: item.unitOfMeasure,
          price: this.isEdit ? item.pricePrevious : item.price,
          priceAmend: item.price,
          currency: item.currency,
        })

    })
  }

  // if(contract.parentId && this.isEdit){
  //   console.log('HERRRREEEE')
  //   console.log(contract)
  //     this.contractLineService.getContractLinesByContractId(contract.parentId).subscribe(y => {
  //       console.log(y)
  //     //   this.cart.items.forEach((item, idx) => {
  //     //     item.price = y.

  //     // })
  //   })
  // }

    this.cart.customerId = contract.customerId
    this.cart.contractYear = contract.contractYear
    this.cart.contractTemplate = this.contractTemplates.find(c => c.name == 'Amendment')
    this.cart.contractTemplate.otherRemarks = contract.otherRemarks
    this.EffectiveDate = contract.effectiveDate
    this.ExpirationDate = contract.expirationDate
    
    this.filterProducts();
    this.filterCustomers();
    this.onCustomerSelect();
    this.isLoading = false;
  }
  loadAll() {
    Observable.forkJoin([
      this.customerService.getCustomerList(),
      this.contractService.getContractTemplates(),
      this.inventoryService.getInventoryItems()
    ]).subscribe(values => {
      this.allCustomers = values[0];
      this.contractTemplates = values[1];
      this.inventoryItems = values[2];
      
      if(this.session.contract){
        this.preLoadfields()
      } else {
        this.filterCustomers();
        this.filterProducts();
        this.onCustomerSelect()
        this.addLine();
        this.isLoading = false;
      }
    }, err => this.errorService.handleError(err));
  }

  validateContract() {
    if(this.cart.contractTemplate && this.cart.contractTemplate.name == 'MSA'){
      if(this.cart.contractYear &&
        this.cart.shippingAddress &&
        this.cart.shippingAddress.company &&
        this.cart.customer &&
        this.cart.customer.id){
          this.saveContract()
      } else {
        alert('Please fill out all required fields')
      }
    } else {
      let valid = false;
      for(let i = 0; i < this.cart.items.length; i ++) {
        if(this.cart.items[i].inventoryItem &&
          this.cart.items[i].inventoryItem.id &&
          (this.cart.items[i].quantity || this.cart.items[i].quantity == 0) &&
          (this.cart.items[i].quantityAmend || this.cart.items[i].quantityAmend == 0) &&
          (this.cart.items[i].priceAmend || this.cart.items[i].priceAmend == 0) &&
          (this.cart.items[i].price || this.cart.items[i].price == 0) &&
          this.cart.items[i].currency) {
          valid = true;
        } else {
          valid = false;
          alert('Please fill out all required fields')
          break;
        }
      }
      if(valid) {
        this.saveContract()
      }
    }
  }

  saveContract() {

    // let cartItems = this.cart.items;
    // cartItems.map((item, idx) => {
    //   item.quantity = this.cart.items[idx].quantityAmend  
    //   item.price = this.cart.items[idx].priceAmend
    //   item.currency = this.cart.items[0].currency
    //   item.unitOfMeasure = this.cart.items[0].unitOfMeasure
    //   item.packageObj = this.cart.items[0].packageObj
    // })

    let body: ContractDTO = {
      // id: +this.paramId,
      contractYear: this.cart.contractYear, 
      contractStatus: 'Created',
      expirationDate: this.ExpirationDate,
      effectiveDate: this.EffectiveDate,
      customerId: this.cart.customerId, 
      customerName: this.cart.shippingAddress.company, 
      contractTemplateId: this.cart.contractTemplate.id, 
      contractTemplateName: this.cart.contractTemplate.name, 
      // comments: this.cart.userMessage,
      otherRemarks: this.cart.contractTemplate.otherRemarks,
      isActive: true,
      parentId: this.session.contract.parentId || +this.paramId,
    }
    let pdfPayload = {
      ContractYear: this.cart.contractYear,
      CurrentDate: new Date().toLocaleDateString('default', {month: '2-digit', day: '2-digit', year: '2-digit'}),
      ExpirationDate: new Date(this.ExpirationDate).toLocaleDateString('default', {month: '2-digit', day: '2-digit', year: '2-digit'}),
      EffectiveDate: new Date(this.EffectiveDate).toLocaleDateString('default', {month: '2-digit', day: '2-digit', year: '2-digit'}),
      Address: this.cart.shippingAddress,
      LineItems: this.cart.items,
      // LineItemsAmended: this.cart.itemsAmend,
      LineItemsAmended: this.cart.items,

      Verbiage: this.cart.contractTemplate.verbiage,
      LastId: this.paramId || 0,
      OtherRemarks: this.cart.contractTemplate.otherRemarks
    }

    if(this.paramId && this.session.contract && this.isEdit == true){
      this.contractService.getContractById(+this.session.contract.id).subscribe(x => {
        body = x;
        body.contractYear = this.cart.contractYear
        // body.contractStatus = 'Created'
        body.expirationDate = this.ExpirationDate
        body.effectiveDate = this.EffectiveDate
        body.customerId = this.cart.customerId
        body.customerName = this.cart.shippingAddress.company
        body.contractTemplateId = this.cart.contractTemplate.id
        body.contractTemplateName = this.cart.contractTemplate.name,
        body.comments = this.cart.userMessage
        body.otherRemarks = this.cart.contractTemplate.otherRemarks

      const contractPdf = this.pdfService.generateVerbiage(pdfPayload, null);
      body.contractVerbiage = JSON.stringify(contractPdf);
      this.contractService.putContract(body, +this.paramId).subscribe(x => {
        if(this.cart.items && Object.keys(this.cart.items[0]).length > 1){
          this.cart.items.map(async item => {
            if(item.id){
              this.contractLineService.getContractLinesById(item.id).subscribe(async y => {
                let lineItem = y;
                lineItem.varietyId = item.inventoryItem.varietyId
                // lineItem.varietyName = item.varietyObj.name
                lineItem.packageId = item.inventoryItem.packageTypeId
                // lineItem.packageName = item.packageObj.name
                lineItem.cropYear = item.cropYear
                lineItem.quantity = item.quantityAmend
                lineItem.quantityPrevious = item.quantity
                lineItem.price = item.priceAmend
                lineItem.pricePrevious = item.price
                lineItem.contractId = this.paramId
                lineItem.currency = item.currency
                lineItem.unitOfMeasure = item.unitOfMeasure
                lineItem.inventoryItemId = item.inventoryItem.id
                const result = await this.contractLineService.putContractLines(lineItem).toPromise();
              })
            } else {
              let postItem: any = item
              postItem.contractId = +this.paramId
              postItem.varietyId = item.inventoryItem.varietyId
              postItem.packageId = item.inventoryItem.packageTypeId
              // postItem.inventoryItemId = item.inventoryItem.id
              const result = await this.contractLineService.postContractLines(item).toPromise();
            }
          })
        }
        this.pdfService.downloadPDF(contractPdf)
        this.session.contract = null;
      })
    })
    } else {
      this.contractService.postContract(body).subscribe(x => {
        pdfPayload.LastId = x.id
        const contractPdf = this.pdfService.generateVerbiage(pdfPayload, this.session.contract.offerNumber);

        body = x
        body.contractVerbiage = JSON.stringify(contractPdf)
        body.offerNumber = this.session.contract.offerNumber
        this.contractService.putContract(body, x.id).subscribe(x => {
          // console.log(x)
        })

        this.saveContractLinesSequentially(x);
        
        this.pdfService.downloadPDF(contractPdf)
        this.session.contract = null;
      }, err => { this.errorService.handleError(err); console.log(err)});
    }
    setTimeout(() => {
      this.router.navigateByUrl(`/harvest-engine/contracts/contract-list`)
    },2000)
  }

 saveContractLinesSequentially = async(x) => {
    for (const item of this.cart.items) {
      let lineItem = {
        // id: null,
        varietyId: item.inventoryItem.varietyId == 0 ? null : item.inventoryItem.varietyId,
        // varietyName: item.varietyObj.name,
        packageId: item.inventoryItem.packageTypeId == 0 ? null : item.inventoryItem.packageTypeId,
        // packageName: item.packageObj.name,
        cropYear: item.cropYear,
        quantity: item.quantityAmend,
        quantityPrevious: item.quantity,
        price: item.priceAmend,
        pricePrevious: item.price,
        contractId: x.id,
        currency: item.currency,
        unitOfMeasure: item.unitOfMeasure,
        inventoryItemId: item.inventoryItem.id,

      }
      const result = await this.contractLineService.postContractLines(lineItem).toPromise();
    }
  }


  onExpirationChange(event) {
    this.ExpirationDate = event.value
  }
  onEffectiveChange(event){
    this.EffectiveDate = event.value
  }

  deleteLine(index) {
    this.cart.items.splice(index, 1);
    this.selectedLineIndex = this.cart.items.length - 1;
    this.addExistingItemToItemList();
  }

  editLine(index) {
    this.selectedLineIndex = index;
    // this.searchItemNumber = this.cart.items[this.selectedLineIndex].itemNumber;
    this.addExistingItemToItemList();
  }

  addLine() {
    let lineItem = this.cart.items[this.selectedLineIndex];

    let amendedLineItem = this.cart.items[this.selectedLineIndex];

      if (
            (
              lineItem
              && lineItem.inventoryItem 
              && (amendedLineItem.priceAmend || amendedLineItem.priceAmend == 0)
              && (amendedLineItem.quantityAmend || amendedLineItem.quantityAmend == 0)
            )
            || this.selectedLineIndex == -1
          ){
        this.cart.items.push({
          currency: 'USD',
          price: 0,
          quantity: 0,
          priceAmend: 0,
          quantityAmend: 0,
          inventoryItem: {name: '', description:''}
          
        });
        this.selectedLineIndex = this.cart.items.length - 1;
        this.setItemFocus = true;
      } else {
        alert('Missing fields')
      }
      

  }
  addLineItem(){
    // console.log(this.cart)
  }


  filterCustomers() {
    let filterCustomers = this.allCustomers.filter(x => x.id == this.cart.customerId || this.searchCustomer === '' || x.name.toLowerCase().includes(this.searchCustomer.toLowerCase()));

    if (filterCustomers.length > 20)
      this.customerList = [];
    else
      this.customerList = _.sortBy(filterCustomers, 'Name');

    // add selected brewery so it shows in the list
    if (this.cart && this.cart.customerId !== 0) {
      if (this.customerList.filter(x => x.id == this.cart.customerId).length === 0) {
        let selectedBrewery = this.allCustomers.filter(x => x.id == this.cart.customerId)[0];
        if (selectedBrewery) {
          this.customerList.push(selectedBrewery);
        }
      }
    }
  }

  onCustomerSelect() {
    let selectedCustomer = this.allCustomers.filter(x => x.id == this.cart.customerId)[0];

    if (selectedCustomer) {
      this.cart.customerId = selectedCustomer.id;
      this.cart.shippingAddress = {
        address: selectedCustomer.shipAddress,
        city: selectedCustomer.shipAddressCity,
        company: selectedCustomer.name,
        state: selectedCustomer.shipAddressState,
        zip: selectedCustomer.shipAddressPostalCode,
        phoneNumber: selectedCustomer.phone,
        email: selectedCustomer.email,
        faxNumber: selectedCustomer.faxNumber
      }

    }
  }

  comparePackages(o1: number, o2: number) {
    return o1 == o2;
  }

  onItemSelect(itemNumber: string) {
    let selectedItem = this.allItems.filter(x => x.parentRefFullName == itemNumber)[0];
    
    this.cart.items[this.selectedLineIndex].variety = selectedItem.customFieldVariety;
    this.cart.items[this.selectedLineIndex].cropYear = +selectedItem.customFieldCropYear;
  }


  addExistingItemToItemList() {
    if (this.cart && this.allItems && this.cart.items[this.selectedLineIndex]) {
      let selectedItem = this.allItems.filter(x => x.parentRefFullName == this.cart.items[this.selectedLineIndex].itemNumber)[0];


      if (selectedItem) {
        // if the selected item doesn't already exist
        if (!this.listOfItems.filter(x => x.parentRefFullName == selectedItem.parentRefFullName)[0]) {
          this.listOfItems.push(selectedItem);
        }
      }
    }
    this.filterProducts();
  }




  setPackage(event: InventoryPackageTypeDTO, lineItem: CartItem) {

    let selectedPackingOption = this.packages.find(x => x.id == event.id);
    
    if(selectedPackingOption){
      lineItem.packageObj = selectedPackingOption;
      // lineItem.packageTypeName = selectedPackingOption.name;
      // lineItem.packageType = +selectedPackingOption.bagSize;
      // lineItem.packageName = selectedPackingOption.boxType;
    }
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getTotalPrice() {
    let totalPrice: number = 0;
    // console.log(this.cart);
    if (this.cart.items) {
      for (let x of this.cart.items) {
        if (x.price != undefined || x.price != null) {
          totalPrice = totalPrice + (x.price * x.quantity);
        }
      }
      return totalPrice;
    }
  }

  getTotalAmendedPrice() {
    let totalPrice: number = 0;
    // console.log(this.cart);
    if (this.cart.items) {
      for (let x of this.cart.items) {
        if (x.priceAmend != undefined || x.priceAmend != null) {
          totalPrice = totalPrice + (x.priceAmend * x.quantityAmend);
        }
        // if (x.packageType === 0 && x.itemNumber === 'CC Fee' || x.packageType === 0 && x.itemNumber === 'Bag Fee') {
        //   totalPrice = totalPrice + x.price;
        // }
      }
      return totalPrice;
    }
  }
  filterProducts() {

    this.itemList = this.inventoryItems.filter(x => this.searchItemNumber === '' || (x.name != null && x.name.toLowerCase().includes(this.searchItemNumber.toLowerCase())));

    // if (this.itemList.length > 1000)
    if(this.searchItemNumber.length < 2){
      this.itemList = [];
    } else {
      this.itemList = _.sortBy(this.itemList, 'Name');
      // this.itemList.push(filterProducts)
    }
    // add selected brewery so it shows in the list
    if (this.cart && this.cart.items.length > 0 && this.selectedLineIndex != -1 && this.cart.items[this.selectedLineIndex].inventoryItem) {
      if (this.itemList.filter(x => x.id == this.cart.items[this.selectedLineIndex].inventoryItem.id).length === 0) {
        let selectedItem = this.inventoryItems.filter(x => x.id == this.cart.items[this.selectedLineIndex].inventoryItem.id)[0];
        if (selectedItem) {
          this.itemList.push(selectedItem);
        }
      }
    }
  }

  filterProductDesc() {
    this.itemList = this.inventoryItems.filter(x => this.searchItemDesc === '' || (x.description != null && x.description.toLowerCase().includes(this.searchItemDesc.toLowerCase())));

    // if (this.itemList.length > 1000)
    if(this.searchItemDesc.length < 2){
      this.itemList = [];
    } else {
      this.itemList = _.sortBy(this.itemList, 'Description');
      // this.itemList.push(filterProducts)
    }
    // add selected brewery so it shows in the list
    if (this.cart && this.cart.items.length > 0 && this.cart.items[this.selectedLineIndex].inventoryItem) {
      if (this.itemList.filter(x => x.id == this.cart.items[this.selectedLineIndex].inventoryItem.id).length === 0) {
        let selectedItem = this.inventoryItems.filter(x => x.id == this.cart.items[this.selectedLineIndex].inventoryItem.id)[0];
        if (selectedItem) {
          this.itemList.push(selectedItem);
        }
      }
    }
  }


}
