// src/app/auth/auth.config.ts

interface AuthConfig {
    CLIENT_ID: string;
    CLIENT_DOMAIN: string;
    AUDIENCE: string;
    REDIRECT: string;
    SCOPE: string;
    NAMESPACE: string;
    TENANT: string;
    TOKEN_ISSUER: string;
  }
  
  export const AUTH_CONFIG: AuthConfig = {
    CLIENT_ID: 'G5kIfAyvR53dAT20T61RPiTPEpFZjDo0',
    // CLIENT_ID: '6TcZZH7ohVAk1qU6r0mwZoqSeIrqReWL',
     CLIENT_DOMAIN: 'login.krag.works',
    // CLIENT_DOMAIN: 'kragworks.auth0.com', // e.g., you.auth0.com
    // AUDIENCE: 'https://dev-api.krag.works', // 'https://kragworks.auth0.com/userinfo', // e.g., http://localhost:8083/api/
    AUDIENCE: 'https://hub.krag.works',
    REDIRECT: 'http://localhost:4200/callback',
    SCOPE: 'openid profile offline_access',
    NAMESPACE: 'http://kragworks.com/roles',
    TENANT : '',
    TOKEN_ISSUER : '',
  };
  