import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { faTruck, faSlidersH } from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'lot-details-by-room-action',
  templateUrl: './lot-details-by-room-action.component.html',
  styleUrls: ['./lot-details-by-room-action.component.scss']
})
export class LotDetailsByRoomActionComponent implements OnInit {

  public faTruck = faTruck;
  public faSlidersH = faSlidersH;

  constructor(
    //dialog ref to this component

    public dialogRef: MatDialogRef<LotDetailsByRoomActionComponent>
  ) { }

  ngOnInit() {
  }

  goToMove(){
    this.dialogRef.close("move");
  }

  adjustQuantity(){
    this.dialogRef.close("adjust");
  }

}
