import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs/Rx';
import { Location } from '@angular/common';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { CropVariety } from '../../../../../core/models/crop-variety';
import { VarietyService } from '../../../../../core/services/variety.service';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { InventoryItemDTO } from '../../../models/item';
import { InventoryItemAttributeDTO } from '../../../models/item-attribute';
import { InventoryItemAttributeValueDTO } from '../../../models/item-attribute-value';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { InventoryAddItemPopupComponent } from '../inventory-add-item-popup/inventory-add-item-popup.component';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { InventoryHumEditComponent } from '../../inventory-summary/inventory-hum-edit/inventory-hum-edit.component';
import { InventoryPackageTypeDTO } from '../../../models/inventory-package-type';
import { InventoryPackageTypeService } from '../../../services/inventory-package-type.service';
import { KwFilterDropdownService } from '../../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import * as _ from 'underscore';
import { MockSharedService, SHARED_SERVICE } from '../../../../../../shared/mockSharedService';
import { PlatformConfigService } from '../../../../../core/services/platform-config.service';

export interface InventoryItemPopupData {
  item: InventoryItemDTO;
  isPopup: boolean;
  isHumEdit: boolean;
  hum: HandlingUnitMasterDTO;
  summaryPopupId?: string;
  hud?: HandlingUnitDetailDTO;
  isReturnBottomsheet?: boolean;
}

@Component({
  selector: 'inventory-item-edit',
  templateUrl: './inventory-item-edit.component.html',
  styleUrls: ['./inventory-item-edit.component.scss']
})

export class InventoryItemEditComponent implements OnInit {
  @ViewChild('itemInputForm') itemInputForm: NgForm;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: InventoryItemPopupData,
    private activatedRoute: ActivatedRoute,
    private inventoryService: InventoryService,
    private packageTypeService: InventoryPackageTypeService,
    private router: Router,
    private bottomSheet: MatBottomSheetRef<InventoryItemEditComponent>,
    private bottomSheetReturn: MatBottomSheet,
    private dialog: MatDialog,
    private changeDetRef: ChangeDetectorRef,
    private filterService: KwFilterDropdownService,
    private location: Location,
    private varietyService: VarietyService,
    private errorService: ErrorService,
    private platformConfigService: PlatformConfigService,
    @Inject(SHARED_SERVICE) private sharedService: MockSharedService, //Inject interface of shared service
  ) {
    this.getAllConfigValues();
    if (data.item)
      this.item = { ...data.item };
    else
      this.item = {};
  }

  // Icons  //
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faTimes = faTimes;
  public faPlus = faPlusCircle;

  public item: InventoryItemDTO = {};
  public items: InventoryItemDTO[];
  public itemAttrabutes: InventoryItemAttributeDTO[];
  public varieties: CropVariety[];
  public isLoading: boolean = false;
  public packageTypes: InventoryPackageTypeDTO[] = [];
  public isREPToken: boolean = false;


  ngOnInit() {

    Observable.forkJoin([
      this.inventoryService.getInventoryItems(),
      this.varietyService.getVarieties(false),
      this.inventoryService.getInventoryItemAttributes(),
      this.packageTypeService.getAll()
      //this.roomService.getRooms()
    ])
      .subscribe(x => {
        this.items = _.sortBy(x[0], 'name').reverse();
        this.varieties = _.sortBy(x[1], 'name');
        this.itemAttrabutes = x[2];
        this.packageTypes = _.sortBy(x[3], 'name');
        //this.isLoading = false;
        this.activatedRoute.queryParams.subscribe(params => {
          if (params["itemId"]) {
            this.inventoryService.getInventoryItem(params["itemId"]).subscribe(x => {
              this.item = x;
              //this.isLoading = false;
            }, err => {
              this.errorService.handleError(err);
              //this.isLoading = false;
            });
          }
          this.customFields();
          this.isLoading = false
        })
      }, err => {
        this.errorService.handleError(err);
        //this.isLoading = false;
      });
  }

  submit() {
    //Pass saveERPFlag hardcoded true from Item create/update.
    let saveERPFlag = true;
    this.isLoading = true;

    if(this.item.parentItemId == 0)
      this.item.parentItemId = null;
      
    if (this.item.id) {
      this.inventoryService.putInventoryItem(this.item,saveERPFlag).subscribe(x => {
        this.cancel();
        this.filterService.loadAllData();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      });
    }
    else {
      this.inventoryService.postInventoryItem(this.item,saveERPFlag).subscribe(x => {
        this.item.id = x.id;
        this.cancel();
        this.isLoading = false;
        this.filterService.loadAllData();
      }, err => {
        this.isLoading = false;
        this.errorService.handleError(err);
        this.changeDetRef.detectChanges();
      });
    }
  }

  typeSelectionChange(event: any) {
    //console.log(event);
    let type = {...this.packageTypes.filter(x => x.id == event.value)[0]};
    this.item.packageTypeName = type.name;
  }


  cancel() {
    if (this.data.isPopup) {
      // reference of bottom sheet
      let ref:any;
      
      if (this.item.id && !this.data.isReturnBottomsheet) {
        this.bottomSheet.dismiss(this.item);
      }
      else if (this.item.id && this.data.isReturnBottomsheet) {
        this.data.hud.itemId = this.item.id;
        let data = {
          Hum: this.data.hum,
          hud: this.data.hud,
          itemId: this.item.id,
          summaryPopupId: this.data.summaryPopupId
        }
        // open the bottom sheet
        if (!this.data.isHumEdit)
        
        //open sheet from shared service to remove circular dependency, it will retrun reference of sheet. 
        ref = this.sharedService.openDialog('InventoryAddItemPopupComponent',this.bottomSheetReturn, { data });
        else
        ref = this.sharedService.openDialog('InventoryHumEditComponent', this.bottomSheetReturn,{ panelClass: 'bottomsheet-60-width', data });

        // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
        let bottomSheetSubscription = ref.afterDismissed().subscribe(job => {

          bottomSheetSubscription.unsubscribe();
          this.changeDetRef.detectChanges();

        });
      }
      else {
        this.bottomSheet.dismiss();
      }
    }
    else
      this.location.back();
  }

  deleteItem() {
    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Delete Product?',
        confirmMessage: 'Are you sure you want to delete this item?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {

      if (result === true) {
        this.isLoading = true;
        let erpSave = true;
        this.inventoryService.deleteInventoryItem(this.item.id,erpSave).subscribe(x => {
          this.cancel();
        }, err => {
          this.errorService.handleError(err);
          this.isLoading = false;
        });
      }
    });
  }


  customFields() {
    if (!this.item.attributes)
      this.item.attributes = [];

    this.itemAttrabutes.forEach(f => {

      var tempField = this.item.attributes.find(x => x.inventoryItemAttributeId == f.id);
      if (tempField) {
        tempField.description = f.description;
        tempField.name = f.name;
        tempField.inventoryItemId = this.item.id;
      }
      else {
        let fieldValue: InventoryItemAttributeValueDTO = {
          datatype: f.datatype,
          description: f.description,
          name: f.name,
          id: 0,
          inventoryItemAttributeId: f.id,
          isActive: true
        };
        this.item.attributes.push(fieldValue);
      }
    });
    this.changeDetRef.markForCheck();
  }

  getOptionsLength(attrabute: InventoryItemAttributeValueDTO) {
    if (this.itemAttrabutes)
      return this.itemAttrabutes.find(x => x.id == attrabute.inventoryItemAttributeId).options.length;
    else
      return [];

  }

  ClearValue(field) {
    var currentField = this.item.attributes.find(x => x.id == field.id);
    currentField.value = null;
  }

  getFieldName(field: InventoryItemAttributeValueDTO) {
    var currentField = this.itemAttrabutes.find(x => x.id == field.inventoryItemAttributeId);
    return currentField.name
  }

  getFieldOptions(field) {
    var currentField = this.itemAttrabutes.find(x => x.id == field.inventoryItemAttributeId);
    return currentField.options
  }

    //get ERPNext token from config
    getAllConfigValues() {
       this.isLoading = true;
      this.platformConfigService.getAllConfigValues().subscribe(
        (data) => {
          this.isLoading = false;
          if(!!data && data.length > 0){
            //set true if erp token present
            this.isREPToken = !!data.find(x=>x.name.includes("ERPNext:AccessToken")).value;
          }
        },
        (err) => {
          this.isLoading = false;
          this.errorService.handleError(err);
        }
      );
      this.changeDetRef.markForCheck();
    }

}
