import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { faFileDownload, faSearch, faPrint, faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import * as _ from 'underscore';
import { FilterItem } from './models/kw-filter-item';
import { FilterResults } from './models/filter-results';
import { FilterSettings } from './models/filter-options';
import { Subscription } from 'rxjs/Rx';
import { TenantWordsPipe } from '../../pipes/tenant-words.pipe';
import * as moment from 'moment-timezone';
import { KwFilterDropdownService } from './kw-filter-dropdown.service';
import { Router } from '@angular/router';
import { Room } from '../../models/storage/room';
import { BlockCustomFieldDTO } from '../../models/block';
import { HttpClient } from '@angular/common/http';




@Component({
  selector: 'kw-filter-dropdown',
  templateUrl: './kw-filter-dropdown.component.html',
  styleUrls: ['./kw-filter-dropdown.component.scss']
})
export class KwFilterDropdownComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {

  @Output() resetDropdowns: EventEmitter<void> = new EventEmitter();

  public blockFilterText: string = ''; // Variable for block search text
  public roomFilterText: string = ''; // Variable for room search text
  public locationFilterText: string = ''; // Variable for location search text
  public fieldmanFilterText: string = ''; // Variable for fieldman search text
  public estimateTypeFilterText: string = ''; // Variable for estimate type search text


  // Function to be called when the reset button is clicked
  onResetButtonClick() {
    // Emit the custom event to the parent component
    this.resetDropdowns.emit();
    this.filterService.resetDropdowns(true)
  }
  private filterServiceSubscription: Subscription;
  public isLoading = true;

  public checked = this.filterService.bypassFilterReset;

  constructor(
    public filterService: KwFilterDropdownService,
    private pipe: TenantWordsPipe,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) { }


  ngOnDestroy(): void {
    if (this.filterServiceSubscription)
      this.filterServiceSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  onSearchTextChanged(searchType: string) {


    if (searchType == "Block") {
      this.filterService.listOfBlocks = [];
      this.filterService.listOfBlocks = this.filterService.allBlocks.filter((block) => {
        return block.name.toLowerCase().includes(this.blockFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name,
        group: item.farmName
      });
    }

    if (searchType == "Room") {
      this.filterService.listOfRooms = [];
      this.filterService.listOfRooms = this.filterService.allRooms.filter((room) => {
        return room.name.toLowerCase().includes(this.roomFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name,
        group: item.warehouse.name
      });
    }

    if (searchType == "Location") {
      this.filterService.listOfLocations = [];
      this.filterService.listOfLocations = this.filterService.allLocations.filter((location) => {
        return location.name.toLowerCase().includes(this.locationFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name,
        group: item.room.name
      });
    }

    if (searchType == "EstimateType") {
      this.filterService.listOfEstimateTypes = [];
      this.filterService.listOfEstimateTypes = this.filterService.allEstimateTypes.filter((estimateType) => {
        return estimateType.name.toLowerCase().includes(this.estimateTypeFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name
      });
    }

    if (searchType == "Fieldman") {

      let fieldmanMap = [...this.filterService.allBlocks]
        .filter(x =>
        (
          (this.filterService.selectedFarms.map(y => y.id).includes(x.farmId) || this.filterService.selectedFarms.length == 0) &&
          (this.filterService.selectedVarieties.map(y => y.id).includes(x.varietyId) || this.filterService.selectedVarieties.length == 0) &&
          (this.filterService.selectedFieldmen.map(y => y.id).includes(x.fieldman || '') || this.filterService.selectedFieldmen.length == 0) &&
          (this.filterService.selectedBlocks.map(y => y.id).includes(x.id) || this.filterService.selectedBlocks.length == 0))
        )
        .map(x => {
          return {
            id: x.fieldman,
            itemName: x.fieldman || 'None',
          };
        });

      let uniqFieldmans = _.uniq(fieldmanMap, x => x.id);
      this.filterService.listOfFieldmen = _.chain(uniqFieldmans).sortBy(x => x.itemName).sortBy(x => x.itemName).value().filter((fieldman) => {
        return fieldman.itemName.toLowerCase().includes(this.fieldmanFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.itemName
      });
    }

  }

  eventFilterChange(event: any) {
    this.filterService.filter();

    if (event == "Block")
      this.filterService.listOfBlocks = this.filterService.allBlocks.filter((block) => {
        return block.name.toLowerCase().includes(this.blockFilterText.toLowerCase()) && 
        (this.filterService.selectedFarms.map(y => y.id).includes(block.farmId) || this.filterService.selectedFarms.length == 0) && 
        (this.filterService.selectedVarieties.map(y => y.id).includes(block.varietyId) || this.filterService.selectedVarieties.length == 0) && 
        (this.filterService.selectedFieldmen.map(y => y.id).includes(block.fieldman || '') || this.filterService.selectedFieldmen.length == 0);
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name + "(" + item.varietyName + ")",
        group: item.farmName
      });

    else if (event == "Room")
      this.filterService.listOfRooms = this.filterService.allRooms.filter((room) => {
        return room.name.toLowerCase().includes(this.roomFilterText.toLowerCase()) &&  
        (this.filterService.selectedWarehouses.map(y => y.id).includes(room.warehouseId) || this.filterService.selectedWarehouses.length == 0);
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name,
        group: item.warehouse.name
      });

    if (event == "Location")
      this.filterService.listOfLocations = this.filterService.allLocations.filter((location) => {
        return location.name.toLowerCase().includes(this.locationFilterText.toLowerCase()) &&  
        (this.filterService.selectedRooms.map(y => y.id).includes(location.roomId) || this.filterService.selectedRooms.length == 0);
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.name,
        group: item.room.name
      });

    if (event == "EstimateType")
      this.filterService.listOfEstimateTypes = this.filterService.listOfEstimateTypes.filter((estimateType) => {
        return estimateType.itemName.toLowerCase().includes(this.estimateTypeFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.itemName,
        group: item.group
      });

    if (event == "Fieldman") {

      let fieldmanMap = [...this.filterService.allBlocks]
        .filter(x =>
        (
          (this.filterService.selectedFarms.map(y => y.id).includes(x.farmId) || this.filterService.selectedFarms.length == 0) &&
          (this.filterService.selectedVarieties.map(y => y.id).includes(x.varietyId) || this.filterService.selectedVarieties.length == 0) &&
          (this.filterService.selectedFieldmen.map(y => y.id).includes(x.fieldman || '') || this.filterService.selectedFieldmen.length == 0) &&
          (this.filterService.selectedBlocks.map(y => y.id).includes(x.id) || this.filterService.selectedBlocks.length == 0))
        )
        .map(x => {
          return {
            id: x.fieldman,
            itemName: x.fieldman || 'None',
          };
        });

      let uniqFieldmans = _.uniq(fieldmanMap, x => x.id);
      this.filterService.listOfFieldmen = _.chain(uniqFieldmans).sortBy(x => x.itemName).sortBy(x => x.itemName).value().filter((fieldman) => {
        return fieldman.itemName.toLowerCase().includes(this.fieldmanFilterText.toLowerCase());
      }).map(item => <FilterItem>{
        id: item.id,
        itemName: item.itemName
      });
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filterSettings.storageSettings)
      this.storageTypesDropdownSettings.singleSelection = this.filterSettings.storageSettings.singleSelection;

    if (this.filterSettings.workOrderSettings)
      this.workOrderTypeDropdownSettings.singleSelection = this.filterSettings.workOrderSettings.singleSelection;

    if (this.filterSettings.groupingSettings)
      this.groupingDropdownSettings.singleSelection = this.filterSettings.groupingSettings.singleSelection;

    if (this.filterSettings.poolTypeSettings)
      this.poolDropdownSettings.singleSelection = this.filterSettings.poolTypeSettings.singleSelection;

    if (this.filterSettings.farmSettings)
      this.farmDropdownSettings.singleSelection = this.filterSettings.farmSettings.singleSelection;

    if (this.filterSettings.blockSettings) {
      if (this.filterSettings.blockSettings.singleSelection) {
        this.blockDropdownSettings.groupBy = '';
      }
      this.blockDropdownSettings.singleSelection = this.filterSettings.blockSettings.singleSelection;
    }

    if (this.filterSettings.fieldmanSettings)
      this.fieldmenDropdownSettings.singleSelection = this.filterSettings.fieldmanSettings.singleSelection;

    if (this.filterSettings.cropYearSettings)
      this.cropYearDropdownSettings.singleSelection = this.filterSettings.cropYearSettings.singleSelection;

    if (this.filterSettings.varietySettings)
      this.varietyDropdownSettings.singleSelection = this.filterSettings.varietySettings.singleSelection;

    if (this.filterSettings.organicConventionalSettings)
      this.organicConventionalDropdownSettings.singleSelection = this.filterSettings.organicConventionalSettings.singleSelection;

    if (this.filterSettings.cropSettings)
      this.cropDropdownSettings.singleSelection = this.filterSettings.cropSettings.singleSelection;

    if (this.filterSettings.dateRangeSettings)
      this.dateRangeSettings.singleSelection = this.filterSettings.dateRangeSettings.singleSelection;

    if (this.filterSettings.qcAreaSettings)
      this.qcAreasDropdownSettings.singleSelection = this.filterSettings.qcAreaSettings.singleSelection;

    if (this.filterSettings.warehouseSettings)
      this.warehouseDropdownSettings.singleSelection = this.filterSettings.warehouseSettings.singleSelection;

    if (this.filterSettings.inventoryItemSettings)
      this.inventoryItemDropdownSettings.singleSelection = this.filterSettings.inventoryItemSettings.singleSelection;

    if (this.filterSettings.locationSettings)
      this.locationDropdownSettings.singleSelection = this.filterSettings.locationSettings.singleSelection;

    if (this.filterSettings.lotNumberSettings)
      this.lotNumberDropdownSettings.singleSelection = this.filterSettings.lotNumberSettings.singleSelection;

    if (this.filterSettings.roomSettings)
      this.roomDropdownSettings.singleSelection = this.filterSettings.roomSettings.singleSelection;

    if (this.filterSettings.estimateTypeSettings)
      this.estimateTypeDropdownSettings.singleSelection = this.filterSettings.estimateTypeSettings.singleSelection;

    if (this.filterSettings.shipmentStatusSettings)
      this.shipmentStatusDropdownSettings.singleSelection = this.filterSettings.shipmentStatusSettings.singleSelection;

    if (this.filterSettings.carrierSettings)
      this.shipmentStatusDropdownSettings.singleSelection = this.filterSettings.carrierSettings.singleSelection;

    if (this.filterSettings.truckTypeSettings)
      this.shipmentStatusDropdownSettings.singleSelection = this.filterSettings.truckTypeSettings.singleSelection;

    if (this.filterSettings.featureEventTypeSettings)
      this.shipmentStatusDropdownSettings.singleSelection = this.filterSettings.featureEventTypeSettings.singleSelection;

    this.setDates();

  }

  ngOnInit() {

    if (!this.filterService.hasLoaded)
      this.filterService.loadAllData();
    else
      this.filterService.setDropdownLists();

    this.isLoading = this.filterService.isLoading;

    if (this.filterSettings.dateRangeSettings === undefined) {
      this.filterSettings.dateRangeSettings = {
        display: false,
        singleSelection: false,
      };
    }

    if (this.filterSettings.dateRangeSettings.startDate && this.filterSettings.dateRangeSettings.endDate) {
      let startDate = this.filterSettings.dateRangeSettings.startDate;
      let endDate = this.filterSettings.dateRangeSettings.endDate;
      this.filterService.selectedDates = [startDate, endDate];
    } else {
      let startDate = moment(new Date()).subtract(0, 'days').toDate();
      let endDate = moment(new Date()).add(7, 'days').toDate();
      this.filterService.selectedDates = [startDate, endDate];
    }

    this.setDates();

    setTimeout(() => {
      for (let dropdown of this.allDropDownsettings) {
        dropdown.noDataLabel = "No Data"
      }

      this.filterServiceSubscription = this.filterService.itemChanged.subscribe(() => {

        if (this.filterService.hasLoaded) {
          this.filterResultsChange.emit(this.filterService.filterResults);
        }
      });
    }, 0);
  }

  onCheckboxChange(checked: boolean): void {
    this.checked = checked;
    this.filterService.onCheckboxChange(this.checked);
  }

  private setDates() {
    if (this.filterSettings && this.filterSettings.dateRangeSettings) {
      if (this.filterSettings.dateRangeSettings.startDate) {
        this.filterService.selectedDates = [this.filterSettings.dateRangeSettings.startDate, this.filterService.selectedDates[1]]
      }

      if (this.filterSettings.dateRangeSettings.endDate)
        this.filterService.selectedDates = [this.filterService.selectedDates[0], this.filterSettings.dateRangeSettings.endDate];
    }

  }

  public GetBlockCustomFieldSettings(field: BlockCustomFieldDTO) {
    let custSettings = { ...this.blockCustomFieldDropdownSettings };
    custSettings.text = this.pipe.transform("Block") + " " + field.name;
    return custSettings
  }





  @Output() cropYear = new EventEmitter<FilterItem>();
  @Input() filterSettings: FilterSettings;
  @Input() stack: boolean = false;
  @Input()
  filterResults: FilterResults = {
    selectedBlocks: [],
    selectedCropYears: [],
    selectedFarms: [],
    selectedFieldmen: [],
    selectedVarieties: [],
    selectedOrganConventional: [],
    selectedDateRange: [],
    selectedQcAreas: [],
    includeCompleted: false,
    selectedCarriers: [],
    selectedTruckTypes: [],
    selectedLoad: "",
    selectedInventoryItems: [],
    selectedLocations: [],
    selectedLotNumbers: [],
    selectedBreweries: [],
    selectedUserNames: [],
    selectedFeatureEventTypes: [],
    selectedGrouping: [],
    selectedPools: [],
    selectedStorageTypes: [],
  };


  @Output()
  filterResultsChange = new EventEmitter<FilterResults>();

  @Output()
  onChange = new EventEmitter<any>();

  @Output()
  exportPressed = new EventEmitter<any>();

  // Icons
  faCalendarAlt = faCalendarAlt;

  public dateRangeSettings = {
    singleSelection: false,
    text: this.pipe.transform("Farm") + "s",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "No data found",
    badgeShowLimit: 1,
  };

  public farmDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Farm") + "s",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "No data found",
    badgeShowLimit: 1,
  };

  public workOrderTypeDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Work Order Type"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "No data found",
    badgeShowLimit: 1,
  };

  public groupingDropdownSettings = {
    singleSelection: true,
    text: "Group By",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public blockDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Block"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    groupBy: 'group',
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public poolDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Pool"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    groupBy: 'group',
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public blockCustomFieldDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Block") + " Custom Fields",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public featureEventTypesDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform('Asset') + 'Event Type',
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    // groupBy: 'group',
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };


  public featureTypesDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform('Asset') + ' Type',
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    // groupBy: 'group',
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public featureDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform('Asset'),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    // groupBy: 'group',
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public selectedRooms: FilterItem[] = [{ itemName: 'No data found', id: 0 }];
  //public selectedBlocks: FilterItem[] = [];
  public listOfRooms: FilterItem[] = [];
  public allRooms: Room[] = [];
  public roomDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Room"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    groupBy: 'group',
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public warehouseDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Warehouse"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public inventoryItemDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Product"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public locationDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Location"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    groupBy: 'group',
    lazyLoading: false,
  };

  public lotNumberDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Lot Number"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "Loading",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public estimateTypeDropdownSettings = {
    singleSelection: true,
    text: this.pipe.transform("Estimate") + ' Type',
    enableSearchFilter: true,
    classes: "",
    noDataLabel: "No data found",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public varietyDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Variety"),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public fieldmenDropdownSettings = {
    singleSelection: false,
    text: "Fieldman",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public userNameDropdownSettings = {
    singleSelection: false,
    text: "User",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public breweryDropdownSettings = {
    singleSelection: false,
    text: "Brewery",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };
  public carrierDropdownSettings = {
    singleSelection: false,
    text: "Carrier",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public truckTypeDropdownSettings = {
    singleSelection: false,
    text: "Truck Type",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public loadDropdownSettings = {
    singleSelection: true,
    text: "Load Number",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public cropDropdownSettings = {
    singleSelection: true,
    text: "Crop",
    // selectAllText: 'Select All',
    // unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public typesDropdownSettings = {
    singleSelection: false,
    text: "Scouting Type",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public storageTypesDropdownSettings = {
    singleSelection: false,
    text: "Storage Type",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public shipmentStatusDropdownSettings = {
    singleSelection: false,
    text: "Shipment Status",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public cropYearDropdownSettings = {
    text: "Crop Year",
    enableSearchFilter: true,
    singleSelection: true,
    noDataLabel: "No crop years for selected crop",
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public organicConventionalDropdownSettings = {
    singleSelection: false,
    text: this.pipe.transform("Org./Conv."),
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  public includeCompletedSettings = {
    singleSelection: true,
    text: "Include Completed?",
    enableSearchFilter: false,

    classes: "",
    lazyLoading: false,
  };

  public qcAreasDropdownSettings = {
    singleSelection: false,
    text: "Qc Area",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1,
    lazyLoading: false,
  };

  // icons 
  public faFileDownload = faFileDownload;
  public faPrint = faPrint;
  public faSearch = faSearch;


  private allDropDownsettings: any[] = [
    this.farmDropdownSettings,
    this.blockDropdownSettings,
    this.varietyDropdownSettings,
    this.cropYearDropdownSettings,
    this.fieldmenDropdownSettings,
    this.carrierDropdownSettings,
    this.truckTypeDropdownSettings,
    this.poolDropdownSettings,
    this.storageTypesDropdownSettings
  ]


}
