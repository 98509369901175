import { Injectable } from '@angular/core';
import { HollingberyApiService } from '../../../../services/hollingbery-api.service';
import { Observable } from 'rxjs';
import { PositionReport_RowItem } from '../models/position-report';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { PostitionReportDetail } from '../models/position-report-detail';
import { BreweryContractYear } from '../models/brewer-contract-year';
import { HttpResponse, HttpEvent, HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { PositionByYearSummary } from '../models/position-by-year';
import { PositionReportComment } from '../models/position-report-comments';

@Injectable({
    providedIn: 'root'
})
export class PositionReportService {
    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();

    constructor(
        private api: HollingberyApiService,
        private auth: AuthService,
        protected httpClient: HttpClient,
        // private aprilFools: AprilFoolsService
    ) {
        this.basePath = environment.HOLLINGBERY_API_URL;
    }

    public getPositionReport(cropYear: string, multiplier: number, contractType: string, ownership: string, packageType?: string, snapshotDate?: Date): Observable<PositionReport_RowItem[]> {
        return this.api.getPositionReport(this.auth.getUserToken(), cropYear, multiplier, contractType, ownership, packageType, snapshotDate);
    };

    public getContractTypes(): Observable<string[]> {
        return this.api.getContractTypes(this.auth.getUserToken());
    }

    public getOwnership(): Observable<string[]> {
        return this.api.getOwnership(this.auth.getUserToken());
    }

    public getDetails(cropYear: string = null, variety: string = null, packageSize: string = null, lotId: string = null, breweryName: string = null, contractType: string = null, ownership: string = null, snapshotDate?: Date): Observable<PostitionReportDetail[]> {
        return this.api.getPositionReportDetails(this.auth.getUserToken(), cropYear, variety, packageSize, lotId, breweryName, contractType, ownership, snapshotDate);
    }

    public getPositionVarieites(): Observable<string[]> {
        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()}) ;

        let headers = this.defaultHeaders;

        headers = headers.set('Authorization', this.auth.getUserToken());

        let queryParameters = new HttpParams();

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string[]>(`${this.basePath}/api/positionreport/varieties`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }

    public getBreweryContractYears(startingYear: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BreweryContractYear>>;
    public getBreweryContractYears(startingYear: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BreweryContractYear>>>;
    public getBreweryContractYears(startingYear: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BreweryContractYear>>>;
    public getBreweryContractYears(startingYear: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()}) ;

        let headers = this.defaultHeaders;

        headers = headers.set('Authorization', this.auth.getUserToken());

        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('startingyear', startingYear.toString());

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/salesreports/brewerycontractyearly`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }

    public getYearlySummary(startingYear: number, multiplier: number, contractType: string, ownership: string, packageType?: string, snapshotDate?: Date): Observable<PositionByYearSummary[]> {

        // let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()}) ;

        let headers = this.defaultHeaders;

        headers = headers.set('Authorization', this.auth.getUserToken());

        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('startingyear', startingYear.toString());
        
        queryParameters = queryParameters.set('Multiplier', multiplier.toString());
        if (ownership !== undefined) {
            queryParameters = queryParameters.set('Ownership', ownership.toString());
        }

        if (contractType != undefined) {
            queryParameters = queryParameters.set('ContractType', contractType.toString());
        }

        if (snapshotDate !== undefined && snapshotDate !== null) {
            queryParameters = queryParameters.set('SnapshotDate', snapshotDate.toString());
        }

        if (packageType !== undefined && packageType !== null) {
            queryParameters = queryParameters.set('PackageType', packageType.toString());
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PositionByYearSummary[]>(`${this.basePath}/api/salesreports/positionByYear`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }


    public getComments(varietyName?: string): Observable<PositionReportComment[]> {
        let headers = this.defaultHeaders;

        headers = headers.set('Authorization', this.auth.getUserToken());

        let queryParameters = new HttpParams();

        queryParameters = queryParameters.set('varietyName', varietyName);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = "application/json";
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PositionReportComment[]>(`${this.basePath}/api/positionReport/comments`,
            {
                headers: headers,
                params: queryParameters
            }
        );
    }


    public deleteComment(id: number): Observable<any>{ 
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.auth.getUserToken()));

        return this.httpClient.delete(`${this.basePath}/api/positionReport/comments/${encodeURIComponent(String(id))}`,
            {
                headers: headers,
            }
        );
    }


    public putComment(id: number, body: PositionReportComment): Observable<PositionReportComment> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.auth.getUserToken()));

        return this.httpClient.put<PositionReportComment>(`${this.basePath}/api/positionReport/comments/${encodeURIComponent(String(id))}`, body,
            {
                headers: headers,
            }
        );
    }


    public postComment(body?: PositionReportComment): Observable<PositionReportComment>
    {
        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.auth.getUserToken()));

        body.createdBy = this.auth.getCurrentUser().name;

        return this.httpClient.post<PositionReportComment>(`${this.basePath}/api/positionReport/comments`, body,
            {
                headers: headers
            }
        );
    }

}
