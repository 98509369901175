import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatDialog, MatPaginator, MatSort } from '@angular/material';
import { AggregateRowModel, ColumnModel, FilterSettingsModel, GridComponent, IFilter, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { InventoryService } from '../../../../../modules/pick-plan/services/inventory.service';
import { DataManager, ODataAdaptor, Query } from '@syncfusion/ej2-data';
import { LotsListDataSource } from './lots-list-datasource';
import { faBars, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { InventoryLotAttributeValue, InventoryLotDTO, SummaryHud, SummaryHum } from '../../../../../modules/inventory/models/inventory-lot';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
import { InventoryLotAttributeDTO } from '../../../../../modules/inventory/models/lot-attribute';
import { FilterSettings } from '../../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { InventoryLotEditComponent } from '../inventory-lot-edit/inventory-lot-edit.component';
import { TenantWordsPipe } from '../../../../../core/pipes/tenant-words.pipe';
import { LotsOptionsDiologComponent } from '../lots-options-diolog/lots-options-diolog.component';
import { Router } from '@angular/router';
import { KwFilterDropdownService } from '../../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';
import { DatePipe } from '@angular/common';
import { LotDetailsByRoomMoveInventoryComponent } from '../../../widgets/lot-details-by-room/lot-details-by-room-move-inventory/lot-details-by-room-move-inventory.component';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { LotDetailsByRoomDialogComponent } from '../../../widgets/lot-details-by-room/lot-details-by-room-dialog/lot-details-by-room-dialog.component';
import { lotInventory, lotInventoryService } from '../../../../laboratory/services/lab-quantity-service';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
// import { InventoryService } from '../../../pick-plan/services/inventory.service';


@Component({
  selector: 'lots-list',
  templateUrl: './lots-list.component.html',
  styleUrls: ['./lots-list.component.css']
})
export class LotsListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: LotsListDataSource;

  @ViewChild('actionTemplate')
  public actionTemplate: any;

  @ViewChild('locationTemplate')
  public locationTemplate: any;

  @ViewChild('productTemplate')
  public productTemplate: any;

  public isLoading = true;
  private initialLoad = true;
  public faEdit = faEdit;
  faBars = faBars;
  @ViewChild('grid') grid: GridComponent;
  public lots: InventoryLotDTO[] = [];
  public allLots: InventoryLotDTO[] = [];
  public data: DataManager;
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"

  }
  public filter: Object;
  public attrabutes?: InventoryLotAttributeDTO[] = [];
  public toolbarOptions: string[] = ['ExcelExport'];
  public pageSettings: PageSettingsModel = {
    pageSize: 50
  }
  public setActionsWidth = {
    class: 'action-width'
  }

  public aggregates: AggregateRowModel[] = [];

  public allInventoryHums: HandlingUnitMasterDTO[] = [];

  public aggregate: AggregateRowModel = {
    columns: [
      {
        type: 'Sum',
        field: 'lotNumber',
        format: 'N0',
        footerTemplate: 'Totals:',
      },
      {
        type: 'Sum',
        field: 'totalQtyOnHand',
        format: 'N0',
        footerTemplate: '${Sum}'
      },
      {
        type: 'Sum',
        field: 'weight',
        format: 'N0',
        footerTemplate: '${Sum}'
      }
    ]
  };


  public columns: ColumnModel[] = [];


  public columnNames: any[] = [];

  baseColumns: ColumnModel[] = [];

  endColumns: ColumnModel[] = [];

  datePipe = new DatePipe('en-US');


  public dropdownSettings: FilterSettings = {
    varietySettings: {
      display: true,
      singleSelection: false
    },
    roomSettings: {
      display: true,
      singleSelection: false
    },
    warehouseSettings: {
      display: true,
      singleSelection: false
    },

    locationSettings: {
      display: true,
      singleSelection: false
    },

    lotNumberSettings: {
      display: true,
      singleSelection: false
    },
    inventoryItemSettings: {
      display: true,
      singleSelection: false
    },

  };

  constructor(
    public dialog: MatDialog,
    private errorService: ErrorService,
    private lotService: InventoryService,
    public router: Router,
    public tenantWordPipe: TenantWordsPipe,
    private bottomSheet: MatBottomSheet,
    public filterService: KwFilterDropdownService,
    public lotInventoryService: lotInventoryService,
    public authService: AuthService
  ) {

  }

  ngOnInit() {
    this.dataSource = new LotsListDataSource(this.paginator, this.sort);

    this.endColumns = [
      { headerText: 'Actions', customAttributes: this.setActionsWidth, field: 'actions' }
    ];

    this.baseColumns = [
      { field: 'lotNumber', headerText: 'Lot Number', autoFit: true, clipMode: "EllipsisWithTooltip" },
      { field: 'productCust', valueAccessor: this.getProduct, headerText: this.tenantWordPipe.transform("Products"), autoFit: true, format: "N0", clipMode: "EllipsisWithTooltip" },
      { field: 'varietyName', headerText: 'Variety', autoFit: true, clipMode: "EllipsisWithTooltip" },
      { field: 'blockName', headerText: 'Block', autoFit: true, clipMode: "EllipsisWithTooltip" },
      { field: 'farmName', headerText: 'Farm', autoFit: true, clipMode: "EllipsisWithTooltip" },
      { field: 'totalQtyOnHand', headerText: this.tenantWordPipe.transform("Available Qty"), autoFit: true, format: "N0" },
      { field: 'locationCust', headerText: this.tenantWordPipe.transform("Location"), autoFit: true, format: "N0", clipMode: "EllipsisWithTooltip" },
      { field: 'weight', headerText: this.tenantWordPipe.transform("Weight"), autoFit: true, format: "N0" },
      { field: 'createdDate', headerText: 'Created', autoFit: true },

    ]

    // this.filterSettings = { type: "Menu" }; 
    this.filter = { type: "CheckBox" }
    this.updateTable();
  }

  updateTable() {
    this.isLoading = true;
    Observable.forkJoin([
      this.lotService.getLots(),
      this.lotService.getInventoryLotAttributes()
    ]).subscribe(x => {

      x[0].forEach(y => {
        y.createdDate = this.datePipe.transform(y.createdDate, 'MM/dd/yy');
      })
      this.lots = x[0];
      this.allLots = x[0];
      this.attrabutes = x[1];

      // this.allInventoryHums = x[2];


      for (let value of this.attrabutes) {
        this.columnNames.push(value.name);
      }

      this.dataSource.data = this.lots;

      this.setColumns();
      this.isLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    }
    );
  }

  setColumns() {
    this.columns = [];

    this.columns.push(...this.baseColumns);

    let details = _.uniq(this.lots.flatMap(x => x.attributes), x => x.customAttributeId);

    let hums = this.lots.filter(f => f.summaryHums != null).flatMap(x => x.summaryHums);
    let huds = hums.flatMap(j => j.summaryHuds);


    for (let x of details) {
      for (let est of this.lots) {
        let existingDetail = est.attributes.find(z => z.customAttributeId == x.customAttributeId);

        est[x.customAttributeName.toString()] = existingDetail ? existingDetail.value : '';

      }


      this.columns.push({
        field: x.customAttributeName.toString(),
        headerText: x.customAttributeName.toString(),
        clipMode: "EllipsisWithTooltip",
        autoFit: true,
        filter: this.filter
      });
    }



    let uniqComponents = _.uniq(huds.map(x => x.packageComponentName)).filter(j => j != '');
    let uniqUnitOfMeasures = _.uniq(huds.map(x => x.packageUnitOfMeasure)).filter(j => j != '');


    this.columns.push(...uniqUnitOfMeasures.map(x => <ColumnModel>{ field: x, headerText: x, autoFit: true, format: 'N2' }));
    this.columns.push(...uniqComponents.map(x => <ColumnModel>{ field: x, headerText: x, autoFit: true, format: 'N2' }));

    // Add the 'Actions' column only if the user has the 'inventory:admin' role
    if (this.authService.hasRole('inventory:admin')) {
      this.columns.push(...this.endColumns);
      this.columns.find(x => x.headerText == 'Actions').template = this.actionTemplate;
    }

    this.lots.forEach(x => {

      uniqUnitOfMeasures.forEach(f => { x[f] = 0 });
      uniqComponents.forEach(f => { x[f] = 0 });

      if (x && x.summaryHums != null)

        x["locationCust"] = this.getLocation(x);

    });

    this.aggregates = [];
    this.aggregates.push(this.aggregate);
  }

  onDataBound() {
    if (this.grid) {
      this.grid.autoFitColumns();  // Use the grid instance to call autoFitColumns
    }
  }

  setFilterResults(results: FilterResults) {
    if (this.initialLoad) {
      this.initialLoad = false;
      results = this.filterService.getFilterState();
    }

    let filteredResults = [...this.allLots.filter(x =>
      (results.selectedFarms.map(y => y.id).includes(x.farmId) || results.selectedFarms.length === 0) &&
      (results.selectedBlocks.map(y => y.id).includes(x.blockId) || results.selectedBlocks.length === 0) &&
      (results.selectedLotNumbers.map(y => y.lotNumber).includes(x.lotNumber) || results.selectedLotNumbers.length === 0) &&
      (results.selectedVarieties.map(y => y.id).includes(x.varietyId) || results.selectedVarieties.length === 0) &&
      (results.selectedFieldmen.map(y => y.itemName).includes(x.fieldman) || results.selectedFieldmen.length === 0) &&
      //(results.selectedCropYears.map(y => y.id).includes(x.cropYearId) || results.selectedCropYears.length === 0) &&
      (this.containsWarehouse(results.selectedWarehouses.map(y => y.id), x.summaryHums) || results.selectedWarehouses.length === 0) &&
      (this.containsRoom(results.selectedRooms.map(y => y.id), x.summaryHums) || results.selectedRooms.length === 0) &&
      (this.containsLocation(results.selectedLocations.map(y => y.id), x.summaryHums) || results.selectedLocations.length === 0) &&
      (this.containsProduct(results.selectedInventoryItems.map(y => y.name), x.summaryHums) || results.selectedInventoryItems.length === 0)
    )];

    this.lots = [...filteredResults];
  }

  public containsWarehouse(arr1, arr2: SummaryHum[]) {

    let value = false;

    arr1.forEach(x => {
      if (arr2)
        if (arr2.map(y => y.warehouseId).includes(x))
          value = true;
    });

    return value;
  }

  public containsRoom(arr1, arr2: SummaryHum[]) {

    let value = false;

    arr1.forEach(x => {
      if (arr2)
        if (arr2.map(y => y.roomId).includes(x))
          value = true;
    });

    return value;
  }

  public containsLocation(arr1, arr2: SummaryHum[]) {
    let value = false;

    arr1.forEach(x => {
      if (arr2)
        if (arr2.map(y => y.locationId).includes(x))
          value = true;
    });

    return value;
  }

  public containsProduct(arr1, arr2) {
    let value = false;

    arr1.forEach(x => {
      if (arr2) {
        let sumHuds = arr2.map(g => g.summaryHuds);
        let huds = [];
        sumHuds.forEach(sh => sh.forEach(th => huds.push(th)));
        if (huds.map(y => y.itemName).includes(x))
          value = true;
      }
    });

    return value;
  }

  public rowClicked(args): void {
    let data = {
      lot: args,
      isPopup: true
    };

    this.dialog.open(LotsOptionsDiologComponent, { data, disableClose: false }).afterClosed().subscribe(val => {
      if (val == "dash")
        this.router.navigateByUrl('/harvest-engine/inventory/lot-dashboard/' + args.id);

      else if (val == "edit") {
        let data = {
          lot: args,
          isPopup: true
        };

        this.bottomSheet.open(InventoryLotEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

        let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
          this.grid.clearSelection();
          this.updateTable();
          bottomSheetSubscription.unsubscribe();
        });
      }
      else if (val == 'move') {
        this.dialog.closeAll();

        this.isLoading = true;
        this.lotService.getInventoryHUMs(false, args.id).subscribe(x => {
          this.allInventoryHums = x;

          let huds = this.allInventoryHums.flatMap(hum => hum.handlingUnitDetails);

          let filteredHuds = huds.filter(hud => hud.lotId == args.id);

          args.handlingUnitDetailId = filteredHuds.map(hud => hud.id);
          args.handlingUnitMasterId = filteredHuds.map(hud => hud.handlingUnitMasterId);

          let moveData = {
            lot: args,
            isPopup: true,
            type: "transfer",
            lotId: data.lot.id,
            lotName: args.lotNumber,
          };

          this.isLoading = false;
          this.bottomSheet.open(LotDetailsByRoomMoveInventoryComponent, { panelClass: 'bottomsheet-50-width', data: moveData, disableClose: false });
          let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
            if (job == 'reload') {
              this.updateTable();
            }
            this.allInventoryHums = [];
            bottomSheetSubscription.unsubscribe();
          });
        }, error => {
          this.isLoading = false;
        });
      } else if (val == 'adjust') {
        console.log(data)
        this.isLoading = true;
        this.lotService.getInventoryHUMs(false, data.lot.id, null, null, null).subscribe((data) => {

          let huds = data.flatMap(hum => hum.handlingUnitDetails);

          // let filteredHuds = huds.filter(hud =>
          //   hud.blockId == this.blockId &&
          //   hud.farmId == this.farmId &&
          //   hud.varietyId == this.varietyId &&
          //   hud.isActive
          // );

          // group huds by lot information
          // let groupedHuds = _.groupBy(filteredHuds, hud => hud.lotNumber);
          if (huds.length == 0) {
            //TODO: show error message
            this.dialog.closeAll();
            const confRef = this.dialog.open(ConfirmationDialogComponent, {
              minWidth: '10%',
              data: {
                confirmLabel: 'Ok',
                title: 'No Active Inventory',
                confirmMessage: 'There is no active inventory for this lot.',
                isWarning: true
              }
            });
          }
          else {
            console.log(huds)
            let groupedHuds = _.groupBy(huds, hud => hud.lotNumber);


            let temp = [...Object.keys(groupedHuds).map(key => {
              let huds = groupedHuds[key];
              let quantity = huds.reduce((acc, hud) => acc + hud.quantity, 0);
              return {
                lotNumber: key,
                lotId: huds[0].lotId,
                receiptDate: new Date(huds[0].createdDate),
                handlingUnitDetailId: huds.map(hud => hud.id),
                handlingUnitMasterId: huds.map(hud => hud.handlingUnitMasterId),
                quantity: quantity,
                originalQuantity: quantity
              };
            })];
            console.log(temp)
            this.dialog.closeAll();

            this.dialog.open(LotDetailsByRoomDialogComponent, { data: temp[0], disableClose: false, width: '750px' }).afterClosed().subscribe((val: lotInventory) => {
              if (val) {
                let adjustmentQuantity = val.quantity - val.originalQuantity;

                console.log(val)

                if (adjustmentQuantity > 0) {
                  val.quantity = Math.abs(adjustmentQuantity);
                  this.lotInventoryService.lotInventoryAdd(val).subscribe(x => {
                    // this.refreshTable.emit(true);
                    this.updateTable();
                  });
                } else {
                  val.quantity = Math.abs(adjustmentQuantity);

                  this.lotInventoryService.lotInventoryRemove(val).subscribe(x => {
                    // this.refreshTable.emit(true);
                    this.updateTable();
                  });
                }
              }
            });
          }
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
        });
      }
    });
  }

  createLot() {
    let data = {
      lot: {},
      isPopup: true
    };

    this.bottomSheet.open(InventoryLotEditComponent, { panelClass: 'bottomsheet-80-width', data, disableClose: false });

    let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
      this.grid.clearSelection();
      this.updateTable();
      bottomSheetSubscription.unsubscribe();
    });
  }

  public getHeaderValue(field: string, column: InventoryLotAttributeDTO) {
    return column.name;
  }

  public getValue(field, data: InventoryLotDTO, column) {
    let attribute = data.attributes.find(x => x.customAttributeName == column.headerText);

    if (attribute)
      return attribute.value;
    else
      return '';

  }

  toolbarClick() {
    this.grid.excelExport();
  }

  public getLocation(data: InventoryLotDTO) {
    let results = [];

    if (data.summaryHums) {
      for (let summaryHum of data.summaryHums) {
        results.push(`${(summaryHum.warehouse ? summaryHum.warehouse : '')} ${(summaryHum.room ? summaryHum.room : '')} ${(summaryHum.location ? summaryHum.location : '')}`)
      }
    }

    return _.uniq(results).join(', ');
  }

  public getProduct(field, data: InventoryLotDTO, column) {
    var value = "";
    var first = true;

    var details = [];
    if (data.summaryHums)
      data.summaryHums.forEach(x => x.summaryHuds.forEach((t, index) => {
        if (t.lotId == data.id)
          details.push(t);
      }));

    var items = [];
    details.forEach(x => items.push(x.itemName))

    _.uniq(items).forEach(x => {
      if (first && x.length > 0) {
        value = x
        first = false;
      }
      else
        if (x.length > 0)
          value = value += ", " + x;
    });

    return value;
  }

}
