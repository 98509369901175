import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { InventoryBatchDTO } from '../models/inventory-batch-dto';

@Injectable({
  providedIn: 'root'
})
export class InventoryBatchService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getAll(workOrderId: number = 0, userId = 0) {

    let queryParameters = new HttpParams();

    if (workOrderId !== undefined && workOrderId !== null) {
      queryParameters = queryParameters.set('workOrderId', <any>workOrderId);
    }

    if (userId !== undefined && userId !== null) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<InventoryBatchDTO[]>(`${this.basePath}/api/inventory/inventoryBatch`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }



  public voidBatch(id: number, reasonCodeId: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    let queryParameters = new HttpParams();

    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('batchId', <any>id);
    }

    if (reasonCodeId !== undefined && reasonCodeId !== null) {
      queryParameters = queryParameters.set('reasonCodeId', <any>reasonCodeId);
    }


    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/inventory/inventoryBatch`,
      {
        headers,
        params: queryParameters
      }
    );
  }


  public reprintBatch(id: number, printerId: number, type: string = ''): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('batchId', <any>id);
    }

    if (printerId !== undefined && printerId !== null) {
      queryParameters = queryParameters.set('printerId', <any>printerId);
    }
    if (type !== undefined && type !== null && type !== '') {
      queryParameters = queryParameters.set('type', <any>type);
    }


    return this.httpClient.put(`${this.basePath}/api/inventory/inventoryBatch/reprint`, null,
      {
        headers,
        params: queryParameters
      }
    );
  }
}
