import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';

import { Observable, of } from 'rxjs';
import { RandomNumberGenerationSource } from 'd3';
import { random } from 'lodash';
import { InventoryItemDTO } from '../../inventory/models/item';
import { HandlingUnitMasterDTO } from '../../inventory/models/handling-unit-master';
import { InventoryLotAttributeValue, InventoryLotDTO, InventoryLotSummaryDTO } from '../../inventory/models/inventory-lot';
import { InventoryItemAttributeDTO } from '../../inventory/models/item-attribute';
import { InventoryLotAttributeDTO } from '../../inventory/models/lot-attribute';
import { InventoryStatusDTO } from '../../inventory/models/inventory-status';
import { InventoryItemAssemblyAttributeDTO, InventoryItemAssemblyDTO } from '../../inventory/models/item-assembly';
import { HandlingUnitBulkDelete, HandlingUnitBulkMove } from '../../inventory/models/handling-unit-bulk-actions';
import { InventoryPackageTypeDTO } from '../../inventory/models/inventory-package-type';
import { HandlingUnitDetailDTO } from '../../inventory/models/handling-unit-detail';
import { ReasonCode } from '../../inventory/models/reason-code';
import { ErrorService } from '../../../../@core/utils/error.service';
import { WorkOrder } from '../../work-orders/models/work-order';
import { Utils } from '../../../../shared/utility';
import { ActionTypeForInvetory } from '../../../../shared/enum';




@Injectable({
  providedIn: "root",
})
export class InventoryService {
  protected basePath = "/";
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
    private errorService: ErrorService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
    this.refreshLocalLots();
  }

  public allLots: InventoryLotDTO[] = [];
  public allLotsForFilters: InventoryLotDTO[] = [];

  public cachedLotSummaries: InventoryLotSummaryDTO[] = [];

  public selectedHUD: HandlingUnitDetailDTO;

  public getInventoryItems(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryItemDTO[]>(
      `${this.basePath}/api/inventory/items`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryStatuses(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryStatusDTO[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/status`,
      //return this.httpClient.get<any[]>(`${this.basePath}/api/inventory/HandlingUnitMaster/status`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryItem(itemId: number): Observable<InventoryItemDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryItemDTO>(
      `${this.basePath}/api/inventory/items/${itemId}`,
      {
        headers,
      }
    );
  }

  public postInventoryItem(
    item: InventoryItemDTO,
    saveERPFlag: boolean = false
  ): Observable<InventoryItemDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryItemDTO>(
      `${this.basePath}/api/inventory/items?saveERPFlag=${saveERPFlag}`,
      item,

      {
        headers,
      }
    );
  }

  public putInventoryItem(
    item: InventoryItemDTO,
    saveERPFlag: boolean = false
  ): Observable<InventoryItemDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<InventoryItemDTO>(
      `${this.basePath}/api/inventory/items/${item.id}?saveERPFlag=${saveERPFlag}`,
      item,
      {
        headers,
      }
    );
  }

  public deleteInventoryItem(
    id: number,
    saveERPFlag: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/items/${id}?saveERPFlag=${saveERPFlag}`,
      {
        headers,
      }
    );
  }

  public getInventorySummary(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    queryParameters = queryParameters.set("inventorySummary", <any>true);

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<HandlingUnitMasterDTO[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  //Hum Calls

  public getWorkOrdersByLot(lotId: number): Observable<WorkOrder[]> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<WorkOrder[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/workorders/${lotId}`,
      {
        headers,
      }
    );
  }

  public getInventoryHUMs(
    includeInactive: boolean = false,
    lotId: number = 0,
    orderNumber?: string,
    warehouseId = 0,
    roomId = 0,
    roomLocationId = 0,
    includeTransactions = false,
    includeWorkOrders = false,
    inventorySummary: boolean = false,
    includeOnOrder = false
  ) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    if (includeTransactions !== undefined && includeTransactions !== null) {
      queryParameters = queryParameters.set(
        "includeTransactions",
        <any>includeTransactions
      );
    }

    if (includeWorkOrders !== undefined && includeWorkOrders !== null) {
      queryParameters = queryParameters.set(
        "includeWorkOrders",
        <any>includeWorkOrders
      );
    }

    if (lotId && lotId !== 0) {
      queryParameters = queryParameters.set("lotId", <any>lotId);
    }

    if (warehouseId && warehouseId !== 0) {
      queryParameters = queryParameters.set("warehouseId", <any>warehouseId);
    }

    if (roomId && roomId !== 0) {
      queryParameters = queryParameters.set("roomId", <any>roomId);
    }

    if (roomLocationId && roomLocationId !== 0) {
      queryParameters = queryParameters.set("locationId", <any>roomLocationId);
    }

    if (orderNumber && orderNumber !== "") {
      queryParameters = queryParameters.set("orderNumber", <any>orderNumber);
    }

    if (includeOnOrder) {
      queryParameters = queryParameters.set(
        "includeOnOrder",
        <any>includeOnOrder
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<HandlingUnitMasterDTO[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryHUM(
    id: string,
    includeInactive: boolean = false
  ): Observable<HandlingUnitMasterDTO> {
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set(
      "IncludeInactive",
      <any>includeInactive
    );

    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.get<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/${id}`,
      {
        params: queryParameters,
        headers,
      }
    );
  }

  public getInventoryHUMByBarcode(
    id: string,
    includeInactive: boolean = false
  ): Observable<HandlingUnitMasterDTO> {
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set(
      "IncludeInactive",
      <any>includeInactive
    );

    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.get<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/barcode/${id}`,
      {
        params: queryParameters,
        headers,
      }
    );
  }

  public reprintInventoryHUM(id: string, printerId: string) {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set("id", id);
    queryParameters = queryParameters.set("printerId", printerId);

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/reprint`,
      null,
      {
        headers,
        params: queryParameters,
      }
    );
  }

  public bulkReprintInventoryHUM(ids: number[], printerId: number) {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set("printerId", printerId.toString());

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/reprintBulk`,
      ids,
      {
        headers,
        params: queryParameters,
      }
    );
  }

  public reprintBulkTag(
    barcodeList: string[],
    printerId: number,
    printQuantity: number,
    tagName: string
  ) {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set("printerId", printerId.toString());
    queryParameters = queryParameters.set(
      "printQuantity",
      printQuantity.toString()
    );
    queryParameters = queryParameters.set("tagName", tagName);

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    console.log(barcodeList);

    return this.httpClient.put<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/reprintBulkTag`,
      barcodeList,
      {
        headers,
        params: queryParameters,
      }
    );
  }

  public postInventoryHUM(
    item: HandlingUnitMasterDTO,
    saveERPFlag: boolean = false
  ): Observable<HandlingUnitMasterDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.post<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster?saveERPFlag=${saveERPFlag}`,
      item,

      {
        headers,
      }
    );
  }

  public postBulkInventoryHUM(
    items: HandlingUnitMasterDTO[],
    printerId: number = 0,
    printQuantity: number = 1,
    saveERPFlag: boolean = false
  ): Observable<HandlingUnitMasterDTO[]> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    let queryParameters = new HttpParams();

    if (printerId !== undefined && printerId !== null && printerId != 0) {
      queryParameters = queryParameters.set("printerId", <any>printerId);
    }
    queryParameters = queryParameters.set("printQuantity", <any>printQuantity);

    return this.httpClient.post<HandlingUnitMasterDTO[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/bulk?saveERPFlag=${saveERPFlag}`,
      items,

      {
        params: queryParameters,
        headers,
      }
    );
  }

  public putInventoryHUM(
    item: HandlingUnitMasterDTO,
    isERPSave?: boolean,
    actionType?: ActionTypeForInvetory
  ): Observable<HandlingUnitMasterDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    let queryParameters = new HttpParams();

    if (isERPSave)
      queryParameters = queryParameters.set(
        "saveERPFlag",
        isERPSave.toString()
      );

    if (!Utils.IsStringNullOrEmpty(actionType)) {
      queryParameters = queryParameters.set(
        "actionType",
        actionType.toString()
      );
    }

    return this.httpClient.put<HandlingUnitMasterDTO>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/${item.id}`,
      item,
      {
        params: queryParameters,
        headers,
      }
    );
  }

  public deleteInventoryHUM(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/HandlingUnitMaster/${id}`,
      {
        headers,
      }
    );
  }

  public getLots(includeInactive: boolean = false, workOrderId: number = 0) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    queryParameters = queryParameters.set(
      "workOrderId",
      workOrderId.toString()
    );

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryLotDTO[]>(
      `${this.basePath}/api/inventory/lots`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getLotsForFilters(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryLotDTO[]>(
      `${this.basePath}/api/inventory/lots/forFilters`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  //Lot Calls

  public getInventoryLots(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryLotDTO[]>(
      `${this.basePath}/api/inventory/lots`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryLotsForFilters(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryLotDTO[]>(
      `${this.basePath}/api/inventory/lots/forFilters`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryLot(lotId: number): Observable<InventoryLotDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryLotDTO>(
      `${this.basePath}/api/inventory/lots/${lotId}`,
      {
        headers,
      }
    );
  }

  public postInventoryLot(lot: InventoryLotDTO): Observable<InventoryLotDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryLotDTO>(
      `${this.basePath}/api/inventory/lots`,
      lot,

      {
        headers,
      }
    );
  }

  public putInventoryLot(lot: InventoryLotDTO): Observable<InventoryLotDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<InventoryLotDTO>(
      `${this.basePath}/api/inventory/lots/${lot.id}`,
      lot,
      {
        headers,
      }
    );
  }

  public deleteInventoryLot(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(`${this.basePath}/api/inventory/lots/${id}`, {
      headers,
    });
  }

  //item attributes

  public getInventoryItemAttributes(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryItemAttributeDTO[]>(
      `${this.basePath}/api/inventory/itemAttributes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryItemAttribute(
    itemAttributesId: number
  ): Observable<InventoryItemAttributeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryItemAttributeDTO>(
      `${this.basePath}/api/inventory/itemAttributes/${itemAttributesId}`,
      {
        headers,
      }
    );
  }

  public postInventoryItemAttributes(
    itemAttributes: InventoryItemAttributeDTO
  ): Observable<InventoryItemAttributeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryItemAttributeDTO>(
      `${this.basePath}/api/inventory/itemAttributes`,
      itemAttributes,

      {
        headers,
      }
    );
  }

  public putInventoryItemAttributes(
    itemAttributes: InventoryItemAttributeDTO
  ): Observable<InventoryItemAttributeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<InventoryItemAttributeDTO>(
      `${this.basePath}/api/inventory/itemAttributes/${itemAttributes.id}`,
      itemAttributes,
      {
        headers,
      }
    );
  }

  public deleteInventoryItemAttributes(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/itemAttributes/${id}`,
      {
        headers,
      }
    );
  }

  //lot attributes

  public getInventoryLotAttributes(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryLotAttributeDTO[]>(
      `${this.basePath}/api/inventory/lotAttributes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryLotAttribute(
    lotAttributesId: number
  ): Observable<InventoryLotAttributeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryLotAttributeDTO>(
      `${this.basePath}/api/inventory/lotAttributes/${lotAttributesId}`,
      {
        headers,
      }
    );
  }

  public postInventoryLotAttributes(
    lotAttributes: InventoryLotAttributeDTO
  ): Observable<InventoryLotAttributeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryLotAttributeDTO>(
      `${this.basePath}/api/inventory/lotAttributes`,
      lotAttributes,

      {
        headers,
      }
    );
  }

  public putInventoryLotAttributes(
    lotAttributes: InventoryLotAttributeDTO
  ): Observable<InventoryLotAttributeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<InventoryLotAttributeDTO>(
      `${this.basePath}/api/inventory/lotAttributes/${lotAttributes.id}`,
      lotAttributes,
      {
        headers,
      }
    );
  }

  public deleteInventoryLotAttributes(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/lotAttributes/${id}`,
      {
        headers,
      }
    );
  }

  //Inventory Item Assemblies -------------

  public getInventoryItemAssemblies(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryItemAssemblyDTO[]>(
      `${this.basePath}/api/inventory/ItemAssemblies`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryItemAssembly(
    id: number
  ): Observable<InventoryItemAssemblyDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryItemAssemblyDTO>(
      `${this.basePath}/api/inventory/ItemAssemblies/${id}`,
      {
        headers: headers,
      }
    );
  }

  public getInventoryItemAssemblyByItemId(
    itemId: number
  ): Observable<InventoryItemAssemblyDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryItemAssemblyDTO>(
      `${this.basePath}/api/inventory/ItemAssemblies/byItem/${itemId}`,
      {
        headers: headers,
      }
    );
  }

  public postInventoryItemAssembly(
    itemAssembly: InventoryItemAssemblyDTO
  ): Observable<InventoryItemAssemblyDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryItemAssemblyDTO>(
      `${this.basePath}/api/inventory/ItemAssemblies`,
      itemAssembly,

      {
        headers,
      }
    );
  }

  public putInventoryItemAssembly(
    itemAssembly: InventoryItemAssemblyDTO
  ): Observable<InventoryItemAssemblyDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<InventoryItemAssemblyDTO>(
      `${this.basePath}/api/inventory/ItemAssemblies/${itemAssembly.id}`,
      itemAssembly,
      {
        headers,
      }
    );
  }

  public deleteInventoryItemAssembly(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/ItemAssemblies/${id}`,
      {
        headers,
      }
    );
  }

  public getInventoryItemAssemblyAttributes(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryItemAssemblyAttributeDTO[]>(
      `${this.basePath}/api/inventory/ItemAssemblyAttributes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public deleteInventoryItemAssemblyComponent(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/ItemAssemblyComponents/${id}`,
      {
        headers,
      }
    );
  }

  public postInventoryFromAssembly(
    itemAssembly: InventoryItemAssemblyDTO,
    warehouseId: number,
    roomId: number = 0,
    locationId: number = 0,
    itemCount: number = 0,
    workOrderId: number,
    printerId: number = 0
  ): Observable<InventoryItemAssemblyDTO> {
    let headers = this.defaultHeaders;
    let queryParameters = new HttpParams();

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    if (warehouseId !== undefined && warehouseId !== null && warehouseId != 0) {
      queryParameters = queryParameters.set("warehouseId", <any>warehouseId);
    }

    if (roomId !== undefined && roomId !== null && roomId != 0) {
      queryParameters = queryParameters.set("roomId", <any>roomId);
    }

    if (locationId !== undefined && locationId !== null && locationId != 0) {
      queryParameters = queryParameters.set("locationId", <any>locationId);
    }

    if (itemCount !== undefined && itemCount !== null && itemCount != 0) {
      queryParameters = queryParameters.set("itemCount", <any>itemCount);
    }

    if (workOrderId !== undefined && workOrderId !== null && workOrderId != 0) {
      queryParameters = queryParameters.set("workOrderId", <any>workOrderId);
    }

    if (printerId !== undefined && printerId !== null && printerId != 0) {
      queryParameters = queryParameters.set("printerId", <any>printerId);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryItemAssemblyDTO>(
      `${this.basePath}/api/inventory/ItemAssemblies/InventoryFromAssembly`,
      itemAssembly,

      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public bulkMoveHandlingUnitMasters(
    request: HandlingUnitBulkMove
  ): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put(
      `${this.basePath}/api/inventory/bulkmove/`,
      request,
      {
        headers,
      }
    );
  }

  public bulkDeleteHandlingUnitMasters(
    request: HandlingUnitBulkDelete
  ): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put(
      `${this.basePath}/api/inventory/bulkdelete/`,
      request,
      {
        headers,
      }
    );
  }

  public getInventoryPackageTypes(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<InventoryPackageTypeDTO[]>(
      `${this.basePath}/api/inventory/inventoryPackageTypes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getInventoryPackageType(
    itemId: number
  ): Observable<InventoryPackageTypeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InventoryPackageTypeDTO>(
      `${this.basePath}/api/inventory/inventoryPackageTypes/${itemId}`,
      {
        headers,
      }
    );
  }

  public postInventoryPackageType(
    item: InventoryPackageTypeDTO
  ): Observable<InventoryPackageTypeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.post<InventoryItemDTO>(
      `${this.basePath}/api/inventory/inventoryPackageTypes`,
      item,

      {
        headers,
      }
    );
  }

  public putInventoryPackageType(
    item: InventoryPackageTypeDTO
  ): Observable<InventoryPackageTypeDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.put<InventoryPackageTypeDTO>(
      `${this.basePath}/api/inventory/inventoryPackageTypes/${item.id}`,
      item,
      {
        headers,
      }
    );
  }

  public deleteInventoryPackageType(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/inventoryPackageTypes/${id}`,
      {
        headers,
      }
    );
  }

  public deleteInventoryHUD(id: number): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete(
      `${this.basePath}/api/inventory/HandlingUnitDetail/${id}`,
      {
        headers,
      }
    );
  }

  public putInventoryHUD(
    hud: HandlingUnitDetailDTO,
    isERPSave?: boolean,
    actionType?: ActionTypeForInvetory
  ): Observable<HandlingUnitDetailDTO> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    let queryParameters = new HttpParams();
    if (isERPSave)
      queryParameters = queryParameters.set(
        "saveERPFlag",
        isERPSave.toString()
      );

    if (!Utils.IsStringNullOrEmpty(actionType)) {
      queryParameters = queryParameters.set(
        "actionType",
        actionType.toString()
      );
    }
    return this.httpClient.put<HandlingUnitDetailDTO>(
      `${this.basePath}/api/inventory/HandlingUnitDetail/${hud.id}`,
      hud,
      {
        params: queryParameters,
        headers,
      }
    );
  }

  public getReasonCodes(includeInactive: boolean = false) {
    let queryParameters = new HttpParams();

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set(
        "IncludeInactive",
        <any>includeInactive
      );
    }

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    // return this.httpClient.get<ReasonCode[]>(`${this.basePath}/api/inventory/reasonCodes`, //This doesn't even exist???
    return this.httpClient.get<ReasonCode[]>(
      `${this.basePath}/api/transactionReasonCodes`,

      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public refreshLocalLots() {
    // this.getInventoryLots().subscribe(lots => {
    //   this.allLots = lots;
    // }, err => this.errorService.handleError(err));
    this.getInventoryLotsForFilters().subscribe(
      (lots) => {
        this.allLotsForFilters = lots;
      },
      (err) => this.errorService.handleError(err)
    );
  }

  public getLotMassBalance(lotId: number): Observable<any[]> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<any[]>(
      `${this.basePath}/api/inventory/lots/massbalance/${lotId}`,
      {
        headers,
      }
    );
  }

  public getPackoutReport(year: string): Observable<any[]> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<any[]>(
      `${this.basePath}/api/inventory/Packout/summary?year=${year}`,
      {
        headers,
      }
    );
  }

  public updateMassBalanceReason(record: any): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );
    return this.httpClient.put(
      `${this.basePath}/api/inventory/lots/update-mass-balance-record`,
      record,
      { headers }
    );
  }

  public getAllIntegrationId() {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get<string[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/getAllIntegrationId`,
      {
        headers: headers,
      }
    );
  }

  public getAllByIntegrationId(integrationId:string) {
    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.get(
      `${this.basePath}/api/inventory/HandlingUnitMaster/getAllInventoryByIntegrationId?integrationId=${integrationId}`,
      {
        headers: headers,
      }
    );
  }

  //Update Hot Room fields
  public updateHotRoomBulk(items: HandlingUnitMasterDTO[]): Observable<HandlingUnitMasterDTO[]> {
    let headers = this.defaultHeaders;

    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );
    return this.httpClient.put<HandlingUnitMasterDTO[]>(
      `${this.basePath}/api/inventory/HandlingUnitMaster/updateHotRoomBulk`,
      items,
      {
        headers: headers,
      }
    );
  }

  //Get all Barcodes list by it's ids
  public getAllHUMbyId(ids:number[]) {

    let headers = this.defaultHeaders;
    headers = headers.set(
      "Authorization",
      String(this.authService.getUserToken())
    );

    return this.httpClient.post(
      `${this.basePath}/api/inventory/HandlingUnitMaster/getAllHUMbyId`,
      ids,
      {
        headers: headers,
      }
    );
  }
}
