import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rooms-list',
  templateUrl: './rooms-list.component.html',
  styleUrls: ['./rooms-list.component.scss']
})
export class RoomsListComponent implements OnInit {
  
  public loading: boolean = true;
  public faPlusCircle = faPlusCircle;
  
  constructor(public router: Router) { }
  
  ngOnInit() {
  }

  onLoaded(){
    this.loading = false;
  }

}
