import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { environment } from '../../../../../../../environments/environment';
import { Cart, CartItem, Payment, ShippingAddress } from '../models/cart';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root'
})
export class CartApiService {

  protected basePath = '/';
  protected basePathKW = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.HOLLINGBERY_API_URL;
    this.basePathKW = environment.PLATFORM_API_URL;
  }

  public updateCart(cart: Cart): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    return this.httpClient.post(`${this.basePath}/api/cart`, cart,
      {
        // withCredentials: this.configuration.withCredentials,
        headers
      }
    );
  }

  // public putCart(cart: Cart): Observable<any> {

  //   let headers = this.defaultHeaders;

  //   headers = headers.set('Authorization', String(this.authService.getUserToken()));


  //   headers = headers.set('Accept', 'application/json');

  //   headers = headers.set('Content-Type', 'application/json');
s

  //   return this.httpClient.put(`${this.basePath}/api/cart/removeCartItems/${cart.recordId}`, 
  //     {
  //       // withCredentials: this.configuration.withCredentials,
  //       headers
  //     }
  //   );
  // }

  public placeOrder(cart: Cart, orderNumberSeed: number, sendEmail: boolean): Observable<Order> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');
    
    let queryParameters = new HttpParams();

    // headers = headers.set('orderNumberSeed', orderNumberSeed.toString());
    queryParameters = queryParameters.set('orderNumberSeed', orderNumberSeed.toString());

    queryParameters = queryParameters.set('sendEmail', sendEmail.toString());


    return this.httpClient.post(`${this.basePathKW}/api/WebStore/paymentprocessing/placeorder`, cart,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        params: queryParameters
      }
    );
  }

  public placeOrderOld(cart: Cart, orderNumberSeed: number, sendEmail: boolean): Observable<Order> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');

    let queryParameters = new HttpParams();

    // headers = headers.set('orderNumberSeed', orderNumberSeed.toString());
    queryParameters = queryParameters.set('orderNumberSeed', orderNumberSeed.toString());
    queryParameters = queryParameters.set('sendEmail', sendEmail.toString());


    return this.httpClient.post(`${this.basePath}/api/paymentprocessing/placeorder`, cart,
      {
        // withCredentials: this.configuration.withCredentials,
        headers: headers,
        params: queryParameters
      }
    );
  }



  public getCart(): Observable<Cart> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Cart>(`${this.basePath}/api/cart`,
      {
        headers,
      }
    );
  }

  public getCartReport(): Observable<Cart[]> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Cart[]>(`${this.basePathKW}/api/WebStore/carts/CartReport`,
      {
        headers,
      }
    );
  }

  public getPaymentOptions(): Observable<Payment[]> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Payment[]>(`${this.basePath}/api/PaymentProcessing/PaymentOptions`,
      {
        headers,
      }
    );
  }

  public getShippingAddresses(): Observable<ShippingAddress[]> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<ShippingAddress[]>(`${this.basePath}/api/PaymentProcessing/ShippingAddresses`,
      {
        headers,
      }
    );
  }

  public removeCart(cart: Cart): Observable<any> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete<Cart>(`${this.basePathKW}/api/webstore/Carts/RemoveCart/${cart.id}`,

      {
        headers,
      }
    );
  }

  // public removeCartItem(cart: Cart, cartItem: CartItem): Observable<any> {
  //   let headers = this.defaultHeaders;

  //   headers = headers.set('Authorization', String(this.authService.getUserToken()));

  //   const httpHeaderAcceptSelected: string | undefined = 'application/json';
  //   if (httpHeaderAcceptSelected !== undefined) {
  //     headers = headers.set('Accept', httpHeaderAcceptSelected);
  //   }

  //   // to determine the Content-Type header
  //   const consumes: string[] = [
  //   ];

  //   return this.httpClient.put<Cart>(`${this.basePath}/api/cart/RemoveCartItem/${cart.recordId}`, cartItem,

  //     {
  //       headers,
  //     }
  //   );
  // }
  public cartItemDelete(id?: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete(`${this.basePathKW}/api/WebStore/CartItems/${id}`,
      {
        headers: headers,
      }
    );
  }

}
