import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { LoadViewModel } from '../models/load-view-model';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getLoads(includeInactive: boolean = false, includeBins = false, date: Date, loadNumber: string, cropYearId: number = undefined) {

    let queryParameters = new HttpParams();

    if (includeBins !== undefined && includeBins !== null) {
      queryParameters = queryParameters.set('includeBins', <any>includeBins);
    }

    if (cropYearId !== undefined && cropYearId !== null) {
      queryParameters = queryParameters.set('cropYearId', cropYearId.toString());
    }


    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    if (date) {
      queryParameters = queryParameters.set('receiptDate', date.toISOString());
    }

    if (loadNumber) {
      queryParameters = queryParameters.set('loadNumber', loadNumber);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<LoadViewModel[]>(`${this.basePath}/api/loads`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getLoad(loadId: number): Observable<LoadViewModel> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LoadViewModel>(`${this.basePath}/api/loads/${loadId}`,
      {
        headers,
      }
    );
  }

  public getLoadByBinBarcode(barcode: string): Observable<LoadViewModel> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<LoadViewModel>(`${this.basePath}/api/loads/bybinbarcode/${barcode}`,
      {
        headers,
      }
    );
  }

  public postLoad(load: LoadViewModel, printTags: boolean = null, printerId: number = null): Observable<LoadViewModel> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    if(printTags !== null) {
      queryParameters = queryParameters.set('printTags', printTags.toString());
    }

    if(printerId !== null) {
      queryParameters = queryParameters.set('printerId', printerId.toString());
    }
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<LoadViewModel>(`${this.basePath}/api/loads`, load,

      {
        headers,
        params: queryParameters
      }
    );
  }

  public notifyIntegration(loadId: number): Observable<any> {

    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<LoadViewModel>(`${this.basePath}/api/loads/notify?loadId=${loadId.toString()}`, null,

      {
        headers,
      }
    );
  }

  public printTag(loadId: number, printerId: number): Observable<LoadViewModel> {

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('id', <any>loadId);
    queryParameters = queryParameters.set('printerId', <any>printerId);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<LoadViewModel>(`${this.basePath}/api/loads/print`, null,

      {
        headers,
        params: queryParameters
      }
    );
  }

  public putLoadDTO(load: LoadViewModel): Observable<LoadViewModel> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<LoadViewModel>(`${this.basePath}/api/loads/${load.id}`, load,

      {
        headers,
      }
    );
  }

  public deleteLoad(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/loads/${id}`,
      {
        headers,
      }
    );
  }
}
