import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Room } from '../../models/storage/room';
import { RoomLocation } from '../../models/storage/room-location';
import { RoomCustomAttrabute } from '../../models/storage/room-custom-attrabute';
import { RoomStatus } from '../../models/storage/roomStatus';
import { StorageByRoomItem } from '../../../modules/pick-plan/pages/storage-by-room/storage-by-room-table/storageByRoomItem';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getRooms(includeInactive: boolean = false, cropYearIds: number = null): Observable<Room[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('includeInactive', includeInactive.toString());

    if(cropYearIds) {
      queryParameters = queryParameters.append('cropYearIds', cropYearIds.toString());
    }

    return this.httpClient.get<Room[]>(`${this.basePath}/api/rooms`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getRoomLocations(includeInactive: boolean = false): Observable<RoomLocation[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append('includeInactive', includeInactive.toString());

    return this.httpClient.get<RoomLocation[]>(`${this.basePath}/api/RoomLocations`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public getRoomLocation(id: number): Observable<RoomLocation> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<RoomLocation>(`${this.basePath}/api/RoomLocations/${id}`,
      {
        headers,
        params: queryParameters
      }
    );
  }

  public postRoomLocation(roomLocation: RoomLocation): Observable<Room> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<RoomLocation>(`${this.basePath}/api/RoomLocations`, roomLocation,

      {
        headers,
      }
    );
  }

  public putRoomLocation(room: RoomLocation): Observable<RoomLocation[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<RoomLocation[]>(`${this.basePath}/api/RoomLocations/${room.id}`, room,

      {
        headers,
      }
    );
  }

  public deleteRoomLocation(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/RoomLocations/${id}`,
      {
        headers,
      }
    );
  }

  public putRoom(room: Room): Observable<Room[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<Room[]>(`${this.basePath}/api/rooms/${room.id}`, room,

      {
        headers,
      }
    );
  }

  public getRoom(id: number): Observable<Room> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<Room>(`${this.basePath}/api/rooms/${id}`,
      {
        headers,
      }
    );
  }

  public deleteRoom(id: number) {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/rooms/${id}`,
      {
        headers,
      }
    );
  }

  public postRoom(room: Room): Observable<Room> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<Room>(`${this.basePath}/api/rooms`, room,

      {
        headers,
      }
    );
  }

  public getCustomAttributes(): Observable<RoomCustomAttrabute[]> {

    // Test Data
    // return of(this.getTestPickPlans());

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<RoomCustomAttrabute[]>(`${this.basePath}/api/rooms/RoomCustomAttrabutes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getRoomStatuses(): Observable<RoomStatus[]> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    return this.httpClient.get<RoomStatus[]>(`${this.basePath}/api/rooms/roomStatuses`,
      {
        headers,
        params: queryParameters
      }
    );
  }

}
