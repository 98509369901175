import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatAutocomplete, MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { KragUtilitiesService } from '../../../../../@core/utils/krag-utilities.service';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { FarmsService } from '../../../../../harvest-engine/core/services/farms.service';
import { FarmDTO } from '../../../../../harvest-engine/core/models/farm';
import { BlockDTO } from '../../../../../harvest-engine/core/models/block';
import { CropVariety } from '../../../../../harvest-engine/core/models/crop-variety';


@Component({
  selector: 'm-block-select',
  templateUrl: './block-select.component.html',
  styleUrls: ['./block-select.component.scss']
})
export class BlockSelectComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BlockSelectComponent>,
    private farmService: FarmsService,
    private auth: AuthService,
    private kragUtil: KragUtilitiesService,
    private changeDet: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: BlockSelectData,
  ) {
    dialogRef.disableClose = true;
  }

  public farmList: any[];

  public dropdownFarmList: FarmDTO[];

  loading: boolean = true;
  // private blockSelectList: Array<any>;
  public selectedBlocks: BlockDTO[] = [];
  public varietyList: CropVariety[] = [];


  // Farm chip setup
  farmSearchSelectedFarms: any[] = [];
  farmSearchSelectable = true;
  farmSearchRemovable = true;
  farmSearchAddOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  farmSearchCtrl = new FormControl();
  @ViewChild('farmInput') farmInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  ngOnInit() {
    this.selectedBlocks = this.data.selectedBlocks;

    this.farmService.apiFarmsGet().subscribe(result => {
      // sort the farm list
      this.farmList = result;

      this.farmList = this.kragUtil.removeInactiveBlocksFromFarms(this.farmList);
      this.farmList = this.kragUtil.sortFarmsAndBlocks(this.farmList);

      // this.varietyList = this.kragUtil.getVarietiesFromFarmList(this.farmList);

      // add all blocks to the select list
      var loopList = JSON.parse(JSON.stringify(result));
      for (const f of loopList) {
        this.addFarmToSearchList(f);
      }

      this.loading = false;
    });
  }

  onBlockSelect(event, block: BlockDTO) {
    if (!event.checked) {
      this.addSelectedBlock(block);
    } else {
      this.removeSelectedBlock(block);
    }
  }

  removeSelectedBlock(block: BlockDTO) {
    let blockExists = false;
    for (let i = this.selectedBlocks.length - 1; i >= 0; i--) {
      if (this.selectedBlocks[i].id === block.id) {
        blockExists = true;
      }
    }

    if (!blockExists) {
      this.selectedBlocks.push(JSON.parse(JSON.stringify(block)));
    }

  }

  addSelectedBlock(block: BlockDTO) {
    for (let i = this.selectedBlocks.length - 1; i >= 0; i--) {
      if (this.selectedBlocks[i].id === block.id) {
        this.selectedBlocks.splice(i, 1);
      }
    }
  }

  saveAndClose() {
    this.dialogRef.close({ selectedBlocks: this.selectedBlocks });
  }

  // farm search methods

  onFarmSelect(event, farm: FarmDTO) {

    if (event.checked) {

      this.addFarmToSearchList(farm);

    } else {

      // This is a little counter intuitive, but if the selected farms and original farms match
      // we want to remove all the farms except the one selected.  This way the first farm you select,
      // filters out all the others.  This was requested by YCR


      // Because the farm checkbox is tied to the ngModel, the checked value updates instantly.
      // So, we need to subtract one from the total farm amount
      if ((this.farmList.length - 1) === this.farmSearchSelectedFarms.filter(f => f.checked).length) {

        for (let f of this.farmSearchSelectedFarms) {
          if(f.id !== farm.id){
            this.removeFarm(f);
          } else {
            this.changeDet.detectChanges();
            f.checked = true;
            


          }

          // farm.checked = true;
        }
      } else {
        this.removeFarm(farm);
      }

    }
  }

  private addFarmToSearchList(farm: FarmDTO) {

    if (farm !== null && farm !== undefined) {
      // farm the user selected from the dropdown

      let farmToAdd = this.farmList.find(f => f.id == farm.id);

      // the farm that exists in the right-hand list.
      // will only exist if the user selected blocks on a farm
      // and then removed it using the chips
      let existingFarm = this.farmSearchSelectedFarms.find(f => f.id === farm.id);

      // if the existing farm was undefiend
      if (existingFarm === undefined) {
        // farmToAdd.checked = true;
        this.farmSearchSelectedFarms.push(JSON.parse(JSON.stringify(farmToAdd)));
      }
      // if we have an existing farm (likely it won't have the full list of blocks)
      else {
        // existingFarm.checked = true;
        // get the blocks that haven't been added to the farm
        let filteredBlocks = farmToAdd.blocks.filter(function (obj) {

          // return true only if the block doesn't exist
          return !(existingFarm.blocks.some(b => b.id == obj.id && b.isActive === true));
        });

        existingFarm.blocks = existingFarm.blocks.concat(filteredBlocks);
      }
      
    }
  }

  removeFarm(farm: FarmDTO): void {

    // get the farm to remove from the selected list
    const farmToRemove = this.farmSearchSelectedFarms.filter(f => f.id === farm.id)[0];

    const index = this.farmSearchSelectedFarms.indexOf(farmToRemove);

    if (index >= 0) {
      // first check if selected farm has any blocks selected
      for (let i = farmToRemove.blocks.length - 1; i >= 0; i--) {
        const b = farmToRemove.blocks[i];
        // if the block isn't selected, remove it from the farm's blocks
        if (!this.checkIfBlockIsSelected(b.id)) {
          const blockIndex = farmToRemove.blocks.indexOf(b);
          farmToRemove.blocks.splice(blockIndex, 1);
        }
      }

      // if the farm doesn't have any more blocks (meaning, none were selected, remove it)
      if (farmToRemove.blocks.length === 0) {
        farmToRemove.checked = false;
      } else {
        // sort remaining blocks
        farmToRemove.blocks.sort(function (a, b) {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      }

    }
  }

  // UI Methods
  selectAllFarms() {
    for (const f of this.farmList) {
      this.addFarmToSearchList(f);
    }
  }

  close() {
    this.dialogRef.close();
  }
  // UI Checks

  checkIfBlockIsSelected(blockId: number) {
    if (this.selectedBlocks.some(x => x.id === blockId)) {
      return true;
    } else {
      return false;
    }
  }

  farmSelected() {
    for(let x of this.farmSearchSelectedFarms) {
      if(x.checked) {
        return true;
      }
    }
  }


}

export interface BlockSelectData {
  // farmList: Farm[];
  selectedBlocks: BlockDTO[];
}
