import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/auth/auth.service';
import { SyncStatus } from '../../models/sync-status';
import { PrecisionJob } from '../models/precision-job';
import { PrecisionJobDetail } from '../models/precision-job-detail';
import { PrecisionPurchaseOrder } from '../models/precision-purchase-order';
import { PrecisionJobService } from './precision-job.service';

@Injectable({
  providedIn: 'root'
})
export class PrecisionSessionService {


  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    private authService: AuthService,
    protected httpClient: HttpClient,
  ) {
    this.basePath = environment.PRECISION_API_URL;
  }


  public isPoReceive: boolean;
  public jobNumber: string;
  public recordId: number;
  public job: PrecisionJob;
  public precisionJobDetails: PrecisionJobDetail[];
  public precisionPurchaseOrder: PrecisionPurchaseOrder;
  public reportPurchaseOrder: PrecisionPurchaseOrder;
  public precisionJobDetail: PrecisionJobDetail;


  public getSyncStatus(): Observable<SyncStatus> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<SyncStatus>(`${this.basePath}/api/syncstatus`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }


}
