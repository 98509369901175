import { Component, OnInit, Input, SimpleChanges, ChangeDetectorRef, OnChanges } from '@angular/core';
import { BlockHistoryDTO } from '../../../../../harvest-engine/core/models/block';
import { TimelineGroup } from '../../general/vertical-timeline/vertical-timeline.component';

import { TimelineHistoryService } from './timeline-history.service';

@Component({
  selector: 'm-field-lifecycle-timeline',
  templateUrl: './field-lifecycle-timeline.component.html',
  styleUrls: ['./field-lifecycle-timeline.component.scss']
})
export class FieldLifecycleTimelineComponent implements OnInit, OnChanges {

  timeLineGroups: TimelineGroup[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private convertService: TimelineHistoryService,
  ) { }

  @Input()
  blockHistory: BlockHistoryDTO[] = [];
  
  ngOnInit() {
    // this.populateTestData();
  }

  ngOnChanges() {
    this.timeLineGroups = this.convertService.getTimelineGroups(this.blockHistory);
    this.changeDetector.detectChanges();

  }

  populateTestData(): void {
    this.timeLineGroups = [
      {
        timelineYear: '2018',
        timelineItems: [
          // first item
          {
            onclickRoute: '/',
            timelineDates: [
              {
                timelineMonths: [
                  '<i class="fas fa-mars "></i> x4',
                  'Mar. 14',
                ]
              }
            ]
          },
          {
            timelineDates: [
              {
                timelineDays: [
                  '1'
                ],
                timelineMonths: [
                  'Jan.'
                ]
              }
            ],
            timelinePosts: [
              {
                postTitle: 'Brand Change',
                contentInnerHTML: 'Citra <i class="fas fa-long-arrow-alt-right"></i> Pahto'
              }
            ]
          }
        ]
      },
      {
        timelineYear: '2017',
        timelineItems: [
          {
            timelineDates: [
              {
                timelineDays: [
                  '14'
                ],
                timelineMonths: [
                  'Jul.'
                ]
              }
            ],
            timelinePosts: [
              {
                postTitle: 'Harvest',
                contentInnerHTML: '120 bales harvested <i class="fas fa-info-circle fa-xs"></i>'
              }
            ]
          }
        ]
      }
    ];
  }
}
