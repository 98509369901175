import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import {  
  faBan,
  faCheck,
  faEye,
  faPen,
  faPlus,
  faPlusCircle,
  faTimes,
  faTrash, } from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../@core/utils/error.service';
import { StorageTypeDTO } from '../../../modules/pick-plan/models/storage-types-dto';
import { StorageService } from '../../services/storage.service';
import { Observable } from "rxjs";
import {
  ColumnModel,
  FilterSettingsModel,
  GridComponent,
  PageSettingsModel,
} from "@syncfusion/ej2-angular-grids";
import {
  ConfirmationData,
  ConfirmationDialogComponent,
} from "../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component";
import { KmlService } from "../../../../@core/utils/kml.service";
import { NbToastrService } from "@nebular/theme";
import { SessionService } from "../../../../@core/utils/session.service";
import { StorageDetailsComponent } from './storage-details/storage-details.component';

@Component({
  selector: 'storage-list',
  templateUrl: './storage-list.component.html',
  styleUrls: ['./storage-list.component.css']
})
export class StorageListComponent implements OnInit {

  // public editStorage: StorageTypeDTO = {};
  // public storageId: number;

  //#region Properties
  public editActive = false;
  public storageLoading = true;
  public storageTypeId: number;
  public submitting: any;
  public allStorageTypes: StorageTypeDTO[] = [];
  public editStorageType: StorageTypeDTO = {};
  public allColumns: ColumnModel[] = [];
public gridName: string = "StorageTypeConfigGrid";
public pageSettings: PageSettingsModel = {
  pageSize: 50,
  pageSizes: [50, 100, 200],
};
public filterSettings: FilterSettingsModel = {
  mode: "Immediate",
  type: "Excel",
};
public toolbarOptions: string[] = [
  "ExcelExport",
];
public enablePersistence: boolean = true;
  //#endregion
  
//#region Icon Properties
public faPlus = faPlus;
public faPen = faPen;
public faEye = faEye;
public faTrash = faTrash;
public faPlusCircle = faPlusCircle;
public faTimes = faTimes;
public faCheck = faCheck;
public faBan = faBan;
//#endregion

//#region ViewChild Properties
@ViewChild("grid") grid: GridComponent;

@ViewChild("actionTemplate")
public actionTemplate: any;

@ViewChild("isActiveTemplate")
public isActiveTemplate: any;
//#endregion

  constructor(
    private storageService: StorageService,
    private errorService: ErrorService,
    private kmlService: KmlService,
    private toastrService: NbToastrService,
    private sessionService: SessionService,
    public dialogService: MatDialog
  ){}


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  //displayedColumns = ['name', 'description', 'isActive', 'integrationId', 'edit'];

  ngOnInit() {
    this.loadData();
  }

  loadData(){
    this.storageLoading = true;

    Observable.forkJoin([
      this.storageService.getStorageTypes(true),
    ]).subscribe(
      
      (results) => {

        this.allStorageTypes = results[0];

        this.storageLoading = false;
        this.setColumns();
      },
      (err) => {
        this.errorService.handleError(err);
        this.storageLoading = false;
      }
    );
  }

  setColumns() {
    this.allColumns = [];

    this.allColumns.push(
      ...[
        {
          field: "name",
          headerText: "Storage Type Name",
        },
        {
          field: "description",
          headerText: "Description",
        },
        {
          field: "integrationId",
          headerText: "Integration Id",
        },    
        {
          headerText: "Action",
          template: this.actionTemplate,
          width: 200,
        },
      ]
    );
  }

  clearFilters() {
    window.localStorage.setItem("gridStorageTypeConfigFilters", "");
    this.grid.clearFiltering();
  }

  toolbarClick(args) {
    this.storageLoading = true;
    this.grid.aggregates = [];
    this.grid.refresh();

    if (args.item.properties.text == "Excel Export") {
      this.grid
        .excelExport({
          fileName: `StorageTypeExport.xlsx`,
        })
        .then(() => {
          this.storageLoading = false;
        });
    }
  }

  confirmDelete(id: number) {
    const confirmDialogData: ConfirmationData = {
      title: "Confirm Deletion",
      confirmMessage: "Are you sure you want to delete this storage type?",
      confirmLabel: "Delete",
      cancelLabel: "Cancel",
      displayComment: false,
      isWarning: false,
    };

    const confirmDialog = this.dialogService.open(ConfirmationDialogComponent, {
      width: "400px",
      data: confirmDialogData,
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteRec(id);
      }
    });
  }

  deleteRec(id: number) {
    this.storageLoading = true;
    this.storageService.deleteStorageTypeDTO(id).subscribe(
      () => {
        this.toastrService.info("Storage type successfully deleted!");
        this.storageLoading = false;
        this.loadData();
      },
      (err) => {
        this.errorService.handleError(err);
        this.storageLoading = false;
      }
    );
  }


  openDialog(IsEdit: boolean, data?: StorageTypeDTO) {
    const storageTypeDetailsDialog = this.dialogService.open(StorageDetailsComponent, {
      width: "500px",
    });
    if (IsEdit) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("grid" + this.gridName, persistData);
      this.sessionService.selectedStorageType = data;
    } else {
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedStorageType = {} as StorageTypeDTO;
    }

    storageTypeDetailsDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadData();
      }
      window.localStorage.removeItem("grid" + this.gridName);
      this.sessionService.selectedStorageType = {} as StorageTypeDTO;
    });
  }


}
