import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AggregateRowModel, ColumnModel, FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import { OrdersService } from '../../../../../../customer-integrations/hollingbery/modules/financial/orders/services/orders.service';
import { Order } from '../../../../../../customer-integrations/hollingbery/modules/financial/orders/models/order';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { faBinoculars } from '@fortawesome/pro-solid-svg-icons';
import * as moment from 'moment-timezone';
import { SessionService } from '../../../../../../@core/utils/session.service';
import { Router } from '@angular/router';
import { InventoryLotDTO } from '../../../models/inventory-lot';

@Component({
  selector: 'lot-order-summary',
  templateUrl: './lot-order-summary.component.html',
  styleUrls: ['./lot-order-summary.component.scss']
})
export class LotOrderSummaryComponent implements OnInit {
  @Input() allHandlingUnits: HandlingUnitMasterDTO[] = [];
  @Input() lotId: number;
  @Input() lot: InventoryLotDTO = {};

  @ViewChild('actionTemplate')
  public actionTemplate: any;

  public aggregates: AggregateRowModel[] = [];
  public faBinoculars = faBinoculars;
  public isLoading = true;

  public filteredHandlingUnits: HandlingUnitMasterDTO[] = [];
  public handlingUnitDetails: HandlingUnitDetailDTO[] = [];
  public allOrders: Order[] = [];
  public orders: Order[] = [];

  public dateFormat = { type: 'date', format: 'MM/dd/yyyy' };

  public selectionOptions = { type: 'Multiple' };
  public columns: ColumnModel[] = [
    {
      field: 'breweryName',
      headerText: 'Brewery',
      autoFit: true
    },
    {
      field: 'orderNumber',
      headerText: 'Order Number',
      autoFit: true
    },
    {
      field: 'orderDateCust',
      valueAccessor: this.getOrderDate,
      headerText: 'Order Date',
      autoFit: true
    },
    {
      field: 'scheduledShipDateCust',
      valueAccessor: this.getShipDate,
      headerText: 'Ship Date',
      autoFit: true
    },
    {
      field: 'status',
      headerText: 'Status',
      autoFit: true
    },
    {
      field: 'salesRep',
      headerText: 'Sales Rep',
      autoFit: true
    }
  ];

  public data: any[] = [];

  public pageSettings: PageSettingsModel = {
    pageSize: 10,
    pageSizes: [10, 25, 50],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }

  constructor(
    private orderService: OrdersService,
    public session: SessionService,
    private changeDetRef: ChangeDetectorRef,
    private router: Router,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.orderService.getOrders().subscribe(orders => {
      this.allOrders = orders;
      this.aggregates = [];
      let aggregate: AggregateRowModel = { columns: [] };


      this.filteredHandlingUnits = this.allHandlingUnits.filter(x => {
        if (x.handlingUnitDetails.filter(g => g.lotId == this.lotId).length > 0) {
          return true;
        }
        else
          return false;
      });


      //this.orders = orders.filter(x => (this.filteredHandlingUnits.map(y => y.handlingUnitDetails.map(k => k.lotId)).includes(x.)));
      //this.orders = this.filterOrdersByLotNumber(orders, this.filteredHandlingUnits, this.lot.lotNumber);

      this.orders = orders.filter(order => {
        if (order.lines) {
          return order.lines.some(line => {
            if (line.selectedLots) {
              return line.selectedLots.some(selectedLot => {
                return selectedLot.lotNumber === this.lot.lotNumber;
              });
            }
            return false;
          });
        }
        return false;
      });


      // console.log(test);

      this.columns.push({
        headerText: "Details",
        field: 'detailsTem',
        template: this.actionTemplate,
        autoFit: true,
      })

      this.changeDetRef.detectChanges();
      this.isLoading = false
    }, err => { this.errorService.handleError(err); this.isLoading = false; });
  }


  // Function to check if a given lot number exists in any HandlingUnitDetailDTO object
  hasMatchingLot(lotNumber: string, handlingUnitMasters: HandlingUnitMasterDTO[]): boolean {
    for (const handlingUnitMaster of handlingUnitMasters) {
      if (handlingUnitMaster.handlingUnitDetails) {
        const matchingLot = handlingUnitMaster.handlingUnitDetails.find(detail => detail.lotNumber === lotNumber);
        if (matchingLot) {
          return true;
        }
      }
    }
    return false;
  }

  // Filter orders based on whether they have lines with selected lots that match the lotNumber
  filterOrdersByLotNumber(orders: Order[], handlingUnitMasters: HandlingUnitMasterDTO[], lotNumber: string): Order[] {
    return orders.filter(order => {
      if (order.lines) {
        return order.lines.some(line => {
          if (line.selectedLots) {
            return line.selectedLots.some(selectedLot => {
              return this.hasMatchingLot(lotNumber, handlingUnitMasters);
            });
          }
          return false;
        });
      }
      return false;
    });
  }


  public getOrderDate(field, data: Order, column) {
    var value = "";

    if (data.orderDate && moment(data.orderDate).year() != 0)
      value = moment(data.orderDate).format('MM/DD/YYYY')

    return value;
  }

  public getShipDate(field, data: Order, column) {
    var value = "";

    if (data.scheduledShipDate && moment(data.scheduledShipDate).year() != 0)
      value = moment(data.scheduledShipDate).format('MM/DD/YYYY')

    return value;
  }

  public rowClicked(args): void {
    this.session.order = args
    this.session.orders = this.allOrders;
    this.router.navigateByUrl(`/hollingberyandson/orders/order-details?orderId=${args.recordId}`);
  }
}