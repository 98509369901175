import { Injectable } from '@angular/core';

import * as builder from 'xmlbuilder';
import { saveAs } from 'file-saver';
import { KragUtilitiesService } from './krag-utilities.service';
import * as _ from 'underscore';
import { AuthService } from '../auth/auth.service';
import { forkJoin, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeoJSON } from 'geojson';
import { FieldEventsService } from '../../harvest-engine/modules/field-events/services/field-event.service';
import { BlockService } from '../../harvest-engine/core/services/block.service';
import { BlockDTO } from '../../harvest-engine/core/models/block';
import { FieldEventDTO } from '../../harvest-engine/modules/field-events/models/field-event';


export interface IGeometry {
  type: string;
  coordinates: number[];
}

export interface IGeoJson {
  type: string;
  geometry: IGeometry;
  bbox?: number[];
  properties?: any;
}


@Injectable({
  providedIn: 'root'
})
export class KmlService {

  private geoJson: GeoJSON;

  constructor(
    private kragUtils: KragUtilitiesService,
    private fieldEvents: FieldEventsService,
    private blocksService: BlockService,
    private authService: AuthService
  ) { }

  public getGeoJSONPolygons() {
    this.blocksService.apiBlocksGet( null, false).subscribe(blocks => {
      var geoData = blocks.filter(b => b.isActive === true && b.gpsPoints.filter(p => p.isActive === true).length >= 3).map(b => {

        var coordinates = b.gpsPoints.filter(p => p.isActive === true).map(p => [+p.longitude, +p.latitude])

        if (b.gpsPoints.filter(p => p.isActive === true).length > 0) {
          coordinates.push([
            +b.gpsPoints.filter(p => p.isActive === true)[0].longitude,
            +b.gpsPoints.filter(p => p.isActive === true)[0].latitude
          ]);
        }

        var dataPoint = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates : [ coordinates ]
          },
          properties: {

            name: b.name,
            farm: b.farmName,
            variety: b.varietyName,
            lat: b.latitude,
            lon: b.longitude,
            varietyColor: b.varietyColor

          }

        }



        return dataPoint;
      });

      var geoJson = {
        type: "FeatureCollection",
        features: geoData
      }

      this.downLoadFile(JSON.stringify(geoJson), 'text/plain', 'geojson');
    });


  }
  public getAllKML(cropYear: number = null) {

    Observable.forkJoin([
      this.blocksService.apiBlocksGet( null, false),
      this.fieldEvents.getFieldEvents( null, null, null, cropYear)
    ]).subscribe(results => {
      this.convertBlocksToKML(results[0], results[1]);
    });
  }


  public convertBlocksToKML(blocks: BlockDTO[], events: FieldEventDTO[]) {


    this.fieldEvents.getFieldEvents().subscribe(e => {
    });

    let object: any = {
      kml: {
        '@xmlns': 'http://www.opengis.net/kml/2.2',
      }
    }


    var result = builder.create(object);

    // Get the grouped varieties
    var groupedVarieties = _.groupBy(blocks, 'varietyName');

    // Map them into a more friendly structure [{varietyName: string, blocks: BlockDTO[]}]
    var varietyList = _.map(groupedVarieties, (value, key) => {
      return { varietyName: key, blocks: value };
    });

    var doc = result.ele('Document', { 'name': 'kragworks-block-export.kml' });

    // create the styles at the top of the document
    for (let v of _.sortBy(varietyList, 'varietyName')) {

      // KML colors are 8 characters
      // AABBGGRR where AA is opacity.  We set this as bb.
      // because kml doesn't use RGB it uses BGR, so we need to split the color to reorder it
      var rr = v.blocks[0].varietyColor.replace('#', '').substring(0, 2);
      var gg = v.blocks[0].varietyColor.replace('#', '').substring(2, 4);
      var bb = v.blocks[0].varietyColor.replace('#', '').substring(4, 6);

      var colorString = `bb${bb}${gg}${rr}`;

      var style = doc.ele('Style', { 'id': v.varietyName.trim() })
        .ele('PolyStyle')
        .ele('color', colorString)
        .up()
        .up()
        .up()
        .ele("Folder")
        .ele('name', v.blocks[0].varietyName.trim())
        .up();

      // Group the farms for a sub folder
      var groupedFarms = _.groupBy(v.blocks, 'farmName');

      // Map them into a more friendly structure [{varietyName: string, blocks: BlockDTO[]}]
      var farmList = _.map(groupedFarms, (value, key) => {
        return { farmName: key, blocks: value };
      });

      for (let f of _.sortBy(farmList, 'farmName')) {

        var farmFolder = style.ele('Folder')
          .ele('name', f.farmName).up();

        // iterate through the blocks in the variety.
        for (let b of f.blocks.filter(b => b.isActive)) {
          if (b.longitude !== undefined && b.latitude !== undefined) {
            var blockFolder = farmFolder.ele('Folder').ele('name', b.name).up();

            var placemark = blockFolder.ele('Placemark')
              .ele('name', b.name).up()
              .ele('MultiGeometry')
              .ele('Point')
              .ele('coordinates', b.longitude + ',' + b.latitude + ',0').up().up()
              .ele('description')
              .dat('<span><b>' + b.farmName + '<b></span>' + '<span> (' + b.varietyName.trim() + ')</span>').up();

            var gpsPoints = _.sortBy(b.gpsPoints.filter(p => p.isActive), 'id');
            if (gpsPoints.length > 3) {
              let points: string[] = [];

              for (let point of gpsPoints.filter(p => p.isActive))
                points.push(point.longitude + ',' + point.latitude + ',0');

              // add the first point to the end to ocmplete the polygon
              points.push(gpsPoints[0].longitude + ',' + gpsPoints[0].latitude + ',0');

              placemark.ele('Polygon')
                .ele('tessellate', 1).up()
                .ele('outerBoundaryIs')
                .ele('LinearRing')
                .ele('coordinates', points.join(' ')).up().up().up().up().up()
                .ele('styleUrl', `#${b.varietyName}`)


            }
          }

          let blockEvents: FieldEventDTO[] = events.filter(x => x.block.id === b.id);

          for (let e of blockEvents) {
            var placemark = blockFolder.ele('Placemark')
              .ele('name', e.fieldEventType.name).up()
              .ele('MultiGeometry')
              .ele('Point')
              .ele('coordinates', e.longitude + ',' + e.latitude + ',0').up().up()
              .ele('description')
              .dat('<span><b>' + e.block.name + '<b></span>' + '<span> (' + e.fieldEventType.name + ')</span>').up();
          }


        }

      }

    }



    // console.log(result.end({ pretty: true }));

    this.downLoadFile(result, 'text/plain', "kml");
  }

  downLoadFile(data: any, type: string, fileType: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    saveAs(blob, "kragworks-blocks." + fileType);
    //window.open(url);

  }
}
