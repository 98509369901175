import { Pipe, PipeTransform } from '@angular/core';
import { UserDTO } from '../../../../../../harvest-engine/core/models/user';


@Pipe({
  name: 'assignedUsersPipe'
})
export class AssignedUsersPipe implements PipeTransform {

  transform(userList: UserDTO[], args?: any): any {
    return userList.map(u => u.name).join(', ');
  }

}
