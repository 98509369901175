import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as _ from 'underscore';
import { Feature, FeatureCollection } from 'geojson';

import { BlockDTO, BlockGPSPointDTO } from '../../../../../harvest-engine/core/models/block'
import { FieldEventDTO } from '../../../../../harvest-engine/modules/field-events/models/field-event';
import { FarmFeature } from '../../../../../harvest-engine/modules/farm-features/models/farm-feature-dto';
import { FilterResults } from '../../../../../harvest-engine/core/widgets/kw-filter-dropdown/models/filter-results';
import { FarmFeatureMarkerService } from '../../../../../harvest-engine/modules/overview-map/services/farm-feature-marker.service';
import { FieldEventMarkerService } from '../../../../../harvest-engine/modules/overview-map/services/field-event-marker.service';


@Component({
  selector: 'kw-block-map-display',
  templateUrl: './block-map-display.component.html',
  styleUrls: ['./block-map-display.component.scss']
})
export class BlockMapDisplayComponent implements OnInit {


  @Input() blockId: number;
  @Input() blocks: BlockDTO[];
  @Input() editable: boolean;
  @Input() height: string = '30vh';
  @Input() outlineBorders: boolean = false;
  @Input() fieldEvents: FieldEventDTO[] = [];
  @Input() farmFeature: FarmFeature[] = [];
  @Input() PinSize: number = 20;
  @Input() isAssetMap: boolean = false;

  @Output() loaded = new EventEmitter();

  private filterResults: FilterResults;


  lottieConfig = {
    path: 'https://res.cloudinary.com/kragworks/raw/upload/v1631255564/HarvestEngine/lottie/area-map.json',
    renderer: 'canvas',
    autoplay: true,
    loop: false
  };
  public noMap: boolean = false;


  public isLoading: boolean = false;
  public mapLoaded: boolean = false;
  gpsPointCount: number = 0;


  public currentMode: string = 'draw_polygon';
  map: mapboxgl.Map;
  draw: MapboxDraw;


  constructor(  
    private farmFeatureMarkerService: FarmFeatureMarkerService,
    private fieldEventMarkerService: FieldEventMarkerService,
    
  ) { }

  ngOnInit() {
  }

  async setMap(event) {
    this.mapLoaded = true;
    this.map = event;

    if (this.editable) {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        styles: [
          {
            id: 'gl-draw-polygon-fill-inactive',
            type: 'fill',
            paint: {
              'fill-color': '#022bc9', // Dark color (black)
              'fill-opacity': 0.4 // Adjust opacity for visibility
            }
          },
          {
            id: 'gl-draw-polygon-stroke-inactive',
            type: 'line',
            paint: {
              'line-color': '#000000', // Same dark color for stroke
              'line-width': 1
            }
          },
          {
            id: 'gl-draw-polygon-and-line-vertex-halo-inactive',
            type: 'circle',
            paint: {
              'circle-radius': 4,
              'circle-color': '#FFF' // White for vertex halo
            }
          },
          {
            id: 'gl-draw-polygon-and-line-vertex-inactive',
            type: 'circle',
            paint: {
              'circle-radius': 3,
              'circle-color': '#000000' // Dark color for vertex
            }
          }
        ]
      });
      
      this.map.on('draw.modechange', (e) => {
        const data = this.draw.getAll();
        if (this.draw.getMode() == 'draw_point') {
          var pids = []
          const lid = data.features[data.features.length - 1].id
          data.features.forEach((f) => {
            if (f.geometry.type === 'Point' && f.id !== lid) {
              pids.push(f.id)
            }
          })
          this.draw.delete(pids);
        }

        this.currentMode = this.draw.getMode();
      });

      this.map.addControl(this.draw, 'top-right');
    }

    let bounds = new mapboxgl.LngLatBounds();

    if (this.blocks || this.isAssetMap == true) {
      this.blocks.forEach(block => {
        if(block.gpsPoints){
          let pointsGroup: Array<Array<BlockGPSPointDTO>> = [];
          pointsGroup = block.gpsPoints.reduce(function (r, a) {
            r[a.polygonId] = r[a.polygonId] || [];
            r[a.polygonId].push(a);
            return r;
          }, Object.create(null));

          Object.keys(pointsGroup).forEach(key => {
            let gpsPoints = _.sortBy(pointsGroup[key].filter(x => x.isActive), x => x.id);
            if (gpsPoints.length > 2) {
              let coordinates = [];
              coordinates.push(gpsPoints.map(x => [+x.longitude, +x.latitude]));

              /** Create additional point if starting and finishing coordinates are not currently overlapping  */
              if(+gpsPoints[0] != +gpsPoints[gpsPoints.length -1]){
                coordinates[0].push([+gpsPoints[0].longitude, +gpsPoints[0].latitude])
              }

              let feature = <Feature>{
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: coordinates
                },
              };

              // Only add to draw if editable, otherwise add directly to map
              if (this.editable) {
                this.draw.add(feature);
              } else {
                this.map.addSource(`polygon-${key}`, {
                  'type': 'geojson',
                  'data': feature
                });
                this.map.addLayer({
                  'id': `polygon-${key}`,
                  'type': 'fill',
                  'source': `polygon-${key}`,
                  'paint': {
                    'fill-color': '#022bc9',
                    'fill-opacity': 0.4
                  }
                });
                this.map.addLayer({
                  'id': `polygon-outline-${key}`,
                  'type': 'line',
                  'source': `polygon-${key}`,
                  'paint': {
                    'line-color': '#000000',
                    'line-width': 1
                  }
                });
              }

              for (let point of gpsPoints) {
                bounds.extend([+point.longitude, +point.latitude]);
              }
            }
          });
        }

        if (block.latitude && block.longitude && +block.latitude != 0 && +block.longitude != 0 && this.isAssetMap == false) {
          let feature = <Feature>{
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [+block.longitude, +block.latitude]
            }
          };

          if (this.editable) {
            this.draw.add(feature);
          } else {
            new mapboxgl.Marker()
              .setLngLat([+block.longitude, +block.latitude])
              .addTo(this.map);
          }
        }
      });

      this.farmFeature.forEach(feature => {
        if (feature.longitude && +feature.latitude && this.isAssetMap == true){
          const marker = new mapboxgl.Marker({
            color: feature.farmFeatureTypeIconColor,
            draggable: false
            }).setLngLat([+feature.longitude, +feature.latitude])
            .addTo(this.map);
          bounds.extend([+feature.longitude, +feature.latitude]);
        }
      });
      this.farmFeatureMarkerService.initializeMarkers(this.map, this.farmFeature);

      this.fieldEventMarkerService.initializeMarkers(this.map, this.fieldEvents);

      this.fieldEvents.forEach(event => {
        if (+event.longitude && +event.latitude){
          const marker = new mapboxgl.Marker({
            color: event.fieldEventType.iconColor,
            draggable: false
            }).setLngLat([+event.longitude, +event.latitude])
            .addTo(this.map);
          bounds.extend([+event.longitude, +event.latitude]);
        }
      });
      
      // if(this.isAssetMap == false){
      //   this.map.jumpTo({center:[+block.longitude, +block.latitude], zoom:17})
      // } else if (this.isAssetMap == true){
      //   this.map.jumpTo({center:[+feature.longitude, +feature.latitude], zoom:17})
      // }
      bounds.isEmpty() ? this.map.jumpTo({
        center: [-98.5795, 39.8283], // Center of the USA
        zoom: 4 // Adjust zoom level to fit the country
      }) : this.map.fitBounds(bounds, { padding: 50, duration: 0, maxZoom: 18 });

      
    }
  // }, 500)

    //  map will need some time to load
      setTimeout(() => { 
        this.loaded.emit();
    }, 500);

  }
}
