import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { PrecisionJobService } from '../../services/precision-job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
import { NbToastrService } from '@nebular/theme';
import { PrecisionCustomer } from '../../models/precision-customer';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import { PrecisionVendor } from '../../models/precision-vendor';

import { ErrorService } from '../../../../@core/utils/error.service';
import { PrecisionPurchaseOrderService } from '../../services/precision-purchase-order.service';
import { PrecisionJobNumber } from '../../models/precision-job-number';
import { PrecisionStatus } from '../../models/precision-status';
import { PrecisionVendorService } from '../../services/precision-vendor-service';
import * as _ from 'underscore';
import { PrecisionSessionService } from '../../services/precision-session.service';
import { faEdit, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { JobDetailBottomSheetData, JobDetailEditBottomsheetComponent } from '../job-details/job-detail-edit-bottomsheet/job-detail-edit-bottomsheet.component';
import { PrecisionJob } from '../../models/precision-job';
import { AuthService } from '../../../../@core/auth/auth.service';
import { MatBottomSheet } from '@angular/material';
import { PrecisionJobDetail } from '../../models/precision-job-detail';
import { Subscription } from 'rxjs';
import { PrecisionPurchaseOrderItem } from '../../models/precision-purchase-order-item';
import { trim } from 'lodash';
import * as lodash from 'lodash';


@Component({
  selector: 'rfq',
  templateUrl: './rfq.component.html',
  styleUrls: ['./rfq.component.scss']
})
export class RfqComponent implements OnInit, OnDestroy {

  @ViewChild('newPoForm') newPoForm: NgForm;

  faTimes = faTimes;
  faPlusSquare = faPlusSquare;
  faEdit = faEdit;
  faSave = faSave;

  public jobNumberList: PrecisionJobNumber[] = [];
  public selectedJobNumber: PrecisionJobNumber = {};
  public sendEmail: boolean = false;
  public vendorList: PrecisionVendor[] = [];
  public allVendors: PrecisionVendor[] = [];
  public referenceNumberList: string[] = [];
  public selectedReferenceNumber: string;
  public purchaseOrder: PrecisionPurchaseOrder = {};
  public formControl = new FormControl();
  public recordId: string;
  public projectedJobNumber: string;
  public isLoading: boolean = false;
  public purchaseOrderNumber: string;
  public poStatusList: PrecisionStatus[] = [];
  public allSelected: boolean = false;
  public selectedVendors: PrecisionVendor[] = [];
  public settings: any;
  public vendorString: string[] = [];

  public categories: string[] = [];
  public items: string[] = [];
  job: PrecisionJob = {};
  public statuses: PrecisionStatus[] = [];
  private bottomSheetSubscription: Subscription;
  public isEdit: boolean = false;
  public selectedLineIndex: number = -1;
  public quickbooksItems: string[] = [];

  constructor(
    private precisionJobService: PrecisionJobService,
    private precisionPurchaseOrderService: PrecisionPurchaseOrderService,
    private precisionVendorService: PrecisionVendorService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private session: PrecisionSessionService,
    private toastr: NbToastrService,
    private changeDetRef: ChangeDetectorRef,
    private vendorService: PrecisionVendorService,
    private authService: AuthService,
    private bottomSheet: MatBottomSheet,
  ) { }

  ngOnDestroy(): void {
    this.session.job = undefined;
  }

  ngOnInit() {
    this.isLoading = true;
    this.getPurchaseOrder();
    this.getJobNumbers();
    this.getStatuses();
    this.getVendors();
    this.getItems();
  }

  getPurchaseOrder() {
    this.purchaseOrder.dueDate = new Date();

    if (this.session.precisionPurchaseOrder) {
      this.purchaseOrder = this.session.precisionPurchaseOrder
      
      this.selectedJobNumber.id = this.purchaseOrder.jobId;
      this.selectedJobNumber.number = this.purchaseOrder.job.jobNumber;

      if(this.purchaseOrder.rfqVendors) {
        this.selectedVendors = this.purchaseOrder.rfqVendors.map(x => {
          return {
            recordId: x.vendorId,
            name: x.vendorName,
            email: '',
          }
        })
      }

      for (let x of this.selectedVendors) {
        this.vendorString.push(x.name);
      }
      
    } else {
        this.activatedRoute.queryParams.subscribe(params => {
          if (!params["recordId"]) {
            this.toastr.warning("We could not find that PO", "PO not found");
            this.isLoading = false;
          }
  
          let recordId = params["recordId"];
          this.precisionPurchaseOrderService.getPurchaseOrder(recordId).subscribe(po => {
            this.purchaseOrder = po;
                
            this.selectedJobNumber.id = this.purchaseOrder.jobId;
            this.selectedJobNumber.number = this.purchaseOrder.job.jobNumber;

            if(this.purchaseOrder.rfqVendors) {
              
              this.selectedVendors = this.purchaseOrder.rfqVendors.map(x => {
                return {
                  recordId: x.vendorId,
                  name: x.vendorName,
                  email: '',
                }
              })
            }
            
            for (let x of this.selectedVendors) {
              this.vendorString.push(x.name);
            }

            this.isLoading = false;
          })
        });
    }
  }

  getTotal() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.quantityOrdered));
  }

  getTotalReceived() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.receivedQuantity));
  }

  getTotalPrice() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.rate));
  }

  save() {
    this.purchaseOrder.rfqVendors = [];
    this.purchaseOrder.isActive = true;
    this.purchaseOrder.isRFQ = true;

    for (let x of this.allVendors) {
      for(let y of this.vendorString) {
        if(x.name === y) {
          
          this.purchaseOrder.rfqVendors.push(
          {
            vendorName: x.name,
            vendorId: x.recordId,
          })
        }
      }
    }

    this.purchaseOrder.rfqVendors = _.uniq(this.purchaseOrder.rfqVendors, 'vendorName');

    if(!this.purchaseOrder.recordId) {
      this.isLoading = true;
      this.purchaseOrder.isPushedToQb = false;
      this.precisionPurchaseOrderService.postPurchaseOrder(false, this.purchaseOrder, this.sendEmail).subscribe(po => {
        this.toastr.success('RFQ has been saved', 'Success');
        this.isLoading = false;

      }, err => this.errorService.handleError(err));
    } else if(this.purchaseOrder.recordId) {
      this.isLoading = true;
      this.purchaseOrder.isPushedToQb = false;
      this.precisionPurchaseOrderService.putPurchaseOrder(false, this.purchaseOrder, this.sendEmail).subscribe(po => {
        this.toastr.success('RFQ has been updated', 'Success');
        this.isLoading = false;
      }, err => this.errorService.handleError(err));
    } 
  }

  
  getJobNumbers() {
    this.precisionJobService.getJobNumbers().subscribe(x => {
      this.jobNumberList = x;
      this.isLoading = false;
    }, err => this.errorService.handleError(err));
  }
  

  selectReferenceNumber() {
    this.purchaseOrder.referenceNumber = this.selectedReferenceNumber;
  }

  clearContactName() {
    this.purchaseOrder.contactName = '';
  }

  clearpaymentMethod() {
    this.purchaseOrder.paymentMethod = '';
  }

  clearDueDate() {
    this.purchaseOrder.dueDate = null;
  }

  clearComments() {
    this.purchaseOrder.comments = '';
  }

  clear() {
    this.newPoForm.reset();
    this.changeDetRef.markForCheck();
  }

  clearShipToAddress() {
    this.purchaseOrder.shipToAddress = '';
  }

  clearShipToCity() {
    this.purchaseOrder.shipToCity = '';
  }

  clearShipToState() {
    this.purchaseOrder.shipToState = '';
  }

  clearShipToZipCode() {
    this.purchaseOrder.shipToZipCode = '';
  }

  clearReferenceNumber() {
    this.purchaseOrder.referenceNumber = '';
  }

  getItems() {
    this.precisionJobService.getItems().subscribe(items => {


      
      this.quickbooksItems = [...items.map(i => i.name)];
      this.quickbooksItems = _.uniq(this.quickbooksItems);
      this.quickbooksItems = _.sortBy(this.quickbooksItems);

      this.quickbooksItems = [...this.quickbooksItems];


    }, err => this.errorService.handleError(err));
  }

  openBottomSheet(jobDetail?: PrecisionJobDetail) {

    let data: JobDetailBottomSheetData = {
      // pass the properties of the detail.  This way it's not overwriting the one on the screen if they press cancel
      jobDetail: { ...jobDetail },
      categories: this.categories.filter(x => x !== '(ALL)'),
      items: this.items.filter(x => x !== '(ALL)'),
      manufacturers: this.getManufacturers(),
      statuses: this.statuses.map(s => s.name),
      vendors: this.getVendors(),
      quickbooksItems: this.quickbooksItems


    }

    // open the bottom sheet
    this.bottomSheet.open(JobDetailEditBottomsheetComponent, { data, disableClose: false },);

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe((jobDetail: PrecisionJobDetail) => {
      
      // Map jobDetail to PrecisionPurchaseOrderItem -> map does not exist for jobDetail
      let item: PrecisionPurchaseOrderItem = {
        description: jobDetail.description,
        item: jobDetail.item,
        notes: jobDetail.notes,
        partNumber: jobDetail.partNumber,
        quantityOrdered: jobDetail.quantity,
        quickbooksItem: jobDetail.quickbooksItem,
        rate: jobDetail.price,
        receivedQuantity: 0
      };

      // Push item into purchase order line items and put PO
      this.purchaseOrder.lineItems.push(item);
      this.precisionPurchaseOrderService.putPurchaseOrder(false, this.purchaseOrder, false).subscribe(x => {
        this.toastr.success('PO Line Item Added', 'Success');
      })

      // Get job by record id and push job detail and put job
      this.precisionJobService.getJob(this.purchaseOrder.jobId).subscribe(job => {
        job.jobDetails.push(jobDetail);
        job.jobDetails = job.jobDetails.filter(x => x !== undefined);

        
        if (jobDetail !== null) {
          if (jobDetail.status === "") {
            jobDetail.status = 'Incomplete';
          }
          this.precisionJobService.putJob(job).subscribe(() => { }, err => this.errorService.handleError(err));
        }
      })
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  getManufacturers(): string[] {
    let manufacturers = [];
    if (this.job.jobDetails && this.job.jobDetails.find(jd => jd && jd.manufacturer != ''))
      manufacturers = this.job.jobDetails.filter(d => d && d.manufacturer).map(d => d.manufacturer);

    manufacturers = _.uniq(manufacturers);
    manufacturers = _.sortBy(manufacturers);
    return manufacturers;
  }

  getStatuses() {
    this.precisionJobService.getStatuses().subscribe(statuses => {
      this.poStatusList = statuses;
    }, err => this.errorService.handleError(err));

    this.vendorService.getVendors().subscribe(x => {
      x = _.sortBy(x, 'name');
      this.vendorList = x;
      this.allVendors = x;
    }, err => this.errorService.handleError(err));
  }

  getVendors(): string[] {
    let vendors = [];
    if (this.job.jobDetails)
      vendors = this.job.jobDetails.filter(d => d && d.vendor).map(d => trim(d.vendor));

    vendors = _.uniq(vendors);
    vendors = _.sortBy(vendors);
    return vendors;
  }

  edit(lineItem: PrecisionPurchaseOrderItem) {
    if(lineItem.isEdit) {
      lineItem.isEdit = false;
    } else {
      lineItem.isEdit = true;
    }
  }

  editPrice(lineItem: PrecisionPurchaseOrderItem) {
    if(lineItem.isEdit) {
      lineItem.isEdit = false;
    } else {
      lineItem.isEdit = true;
    }
  }


}



