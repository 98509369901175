import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { storageByPoolItem } from '../pages/storage-by-pool/storage-by-pool-table/storageByPoolItem';
import { StorageByRoomItem } from '../pages/storage-by-room/storage-by-room-table/storageByRoomItem';
import { storageByTypeItem } from '../pages/storage-by-type/storage-by-type-table/storageByTypeItem';

@Injectable({
  providedIn: 'root'
})
export class HarvestStorageDataService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) { this.basePath = environment.PLATFORM_API_URL; }

  public getStorageByRoomItems(cropYearId: number) {

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('cropYear', cropYearId.toString());

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<StorageByRoomItem[]>(`${this.basePath}/api/StorageByRoom`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getStorageByRoomItemById(id: number, cropYearId: number) {

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('cropYearId', cropYearId.toString());

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<StorageByRoomItem>(`${this.basePath}/api/StorageByRoom/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }


  public getStorageByTypeItems(cropYearId: number) {

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('cropYear', cropYearId.toString());

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<storageByTypeItem[]>(`${this.basePath}/api/StorageByType`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }


  public getStorageByPoolItems(cropYearId: number) {

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('cropYear', cropYearId.toString());

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<storageByPoolItem[]>(`${this.basePath}/api/StorageByPool`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }
}
