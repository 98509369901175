import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { faTrashAlt, faThLarge, faTags, faTruckMoving, faCalendarCheck, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { SessionService } from '../../../../../@core/utils/session.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatBottomSheet } from '@angular/material';
import { QuickPoComponent } from '../../quick-po/quick-po.component';
import { PrecisionPurchaseOrderService } from '../../../services/precision-purchase-order.service';
import { PrecisionSessionService } from '../../../services/precision-session.service';
import { NbToastrService } from '@nebular/theme';
import { faToggleOn, faClipboardCheck, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { ReportService } from '../../../../../reports/report.service';


@Component({
  selector: 'po-actions-dialog',
  templateUrl: './po-actions-dialog.component.html',
  styleUrls: ['./po-actions-dialog.component.scss']
})
export class PoActionsDialogComponent implements OnInit, OnDestroy {

  // Icons //
  faTrashAlt = faTrashAlt;
  faThLarge = faThLarge;
  faPrint = faPrint;
  faTags = faTags;
  faTruckMoving = faTruckMoving;
  faCalendarCheck = faCalendarCheck;
  faEdit = faEdit;
  faToggleOn = faToggleOn;
  faClipboardCheck = faClipboardCheck;
  faSave = faSave;
  
  public isLoading: boolean = false;
  public isConvertToPO: boolean = false;

  constructor(
    public bottomSheet: MatBottomSheet,
    public session: PrecisionSessionService,
    private router: Router,
    private poService: PrecisionPurchaseOrderService,
    public dialogRef: MatDialogRef<PoActionsDialogComponent>,
    private toastr: NbToastrService,
    public reportService: ReportService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnDestroy(): void {
    this.session.precisionPurchaseOrder = undefined;
  }

    
  ngOnInit(): void {
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  edit() {
    this.dialogRef.close();
    //this.bottomSheet.open(QuickPoComponent);
    this.router.navigateByUrl(`/precision/quick-po?recordId=${this.session.precisionPurchaseOrder.recordId}`);
  }

  editRfq() {
    this.dialogRef.close();
    //this.bottomSheet.open(QuickPoComponent);
    this.router.navigateByUrl(`/precision/rfq?recordId=${this.session.precisionPurchaseOrder.recordId}`);
  }

  receivePo() {
    this.session.isPoReceive = true;
    this.dialogRef.close();
    //this.bottomSheet.open(QuickPoComponent);
    this.router.navigateByUrl(`/precision/quick-po?recordId=${this.session.precisionPurchaseOrder.recordId}`);
  }

  openDialog() {
    this.dialogRef.close();
    this.bottomSheet.open(QuickPoComponent);
  }


  deletePO() {
    this.isLoading = true;
    if(!this.session.precisionPurchaseOrder.isPushedToQb) {
      this.session.precisionPurchaseOrder.isPushedToQb = false;
      this.poService.deleteRfq(this.session.precisionPurchaseOrder.recordId).subscribe(x => {
        this.dialogRef.close();
        this.toastr.success('RFQ deleted successfully');
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    } else {
      this.toastr.warning('PO has been pushed to QuickBook', 'Cannot delete')
    }
  }

  deleteRFQ() { 
    this.isLoading = true;
    this.session.precisionPurchaseOrder.isPushedToQb = false;
    this.poService.deleteRfq(this.session.precisionPurchaseOrder.recordId).subscribe(x => {
      this.dialogRef.close();
      this.toastr.success('RFQ deleted successfully');
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  ConvertRfqToPo() {
    if(this.isConvertToPO) {
      this.isConvertToPO = false;
    } else {
      this.isConvertToPO = true;
    }
  }

  selectVendor() {
    this.session.precisionPurchaseOrder.vendorName = 
      this.session.precisionPurchaseOrder.rfqVendors.filter(x => x.vendorId === this.session.precisionPurchaseOrder.vendorId.toString())[0].vendorName;
  }

  savePO() {
    this.isLoading = true;
    this.session.jobNumber = this.session.precisionPurchaseOrder.job.jobNumber;
    let po = this.session.precisionPurchaseOrder;
    this.dialogRef.close();
    po.isRFQ = false;
    this.poService.putPurchaseOrder(false, po, false).subscribe(po => {

      this.toastr.success('RFQ successfully converted to a PO');
      this.router.navigateByUrl(`/precision/purchase-orders`);
      this.isLoading = false;
  }, err => {
    this.errorService.handleError(err);
    this.isLoading = false;
    });
  }

  printPo(){
    this.session.reportPurchaseOrder = {...this.session.precisionPurchaseOrder};
  
    this.dialogRef.close();
    this.reportService.printReport("precision-purchase-order", []);
    
  }

}


