import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material';
import { faArrowAltCircleLeft, faTimes, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Employee } from '../../../models/employee';
import { EmployeeService } from '../../../services/employee/employee.service';
import { Fp_Crew } from '../../../../../customer-integrations/firman/models/fp-crew';
import { FpCrewService } from '../../../../../customer-integrations/firman/services/crew/fp-crew.service';

@Component({
  selector: 'employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})

export class EmployeeEditComponent implements OnInit {
  public employee: Employee = {};
  public isLoading: boolean = false;
  public crews: Fp_Crew[] = [];
  
  // Icons  //
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faTimes = faTimes;
  public faPlus = faPlusCircle;
  
  constructor( @Inject(MAT_BOTTOM_SHEET_DATA) public data: EployeePopupData,
    private bottomSheet: MatBottomSheet,
    private employeeService: EmployeeService,
    public changeDetRef: ChangeDetectorRef,
    private crewService: FpCrewService,
    private errorService: ErrorService) {
      if (!data.employee) 
        this.employee = {};
      else
        this.employee = {...data.employee};

      if(!data.crews || data.crews.length == 0)
        this.crews = [];
      else
        this.crews = [...data.crews]
   }

  ngOnInit() {
    if(!this.crews || this.crews.length == 0){
      this.isLoading = true;
      this.crewService.getCrews().subscribe(x => {
        this.crews = [...x].sort((a,b) => a.crewName.localeCompare(b.crewName));
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
  }

  formatInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove any non-numeric characters (except the decimal point)
    value = value.replace(/[^0-9.]/g, '');

    // Ensure it has at most 2 decimal places
    const decimalParts = value.split('.');
    if (decimalParts.length > 1) {
      value = decimalParts[0] + '.' + decimalParts[1].substring(0, 2);
    }

    // Set the formatted value back to the input
    inputElement.value = value;
  }

  submit() {
    this.isLoading = true;
    if (this.employee.id) {
      this.employeeService.putEmployee(this.employee).subscribe(x => {
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
    else {
      this.employeeService.postEmployee(this.employee).subscribe(x => {
        this.employee.id = x.id;
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
  }

  cancel() {
    this.bottomSheet.dismiss();
  }

  deletePackageType() {
    this.isLoading = true;
    this.employeeService.deleteEmployee(this.employee.id).subscribe(x => {
      this.cancel();
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

}


export interface EployeePopupData {
  employee: Employee;
  crews: Fp_Crew[];
  isPopup: boolean;
  isReturnBottomsheet?: boolean;
}