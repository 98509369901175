import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { AuthService } from '../../../../@core/auth/auth.service';
import { FarmFeature, FarmFeatureType } from '../models/farm-feature-dto';
import { environment } from '../../../../../environments/environment';
import { FarmFeatureEvent, FarmFeatureEventType } from '../models/farm-feature-event';
import { FarmFeatureEventTimelineItem } from '../models/farm-feature-event-timeline-item';

@Injectable({
  providedIn: 'root'
})
export class FarmFeatureService {


  public defaultHeaders = new HttpHeaders();
  constructor(
    private authService: AuthService,
    protected httpClient: HttpClient,
  ) {

  }


  /**** FARM FEATURE BY ID  ****/
  public getFarmFeatureById(id: number): Observable<any> {

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeatures/${id}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  /**** FARM FEATURE TYPES  ****/
  public getFarmFeatureTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<FarmFeatureType>>;
  public getFarmFeatureTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FarmFeatureType>>>;
  public getFarmFeatureTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FarmFeatureType>>>;
  public getFarmFeatureTypes(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams();



    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeaturetypes`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getFarmFeatureType(id: number, observe?: 'body', reportProgress?: boolean): Observable<FarmFeatureType>;
  public getFarmFeatureType(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FarmFeatureType>>;
  public getFarmFeatureType(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FarmFeatureType>>;
  public getFarmFeatureType(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams();



    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeaturetypes/${id}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getLoadingAreas(): Observable<any> {

    let queryParameters = new HttpParams();



    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeaturetypes/LoadingAreas`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public addOrUpdateFarmFeatureType(featureType: FarmFeatureType, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public addOrUpdateFarmFeatureType(featureType: FarmFeatureType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public addOrUpdateFarmFeatureType(featureType: FarmFeatureType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public addOrUpdateFarmFeatureType(featureType: FarmFeatureType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];


    headers = headers.set('Accept', 'application/json');

    headers = headers.set('Content-Type', 'application/json');


    if (featureType.farmFeatureTypeId == 0) {
      return this.httpClient.post(`${environment.PLATFORM_API_URL}/api/farmfeaturetypes`, featureType,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    } else {
      return this.httpClient.put(`${environment.PLATFORM_API_URL}/api/farmfeaturetypes/${featureType.farmFeatureTypeId}`, featureType,
        {
          // withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress
        }
      );
    }

  }

  /*** Farm Features/EVENTS */


  public getFarmFeatureEvents(cropYear?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<FarmFeatureEvent>>;
  public getFarmFeatureEvents(cropYear?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FarmFeatureEvent>>>;
  public getFarmFeatureEvents(cropYear?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FarmFeatureEvent>>>;
  public getFarmFeatureEvents(cropYear?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams();

    if (cropYear)
      queryParameters = queryParameters.append('cropYear', cropYear.toString());



    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeaturesevent`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  /*** Farm Features/EVENTS/{id} */


  // public getFarmFeatureEventsById(cropYear?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<FarmFeatureEvent>>;
  // public getFarmFeatureEventsById(cropYear?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FarmFeatureEvent>>>;
  // public getFarmFeatureEventsById(cropYear?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FarmFeatureEvent>>>;
  public getFarmFeatureEventsByFeatureId(id: number, cropYear?: number): Observable<any> {

    let queryParameters = new HttpParams();

    if (cropYear)
      queryParameters = queryParameters.append('cropYear', cropYear.toString());

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeaturesevent/ByFeatureId/${id}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }




  /*** Farm Features Event Types */
  public getFarmFeatureEventTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<FarmFeatureEventType>>;
  public getFarmFeatureEventTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FarmFeatureEventType>>>;
  public getFarmFeatureEventTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FarmFeatureEventType>>>;
  public getFarmFeatureEventTypes(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams();



    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));


    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get(`${environment.PLATFORM_API_URL}/api/farmfeatureseventtype`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }


  public getFarmFeatures(eventStartDate?: Date, eventEndDate?: Date, blockId?: number, farmId?: number): Observable<FarmFeature[]> {

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    if (blockId)
      queryParameters = queryParameters.append('blockId', blockId.toString());

    if (farmId)
      queryParameters = queryParameters.append('farmId', farmId.toString());

    if (eventStartDate)
      queryParameters = queryParameters.append('eventStartDate', moment(eventStartDate).toISOString());


    if (eventEndDate)
      queryParameters = queryParameters.append('eventEndDate', moment(eventEndDate).toISOString());
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = "application/json";
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<FarmFeature[]>(`${environment.PLATFORM_API_URL}/api/farmfeatures`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public putFarmFeature(feature: FarmFeature): Observable<FarmFeature> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<FarmFeature>(`${environment.PLATFORM_API_URL}/api/farmfeatures/${feature.farmFeatureId}`, feature,
      {
        headers,
      }
    );
  }

  public postFarmFeature(feature: FarmFeature): Observable<FarmFeature> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.post<FarmFeature>(`${environment.PLATFORM_API_URL}/api/farmfeatures`,
      feature,
      {
        headers,
      }
    );
  }


  /// REPORTS

  public getEventTimelineReport(cropYear: number, farmId: number = 0, includeEvents = true, startDate: Date = null, endDate: Date = null
    , groupType: string = "week"): Observable<FarmFeatureEventTimelineItem[]> {

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append("cropYear", cropYear.toString());
    queryParameters = queryParameters.append("dateType", groupType);


    if (farmId > 0)
      queryParameters = queryParameters.append("farmId", farmId.toString());

    if (!includeEvents)
      queryParameters = queryParameters.append("includeEvents", "false");

    if (startDate)
      queryParameters = queryParameters.append("startDate", moment(startDate).toISOString());

    if (endDate)
      queryParameters = queryParameters.append("endDate", moment(endDate).toISOString());

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<FarmFeatureEventTimelineItem[]>(`${environment.PLATFORM_API_URL}/api/farmfeaturesevent/reports/timeline`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getEventTimelineReportById(id: number, cropYear: number): Observable<FarmFeatureEventTimelineItem[]> {

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append("cropYear", cropYear.toString());
    queryParameters = queryParameters.append("dateType", "week");

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<FarmFeatureEventTimelineItem[]>(`${environment.PLATFORM_API_URL}/api/farmfeaturesevent/reports/timeline/${id}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public sendTimelineReportEmail(emailObject: any) {
    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.post<FarmFeatureEventTimelineItem[]>(`${environment.PLATFORM_API_URL}/api/farmfeaturesevent/reports/email`, emailObject,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }




  public putFarmFeatureEvent(event: FarmFeatureEvent): Observable<FarmFeatureEvent> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();



    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.put<FarmFeatureEvent>(`${environment.PLATFORM_API_URL}/api/FarmFeaturesEvent/${event.id}`, event,
      {
        headers,
      }
    );
  }


  public postFarmFeatureEvent(event: FarmFeatureEvent): Observable<FarmFeatureEvent> {

    if (environment.isMockEnabled) {
      return Observable.of(event);
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.post<FarmFeatureEvent>(`${environment.PLATFORM_API_URL}/api/FarmFeaturesEvent`,
      event,
      {
        headers,
      }
    );
  }

  public deleteFarmFeatureEvent(id: number): Observable<FarmFeatureEvent> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('setInactive', "false");

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<FarmFeatureEvent>(`${environment.PLATFORM_API_URL}/api/FarmFeaturesEvent/${id}`,
      {
        headers: headers,
        params: queryParameters,
      }
    );
  }


  public getFarmFeatureEvent(id: number): Observable<FarmFeatureEvent> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('setInactive', "false");

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<FarmFeatureEvent>(`${environment.PLATFORM_API_URL}/api/FarmFeaturesEvent/${id}`,
      {
        headers: headers,
        params: queryParameters,
      }
    );
  }

  //Save Event Type
  public postFarmFeatureEventType(featureEventType: FarmFeatureEventType): Observable<FarmFeatureEventType> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.post<FarmFeatureEventType>(`${environment.PLATFORM_API_URL}/api/farmfeatureseventtype`,
      featureEventType,
      {
        headers,
      }
    );
  }

   //Update Event Type
  public putFarmFeatureEventType(featureEventType: FarmFeatureEventType): Observable<FarmFeatureEventType> {
    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.put<FarmFeatureEventType>(`${environment.PLATFORM_API_URL}/api/farmfeatureseventtype/${featureEventType.id}`,
      featureEventType,
      {
        headers,
      }
    );
  }

}

