import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobListComponent } from './pages/job-list/job-list.component';
import { JobDetailsComponent } from './pages/job-details/job-details.component';
import { 
          MatInputModule, 
          MatTableModule, 
          MatPaginatorModule, 
          MatSortModule, 
          MatProgressBarModule, 
          MatCheckboxModule, 
          MatListModule, 
          MatDatepickerModule, 
          MatIconModule, 
          MatButtonModule,
          MatFormFieldModule,
          MatSelectModule,
          MatBottomSheetModule,
          MAT_DIALOG_DATA,
          MAT_BOTTOM_SHEET_DATA,
          MatExpansionModule,
          MatAutocompleteModule, 
        } from '@angular/material';

import { PrecisionRoutingModule } from './precision.routing.module';
import { NbInputModule, NbSpinnerModule, NbCardModule, NbTreeGridModule, NbDatepickerModule, NbTooltipModule  } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PurchaseOrderComponent } from './pages/purchase-order/purchase-order.component';
import { DropboxPopupComponent } from './pages/dropbox-popup/dropbox-popup.component';
import { NewJobComponent } from './pages/new-job/new-job.component';
import { PrecisionFileDisplayComponent } from './pages/dropbox-popup/precision-file-display/precision-file-display.component';
import { PrecisionDropboxService } from './services/precision-dropbox.service';
import { QuickPoComponent } from './pages/quick-po/quick-po.component';
import { PoLineItemsComponent } from './pages/quick-po/po-line-items/po-line-items.component';
import { JobDetailEditComponent } from './pages/job-details/job-detail-edit/job-detail-edit.component';
import { JobDetailEditBottomsheetComponent } from './pages/job-details/job-detail-edit-bottomsheet/job-detail-edit-bottomsheet.component';
import { JobPopupComponent } from './pages/job-popup/job-popup.component';
import { RfqComponent } from './pages/rfq/rfq.component';
import { PurchaseOrderTableComponent } from './pages/purchase-order-table/purchase-order-table.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PoActionsDialogComponent } from './pages/purchase-order-table/po-actions-dialog/po-actions-dialog.component';
import { ReceiveLineItemsComponent } from './pages/receive-line-items/receive-line-items.component';
import { JobActionsDialogComponent } from './pages/job-list/job-actions-dialog/job-actions-dialog.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PurchaseOrderDialogComponent } from './pages/job-list/purchase-order-dialog/purchase-order-dialog.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { SyncStatusComponent } from './widgets/sync-status/sync-status.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PurchaseOrderReportComponent } from './reports/purchase-order-report/purchase-order-report.component';

@NgModule({
  declarations: [
      JobListComponent, 
      JobDetailsComponent,
      PurchaseOrderComponent,
      DropboxPopupComponent,
      NewJobComponent,
      PrecisionFileDisplayComponent,
      QuickPoComponent,
      PoLineItemsComponent,
      PoLineItemsComponent,
      JobDetailEditComponent,
      JobDetailEditBottomsheetComponent,
      JobPopupComponent,
      RfqComponent,
      PurchaseOrderTableComponent,
      PoActionsDialogComponent,
      ReceiveLineItemsComponent,
      JobActionsDialogComponent,
      PurchaseOrderDialogComponent,
      SyncStatusComponent,
      PurchaseOrderReportComponent
    ],
  imports: [
    ReactiveFormsModule,
    FontAwesomeModule,
    CommonModule,
    MatSelectModule,
    MatTableModule,
    PrecisionRoutingModule,
    MatPaginatorModule, 
    MatSortModule, 
    MatProgressBarModule, 
    MatCheckboxModule, 
    // NgxMatSelectSearchModule,
    MatListModule, 
    MatDatepickerModule,
    MatBottomSheetModule,
    MatExpansionModule,
    NbInputModule,
    NbSpinnerModule, 
    NbCardModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NbTooltipModule,
    MatFormFieldModule,
    FormsModule,
    NbTreeGridModule,
    MatAutocompleteModule,
    NbDatepickerModule,
    AngularMultiSelectModule,
    MatGridListModule,
    Ng2SmartTableModule,
  ],
  exports: [
    QuickPoComponent,
    NewJobComponent,
    SyncStatusComponent,
    PurchaseOrderReportComponent
  ],
  entryComponents: [
    DropboxPopupComponent,
    JobDetailEditBottomsheetComponent,
    JobPopupComponent,
    PoActionsDialogComponent,
    ReceiveLineItemsComponent,
    JobActionsDialogComponent,
    PurchaseOrderDialogComponent
  ],
  providers: [
    {provide: MAT_BOTTOM_SHEET_DATA, useValue: {}}
  ]
})
export class PrecisionModule { }
