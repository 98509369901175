import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { PrecisionJob } from '../../models/precision-job';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { PrecisionJobService } from '../../services/precision-job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
import { NbToastrService } from '@nebular/theme';
import { PrecisionCustomer } from '../../models/precision-customer';
import { PrecisionCustomerService } from '../../services/precision-customer.service';
import { ErrorService } from '../../../../@core/utils/error.service';
import { MatBottomSheetRef, MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { PrecisionSessionService } from '../../services/precision-session.service';
import * as _ from 'underscore';

@Component({
  selector: 'new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss']
})
export class NewJobComponent implements OnInit, OnDestroy {

  @ViewChild('newJobForm') newJobForm: NgForm;

  public faTimes = faTimes;
  public customerList: PrecisionCustomer[] = [];
  public selectedCustomer: PrecisionCustomer;
  public jobTypeList: string[] = ['FFP', 'T&M', 'Warranty', 'Non-billable'];
  public jobStatusList: string[] = ['Open', 'Ready to Bill', 'Closed'];
  public selectedJobType: string;
  public stakeholderList: string[] = [];
  public selectedStakeholder: string;
  //public newJob: PrecisionJob = {};
  public formControl = new FormControl();
  public recordId: string;
  public projectedJobNumber: string;
  public isLoading: boolean = false;
  public fakeCustomer: PrecisionCustomer[] = [];
  public isNew: boolean;
  public jobNumberIsLoading: boolean;

  public customerDropdownSettings = {
    singleSelection: true,
    text: "Select Customer",
    selectAllText: 'Select All',
    unSelectAllText: 'Clear All',
    enableSearchFilter: true,
    classes: "",
    badgeShowLimit: 1
  };

  constructor(
    private precisionJobService: PrecisionJobService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private session: PrecisionSessionService,
    private toastr: NbToastrService,
    private changeDetRef: ChangeDetectorRef,
    private customerService: PrecisionCustomerService,
    private errorService: ErrorService,
    private _bottomSheetRef: MatBottomSheetRef<NewJobComponent>,
    private dialog: MatDialog
  ) { }

  @Input() newJob: PrecisionJob;
  @Output() close: EventEmitter<PrecisionJob> = new EventEmitter<PrecisionJob>();


  ngOnDestroy(): void {
    this.newJobForm.reset();
    this.changeDetRef.markForCheck();
  }

  ngOnInit() {
    this.isLoading = true;

    if (!this.newJob.recordId) {
      //this.getJobNumber();
      this.isNew = true;
    } else {
      this.isNew = false;
      this.isLoading = true;
      this.precisionJobService.getJob(this.newJob.recordId).subscribe(x => {
        this.newJob = x;
        this.isLoading = false;
      });
    }

    this.getCustomers();
  }

  save() {
    this.isLoading = true;
    
    if(this.newJob.awardValue)
      this.newJob.awardValue = this.newJob.awardValue.toString();
      
    if (this.newJob.recordId) {
      this.precisionJobService.putJob(this.newJob).subscribe(job => { 
        this.session.job = job;
        this.close.emit(this.newJob);
        this.isLoading = false;
        this.changeDetRef.markForCheck();
        this.toastr.success('Job updated successfully', 'SUCCESS');
      }, err => { this.errorService.handleError(err) });
    } if (!this.newJob.recordId) {
      this.newJob.isActive = true;
      let customer = this.customerList.filter(x => x.name === this.newJob.customerName)[0];
      this.newJob.customerId = customer.recordId;
      this.newJob.purchaseOrders = [];
      this.precisionJobService.postJob(this.newJob).subscribe(job => { 
        this.session.job = job;
        this.close.emit(job);
        this.isLoading = false;
        this.changeDetRef.markForCheck();
        this.toastr.success('Job created successfully', 'SUCCESS');
      }, err => { this.errorService.handleError(err) });
    }
  }

  customerSelected(eventValue: any){
    this.newJob.customerId = eventValue;
    this.newJob.customerName = this.customerList.filter(x => x.recordId === eventValue)[0].name;
    this.getJobNumber();
  }

  getJobNumber() {
  this.jobNumberIsLoading = true;
  this.changeDetRef.markForCheck();
    this.precisionJobService.getJobNumber(this.newJob.customerId).subscribe(x => {

      this.projectedJobNumber = x;
      
      this.newJob.jobNumber = x;
      this.isLoading = false;
      this.jobNumberIsLoading = false;
      this.changeDetRef.markForCheck();
    }, err => { this.errorService.handleError(err) });
  }

  getCustomers() {
    this.customerService.getCustomers().subscribe(customers => {
      customers = _.sortBy(customers, 'name');
      this.customerList = customers;
      this.changeDetRef.markForCheck();
      this.isLoading = false;
      this.changeDetRef.markForCheck();
    }, err => { this.errorService.handleError(err) });
  }

  remove() {
    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Delete Job?',
        confirmMessage: 'Are you sure you want to delete ' + this.newJob.jobNumber + '?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {
      this.isLoading = true;
      this.changeDetRef.markForCheck();
      if (result === true) {
        this.newJob.isActive = false;

        this.precisionJobService.putJob(this.newJob).subscribe(job => {
          this.close.emit(this.newJob);
          this.toastr.success(this.newJob.jobNumber, 'Deleted successsfully');
          this.isLoading = false;
        }, err => {
          this.isLoading = false;
          this.errorService.handleError(err);
        });

      } else {
        this.isLoading = false;
      }
    });

  }

  clear() {
    this.newJobForm.reset();
    this.changeDetRef.markForCheck();

  }

  clearEmail() {
    this.newJob.poContactEmail = '';
  }

  clearPOContact() {
    this.newJob.poContact = '';
  }

  clearClientPONumber() {
    this.newJob.clientPONumber = '';
  }

  clearZipCode() {
    this.newJob.billingZipCode = '';
  }

  clearDescription() {
    this.newJob.description = '';
  }

  clearAwardValue() {
    this.newJob.awardValue = null;
  }

  clearPhoneNumber() {
    this.newJob.poContactPhoneNumber = '';
  }

  clearComments() {
    this.newJob.comments = '';
  }


}


