import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { faPlusCircle, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Room } from '../../../models/storage/room';
import { RoomLocation } from '../../../models/storage/room-location';
import { Warehouse } from '../../../models/storage/warehouse';
import { RoomService } from '../../../services/storage/room-service';
import { WarehouseService } from '../../../services/storage/warehouse-service';

@Component({
  selector: 'room-location-edit',
  templateUrl: './room-location-edit.component.html',
  styleUrls: ['./room-location-edit.component.scss']
})
export class RoomLocationEditComponent implements OnInit {

  public faPlusCircle = faPlusCircle;

  public editRoomLocation: RoomLocation = {};
  public roomLocationId: number;
  public roomLocationLoading = true;
  public submitting: any;
  public warehouses: Warehouse[] = [];
  public rooms: Room[] = [];
  public allRooms: Room[] = [];
  public warehouseId: number;
  public room: Room = {};
  // public editWarehouse: Warehouse;

  public faTimes = faTimes;
  public faCheck = faCheck;

  constructor(
    private errorService: ErrorService,
    private roomLocationService: RoomService,
    public service: RoomService,
    private _bottomSheetRef: MatBottomSheetRef<RoomLocationEditComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private changeDetRef: ChangeDetectorRef,
    public warehouseService: WarehouseService) {
    // if (data.roomLocation)
    //   this.editRoomLocation = data.roomLocation;
  }

  ngOnInit() {
    this.roomLocationLoading = true;

    if (this.data.roomLocation) {
      this.roomLocationService.getRoomLocation(this.data.roomLocation.id).subscribe(roomLocation => {
        this.editRoomLocation = roomLocation;

        Observable.forkJoin([
          this.warehouseService.getWarehouses(),
          this.service.getRooms(),
        ]).subscribe(data => {
          this.warehouses = data[0];
          this.rooms = data[1];
          this.allRooms = data[1];

          this.room = this.rooms.find(x => x.id == this.editRoomLocation.roomId);
          this.warehouseId = this.room.warehouseId;
          this.roomLocationLoading = false;
          this.changeDetRef.detectChanges();
        }, err => {
          this.errorService.handleError(err);
          this.roomLocationLoading = false;
          this.changeDetRef.detectChanges();
        });
      }, err => {
        this.roomLocationLoading = false;
        this.errorService.handleError(err);
        this.changeDetRef.detectChanges();
      });
    }
    else {
      this.editRoomLocation = {};
      this.editRoomLocation.isActive = true;

      Observable.forkJoin([
        this.warehouseService.getWarehouses(),
        this.service.getRooms(),
      ]).subscribe(data => {
        this.warehouses = data[0];
        this.rooms = data[1];
        this.allRooms = data[1];

        this.roomLocationLoading = false;
        this.changeDetRef.detectChanges();
      }, err => {
        this.errorService.handleError(err);
        this.roomLocationLoading = false;

        this.changeDetRef.detectChanges();
      });
    }
  }


  deleteRoomLocationClicked(form: NgForm) {
    this.roomLocationLoading = true;
    if (this.editRoomLocation.id !== null && this.editRoomLocation.id !== undefined) {
      this.service.deleteRoomLocation(this.editRoomLocation.id).subscribe(x => {
        this.editRoomLocation = {};
        this.roomLocationLoading = false;
        form.resetForm();
        this._bottomSheetRef.dismiss();
      }, err => { this.errorService.handleError(err); this.roomLocationLoading = false; form.resetForm(); });
    }
    else {
      alert("No Room Location Selected!!");
      this.roomLocationLoading = false;
      form.resetForm();
    }
  }


  saveRoomLocationClicked(form: NgForm) {
    this.roomLocationLoading = true;
    if (this.editRoomLocation.isActive === null || this.editRoomLocation.isActive === undefined) { this.editRoomLocation.isActive = false; };
    if (this.editRoomLocation.id !== null && this.editRoomLocation.id !== undefined) {
      this.editRoomLocation.updatedDate = new Date;
      this.service.putRoomLocation(this.editRoomLocation).subscribe(x => {
        this.editRoomLocation = {};
        this.roomLocationLoading = false;
        form.resetForm();
        this._bottomSheetRef.dismiss();
      }, err => { this.errorService.handleError(err); this.roomLocationLoading = false; form.resetForm(); });
    }
    else {
      this.editRoomLocation.id = 0;
      this.editRoomLocation.createdDate = new Date();
      this.editRoomLocation.updatedDate = this.editRoomLocation.createdDate;
      this.service.postRoomLocation(this.editRoomLocation).subscribe(x => {
        // this.editRoomLocation.warehouse = this.editWarehouse;
        this.editRoomLocation = {};
        this.roomLocationLoading = false;
        form.resetForm();
        this._bottomSheetRef.dismiss();
      }, err => { this.errorService.handleError(err); this.roomLocationLoading = false; form.resetForm(); });
    }
  }

  cancelClicked(form: NgForm) {
    this.editRoomLocation = {};
    this._bottomSheetRef.dismiss();
  }

  roomChanged(id: number) {
    let editRoom = this.rooms.find(x => x.id == id);
    this.editRoomLocation.roomId = editRoom.id;

    this.warehouseId = editRoom.warehouseId;
  }

  warehouseChanged(id: number) {
    let editWarehouse = this.warehouses.find(x => x.id === id);
    this.rooms = [...this.allRooms.filter(x => x.warehouseId == editWarehouse.id)];
    this.changeDetRef.detectChanges();
  }

}
