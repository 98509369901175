import { Injectable } from '@angular/core';
import { Warehouse } from '../../models/storage/warehouse';

@Injectable({
  providedIn: 'root'
})
export class LocationBarcodeReportService {

  public allWarehouses: Warehouse[] = [];
  public selectedWarehouses: Warehouse[] = [];
  public isReport: boolean = false;
  
  constructor() { }
}
