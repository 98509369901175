import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { HandlingUnitMasterDTO } from '../../models/handling-unit-master';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatDialog } from '@angular/material';
import { LabQuantityDiologComponent } from '../../../laboratory/pages/lot-summary/lab-quantity-diolog/lab-quantity-diolog.component';
import * as _ from 'underscore';
import { faBars } from '@fortawesome/pro-duotone-svg-icons';
import { LotDetailsByRoomDialogComponent } from './lot-details-by-room-dialog/lot-details-by-room-dialog.component';
import { lotInventory } from '../../../laboratory/services/lab-quantity-service';
import { lotInventoryService } from '../../../laboratory/services/lab-quantity-service';
import { LotDetailsByRoomActionComponent } from '../lot-details-by-room-action/lot-details-by-room-action.component';
import { LabQuantityAddComponent } from '../../../laboratory/pages/lot-summary/lab-quantity-add/lab-quantity-add.component';
import { LotDetailsByRoomMoveInventoryComponent } from './lot-details-by-room-move-inventory/lot-details-by-room-move-inventory.component';

export interface GroupedLot {
  lotNumber: string;
  receiptDate: Date;
  quantity: number;
}
@Component({
  selector: 'lot-details-by-room',
  templateUrl: './lot-details-by-room.component.html',
  styleUrls: ['./lot-details-by-room.component.scss']
})
export class LotDetailsByRoomComponent implements OnInit {

  @Output() refreshTable = new EventEmitter<boolean>();

  public isLoading: boolean = true;

  public allHums: HandlingUnitMasterDTO[] = [];

  @Input() roomId: number;
  @Input() blockId: number;
  @Input() farmId: number;
  @Input() cropYearId: number;
  @Input() varietyId: number;

  public lots: GroupedLot[] = [];
  faBars = faBars;

  constructor(
    private inventoryService: InventoryService,
    private errorService: ErrorService,
    public dialog: MatDialog,
    private lotInventoryService: lotInventoryService,
    private bottomSheet: MatBottomSheet,
  ) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.inventoryService.getInventoryHUMs(false, null, null, null, this.roomId).subscribe((data) => {
      this.allHums = data;

      let huds = this.allHums.flatMap(hum => hum.handlingUnitDetails);

      let filteredHuds = huds.filter(hud =>
        hud.blockId == this.blockId &&
        hud.farmId == this.farmId &&
        hud.varietyId == this.varietyId &&
        hud.isActive
      );

      // group huds by lot information
      let groupedHuds = _.groupBy(filteredHuds, hud => hud.lotNumber);

      this.lots = [...Object.keys(groupedHuds).map(key => {
        let huds = groupedHuds[key];
        let quantity = huds.reduce((acc, hud) => acc + hud.quantity, 0);
        return {
          lotNumber: key,
          lotId: huds[0].lotId,
          receiptDate: new Date(huds[0].createdDate),
          handlingUnitDetailId: huds.map(hud => hud.id),
          handlingUnitMasterId: huds.map(hud => hud.handlingUnitMasterId),
          quantity: quantity
        };
      })];


      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }


  public actionsClicked(args: GroupedLot): void {

    let data: lotInventory = args;

    data.originalQuantity = args.quantity;
    this.dialog.open(LotDetailsByRoomActionComponent, { data, disableClose: false, width: '280px' }).afterClosed().subscribe((val) => {
      if (val == 'adjust') {

        this.dialog.closeAll();

        this.dialog.open(LotDetailsByRoomDialogComponent, { data, disableClose: false, width: '750px' }).afterClosed().subscribe((val: lotInventory) => {
          if (val) {
            let adjustmentQuantity = val.quantity - val.originalQuantity;

            console.log(val)
            if (adjustmentQuantity > 0) {
              val.quantity = Math.abs(adjustmentQuantity);
              this.lotInventoryService.lotInventoryAdd(val).subscribe(x => {
                this.refreshTable.emit(true);
              });
            } else {
              val.quantity = Math.abs(adjustmentQuantity);

              this.lotInventoryService.lotInventoryRemove(val).subscribe(x => {
                this.refreshTable.emit(true);
              });
            }            
          }

        });
      }
      else if (val == 'move') {
        this.dialog.closeAll();
        let moveData = {
          lot: args,
          isPopup: true,
          type: "transfer",
          lotId: data.lotId,
          lotName: args.lotNumber,
        };

        this.bottomSheet.open(LotDetailsByRoomMoveInventoryComponent, { panelClass: 'bottomsheet-50-width', data: moveData, disableClose: false });
        let bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(job => {
          if (job == 'reload') {
            this.refreshTable.emit(true);
          }
          bottomSheetSubscription.unsubscribe();
        });
      }
      else {
        this.dialog.closeAll();
      }
    });

  }

}
