import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MatDialog, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faPlusCircle, faTimes, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Location } from '@angular/common';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { InventoryItemDTO } from '../../models/item';
import { InventoryItemAssemblyAttributeDTO, InventoryItemAssemblyAttributeValueDTO, InventoryItemAssemblyComponentDTO, InventoryItemAssemblyDTO } from '../../models/item-assembly';
import { I } from '@angular/cdk/keycodes';
import * as _ from 'underscore';

export interface ItemAssemblyPopupData {
  itemAssembly: InventoryItemAssemblyDTO;
  isPopup: boolean;
  isReturnBottomsheet?: boolean;
  isAssemblyEdit: boolean;
  summaryPopupId?: string;
  createItem?: number;
}

@Component({
  selector: 'item-assembly-edit',
  templateUrl: './item-assembly-edit.component.html',
  styleUrls: ['./item-assembly-edit.component.scss']
})
export class ItemAssemblyEditComponent implements OnInit {

  public assembly: InventoryItemAssemblyDTO = {};
  public isLoading: boolean = true;
  public items: InventoryItemDTO[] = [];
  public constituentItems: InventoryItemDTO[] = [];
  public assemblyAttrabutes: InventoryItemAssemblyAttributeDTO[];
  public currentComponent: InventoryItemAssemblyComponentDTO = {};


  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faTimes = faTimes;
  public faPlus = faPlusCircle;
  faTrash = faTrash;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: ItemAssemblyPopupData,
    private activatedRoute: ActivatedRoute,
    private inventoryService: InventoryService,
    private router: Router,
    private bottomSheet: MatBottomSheetRef<ItemAssemblyEditComponent>,
    private bottomSheetReturn: MatBottomSheet,
    private location: Location,
    private changeDetRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private errorService: ErrorService,) {
    if (data.itemAssembly)
      this.assembly = data.itemAssembly;
    else
      this.assembly = {};

    if(data.createItem)
      this.assembly.itemId = data.createItem;
  }

  ngOnInit() {
    this.isLoading = true;
    Observable.forkJoin([
      this.inventoryService.getInventoryItems(),
      this.inventoryService.getInventoryItemAssemblyAttributes()
      //this.roomService.getRooms()
    ])
      .subscribe(x => {
        this.constituentItems = _.sortBy(x[0].filter(y => y.parentItemName == "Plant Media Constituent"), z => z.name);
        this.items = _.sortBy(x[0].filter(y =>  y.parentItemName == "Plant Media"), z => z.name);
        this.assemblyAttrabutes = x[1];

        this.customFields();

        this.isLoading = false;
        this.changeDetRef.detectChanges();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
  }

  submit() {
    this.isLoading = true;
    if(!this.assembly.components)
      this.assembly.components = [];
    if (this.assembly.id) {
      this.inventoryService.putInventoryItemAssembly(this.assembly).subscribe(x => {
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
    else {
      this.inventoryService.postInventoryItemAssembly(this.assembly).subscribe(x => {
        this.assembly.id = x.id;
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
  }

  cancel() {
      this.bottomSheet.dismiss();
  }

  addComponent() {
    let newComponent: InventoryItemAssemblyComponentDTO = {}
    newComponent.isRequired = this.currentComponent.isRequired;
    newComponent.item = this.currentComponent.item;
    newComponent.itemId = this.currentComponent.itemId;
    newComponent.notes = this.currentComponent.notes;
    newComponent.quantityRequired = this.currentComponent.quantityRequired;
    newComponent.unitQuantity = this.currentComponent.unitQuantity;

    if (!this.assembly.components)
      this.assembly.components = [];
    this.assembly.components.push({ ...newComponent });
    this.currentComponent = {};
    this.changeDetRef.detectChanges();
  }

  componentItemChange(value: InventoryItemDTO) {
    this.currentComponent.item = value;
    this.currentComponent.itemId = value.id

  }

  removeComponent(row) {
    this.isLoading = true;
    this.assembly.components.forEach((element, index) => {
      if (element == row) {
        if(element.id != 0 && element.id != null){
          this.inventoryService.deleteInventoryItemAssemblyComponent(element.id).subscribe(x => {
            this.assembly.components.splice(index, 1);
            this.isLoading = false;
            this.changeDetRef.detectChanges();
          }, err => {
            this.errorService.handleError(err);
            this.isLoading = false;
          });
        }
        else{
          this.assembly.components.splice(index, 1);
        this.changeDetRef.detectChanges();
        this.isLoading = false;
        }
        
      }
    });
  }

  deleteAssembly() {
    this.isLoading = true;
    this.inventoryService.deleteInventoryItemAssembly(this.assembly.id).subscribe(x => {
      this.cancel();
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  customFields() {
    if (!this.assembly.attributeValues)
      this.assembly.attributeValues = [];

    this.assemblyAttrabutes.forEach(f => {

      var tempField = this.assembly.attributeValues.find(x => x.inventoryItemAssemblyAttributeId == f.id);
      if (tempField) {
        tempField.description = f.description;
        tempField.name = f.name;
        tempField.id = this.assembly.id;
      }
      else {
        let fieldValue: InventoryItemAssemblyAttributeValueDTO = {
          datatype: f.datatype,
          description: f.description,
          name: f.name,
          id: 0,
          inventoryItemAssemblyAttributeId: f.id,
          isActive: true
        };
        this.assembly.attributeValues.push(fieldValue);
      }
    });
    this.changeDetRef.markForCheck();
  }

  getOptionsLength(attrabute: InventoryItemAssemblyAttributeValueDTO) {
    var t = [];
    if (this.assemblyAttrabutes) {
      t = this.assemblyAttrabutes.find(x => x.id == attrabute.inventoryItemAssemblyAttributeId).options;
    }
    if (t)
      return t.length;
    else
      return 0;
  }

  ClearValue(field: InventoryItemAssemblyAttributeValueDTO) {
    var currentField = this.assembly.attributeValues.find(x => x.id == field.id);
    currentField.value = null;
  }

  getFieldName(field: InventoryItemAssemblyAttributeValueDTO) {
    var currentField = this.assemblyAttrabutes.find(x => x.id == field.inventoryItemAssemblyAttributeId);
    return currentField.name
  }

  getFieldOptions(field: InventoryItemAssemblyAttributeValueDTO) {
    var currentField = this.assemblyAttrabutes.find(x => x.id == field.inventoryItemAssemblyAttributeId);
    return currentField.options
  }

}
