import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldmanListDataSource } from '../fieldman-list-datasource';
import { FieldmanService } from '../../../services/Fieldman.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { Fieldman } from '../../../models/fieldman';
import { SessionService } from '../../../../../@core/utils/session.service';

@Component({
  selector: 'fieldman-edit-list',
  templateUrl: './fieldman-edit-list.component.html',
  styleUrls: ['./fieldman-edit-list.component.scss']
})
export class FieldmanEditListComponent implements OnInit {
  //#region Properties
  dataSource: FieldmanListDataSource;
  public editfieldman: Fieldman = {};
  public fieldmanId: number;
  public isLoading = false;
  public submitting: any;
  public editActive = false;
  //#endregion

  //#region ViewChild Properties
  @ViewChild(MatPaginator) 
  paginator: MatPaginator;

  @ViewChild(MatSort) 
  sort: MatSort;
  //#endregion
 
 //#region Constructor with dependencies
  constructor(
    private fieldmanService: FieldmanService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<any>,
    private sessionService: SessionService
  ) { }
 //#endregion
  ngOnInit() {
    this.dataSource = new FieldmanListDataSource(this.paginator, this.sort);
    this.isLoading = true;
     this.editfieldman = this.sessionService.selectedFieldMan;
     if(this.editfieldman === null || this.editfieldman === undefined) {
      this.editfieldman = {};
      this.editActive = true;
      this.isLoading = false;
     }

    this.fieldmanService.getFieldmen().subscribe(data => {
      this.dataSource.data = data;
      if(this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
      this.isLoading = false;
    }, err => { this.errorService.handleError(err); this.isLoading = false; });
  }

  saveFieldmanClicked() {
    this.isLoading = true;
    this.submitting = true;
    setTimeout(() => {
      if (this.editfieldman.id !== null && this.editfieldman.id !== undefined) {
        let index = this.dataSource.data.indexOf(this.dataSource.data.filter(w => w.id === this.editfieldman.id)[0]);
        this.fieldmanService.putFieldman(this.editfieldman).subscribe(x => {
          this.dataSource.data[index] = this.editfieldman;
          if(this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
          this.editfieldman = {};
          this.isLoading = false;
          this.dialogRef.close(true);
        }, err => {
          this.errorService.handleError(err);
          this.isLoading = false;
          this.dialogRef.close(false);
        });
      }
      else {
        this.fieldmanService.postFieldman(this.editfieldman).subscribe(x => {
          this.editfieldman.id = x.id;
          this.dataSource.data.push(this.editfieldman);
          if(this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
          this.editfieldman = {};
          this.isLoading = false;
          this.dialogRef.close(true);
        }, err => {
          this.errorService.handleError(err);
          this.isLoading = false;
          this.dialogRef.close(false);
        });
      }
      this.editActive = false;
    }, 1000);
  }

  cancelClicked(cancelled: boolean = false) {
    this.editActive = false;
    this.editfieldman = {};
    this.dialogRef.close(cancelled);
  }

}
