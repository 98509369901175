import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { RoleGuard } from './@core/auth/auth.roleguard.service';

const routes: Routes = [
	{
		path: '',
		loadChildren: 'app/pages/pages.module#PagesModule'
  },
  {
    path: 'overview-map',
    loadChildren: 'app/harvest-engine/modules/overview-map/overview-map.module#OverviewMapModule',
  },
  {
    path: 'public',
    loadChildren: 'app/public/public.module#PublicModule'
  },
  	
  // Reporting
  {
    path: 'reports',
    loadChildren: 'app/reports/report.module#ReportModule',
    outlet: 'reports'
  },
	{
		path: '**',
		redirectTo: '404',
		pathMatch: 'full'
  },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
