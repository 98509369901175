import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from '../../../../@core/utils/error.service';
import { SyncStatus } from '../../../models/sync-status';
import { PrecisionSessionService } from '../../services/precision-session.service';

@Component({
  selector: 'precision-sync-status',
  templateUrl: './sync-status.component.html',
  styleUrls: ['./sync-status.component.scss']
})
export class SyncStatusComponent implements OnInit, OnDestroy {

  constructor(
    private precisionSession: PrecisionSessionService,
    private errorService: ErrorService
  ) { }

  private timer: any;
  public status: SyncStatus = { status: "Offline", lastFailMessage: ""};
  public isLoading: boolean = true;
  public statusSubscription: any;

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  ngOnInit() {
    this.getStatus();

    this.timer = setInterval(() => {
      this.getStatus();
    }, 60000);

  }

// UNCOMMENT THIS BEFORE DEPLOYING!!!!!!!!!!!!!!!!!!!!!!
  getStatus() {
    // this.statusSubscription = this.precisionSession.getSyncStatus().subscribe(data => {
    //   this.status = data;
    //   this.isLoading = false;
    //   this.statusSubscription = null;
    // }, error => {
    //   this.isLoading = false;
    // });
    this.isLoading = false;
  }

  getStatusColor() {
    if (this.status.status == "Online")
      return "green"
    else if (this.status.status == "Offline")
      return "darkred"
  }

}
