import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Warehouse } from '../../models/storage/warehouse';
import * as _ from 'underscore';
import { WarehouseService } from '../../services/storage/warehouse-service';
import { ErrorService } from '../../../../@core/utils/error.service';


@Component({
  selector: 'warehouse-selector',
  templateUrl: './warehouse-selector.component.html',
  styleUrls: ['./warehouse-selector.component.scss']
})
export class WarehouseSelectorComponent implements OnInit {

  @Input() warehouse: Warehouse;
  @Input() public selectedWarehouseId: number;
  @Output() warehouseChange: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();

  @Output() selectionChanged: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();

  @Input() isRequired: boolean = false;
  public searchValue: string = "";
  public isLoading = true;

  public objList: Warehouse[] = [];
  public allObj: Warehouse[] = [];

  constructor(
    private warehouseService: WarehouseService,
    private changeDetRef: ChangeDetectorRef,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.warehouseService.getWarehouses().subscribe(r => {
      this.allObj = r;

      if(this.selectedWarehouseId) {
        this.warehouse = this.allObj.find(x => x.id == this.selectedWarehouseId);
        this.filter();
        this.isLoading = false;
        this.changeDetRef.markForCheck();
      } else {
        this.filter();
        this.isLoading = false;
        this.changeDetRef.markForCheck();
      }

    }, err => this.errorService.handleError(err));
  }

  onSelectChange(){
    
    this.selectionChanged.emit(this.warehouse);
  }

  clearSelection(){
    this.warehouse = {};
  }

  filter() {

    let filterList = this.allObj.filter(x => this.searchValue === ''|| x.name.toLowerCase().includes(this.searchValue.toLowerCase()));

    if (filterList.length > 20)
      this.objList = [];
    else
      this.objList = _.sortBy(filterList, 'name');

    // add selected warehouse so it shows in the list
    if (this.warehouse && this.warehouse.id !== 0) {
      if (this.objList.filter(x => x.id == this.warehouse.id).length === 0) {
        let selectedObj = this.allObj.filter(x => x.id == this.warehouse.id)[0];
        if (selectedObj) {
          this.objList.push(selectedObj);
        }
      }
    }
  }

}