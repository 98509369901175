import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { WorkOrderScheduleComponent } from './pages/work-order-schedule/work-order-schedule.component';
// import { ScheduleCellContentsComponent } from './pages/schedule-cell-contents/schedule-cell-contents.component';
// import { WorkOrdersRoutingModule } from './contracts.routing.module';
// import { AgendaService, DayService, MonthAgendaService, MonthService, ResizeService, ScheduleModule, TimelineMonthService, TimelineViewsService, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { NbCardModule, NbSpinnerModule } from '@nebular/theme';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { WorkOrderInputComponent } from './pages/work-order-input/work-order-input.component';
// import { PagesModule } from '../../../pages/pages.module';
// import { PartialsModule } from '../../../partials/partials.module';
import {MatSortModule, MatPaginatorModule, MatTableModule,MatBottomSheetModule, MatButtonModule, MatButtonToggleModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatDivider, MatDividerModule,
  MatFormFieldModule,MatIconModule, MatInputModule, MatSelectModule, MatSlideToggleModule, MatTabsModule } from '@angular/material';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule } from '@angular/forms';
// import { TcPropagationComponent } from './widgets/tc-propagation/tc-propagation.component';
// import { MediaMakingComponent } from './widgets/media-making/media-making.component';
// import { ContaminationChecksComponent } from './widgets/contamination-checks/contamination-checks.component';
import { TextFieldModule } from '@angular/cdk/text-field';
// import { WorkOrderTypesComponent } from './pages/work-order-types/work-order-types.component';
// import { WorkOrderTypeEditComponent } from './pages/work-order-type-edit/work-order-type-edit.component';
import { TenantWordsModule } from '../../core/pipes/tenant-words.module';
import { FreezeService, GridModule, GroupService, PageService, SortService } from '@syncfusion/ej2-angular-grids';
// import { GhWorkOrderScheduleComponent } from './pages/gh-work-order-schedule/gh-work-order-schedule.component';
// import { GhScheduleCellContentsComponent } from './pages/gh-schedule-cell-contents/gh-schedule-cell-contents.component';
// import { GhWorkOrderInputComponent } from './pages/gh-work-order-input/gh-work-order-input.component';
// import { GreenhouseWorkOrderComponent } from './widgets/greenhouse-work-order/greenhouse-work-order.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { DateTimePickerModule, CalendarModule,DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
// import { WorkOrderReportComponent } from './pages/work-order-report/work-order-report.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import { PrinterSelectorModule } from '../../core/widgets/printer-select/printer-selector.module';
// import { KwFilterDropdownModule } from '../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.module';
// import { GhLotGeneratorComponent } from './pages/gh-lot-generator/gh-lot-generator.component';
// import { AlertConfig, AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-modialog';
import { BootstrapModalModule } from 'ngx-modialog/plugins/bootstrap';



// import { GenericWorkOrderComponent } from '../work-orders/widgets/generic-work-order/generic-work-order.component';
import { ContractInputComponent } from './contract-input/contract-input.component';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractAmmendComponent } from './contract-ammend/contract-ammend.component';
import { PopupModalComponent } from '../contracts/widgets/popup-modal/popup-modal.component';
import { NgxDropzoneModule } from 'ngx-dropzone';


@NgModule({
  declarations: [
    // WorkOrderInputComponent,
    // WorkOrderScheduleComponent, 
    // GhWorkOrderScheduleComponent,
    // ScheduleCellContentsComponent, 
    // GhScheduleCellContentsComponent,
    // TcPropagationComponent, 
    // MediaMakingComponent, 
    // ContaminationChecksComponent, 
    // WorkOrderTypesComponent, 
    // WorkOrderTypeEditComponent,
    // GhWorkOrderInputComponent,
    // GreenhouseWorkOrderComponent,
    // WorkOrderReportComponent,
    // GhLotGeneratorComponent,
    // WorkOrderReportComponent, 
    // GenericWorkOrderComponent,
    ContractInputComponent,
    ContractListComponent,
    ContractAmmendComponent,
    PopupModalComponent
  ],
  imports: [
    ModalModule.forRoot(),
    BootstrapModalModule,
    // AlertModule,
    // KwFilterDropdownModule,
    NgxDropzoneModule,
    DateTimePickerModule,
    DatePickerModule,
    CalendarModule,
    CommonModule,
    // WorkOrdersRoutingModule,
    // ScheduleModule,
    NbCardModule,
    FontAwesomeModule,
    MatButtonToggleModule,
    NbSpinnerModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    // AngularMultiSelectModule,
    TenantWordsModule,
    FormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatFormFieldModule,
    GridModule,
    MatSelectModule,
    MatTabsModule,
    TextFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDividerModule,
    MatDatepickerModule,
    MatIconModule,
    OwlDateTimeModule,
    DropDownListAllModule,
    NgxMatSelectSearchModule,
  ],

  exports: [
    ContractInputComponent,
    PopupModalComponent
  ],

  entryComponents: [
    ContractInputComponent,
    PopupModalComponent
  ],

  providers: [
    PageService,
    SortService,
    GroupService,
    FreezeService,
    ModalModule
  ],
  bootstrap: [ContractListComponent]
})
export class ContractsModule { }
