import { Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { MatPaginator, MatSort } from '@angular/material';
import { EventEmitter } from 'events';
import { TenantWords } from '../../../models/tenantWords';
import { TenantWordService } from '../../../services/TenantWord.service';
import { faCheck, faPlusCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { DictionaryDataSource } from './dictionary-datasource';
import { AuthService } from '../../../../../@core/auth/auth.service';

@Component({
  selector: 'dictionary',
  templateUrl: './dictionary.component.html',
  styleUrls: ['./dictionary.component.css']
})
export class DictionaryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: DictionaryDataSource;
  public faPlusCircle = faPlusCircle;

  public editWord: TenantWords = {};
  public wordId: number;
  public wordLoading = false;
  public submitting: any;
  public editActive = false;
  
  public faTimes = faTimes;
  public faCheck = faCheck;

  constructor(
    private wordService: TenantWordService,
    private authService: AuthService,
    private errorService: ErrorService
  ){}


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['originalWord', 'replacementWord', 'isActive', 'edit'];

  ngOnInit() {
    this.dataSource = new DictionaryDataSource(this.paginator, this.sort);

    this.dataSource.data = JSON.parse(localStorage.getItem('tenantWordDictionary'));
    this.paginator._changePageSize(this.paginator.pageSize);
    
  }

  editClicked(id : number) {
    this.editWord = {...this.dataSource.data.filter(u => u.id === id)[0]};
    this.editActive = true;
  }

  saveWordClicked() {
    if(this.editWord.isActive === null || this.editWord.isActive === undefined){ this.editWord.isActive = false; }
    if(this.editWord.id !== null && this.editWord.id !== undefined){
        let index =  this.dataSource.data.indexOf(this.dataSource.data.filter(w => w.id === this.editWord.id)[0]);
        this.wordService.putTenantWords(this.editWord, String(this.authService.getUserToken())).subscribe(x => {
        this.dataSource.data[index] = this.editWord;
        localStorage.setItem('tenantWordDictionary', JSON.stringify(this.dataSource.data));
        this.paginator._changePageSize(this.paginator.pageSize)
        this.editWord = {};
      }, err => {this.errorService.handleError(err)});
    }
    else{
        this.wordService.postTenantWords(this.editWord, String(this.authService.getUserToken())).subscribe(x => {
          this.editWord.id = x.id;
          this.dataSource.data.push(this.editWord);
          localStorage.setItem('tenantWordDictionary', JSON.stringify(this.dataSource.data));
          this.paginator._changePageSize(this.paginator.pageSize)
          this.editWord = {};
      }, err => {this.errorService.handleError(err)});
    }
    this.editActive = false;
  }

  cancelClicked() {
    this.editActive = false;
    this.editWord = {};
  }

  deleteWordClicked(){
    if(this.editWord.id !== null && this.editWord.id !== undefined){
      this.wordService.deleteTenantWords(this.editWord.id, String(this.authService.getUserToken())).subscribe(x => {
        this.dataSource.data.splice(this.dataSource.data.indexOf(this.editWord), 1);
        this.paginator._changePageSize(this.paginator.pageSize)
        localStorage.setItem('tenantWordDictionary', JSON.stringify(this.dataSource.data));
        this.editWord = {};
      }, err => {this.errorService.handleError(err)});
    }
    else{
      alert("No Dictionary Selected!!");
    }
    this.editActive = false;
  }

  addWordClicked(){
    this.editWord = {};
    this.editActive = true;
  }
}
