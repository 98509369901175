import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { PrecisionFileData } from '../../../models/precision-file-data';
import { faFolder, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import * as Dropbox from 'dropbox';
import { PrecisionDropboxService } from '../../../services/precision-dropbox.service';


@Component({
  selector: 'precision-file-display',
  templateUrl: './precision-file-display.component.html',
  styleUrls: ['./precision-file-display.component.scss'],
})
export class PrecisionFileDisplayComponent implements OnInit {

  public faFolder: any = faFolder;
  public faFile: any = faFileDownload;

  @Output() downloadComplete= new EventEmitter();

  @Input() fileData: PrecisionFileData[] = [];
  constructor(
    private dropboxService: PrecisionDropboxService,
    private changeDetRef: ChangeDetectorRef
  ) { 

  }

  downloadFile(file: PrecisionFileData) {
    this.dropboxService.isDownloading = true;
    this.downloadComplete.emit();
    this.dropboxService.getDropboxKey().subscribe(key => {

      let dbx = new Dropbox.Dropbox({ accessToken: key });

      dbx.filesGetTemporaryLink({ path: file.path })
        .then(response => {

          var link = document.createElement("a");
          link.href = response.link;
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.dropboxService.isDownloading = false;
          this.changeDetRef.markForCheck();


        })
        .catch(function (error) {
          // handle error
          this.dropboxService.isDownloading = false;
        });
       
    });

  }

  downloadCompletedEventTriggered(){
    this.downloadComplete.emit();
  }
  ngOnInit() {
  }

}
