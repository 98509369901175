import { Injectable } from '@angular/core';
import { BlockHistoryDTO } from '../../../../../harvest-engine/core/models/block';
import { TimelineGroup, TimelineItem } from '../../general/vertical-timeline/vertical-timeline.component';

@Injectable({
  providedIn: 'root'
})
export class TimelineHistoryService {

  constructor() { }

  getTimelineGroups(histories: BlockHistoryDTO[]) : TimelineGroup[] {
    let returnArray : TimelineGroup[] = [];
    
    // get distinct years
    let years = Array.from(new Set(
                                  histories.map(h => new Date(h.createdDateTime).getFullYear())
                                ));
    
    // iterate through each year in the list (sorted descending) and get the months
    for(let year of years.sort((a, b) => b - a)) {

      let events = histories.filter(h => new Date(h.createdDateTime).getFullYear() === year).sort((a,b) => {
          return new Date(b.createdDateTime).valueOf() - new Date(a.createdDateTime).valueOf();
      });

      let timelineItems: TimelineItem[] = [];

      for(let event of events){
        timelineItems.push(this.getTimelineItem(event));
      }
      let group: TimelineGroup = {
        timelineItems: timelineItems,
        timelineYear: year.toString(),
      };

      returnArray.push(group);
    }

    return returnArray;
  }

  getTimelineItem(event: BlockHistoryDTO): TimelineItem {
    let item: TimelineItem = {};
    let eventDate = new Date(event.createdDateTime);

    switch(event.eventType) {
      case "Variety Change":
        item.timelineDates = [ { timelineDays: [ eventDate.getDate().toString() ], timelineMonths: [ eventDate.toLocaleString('en-us', {month: 'short'}) ] } ]
        item.timelinePosts = [ { contentInnerHTML: event.description } ]
        break;
      case "Field Event":
        item.timelineDates = [
          {
            timelineMonths: [
              '<i class="fas ' + event.icon + ' "></i> x' + event.eventDetails.count,
              eventDate.toLocaleString('en-us', {month: 'short'}) + ' ' + eventDate.getDate(),
            ]
          }
        ]
        break;
      default:
        item.timelineDates = [ { timelineDays: [ eventDate.getDate().toString() ], timelineMonths: [ eventDate.toLocaleString('en-us', {month: 'short'}) ] } ]
        item.timelinePosts = [ { contentInnerHTML: event.description } ]
      break;

    }

    return item;
  }
}
