import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MatDialog, MatDialogRef, MAT_BOTTOM_SHEET_DATA, MAT_DIALOG_DATA } from '@angular/material';
import { faArrowAltCircleLeft, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSave, faScanner, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs/Rx';
import { identity } from 'underscore';
import { AuthService } from '../../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../../@core/utils/error.service';
import { ConfirmationDialogComponent } from '../../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { TransactionReasonCode } from '../../../../../core/models/transactionReasonCode';
import { RoomService } from '../../../../../core/services/storage/room-service';
import { WarehouseService } from '../../../../../core/services/storage/warehouse-service';
import { TransactionService } from '../../../../../core/services/TransactionService';
import { InventoryService } from '../../../../pick-plan/services/inventory.service';
import { HandlingUnitBulkDelete } from '../../../models/handling-unit-bulk-actions';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import { InventorySessionService } from '../../../services/inventory-session.service';
import { InventoryScanPopupComponent } from '../inventory-scan-popup/inventory-scan-popup.component';
import { RemoveInventoryConfirmationComponent } from '../inventory-summary-popup/remove-inventory-confirmation/remove-inventory-confirmation.component';

@Component({
  selector: 'inventory-move-location-popup',
  templateUrl: './inventory-move-location-popup.component.html',
  styleUrls: ['./inventory-move-location-popup.component.scss']
})
export class InventoryMoveLocationPopupComponent implements OnInit {

  public isLoading: boolean = false;
  public faTimes = faTimes;
  public faTrashAlt = faTrashAlt;
  public faScanner = faScanner;

  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTrash = faTrash;
  faSave = faSave;
  public warehouses = [];
  public rooms = [];
  public locations = [];
  public allWarehouses = [];
  public allRooms = [];
  public allLocations = [];
  public transactionReasons = [];
  
  public moveToHum: HandlingUnitMasterDTO = {};
  bulkRemoveInventory: HandlingUnitBulkDelete = {};

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public dialogRef: MatBottomSheetRef<InventoryMoveLocationPopupComponent>,
    private errorService: ErrorService,
    public roomService: RoomService,
    public warehouseService: WarehouseService,
    public transactionService: TransactionService,
    private changeDetRef: ChangeDetectorRef,
    public authService: AuthService,
    private dialog: MatDialog,
    private toastr: NbToastrService,
    private confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private inventoryService: InventoryService,
    private session: InventorySessionService,
    ) { }

  ngOnInit() {
    this.isLoading = true;
    Observable.forkJoin([
      this.warehouseService.getWarehouses(),
      this.roomService.getRooms(),
      this.roomService.getRoomLocations(),
      this.transactionService.getTransactionReasonCodes()
      //this.roomService.getRooms()
    ])
      .subscribe(x => {
        this.allWarehouses = x[0];
        this.allRooms = x[1];
        this.allLocations = x[2];
        this.transactionReasons = x[3];

        this.warehouses = this.allWarehouses;
        this.rooms = this.allRooms;
        this.locations = this.allLocations; 
        
        if(this.data.hum.isMultiple) {

          this.moveToHum = JSON.parse(JSON.stringify(this.data.hum));
          
          // this.moveToHum.handlingUnitDetails.forEach(x => { x.isChecked = true; });
          this.isLoading = false;
          if(this.moveToHum.roomLocationId) 
            this.locationSelectionChanged()
            
          this.changeDetRef.detectChanges();

        } else if(!this.data.hum.isMultiple) {

          this.moveToHum = JSON.parse(JSON.stringify(this.data.hum));
          this.moveToHum.handlingUnitDetails.forEach(x => { x.isChecked = true; });

          if(this.moveToHum.roomLocationId)
            this.locationSelectionChanged()
          this.changeDetRef.detectChanges();
          }
        
          this.isLoading = false;
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
  }


  close() {
    this.dialogRef.dismiss();
  }

  removeInventory() {
    if(!this.moveToHum.transactionReasonCodeId || this.moveToHum.transactionReasonCodeId == 0){
      this.toastr.warning("Please select a reason code", "No reason code");
      return;
    }

    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Remove Inventory',
        confirmMessage: 'Are you sure you want to delete the Inventory for ' + this.moveToHum.barcode + '?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {
      if (result == true)
        this.inventoryService.deleteInventoryHUM(this.moveToHum.id).subscribe(x => {
          this.confirmationDialogRef.close();
          this.dialog.closeAll();
        });
        this.dialogRef.dismiss(true);
    });
  }

  scan() {
    const dialogRef = this.dialog.open(InventoryScanPopupComponent, {
      minWidth: '30%',
      data: {
        isHumEdit: true
      }
    });

    dialogRef.afterClosed().subscribe(x => {
      if (x.isLocation) {
        this.moveToHum.roomLocationId = x.location.id;
        this.locationSelectionChanged();
      }

      if (x.isRoom) {
        this.moveToHum.roomId = x.room.id;
        this.roomSelectionChanged();
      }

      if (x.isWarehouse) {
        this.moveToHum.warehouseId = x.warehouse.id;
        this.warehouseSelectionChanged();
      }

      this.isLoading = false;
      this.changeDetRef.markForCheck();
    });
  }

  submit() {
    this.isLoading = true;
    let moveAll = true;

    this.moveToHum.handlingUnitDetails.forEach(x => {
      if (!x.isChecked) {
        moveAll = false;
      }
    });

    if (moveAll) {
      this.data.hum.warehouseId = this.moveToHum.warehouseId;
      this.data.hum.warehouseName = this.allWarehouses.find(x => x.id == this.moveToHum.warehouseId).name;
      this.data.hum.roomId = this.moveToHum.roomId;      

      let moveToRoom = this.allRooms.find(x => x.id == this.moveToHum.roomId);

      if(moveToRoom)
        this.data.hum.roomName = moveToRoom.name;
        
      this.data.hum.roomLocationId = this.moveToHum.roomLocationId;
      this.data.hum.transactionReasonCodeId = this.moveToHum.transactionReasonCodeId;
      if(this.allLocations.find(x => x.id == this.moveToHum.roomLocationId))
        this.data.hum.roomLocationName = this.allLocations.find(x => x.id == this.moveToHum.roomLocationId).name;
      else
        this.data.hum.roomLocationName = null;

      this.inventoryService.putInventoryHUM(this.data.hum).subscribe(x => {
        this.dialogRef.dismiss(this.data.hum);
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
    else {
      this.data.hum.transactionReasonCodeId = this.moveToHum.transactionReasonCodeId;
      this.data.hum.handlingUnitDetails.forEach(x => {
        let currentDetail = this.moveToHum.handlingUnitDetails.find(mx => mx.id == x.id);
        if (currentDetail.isChecked) {
          if (currentDetail.quantity == x.quantity) {
            x.isActive = false;
          }
          else if (currentDetail.quantity != x.quantity) {
            x.quantity = x.quantity - currentDetail.quantity
          }
        }
        else {
          this.moveToHum.handlingUnitDetails = this.moveToHum.handlingUnitDetails.filter(x => x.id != currentDetail.id);
        }
        currentDetail.id = 0;
      });

      this.moveToHum.id = 0;

      Observable.forkJoin([
        this.inventoryService.putInventoryHUM(this.data.hum),
        this.inventoryService.postInventoryHUM(this.moveToHum)
        //this.roomService.getRooms()
      ]).subscribe(x => {
        this.dialogRef.dismiss(this.data.hum);
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
        this.changeDetRef.detectChanges();
      });
    }
  }

  removeInventoryBulk() {
    const confRef = this.dialog.open(RemoveInventoryConfirmationComponent, {
      minWidth: '10%',
      data: {
      }
    }).afterClosed().subscribe(result => {
      
      if (result.result == true) {

        this.isLoading = true;

        this.bulkRemoveInventory.handlingUnitMasterIds = this.session.handlingUnitBulkMoveList[0].handlingUnitMasterIds;
        this.bulkRemoveInventory.reasonCodeId = 0;  

        if (result.transactionReasonCodeId) {
          this.bulkRemoveInventory.reasonCodeId = result.transactionReasonCodeId;
        }

        this.inventoryService.bulkDeleteHandlingUnitMasters(this.bulkRemoveInventory).subscribe(x => {
          this.toastr.success('Items successfully removed from inventory', 'SUCCESS');
          this.dialogRef.dismiss(true);
          this.session.handlingUnitBulkMoveList = [];
        }, err => {
          this.isLoading = false;
          this.errorService.handleError(err);
        });
      }
    });
  }

  saveBulkMove() {
    this.isLoading = true;
    this.inventoryService.bulkMoveHandlingUnitMasters(this.moveToHum).subscribe(x => {
      this.isLoading = false;
      this.dialogRef.dismiss(true);
      this.toastr.success('Bulk move successful', 'SUCCESS');
      this.changeDetRef.detectChanges();
    }, err => {
      this.isLoading = false;
      this.errorService.handleError(err);
    });
    
  }

  roomSelectionChanged(){
    let room = this.allRooms.find(x => x.id == this.moveToHum.roomId);
    this.moveToHum.warehouseId = room.warehouseId;
    this.locations = [...this.allLocations.filter(x => {
      return this.rooms.some( f => {
        return room.id == x.roomId;
      });
    })];
    this.moveToHum.roomLocationId = null;
    this.changeDetRef.detectChanges();
  }

  warehouseSelectionChanged(){
    let warehouse = this.allWarehouses.find(x => x.id == this.moveToHum.warehouseId);
    this.rooms = [...this.allRooms.filter(x => x.warehouseId == warehouse.id)];
    this.locations = [...this.allLocations.filter(x => {
      return this.rooms.some( f => {
        return f.id == x.roomId;
      });
    })];
    this.moveToHum.roomId = null;
    this.moveToHum.roomLocationId = null;
    this.changeDetRef.detectChanges();
  }

  locationSelectionChanged(){
    let location = this.allLocations.find(x => x.id == this.moveToHum.roomLocationId);
    let room = this.allRooms.find(x => x.id == location.roomId);
    this.moveToHum.warehouseId = room.warehouseId;
    this.moveToHum.roomId = room.id;
    this.locations = [...this.allLocations.filter(x => {
      return this.rooms.some( f => {
        return room.id == x.roomId;
      });
    })];
    //this.moveToHum.roomLocationId = null;
    this.changeDetRef.detectChanges();
  }

  setReasonId(){
    let t = this.moveToHum;
    
  }
}
