import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AggregateRowModel, ColumnModel, FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { HandlingUnitMasterDTO } from '../../../models/handling-unit-master';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import { HandlingUnitDetailDTO } from '../../../models/handling-unit-detail';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { HandlingUnitBulkMove } from '../../../models/handling-unit-bulk-actions';
import { Router } from '@angular/router';
import { InventorySessionService } from '../../../services/inventory-session.service';
import { KwFilterDropdownService } from '../../../../../core/widgets/kw-filter-dropdown/kw-filter-dropdown.service';

@Component({
  selector: 'lot-barcode-summary',
  templateUrl: './lot-barcode-summary.component.html',
  styleUrls: ['./lot-barcode-summary.component.scss']
})
export class LotBarcodeSummaryComponent implements OnInit {



  @Input() allHandlingUnits: HandlingUnitMasterDTO[] = [];
  @Input() lotId: number;
  @Input() lotNumber: string;

  public handlingUnitDetails: HandlingUnitDetailDTO[] = [];
  public faPrint = faPrint;

  public dateFormat = { type: 'date', format: 'MM/dd/yyyy' };

  public selectionOptions = { type: 'Multiple' };
  public columns: ColumnModel[] = [];

  public aggregates: AggregateRowModel[] = [];

  public data: any[] = [];

  public pageSettings: PageSettingsModel = {
    pageSize: 10,
    pageSizes: [10, 25, 50],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }

  constructor(
    private router: Router,
    public filterService: KwFilterDropdownService,
    private session: InventorySessionService) { }

  getLocation(x: HandlingUnitMasterDTO) {
    let locations: string[] = [];

    if (x.warehouseName)
      locations.push(x.warehouseName);

    if (x.roomName)
      locations.push(x.roomName);

    if (x.roomLocationName)
      locations.push(x.roomLocationName);

    if (locations.length > 0)
      return locations.join(', ');
    else
      return 'Unknown';
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.aggregates = [];
    let aggregate: AggregateRowModel = { columns: []};

    this.handlingUnitDetails = this.allHandlingUnits.flatMap(x => x.handlingUnitDetails).filter(x => x.lotId == this.lotId);

    let barcodeGroups = _.groupBy(this.allHandlingUnits, x => x.barcode);

    this.data = _.map(barcodeGroups, (hums, key: any) => {

      let value = hums.flatMap(x => x.handlingUnitDetails).filter(x => x.lotId == this.lotId);
      let hum = this.allHandlingUnits.find(x => x.barcode == key);

      return {
        barcode: key,
        //id: key.handlingUnitMasterId,
        qty: lodash.sum(value.map(y => y.quantity)),
        status: hum.inventoryStatusName,
        hasComponents: value[0].packageHasComponents,
        packageComponentName: value[0].packageComponentName,
        packageComponentQuantityPerPackage: value[0].packageComponentQuantityPerPackage,
        packageComponentUnitOfMeasure: value[0].packageComponentUnitOfMeasure,
        packageComponentWeight: value[0].packageComponentWeight,
        packageComponentWeightUnitOfMeasure: value[0].packageComponentWeightUnitOfMeasure,
        packageQuantityPerPallet: value[0].packageQuantityPerPallet,
        packageUnitOfMeasure: value[0].packageUnitOfMeasure,
        packageName: value[0].packageName,
        packageWeight: value[0].packageWeight,
        totalWeight: lodash.sum(value.map(y => y.quantity)) * value[0].packageWeight,
        location: this.getLocation(hum)
      };
    });

    for (let product of this.data) {
      if (product.hasComponents && !this.columns.find(x => x.field == product.packageComponentName)) {


        // get package qty
        product[product.packageUnitOfMeasure] = Math.floor(product.qty);


        // get components
        product[product.packageComponentName] = (product.qty % 2 * product.packageComponentQuantityPerPackage);

        // weight
        product['weight'] = product.totalWeight;


      }
    }

    // set columns

    this.columns.push({
      field: 'barcode',
      headerText: 'Barcode',
      autoFit: false
    });


    this.columns.push({
      field: 'status',
      headerText: 'Status',
      autoFit: true
    });

    this.columns.push({
      field: 'location',
      headerText: 'Location',
      autoFit: false
    })



    let packageUnitOfMeasures = _.uniq(this.data.map(x => x.packageUnitOfMeasure).filter(x => x != ""));

    for (let packageUnitOfMeasure of packageUnitOfMeasures) {
      this.columns.push({
        field: 'qty',
        headerText: packageUnitOfMeasure,
        format: 'N1',
        autoFit: true
      });

      aggregate.columns.push({
        field: 'qty',
        type: 'sum',
        format: 'N1'
      });
    }


    let uniqComponentPackageNames = _.uniq(this.data.map(x => x.packageComponentName).filter(x => x != ""));

    for (let uniqPackageName of uniqComponentPackageNames) {
      this.columns.push({
        field: uniqPackageName,
        headerText: uniqPackageName,
        format: 'N1',
        autoFit: true
      });

      aggregate.columns.push({
        field: uniqPackageName,
        type: 'sum',
        format: 'N1'
      });
    }


    this.columns.push({
      field: 'totalWeight',
      headerText: 'Weight',
      autoFit: true,
      format: 'N1'
    });

    aggregate.columns.push({
      field: 'totalWeight',
      type: 'sum',
      format: 'N1'
    });

    this.aggregates.push(aggregate);
  }


  bulkAction(){

    let bulkMoveList: HandlingUnitBulkMove[] = [];

    let masterIds = this.handlingUnitDetails.map(x => x.handlingUnitMasterId);
    let hums = this.allHandlingUnits.filter(x => masterIds.includes(x.id));

    let groupData = hums.reduce((r, a) => {
      r[a.barcode] = [...r[a.barcode] || [], a];
      return r;
    }, {});


    const barcodes = Object.keys(groupData);
    for (const barcode of barcodes) {
      const x = groupData[barcode];

      let warehouse = this.filterService.allWarehouses.find(y => y.id == x[0].warehouseId);
      let room = this.filterService.allRooms.find(y => y.id == x[0].roomId);
      let location = this.filterService.allLocations.find(y => y.id == x[0].roomLocationId);

      const uniqueItemsSet = new Set();
      x.forEach(item => {
        const value = item["itemName"];
        uniqueItemsSet.add(value);
      });

      const uniqueLotSet = new Set();
      x.forEach(item => {
        const value = item["lotNumber"];
        uniqueLotSet.add(value);
      });

      bulkMoveList.push({
        humId: x[0].id,
        warehouseId: x[0].warehouseId ? x[0].warehouseId : 0,
        roomId: x[0].roomId ? x[0].roomId : 0,
        roomLocationId: x[0].roomLocationId ? x[0].roomLocationId : 0,
        roomLocationName: location ? location.name : "",
        roomName: room ? room.name : "",
        warehouseName: warehouse ? warehouse.name : "",
        tagNumber: x[0].barcode,
        details:  Array.from(uniqueItemsSet).join(', '), //(x.handlingUnitDetails.length > 0 ? x.handlingUnitDetails[0].itemName : ""),
        lot: this.lotNumber, //(x.handlingUnitDetails.length > 0 ? x.handlingUnitDetails[0].lotNumber : ""),
        isMultiple: true,
        boxStart :  x[0].boxStart ? x[0].boxStart : '',
        boxEnd :  x[0].boxEnd ? x[0].boxEnd : '',
        integrationId : x[0].integrationId ? x[0].integrationId: '',
        dateIn : x[0].dateIn ?  x[0].dateIn : '',
        dateOut : x[0].dateOut ? x[0].dateOut : ''
      });

      if (!bulkMoveList[0].handlingUnitMasterIds) {
        bulkMoveList[0].handlingUnitMasterIds = [];
        bulkMoveList[0].handlingUnitMasterIds.push(x[0].id);
      } else {
        bulkMoveList[0].handlingUnitMasterIds.push(x[0].id);
      }
    }
    this.session.handlingUnitBulkMoveList = bulkMoveList;
    this.router.navigateByUrl("/harvest-engine/inventory/inventory-bulk-move");
  }

  ngOnInit() {
  }
}
