import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit } from '@angular/core';
import { FilterSettings } from '../../../../core/widgets/kw-filter-dropdown/models/filter-options';
import { FilterResults } from '../../../../core/widgets/kw-filter-dropdown/models/filter-results';

@Component({
  selector: 'cycle-count',
  templateUrl: './cycle-count.component.html',
  styleUrls: ['./cycle-count.component.scss']
})
export class CycleCountComponent implements OnInit {

  public steps = CycleCountStep;
  public selectedStep: string = this.steps.SELECT_AREA;

  public dropdownSettings: FilterSettings = {
    roomSettings: {
      display: true,
      singleSelection: true
    },
    warehouseSettings: {
      display: true,
      singleSelection: false
    },
    locationSettings: {
      display: true,
      singleSelection: false
    }
  }
  public isLoading: boolean = true;

  constructor() { }

  ngOnInit() {
    // this.isLoading = false;
  }


  setFilterResults(results: FilterResults) {

    this.isLoading = false;
  }



  lottieConfig = {
    path: 'https://res.cloudinary.com/kragworks/raw/upload/v1627967006/HarvestEngine/lottie/select.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

}


export const CycleCountStep = {
  SELECT_AREA: "SELECT_AREA",
  LOCATION_SUMMARY: "LOCATION_SUMMARY",
}
