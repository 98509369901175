import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, I18nSelectPipe, PercentPipe, AsyncPipe, DecimalPipe, JsonPipe, UpperCasePipe, I18nPluralPipe, KeyValuePipe, SlicePipe, DatePipe, LowerCasePipe, TitleCasePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FieldTaskInputComponent } from './content/widgets/field-tasks/field-task-input/field-task-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickListModule } from 'primeng/picklist';
import { ToastModule } from 'primeng/toast';
import { ChartModule } from 'primeng/chart';
import { ChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { LottieAnimationViewModule } from 'ng-lottie';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatExpansionModule,

	MatIconModule,
	MatInputModule,
	MatListModule,

	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatSelectModule,
	MatSortModule,
	MatTableModule,
	MatToolbarModule,
	MatTooltipModule,


} from '@angular/material';
import { BlockSelectComponent } from './content/widgets/general/block-select/block-select.component';
import { UserSelectComponent } from './content/widgets/general/user-select/user-select.component';
import { FieldTaskPieChartComponent } from './content/widgets/field-tasks/field-task-pie-chart/field-task-pie-chart.component';
import { FieldTaskLineChartComponent } from './content/widgets/field-tasks/field-task-line-chart/field-task-line-chart.component';
import { FieldTaskBarChartComponent } from './content/widgets/field-tasks/field-task-bar-chart/field-task-bar-chart.component';
import { FieldTaskTableComponent } from './content/widgets/field-tasks/field-task-table/field-task-table.component';
import { ConfirmationDialogComponent } from './content/widgets/general/confirmation-dialog/confirmation-dialog.component';
import { FieldTaskActiveComponent } from './content/widgets/field-tasks/field-task-active/field-task-active.component';
import { FieldLifecycleTimelineComponent } from './content/widgets/field-lifecycle/field-lifecycle-timeline/field-lifecycle-timeline.component';
import { VerticalTimelineComponent } from './content/widgets/general/vertical-timeline/vertical-timeline.component';
import { BlockMapDisplayComponent } from './content/widgets/field-lifecycle/block-map-display/block-map-display.component';

import { NbCardModule, NbSpinnerModule, NbDatepickerModule, NbPopoverModule } from '@nebular/theme';
import { GMapModule } from 'primeng/gmap';
import { TimelineHistoryService } from './content/widgets/field-lifecycle/field-lifecycle-timeline/timeline-history.service';
import { FieldTaskCompletedByMonthComponent } from './content/widgets/field-tasks/field-task-completed-by-month/field-task-completed-by-month.component';
import { AssignedUsersPipe } from './content/widgets/field-tasks/pipes/assigned-user/assigned-users.pipe';
import { FieldTaskBlockPopoverComponent } from './content/widgets/field-tasks/field-task-table/field-task-block-popover/field-task-block-popover.component';
import { KwImageComponent } from './content/widgets/general/kw-image/kw-image.component';
import { KragworksResponsiveTableComponent } from './ui-components/kragworks-responsive-table/kragworks-responsive-table.component';
import { ResponsiveTablePipe } from './ui-components/kragworks-responsive-table.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HarvestEngineModule } from '../harvest-engine/harvest-engine.module';
import { TenantWordsModule } from '../harvest-engine/core/pipes/tenant-words.module';
import { environment } from '../../environments/environment';


@NgModule({
	declarations: [
		FieldTaskPieChartComponent,
		FieldTaskLineChartComponent,
		FieldTaskBarChartComponent,
		FieldTaskCompletedByMonthComponent,
		FieldTaskTableComponent,
		FieldTaskInputComponent,
		BlockSelectComponent,
		UserSelectComponent,
		ConfirmationDialogComponent,
		FieldTaskActiveComponent,
		FieldLifecycleTimelineComponent,
		ResponsiveTablePipe,
		VerticalTimelineComponent,
		BlockMapDisplayComponent,
		AssignedUsersPipe,
		FieldTaskBlockPopoverComponent,
		KwImageComponent,
		KragworksResponsiveTableComponent,
	],
	entryComponents: [
		BlockSelectComponent,
		UserSelectComponent,
		ConfirmationDialogComponent,
	],
	exports: [
		KwImageComponent,
		FieldTaskInputComponent,
		BlockSelectComponent,
		FieldTaskPieChartComponent,
		FieldTaskLineChartComponent,
		FieldTaskBarChartComponent,
		FieldTaskCompletedByMonthComponent,
		FieldTaskTableComponent,
		ChartModule,
		ChartsModule,
		ConfirmationDialogComponent,
		FieldTaskActiveComponent,
		FieldLifecycleTimelineComponent,
		VerticalTimelineComponent,
		BlockMapDisplayComponent,
		FieldTaskBlockPopoverComponent,
		KragworksResponsiveTableComponent,
		NgbModule,
	],
	imports: [
		NgxMapboxGLModule.withConfig({
			accessToken: environment.MAPBOX_KEY,
		  }),
		CloudinaryModule,
		NgSelectModule,
		NbDatepickerModule,
		CommonModule,
		HarvestEngineModule,
		RouterModule,
		TenantWordsModule,
		NgbModule,
		PerfectScrollbarModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatTableModule,
		MatPaginatorModule,
		MatSelectModule,
		LottieAnimationViewModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatIconModule,
		MatTooltipModule,
		ProgressSpinnerModule,
		MatExpansionModule,
		MatInputModule,
		FormsModule,
		MatToolbarModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatDividerModule,
		MatListModule,
		MatExpansionModule,
		NbSpinnerModule,
		MatDialogModule,
		ToastModule,
		ChartModule,
		ChartsModule,
		MatChipsModule,
		MatAutocompleteModule,
		PickListModule,
		NbCardModule,
		GMapModule,
		NbPopoverModule,
		FontAwesomeModule
	],
	providers: [
		TimelineHistoryService,
		CurrencyPipe,
		PercentPipe,
		AsyncPipe,
		DecimalPipe,
		JsonPipe,
		UpperCasePipe,
		I18nPluralPipe,
		KeyValuePipe,
		SlicePipe,
		DatePipe,
		I18nSelectPipe,
		LowerCasePipe,
		TitleCasePipe
	],

})
export class PartialsModule { }
