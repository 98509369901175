import { Component, OnInit, Input, HostListener } from '@angular/core';
import * as _ from "lodash";
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { JsonHubProtocol } from '@aspnet/signalr';

export interface ResponsiveTableHeaderItem {
  objectKey: string;
  title: string;
}

export interface ResponsiveTablePipeItem {
  objectKey: string;
  pipe: string;
}
@Component({
  selector: 'kragworks-responsive-table',
  templateUrl: './kragworks-responsive-table.component.html',
  styleUrls: ['./kragworks-responsive-table.component.scss']
})

export class KragworksResponsiveTableComponent implements OnInit {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  @Input() data: any[] = [];
  @Input() minWidth: number = 600;
  @Input() pipeList: ResponsiveTablePipeItem[] = [];

  public headerItems: ResponsiveTableHeaderItem[] = [];
  public screenHeight: number;
  public screenWidth: number;

  ngOnInit() {
    this.getScreenSize();
    for (let object of this.data) {
      let keys = Object.keys(object);

      for (let key of keys) {
        if (!this.headerItems.find(c => c.objectKey === key)) {
          this.headerItems.push({
            objectKey: key,
            title: _.startCase(key)
          });
        }
      }
    }
  }

  getMobileHeader(index: number) {
    return this.data[index][Object.keys(this.data[index])[0]];
  }

  getPipe(key: string) {
    let pipeObject = this.pipeList.find(p => p.objectKey === key);
    if (pipeObject && pipeObject.pipe ) {
      return pipeObject.pipe;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

  }
}

