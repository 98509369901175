import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse, HttpEvent, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { FieldEventDTO } from '../models/field-event';

@Injectable({
  providedIn: 'root'
})
export class FieldEventsService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

    public deleteFieldEvent(id: number): Observable<any>{ 
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFieldEventsByIdDelete.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.authService.getUserToken()));

        return this.httpClient.delete(`${this.basePath}/api/FieldEvents/${encodeURIComponent(String(id))}`,
            {
                headers: headers,
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param authorization Please insert JWT with Bearer into field
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFieldEventbyId(id: number): Observable<FieldEventDTO> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFieldEventsByIdGet.');
        }

        let headers = this.defaultHeaders;

        headers = headers.set('Authorization', String(this.authService.getUserToken()));

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FieldEventDTO>(`${this.basePath}/api/FieldEvents/${encodeURIComponent(String(id))}`,
            {
                headers: headers
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param authorization Please insert JWT with Bearer into field
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putFieldEvent(id: number, body: FieldEventDTO): Observable<FieldEventDTO> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiFieldEventsByIdPut.');
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.authService.getUserToken()));

        return this.httpClient.put(`${this.basePath}/api/FieldEvents/${encodeURIComponent(String(id))}`, body,
            {
                headers: headers,
            }
        );
    }

    /**
     * 
     * 
     * @param authorization Please insert JWT with Bearer into field
     * @param blockId 
     * @param varietyId 
     * @param farmId 
     * @param cropYear 
     * @param fieldEventTypeId 
     */
    public getFieldEvents(blockId?: number, varietyId?: number, farmId?: number, cropYear?: number, fieldEventTypeId?: number, limit?: number): Observable<FieldEventDTO[]>
    {
        let queryParameters = new HttpParams();
        
        if (blockId !== undefined && blockId !== null) {
            queryParameters = queryParameters.set('blockId', <any>blockId);
        }
        if (varietyId !== undefined && varietyId !== null) {
            queryParameters = queryParameters.set('varietyId', <any>varietyId);
        }
        if (farmId !== undefined && farmId !== null) {
            queryParameters = queryParameters.set('farmId', <any>farmId);
        }
        if (cropYear !== undefined && cropYear !== null) {
            queryParameters = queryParameters.set('cropYear', <any>cropYear);
        }
        if (fieldEventTypeId !== undefined && fieldEventTypeId !== null) {
            queryParameters = queryParameters.set('fieldEventTypeId', <any>fieldEventTypeId);
        }
        
        if(limit) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.authService.getUserToken()));

        if(limit) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        return this.httpClient.get<FieldEventDTO[]>(`${this.basePath}/api/FieldEvents`,
            {
                params: queryParameters,
                headers: headers,
            }
        );
    }

    /**
     * 
     * 
     * @param authorization Please insert JWT with Bearer into field
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFieldEvent(body?: FieldEventDTO): Observable<any>
    {
        let headers = this.defaultHeaders;
        headers = headers.set('Authorization', String(this.authService.getUserToken()));

        return this.httpClient.post<FieldEventDTO>(`${this.basePath}/api/FieldEvents`, body,
            {
                headers: headers
            }
        );
    }

    public postEventNotification(file: File[], fieldEventId): Observable<FormData> {
        let headers = this.defaultHeaders;
    
        headers = headers.set('Authorization', String(this.authService.getUserToken()));
        let formData = new FormData();

        formData.append('fieldEventId', fieldEventId);

        for (const image of file) {
            formData.append('file', image);
        }

        const httpHeaderAcceptSelected: string | undefined = 'application/json';
        if (httpHeaderAcceptSelected !== undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/FieldEvents/Notification`, formData,
        {
            headers,
        });
    }
    
}
