import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QcSample, QcMaster, QcArea, QcType } from '../models/qc-master';

@Injectable({
  providedIn: 'root'
})
export class QcSessionService {

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
  ) { 
  }

  // Add Edit Items
  public currentQcMaster: QcMaster;
  public currentSampleArea: QcArea;
  public currentType: number;

  public selectedRoomId: number;
  public selectedCropYearId: number;


}
