import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../@core/utils/error.service';
import { TenantWords } from '../models/tenantWords';
import { TenantWordService } from '../services/TenantWord.service';

@Pipe({
  name: 'tenantWords'
})
export class TenantWordsPipe implements PipeTransform {

  public tenantWord: TenantWords = JSON.parse(localStorage.getItem('tenantWordDictionary'));
  public tenantWordList: TenantWords[];
  public word;

  constructor(private wordService: TenantWordService, private errorService: ErrorService,) { }

  transform(value: any, args?: any): any {
    return this.GetWords(value);
  }

  GetWords(word: any): any {
    // this.wordService.getTenantWords().subscribe(results => { 
    //   //this.tenantWord = results.find(item => item.isActive === true);
    
    // },
    // error => { this.errorService.handleError(error); });
    

    this.tenantWordList = JSON.parse(localStorage.getItem('tenantWordDictionary'));
    
    if(this.tenantWordList != null && this.tenantWordList != undefined){
      this.tenantWord = this.tenantWordList.find(item => item.originalWord == word && item.isActive === true);
    }
    
    if(this.tenantWord != null && this.tenantWord != undefined){
      //localStorage.setItem('tenantWord', JSON.stringify(this.tenantWord));
      return this.tenantWord.replacementWord
    }
     return word; 
  }
}
