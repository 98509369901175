import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef, MatBottomSheet, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faTimes, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { InventoryPackageTypeDTO } from '../../../../modules/inventory/models/inventory-package-type';
import { InventoryAddItemPopupComponent } from '../../../../modules/inventory/pages/inventory-items/inventory-add-item-popup/inventory-add-item-popup.component';
import { InventoryHumEditComponent } from '../../../../modules/inventory/pages/inventory-summary/inventory-hum-edit/inventory-hum-edit.component';
import { LoadViewModel } from '../../../../modules/pick-plan/models/load-view-model';
import { InventoryService } from '../../../../modules/pick-plan/services/inventory.service';
import { LoadService } from '../../../../modules/pick-plan/services/load.service';
import { BlockDTO } from '../../../models/block';
import { CropVariety } from '../../../models/crop-variety';
import { CropYear } from '../../../models/cropYear';
import { BlockService } from '../../../services/block.service';
import { CropYearService } from '../../../services/crop-year.service';
import { VarietyService } from '../../../services/variety.service';

export interface InventoryPackageTypePopupData {
  packageType: InventoryPackageTypeDTO;
  isPopup: boolean;
  isReturnBottomsheet?: boolean;
}

@Component({
  selector: 'inventory-package-type-edit',
  templateUrl: './inventory-package-type-edit.component.html',
  styleUrls: ['./inventory-package-type-edit.component.scss']
})
export class InventoryPackageTypeEditComponent implements OnInit {
  @ViewChild('packageTypeInputForm') packageTypeInputForm: NgForm;

  constructor( @Inject(MAT_BOTTOM_SHEET_DATA) public data: InventoryPackageTypePopupData,
    private activatedRoute: ActivatedRoute,
    private inventoryService: InventoryService,
    private router: Router,
    private bottomSheet: MatBottomSheetRef<InventoryPackageTypeEditComponent>,
    private bottomSheetReturn: MatBottomSheet,
    private changeDetRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private authService: AuthService,
    private varietyService: VarietyService,
    private errorService: ErrorService
    ) {
    if (data.packageType){
      this.packageType = data.packageType;
      if (Object.keys(this.packageType).length === 0) {
        this.packageType = {
          isActive: true
        };
      }
    }
    else{
      this.packageType = {
        isActive: true
      };
    }
      
  }

  // Icons  //
  public faArrowAltCircleLeft = faArrowAltCircleLeft;
  public faTimes = faTimes;
  public faPlus = faPlusCircle;

  public packageType: InventoryPackageTypeDTO = {};
  public years: CropYear[];
  public blocks: BlockDTO[];
  public loads: LoadViewModel[];
  public varieties: CropVariety[];
  public isLoading: boolean = true;
  public packageTypes: InventoryPackageTypeDTO[] = [];

  ngOnInit() {
    if (!this.packageType.id)
      this.activatedRoute.queryParams.subscribe(params => {
        if (params["packageTypeId"]) {
          this.inventoryService.getInventoryPackageType(params["packageTypeId"]).subscribe(x => {
            this.packageType = x;
            //this.isLoading = false;
          }, err => {
            this.errorService.handleError(err);
            //this.isLoading = false;
          });
        }
        // this.customFields();
        this.isLoading = false
      })
    this.isLoading = false;
  }

  submit() {
    this.isLoading = true;
    if (this.packageType.id) {
      this.inventoryService.putInventoryPackageType(this.packageType).subscribe(x => {
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
    else {
      this.inventoryService.postInventoryPackageType(this.packageType).subscribe(x => {
        this.packageType.id = x.id;
        this.cancel();
      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    }
  }

  cancel() {
    this.bottomSheet.dismiss();
  }

  deletePackageType() {
    this.isLoading = true;
    this.inventoryService.deleteInventoryPackageType(this.packageType.id).subscribe(x => {
      this.cancel();
    }, err => {
      this.errorService.handleError(err);
      this.isLoading = false;
    });
  }

  // customFields() {
  //   if (!this.packageType.attributes)
  //     this.packageType.attributes = [];

  //   this.packageTypeAttrabutes.forEach(f => {

  //     var tempField = this.packageType.attributes.find(x => x.id == f.id);
  //     if (tempField) {
  //       tempField.customAttributeDescription = f.description;
  //       tempField.customAttributeName = f.name;
  //       tempField.id = this.packageType.id;
  //     }
  //     else {
  //       let fieldValue: InventoryPackageTypeAttributeValue = {
  //         customAttributeDatatype: f.datatype,
  //         customAttributeDescription: f.description,
  //         customAttributeName: f.name,
  //         id: 0,
  //         customAttributeId: f.id,
  //         isActive: true
  //       };
  //       this.packageType.attributes.push(fieldValue);
  //     }
  //   });
  //   this.changeDetRef.markForCheck();
  // }

  // getOptionsLength(attrabute) {
  //   var t = this.packageTypeAttrabutes.find(x => x.id == attrabute.customAttributeId);
  //   if (t && t.options)
  //     return t.options.length;
  //   else
  //     return 0;
  // }

  // ClearValue(field) {
  //   var currentField = this.packageType.attributes.find(x => x.id == field.id);
  //   currentField.value = null;
  // }

  // getFieldName(field) {
  //   var currentField = this.packageTypeAttrabutes.find(x => x.id == field.customAttributeId);
  //   return currentField.name
  // }

  // getFieldOptions(field) {
  //   var currentField = this.packageTypeAttrabutes.find(x => x.id == field.customAttributeId);
  //   return currentField.options
  // }
}
