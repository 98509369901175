import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantWordsPipe } from './tenant-words.pipe';

@NgModule({
  declarations: [TenantWordsPipe],
  imports: [
    CommonModule,
  ],
  exports:[
    TenantWordsPipe
  ],
  providers: [TenantWordsPipe]
})
export class TenantWordsModule { }
