import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Dispatch } from 'd3';
import { PlatformConfigService } from '../../../services/platform-config.service';

@Component({
  selector: 'dispatch-edit',
  templateUrl: './dispatch-edit.component.html',
  styleUrls: ['./dispatch-edit.component.scss']
})
export class DispatchEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<DispatchEditComponent>,
    private platformConfigService: PlatformConfigService) { }

  public phoneNumber: string = "";
  public isLoading: boolean = false;

  ngOnInit() {
    if(this.data){
      this.phoneNumber = this.data.phone;
    }
  }

  save(){
    this.platformConfigService.putDispatchPhone(this.phoneNumber).subscribe(phone => {
      this.dialog.close(this.phoneNumber);
    });
  }

}
