import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { ColumnModel, FilterSettingsModel, Grid } from '@syncfusion/ej2-angular-grids';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { FilterResults } from '../../../../core/widgets/kw-filter-dropdown/models/filter-results';
import { FilterSettings } from '../../../../core/widgets/kw-filter-dropdown/models/filter-options';

@Component({
  selector: 'packout-report',
  templateUrl: './packout-report.component.html',
  styleUrls: ['./packout-report.component.scss']
})
export class PackoutReportComponent implements OnInit {
  @ViewChild('grid') public grid: Grid;
  @ViewChild('detailTemplate') detailTemplate: any;

  public isLoading = false;
  public columns: ColumnModel[] = [];
  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }
  public dataSource: any = [];
  private filterResults: FilterResults = {};
  public kwFilterSettings: FilterSettings = {
    cropYearSettings: {
      display: true,
      singleSelection: true
    },
  };

  constructor(
    private errorService: ErrorService,
    private toastService: NbToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private inventoryService: InventoryService,
  ) { }

  ngOnInit() {

    this.columns = [
      { field: 'packoutCode', headerText: 'Packout Date', width: 150, format: 'yyyy-MM-dd' },
      { field: 'packoutTime', headerText: 'Packout Time', width: 150, format: 'HH:mm' },
      { field: 'packoutType', headerText: 'Packout Type', width: 150 },
      { field: 'packoutWeight', headerText: 'Packout Weight', width: 150 },
      { field: 'packoutCount', headerText: 'Packout Count', width: 150 },
      { field: 'packoutLocation', headerText: 'Packout Location', width: 150 },
      { field: 'packoutUser', headerText: 'Packout User', width: 150 },
    ];

    this.loadPackoutReport();
  }

  loadPackoutReport() {
    this.isLoading = true;
    // if this.filterResults.selectedCropYears[0].itemName is null, use current year
    var cropYear = new Date().getFullYear().toString();
    if (this.filterResults.selectedCropYears != null && this.filterResults.selectedCropYears[0].id != null) {
      cropYear = this.filterResults.selectedCropYears[0].itemName;
    }

    this.inventoryService.getPackoutReport(cropYear).subscribe(
      (response: any) => {
        this.dataSource = [];
        this.dataSource = response;
        this.isLoading = false;
        this.grid.refresh();
        this.changeDetectorRef.detectChanges();
      },
      (error) => {
        this.isLoading = false;
        this.errorService.handleError(error);
      }
    );
  }

  search(newFilters: FilterResults) {
    if (this.filterResults.selectedCropYears != null && newFilters.selectedCropYears != null && newFilters.selectedCropYears[0].id != this.filterResults.selectedCropYears[0].id) {
      this.isLoading = true;
      this.filterResults = { ...newFilters };
      this.loadPackoutReport();
    }
    else {
      this.filterResults = { ...newFilters };
    }
  }


}


export interface ReportDto {
  packoutCode: string;
  totalQuantity: number;
  lots: string;
  date: Date;
  lotMassBalances: any[];
}
