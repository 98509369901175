import { Component, OnInit } from '@angular/core';

import * as isBefore from 'date-fns/is_before';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { FieldTasksService } from '../../../../../harvest-engine/modules/field-events/services/field-tasks.service';

@Component({
  selector: 'm-field-task-pie-chart',
  templateUrl: './field-task-pie-chart.component.html',
  styleUrls: ['./field-task-pie-chart.component.scss']
})
export class FieldTaskPieChartComponent implements OnInit {

  public noTasks: boolean = false;

  loading : boolean = true;
  public doughnutChartLabels: string[] = ['Overdue', 'Active', 'Complete'];
  public doughnutChartData: number[] = [];
  public doughnutChartType: string = 'doughnut';
  public doughnutOptions: any = {
      legend: {
        display: true,
        position: 'bottom',
      }
    };
    public chartColors = [{ backgroundColor: ['#BD1E1E', '#0B4F6C', '#41C918'] }];
  constructor(
    private fieldTaskService: FieldTasksService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.fieldTaskService.apiFieldTasksGet(null, true).subscribe(fts => {
      
      if(!fts) {
        this.noTasks = true;
      } else {
        this.noTasks = false;
      }

      let overdueCount: number = 0;
      let activeCount: number = 0;
      let completeCount: number = 0;

      for (const task of fts) {
          if (task.isUserCompleted) {
            completeCount++;
          } else if (isBefore(task.dueDate, new Date())) {
            overdueCount++;
          } else {
            activeCount++;
          }

          this.doughnutChartData = [overdueCount, activeCount, completeCount];
      }
      this.loading = false;
    });

  }

  // Doughnut


  // events
  public chartClicked(e: any): void {

  }

  public chartHovered(e: any): void {

  }

}
