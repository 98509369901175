import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { faTimes, faReceipt, faPlusSquare, faSave, faTrashAlt, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { PrecisionJobService } from '../../services/precision-job.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../../../@core/utils/session.service';
import { NbToastrService } from '@nebular/theme';
import { PrecisionCustomer } from '../../models/precision-customer';
import { PrecisionPurchaseOrder } from '../../models/precision-purchase-order';
import { PrecisionVendor } from '../../models/precision-vendor';

import { ErrorService } from '../../../../@core/utils/error.service';
import { PrecisionPurchaseOrderService } from '../../services/precision-purchase-order.service';
import { PrecisionJobNumber } from '../../models/precision-job-number';
import { PrecisionStatus } from '../../models/precision-status';
import { PrecisionJob } from '../../models/precision-job';
import { MatBottomSheet } from '@angular/material';
import { ReceiveLineItemsComponent } from '../receive-line-items/receive-line-items.component';
import { PrecisionJobDetail } from '../../models/precision-job-detail';
import { PrecisionVendorService } from '../../services/precision-vendor-service';
import { PrecisionSessionService } from '../../services/precision-session.service';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import { PrecisionPurchaseOrderReceipt } from '../../models/precision-purchase-order-receipt';
import { PrecisionReceiptItem } from '../../models/precision-receipt-item';
import { PrecisionPurchaseOrderItem } from '../../models/precision-purchase-order-item';
import { AuthService } from '../../../../@core/auth/auth.service';
import { faCalendarPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { JobDetailBottomSheetData, JobDetailEditBottomsheetComponent } from '../job-details/job-detail-edit-bottomsheet/job-detail-edit-bottomsheet.component';
import { Subscription } from 'rxjs';
import { trim } from 'lodash';



@Component({
  selector: 'quick-po',
  templateUrl: './quick-po.component.html',
  styleUrls: ['./quick-po.component.scss']
})
export class QuickPoComponent implements OnInit, OnDestroy {

  @ViewChild('receiveLineItemForm') receiveLineItemForm: NgForm;
  @ViewChild('newPoForm') newPoForm: NgForm;
  @Input() isDashboard: boolean;
  faTimes = faTimes;
  faReceipt = faReceipt;
  faTrash = faTrash;
  faPlusSquare = faPlusSquare;
  faCalendarPlus = faCalendarPlus;
  faSave = faSave;
  faTrashAlt = faTrashAlt;
  faShareSquare = faShareSquare;

  pushToQb: boolean = false;

  public jobList: PrecisionJobNumber[] = [];
  public allJobs: PrecisionJobNumber[] = [];
  public selectedJobNumber: string;
  public searchJobNumber: string;
  public vendorList: PrecisionVendor[] = [];
  public selectedVendor: PrecisionVendor;
  public referenceNumberList: string[] = [];
  public selectedReferenceNumber: string;
  public purchaseOrder: PrecisionPurchaseOrder = {};
  public formControl = new FormControl();
  recordId: number;
  public projectedJobNumber: string;
  public isLoading: boolean = false;
  public purchaseOrderNumber: string;
  public poStatusList: PrecisionStatus[] = [];
  public poReceipt: PrecisionPurchaseOrderReceipt;
  public isNew: boolean = false;
  job: PrecisionJob = {};
  public isReceiveItem: boolean = false;
  currDate = new Date();
  public isReceivingLoading: boolean = false;
  private selectedIndex: number;
  public saveComplete: boolean = false;
  public notesId: number = 21;
  public receivedQty: number;
  isSaving: boolean = false;
  private bottomSheetSubscription: Subscription;
  public sendEmail: boolean = false;
  private isNewJobDetail: boolean = false;
  public categories: string[] = [];
  public items: string[] = [];
  private selectedJobIndex: number;
  public statuses: PrecisionStatus[] = [];
  public allJobDetails: PrecisionJobDetail[] = [];
  public quickbooksItems: string[] = [];
  public isEdit: boolean = false;

  constructor(
    private precisionJobService: PrecisionJobService,
    private purchaseOrderService: PrecisionPurchaseOrderService,
    private errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private session: PrecisionSessionService,
    private toastr: NbToastrService,
    private changeDetRef: ChangeDetectorRef,
    private precisionPurchaseOrderService: PrecisionPurchaseOrderService,
    private bottomSheet: MatBottomSheet,
    private vendorService: PrecisionVendorService,
    private authService: AuthService
  ) { }

  ngOnDestroy(): void {
    this.session.job = undefined;
    this.session.precisionPurchaseOrder = undefined;
  }

  ngOnInit() {
    this.isLoading = true;

    if (!this.purchaseOrder.recordId) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }

    this.activatedRoute.queryParams.subscribe(params => {
      if (!params["recordId"] && !this.isDashboard) {
        //this.toastr.warning("We could not find that job", "Job not found");
      }
      this.recordId = params["recordId"];
    })


    this.getPurchaseOrder();
    this.getStatuses();
    this.getItems();

  }

  getTotal() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.quantityOrdered));
  }

  getTotalReceived() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.receivedQuantity));
  }

  getTotalPrice() {
    return lodash.sum(this.purchaseOrder.lineItems.map(x => x.rate * x.quantityOrdered));
  }


  getPurchaseOrder() {
    this.purchaseOrder.dueDate = new Date();

    if (this.session.precisionPurchaseOrder) {

      this.purchaseOrder = this.session.precisionPurchaseOrder;
      this.purchaseOrder.dueDate = new Date();
      this.purchaseOrder.dueDate.setDate(this.purchaseOrder.dueDate.getDate() + 30);
      this.getJobNumbers();

    } else if (this.recordId) {

      this.precisionPurchaseOrderService.getPurchaseOrder(this.recordId).subscribe(po => {
        this.purchaseOrder = po;

        this.session.precisionPurchaseOrder = po;
        this.getJobNumbers();

      }, err => {
        this.errorService.handleError(err);
        this.isLoading = false;
      });
    } else {

      this.getJobNumbers();
    }

    this.purchaseOrder.emailAddress = this.authService.getCurrentUser().email;
  }

  doJobFilter() {
    this.jobList = [...this.allJobs.filter(j => j.number.toLowerCase().includes(this.searchJobNumber.toLowerCase()))]
  }

  submitAndPush() {
    this.pushToQb = true;
    this.newPoForm.ngSubmit.emit();
  }

  submit() {
    this.pushToQb = false;
    this.newPoForm.ngSubmit.emit();
  }

  save() {
    this.isLoading = true;
    this.purchaseOrder.isActive = true;

    if (!this.purchaseOrder.recordId) {

      // override push to QB if there aren't any line items
      if (!this.purchaseOrder.lineItems)
        this.purchaseOrder.lineItems = [];

      if (this.purchaseOrder.lineItems.length == 0) {
        this.pushToQb = false;
      }

      this.purchaseOrder.dateOrdered = new Date();
      this.purchaseOrder.isPushedToQb = false;

      this.purchaseOrderService.postPurchaseOrder(this.pushToQb, this.purchaseOrder, this.sendEmail).subscribe(po => {
        if (po) {

          this.toastr.success('Purchase order created successfully');
          this.purchaseOrder = po;
          this.saveComplete = true;
          this.changeDetRef.markForCheck();
          this.isLoading = false;
        }
      }, err => { this.isLoading = false; this.errorService.handleError(err) })
    } else if (this.purchaseOrder.recordId) {

      this.purchaseOrderService.putPurchaseOrder(this.pushToQb, this.purchaseOrder, this.sendEmail).subscribe(po => {
        this.toastr.success('Purchase order updated successfully');
        this.isLoading = false;
        this.changeDetRef.markForCheck();

      }, err => { this.isLoading = false; this.errorService.handleError(err) })
    }

  }


  getJobNumbers() {
    this.precisionJobService.getJobNumbers().subscribe(x => {
      this.jobList = x;
      this.allJobs = x;
      if (this.session.precisionPurchaseOrder && this.session.precisionPurchaseOrder.job) {

        if (this.session.job) {
          this.selectedJobNumber = this.session.job.jobNumber;
          let selectedJobNumber = this.jobList.filter(x => x.number === this.session.job.jobNumber)[0].number;
          if (selectedJobNumber) {
            this.searchJobNumber = selectedJobNumber;
            this.selectedJobNumber = selectedJobNumber;
          }
          this.isLoading = false;
        }
        this.purchaseOrder.jobId = this.session.precisionPurchaseOrder.job.recordId;
        this.purchaseOrder.job = this.session.precisionPurchaseOrder.job;
        this.isLoading = false;
      }
      this.isLoading = false;
      this.changeDetRef.markForCheck();
    })
  }


  selectVendor() {
    let selectedVendor = this.vendorList.filter(x => x.recordId === this.purchaseOrder.vendorId)[0];

    this.purchaseOrder.vendorName = selectedVendor.name;

    if (selectedVendor.email) {
      this.purchaseOrder.emailAddress = selectedVendor.email

      // Testing Only
      this.purchaseOrder.emailAddress = this.authService.getCurrentUser().email;
    }

  }

  getStatuses() {
    this.precisionJobService.getStatuses().subscribe(statuses => {
      this.poStatusList = statuses;
    }, err => this.errorService.handleError(err));

    this.vendorService.getVendors().subscribe(x => {
      x = _.sortBy(x, 'name');
      this.vendorList = x;
    }, err => this.errorService.handleError(err));

    this.vendorList = _.sortBy(this.vendorList, 'name');
  }

  getVendors(): string[] {
    let vendors = [];
    if (this.job.jobDetails)
      vendors = this.job.jobDetails.filter(d => d && d.vendor).map(d => trim(d.vendor));

    vendors = _.uniq(vendors);
    vendors = _.sortBy(vendors);
    return vendors;
  }

  getPoReceipt() {
    if (!this.poReceipt) {
      this.poReceipt = {
        recordId: 0,
        receiptDate: new Date(),
        receivedBy: '',
        receiptItems: [],
        comments: '',
        receivedAt: '',

        isActive: true,
        createdBy: '',
        createdByUserId: 0,
        status: '',
      };
    }

    this.poReceipt.receiptItems = [];

    for (let lineItem of this.purchaseOrder.lineItems) {
      this.poReceipt.receiptItems.push({
        itemNumber: lineItem.item,

        receivedQuantity: 0,
        notes: lineItem.notes,
      });
    }
  }


  getItems() {
    this.precisionJobService.getItems().subscribe(items => {


      
      this.quickbooksItems = [...items.map(i => i.name)];
      this.quickbooksItems = _.uniq(this.quickbooksItems);
      this.quickbooksItems = _.sortBy(this.quickbooksItems);

      this.quickbooksItems = [...this.quickbooksItems];


    }, err => this.errorService.handleError(err));
  }

  selectReferenceNumber() {
    this.purchaseOrder.referenceNumber = this.selectedReferenceNumber;
  }


  clearDueDate() {
    this.purchaseOrder.dueDate = null;
  }


  clear() {
    this.newPoForm.reset();
  }

  cancelReceiving() {
    this.isReceiveItem = false;
    this.poReceipt.receiptItems = [];
    this.receiveLineItemForm.resetForm()
  }

  toJobDetails() {
    this.router.navigateByUrl(`/precision/job-details?recordId=${this.purchaseOrder.jobId}`);
  }

  openReceiveInputs() {
    this.getPoReceipt();
    this.isReceiveItem = true;
  }

  savePoReceipt() {
    for (let line of this.purchaseOrder.lineItems) {
      for (let receipt of this.poReceipt.receiptItems) {
        if (line.item === receipt.itemNumber) {
          line.receivedQuantity = line.receivedQuantity + receipt.receivedQuantity;
        }
      }
    }

    this.isReceivingLoading = true;
    if (this.purchaseOrder.receipts === null || this.purchaseOrder.receipts === undefined) {
      this.purchaseOrder.receipts = [];
    }
    this.purchaseOrder.receipts.push(this.poReceipt);
    this.purchaseOrderService.putPurchaseOrder(false, this.purchaseOrder, this.sendEmail).subscribe(po => {
      this.toastr.success('Items', 'Successfully received');
      this.receiveLineItemForm.resetForm()
      this.isReceiveItem = false;
      this.isReceivingLoading = false;
    }, err => {
      this.errorService.handleError(err);
      this.isReceivingLoading = false;
    });
  }

  getNotesID() {
    let id = _.random(this.notesId);
    return id;
  }

  setInput() {
    if (this.session.isPoReceive) {
      this.isReceiveItem = true;
    }
  }

  deletePO() {
    this.isSaving = true;

    if (!this.purchaseOrder.isPushedToQb) {
      this.purchaseOrder.isPushedToQb = false;
      this.purchaseOrderService.deleteRfq(this.purchaseOrder.recordId).subscribe(x => {
        this.toastr.success('PO deleted successfully', 'Success');
        this.purchaseOrder = {};
        this.session.precisionPurchaseOrder = {};
        this.isSaving = false;
      }, err => {
        this.errorService.handleError(err);
        this.isSaving = false;
      });
    } else {
      this.toastr.warning('PO has been pushed to QuickBook', 'Cannot delete')
      this.isSaving = false;
    }
  }


  openJobDetailBottomsheet(jobDetail: PrecisionJobDetail) {

    // if the job detail is null, then it's a new job
    this.isNewJobDetail = !jobDetail ? true : false;

    // get the index of the job in the job detail list

    if (this.job.jobDetails)
      this.selectedJobIndex = this.job.jobDetails.indexOf(jobDetail);
    else
      this.selectedJobIndex = 0;

    let data: JobDetailBottomSheetData = {
      // pass the properties of the detail.  This way it's not overwriting the one on the screen if they press cancel
      jobDetail: { ...jobDetail },
      categories: this.categories.filter(x => x !== '(ALL)'),
      items: this.items.filter(x => x !== '(ALL)'),
      manufacturers: this.getManufacturers(),
      statuses: this.statuses.map(s => s.name),
      vendors: this.getVendors(),
      quickbooksItems: this.quickbooksItems


    }

    // open the bottom sheet
    this.bottomSheet.open(JobDetailEditBottomsheetComponent, { data, disableClose: false },);

    // subscribe to the bottom sheet so we can get the detail once it has been saved.  Then unsubscribe from the bottom sheet.
    this.bottomSheetSubscription = this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe((jobDetail: PrecisionJobDetail) => {
      
      // Map jobDetail to PrecisionPurchaseOrderItem -> map does not exist for jobDetail
      let item: PrecisionPurchaseOrderItem = {
        description: jobDetail.description,
        item: jobDetail.item,
        notes: jobDetail.notes,
        partNumber: jobDetail.partNumber,
        quantityOrdered: jobDetail.quantity,
        quickbooksItem: jobDetail.quickbooksItem,
        rate: jobDetail.price,
        receivedQuantity: 0
      };

      // Push item into purchase order line items and put PO
      this.purchaseOrder.lineItems.push(item);
      this.purchaseOrderService.putPurchaseOrder(false, this.purchaseOrder, false).subscribe(x => {
        this.toastr.success('PO Line Item Added', 'Success');
      })

      // Get job by record id and push job detail and put job
      this.precisionJobService.getJob(this.purchaseOrder.jobId).subscribe(job => {
        job.jobDetails.push(jobDetail);
        job.jobDetails = job.jobDetails.filter(x => x !== undefined);


        if (jobDetail !== null) {
          if (jobDetail.status === "") {
            jobDetail.status = 'Incomplete';
          }
          this.precisionJobService.putJob(job).subscribe(() => { }, err => this.errorService.handleError(err));
          this.allJobDetails = this.job.jobDetails;
        }
      })
      this.bottomSheetSubscription.unsubscribe();
    });
  }

  getManufacturers(): string[] {
    let manufacturers = [];
    if (this.job.jobDetails && this.job.jobDetails.find(jd => jd && jd.manufacturer != ''))
      manufacturers = this.job.jobDetails.filter(d => d && d.manufacturer).map(d => d.manufacturer);

    manufacturers = _.uniq(manufacturers);
    manufacturers = _.sortBy(manufacturers);
    return manufacturers;
  }

  edit(lineItem: PrecisionPurchaseOrderItem) {
    if(lineItem.isEdit) {
      lineItem.isEdit = false;
    } else {
      lineItem.isEdit = true;
    }
  }

  clearShipToName() {
    this.purchaseOrder.shipToName = '';
  }

}



