import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../../@core/auth/auth.service';
import { environment } from '../../../../../environments/environment';

import { Observable, of } from 'rxjs';
import { PickPlanDTO } from '../models/pick-plan-dto';
import { RandomNumberGenerationSource } from 'd3';
import { random } from 'lodash';
import { PickPlanCustomAttribute } from '../models/pick-plan-custom-attribute';
import { PickDTO } from '../models/pick-dto';
import { StorageTypeDTO } from '../models/storage-types-dto';
import { PoolTypeDTO } from '../models/pool-type-dto';



@Injectable({
  providedIn: 'root'
})
export class PickPlanService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public getPickPlans(includeInactive: boolean = false, startDate: Date, endDate: Date, cropYearId: number, blockId: number = null) {

    // Test Data
    // return of(this.getTestPickPlans());

    let queryParameters = new HttpParams();

    if (startDate) {
      queryParameters = queryParameters.append('startDate', startDate.toISOString());
    }

    if(endDate) {
      queryParameters = queryParameters.append('endDate', endDate.toISOString());  
    }
    
    if(blockId) {
      queryParameters = queryParameters.append('blockId', blockId.toString());
    }

    queryParameters = queryParameters.append('cropYearId', cropYearId.toString());

    if (includeInactive !== undefined && includeInactive !== null) {
      queryParameters = queryParameters.set('includeInactive', <any>includeInactive);
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<PickPlanDTO[]>(`${this.basePath}/api/pickplans`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getPickPlan(pickPlanId: number): Observable<PickPlanDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PickPlanDTO>(`${this.basePath}/api/pickplans/${pickPlanId}`,
      {
        headers,
      }
    );
  }

  public postPickPlan(pickPlan: PickPlanDTO): Observable<PickPlanDTO> {
    
    //return of(pickPlan);
    // return of(pickPlan);

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<PickPlanDTO>(`${this.basePath}/api/pickplans`, pickPlan,

      {
        headers,
      }
    );
  }

  public putPickPlan(pickPlan: PickPlanDTO): Observable<PickPlanDTO> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<PickPlanDTO>(`${this.basePath}/api/pickplans/${pickPlan.id}`, pickPlan,

      {
        headers,
      }
    );
  }

  public deletePickPlan(id: number): Observable<any> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/pickplans/${id}`,
      {
        headers,
      }
    );
  }

  public findPickPlan(blockId: number, storageId: number, roomId: number, warehouseId: number, poolTypeId: number, cropYearId: number): Observable<PickPlanDTO> {
    let queryParameters = new HttpParams();

    if (blockId)
      queryParameters = queryParameters.append('blockId', blockId.toString());
    if (storageId)
      queryParameters = queryParameters.append('storageId', storageId.toString());
    if (roomId)
      queryParameters = queryParameters.append('roomId', roomId.toString());
    if (warehouseId)
      queryParameters = queryParameters.append('warehouseId', warehouseId.toString());
    if (poolTypeId)
      queryParameters = queryParameters.append('poolTypeId', poolTypeId.toString());
    if (cropYearId)
      queryParameters = queryParameters.append('cropYearId', cropYearId.toString());


    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<PickPlanDTO>(`${this.basePath}/api/pickplans/find`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }

  public getCustomAttributes(): Observable<PickPlanCustomAttribute[]> {

    // Test Data
    // return of(this.getTestPickPlans());

    let queryParameters = new HttpParams();

    let headers = this.defaultHeaders;
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    return this.httpClient.get<PickPlanCustomAttribute[]>(`${this.basePath}/api/pickplancustomattributes`,
      {
        params: queryParameters,
        headers: headers,
      }
    );
  }



}
