import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { JobListComponent } from "./pages/job-list/job-list.component";
import { JobDetailsComponent } from "./pages/job-details/job-details.component";
import { PurchaseOrderComponent } from "./pages/purchase-order/purchase-order.component";
import { NewJobComponent } from "./pages/new-job/new-job.component";
import { QuickPoComponent } from "./pages/quick-po/quick-po.component";
import { RfqComponent } from "./pages/rfq/rfq.component";
import { PurchaseOrderTableComponent } from "./pages/purchase-order-table/purchase-order-table.component";


const routes: Routes = [
    {
        path: 'job-list',
        component: JobListComponent
    },
    {
        path: 'job-details',
        component: JobDetailsComponent
    },
    {
        path: 'purchase-order',
        component: PurchaseOrderComponent
    },
    {
        path: 'purchase-orders',
        component: PurchaseOrderTableComponent
    },
    {
        path: 'rfqs',
        component: PurchaseOrderTableComponent
    },
    {
        path: 'new-job',
        component: NewJobComponent
    },
    {
        path: 'quick-po',
        component: QuickPoComponent
    },
    {
        path: 'rfq',
        component: RfqComponent
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PrecisionRoutingModule {
}