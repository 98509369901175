import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { faCheck, faPlusCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Observable } from 'rxjs';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { Room } from '../../../models/storage/room';
import { RoomCustomAttrabute } from '../../../models/storage/room-custom-attrabute';
import { RoomDetail } from '../../../models/storage/room-detail';
import { RoomStatus } from '../../../models/storage/roomStatus';
import { Warehouse } from '../../../models/storage/warehouse';
import { RoomService } from '../../../services/storage/room-service';
import { WarehouseService } from '../../../services/storage/warehouse-service';
import { PlatformConfigService } from '../../../services/platform-config.service';
import { ConfirmationDialogComponent } from '../../../../../partials/content/widgets/general/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'room-edit-popup',
  templateUrl: './room-edit-popup.component.html',
  styleUrls: ['./room-edit-popup.component.scss']
})
export class RoomEditPopupComponent implements OnInit {
  public faPlusCircle = faPlusCircle;

  public editRoom: Room = {};
  public roomId: number;
  public roomLoading = true;
  public submitting: any;
  public warehouses: Warehouse[] = [];
  public filteredDetails: RoomCustomAttrabute[];
  public newRoomDetail: RoomDetail[] = [];
  public statuses: RoomStatus[] = [];

  public treatmentInterval: number = 0;
  // public editWarehouse: Warehouse;

  public faTimes = faTimes;
  public faCheck = faCheck;

  constructor(
    private errorService: ErrorService,
    private roomService: RoomService,
    public service: RoomService,
    private _bottomSheetRef: MatBottomSheetRef<RoomEditPopupComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private changeDetRef: ChangeDetectorRef,
    private platformConfigService: PlatformConfigService,
    public warehouseService: WarehouseService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private dialog: MatDialog,

  ) {
    // if (data.room)
    //   this.editRoom = data.room;
  }

  ngOnInit() {
    this.roomLoading = true;
    // NAVIGATING FROM STORAGE BY ROOM REPORT //
    if (this.data.room.roomId) {
      this.roomService.getRoom(this.data.room.roomId).subscribe(room => {
        this.editRoom = room;
        Observable.forkJoin([
          this.warehouseService.getWarehouses(),
          this.service.getCustomAttributes(),
          this.service.getRoomStatuses(),
          this.platformConfigService.getRoomTreatmentInterval()
        ]).subscribe(data => {
          this.warehouses = data[0];
          this.filteredDetails = data[1];

          this.treatmentInterval = data[3];
          this.newRoomDetail = data[1].map(attr => {
            return <RoomDetail>{
              id: 0,
              value: "",
              isActive: true,
              roomId: this.editRoom.id,
              roomCustomAttributeId: attr.id,
              roomCustomAttribute: attr
            }
          });
    
          this.statuses = data[2];
    
          this.newRoomDetail.forEach(x => {
            let existing = this.editRoom.details.find(x => x.roomCustomAttributeId == x.roomCustomAttributeId);
            if (!existing)
              this.editRoom.details.push(x);
          });
    
          this.changeDetRef.markForCheck();
          this.roomLoading = false;
        }, err => {
          this.roomLoading = false;
          this.errorService.handleError(err);
        });
    
      }, err => {
        this.roomLoading = false;
        this.errorService.handleError(err);
      });
  
      // NAVIGATING FROM ROOMS LIST REPORT //
    } else if(this.data.room.id) {

      this.editRoom = this.data.room;
      Observable.forkJoin([
        this.warehouseService.getWarehouses(),
        this.service.getCustomAttributes(),
        this.service.getRoomStatuses(),
      ]).subscribe(data => {
        this.warehouses = data[0];
        this.filteredDetails = data[1];
        this.newRoomDetail = data[1].map(attr => {
          return <RoomDetail>{
            id: 0,
            value: "",
            isActive: true,
            roomId: this.editRoom.id,
            roomCustomAttributeId: attr.id,
            roomCustomAttribute: attr
          }
        });
  
        this.statuses = data[2];
  
        this.newRoomDetail.forEach(x => {
          let existing = this.editRoom.details.find(x => x.roomCustomAttributeId == x.roomCustomAttributeId);
          if (!existing)
            this.editRoom.details.push(x);
        });
  
        this.changeDetRef.markForCheck();
        this.roomLoading = false;
      }, err => {
        this.roomLoading = false;
        this.errorService.handleError(err);
      });
  
    } else {
      this.editRoom = this.data.room;
      Observable.forkJoin([
        this.warehouseService.getWarehouses(),
        this.service.getCustomAttributes(),
        this.service.getRoomStatuses(),
      ]).subscribe(data => {
        this.warehouses = data[0];
        this.filteredDetails = data[1];
        this.newRoomDetail = data[1].map(attr => {
          return <RoomDetail>{
            id: 0,
            value: "",
            isActive: true,
            roomId: 0,
            roomCustomAttributeId: attr.id,
            roomCustomAttribute: attr
          }
        });
  
        this.statuses = data[2];
  
        this.newRoomDetail.forEach(x => {
          let existing = this.editRoom.details.find(x => x.roomCustomAttributeId == x.roomCustomAttributeId);
          if (!existing)
            this.editRoom.details.push(x);
        });
  
        this.changeDetRef.markForCheck();
        this.roomLoading = false;
      }, err => {
        this.roomLoading = false;
        this.errorService.handleError(err);
      });
    }

  }

  public setNextTreatmentDate() {
    if (this.editRoom.lastTreatmentDate) {
      this.editRoom.nextTreatmentDate = new Date(this.editRoom.lastTreatmentDate);
      this.editRoom.nextTreatmentDate.setDate(this.editRoom.nextTreatmentDate.getDate() + this.treatmentInterval);
    }
  }


  deleteRoomClicked(form: NgForm) {
    const confRef = this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '10%',
      data: {
        confirmLabel: 'Yes',
        title: 'Delete Room?',
        confirmMessage: 'Are you sure you want to delete this room?',
        cancelLabel: 'No'
      }
    }).afterClosed().subscribe(result => {
      if (result === true) {
        this.roomLoading = true;
        if (this.editRoom.id !== null && this.editRoom.id !== undefined) {
          this.service.deleteRoom(this.editRoom.id).subscribe(x => {
            this.editRoom = {};
            this.roomLoading = false;
            form.resetForm();
            this._bottomSheetRef.dismiss();
          }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
        }
        else {
          alert("No Room Selected!!");
          this.roomLoading = false;
          form.resetForm();
        }
      }
    });
   
  }

  isAttrRequired(detail: RoomDetail) {
    if (detail.roomCustomAttribute)
      return detail.roomCustomAttribute.isRequired;
    else return false;
  }

  attrDatatype(detail: RoomDetail) {
    if (detail.roomCustomAttribute)
      return detail.roomCustomAttribute.datatype;
    else return "";
  }

  saveRoomClicked(form: NgForm) {
    this.roomLoading = true;
    if (this.editRoom.isActive === null || this.editRoom.isActive === undefined) { this.editRoom.isActive = false; };
    if (this.editRoom.id !== null && this.editRoom.id !== undefined) {
      this.editRoom.updatedDate = new Date;
      this.service.putRoom(this.editRoom).subscribe(x => {
        this.editRoom = {};
        this.roomLoading = false;
        form.resetForm();
        this._bottomSheetRef.dismiss();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
    else {
      this.editRoom.id = 0;
      this.editRoom.createdDate = new Date();
      this.editRoom.updatedDate = this.editRoom.createdDate;
      this.editRoom.locations = [];
      this.service.postRoom(this.editRoom).subscribe(x => {
        // this.editRoom.warehouse = this.editWarehouse;
        this.editRoom = {};
        this.roomLoading = false;
        form.resetForm();
        this._bottomSheetRef.dismiss();
      }, err => { this.errorService.handleError(err); this.roomLoading = false; form.resetForm(); });
    }
  }

  cancelClicked(form: NgForm) {
    this.editRoom = {};
    this._bottomSheetRef.dismiss();
  }

  warehouseChanged(id: number) {
    let editWarehouse = this.warehouses.find(x => x.id === id);
    this.editRoom.warehouse = editWarehouse;
    var t = 3;
  }
  clearDetailValue(typeId: number) {
    this.editRoom.details.find(x => x.roomCustomAttribute.id === typeId).value = "";
  }

  getCustomAttributes() {
    this.service.getCustomAttributes().subscribe(attrs => {
      this.filteredDetails = attrs;
      this.newRoomDetail = attrs.map(attr => {
        return <RoomDetail>{
          id: 0,
          value: "",
          isActive: true,
          roomId: this.editRoom.id,
          roomCustomAttributeId: attr.id,
          roomCustomAttribute: attr
        }
      });

      this.editRoom.details = [...this.newRoomDetail];
    });
  }

  getOptionsLength(detail: RoomDetail) {
    if (this.filteredDetails && this.filteredDetails.length > 0) {
      var d = this.filteredDetails.find(x => x.id == detail.roomCustomAttributeId);
      if (d && d.options)
        return d.options.length;
      else
        return 0;
    }
    else
      return 0;

  }

  getDetailName(detail: RoomDetail) {
    var currentField = this.filteredDetails.find(x => x.id == detail.roomCustomAttributeId);
    return currentField.name
  }

  getDetailOptions(detail: RoomDetail) {
    var currentField = this.filteredDetails.find(x => x.id == detail.roomCustomAttributeId);
    return currentField.options
  }

}
