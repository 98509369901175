import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/auth/auth.service';
import { Employee } from '../../models/employee';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
    private authService: AuthService,
  ) {
    this.basePath = environment.PLATFORM_API_URL; 
    this.defaultHeaders = this.defaultHeaders.set('Authorization', this.authService.getUserToken());

  }

  public getEmployees(): Observable<Employee[]> {


    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();



    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Employee[]>(`${this.basePath}/api/Employees`,
      {
        headers,
      }
    );
  }

  public putEmployee(employee: Employee): Observable<Employee> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();



    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.put<Employee>(`${this.basePath}/api/Employees/${employee.id}`, employee,
      {
        headers,
      }
    );
  }


  public postEmployee(employee: Employee): Observable<Employee> {

    if (environment.isMockEnabled) {
      return Observable.of(employee);
    }

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.post<Employee>(`${this.basePath}/api/Employees`,
      employee,
      {
        headers,
      }
    );
  }

  public deleteEmployee(id: number): Observable<Employee> {

    let headers = this.defaultHeaders;

    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('setInactive', "false");

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<Employee>(`${this.basePath}/api/Employees/${id}`,
      {
        headers: headers,
        params: queryParameters,
      }
    );
  }
}


