import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from '../../../../../@core/auth/auth.service';
import { NgStyle } from '@angular/common';


@Component({
  selector: 'kw-image',
  templateUrl: './kw-image.component.html',
  styleUrls: ['./kw-image.component.scss']
})
export class KwImageComponent implements OnInit, OnChanges {

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    let user = this.authService.getCurrentUser();
    let tenantId = user.tenant.tenantId;


    this.public_id = tenantId + '/' + this.imageId + '.png';
    
    this.createNgStyle();
    
    
  }

  @Input('imageId') imageId : string;

  @Input() imageWidth: number;

  @Input() imageHeight: number;

  public public_id: string;

  public ngStyleArray: any = {};

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    
  }

  createNgStyle(){
    // Set width
    if(this.imageWidth !== null && this.imageWidth !== undefined){
      this.ngStyleArray.width = this.imageWidth + "px";
    }

    // Set Height
    if(this.imageHeight !== null && this.imageHeight !== undefined){
      this.ngStyleArray.height = this.imageHeight + "px";
    }

  }

}
