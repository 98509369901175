import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PrecisionJob } from '../models/precision-job';
import { PrecisionJobDetail } from '../models/precision-job-detail';
import { PrecisionCategory } from '../models/precision-category';
import { HttpResponse, HttpEvent, HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../../../@core/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { PrecisionItem } from '../models/precision-items';
import { PrecisionStatus } from '../models/precision-status';
import { PrecisionPurchaseOrder } from '../models/precision-purchase-order';
import { PrecisionJobNumber } from '../models/precision-job-number';

@Injectable({
  providedIn: 'root'
})
export class PrecisionJobService {
  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  constructor(
    private authService: AuthService,
    protected httpClient: HttpClient, 
  ) {
    this.basePath = environment.PRECISION_API_URL;
   }


  public getJobs(): Observable<PrecisionJob[]> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionJob[]>(`${this.basePath}/api/jobs/`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getJob(recordId: number): Observable<PrecisionJob> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionJob>(`${this.basePath}/api/jobs/${recordId}`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getJobNumber(customerId: string): Observable<string> {
    
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    queryParameters = queryParameters.append("customerId", customerId);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<string>(`${this.basePath}/api/jobs/jobNumber`, 
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getJobNumbers(): Observable<PrecisionJobNumber[]> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionJobNumber[]>(`${this.basePath}/api/jobs/jobNumbers`, 
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public postJob(job: PrecisionJob): Observable<PrecisionJob> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append("pushToQb", 'true');
    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<PrecisionJob>(`${this.basePath}/api/jobs/`, job,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public putJob(job: PrecisionJob): Observable<PrecisionJob> {
    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.put<PrecisionJob>(`${this.basePath}/api/jobs/${job.recordId}`, job,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getCategories(): Observable<PrecisionCategory[]> {

    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionCategory[]>(`${this.basePath}/api/Categories`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getItems(): Observable<PrecisionItem[]> {

    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionCategory[]>(`${this.basePath}/api/Items`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }

  public getStatuses(): Observable<PrecisionStatus[]> {

    let headers = this.defaultHeaders;
  
    headers = headers.set('Authorization', String(this.authService.getUserToken()));

    let queryParameters = new HttpParams();

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<PrecisionStatus[]>(`${this.basePath}/api/Statuses`,
      {
        headers: headers,
        params: queryParameters
      }
    );
  }


}


export interface PrecisionCustomer {
  recordId?: number;
  quickbooksId?: string;
  name?: string;
}
