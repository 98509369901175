import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';

import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { AuthComponent } from '../../../pages/auth/auth.component';
import { AuthService } from '../../../@core/auth/auth.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;

  userMenu = [{ title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              public authService: AuthService,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.user = this.authService.userProfile();
    
    //Add option to change tenant in menu for admin role
    if(this.authService.hasRole('kw:admin')){
      this.userMenu.push({title: "Change Tenant"});
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  logOut() {
    console.log(this.authService.isAuthenticated)
  }
}
