import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TenantWords } from '../models/tenantWords';

@Injectable({
  providedIn: 'root'
})
export class TenantWordService {

  protected basePath = '/';
  public defaultHeaders = new HttpHeaders();

  public wordList: TenantWords[] = [];
  public isLoading: boolean = true;
  private AuthToken: string;

  constructor(
    protected httpClient: HttpClient
  ) {
    this.basePath = environment.PLATFORM_API_URL;
  }

  public setToken(token: string){
    this.AuthToken = token;
  }

  public putTenantWords(tenantWords: TenantWords, token: string = null): Observable<TenantWords[]> {

    let headers = this.defaultHeaders;

    if(token)
      headers = headers.set('Authorization', token);
    else
      headers = headers.set('Authorization', this.AuthToken);

    return this.httpClient.put<TenantWords[]>(`${this.basePath}/api/TenantWords/${tenantWords.id}`, tenantWords,

      {
        headers,
      }
    );
  }

  public getTenantWords(id?: number, token: string = null): Observable<TenantWords> {

    let headers = this.defaultHeaders;

    if(token)
      headers = headers.set('Authorization', token);
    else
      headers = headers.set('Authorization', this.AuthToken);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    if(id != null){ return this.httpClient.get<TenantWords>(`${this.basePath}/api/TenantWords/${id}`, { headers,});}
    else{ return this.httpClient.get<TenantWords>(`${this.basePath}/api/TenantWords`, { headers,});}
  }

  public deleteTenantWords(id: number, token: string = null) {

    let headers = this.defaultHeaders;

    if(token)
      headers = headers.set('Authorization', token);
    else
      headers = headers.set('Authorization', this.AuthToken);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.delete(`${this.basePath}/api/TenantWords/${id}`,
      {
        headers,
      }
    );
  }

  public postTenantWords(TenantWords: TenantWords, token: string = null): Observable<TenantWords> {

    let headers = this.defaultHeaders;

    if(token)
      headers = headers.set('Authorization', token);
    else
      headers = headers.set('Authorization', this.AuthToken);

    const httpHeaderAcceptSelected: string | undefined = 'application/json';
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.post<TenantWords>(`${this.basePath}/api/TenantWords`, TenantWords,

      {
        headers,
      }
    );
  }
}
