import { Component, Input, OnInit } from '@angular/core';
import { InventoryService } from '../../../pick-plan/services/inventory.service';
import { ErrorService } from '../../../../../@core/utils/error.service';
import { FilterSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'mass-balance',
  templateUrl: './mass-balance.component.html',
  styleUrls: ['./mass-balance.component.scss']
})
export class MassBalanceComponent implements OnInit {

  @Input() lotId: number;
  public isLoading: boolean = true;
  public massBalance: any[] = [];


  public dateFormat = { type: 'date', format: 'dd/MM/yyyy' };
  public pageSettings: PageSettingsModel = {
    pageSize: 10,
    pageSizes: [10, 25, 50],
  }

  public filterSettings: FilterSettingsModel = {
    mode: "Immediate",
    type: "Excel"
  }


  constructor(
    private errorService: ErrorService,
    private inventoryService: InventoryService
  ) { }

  ngOnInit() {


    this.inventoryService.getLotMassBalance(this.lotId).subscribe((data: any) => {

      // sort the data by date
      data.sort((a, b) => {
        return new Date(a.date) > new Date(b.date) ? 1 : -1;
      });
      
      this.massBalance = data;
      this.isLoading = false;
    }, err => { this.errorService.handleError(err); this.isLoading = false; });
  }

  public includePackout() {
    if (!this.massBalance || this.massBalance.length == 0) return false;
    return this.massBalance.some(x => x.packoutCode && x.packoutCode != null && x.packoutCode != '' && x.packoutCode != undefined);
  }

  public includeProduct(){
    return this.massBalance.some(x => x.productCode && x.productCode != null && x.productCode != '' && x.productCode != undefined);
  }


}
